import api from '../../utils/axios';
import { useMutation, useQuery } from 'react-query';
import appStore from '../../store';

const fetchPaymentsLink = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/payment_links', { params })
    : api.sandbox.get('/payment_links', { params });

export const useFetchPaymentsLink = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchPaymentsLink', params],
    () => {
      return fetchPaymentsLink(params);
    },
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const retrievePaymentLink = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/payment_links/${id}/line_items`, {
        params,
      })
    : api.sandbox.get(`/payment_links/${id}/line_items`, {
        params,
      });

// console.log('Testing params frompaymentlink api', params);

export const useRetrievePaymentLink = ({
  id,
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['retrievePaymentLink', id],
    () => retrievePaymentLink(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchPaymentLink = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/payment_links/${id}`, { params })
    : api.sandbox.get(`/payment_links/${id}`, { params });

// console.log('Testing params frompaymentlink api', params);

export const useFetchPaymentLink = ({
  id,
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchPaymentLink', id],
    () => fetchPaymentLink(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const updatePaymentLink = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/payment_links/${id}`, data)
    : api.sandbox.patch(`/payment_links/${id}`, data);

export const useUpdatePaymentLink = (id) => {
  return useMutation(['updatePaymentLink', id], (data) =>
    updatePaymentLink(id, data)
  );
};

const fetchRefunds = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/refunds', { params })
    : api.sandbox.get('/refunds', { params });

export const useFetchRefunds = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchRefunds', params],
    () => fetchRefunds(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};


const fetchCheckoutBranding = (params) =>
  api.live.get(`/checkout_branding`, { params });

export const useFetchCheckoutBranding = ({
  onSuccess,
  onError,
  enabled,
  params,
}) => {
  return useQuery(
    ['fetchCheckoutBranding', params],
    () => {
      return fetchCheckoutBranding(params);
    },
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};
