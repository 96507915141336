import DashboardTable from "../../../../Dashboard/DashboardTable/DashboardTable";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as DateIcon } from "../../../../../assets/images/icons/date.svg";
import { useSelector } from "react-redux";
import { useFetchCustomers } from "../../../../../api/customer/customer";
import { dateFormatter } from "../../../../../utils/date";
import useTablePagination from "../../../../../hooks/useTablePagination";
import { useNavigate } from "react-router-dom";

function CustomersTable({ filters = {} }) {
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const { current_account } = useSelector((state) => state.auth);
  const { refetch_customer } = useSelector((state) => state.customer);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_customer, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchCustomersSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        name: row.name,
        email: row.email,
        description: row.description ?? "-",
        created: dateFormatter(row.created_at),
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchCustomersError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchCustomers({
    onSuccess: onFetchCustomersSuccess,
    onError: onFetchCustomersError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const handleRowClick = (row) => {
    // dispatch(setSelectedCustomer(row._data));
    navigate(DASHBOARD_PATH + `/customers/${row.id}`);
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 100,
        Cell: (cell) => {
          return <span className="font-semibold">{cell.value}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 100,
        Cell: (cell) => {
          return <span>{cell.value ?? "-"}</span>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        minWidth: 230,
        Cell: (cell) => {
          return <span className="text-light font-normal">{cell.value}</span>;
        },
      },
      {
        Header: "Created",
        accessor: "created",
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className="flex items-center">
              <DateIcon className="mr-1.5" />
              <span className="text-light">{cell.value}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          selectable
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Customers"
        />
      </div>
    </>
  );
}

export default CustomersTable;
