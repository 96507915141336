import { useSelector } from 'react-redux';
import {
  capitalize,
} from 'utils/string';
import { blockchainNetwork } from 'utils/crypto_utils';

const PayoutMethod = () => {
  const { selected_payout } = useSelector(
    (state) => state.payout
  );

  return (
    <div className='space-y-1.5 pb-2'>
      <div className='app-modal-fullscreen__title'>
        Payout Method
      </div>
      <div className='horizontal-divider' />
      <div className='pt-4'>
        {selected_payout.method === 'crypto' &&
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 space-y-2'>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transaction ID
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payout?.blockchain_transaction?.id ?? '-'}
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Method
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {capitalize(selected_payout.method)}
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Network
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payout?.blockchain_transaction?.network ? blockchainNetwork(
                    selected_payout.blockchain_transaction.network
                  ) : '-'}
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Address
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  { 
                    selected_payout?.blockchain_transaction
                      ?.to_addr ? selected_payout.blockchain_transaction
                      .to_addr : '-'
                  }
                </div>
              </div>
              {selected_payout?.blockchain_transaction
                ?.memo && (
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Transaction Memo
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    {
                      selected_payout.blockchain_transaction
                        .memo
                    }
                  </div>
                </div>
              )}
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transaction Hash
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payout?.blockchain_transaction?.tx_hash ? (selected_payout.blockchain_transaction
                    .explorer ? (
                    <a
                      href={
                        selected_payout
                          .blockchain_transaction.explorer
                      }
                      target='_blank'
                      rel='noreferrer'
                      className='text-tertiary'>
                      {
                        selected_payout
                          .blockchain_transaction.tx_hash
                      }
                    </a>
                  ) : (
                    <>
                      {
                        selected_payout
                          .blockchain_transaction.tx_hash
                      }
                    </>
                  )) : '-'}
                </div>
              </div>
            </div>
          </div>
        }
        {selected_payout.method === 'internal' &&
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 space-y-2'>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Receiving Merchant
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payout?.internal_account ?? '-'}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default PayoutMethod;
