import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import useTablePagination from "../../../../../../../hooks/useTablePagination";
import {currencyFormatter, currencySymbol, snakeCaseToTitleCase} from "../../../../../../../utils/string";
import DashboardModalTable from "../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import {useFetchCreditNotes} from "../../../../../../../api/customer/credit_note";

function CustomerDetailsCreditNotesTable() {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_customer } = useSelector((state) => state.customer);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage
  } = useTablePagination(data, tableRows);

  const onFetchCreditNotesSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  }

  const {
    isFetching: isFetchingData,
  } = useFetchCreditNotes({
    onSuccess: onFetchCreditNotesSuccess,
    onError: () => null,
    enabled: true,
    params:  {
      customer: selected_customer?.id,
      limit: limitQuery,
      ...requestQuery
    }
  });

  const tableColumns = useMemo(() => [
    {
      Header: "ID",
      accessor: "credit_note_number",
      minWidth: 100,
      Cell: (cell) => (
        <div className="app-modal-fullscreen__value">{cell.value}</div>
      )
    },
    {
      Header: "Reason",
      accessor: "reason",
      Cell: (cell) => (
        <div className="app-modal-fullscreen__value font-semibold">
          {snakeCaseToTitleCase(cell.value)}
        </div>
      )
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: (cell) => {
        const row = cell.row.original;
        return (
          <div 
            className="app-modal-fullscreen__table__amount"
            style={{ fontSize: "1.2rem" }}
          >
            <div className="text-light">
              {currencySymbol(row.currency)}
              {currencyFormatter(row.currency, row.amount)}
            </div>
            <span className="text-light">{row.currency?.toUpperCase()}</span>
          </div>
        )
      }
    },
  ], []);

  return (
    <div className="space-y-1.5">
      <div className="app-modal-fullscreen__title">Credit Notes</div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Credit Notes"
      />
    </div>
  )
}

export default CustomerDetailsCreditNotesTable;
