import Modal from "../../../../../Common/Modal/Modal";
import Input from "../../../../../Common/Form/Input/Input";
import Label from "../../../../../Common/Form/Label/Label";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Stack from "../../../../../Common/Stack/Stack";
import Textarea from "../../../../../Common/Form/Textarea/Textarea";
import WebhookSelectEvents from "../../WebhookSelectEvents/WebhookSelectEvents";
import webhookTypes from "../../../../../../utils/webhook_types";
import {ReactComponent as CloseIcon} from "../../../../../../assets/images/icons/modal-close.svg";
import Button from "../../../../../Common/Form/Button/Button";
import {useStoreWebhook, useUpdateWebhook} from "../../../../../../api/webhook/webhook";
import {toast} from "react-toastify";
import {
  setKeyRevealed,
  setRefetchWebhook,
  setShowWebhookForm, setWebhookSecretKey
} from "../../../../../../store/webhook/webhookSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const formSchema = yup.object({
  url: yup.string().required().label('Endpoint Url'),
  enabled_events: yup.array().min(1).required().label('Events'),
}).required();

function WebhooksModalCreate({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {selected_webhook} = useSelector((state) => state.webhook);
  const {
    register,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues: {
      url: selected_webhook?.url ?? "",
      description: selected_webhook?.description ?? "",
      enabled_events: selected_webhook?.enabled_events ?? [],
    },
    resolver: yupResolver(formSchema)
  });
  const enabled_events = watch("enabled_events");

  const { mutate: mutateStoreWebhook, isLoading: isStoreLoading } = useStoreWebhook();
  const { mutate: mutateUpdateWebhook, isLoading: isUpdateLoading } = useUpdateWebhook(selected_webhook?.id);

  useEffect(() => {
    return () => dispatch(setShowWebhookForm(false));
  }, [dispatch]);

  const handleClose = () => {
    onClose && onClose();
  }

  const fieldError = (field) => {
    return errors[field]?.message/* || (error && error.response?.data.error.param === field);*/
  }

  const handleEventSelect = (event) => {
    if (enabled_events.includes(event)) {
      setValue("enabled_events",
        enabled_events.filter(item => item !== event)
      );
      return;
    }

    if (event === "*") {
      setValue("enabled_events", [event]);
      return;
    }

    setValue("enabled_events", [
      ...enabled_events,
      event
    ]);
  }

  const handleClearEvents = () => {
    setValue("enabled_events", []);
  }

  const handleRemoveEvent = (event) => {
    setValue("enabled_events", enabled_events.filter(item => item !== event));
  }

  const onSubmit = (formData) => {
    const _data = {
      enabled_events: formData.enabled_events,
      url: formData.url,
    }

    if (formData.description) _data.description = formData.description;

    if (selected_webhook?.id) {
      mutateUpdateWebhook(_data, {
        onSuccess: () => {
          dispatch(setRefetchWebhook(Date.now()));
          dispatch(setShowWebhookForm(false));
          toast.success("Webhook updated");
          handleClose();
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        }
      })
      return;
    }

    mutateStoreWebhook(_data, {
      onSuccess: (data) => {
        dispatch(setKeyRevealed(true));
        dispatch(setWebhookSecretKey(data.data.secret));
        dispatch(setRefetchWebhook(Date.now()));
        navigate(`/dashboard/webhooks/${data.data.id}`);
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      }
    })
  }

  return (
    <Modal show={show} onClose={handleClose} size="lg" overflowVisible>
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {selected_webhook?.id ? "Update" : "Create"} Webhook Endpoint
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form id="new-webhook" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1">
            <div>
              <Label
                title="Endpoint URL"
                error={fieldError("url")}
              />
              <Input
                {...register("url")}
                type="text"
                block
                error={fieldError("url")}
                placeholder="https://"
              />
            </div>
            <div>
              <Label
                title="Description (optional)"
                error={fieldError("description")}
              />
              <Textarea
                {...register("description")}
                block
                rows="3"
              />
            </div>
          </Stack>
          <div className="app-webhook__modal__form-title mt-7 mb-4">Events to Send</div>
          <WebhookSelectEvents
            className="w-1/2 mb-4"
            options={webhookTypes}
            onSelect={handleEventSelect}
            value={enabled_events}
          />
          {fieldError("enabled_events") &&
          <p className="app-input__help">{fieldError("enabled_events")}</p>
          }
          {enabled_events.length > 0 &&
          <>
            <div className="app-webhook__modal__events__clear">
              <span role="button" onClick={handleClearEvents}>Clear all</span>
            </div>
            <div className="app-webhook__modal__events">
              {enabled_events.map((event, index) => (
                <div key={`event-${event}-${index}`} className="app-webhook__modal__events__item">
                  <span>{event === "*" ? "all" : event}</span>
                  <CloseIcon onClick={() => handleRemoveEvent(event)}/>
                </div>
              ))}
            </div>
          </>
          }
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button
            disabled={isStoreLoading || isUpdateLoading}
            form="new-webhook"
            type="submit"
          >
            Save
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}

export default WebhooksModalCreate;
