import ModalFullScreen from "../../../../../../Common/ModalFullScreen/ModalFullScreen";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelTag from "../../../../../../Common/LabelTag/LabelTag";
import { ReactComponent as DateIcon } from "../../../../../../../assets/images/icons/date.svg";
import {
  useDeleteInvoice,
  useUpdateInvoice,
} from "../../../../../../../api/customer/invoice";
import { toast } from "react-toastify";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../utils/string";
import clsx from "clsx";
import moment from "moment";
import { dateFormatter } from "../../../../../../../utils/date";
import MetadataForm from "../../../../../../Dashboard/MetadataForm/MetadataForm";
import NotesModule from '../../../../../../Dashboard/Notes/Notes';
import {
  setRefetchInvoices,
  setSelectedInvoice,
} from "../../../../../../../store/invoice/invoiceSlice";
import ModalDelete from "../../../../../../Dashboard/Modals/ModalDelete/ModalDelete";
import InvoiceDetailsButtons from "./InvoiceDetailsButtons/InvoiceDetailsButtons";
import InvoiceDetailsEventTable from "./InvoiceDetails/InvoiceDetailsEventsTable";
import InvoiceDetailsLogsTable from "./InvoiceDetails/InvoiceDetailsLogsTable";
import InvoiceDetailsPaymentsTable from "./InvoiceDetails/InvoiceDetailsPaymentsTable";
import InvoiceDetailsCreditNotesTable from "./InvoiceDetails/InvoiceDetailsCreditNotesTable";
import InvoiceDetailsItems from "./InvoiceDetails/InvoiceDetailsItems";
import CopyToClipboardBoxID from "../../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";

function InvoicesModalDetails({ show = false, onClose = null , setShowCreditNoteModal = null }) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selected_invoice } = useSelector((state) => state.invoice);

  const { mutate: mutateDeleteInvoice, isLoading: isDeleteLoading } =
    useDeleteInvoice(selected_invoice?.id);
  const { mutate: mutateUpdateInvoice, isLoading: isUpdateLoading } =
    useUpdateInvoice(selected_invoice?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleModalDelete = () => {
    if (isDeleteLoading) return;

    mutateDeleteInvoice(null, {
      onSuccess: () => {
        setShowDeleteModal(true);
        handleClose();
        dispatch(setSelectedInvoice(null));
        dispatch(setRefetchInvoices(Date.now()));
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleMetadataSave = (metadata) => {
    mutateUpdateInvoice(
      { metadata },
      {
        onSuccess: (data) => {
          dispatch(setSelectedInvoice(data.data));
          dispatch(setRefetchInvoices(Date.now()));
          toast.success("Metadata updated");
        },
      }
    );
  };


  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">Invoice</div>
            <CopyToClipboardBoxID text={selected_invoice?.id} />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          <div className="space-y-7">
            <div className="space-y-1">
              <div className="flex items-center gap-3">
                <div className="app-modal-fullscreen__big-title">
                  {selected_invoice?.invoice_number}
                </div>
                <span className="text-light font-semibold">for</span>
                <div className="app-customers__modal__balance">
                  {currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    selected_invoice.amount_due
                  )}
                  <span>{selected_invoice.currency.toUpperCase()}</span>
                </div>
                <LabelTag
                  color={clsx(
                    selected_invoice.status === "paid" && "primary",
                    selected_invoice.status === "part_paid" && "tertiary",
                    selected_invoice.status === "void" && "warning",
                    selected_invoice.status === "issued" && "secondary",
                    selected_invoice.status === "over_due" && "danger",
                    selected_invoice.status === "uncollectible" && "dark"
                  )}
                  className="ml-6"
                >
                  {selected_invoice.status}
                </LabelTag>
              </div>
              <div className="flex items-center pb-2">
                <DateIcon className="mr-1.5" />
                <span className="app-modal-fullscreen__value">
                  Last updated{" "}
                  {moment.unix(selected_invoice.updated_at).fromNow()}
                </span>
              </div>
              <InvoiceDetailsButtons
                handleClose={handleClose}
                isDeleteLoading={isDeleteLoading}
                setShowDeleteModal={setShowDeleteModal}
                setShowCreditNoteModal={setShowCreditNoteModal}
              />
            </div>
            <NotesModule enabled={!!selected_invoice} parent_object={selected_invoice} />
            <div className="space-y-1.5 pb-2">
              <div className="app-modal-fullscreen__title">Summary</div>
              <div className="horizontal-divider" />
              <div className="pt-4">
                <div className="grid grid-cols-5 gap-4">
                  <div className="col-span-2 space-y-1">
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Customer ID
                      </div>
                      <div className="app-modal-fullscreen__kv__value break-all">
                        {selected_invoice.customer.id}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Customer Name
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.customer.name}
                      </div>
                    </div>
                    {selected_invoice.shipping_rates.length > 0 && (
                      <div className="app-modal-fullscreen__kv">
                        <div className="app-modal-fullscreen__kv__key">
                          Shipping Address
                        </div>
                        <div className="app-modal-fullscreen__kv__value">
                          {selected_invoice.shipping_rates.length > 0 &&
                          selected_invoice.customer.shipping ? (
                            <>
                              <p>
                                {selected_invoice.customer.shipping.first_line}
                              </p>
                              {selected_invoice.customer.shipping
                                .second_line && (
                                <p>
                                  {
                                    selected_invoice.customer.shipping
                                      .second_line
                                  }
                                </p>
                              )}
                              <p>
                                {[
                                  selected_invoice.customer.shipping.state,
                                  selected_invoice.customer.shipping.country,
                                  selected_invoice.customer.shipping.post_code,
                                ]
                                  .filter((n) => n)
                                  .join(", ")}
                              </p>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    )}
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Currency
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.currency.toUpperCase()}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Accepted Crypto Currencies
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {!selected_invoice.crypto_payment_currencies.length ? (
                          "All"
                        ) : (
                          <>
                            {selected_invoice.crypto_payment_currencies.join(
                              ", "
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 space-y-1">
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Invoice Number
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.invoice_number}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Status
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        <LabelTag
                          color={clsx(
                            selected_invoice.status === "paid" && "primary",
                            selected_invoice.status === "part_paid" &&
                              "tertiary",
                            selected_invoice.status === "void" && "warning",
                            selected_invoice.status === "issued" && "secondary",
                            selected_invoice.status === "over_due" && "danger",
                            selected_invoice.status === "uncollectible" &&
                              "dark"
                          )}
                        >
                          {selected_invoice.status}
                        </LabelTag>
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Due Date
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.due_date
                          ? dateFormatter(selected_invoice.due_date)
                          : "-"}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Partial Payment
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.partial_payment ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Cryptocurrency Padding
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.crypto_payment_padding === 0
                          ? "None"
                          : `${selected_invoice.crypto_payment_padding}%`}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Invoice Sending Method
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_invoice.sending_method === "manual" &&
                          "Send Invoice Manually"}
                        {selected_invoice.sending_method === "auto" &&
                          "Invoice Sent Automatically"}
                      </div>
                    </div>
                    {selected_invoice?.hosted_invoice && (
                      <div className="app-modal-fullscreen__kv">
                        <div className="app-modal-fullscreen__kv__key">
                          Payment Page URL
                        </div>
                        <div className="app-modal-fullscreen__kv__value">
                          <a
                            href={selected_invoice.hosted_invoice}
                            target="_blank"
                            rel="noreferrer"
                            className="break-all text-tertiary"
                          >
                            {selected_invoice.hosted_invoice}
                          </a>
                        </div>
                      </div>
                    )}
                  </div> 
                </div>
              </div>
            </div>
            <InvoiceDetailsItems enabled={!!selected_invoice} />
            <div className="space-y-1.5 pb-2">
              <MetadataForm
                data={selected_invoice.metadata}
                onSave={handleMetadataSave}
                loading={isUpdateLoading}
              />
            </div>
            <InvoiceDetailsPaymentsTable enabled={!!selected_invoice} />
            <InvoiceDetailsCreditNotesTable enabled={!!selected_invoice} />
            <InvoiceDetailsLogsTable enabled={!!selected_invoice} />
            <InvoiceDetailsEventTable enabled={!!selected_invoice} />
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title="Delete Invoice"
          message="Are you sure you want to delete this invoice?"
        />
      )}
    </>
  );
}

export default InvoicesModalDetails;
