import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    selected_customer: null,
    show_customer_form: false,
    refetch_customer: null,
    selected_pending_invoice_item: null,
  },
  reducers: {
    setSelectedCustomer: (state, payload) => {
      state.selected_customer = payload.payload;
    },
    setShowCustomerForm: (state, payload) => {
      state.show_customer_form = payload.payload;
    },
    setRefetchCustomer: (state, payload) => {
      state.refetch_customer = payload.payload;
    },
    setSelectedPendingInvoiceItem: (state, payload) => {
      state.selected_pending_invoice_item = payload.payload;
    },
  },
});

export const {
  setSelectedCustomer,
  setShowCustomerForm,
  setRefetchCustomer,
  setSelectedPendingInvoiceItem,
} = customerSlice.actions;

export default customerSlice.reducer;
