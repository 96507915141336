import React, { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/modal-close.svg";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { currencyFormatter, currencySymbol } from "../../../utils/string";
import { useAsyncDebounce } from "react-table";
import { useFetchDiscounts } from "../../../api/product/discount";

function CustomSelectDiscounts({
  value = null,
  placeholder = "",
  currency = null,
  disabled = false,
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    const params = {};
    if (inputValue) params.query = inputValue;
    if (currency) params.currency = currency;
    params.null_currency = true;
    params.expired = false;
    return params;
  }, [inputValue, currency]);

  const { isLoading, refetch } = useFetchDiscounts({
    onSuccess: (data) => setOptions(data.data),
    onError: () => null,
    enabled: false,
    params: {
      active: true,
      ...bindParams,
    },
  });

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const handleOptionClick = (option) => {
    if (disabled) return;

    setSelected(option);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const handleClearSelection = (e) => {
    e.stopPropagation();

    setSelected(null);
    rest.onSelect && rest.onSelect(null);
  };

  const handleOpenClick = () => {
    if (disabled) return;

    setOpen(!open);
  };

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={handleOpenClick}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active",
          disabled && "select-customer__input--disabled"
        )}
      >
        <div className="select-merchant__input__value">
          {!selected ? (
            <span>Select discount</span>
          ) : (
            <div className="select-merchant__input__selected">
              <div>
                {truncate(selected.name, { length: 50, separator: "..." })}
              </div>
              <span>-</span>
              <span>
                {selected.amount_off && (
                  <>
                    {currencySymbol(selected.currency)}{" "}
                    {currencyFormatter(selected.currency, selected.amount_off)}
                  </>
                )}
                {selected.percent_off && <>{selected.percent_off}%</>}
              </span>
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-1">
          {selected && (
            <CloseIcon
              onClick={handleClearSelection}
              className="select-customer__input__clear"
            />
          )}
          <img
            className="select-customer__input__arrow"
            src={ArrowDownIcon}
            alt=""
          />
        </div>
      </div>
      {open && (
        <div className="select-customer__options">
          <div className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search">
            <input
              autoFocus
              type="text"
              className="select-customer__input__value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {isLoading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {!isLoading &&
            options.map((discount, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(discount)}
                className={clsx(
                  "select-customer__options__item",
                  selected &&
                    discount.id === selected.id &&
                    "select-customer__options__item--active"
                )}
              >
                <div>
                  {truncate(discount.name, { length: 50, separator: "..." })}
                </div>
                <span>-</span>
                <span>
                  {discount.amount_off && (
                    <>
                      {currencySymbol(discount.currency)}{" "}
                      {currencyFormatter(
                        discount.currency,
                        discount.amount_off
                      )}
                    </>
                  )}
                  {discount.percent_off && <>{discount.percent_off}%</>}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default CustomSelectDiscounts;
