import { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/images/icons/dashboard/delete.svg";
import { useSelector } from "react-redux";
import { useUpdateInvoice } from "../../../../../../../../api/customer/invoice";
import SelectShippingRates from "../../../../../../../Dashboard/SelectShippingRates/SelectShippingRates";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../../utils/string";

function InvoiceShippingRates({ refetchInvoice }) {
  const [selectedShippingRates, setSelectedShippingRates] = useState([]);
  const [showShippingRatesSelect, setShowShippingRatesSelect] = useState(false);
  const { draft_invoice } = useSelector((state) => state.invoice);

  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  useEffect(() => {
    if (!draft_invoice) return;
    setSelectedShippingRates(draft_invoice?.shipping_rates);
  }, [draft_invoice]);

  const handleShippingRateSelect = (shipping_rate) => {
    setShowShippingRatesSelect(false);

    if (!draft_invoice) return;

    mutateUpdateInvoice(
      {
        shipping_rates: [
          ...selectedShippingRates.map((shipping_rates) => shipping_rates.id),
          shipping_rate?.id,
        ],
      },
      {
        onSuccess: () => {
          refetchInvoice();
          setShowShippingRatesSelect(false);
        },
      }
    );
  };

  const handleAddShippingRateClick = () => {
    setShowShippingRatesSelect(true);
  };

  const handleDeleteShippingRateClick = (id) => {
    const shipping_rates = selectedShippingRates
      .filter((shipping_rates) => shipping_rates.id !== id)
      .map((shipping_rates) => shipping_rates.id);

    mutateUpdateInvoice(
      {
        shipping_rates: shipping_rates.length ? shipping_rates : "",
      },
      {
        onSuccess: () => {
          refetchInvoice();
          setShowShippingRatesSelect(false);
        },
      }
    );
  };

  return (
    <div>
      <div className="app-invoices__modal__earning">Shipping rate</div>
      {selectedShippingRates.map((shipping_rate, index) => (
        <div key={index}>
          <div className="app-invoices__modal__invoice-discount-tax mb-1">
            <div>
              {shipping_rate?.description && (
                <span className="mr-2">{shipping_rate.description}</span>
              )}
              {currencySymbol(shipping_rate.currency)}{" "}
              {currencyFormatter(shipping_rate.currency, shipping_rate.amount)}
            </div>
            <DeleteIcon
              onClick={() => handleDeleteShippingRateClick(shipping_rate.id)}
              className="cursor-pointer"
            />
          </div>
        </div>
      ))}
      {showShippingRatesSelect && (
        <div className="w-8/12">
          <SelectShippingRates
            onSelect={handleShippingRateSelect}
            data={selectedShippingRates.map(
              (shipping_rate) => shipping_rate.id
            )}
          />
        </div>
      )}
      {!showShippingRatesSelect && (
        <span
          onClick={handleAddShippingRateClick}
          className="app-invoices__modal__link"
        >
          Add shipping rate
        </span>
      )}
    </div>
  );
}

export default InvoiceShippingRates;
