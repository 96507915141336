import "./CustomersModalAdjustBalance.scss";
import Modal from "../../../../../Common/Modal/Modal";
import Label from "../../../../../Common/Form/Label/Label";
import Stack from "../../../../../Common/Stack/Stack";
import Select from "../../../../../Common/Form/Select/Select";
import InputPrice from "../../../../../Common/Form/InputPrice/InputPrice";
import Textarea from "../../../../../Common/Form/Textarea/Textarea";
import Button from "../../../../../Common/Form/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import {
  currencyConverter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "../../../../../../utils/string";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FIAT_CURRENCIES_SELECT,
  ITEM_PRICE_REGEX,
} from "../../../../../../utils/constants";
import { useStoreCustomerBalanceTransaction } from "../../../../../../api/customer/balance_transaction";
import { toast } from "react-toastify";
import { setRefetchCustomer } from "../../../../../../store/customer/customerSlice";

const formSchema = yup
  .object({
    description: yup.string().label("Description"),
    price: yup
      .string()
      .required()
      .trim()
      .matches(
        ITEM_PRICE_REGEX,
        "Price must be greater than 0 and must not exceed 2 decimal places"
      )
      .label("Price"),
  })
  .required();

function CustomersModalAdjustBalance({ show = false, onClose = null, balances = [] }) {
  const dispatch = useDispatch();
  const [adjustType, setAdjustType] = useState("credit");
  const [selectedCurrency, setSelectedCurrency] = useState(balances[0]);
  const [newBalance, setNewBalance] = useState(balances[0].balance);
  const { selected_customer } = useSelector((state) => state.customer);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: "",
      description: "",
    },
    resolver: yupResolver(formSchema),
  });

  const price = watch("price");

  const { mutate } = useStoreCustomerBalanceTransaction(selected_customer?.id);

  const getCurrencies = useMemo(() => {
    return Object.entries(selected_customer.balance).map(([key, value]) => ({
      currency: key,
      balance: value,
    }));
  }, [selected_customer]);

  useEffect(() => {
    if (!selectedCurrency) {
      return;
    }

    setNewBalance(
      adjustType === "credit"
        ? selectedCurrency.balance + price * 100
        : selectedCurrency.balance - price * 100
    );
  }, [price, selectedCurrency, adjustType]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCurrencyChange = (e) => {
    const _currency = getCurrencies.find(
      (currency) => currency.currency === e.value
    );

    if (_currency) {
      setSelectedCurrency(_currency);
      return;
    }

    setSelectedCurrency({
      currency: e.value,
      balance: 0,
    });
    setNewBalance(0);
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const onSubmit = (formData) => {
    const _data = {
      currency: selectedCurrency.currency,
    };

    _data.amount = Number(currencyConverter(
      selectedCurrency.currency,
      adjustType === "credit" ? formData.price : -formData.price
    ));

    if (formData.description) _data.description = formData.description;

    mutate(_data, {
      onSuccess: () => {
        const _message =
          adjustType === "credit" ? "Credit balance: " : "Debit balance: ";
        const _amount = `${currencySymbol(
          selectedCurrency.currency
        )}${currencyFormatter(selectedCurrency.currency, _data.amount)}`;

        toast.success(
          <>
            {_message}
            <strong>{_amount}</strong>
          </>
        );
        dispatch(setRefetchCustomer(Date.now()));
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  return (
    <Modal show={show} onClose={handleClose} size="sm">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">Adjust Balance</div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <div className="app-adjust-balance__modal__header">
          <div>
            <div className="app-adjust-balance__modal__header__text">
              Current balance:
            </div>
            <div className="app-adjust-balance__modal__header__balance">
              <span className="app-adjust-balance__modal__header__amount">
                {currencyPrettier(
                  selectedCurrency?.currency,
                  selectedCurrency?.balance
                )}
              </span>
              <span className="app-adjust-balance__modal__header__currency">
                {selectedCurrency?.currency.toUpperCase()}
              </span>
            </div>
          </div>
          <div>
            <div className="app-adjust-balance__modal__header__text">
              New balance:
            </div>
            <div className="app-adjust-balance__modal__header__balance">
              <span className="app-adjust-balance__modal__header__amount">
                {currencyPrettier(selectedCurrency?.currency, newBalance)}
              </span>
              <span className="app-adjust-balance__modal__header__currency">
                {selectedCurrency?.currency.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
        <form id="adjust-balance" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1" gap="4">
            <div>
              <Label title="Adjustment Type" />
              <Select
                onSelect={(e) => setAdjustType(e.value)}
                options={[
                  { label: "Credit", value: "credit" },
                  { label: "Debit", value: "debit" },
                ]}
                value={adjustType}
              />
            </div>
            <div>
              <Label title="Amount" error={fieldError("price")} />
              <InputPrice
                {...register("price")}
                block
                currencies={FIAT_CURRENCIES_SELECT}
                onCurrencyChange={handleCurrencyChange}
                placeholder="0.00"
                currency={selectedCurrency?.currency}
                error={fieldError("price")}
              />
            </div>
            <div>
              <Label title="Internal Description" />
              <Textarea {...register("description")} block rows={2} />
            </div>
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button form="adjust-balance" type="submit">
            Apply
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default CustomersModalAdjustBalance;
