import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
// import useTableFilters from "../../../../hooks/useTableFilters";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import DashboardNavbar from "layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import BalanceTable from "./BalanceTable";
import useTableFilters from "hooks/useTableFilters";

const TABLE_FILTERS = [
  { label: "All", column: "status", value: "" },
  { label: "Drafts", column: "status", value: "draft" },
  { label: "Outstanding", column: "status", value: "issued" },
  { label: "Overdue", column: "status", value: "over_due" },
  { label: "Paid", column: "status", value: "paid" },
];

function Balance() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {
  //   selected_invoice,
  //   refetch_invoice,
  //   draft_invoice,
  //   show_create_invoice,
  // } = useSelector((state) => state.invoice);
  // const [showDetailModal, setShowDetailsModal] = useState(false);
  // const [showCreateModal, setShowCreateModal] = useState(false);
//   const [refreshInvoice, setRefreshInvoice] = useState(false);
  const [filters, setFilters] = useState({});
  const { id } = useParams();
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });

  // useFetchInvoice({
  //   onSuccess: (data) => {
  //     dispatch(setSelectedInvoice(data));
  //     setRefreshInvoice(false);
  //   },
  //   onError: (error) => {
  //     setRefreshInvoice(false);
  //     navigate("/dashboard/invoices");
  //     toast.error(error.response?.data.error.message);
  //   },
  //   id,
  //   enabled: refreshInvoice,
  // });

//   const handleCloseDetailModal = useCallback(() => {
//     setShowDetailsModal(false);
//     dispatch(setSelectedInvoice(null));
//     navigate("/dashboard/invoices");
//   }, [dispatch, navigate]);

  useEffect(() => {
    // if (!id) {
    //   handleCloseDetailModal();
    //   return;
    // }

    // setRefreshInvoice(true);
  }, []);

//   useEffect(() => {
//     return () => {
//       dispatch(setSelectedInvoice(null));
//     };
//   }, [dispatch]);

//   useEffect(() => {
//     setShowCreateModal(!!draft_invoice);
//   }, [draft_invoice]);

  // useEffect(() => {
  //   if (show_create_invoice) setShowCreateModal(true);
  // }, [show_create_invoice]);

//   useEffect(() => {
//     setShowDetailsModal(!!selected_invoice);
//   }, [selected_invoice]);

//   const handleCloseCreateModal = () => {
//     setShowCreateModal(false);
//     dispatch(setDraftInvoice(null));
//   };

//   const handleNewInvoiceClick = () => {
//     dispatch(setShowCreateInvoice(false));
//     dispatch(setSelectedInvoiceCustomer(null));
//     dispatch(setSelectedInvoiceItem(null));
//     dispatch(setShowProductsSelect(false));
//     dispatch(setSelectOneTime(false));
//     dispatch(setInvoiceAcceptedCryptos([]));
//     dispatch(setInvoiceCustomFields([]));
//     dispatch(setInvoiceLineItems([]));
//     dispatch(setInvoiceCurrency(""));
//     dispatch(setDraftInvoice(null));
//     setShowCreateModal(true);
//   };

//   const handleFiltersChange = (filters) => {
//     setFilters(filters);

//     setFilter({ column: "status", value: filters?.status ?? "" });
//   };

//   const activeFilterFiltered = useMemo(() => {
//     return Object.fromEntries(
//       Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
//     );
//   }, [activeFilter]);

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...(activeFilter.status ? activeFilter : null),
    };
  }, [filters, activeFilter]);

//   const handleFilterClick = (filter) => {
//     if (filter && filter.label === "All") {
//       setFilters((state) => {
//         const { status, ...rest } = state;

//         return rest;
//       });
//     }

//     setFilter(filter);
//   };

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Balance</div>
              <span className="app-dashboard__card__header__filters">
              </span>
            </div>
          </div>
          <BalanceTable filters={getFilters}/>
        </div>
        {/* {showDetailModal && (
          <InvoicesModalDetails
            show={showDetailModal}
            onClose={handleCloseDetailModal}
          />
        )}
        {showCreateModal && (
          <InvoicesModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )} */}
        <ReactTooltip
          id="invoice-modal-create"
          place="bottom"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
        </ReactTooltip>
      </div>
    </div>
  );
}

export default Balance;
