import "./Home.scss";
import Stack from "../../../components/Common/Stack/Stack";
import Select from "../../../components/Common/Form/Select/Select";
import DashboardNavbar from "../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import SectionBalance from "../../../components/Pages/Dashboard/Home/SectionBalance/SectionBalance";

function Home() {
  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <Stack columns="4" className="xl:grid-cols-3">
          <div className="app-dashboard__card col-span-4 xl:col-span-2 lg:col-span-2">
            <div className="app-dashboard__card__header">
              <div className="app-dashboard__card__title">Today</div>
              <Stack columns="1" gap="3" className="sm:grid-cols-2">
                <Select
                  options={[
                    {label: "Gross Volume", value: "gross-volume"},
                  ]}
                  value="gross-volume"
                />
                <Select
                  path="assets/images/icons/cryptos"
                  style={{minWidth: "150px"}}
                  options={[
                    {label: "Bitcoin", value: "btc", icon: "btc"}
                  ]}
                  value="btc"
                />
              </Stack>
            </div>
          </div>
          <SectionBalance />
          <div className="app-dashboard__card col-span-4 xl:col-span-3 lg:col-span-4">
            <div className="app-dashboard__card__header">
              <div className="app-dashboard__card__title">Overview</div>
              <Select
                style={{minWidth: "200px"}}
                options={[
                  {label: "Last 7 days", value: "7d"},
                ]}
                value="7d"
              />
            </div>
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default Home;
