import Label from "../../../../../../Common/Form/Label/Label";
import React from "react";
import { useSelector } from "react-redux";
import Input from "../../../../../../Common/Form/Input/Input";
import InfoIcon from "../../../../../../../assets/images/icons/info.svg";

function ProductsModalUnitLabel({ register, error }) {
  const { edit_product } = useSelector((state) => state.product);

  return (
    <div>
      <Label
        title="Unit label"
        subtitle={edit_product?.id ? '' : '(optional)'}
        tooltip={`
          <div class="mb-2">A label that represents units of this product in Walio and on customer’s receipts and invoices.</div>
          <div><span class="font-semibold">Example usage</span>: tickets sold per seat, will have the unit_label of seat</div>
        `}
        tooltipFor="product-modal-create"
        tooltipHtml={true}
        icon={InfoIcon}
        error={error}
      />
      <Input
        {...register("unit_label")}
        block
        error={error}
        disabled={edit_product?.id}
      />
    </div>
  );
}

export default ProductsModalUnitLabel;
