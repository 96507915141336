import axios from "axios";
import { logoutUser } from "../store/app/authSlice";
import qs from "qs";

let store;
export const injectStore = (_store) => {
  store = _store;
};

const instance = axios.create({
  baseURL: "https://walio.io/v1",
  withCredentials: true,
});

const sandboxInstance = axios.create({
  baseURL: "https://walio.io/test/v1",
  withCredentials: true,
});

function responseInterceptors(error) {
  if (error.response && error.response.status === 401) {
    // merchant_authentication_failed
    if (error.response.data.error.code === "merchant_authentication_failed") {
      window.location.reload();
    }

    // authentication_failed
    if (error.response.data.error.code === "authentication_failed") {
      if (store.getState().auth.authenticated) {
        store.dispatch(logoutUser());
      }
    }
  }

  console.log(error);

  return Promise.reject(error.response.data);
}

function requestInterceptors(config) {
  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };
  return config;
}

instance.interceptors.request.use(requestInterceptors);

sandboxInstance.interceptors.request.use(requestInterceptors);

instance.interceptors.response.use(
  (response) => response,
  responseInterceptors
);

sandboxInstance.interceptors.response.use(
  (response) => response,
  responseInterceptors
);

const modes = {
  live: instance,
  sandbox: sandboxInstance,
};

export default modes;
