export const typesTable = {
    topup: {
      displayValue: "Topup",
      reportingValue: "Topup",
      colour: "primary",
      creditDebit: "C"
    },
    payout: {
      displayValue: "Payout",
      reportingValue: "Payout",
      colour: "danger",
      creditDebit: "D"
    },
    payout_network_fee: {
      displayValue: "Payout Network Fee",
      reportingValue: "Network Fee",
      colour: "tertiary",
      creditDebit: "D"
    },
    payout_reversal: {
      displayValue: "Payout Reversal",
      reportingValue: "Payout Reversal",
      colour: "tertiary",
      creditDebit: "C"
    },
    recipient_wallet_transfer: {
      displayValue: "Recipient Wallet Transfer",
      reportingValue: "Transfer",
      colour: "primary",
      creditDebit: "C"
    },
    wallet_transfer: {
      displayValue: "Wallet Transfer",
      reportingValue: "Transfer",
      colour: "danger",
      creditDebit: "D"
    },
    wallet_transfer_reversal: {
      displayValue: "Wallet Transfer Reversal",
      reportingValue: "Transfer Reversal",
      colour: "tertiary",
      creditDebit: "C"
    },
    rp_transfer_in: {
      displayValue: "Reward Pool Credit",
      reportingValue: "Transfer",
      colour: "secondary",
      creditDebit: "D"
    },
    rp_transfer_out: {
      displayValue: "Reward Pool Debit",
      reportingValue: "Transfer",
      colour: "secondary",
      creditDebit: "C"
    },
    rp_transfer_reversal: {
      displayValue: "Reward Pool Credit Reversal",
      reportingValue: "Transfer Reversal",
      colour: "tertiary",
      creditDebit: "C"
    },
    rp_reward: {
      displayValue: "Reward",
      reportingValue: "Earnings",
      colour: "primary",
      creditDebit: "C"
    },
    swap_from: {
      displayValue: "Swap Out",
      reportingValue: "Swap",
      colour: "secondary",
      creditDebit: "D"
    },
    swap_to: {
      displayValue: "Swap In",
      reportingValue: "Swap",
      colour: "secondary",
      creditDebit: "C"
    },
    walio_fee: {
      displayValue: "Walio Fee",
      reportingValue: "Fee",
      colour: "tertiary",
      creditDebit: "D"
    },
    carbon_zero: {
      displayValue: "Carbon Zero Contribution",
      reportingValue: "Contribution",
      colour: "Yellow",
      creditDebit: "D"
    },
    application_fee: {
      displayValue: "Application Fee",
      reportingValue: "Earnings",
      colour: "primary",
      creditDebit: "C"
    },
    application_fee_refund: {
      displayValue: "App Fee Refund",
      reportingValue: "Earnings Refund",
      colour: "danger",
      creditDebit: "D"
    },
    payment: {
      displayValue: "Payment",
      reportingValue: "Earnings",
      colour: "primary",
      creditDebit: "C"
    },
    refund: {
      displayValue: "Refund",
      reportingValue: "Refund",
      colour: "danger",
      creditDebit: "D"
    },
    refund_network_fee: {
        displayValue: "Refund Network Fee",
        reportingValue: "Network Fee",
        colour: "tertiary",
        creditDebit: "D"
      },
      refund_reversal: {
        displayValue: "Refund Reversal",
        reportingValue: "Refund Reversal",
        colour: "tertiary",
        creditDebit: "C"
      },
      recipient_transfer: {
        displayValue: "Recipient Transfer",
        reportingValue: "Transfer",
        colour: "primary",
        creditDebit: "C"
      },
      transfer: {
        displayValue: "Transfer",
        reportingValue: "Transfer",
        colour: "danger",
        creditDebit: "D"
      },
      transfer_reversal: {
        displayValue: "Transfer Reversal",
        reportingValue: "Transfer Reversal",
        colour: "tertiary",
        creditDebit: "C"
      },
      vault_in: {
        displayValue: "Vault In",
        reportingValue: "Vault Transfer",
        colour: "dark",
        creditDebit: "D"
      },
      vault_out: {
        displayValue: "Vault Out",
        reportingValue: "Vault Transfer",
        colour: "dark",
        creditDebit: "C"
      },
      adjustment_credit: {
        displayValue: "Adjustment Credit",
        reportingValue: "Adjustment",
        colour: "dark",
        creditDebit: "C"
      },
      adjustment_debit: {
        displayValue: "Adjustment Debit",
        reportingValue: "Adjustment",
        colour: "dark",
        creditDebit: "D"
      },
      exchange_from: {
        displayValue: "Exchange From",
        reportingValue: "Exchange",
        colour: "secondary",
        creditDebit: "D"
        },
        exchange_to: {
        displayValue: "Exchange To",
        reportingValue: "Exchange",
        colour: "secondary",
        creditDebit: "C"
        },
        exchange_reversal: {
        displayValue: "Exchange Reversal",
        reportingValue: "Exchange Reversal",
        colour: "tertiary",
        creditDebit: "C"
        },
        exchange_fee: {
        displayValue: "Exchange Fee",
        reportingValue: "Fee",
        colour: "tertiary",
        creditDebit: "D"
        },
        exchange_fee_reversal: {
        displayValue: "Exchange Fee Reversal",
        reportingValue: "Fee Reversal",
        colour: "tertiary",
        creditDebit: "C"
        },
        exchange_network_fee: {
        displayValue: "Exchange Network Fee",
        reportingValue: "Network Fee",
        colour: "tertiary",
        creditDebit: "D"
        },
        exchange_network_fee_reversal: {
        displayValue: "Exchange Network Fee Reversal",
        reportingValue: "Network Fee Reversal",
        colour: "tertiary",
        creditDebit: "C"
        },
        exchange_network_fee_refund: {
        displayValue: "Exchange Network Fee Refund",
        reportingValue: "Network Fee Refund",
        colour: "tertiary",
        creditDebit: "D"
        },
        
    };

    // turn the above into a function that returns the  array of object label value

    export const typesTableArray = (typesTable) => {
        const typesArray = [];
        for (const [key, value] of Object.entries(typesTable)) {
            typesArray.push({ label: value.displayValue, value: key });
        }
        return typesArray;
    }