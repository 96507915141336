import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatter } from '../../../../../utils/date';
import clsx from 'clsx';
import LabelTag from '../../../../Common/LabelTag/LabelTag';
import { ReactComponent as DateIcon } from '../../../../../assets/images/icons/date.svg';
import PaymentLinkDetailsPreview from './PaymentLinkDetailsPreview';
import { currencyFormatter } from 'utils/string';
import PaymentLinkPreview from './PaymentLinkPreview';
import { Link } from 'react-router-dom';

const DetailPaymentLinks = () => {
  const [showModal, setShowModal] = useState(false);
  const { selected_payment_link } = useSelector(
    (state) => state.paymentLink
  );

  console.log('details -', selected_payment_link);
  const handleEditClick = () => {
    setShowModal(true);
  };

  const getPaymentCurrencies = () => {
    if (
      selected_payment_link.crypto_payment_currencies
        .length < 1
    ) {
      return 'value' === 'All';
    } else {
    }
  };

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  return (
    <div className='space-y-1.5 pb-2'>
      <div className='flex justify-between'>
        <div className='app-modal-fullscreen__title'>
          Details
        </div>
      </div>
      <div className='horizontal-divider' />
      <div
        className='flex pt-4'
        style={{
          // height: 'calc(80vh - 172px)',
          // overflow: 'hidden',
        }}>
        <div className='flex flex-col gap-3 w-2/5'>
          <div className='app-modal-fullscreen__kv'>
            <div className='app-modal-fullscreen__kv__key'>
              Status
            </div>
            <div className='app-modal-fullscreen__kv__value'>
              <LabelTag
                color={clsx(
                  selected_payment_link.active ?
                    'primary' : 'danger'
                )}>
                {selected_payment_link.active ? 'Active' : 'Archived'}
              </LabelTag>
            </div>
          </div>
          <div className='app-modal-fullscreen__kv'>
            <div className='app-modal-fullscreen__kv__key'>
              Created
            </div>
            <div className='app-modal-fullscreen__kv__value '>
              <div className='flex items-center'>
                <DateIcon className='mr-1.5' />
                <span className='app-modal-fullscreen__value'>
                  {dateFormatter(
                    selected_payment_link.created_at
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className='app-modal-fullscreen__kv'>
            <div className='app-modal-fullscreen__kv__key'>
              Allow Promo Codes
            </div>
            <div className='app-modal-fullscreen__kv__value '>
              {selected_payment_link.allow_promo_code ? 'Yes' : 'No'}
            </div>
          </div>

          <div className='app-modal-fullscreen__kv'>
            <div className='app-modal-fullscreen__kv__key'>
              Payment Padding
            </div>
            <div className='app-modal-fullscreen__kv__value '>
              {selected_payment_link.crypto_payment_padding}
              %
            </div>
          </div>

          <div className='app-modal-fullscreen__kv'>
            <div className='app-modal-fullscreen__kv__key'>
              Call to action button
            </div>
            <div className='app-modal-fullscreen__kv__value'>
              {selected_payment_link.submit_type}
            </div>
          </div>

          {selected_payment_link.completion_behaviour.type === "confirmation_page" && (
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Confirmation Page
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment_link.completion_behaviour.confirmation_message ? selected_payment_link.completion_behaviour.confirmation_message : 'Default'}
              </div>
            </div>
          )}

          {selected_payment_link.completion_behaviour.type === "redirect" && (
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Redirect URL
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment_link.completion_behaviour.redirect_url}
              </div>
            </div>
          )}

          <div className='app-modal-fullscreen__kv'>
            <div className='app-modal-fullscreen__kv__key'>
              Collect Address
            </div>
            <div className='app-modal-fullscreen__kv__value'>
              {selected_payment_link.collect_shipping_address ? 'Yes' : 'No'}
            </div>
          </div>

          {selected_payment_link.shipping_address_collection?.enabled && (
            <>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Collection Shipping Address
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  Yes
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Shipping Rates
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex flex-col'>
                    {selected_payment_link.shipping_rates.map((rate) => (
                      <Link className='hover:underline hover:text-blue-600' to={`/dashboard/shipping_rates/${rate.id}`}>{currencyFormatter(rate.currency, rate.amount) + ' ' + rate.currency.toUpperCase()}</Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Allowed countries
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payment_link.shipping_address_collection.allowed_countries.length < 1 ? 'All' : selected_payment_link.shipping_address_collection.allowed_countries.join(', ')}
                </div>
              </div>
            </>
          )}

          {selected_payment_link.phone_number_collection?.enabled && (
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Collect phone number
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                Yes
              </div>
            </div>
          )}

          {selected_payment_link.on_behalf_of && (
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                On behalf of
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment_link.on_behalf_of}
              </div>
            </div>
          )}

          {selected_payment_link.application_fee_amount && (
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Application Fee Amount
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment_link.application_fee_amount}
              </div>
            </div>
          )}

          {selected_payment_link.subscription_data && (
            <>
              {selected_payment_link.subscription_data.trial_period_days && (
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Trial
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    {selected_payment_link.subscription_data.trial_period_days} days
                  </div>
                </div>
              )}
              {selected_payment_link.subscription_data.transfer_data && (
                <>
                  <div className='app-modal-fullscreen__kv'>
                    <div className='app-modal-fullscreen__kv__key'>
                      Transfer Destination
                    </div>
                    <div className='app-modal-fullscreen__kv__value'>
                      {selected_payment_link.subscription_data.transfer_data.destination}
                    </div>
                  </div>
                  {selected_payment_link.subscription_data.transfer_data.amount_percent && (
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Transfer Percent
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_payment_link.subscription_data.transfer_data.amount_percent}%
                      </div>
                    </div>
                  )}
                </>
              )}
              {selected_payment_link.subscription_data.default_tax_rates && (
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Default Tax Rates
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    {selected_payment_link.subscription_data.default_tax_rates.map((rate) => (
                      rate.id + rate.percentage + '%, '
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
          {selected_payment_link.transfer_data === "redirect" && (
            <>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transfer Destination
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payment_link.transfer_data.destination}
                </div>
              </div>
              {selected_payment_link.subscription_data.transfer_data.amount && (
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Transfer Amount
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    {selected_payment_link.subscription_data.transfer_data.amount}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className='w-3/5'>
          <PaymentLinkPreview selected_payment_link={selected_payment_link} />
        </div>
      </div>
    </div>
  );
};

export default DetailPaymentLinks;
