import "./SectionPricing.scss";
import Stack from "../../../Common/Stack/Stack";
import LogoSmall from "../../../../assets/images/logo-small.svg";

/*const pricingData = [
  {
    key: "Payments",
    value: "Free",
    label: null,
  },
  {
    key: "Invoice",
    value: { month: "6", year: "65" },
    label: null,
  },
  {
    key: "Automated Subscriptions",
    value: "Free",
    label: "(Included in Invoice)",
  },
  {
    key: "Retail",
    value: { month: "6", year: "65" },
    label: null,
  },
  {
    key: "Payment Links",
    value: "Free",
    label: "(included in Walio set up)",
  },
  {
    key: "Links",
    value: { month: "1", label: "/account per month (+ payment fees)" },
  },
  {
    key: "GDS",
    value: { month: "12", year: "125" },
    label: null,
  },
];*/
const feesData = [
  {
    key: "Payments",
    value: "0.8 – 1.20% *",
    color: null,
  },
  {
    key: "Invoices",
    value: (
      <>
        0.3% of Invoice value - 25% cheaper in{" "}
        <img src={LogoSmall} alt="Walio" /> WALIO**
      </>
    ),
    color: null,
  },
  {
    key: "Automated subscriptions",
    value: (
      <>
        0.2% of Subscription value - 25% cheaper in{" "}
        <img src={LogoSmall} alt="Walio" /> WALIO**
      </>
    ),
    color: null,
  },
  {
    key: "Retail",
    value: (
      <>
        0.3% of Order value - 25% cheaper in <img src={LogoSmall} alt="Walio" />{" "}
        WALIO**
      </>
    ),
    color: null,
  },
  {
    key: "Swaps/Auto Converts",
    value: "1.85 - 2.85% *",
    color: null,
  },
  {
    key: "Deposits",
    value: "Free",
    color: "primary",
  },
  {
    key: "Transfers (Link Accounts)",
    value: "Free",
    color: "primary",
  },
  {
    key: "Payment Links",
    value: "Free",
    color: "primary",
  },
  {
    key: "Payouts (Manual)",
    value: "Blockchain fee only",
    color: "tertiary",
  },
  {
    key: "Payouts (Automatic)",
    value: "0.5% + Blockchain fee",
    color: null,
  },
  {
    key: "Payout (Forwarding/Instant)",
    value: "0.5% + Blockchain fee",
    color: null,
  },
  {
    key: "Refunds",
    value: "Blockchain fee only",
    color: "tertiary",
  },
  {
    key: "Customer Emails",
    value: "Free",
    color: "primary",
  },
  {
    key: "Notification Emails",
    value: "Free",
    color: "primary",
  },
  {
    key: "Wallet Creations (GDS)",
    value: "Free",
    color: "primary",
  },
  {
    key: "Wallet Transfers (GDS)",
    value: "0.25%",
    color: null,
  },
  {
    key: "Wallet Payouts (GDS)",
    value: "0.5%",
    color: null,
  },
  {
    key: "Token Creation (GDS)",
    value: (
      <>
        $125 + Blockchain fee - 25% cheaper in{" "}
        <img src={LogoSmall} alt="Walio" /> WALIO**
      </>
    ),
    color: null,
  },
  {
    key: "NFT Creation (GDS)",
    value: (
      <>
        $170 + Blockchain fee - 25% cheaper in{" "}
        <img src={LogoSmall} alt="Walio" /> WALIO**
      </>
    ),
    color: null,
  },
  {
    key: "Reward Pool Distributions (GDS)",
    value: "0.25%",
    color: null,
  },
  {
    key: "Financial Reports",
    value: "0.5% of sales volume",
    color: null,
  },
];

function SectionPricing() {
  const scrollToSection = (id = "") => {
    const anchor = window.document.getElementById(id);

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="section-pricing">
      <div id="fees" className="app-container">
        <h3 className="h3 section-pricing__title">Fees</h3>
        <Stack columns="1" className="justify-items-center">
          {/*<div className="section-pricing__card">
            <h3 className="h3 section-pricing__card__title">Pricing</h3>
            <div className="section-pricing__card__divider" />
            <div className="section-pricing__card__items">
              {pricingData.map((pricing, index) => (
                <Stack
                  key={index}
                  columns="1"
                  className="sm:grid-cols-3 section-pricing__card__item"
                >
                  <div className="section-pricing__card__key">
                    {pricing.key}
                  </div>
                  <div className="section-pricing__card__value">
                    {typeof pricing.value === "string" ? (
                      <>
                        {pricing.value}
                        <div className="section-pricing__card__value__label">
                          {pricing.label}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="section-pricing__card__value__in">
                          <div className="section-pricing__card__value__in__figure">
                            ${pricing.value.month}
                          </div>
                          <div>
                            <div className="section-pricing__card__value__in__walio">
                              <div>in</div>
                              <img src={LogoSmall} alt="Walio" />
                              <div>WALIO</div>
                            </div>
                            <div className="section-pricing__card__value__in__per">
                              {pricing.value.label ?? "/month"}
                            </div>
                          </div>
                        </div>
                        {pricing.value.year && (
                          <>
                            <span className="section-pricing__card__value__or">
                              or
                            </span>
                            <div className="section-pricing__card__value__in">
                              <div className="section-pricing__card__value__in__figure">
                                ${pricing.value.year}
                              </div>
                              <div>
                                <div className="section-pricing__card__value__in__walio">
                                  <div>in</div>
                                  <img src={LogoSmall} alt="Walio" />
                                  <div>WALIO</div>
                                </div>
                                <div className="section-pricing__card__value__in__per">
                                  /year
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Stack>
              ))}
            </div>
          </div>*/}
          <div className="w-3/5 section-pricing__card section-pricing__card--border-gray">
            <div className="section-pricing__card__fee__items">
              {feesData.map((fee, index) => (
                <Stack
                  key={index}
                  columns="2"
                  className="section-pricing__card__fee__item"
                >
                  <div className="section-pricing__card__fee__key border-r">
                    {fee.key}
                  </div>
                  <div
                    className={`
                    section-pricing__card__fee__value 
                    ${
                      fee.color
                        ? `text-${fee.color}`
                        : "section-pricing__card__fee__value__text-red"
                    }
                  `}
                  >
                    {fee.value}
                  </div>
                </Stack>
              ))}
            </div>
            <div className="section-pricing__card__fee__discountable mt-4">
              * Fees reduced based on a monthly average WALIO token balance –{" "}
              <span
                className="section-pricing__card__fee__discountable__link"
                onClick={() => scrollToSection("rewards")}
              >
                see here
              </span>
            </div>
            <div className="section-pricing__card__fee__discountable">
              ** Certain fees can be reduced by 25% when chosen to pay the fee
              amount in <img src={LogoSmall} alt="Walio" /> WALIO. Blockchain
              fees cannot be paid in WALIO
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
}

export default SectionPricing;
