import DashboardTable from "../../../../../Dashboard/DashboardTable/DashboardTable";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as DateIcon } from "../../../../../../assets/images/icons/date.svg";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../../../../../utils/date";
import { useFetchDiscounts } from "../../../../../../api/product/discount";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../utils/string";
import useTablePagination from "../../../../../../hooks/useTablePagination";
import { useNavigate } from "react-router-dom";
import { DISCOUNT_TYPES } from "../../../../../../utils/constants";

function DiscountsTable({ filters = {} }) {
  const navigate = useNavigate();
  const { refetch_discount } = useSelector((state) => state.discount);
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_discount, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchDiscountsSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        name: row.name,
        promo_code: row.promo_code,
        type: row.type,
        amount_gte: row.amount_gte,
        amount_off: row.amount_off,
        percent_off: row.percent_off,
        times_redeemed: row.times_redeemed,
        expires: row.end_date ? dateFormatter(row.end_date) : null,
        active: row.active,
        id: row.id,
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchDiscountsError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchDiscounts({
    onSuccess: onFetchDiscountsSuccess,
    onError: onFetchDiscountsError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedDiscount(row._data));
    navigate(`/dashboard/discounts/${row.id}`);
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 100,
        Cell: (cell) => {
          return <span className="font-semibold break-all">{cell.value}</span>;
        },
      },
      {
        Header: "Promo code",
        accessor: "promo_code",
        maxWidth: 60,
        Cell: (cell) => {
          return <span>{cell.value ?? "-"}</span>;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className="flex flex-wrap items-center gap-3">
              <span>{DISCOUNT_TYPES?.[cell.value]}</span>
            </div>
          );
        },
      },
      {
        Header: "Value",
        accessor: "value",
        maxWidth: 40,
        Cell: (cell) => {
          const _row = cell.row.original._data;
          return (
            <>
              {_row.amount_off && (
                <div className="app-customers__modal__earning">
                  <div className="text-light">
                    {currencySymbol(_row.currency)}
                    {currencyFormatter(_row.currency, _row.amount_off)}
                    {" off"}
                  </div>
                </div>
              )}
              {_row.percent_off && <span>{_row.percent_off}% off</span>}
            </>
          );
        },
      },
      {
        Header: "Redemptions",
        accessor: "times_redeemed",
        align: "right",
        maxWidth: 40,
        Cell: (cell) => {
          return <div className="w-full text-right">{cell.value ?? "-"}</div>;
        },
      },
      {
        Header: "Expires",
        accessor: "expires",
        maxWidth: 60,
        Cell: (cell) => {
          return cell.value ? (
            <div className="flex items-center">
              {cell.value && (
                <div>
                  <DateIcon className="mr-1.5" />
                </div>
              )}
              <span className="text-light">{cell.value}</span>
            </div>
          ) : (
            "-"
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Discounts"
        />
      </div>
    </>
  );
}

export default DiscountsTable;
