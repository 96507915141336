import ModalFullScreen from '../../../../../Common/ModalFullScreen/ModalFullScreen';
import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from '../../../../../../utils/string';
import { dateFormatter } from '../../../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import MetadataForm from '../../../../../Dashboard/MetadataForm/MetadataForm';
import DashboardButton from '../../../../../Common/Form/DashboardButton/DashboardButton';
import { ReactComponent as EditIcon } from '../../../../../../assets/images/icons/dashboard/edit.svg';
import {
  useFetchCustomerEarnings,
  useUpdateCustomer,
} from '../../../../../../api/customer/customer';
import {
  setRefetchCustomer,
  setSelectedCustomer,
  setShowCustomerForm,
} from '../../../../../../store/customer/customerSlice';
import { toast } from 'react-toastify';
import map from 'lodash/map';
import CustomerDetailsCreditNotesTable from './CustomerDetails/CustomerDetailsCreditNotesTable';
import CustomerDetailsSubscriptionsTable from './CustomerDetails/CustomerDetailsSubscriptionsTable';
import CustomerDetailsPendingItemsTable from './CustomerDetails/CustomerDetailsPendingItemsTable';
import CustomerDetailsPaymentsTable from './CustomerDetails/CustomerDetailsPaymentsTable';
import CustomerDetailsInvoicesTable from './CustomerDetails/CustomerDetailsInvoicesTable/CustomerDetailsInvoicesTable';
import CustomersModalAdjustBalance from '../CustomersModalAdjustBalance/CustomersModalAdjustBalance';
import CustomersModalBalance from '../CustomersModalBalance/CustomersModalBalance';
import CustomersModalCreateInvoiceItem from '../CustomersModalCreateInvoiceItem/CustomersModalCreateInvoiceItem';
import CopyToClipboardBoxID from '../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import CustomersModalInvoiceNow from '../CustomersModalInvoiceNow/CustomersModalInvoiceNow';
// import {ReactComponent as EditIcon} from "../../../../../../assets/images/icons/dashboard/edit.svg";
// import {ReactComponent as DeleteIcon} from "../../../../../../assets/images/icons/dashboard/delete.svg";

const MAX_DISPLAYED_EARNINGS = 3;

function CustomersModalDetails({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [showMoreEarnings, setShowMoreEarnings] =
    useState(false);
  const [showAdjustModal, setShowAdjustModal] =
    useState(false);
  const [showBalanceModal, setShowBalanceModal] =
    useState(false);
  const [
    showCreateInvoiceItemModal,
    setShowCreateInvoiceItemModal,
  ] = useState(false);
  const [showInvoiceNowModal, setInvoiceNowModal] =
    useState(false);
  const [
    selectedBalanceCurrency,
    setSelectedBalanceCurrency,
  ] = useState(null);
  const { selected_customer } = useSelector(
    (state) => state.customer
  );

  const {
    mutate: mutateUpdateCustomer,
    isLoading: isUpdateLoading,
  } = useUpdateCustomer(selected_customer?.id);
  const { data: earnings } = useFetchCustomerEarnings({
    id: selected_customer?.id,
  });

  const handleClose = () => {
    onClose && onClose();
  };

  const handleMetadataSave = (metadata) => {
    mutateUpdateCustomer(
      { metadata },
      {
        onSuccess: (data) => {
          dispatch(setSelectedCustomer(data.data));
          dispatch(setRefetchCustomer(Date.now()));
          toast.success('Metadata updated');
        },
      }
    );
  };

  const handleEditCustomer = () => {
    dispatch(setShowCustomerForm(true));
  };

  const handleAdjustBalanceClick = () => {
    setShowAdjustModal(true);
  };

  const handleCloseAdjustBalanceModal = () => {
    setShowAdjustModal(false);
  };

  const handleViewBalanceDetailsClick = () => {
    setShowBalanceModal(true);
  };

  const handleCloseViewBalanceDetailsModal = () => {
    setShowBalanceModal(false);
  };

  const handleCloseCreateInvoiceItemModal = () => {
    setShowCreateInvoiceItemModal(false);
  };

  const handleCloseInvoiceNowModal = () => {
    setInvoiceNowModal(false);
  };

  const handleBalanceClick = (currency) => {
    setSelectedBalanceCurrency(currency);
    setShowBalanceModal(true);
  };

  const getEarnings = useMemo(() => {
    if (!earnings) return [];

    return showMoreEarnings
      ? earnings?.earnings
      : earnings?.earnings.slice(0, MAX_DISPLAYED_EARNINGS);
  }, [earnings, showMoreEarnings]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Customer
            </div>
            <CopyToClipboardBoxID
              text={selected_customer?.id}
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div className='app-customers__modal__body'>
            <div className='app-customers__modal__body__left'>
              <div className='space-y-5'>
                <div
                  className='flex items-center'
                  style={{ height: '72px' }}>
                  <div>
                    <div className='app-modal-fullscreen__big-title'>
                      {selected_customer.name}
                    </div>
                    <div className='app-modal-fullscreen__subtitle'>
                      {selected_customer.email}
                    </div>
                  </div>
                </div>
                <div className='horizontal-divider' />
                <div className='grid grid-cols-2 gap-5 divide-x pb-7'>
                  <div className='pr-5'>
                    <div className='app-modal-fullscreen__label'>
                      CREATED
                    </div>
                    <div className='flex items-center'>
                      <div className='self-start'>
                        <DateIcon className='mr-1.5 mt-1' />
                      </div>
                      <span className='app-modal-fullscreen__value'>
                        {dateFormatter(
                          selected_customer.created_at,
                          true
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='pl-5'>
                    <div className='app-modal-fullscreen__label'>
                      EARNINGS
                    </div>
                    <div className='app-modal-fullscreen__label'>
                      {getEarnings.length < 1 && '-'}
                      {getEarnings.map((earning, index) => (
                        <div
                          key={index}
                          className='app-customers__modal__earning'>
                          {currencySymbol(earning.currency)}
                          {currencyFormatter(
                            earning.currency,
                            earning.amount
                          )}
                          <span>
                            {' '}
                            {earning.currency.toUpperCase()}
                          </span>
                        </div>
                      ))}
                    </div>
                    {earnings?.earnings.length >=
                      MAX_DISPLAYED_EARNINGS + 1 && (
                      <div
                        onClick={() => setShowMoreEarnings(!showMoreEarnings)}
                        className='app-modal-fullscreen__sm-link text-secondary'>
                        Show{' '}
                        {showMoreEarnings ? 'less' : 'more'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='space-y-1.5 pb-7'>
                <div className='flex justify-between'>
                  <div className='app-modal-fullscreen__title'>
                    Details
                  </div>
                  <DashboardButton
                    onClick={handleEditCustomer}
                    outline
                    color='light'
                    size='sm'
                    icon={<EditIcon />}>
                    Edit
                  </DashboardButton>
                </div>
                <div className='horizontal-divider' />
                <div className='space-y-1.5 pt-1.5'>
                  <div>
                    <div className='app-modal-fullscreen__label'>
                      ID
                    </div>
                    <div className='app-modal-fullscreen__value'>
                      {selected_customer.id}
                    </div>
                  </div>
                  <div>
                    <div className='app-modal-fullscreen__label'>
                      NAME
                    </div>
                    <div className='app-modal-fullscreen__value'>
                      {selected_customer.name}
                    </div>
                  </div>
                  <div>
                    <div className='app-modal-fullscreen__label'>
                      EMAIL
                    </div>
                    <div className='app-modal-fullscreen__value'>
                      {selected_customer.email}
                    </div>
                  </div>
                  <div>
                    <div className='app-modal-fullscreen__label'>
                      SHIPPING DETAILS
                    </div>
                    <div className='app-modal-fullscreen__value'>
                      {selected_customer.shipping && (
                        <>
                          <p>
                            {
                              selected_customer.shipping
                                .first_line
                            }
                            {selected_customer.shipping
                              .second_line
                              ? `, ${selected_customer.shipping.second_line}`
                              : ''}
                          </p>
                          <p>
                            {
                              selected_customer.shipping
                                .city
                            }
                            {selected_customer.shipping
                              .state
                              ? `, ${selected_customer.shipping.state}`
                              : ''}
                          </p>
                          <p>
                            {
                              selected_customer.shipping
                                .country
                            }
                            {selected_customer.shipping
                              .post_code
                              ? `, ${selected_customer.shipping.post_code}`
                              : ''}
                          </p>
                        </>
                      )}
                      {!selected_customer.shipping && (
                        <p>No shipping details</p>
                      )}
                    </div>
                  </div>
                  {/*{selected_customer.shipping &&
                  <div className="app-modal-fullscreen__sm-link text-secondary">Show less</div>
                  }*/}
                </div>
              </div>
              <div className='space-y-1.5'>
                <MetadataForm
                  data={selected_customer.metadata}
                  onSave={handleMetadataSave}
                  loading={isUpdateLoading}
                  key_cols='4'
                  value_cols='8'
                />
              </div>
            </div>
            <div className='app-customers__modal__body__right'>
              <div className='space-y-7 mt-2'>
                <div className='app-modal-fullscreen__tab app-modal-fullscreen__tab--active'>
                  Overview
                </div>
                <div className='space-y-1.5 pb-2'>
                  <div className='flex justify-between'>
                    <div
                      className='app-modal-fullscreen__title'
                      style={{ marginBottom: '1px' }}>
                      Customer Balances
                    </div>
                    <div className='flex gap-4'>
                      <div
                        onClick={
                          handleViewBalanceDetailsClick
                        }
                        className='app-modal-fullscreen__sm-link text-tertiary'>
                        View Details
                      </div>
                      <div
                        onClick={handleAdjustBalanceClick}
                        className='app-modal-fullscreen__sm-link text-primary'>
                        Adjust
                      </div>
                    </div>
                  </div>
                  <div className='horizontal-divider' />
                  <div className='app-customers__modal__balance gap-4 pt-5'>
                    {map(
                      selected_customer.balance,
                      (balance, currency) => (
                        <div
                          key={currency}
                          onClick={() =>
                            handleBalanceClick(currency)
                          }
                          className='app-customers__modal__balance__item'>
                          {currencyPrettier(
                            currency,
                            selected_customer.balance[
                              currency
                            ]
                          )}
                          <span>
                            {currency?.toUpperCase()}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <CustomerDetailsSubscriptionsTable />
                <CustomerDetailsPaymentsTable />
                <CustomerDetailsInvoicesTable />
                <CustomerDetailsPendingItemsTable
                  setShowCreateInvoiceItemModal={
                    setShowCreateInvoiceItemModal
                  }
                  setInvoiceNowModal={setInvoiceNowModal}
                />
                <CustomerDetailsCreditNotesTable />
              </div>
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showAdjustModal && (
        <CustomersModalAdjustBalance
          show={showAdjustModal}
          onClose={handleCloseAdjustBalanceModal}
          balances={Object.entries(selected_customer.balance).map(([key, value]) => ({
            currency: key,
            balance: value,
          }))}
        />
      )}
      {showBalanceModal && (
        <CustomersModalBalance
          show={showBalanceModal}
          onClose={handleCloseViewBalanceDetailsModal}
          currency={selectedBalanceCurrency}
        />
      )}
      {showCreateInvoiceItemModal && (
        <CustomersModalCreateInvoiceItem
          show={showCreateInvoiceItemModal}
          onClose={handleCloseCreateInvoiceItemModal}
        />
      )}
      {showInvoiceNowModal && (
        <CustomersModalInvoiceNow
          show={showInvoiceNowModal}
          onClose={handleCloseInvoiceNowModal}
        />
      )}
    </>
  );
}

export default CustomersModalDetails;
