import DashboardModalTable from 'components/Dashboard/DashboardModalTable/DashboardModalTable'
import useTablePagination from 'hooks/useTablePagination';
import React , {useState , useEffect, useMemo} from 'react'
import { useSelector } from 'react-redux';
import { dateFormatter } from 'utils/date';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";

export default function WebhooksAttemps() {
    const [data, setData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const { selected_event } = useSelector(
      (state) => state.event
    );
    const {
      canNextPage,
      canPreviousPage,
      dataCount,
      pageCount,
      requestQuery,
      limitQuery,
      onPreviousPage,
      onNextPage,
    } = useTablePagination(data, tableRows);
    const tableColumns = useMemo(
        () => [
          {
            Header: 'Endpoint URL',
            accessor: 'endpoint_url',
            Cell: (cell) => {
              const row = cell.row.original;
              return (
                <div className='flex items-start gap-2'>
                    
                </div>
              );
            },
          },
          {
            Header: 'Date',
            accessor: 'created_at',
            maxWidth: 70,
            Cell: (cell) => {
              return (
                <div className='flex items-center'>
                  <div>
                    <DateIcon className='mr-1.5' />
                  </div>
                  <span className='app-modal-fullscreen__value'>
                    {dateFormatter(cell.value, true)}
                  </span>
                </div>
              );
            },
          },
          {
            Header: 'Response',
            accessor: 'response',
            width: 50,
            Cell: (cell) => {
              const row = cell.row.original;
              return (
                <div>
                    
                </div>
              );
            },
          },
   
        ],
        []
      );
    
  return (
    <div className='space-y-1.5 pb-2 mt-10'>
    <div className='app-modal-fullscreen__title space-x-4'>
      <span>Webhooks Attempts</span>

    </div>
    <span className='text-light mt-4 '>
        Any attempts to send this event to your webhook endpoint
    </span>
    <DashboardModalTable
      columns={tableColumns}
      data={tableRows}
      pageCount={pageCount}
      onPreviousPage={onPreviousPage}
      onNextPage={onNextPage}
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      loading={false}
      count={dataCount}
      noRecordTag="attempts todo"
    />
  </div>
  )
}
