import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchWallets = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/wallets", { params })
    : api.sandbox.get("/wallets", { params });

export const useFetchWallets = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchWallets", params], () => fetchWallets(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};
