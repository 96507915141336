import Modal from "../../../../../Common/Modal/Modal";
import Button from "../../../../../Common/Form/Button/Button";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set, useFieldArray, useForm } from "react-hook-form";
import Stack from "../../../../../Common/Stack/Stack";
import {
  useSignMerchantUpload,
  useStoreProduct,
  useUpdateProduct,
} from "../../../../../../api/product/product";
import { toast } from "react-toastify";
import { setRefetchProduct } from "../../../../../../store/product/productSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import ProductsModalCreateName from "./ProductsModalCreateForm/ProductsModalCreateName";
import ProductsModalCreateDescription from "./ProductsModalCreateForm/ProductsModalCreateDescription";
import ProductsModalCreateUpload from "./ProductsModalCreateForm/ProductsModalCreateUpload";
import ReactTooltip from "react-tooltip";
import ProductsModalShippable from "./ProductsModalCreateForm/ProductsModalShippable";
import ProductsModalUrl from "./ProductsModalCreateForm/ProductsModalUrl";
import ProductsModalUnitLabel from "./ProductsModalCreateForm/ProductsModalUnitLabel";
import { FIAT_CURRENCIES_SELECT, PRICE_REGEX } from "../../../../../../utils/constants";
import {
  currencyConverter,
  sanitizeUnitAmount,
} from "../../../../../../utils/string";
import { validateTiers } from "../../../../../../utils/price_utils";
import { useStorePrice } from "../../../../../../api/product/price";
import { PRODUCT_MODEL_VALUES } from "../ProductsModalDetails/ProductDetails/ProductAddPriceModal";
import { AddPrice } from "./AddPrice/AddPrice";
import { useLocation, useNavigate } from "react-router-dom";

const MAX_PRICES = 5;
const CLOUDINARY_UPLOAD_URL = `https://api.cloudinary.com/v1_1/walio-cloud/image/upload`;
const CLOUDINARY_API_KEY = 213477317772516;

const formSchema = yup.object({
  name: yup.string().required().label("Name"),
  prices: yup.array().of(
    yup.object().shape({
      tiers: yup.array(),
      custom_unit_amount: yup.object(),
      amount: yup
        .string()
        .trim()
        // field is requierd when   tiers or costum_unit_amount  exist then fiels is not required otherwise it is required
        .when(["tiers", "custom_unit_amount"], {
          is: (tiers, custom_unit_amount) => {
            if (tiers || custom_unit_amount) {
              return false;
            }
            return true;
          },
          then: yup
            .string()
            .required()
            .matches(
              PRICE_REGEX,
              "Amount must be positive and not exceed 14 decimal places"
            ),
        })
        .label("Price"),
      recurring: yup
        .object()
        .shape({
          interval: yup.string().label("Billing period interval"),
          interval_count: yup
            .number()
            .integer()
            .nullable()
            .min(1)
            .label("Billing period interval count")
            .transform((v, o) => (o === "" || Number.isNaN(o) ? null : v))
            .typeError("Billing period must be an integer")
            .when("interval", (interval, schema) => {
              let _maxInterval = 365;
              if (interval === "week") _maxInterval = 52;
              if (interval === "month") _maxInterval = 12;
              if (interval === "year") _maxInterval = 1;

              return schema.max(_maxInterval);
            }),
        })
        .when("type", (type, schema) => {
          if (type === "recurring") return schema.required();
          return schema.notRequired();
        }),
    })
  ),
});

function ProductsModalCreate({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const [productId, setProductId] = useState(null);
  const [images, setImages] = useState([]);
  const [tierErrors, setTierErrors] = useState([]);
  const [files, setFiles] = useState([]);
  const { current_account } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { edit_product } = useSelector((state) => state.product);


  useEffect(() => {

    return () => {
      navigate(location.pathname.replace("/create", "").replace("/edit", ""));
    };

  }, []);
  const settierError = (errors) => {
    var unit_amount = document.getElementsByClassName("editable-cell-input");
    var last_unit = document.getElementsByClassName("editable-cell-input-last");
    var errorsElm = document.getElementsByClassName(
      "editable-cell-input-error"
    );
    var errorsElmLast = document.getElementsByClassName(
      "editable-cell-input-last-error"
    );
    var f = document.getElementsByClassName("editable-cell-input-flat");

    for (let i = 0; i < errors.length; i++) {
      const element = errors[i];
      if (element.field === "unit_amount") {
        unit_amount[element.index].classList.add("warning");
        errorsElm[element.index].classList.add("show");
        errorsElm[element.index].innerHTML = element.message;
      } else if (element.field === "last_unit") {
        last_unit[element.index].classList.add("warning");
        errorsElmLast[element.index].classList.add("show");
        errorsElmLast[element.index].innerHTML = element.message;
      }
    }
  };
  const {
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    // getValues(),
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: edit_product?.name ?? "",
      description: edit_product?.description ?? "",
      url: edit_product?.url ?? "",
      image_url: edit_product?.image_url ?? [],
      prices: !edit_product
        ? [
          {
            amount: "",
            currency: current_account?.settings?.reporting_currency ?? "usd",
            tax_behaviour: "unspecified",
            billing_scheme: "per_unit",
            transform_quantity: {
              divide_by: "2",
              round: "up",
            },
            custom_unit_amount: {
              maximum: "",
              minimum: "",
              preset: "",
            },
            tiers: [
              {
                first_unit: 1,
                last_unit: 1,
                unit_amount: "",
                flat_amount: "",
                //currency: defaultCurrency,
              },
              {
                first_unit: 2,
                last_unit: "inf",
                unit_amount: "",
                flat_amount: "",
                //currency: defaultCurrency,
              },
            ],
            tiers_mode: "volume",
            type: "recurring",
            billing_period: "daily",
            recurring: {
              aggregate_usage: "sum",
              interval: "day",
              interval_count: "1",
            },
            metered: false,
            mode: PRODUCT_MODEL_VALUES.STANDARD,
            description: "",
            metadata: null,
            errors: [],
          },
        ]
        : [],
    },
    resolver: yupResolver(formSchema),
  });
  useEffect(() => { }, [errors]);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "prices",
  });

  const prices = watch("prices");

  const createPrices = async () => {
    for (const price of prices) {
      const data = {
        currency: price.currency,
        type: price.type,
        product: productId,
      };

      if (price?.mode?.id === PRODUCT_MODEL_VALUES.CUSTOMER_CHOOSES.id) {
        let customUnitAmount = {
          enabled: true, // custom_unit_amount.enabled: true
          // minimum: price.custom_unit_amount.minimum ? Math.trunc(
          //   Number(currencyConverter(
          //     price.currency,
          //     price.custom_unit_amount.minimum,
          //     false
          //   )) 
          // ) : 1,
        };

        if (price.custom_unit_amount.minimum) {
          customUnitAmount.minimum = Math.trunc(
            Number(currencyConverter(
              price.currency,
              price.custom_unit_amount.minimum,
              false
            ))
          );
        }

        if (price.custom_unit_amount.maximum)
          customUnitAmount.maximum = Math.trunc(
            Number(
              currencyConverter(
                price.currency,
                price.custom_unit_amount.maximum,
                false
              )
            )
          );
        if (price.custom_unit_amount.preset)
          customUnitAmount.preset = Math.trunc(
            Number(
              currencyConverter(
                price.currency,
                price.custom_unit_amount.preset,
                false
              )
            )
          );

        data.custom_unit_amount = customUnitAmount;
      } else {
        if (price?.mode?.id === PRODUCT_MODEL_VALUES.TIERED.id) {
          let tiersData = [];

          for (let i = 0; i < price.tiers.length; i++) {
            let tierObj = {
              up_to:
                price.tiers[i].last_unit !== "inf"
                  ? Number(price.tiers[i].last_unit)
                  : price.tiers[i].last_unit,
            };
            if (price.tiers[i].unit_amount) {
              const unitAmount = currencyConverter(
                price.currency,
                price.tiers[i].unit_amount,
                true
              );

              if (unitAmount.includes(".")) {
                tierObj.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
              } else tierObj.unit_amount = Number(unitAmount);
            }
            if (price.tiers[i].flat_amount) {
              const flatAmount = currencyConverter(
                price.currency,
                price.tiers[i].flat_amount,
                true
              );

              if (flatAmount.includes(".")) {
                tierObj.flat_amount_decimal = sanitizeUnitAmount(flatAmount);
              } else tierObj.flat_amount = Number(flatAmount);
            }
            tiersData.push(tierObj);
          }

          data.tiers = tiersData;
          data.tiers_mode = price.tiers_mode;
          data.billing_scheme = "tiered";
        } else {
          const unitAmount = currencyConverter(
            price.currency,
            price.amount === "" ? 0 : price.amount,
            true
          );
          if (unitAmount.includes(".")) {
            // it is a decimal value, needs to be stored on 'unit_amount_decimal'
            data.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
          } else data.unit_amount = Number(unitAmount);

          if (price?.mode?.id === PRODUCT_MODEL_VALUES.PACKAGE.id) {
            data.transform_quantity = {
              divide_by: Number(price.transform_quantity.divide_by),
              round: price.transform_quantity.round,
            };
          }
        }

        if (price.type === "recurring") {
          if (price.billing_period === "daily") {
            data.recurring = {
              interval: "day",
              interval_count: 1,
              usage_type: "licenced",
            };
          }
          if (price.billing_period === "weekly") {
            data.recurring = {
              interval: "week",
              interval_count: 1,
              usage_type: "licenced",
            };
          }
          if (price.billing_period === "monthly") {
            data.recurring = {
              interval: "month",
              interval_count: 1,
              usage_type: "licenced",
            };
          }
          if (price.billing_period === "three_monthly") {
            data.recurring = {
              interval: "month",
              interval_count: 3,
              usage_type: "licenced",
            };
          }
          if (price.billing_period === "six_monthly") {
            data.recurring = {
              interval: "month",
              interval_count: 6,
              usage_type: "licenced",
            };
          }
          if (price.billing_period === "yearly") {
            data.recurring = {
              interval: "year",
              interval_count: 1,
              usage_type: "licenced",
            };
          }
          if (price.billing_period === "custom") {
            data.recurring = {
              interval: price.recurring.interval,
              interval_count: Number(price.recurring.interval_count),
              usage_type: "licenced",
            };
          }

          if (price.metered) {
            data.recurring.aggregate_usage = price.recurring.aggregate_usage;
            data.recurring.usage_type = "metered";
          }
        }
      }

      await mutateStorePrice(data, {
        onError: (error) => {
          handleClose();
          dispatch(setRefetchProduct(Date.now()));
          toast.error(error.response?.data.error.message);
        },
      });
    }

    handleClose();
    dispatch(setRefetchProduct(Date.now()));
    toast.success(
      `Product successfully ${edit_product?.id ? "updated" : "created"}`
    );
  };

  const onSignMerchantUploadSuccess = async (data) => {
    const imagesURL = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("access_mode", data.access_mode);
      formData.append("folder", data.folder);
      formData.append("format", data.format);
      formData.append("signature", data.signature);
      formData.append("timestamp", data.timestamp);
      formData.append("type", data.type);
      formData.append("api_key", CLOUDINARY_API_KEY);

      await axios.post(CLOUDINARY_UPLOAD_URL, formData).then((response) => {
        imagesURL.push(response.data.secure_url);
      });
    }

    const _images = [...images, ...imagesURL];
    mutateUpdateProduct(
      {
        image_url: _images.length ? _images : "",
      },
      {
        onSuccess: () => {
          if (edit_product?.id) {
            handleClose();
            dispatch(setRefetchProduct(Date.now()));
            toast.success(
              `Product successfully ${edit_product?.id ? "updated" : "created"}`
            );
            return;
          }
          createPrices();
        },
        onError: () => {
          handleClose();
          dispatch(setRefetchProduct(Date.now()));
        },
      }
    );
  };

  const { mutateAsync: mutateStorePrice } = useStorePrice();

  const {
    mutate: mutateStoreProduct,
    isSuccess: isStoreSuccess,
    isLoading: isStoreLoading,
  } = useStoreProduct();

  const {
    mutate: mutateUpdateProduct,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateProduct(productId ?? edit_product?.id);

  const { refetch: refetchSignature } = useSignMerchantUpload({
    onSuccess: onSignMerchantUploadSuccess,
    enabled: false,
    params: {
      type: "product",
      id: productId,
    },
  });

  useEffect(() => {
    files.forEach((file) => !file.preview && URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (productId) refetchSignature();
  }, [productId, refetchSignature]);

  useEffect(() => {
    setImages(edit_product?.image_url ?? []);
  }, [edit_product]);

  const handleClose = () => {
    onClose && onClose();
  };

  const onSubmit = (formData) => {
    // This is for updating an exisiting product:
    if (edit_product?.id) {
      mutateUpdateProduct(
        {
          description: formData.description,
          unit_label: formData.unit_label,
          url: formData.url,
          shippable: formData.shippable,
        },
        {
          onSuccess: (data) => {
            setProductId(data.data.id);
          },
          onError: (error) => {
            toast.error(error.response?.data.error.message);
          },
        }
      );
      return;
    }
    // Otherwise this is for newly created products:
    // First check if there are any prices and if there are errors in them.
    var correct = true;
    for (const price of prices) {
      if (price?.mode?.id === PRODUCT_MODEL_VALUES.TIERED.id) {
        var errors = validateTiers(price.tiers);
        if (errors.length > 0) {
          correct = false;
          settierError(errors);
          update(prices.indexOf(price), { ...price, errors: errors });
        } else {
          update(prices.indexOf(price), { ...price, errors: [] });
        }
      }
    }
    if (!correct) return;

    const _data = { name: formData.name };
    if (formData.description) _data.description = formData.description;
    if (formData.unit_label) _data.unit_label = formData.unit_label;
    if (formData.url) _data.url = formData.url;
    if (formData.shippable) _data.shippable = formData.shippable;

    mutateStoreProduct(_data, {
      onSuccess: (data) => {
        setProductId(data.data.id);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const imagesLength = useMemo(() => {
    return files.length + images.length;
  }, [files, images]);

  const [expanded, setexpanded] = useState([true]);

  const handleAddPriceClick = () => {
    if (prices.length >= MAX_PRICES) return;

    var test = expanded;
    for (var i = 0; i < test.length; i++) test[i] = false;
    append({
      amount: "",
      currency: current_account?.settings?.reporting_currency ?? "usd",
      tax_behaviour: "unspecified",
      billing_scheme: "per_unit",
      transform_quantity: {
        divide_by: "2",
        round: "up",
      },
      custom_unit_amount: {
        maximum: "",
        minimum: "",
        preset: "",
      },
      tiers: [
        {
          first_unit: 1,
          last_unit: 1,
          unit_amount: "",
          flat_amount: "",
          //currency: defaultCurrency,
        },
        {
          first_unit: 2,
          last_unit: "inf",
          unit_amount: "",
          flat_amount: "",
          //currency: defaultCurrency,
        },
      ],
      tiers_mode: "volume",
      type: "recurring",
      billing_period: "daily",
      recurring: {
        aggregate_usage: "sum",
        interval: "day",
        interval_count: "1",
      },
      metered: false,
      mode: PRODUCT_MODEL_VALUES.STANDARD,
      description: "",
      metadata: null,
      errors: [],
    });
    // Set this to be expanded:
    test.push(true);
    setexpanded([...test]);
  };

  const handleDeletePrice = (id) => {
    console.log("Index", id);
    if (prices.length > 0) {
      remove(id);
      // remove from expanded list:
      var test = expanded;
      console.log("TEST:", test);
      test.splice(id, 1);
      console.log("TEST after splice:", test);
      setexpanded([...test]);
    }
  };

  const handleDuplicatePrice = (id) => {
    if (prices.length < MAX_PRICES) {
      // Add to expanded:
      var test = expanded;
      for (var i = 0; i < test.length; i++) test[i] = false;
      append(prices[id]);
      test.push(true);
      setexpanded([...test]);
    }
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      size="lg"
      footerBorder={true}
      bodyScrollable={true}
    >
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {edit_product?.id ? "Edit" : "Create a new"} product
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form
          id="create-product"
          onSubmit={handleSubmit(onSubmit)}
        //  className='overflow-y-scroll'
        >
          <Stack columns="1" gap="4">
            <div className="app-customers__modal__form-title">Details</div>
            <div className="grid grid-cols-2 gap-x-4">
              <Stack columns="1" gap="4" className="content-start">
                <ProductsModalCreateName
                  register={register}
                  error={fieldError("name")}
                />
                <ProductsModalCreateDescription register={register} />
                {/* <Stack
                  columns='2'
                  gap='4'
                  className='content-start'>
                </Stack> */}
                <ProductsModalUnitLabel
                  register={register}
                  error={fieldError("unit_label")}
                />
                <ProductsModalUrl
                  register={register}
                  error={fieldError("url")}
                />
                <ProductsModalShippable
                  register={register}
                  error={fieldError("shippable")}
                />
              </Stack>
              <ProductsModalCreateUpload
                setFiles={setFiles}
                files={files}
                setImages={setImages}
                images={images}
                imagesLength={imagesLength}
              />
            </div>
            {!edit_product && (
              <>
                <div className="app-customers__modal__form-title mt-3">
                  Price information
                </div>
                {prices.map((price, index) => {
                  return (
                    <AddPrice
                      appendData={append}
                      prices={prices}
                      id={index}
                      key={index}
                      price={price}
                      registerData={register}
                      fieldError={fieldError}
                      expanded={expanded}
                      setexpanded={(e) => setexpanded(e)}
                      updatedata={update}
                      removeData={remove}
                      errorss={errors}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      duplicatePrice={handleDuplicatePrice}
                      deletePrice={handleDeletePrice}
                    />
                  );
                })}
                {prices.length < MAX_PRICES && (
                  <div
                    className="app-tax_rates__button-link"
                    onClick={handleAddPriceClick}
                  >
                    {prices.length >= 1
                      ? "+ Add another price"
                      : "+ Add a price"}
                  </div>
                )}
              </>
            )}
          </Stack>
        </form>
        <ReactTooltip
          id="product-modal-create"
          multiline
          place="right"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        />
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button
            loading={isStoreLoading || isUpdateLoading}
            disabled={isStoreLoading || isUpdateLoading}
            form="create-product"
            type="submit"
          // onClick={(e)=>console.log("prices: ", prices)}
          >
            {edit_product?.id ? "Update" : "Create"}
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default ProductsModalCreate;
