import api from "../../utils/axios";
import {useMutation} from "react-query";

const login = (data) => api.live.post("/login", data);
export const useAuthLogin = () => {
  return useMutation("login", login);
}

const register = (data) => api.live.post("/register", data);
export const useAuthRegister = () => {
  return useMutation("register", register);
}

const verifyEmail = (token) => api.live.post("/verifyEmail", { token });
export const useAuthVerifyEmail = () => {
  return useMutation("verifyEmail", verifyEmail);
}

const resendConfirmationEmail = (email) => api.live.post("/resendEmail", { email });
export const useAuthResendEmail = () => {
  return useMutation("resendEmail", resendConfirmationEmail);
}

const forgotPassword = (email) => api.live.post("/forgotPassword", { email });
export const useForgotPassword = () => {
  return useMutation("forgotPassword", forgotPassword);
}

const resetPassword = (data) => api.live.post("/resetPassword", data);
export const useResetPassword = () => {
  return useMutation("resetPassword", resetPassword);
}

const logout = () => api.live.post("/logout");
export const useUserLogout = () => {
  return useMutation("useLogout", logout);
}
