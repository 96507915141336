import "./SelectMerchants.scss";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import MerchantIcon from "../../../assets/images/icons/dashboard/icon_merchant.svg";
import PlusIcon from "../../../assets/images/icons/dashboard/plus.svg";
import {useRef, useState} from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import {useSelector} from "react-redux";
import useSwitchMerchantAccount from "../../../hooks/useMerchantAccount";

function SelectMerchants() {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const { current_account, accounts } = useSelector((state) => state.auth);
  useOutsideClick(selectRef, () => setOpen(false));

  const { switchAccount } = useSwitchMerchantAccount();

  const handleOptionClick = (option) => {
    if (current_account.id !== option.id) {
      switchAccount(option.id);
    }
    setOpen(false);
  }

  return (
    <div ref={selectRef} className="select-merchant">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-merchant__input",
          open && "select-merchant__input--active"
        )}
      >
        <div className="select-merchant__input__value">
          <img
            className="select-merchant__input__icon"
            src={current_account.settings.branding.icon ?? MerchantIcon}
            alt=""
          />
          <span>{current_account.name}</span>
        </div>
        <img className="select-merchant__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open &&
        <div className="select-merchant__options">
          {accounts.map((account, index) =>
            <div
              key={index}
              onClick={() => handleOptionClick(account)}
              className={clsx(
                "select-merchant__options__item",
                account.id === current_account.id && "select-merchant__options__item--active"
              )}
            >
              <img
                className="select-merchant__options__item__icon"
                src={account.icon ?? MerchantIcon}
                alt=""
              />
              <span>{account.name}</span>
            </div>
          )}
          <div className="select-merchant__add">
            <img className="select-merchant__add__icon" src={PlusIcon} alt=""/>
            <span>Add new account</span>
          </div>
        </div>
      }
    </div>
  )
}

export default SelectMerchants;