import Stack from "../../../Common/Stack/Stack";
import "./SectionHow.scss";
import {ReactComponent as Step1Icon} from "../../../../assets/images/sections/how/step-1.svg";
import {ReactComponent as Step2Icon} from "../../../../assets/images/sections/how/step-2.svg";
import {ReactComponent as Step3Icon} from "../../../../assets/images/sections/how/step-3.svg";
import {ReactComponent as Step4Icon} from "../../../../assets/images/sections/how/step-4.svg";

function SectionHow() {
  return (
    <div className="app-container section-how">
      <h3 className="h3 section-how__title">How Walio works?</h3>
      <Stack columns="1" gap="0" className="lg:grid-cols-4">
        <div className="section-how__step__container">
          <div className="section-how__step">
            <div className="section-how__step__number section-how__step__number--dark">
              <span>01</span>
            </div>
            <div className="section-how__step__line" />
          </div>
          <div>
            <Step1Icon className="section-how__icon" />
            <p className="text-small section-how__text">Create a payment for your products or services. </p>
          </div>
        </div>
        <div className="section-how__step__container">
          <div className="section-how__step">
            <div className="section-how__step__number section-how__step__number--green">
              <span>02</span>
            </div>
            <div className="section-how__step__line" />
          </div>
          <div>
            <Step2Icon className="section-how__icon" />
            <p className="text-small section-how__text">Send the payment link, or email an invoice to your customer.</p>
          </div>
        </div>
        <div className="section-how__step__container">
          <div className="section-how__step">
            <div className="section-how__step__number section-how__step__number--dark">
              <span>03</span>
            </div>
            <div className="section-how__step__line" />
          </div>
          <div>
            <Step3Icon className="section-how__icon" />
            <p className="text-small section-how__text">
              We generate the payment options for your customer in various cryptocurrencies and blockchain networks.
            </p>
          </div>
        </div>
        <div className="section-how__step__container">
          <div className="section-how__step">
            <div className="section-how__step__number section-how__step__number--green">
              <span>04</span>
            </div>
            <div className="section-how__step__line" />
          </div>
          <div>
            <Step4Icon className="section-how__icon" />
            <p className="text-small section-how__text">You receive your payment!</p>
          </div>
        </div>
      </Stack>
    </div>
  )
}

export default SectionHow;
