import DashboardButton from "../../../../../../../Common/Form/DashboardButton/DashboardButton";
import {ReactComponent as PlusIcon} from "../../../../../../../../assets/images/icons/dashboard/plus.svg";
import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import useTablePagination from "../../../../../../../../hooks/useTablePagination";
import DashboardModalTable from "../../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import {useFetchCreditNotes} from "../../../../../../../../api/customer/credit_note";
import {
  currencyFormatter,
  currencySymbol,
  snakeCaseToTitleCase
} from "../../../../../../../../utils/string";
import LabelTag from "../../../../../../../Common/LabelTag/LabelTag";
import clsx from "clsx";

function InvoiceDetailsCreditNotesTable({ enabled = false }) {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_invoice } = useSelector((state) => state.invoice);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage
  } = useTablePagination(data, tableRows);

  const onFetchCreditNotesSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  }

  const {
    isLoading: isFetchingData,
  } = useFetchCreditNotes({
    onSuccess: onFetchCreditNotesSuccess,
    onError: () => null,
    enabled,
    params: {
      invoice: selected_invoice?.id,
      customer: selected_invoice?.customer?.id,
      limit: limitQuery,
      ...requestQuery
    }
  });

  const tableColumns = useMemo(() => [
    {
      Header: "ID",
      accessor: "credit_note_number",
      minWidth: 100,
      Cell: (cell) => {
        return (
          <div className="app-modal-fullscreen__value">{cell.value}</div>
        )
      }
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: (cell) => {
        const row = cell.row.original;
        return (
          <div 
            className="app-modal-fullscreen__table__amount"
            style={{ fontSize: "1.2rem" }}
          >
            <div className="text-light">
              {currencySymbol(row.currency)}
              {currencyFormatter(row.currency, row.amount)}
            </div>
          </div>
        )
      }
    },
    {
      Header: "Reason",
      accessor: "reason",
      Cell: (cell) => {
        return (
          <div className="app-modal-fullscreen__value">
            {snakeCaseToTitleCase(cell.value)}
          </div>
        )
      }
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (cell) => {
        return (
          <div className="app-modal-fullscreen__value">
            {snakeCaseToTitleCase(cell.value)}
          </div>
        )
      }
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 50,
      Cell: (cell) => {
        return (
          <LabelTag
            color={clsx(
              cell.value === "paid" && "primary",
              cell.value === "void" && "warning",
            )}
          >
            {cell.value}
          </LabelTag>
        )
      }
    },
  ], []);

  return (
    <div className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Credit Notes</div>
        <div className="flex gap-4">
          <DashboardButton outline color="primary" size="sm" icon={<PlusIcon />}>
            <span className="text-dark">Create Credit Note</span>
          </DashboardButton>
        </div>
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Credit Notes"
      />
    </div>
  )
}

export default InvoiceDetailsCreditNotesTable;
