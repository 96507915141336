import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchCustomers = (params) => appStore.getState().app.livemode
  ? api.live.get("/customers", { params })
  : api.sandbox.get("/customers", { params });

export const useFetchCustomers = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchCustomers", params],
    () => fetchCustomers(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchCustomer = (id, params) => appStore.getState().app.livemode
  ? api.live.get(`/customers/${id}`, { params })
  : api.sandbox.get(`/customers/${id}`, { params });

export const useFetchCustomer = ({onSuccess, onError, enabled, id, params = null}) => {
  return useQuery(
    ["fetchCustomer", id],
    () => fetchCustomer(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storeCustomer = (data) => appStore.getState().app.livemode
  ? api.live.post("/customers", data)
  : api.sandbox.post("/customers", data);

export const useStoreCustomer = () => {
  return useMutation(
    "storeCustomer",
    storeCustomer
  );
}

const updateCustomer = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/customers/${id}`, data)
  : api.sandbox.patch(`/customers/${id}`, data);

export const useUpdateCustomer = (id) => {
  return useMutation(
    ["updateCustomer", id],
    (data) => updateCustomer(id, data)
  );
}

const fetchCustomerEarnings = (id) => appStore.getState().app.livemode
  ? api.live.get(`/customers/${id}/earnings`)
  : api.sandbox.get(`/customers/${id}/earnings`);

export const useFetchCustomerEarnings = ({onSuccess, onError, enabled, id}) => {
  return useQuery(
    ["fetchCustomerEarnings", id],
    () => fetchCustomerEarnings(id),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
