import Label from "../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../Common/Form/Input/Input";
import React from "react";
import InfoIconImage from "../../../../../../../../assets/images/icons/info.svg";

function DiscountsModalCreateName({ register, error = "" }) {
  return (
    <div>
      <Label
        title="Name"
        required={true}
        error={error}
        icon={InfoIconImage}
        tooltipFor="discount-modal-create"
        tooltipHtml={true}
        tooltip={`
          The name of the Discount will 
          appear on Invoices and receipts
        `}
      />
      <Input
        {...register("name")}
        block
        type="text"
        error={error}
      />
    </div>
  )
}

export default DiscountsModalCreateName;