import { data } from "autoprefixer";
import moment from "moment-timezone";
import { currencyPrettier } from "./string.js";
export const periodDateFormatter = (startDate, endDate) => {
	if (startDate === endDate) return null;
	else {
		const start = moment.unix(startDate);
		const end = moment.unix(endDate);
		
		return `${start.format(`DD MMM${start.year() !== end.year() ? ", YYYY" : ""}`)} - ${end.format('DD MMM, YYYY')}`;
	}
}

/**
 * Generates the data used to display an invoice's items.
 * @param {Array} invoiceLineItems An array of Invoice Line Item objects for the `invoice` with their `price` `product` `discount_amounts.discount` `tax_amounts.tax_rate` `tax_rates` expanded.
 * @returns {Array} Returns the invoice line items data array.
 */

export const generateLineItemsTableRowaData = (LineItemsData = []) => {
	var tableRows = [];

	for (const item  of LineItemsData ) {
		// Only add period row if an item has 'period' defined:
		if (item.period) tableRows.push({
			type : "period",
			data: item.period,
		});
		// Then add item and sub item rows:
		for (const data of item.data)	{
			tableRows.push({
				type : "item",
				data: data,
			});
			if (data.sub_items.length > 0) {
				for (const subItem of data.sub_items) {
					tableRows.push({
						type : "sub_item",
						data: subItem,
					});
				}
			}
		}
	}

	return tableRows;
}
export function generateInvoiceLineItemData(invoiceLineItems = []) {
	try {
		let lineItems = invoiceLineItems;
		let lineItemGroup = [];

		// 1. Group items based on 'invoice_item' or 'subscription_item' (to get tiered items)
		for (const item of lineItems) {
			if (item.tier_label) {
				if (item.invoice_item) {
					// See if there are any other items with the same 'invoice_item' (as it may be a tiered charge)
					const sameItemIndex = lineItemGroup.findIndex((obj) => obj.invoice_item === item.invoice_item);
					if (sameItemIndex >= 0) {
						lineItemGroup[sameItemIndex].data.push(item);
					} else lineItemGroup.push({ invoice_item: item.invoice_item, type: 'tier', data: [item], period: item.period});
				} else {
					const sameItemIndex = lineItemGroup.findIndex(obj => obj.subscription_item === item.subscription_item);
					if (sameItemIndex >= 0) {
						lineItemGroup[sameItemIndex].data.push(item);
					} else lineItemGroup.push({ subscription_item: item.subscription_item, type: 'tier', data: [item], period: item.period});
				}
			} else {
				lineItemGroup.push({ type: 'standard', data: [item], period: item.period});
			}
		}

		// 2. Sort the items by their period:
		// Sort by period start earliest to latest;
		// If their period start is the same, then sort by their period end date;
		lineItemGroup = lineItemGroup.sort((a, b) => {
			if (a.period.start === b.period.start) return b.period.end - a.period.end;
			else return a.period.start - b.period.start;
		});

		// 3. Now group items into time periods:
		// This groups items by time period. E.g if an item is for a Subscription that is from 01 January to 31 January, if there is
		// another item whos billing time is for 07 January, it will fall into this time period.
		let groupedItems = [];

		for (const group of lineItemGroup) {
			let lastPeriodInArray = groupedItems[groupedItems.length - 1];
			if (lastPeriodInArray) {
				// Check if the last item in the period group has a end and start time;
				if (lastPeriodInArray.period) {
					if (group.period.start <= lastPeriodInArray.period.end) {
						groupedItems[groupedItems.length - 1].items.push({ type: group.type, data: group.data });

						// update the periods end date if needed:
						if (group.period.end > lastPeriodInArray.period.end) {
							groupedItems[groupedItems.length - 1].period.end = group.period.end;
						}
					} else {
						// create a new period group:
						groupedItems.push({ 
							period: group.period.end !== group.period.start ? group.period : null, 
							items: [
								{ type: group.type, data: group.data }
							]
						});
					}
				} else {
					// Last item has no period times;
					// See if we should add to it, or need to create a new period:
					if (group.period.end !== group.period.start) {
						// create new group:
						groupedItems.push({ 
							period: group.period, 
							items: [
								{ type: group.type, data: group.data }
							]
						});
					} else {
						// we can add to the existing group:
						groupedItems[groupedItems.length - 1].items.push({ type: group.type, data: group.data });
					}
				}
			} else {
				// This is the first item in the grouped array;
				groupedItems.push({ 
					period: group.period.end !== group.period.start ? group.period : null, 
					items: [
						{ type: group.type, data: group.data }
					]
				});
			}
		}

		// EXAMPLE:
		// const groupedItems = [
		// 	{
		// 		period: null, 
		// 		items: [
		// 			{type: 'tier', data: []},
		// 			{type: 'standard', data: []}
		// 		]
		// 	},
		// 	{
		// 		period: {start, end},
		// 		items: [
		// 			{type: 'tier', data: []},
		// 			{type: 'standard', data: []}
		// 		]
		// 	}
		// ]
	

		// 4. Now build the item data:
		let items = [];
		// EXAMPLE: {
		// 	name: '',
		// 	quantity: 1,
		// 	amount: '$20.00',
		//	discounts: [ {name, amount}]
		// 	sub_items: [
		// 		{name, quantity, amount}
		// 	]
		// }

		for (const periodGroup of groupedItems) {
			let periodObj = {
				period: periodGroup.period ? periodDateFormatter(periodGroup.period.start, periodGroup.period.end) : null,
				data: []
			};

			for (const item of periodGroup.items) {
				let itemData = item.data;
				const firstItem = itemData[0];

				let itemName = `${firstItem.product.name}${firstItem.product.unit_label ? [' (per ' + firstItem.product.unit_label + ')'] : ''}`;
				if (firstItem.proration && firstItem.description) itemName = firstItem.description;
				// Build core item object:
				let itemObj = {
					amount: item.type === 'standard' ? currencyPrettier(firstItem.currency, firstItem.amount) : null,
					discounts: [],
					image_url: firstItem.product.image_url.length > 0 ? firstItem.product.image_url[0] : null,
					name: itemName,
					quantity: item.type === 'standard' ? firstItem.quantity : null,
					sub_items: [],
					unit_amount: item.type === 'standard' ? currencyPrettier(firstItem.price.currency, firstItem.price.unit_amount_decimal) : null,
					unit_bucket_size: firstItem.price.transform_quantity ? firstItem.price.transform_quantity.divide_by : null
				}

				if (item.type === 'tier') {
					// It is a tiered item;
					// Sort the items based on:
					// - The tier value (lowest tier first)
					// - If they are the same tier, then based on their 'quantity' (highest first)
					itemData = itemData.sort((a, b) => {
						// sort same tier items (highest quantity to lowest);
						// otherwise sort tiers lowest to highest.
						if (a.tier === b.tier) return b.quantity - a.quantity;
						else return a.tier - b.tier;
					});
	
					// sum the total quantities;
					itemObj.quantity = itemData.reduce((acc, obj) => {return acc + obj.quantity}, 0);

					// compute each sub item object:
					for (const subItem of itemData) {
						let subItemObj = {
							amount: currencyPrettier(subItem.currency, subItem.amount),
							discounts: [],
							name: subItem.tier_label,
							quantity: subItem.quantity,
							unit_amount: subItem.quantity > 0 ? currencyPrettier(subItem.currency, subItem.price.tiers[subItem.tier - 1].unit_amount_decimal) : null,
						}

						if (subItem.discount_amounts.length > 0) {
							for (const da of subItem.discount_amounts) {
								if (da.source === 'item') {
									subItemObj.discounts.push({
										name: `${da.discount.name} (${typeof da.discount.percent_off === 'number' ? [da.discount.percent_off + '% off'] : [currencyPrettier(subItem.currency, da.discount.amount_off) + ' off']})`,
										amount: `-${currencyPrettier(subItem.currency, da.amount)}`
									});
								}
							}
						}

						// add sub items to parent object;
						itemObj.sub_items.push(subItemObj)
					}
				} else {
					// It is a standard item;
					// Check if the item has any discounts:
					if (firstItem.discount_amounts.length > 0) {
						for (const da of firstItem.discount_amounts) {
							if (da.source === 'item') {
								itemObj.discounts.push({
									name: `${da.discount.name} (${typeof da.discount.percent_off === 'number' ? [da.discount.percent_off + '% off'] : [currencyPrettier(firstItem.currency, da.discount.amount_off) + ' off']})`,
									amount: `-${currencyPrettier(firstItem.currency, da.amount)}`
								});
							}
						}
					}
				}
				
				periodObj.data.push(itemObj);
			}

			items.push(periodObj);
		}
		
		return items;
	} catch (e) {
		throw e;
	}
};

const testData = [
	{
			"id": "ili_1a1sAkfJvB21hUbp2BPzRXXuosy",
			"object": "invoice_line_item",
			"amount": 18000,
			"amount_excluding_tax": 14024,
			"computed_amount": 16128,
			"created_at": 1668536464,
			"currency": "gbp",
			"description": "Consultation Meeting (per 30 minutes)",
			"discount_amounts": [
					{
							"amount": 1872,
							"discount": {
									"id": "discount_1a1fP1jV7zxnPTJg6igUDI9Gfyz",
									"object": "discount",
									"active": true,
									"amount_gte": null,
									"amount_off": null,
									"created_at": 1668477638,
									"currency": null,
									"end_date": null,
									"live_mode": false,
									"max_redemptions": null,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"name": "Startup Discount",
									"percent_off": 10,
									"products": [],
									"promo_code": null,
									"quantity_gte": null,
									"start_date": 1668477638,
									"times_redeemed": 0,
									"type": "standard"
							},
							"source": "group"
					}
			],
			"discountable": true,
			"discounts": [],
			"invoice": "inv_1a1o0XVejykDsCnLYtloq1L2Xb9",
			"invoice_item": "ii_1a1sAkfJvB21hUbp2BPzRXXuosy",
			"live_mode": false,
			"merchant": "acc_1A1SKLHDCutyYwGonev",
			"metadata": {},
			"period": {
					"start": 1661987134,
					"end": 1664578774
			},
			"price": {
					"id": "price_1a1DaMgaBw4dTB6HhlRvs1lQuXO",
					"object": "price",
					"active": true,
					"billing_scheme": "per_unit",
					"created_at": 1668477065,
					"currency": "gbp",
					"custom_unit_amount": null,
					"description": null,
					"live_mode": false,
					"lookup_key": null,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"product": "prod_1a1ZvZCeiAE2X7khCQW7I48R17V",
					"recurring": null,
					"tax_behaviour": "unspecified",
					"tiers": null,
					"tiers_mode": null,
					"transform_quantity": null,
					"type": "one_time",
					"unit_amount": 6000,
					"unit_amount_decimal": "6000",
					"user_hidden_in_lists": false
			},
			"product": {
					"id": "prod_1a1ZvZCeiAE2X7khCQW7I48R17V",
					"object": "product",
					"active": true,
					"created_at": 1668477064,
					"description": "General consulting meeting",
					"image_url": [],
					"live_mode": false,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"name": "Consultation Meeting",
					"shippable": false,
					"unit_label": "30 minutes",
					"url": null,
					"user_hidden_in_lists": false
			},
			"proration": false,
			"proration_details": null,
			"quantity": 3,
			"subscription": null,
			"subscription_item": null,
			"tax_amounts": [
					{
							"amount": 2104,
							"display_name": "VAT",
							"inclusive": true,
							"percent": 15,
							"source": "group",
							"tax_rate": {
									"id": "tax_1A1T9Dc2Z7Pok831mpG",
									"object": "tax_rate",
									"active": true,
									"country": null,
									"created_at": 1668477606,
									"description": null,
									"display_name": "VAT",
									"inclusive": true,
									"live_mode": false,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"metadata": {},
									"percent": 15,
									"state": null,
									"type": "vat"
							}
					}
			],
			"tax_rates": [],
			"tier": null,
			"tier_label": null,
			"unit_amount": 6000,
			"unit_amount_decimal": "6000",
			"unit_amount_excluding_tax": "4675"
	},
	{
			"id": "ili_1a1JyUlQ6jlhyiursoaPJiF02ag",
			"object": "invoice_line_item",
			"amount": 24000,
			"amount_excluding_tax": 18699,
			"computed_amount": 21504,
			"created_at": 1668536431,
			"currency": "gbp",
			"description": "Consultation Meeting (per 30 minutes)",
			"discount_amounts": [
					{
							"amount": 2496,
							"discount": {
									"id": "discount_1a1fP1jV7zxnPTJg6igUDI9Gfyz",
									"object": "discount",
									"active": true,
									"amount_gte": null,
									"amount_off": null,
									"created_at": 1668477638,
									"currency": null,
									"end_date": null,
									"live_mode": false,
									"max_redemptions": null,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"name": "Startup Discount",
									"percent_off": 10,
									"products": [],
									"promo_code": null,
									"quantity_gte": null,
									"start_date": 1668477638,
									"times_redeemed": 0,
									"type": "standard"
							},
							"source": "group"
					}
			],
			"discountable": true,
			"discounts": [],
			"invoice": "inv_1a1o0XVejykDsCnLYtloq1L2Xb9",
			"invoice_item": "ii_1a1JyUlQ6jlhyiursoaPJiF02ag",
			"live_mode": false,
			"merchant": "acc_1A1SKLHDCutyYwGonev",
			"metadata": {},
			"period": {
					"start": 1664579134,
					"end": 1667174374
			},
			"price": {
					"id": "price_1a1DaMgaBw4dTB6HhlRvs1lQuXO",
					"object": "price",
					"active": true,
					"billing_scheme": "per_unit",
					"created_at": 1668477065,
					"currency": "gbp",
					"custom_unit_amount": null,
					"description": null,
					"live_mode": false,
					"lookup_key": null,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"product": "prod_1a1ZvZCeiAE2X7khCQW7I48R17V",
					"recurring": null,
					"tax_behaviour": "unspecified",
					"tiers": null,
					"tiers_mode": null,
					"transform_quantity": null,
					"type": "one_time",
					"unit_amount": 6000,
					"unit_amount_decimal": "6000",
					"user_hidden_in_lists": false
			},
			"product": {
					"id": "prod_1a1ZvZCeiAE2X7khCQW7I48R17V",
					"object": "product",
					"active": true,
					"created_at": 1668477064,
					"description": "General consulting meeting",
					"image_url": [],
					"live_mode": false,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"name": "Consultation Meeting",
					"shippable": false,
					"unit_label": "30 minutes",
					"url": null,
					"user_hidden_in_lists": false
			},
			"proration": false,
			"proration_details": null,
			"quantity": 4,
			"subscription": null,
			"subscription_item": null,
			"tax_amounts": [
					{
							"amount": 2805,
							"display_name": "VAT",
							"inclusive": true,
							"percent": 15,
							"source": "group",
							"tax_rate": {
									"id": "tax_1A1T9Dc2Z7Pok831mpG",
									"object": "tax_rate",
									"active": true,
									"country": null,
									"created_at": 1668477606,
									"description": null,
									"display_name": "VAT",
									"inclusive": true,
									"live_mode": false,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"metadata": {},
									"percent": 15,
									"state": null,
									"type": "vat"
							}
					}
			],
			"tax_rates": [],
			"tier": null,
			"tier_label": null,
			"unit_amount": 6000,
			"unit_amount_decimal": "6000",
			"unit_amount_excluding_tax": "4675"
	},
	{
			"id": "ili_1a1zSYaLpJH39KOQJzNEtOG1ek1",
			"object": "invoice_line_item",
			"amount": 135000,
			"amount_excluding_tax": 105725,
			"computed_amount": 121584,
			"created_at": 1668536395,
			"currency": "gbp",
			"description": "Advisory Service (per month)",
			"discount_amounts": [
					{
							"amount": 13416,
							"discount": {
									"id": "discount_1a1fP1jV7zxnPTJg6igUDI9Gfyz",
									"object": "discount",
									"active": true,
									"amount_gte": null,
									"amount_off": null,
									"created_at": 1668477638,
									"currency": null,
									"end_date": null,
									"live_mode": false,
									"max_redemptions": null,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"name": "Startup Discount",
									"percent_off": 10,
									"products": [],
									"promo_code": null,
									"quantity_gte": null,
									"start_date": 1668477638,
									"times_redeemed": 0,
									"type": "standard"
							},
							"source": "group"
					}
			],
			"discountable": true,
			"discounts": [],
			"invoice": "inv_1a1o0XVejykDsCnLYtloq1L2Xb9",
			"invoice_item": "ii_1a1zSYaLpJH39KOQJzNEtOG1ek1",
			"live_mode": false,
			"merchant": "acc_1A1SKLHDCutyYwGonev",
			"metadata": {},
			"period": {
					"start": 1664579134,
					"end": 1667174374
			},
			"price": {
					"id": "price_1a18vssTKDwcYeOsTqfdHLqHy4M",
					"object": "price",
					"active": true,
					"billing_scheme": "per_unit",
					"created_at": 1668476233,
					"currency": "gbp",
					"custom_unit_amount": null,
					"description": null,
					"live_mode": false,
					"lookup_key": null,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"product": "prod_1a1YVk7gnYgRRjXJpM3FoaTWUp8",
					"recurring": null,
					"tax_behaviour": "unspecified",
					"tiers": null,
					"tiers_mode": null,
					"transform_quantity": null,
					"type": "one_time",
					"unit_amount": 135000,
					"unit_amount_decimal": "135000",
					"user_hidden_in_lists": false
			},
			"product": {
					"id": "prod_1a1YVk7gnYgRRjXJpM3FoaTWUp8",
					"object": "product",
					"active": true,
					"created_at": 1668476232,
					"description": "Advisory work for start up founders",
					"image_url": [],
					"live_mode": false,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"name": "Advisory Service",
					"shippable": false,
					"unit_label": "month",
					"url": null,
					"user_hidden_in_lists": false
			},
			"proration": false,
			"proration_details": null,
			"quantity": 1,
			"subscription": null,
			"subscription_item": null,
			"tax_amounts": [
					{
							"amount": 15859,
							"display_name": "VAT",
							"inclusive": true,
							"percent": 15,
							"source": "group",
							"tax_rate": {
									"id": "tax_1A1T9Dc2Z7Pok831mpG",
									"object": "tax_rate",
									"active": true,
									"country": null,
									"created_at": 1668477606,
									"description": null,
									"display_name": "VAT",
									"inclusive": true,
									"live_mode": false,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"metadata": {},
									"percent": 15,
									"state": null,
									"type": "vat"
							}
					}
			],
			"tax_rates": [],
			"tier": null,
			"tier_label": null,
			"unit_amount": 135000,
			"unit_amount_decimal": "135000",
			"unit_amount_excluding_tax": "105725"
	},
	{
			"id": "ili_1a10rBcxTzNoMR9y8oi6CGvMGpE",
			"object": "invoice_line_item",
			"amount": 135000,
			"amount_excluding_tax": 105725,
			"computed_amount": 121584,
			"created_at": 1668536357,
			"currency": "gbp",
			"description": "Advisory Service (per month)",
			"discount_amounts": [
					{
							"amount": 13416,
							"discount": {
									"id": "discount_1a1fP1jV7zxnPTJg6igUDI9Gfyz",
									"object": "discount",
									"active": true,
									"amount_gte": null,
									"amount_off": null,
									"created_at": 1668477638,
									"currency": null,
									"end_date": null,
									"live_mode": false,
									"max_redemptions": null,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"name": "Startup Discount",
									"percent_off": 10,
									"products": [],
									"promo_code": null,
									"quantity_gte": null,
									"start_date": 1668477638,
									"times_redeemed": 0,
									"type": "standard"
							},
							"source": "group"
					}
			],
			"discountable": true,
			"discounts": [],
			"invoice": "inv_1a1o0XVejykDsCnLYtloq1L2Xb9",
			"invoice_item": "ii_1a10rBcxTzNoMR9y8oi6CGvMGpE",
			"live_mode": false,
			"merchant": "acc_1A1SKLHDCutyYwGonev",
			"metadata": {},
			"period": {
					"start": 1661987134,
					"end": 1664578774
			},
			"price": {
					"id": "price_1a18vssTKDwcYeOsTqfdHLqHy4M",
					"object": "price",
					"active": true,
					"billing_scheme": "per_unit",
					"created_at": 1668476233,
					"currency": "gbp",
					"custom_unit_amount": null,
					"description": null,
					"live_mode": false,
					"lookup_key": null,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"product": "prod_1a1YVk7gnYgRRjXJpM3FoaTWUp8",
					"recurring": null,
					"tax_behaviour": "unspecified",
					"tiers": null,
					"tiers_mode": null,
					"transform_quantity": null,
					"type": "one_time",
					"unit_amount": 135000,
					"unit_amount_decimal": "135000",
					"user_hidden_in_lists": false
			},
			"product": {
					"id": "prod_1a1YVk7gnYgRRjXJpM3FoaTWUp8",
					"object": "product",
					"active": true,
					"created_at": 1668476232,
					"description": "Advisory work for start up founders",
					"image_url": [],
					"live_mode": false,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"name": "Advisory Service",
					"shippable": false,
					"unit_label": "month",
					"url": null,
					"user_hidden_in_lists": false
			},
			"proration": false,
			"proration_details": null,
			"quantity": 1,
			"subscription": null,
			"subscription_item": null,
			"tax_amounts": [
					{
							"amount": 15859,
							"display_name": "VAT",
							"inclusive": true,
							"percent": 15,
							"source": "group",
							"tax_rate": {
									"id": "tax_1A1T9Dc2Z7Pok831mpG",
									"object": "tax_rate",
									"active": true,
									"country": null,
									"created_at": 1668477606,
									"description": null,
									"display_name": "VAT",
									"inclusive": true,
									"live_mode": false,
									"merchant": "acc_1A1SKLHDCutyYwGonev",
									"metadata": {},
									"percent": 15,
									"state": null,
									"type": "vat"
							}
					}
			],
			"tax_rates": [],
			"tier": null,
			"tier_label": null,
			"unit_amount": 135000,
			"unit_amount_decimal": "135000",
			"unit_amount_excluding_tax": "105725"
	}
];

export const testData2 = [
	{
			"id": "ili_1a1vs0ZwKyMFbox93FIC6YfIZL8",
			"object": "invoice_line_item",
			"amount": 5760,
			"amount_excluding_tax": 5760,
			"computed_amount": 5760,
			"created_at": 1674923957,
			"currency": "gbp",
			"description": "12 x Lord of The Rings - Hard back (Tier 2 at £4.80 per unit)",
			"discount_amounts": [],
			"discountable": true,
			"discounts": [],
			"invoice": "inv_1a10Syqb1YOgh1l4gq7Qu1cS7vx",
			"invoice_item": "ii_1a1vdnrnA0YCzVPzasUvqrai531",
			"live_mode": false,
			"merchant": "acc_1A1SKLHDCutyYwGonev",
			"metadata": {},
			"period": {
					"start": 1661987134,
					"end": 1664578774
			},
			"price": {
					"id": "price_1a1y4zdvoxH7VWv0VeKGoMWrCr1",
					"object": "price",
					"active": true,
					"billing_scheme": "tiered",
					"created_at": 1674923766,
					"currency": "gbp",
					"custom_unit_amount": null,
					"description": null,
					"live_mode": false,
					"lookup_key": null,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"product": "prod_1a1h8h43r1QXcz9VM8kNdkCjoK9",
					"recurring": null,
					"tax_behaviour": "unspecified",
					"tiers": [
							{
									"flat_amount": null,
									"flat_amount_decimal": null,
									"unit_amount": 500,
									"unit_amount_decimal": "500",
									"up_to": 10
							},
							{
									"flat_amount": null,
									"flat_amount_decimal": null,
									"unit_amount": 480,
									"unit_amount_decimal": "480",
									"up_to": 20
							},
							{
									"flat_amount": null,
									"flat_amount_decimal": null,
									"unit_amount": 430,
									"unit_amount_decimal": "430",
									"up_to": 50
							},
							{
									"flat_amount": null,
									"flat_amount_decimal": null,
									"unit_amount": 400,
									"unit_amount_decimal": "400",
									"up_to": null
							}
					],
					"tiers_mode": "volume",
					"transform_quantity": null,
					"type": "one_time",
					"unit_amount": null,
					"unit_amount_decimal": null,
					"user_hidden_in_lists": false
			},
			"product": {
					"id": "prod_1a1h8h43r1QXcz9VM8kNdkCjoK9",
					"object": "product",
					"active": true,
					"created_at": 1674923766,
					"description": null,
					"image_url": [],
					"live_mode": false,
					"merchant": "acc_1A1SKLHDCutyYwGonev",
					"metadata": {},
					"name": "Lord of The Rings - Hard back",
					"shippable": false,
					"unit_label": null,
					"url": null,
					"user_hidden_in_lists": false
			},
			"proration": false,
			"proration_details": null,
			"quantity": 12,
			"subscription": null,
			"subscription_item": null,
			"tax_amounts": [],
			"tax_rates": [],
			"tier": "2",
			"tier_label": "Unit Amount",
			"unit_amount": 480,
			"unit_amount_decimal": "480",
			"unit_amount_excluding_tax": "480"
	}
];

const result = generateInvoiceLineItemData(testData2);
console.log(...result[0].data);

// Prints: [
// 	{ 
// 		period: '01 Sep - 30 Sep, 2022', 
// 		data: [ 
// 			{
// 				amount: '£180.00',
// 				discounts: [],
// 				image_url: null,
// 				name: 'Consultation Meeting (per 30 minutes)',
// 				quantity: 3,
// 				sub_items: [],
// 				unit_amount: '£60.00',
// 				unit_bucket_size: null
// 			}, 
// 			{
// 				amount: '£1,350.00',
// 				discounts: [],
// 				image_url: null,
// 				name: 'Advisory Service (per month)',
// 				quantity: 1,
// 				sub_items: [],
// 				unit_amount: '£1,350.00',
// 				unit_bucket_size: null
// 			} 
// 		] 
// 	},
//   { 
// 		period: '01 Oct - 30 Oct, 2022', 
// 		data: [ 
// 			{
// 				amount: '£240.00',
// 				discounts: [],
// 				image_url: null,
// 				name: 'Consultation Meeting (per 30 minutes)',
// 				quantity: 4,
// 				sub_items: [],
// 				unit_amount: '£60.00',
// 				unit_bucket_size: null
// 			}, 
// 			{
// 				amount: '£1,350.00',
// 				discounts: [],
// 				image_url: null,
// 				name: 'Advisory Service (per month)',
// 				quantity: 1,
// 				sub_items: [],
// 				unit_amount: '£1,350.00',
// 				unit_bucket_size: null
// 			}
// 		] 
// 	}
// ]

// Prints 2:
// [ 
// 	{ 
// 		period: '01 Sep - 30 Sep, 2022', 
// 		data: [ 
// 			{
// 				amount: null,
// 				discounts: [],
// 				image_url: null,
// 				name: 'Lord of The Rings - Hard back',
// 				quantity: 12,
// 				sub_items: [
// 					{
// 						amount: '£57.60',
// 						discounts: [],
// 						name: 'Unit Amount',
// 						quantity: 12,
// 						unit_amount: '£4.80'
// 					}
// 				],
// 				unit_amount: null,
// 				unit_bucket_size: null
// 			} 
// 		] 
// 	} 
// ]

