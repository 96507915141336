import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { useFetchRequestsLog } from '../../../../../api/request_log/request_log';
import LabelTag from '../../../../Common/LabelTag/LabelTag';
import { dateFormatter } from '../../../../../utils/date';
import DashboardModalTable from '../../../../Dashboard/DashboardModalTable/DashboardModalTable';

function PaymentsModalLogsTable({ enabled = false }) {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_payment_link } = useSelector(
    (state) => state.paymentLink
  );
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchLogsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  };

  const { isLoading: isFetchingData } = useFetchRequestsLog(
    {
      onSuccess: onFetchLogsSuccess,
      onError: () => null,
      enabled,
      params: {
        endpoint: `/payments/${selected_payment_link?.id}`,
        limit: limitQuery,
        ...requestQuery,
      },
    }
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'response.status',
        maxWidth: 35,
        Cell: (cell) => {
          return (
            <LabelTag
              color={`${
                cell.value >= 400 ? 'danger' : 'primary'
              }`}>
              {cell.value}
              <span className='ml-1'>
                {cell.value >= 400 ? 'FAIL' : 'OK'}
              </span>
            </LabelTag>
          );
        },
      },
      {
        Header: '',
        accessor: 'request',
        minWidth: 300,
        Cell: (cell) => {
          return (
            <div className='app-modal-fullscreen__value'>
              <span className='mr-4'>
                {cell.value.method.toUpperCase()}
              </span>
              <span>{cell.value.path}</span>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'created_at',
        Cell: (cell) => {
          return (
            <div className='app-modal-fullscreen__value text-right'>
              {dateFormatter(cell.value, true)}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className='space-y-1.5'>
      <div className='app-modal-fullscreen__title'>
        Logs
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        hideColumns
        noRecordTag="Logs"
      />
    </div>
  );
}

export default PaymentsModalLogsTable;
