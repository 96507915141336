import {useEffect, useMemo, useRef, useState} from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import {ReactComponent as Loader} from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import {useAsyncDebounce} from "react-table";
import {useFetchTaxRates} from "../../../api/product/tax_rate";

function SelectTaxRates({ value = null, placeholder = "", data = [], ...rest }) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? {query: inputValue} : null;
  }, [inputValue]);

  const { isLoading, refetch } = useFetchTaxRates({
    onSuccess: (data) => setOptions(data.data),
    onError: () => null,
    enabled: false,
    params: {
      active: true,
      ...bindParams
    }
  });

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const handleOptionClick = (option) => {
    setSelected(option);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  }

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const getFilteredOptions = useMemo(() => {
    return data.length ? options.filter(option => !data.includes(option.id)) : options;
  }, [options, data]);

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <div className="select-merchant__input__value">
          <span>Select a tax rate</span>
        </div>
        <img className="select-customer__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open &&
      <div className="select-customer__options">
        <div
          className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search"
        >
          <input
            autoFocus
            type="text"
            className="select-customer__input__value"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Search..."
          />
        </div>
        {isLoading &&
        <div className="select-customer__loader">
          <Loader/>
        </div>
        }
        {!isLoading && getFilteredOptions.map((tax, index) =>
          <div
            key={index}
            onClick={() => handleOptionClick(tax)}
            className={clsx(
              "select-customer__options__item",
              selected && tax.id === selected.id && "select-customer__options__item--active"
            )}
          >
            <div className="font-normal">
              {truncate(tax.display_name, {'length': 50, 'separator': '...'})}
            </div>
            <span>{`(${tax.percent}%${tax.inclusive && ' incl.'})`}</span>
          </div>
        )}
      </div>
      }
    </div>
  )
}

export default SelectTaxRates;
