import Label from "../../../../../../Common/Form/Label/Label";
import InputPrice from "../../../../../../Common/Form/InputPrice/InputPrice";
import React ,{useEffect}from "react";
import {FIAT_CURRENCIES_SELECT} from "../../../../../../../utils/constants";

function ProductsModalCreatePrice({ index, register, price, update, errors }) {

  useEffect(() => {
console.log(errors)
  }, [errors])
  
  return (
    <div>
      <Label
        title="Price"
        error={errors?.prices ? errors?.prices[index]?.amount?.message : ""}
      />
      <InputPrice
        {...register(`prices.${index}.amount`)}
        block
        min="0"
        currencies={FIAT_CURRENCIES_SELECT}
        currency={price?.currency}
        onCurrencyChange={(e) =>
          update(index, {
            ...price,
            currency: e?.value
          })
        }
        placeholder="0.00"
        error={errors?.prices ? errors?.prices[index]?.amount?.message : ""}

      />
    </div>
  )
}

export default ProductsModalCreatePrice;
