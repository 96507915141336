import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { currencyFormatter, currencySymbol } from "../../../utils/string";
import { useAsyncDebounce } from "react-table";
import { useFetchDiscounts } from "../../../api/product/discount";
import { useSelector } from "react-redux";

function SelectDiscounts({ value = null, placeholder = "", ...rest }) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { invoice_currency, draft_invoice } = useSelector(
    (state) => state.invoice
  );
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  const getCurrency = useMemo(() => {
    return draft_invoice ? draft_invoice?.currency : invoice_currency;
  }, [draft_invoice, invoice_currency]);

  const { isLoading, refetch } = useFetchDiscounts({
    onSuccess: (data) => setOptions(data.data),
    onError: () => null,
    enabled: false,
    params: {
      active: true,
      ...bindParams,
      expired: false,
      currency: getCurrency,
      null_currency: true
    },
  });

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const handleOptionClick = (option) => {
    setSelected(option);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  return (
    <div ref={selectRef} className="select-customer z-40">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <div className="select-merchant__input__value">
          <span>Select discount</span>
        </div>
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && (
        <div className="select-customer__options">
          <div className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search">
            <input
              autoFocus
              type="text"
              className="select-customer__input__value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {isLoading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {!isLoading &&
            options.map((discount, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(discount)}
                className={clsx(
                  "select-customer__options__item",
                  selected &&
                    discount.id === selected.id &&
                    "select-customer__options__item--active"
                )}
              >
                <div>
                  {truncate(discount.name, { length: 50, separator: "..." })}
                </div>
                <span>-</span>
                <span>
                  {discount.amount_off && (
                    <>
                      {`${currencySymbol(discount.currency)} ${currencyFormatter(
                        discount.currency,
                        discount.amount_off
                      )} off`}
                    </>
                  )}
                  {discount.percent_off && <>{`${discount.percent_off}% off`}</>}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SelectDiscounts;
