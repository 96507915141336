import Input from 'components/Common/Form/Input/Input'
import Label from 'components/Common/Form/Label/Label'
import Modal from 'components/Common/Modal/Modal'
import React from 'react'
import InfoIcon from "assets/images/icons/info.svg";
import Button from 'components/Common/Form/Button/Button';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import PermissionsData from 'components/Dashboard/APIPermissions/permissionsData';
import PermissionsTable from 'components/Dashboard/APIPermissions/PermissionsTable';
import { useCreateApiKey } from 'api/api_key/api_key';
import { useGetVerficationCode } from 'api/merchant/merchant';

export default function ApikeysCreateModal({
  show = false,
  onClose = null,
  restrcitedKey = false,
  setFetchChanges = null
}) {
  const { current_account, user } = useSelector((state) => state.auth);
  const { livemode } = useSelector((state) => state.app);
  const getVerficationCode = useGetVerficationCode();
  const createApiKey = useCreateApiKey();
  const [permissions, setPermissions] = React.useState(PermissionsData);
  const [APIKey, setAPIKey] = React.useState({
    name: "",
    note: "",
    permissions: [],
    TFA: "",
    verificationCode: "",
  });
  const [errors, setErrors] = React.useState({
    name: "",
    note: "",
    permissions: "",
    TFA: "",
    verificationCode: "",
  });

  const handleClose = () => {
    onClose && onClose();
  };

  const getCode = () => {
    getVerficationCode.mutate({
      reason: "create_key"
    }, {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  };

  const handleSubmit = () => {
    let permissionsRaw = [];
    let permissionsAccountLinkRaw = [];
    if (restrcitedKey) {
      permissions.forEach((permission) => {
        permission.subPermisssions.forEach((subPermission) => {
          if (subPermission.permissions.read) {
            permissionsRaw.push(subPermission.name.toLowerCase().replace(' ', '_') + ".read");
          }
          if (subPermission.permissions.write) {
            permissionsRaw.push(subPermission.name.toLowerCase().replace(' ', '_') + ".write");
          }
          if (subPermission.linkAccountPermissions?.read) {
            permissionsAccountLinkRaw.push(subPermission.name.toLowerCase().replace(' ', '_') + ".read");
          }
          if (subPermission.linkAccountPermissions?.write) {
            permissionsAccountLinkRaw.push(subPermission.name.toLowerCase().replace(' ', '_') + ".write");
          }
        })
      })

      if (permissionsRaw.length === 0) {
        setErrors({
          ...errors,
          permissions: "Please select at least one permission"
        })
        return;
      }
    }

    const data = {
      type: restrcitedKey ? "restricted" : "secret",
      name: APIKey.name,
      note: APIKey.note,
    }

    if (restrcitedKey) {
      data.permissions = permissionsRaw;
      data.link_permissions = permissionsAccountLinkRaw;
    }

    if (user?.two_factor_authentication_enabled) {
      data.otp = APIKey.TFA;
    }

    if (current_account?.otp?.enabled?.api_key_create) {
      data.verification_code = APIKey.verificationCode;
    }

    console.log("data>>>", data);

    createApiKey.mutate(data, {
      onSuccess: (data) => {
        if (data.status === 206) {
          console.log(data.data.error)
          if (data.data.error.param === "otp") {
            setErrors({
              ...errors,
              TFA: data.data.error.message
            })
          } else if (data.data.error.param === "verification_code") {
            setErrors({
              ...errors,
              verificationCode: data.data.error.message
            })
          }
        } else {
          handleClose();
          setFetchChanges && setFetchChanges(true);
        }
      },
      onError: (error) => {
        console.log(error.error)
      }
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      setErrors({
        ...errors,
        name: value.length > 50 ? "Name must be less than 50 characters" : ""
      })
    }

    if (name === 'note') {
      setErrors({
        ...errors,
        note: value.length > 200 ? "Note must be less than 200 characters" : ""
      })
    }

    if (name === 'TFA') {
      setErrors({
        ...errors,
        TFA: value.length === 0 ? "2FA Code is required" : ""
      })
    }

    if (name === 'verificationCode') {
      setErrors({
        ...errors,
        verificationCode: value.length === 0 ? "Verfication code id Required" : ""
      })
    }

    if ((name === 'verificationCode' && value.length > 7) || (name === 'TFA' && value.length > 6)) {
      return;
    }

    setAPIKey({
      ...APIKey,
      [name]: value
    });
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Modal style={{ overflow: "visible" }} show={show} onClose={handleClose} size={restrcitedKey ? "lg" : "md"}>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
            Create {!livemode && "a Testmode"} {restrcitedKey ? "Restricted" : "Secret"} Key
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name='body'>
        {!user?.two_factor_authentication_enabled && (
          <div className="text-sm bg-red-100 text-red-400 w-full p-3 border-t border-b border-red-200 absolute top-0 left-0">
            You need to enable 2FA to create an API Key
          </div>
        )}
        <form className={`w-full flex flex-col gap-4 mb-4 ${!user?.two_factor_authentication_enabled && "mt-10"}`}>
          {/* {restrcitedKey && (
            <p className='text-sm text-gray-500'>
              Create a new API key to use in your applications. Never share API keys with unauthorised people and store them securely for your account security.
            </p>
          )} */}
          <p className='text-sm text-gray-500'>
            Create a new API key to use in your applications. Never share API keys with unauthorised people and store them securely for your account security.
          </p>
          <div className='w-full'>
            <Label title='Name' />
            <Input
              className='w-full'
              type='text'
              name='name'
              placeholder='Enter a name for this key'
              value={APIKey.name}
              error={errors.name}
              onChange={handleOnChange}
            />
          </div>
          <div className='w-full'>
            <Label
              title='Note'
              tooltip={`Leave a hint at where the keys is stored`}
              icon={InfoIcon}
              tooltipHtml={true}
              tooltipFor="tooltip-api"
            />
            <Input
              className='w-full'
              type='text'
              name='note'
              placeholder="Enter a note for this key"
              value={APIKey.note}
              error={errors.note}
              onChange={handleOnChange}
            />
          </div>
        </form>
        {restrcitedKey && (
          <>
            {errors.permissions && (
              <div className="text-sm bg-red-200 text-red-400 w-full p-2 border border-red-400 rounded-md">
                {errors.permissions}
              </div>
            )}
            <div onClick={
              () => {
                setErrors({
                  ...errors,
                  permissions: ""
                })
              }
            }>
              <PermissionsTable
                permissions={permissions}
                setPermissions={setPermissions}
              />
            </div>
          </>
        )}
        <div className={`${restrcitedKey ? "grid grid-cols-2 mt-4 gap-4" : "flex flex-col gap-4"}`}>
          {user?.two_factor_authentication_enabled &&
            <div className="">
              <Label title="2FA Code" error={""} />
              <Input
                className="w-full"
                placeholder="Enter your 2FA code"
                value={APIKey.TFA}
                error={errors.TFA}
                name="TFA"
                onChange={handleOnChange}
              />
            </div>
          }
          {current_account?.otp?.enabled?.api_key_create &&
            <div className="">
              <Label title="Verification code" />
              <div className="flex gap-2">
                <Input
                  className="w-full"
                  placeholder="Enter verification code"
                  value={APIKey.verificationCode}
                  error={errors.verificationCode}
                  name="verificationCode"
                  onChange={handleOnChange}
                />
                <button
                  className={`rounded-md bg-primary py-2 px-3.5 whitespace-nowrap text-white text-sm font-semibold disabled:bg-opacity-70 disabled:cursor-not-allowed`}
                  disabled={!user?.two_factor_authentication_enabled}
                  type="button"
                  onClick={() => {
                    getCode()
                  }}>
                  Get code
                </button>
              </div>
            </div>
          }
        </div>
      </Modal.Slot>
      <Modal.Slot name='footer'>
        <div className='flex justify-end'>
          <Button
            onClick={handleSubmit}
            className='disabled:bg-opacity-70 disabled:cursor-not-allowed'
            disabled={
              errors.name ||
              errors.note ||
              errors.permissions ||
              errors.TFA ||
              errors.verificationCode ||
              !user?.two_factor_authentication_enabled
            }
          >
            Create
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}
