import { useFetchBalance, useStoreToVault, useStoreVault } from 'api/wallet/balance';
import { useFetchCurrencies } from 'api/wallet/currencies';
import Button from 'components/Common/Form/Button/Button';
import ButtonGradient from 'components/Common/Form/Button/ButtonGradient/ButtonGradient';
import InputPrice, { InputPriceCrypto } from 'components/Common/Form/InputPrice/InputPrice';
import Label from 'components/Common/Form/Label/Label';
import Select from 'components/Common/Form/Select/Select';
import Modal from 'components/Common/Modal/Modal'
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components';
import { FIAT_CURRENCIES } from 'utils/constants';
import { cryptocurrencyFormatter, currencyFormatter, currencyPrettier, currencySymbol } from 'utils/string';
import SelectAccounts, { SelectWallets } from './SelectAccount.js';
import Checkbox from 'components/Common/Form/Checkbox/Checkbox';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Input from 'components/Common/Form/Input/Input';
import { eventListeners } from '@popperjs/core';
import { useStoreTransfer } from 'api/wallet/transfers';
import { useLocation, useNavigate } from 'react-router-dom';

export default function TransferCreateModal({
  show = false,
  onClose = null,
  selectedCurrency = null,
}) {
  const [errors, seterrors] = useState(
    {
      amount: "",
      account: "",
      currency: "",
      destination: "",
    }
  )
  const types = [{
    label: "Internal transfer",
    value: "internal",

  },
  {
    label: "Link transfer",
    value: "link"
  },
  {
    label: "Wallet transfer",
    value: "platform_to_wallet"
  },
  {
    label: "Vault transfer",
    value: "vault"
  }
  ]
  const methods = {
    fiat: [
      {
        label: "Bank Account (to topup/payout with a bank account",
        value: "bank",
      },
      {
        label: "Paypal etc",
        value: "paypal",
      }
    ],
    crypto: [
      {
        label: "Blockchain (standard blockchain topups/payouts)",
        value: "Blockchain",
      },
      {
        label: "Binance (to topup/payout with a Binanace account)",
        value: "Binance",
      },
      {
        label: "Crypto.com",
        value: "Crypto_com",
      },
    ]
  }
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState(types[0]);
  const [currency, setcurrency] = useState(selectedCurrency);
  const [currencyType, setCurrencyType] = useState("crypto");

  const [data, setdata] = useState();
  const [destination, setdestination] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setamount] = useState("");
  const [balance, setBalance] = useState();
  const [toVault, setToVault] = useState(false);
  const [checklink, setchecklink] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {

    return () => {
      navigate(pathname.replace("/create", "").replace("/edit", ""));

    };

  }, []);

  // const { mutateAsync: mutateStoreToVault } = useStoreVault();
  const {
    mutate: mutateStoreVault,
    isSuccess: isStoreSuccess,
    isLoading: isStoreLoading,
  } = useStoreVault();
  const {
    mutate: mutateStoreTransfer,
    // isSuccess: isStoreSuccess,
    // isLoading: isStoreLoading,
  } = useStoreTransfer();
  const onsubmit = () => {
    console.log("submit")
    if (amount === "") {
      seterrors({
        amount: "Amount is required",
      })
      return;
    }

    if (type.value === "vault") {
      console.log("vault")
      var data;
      if (currencyType === "crypto") {
        data = {
          crypto_amount: amount,
          crypto_currency: currency,
          to_vault: toVault,
        }
      } else {
        data = {
          amount: amount,
          currency: currency.toLowerCase(),
          to_vault: toVault,
        }
      }
      mutateStoreVault(data, {
        onSuccess: (data) => {
          console.log(data)
          toast.success("Transfer successful");
          handleClose()
          // setProductId(data.data.id);÷÷
        },
        onError: (error) => {
          console.log(error)
          toast.error(error.response?.data.error.message);

        },
      });

    }
    else {
      if (destination === "") {
        seterrors({
          destination: "Amount is required",
        })
        return;
      }

      var data;
      if (currencyType === "crypto") {
        data = {
          crypto_amount: amount,
          crypto_currency: currency,
          type: type.value,
          crypto: true,
          destination: destination
        }

      }
      else {
        data = {
          amount: amount,
          currency: currency.toLowerCase(),
          type: type.value,
          crypto: false,
          destination: destination

        }
        mutateStoreTransfer(data, {
          onSuccess: (data) => {
            console.log(data)
            toast.success("Transfer successful");
            handleClose()
            // setProductId(data.data.id);÷÷
          },
          onError: (error) => {
            console.log(error)
            toast.error(error.response?.data.error.message);

          },
        });
      }

    }

  }

  const handleClose = () => {
    onClose && onClose();
  };
  const onFetchCurrenciesSuccess = (data) => {
    setdata(data)
    const currencies = data.map((data) => {
      return {
        label: data.currency.toUpperCase(),
        value: data.currency.toUpperCase(),
        icon: currencyType === "crypto" ? data.currency.toLowerCase() : currencySymbol(data.currency),

      };
    });
    if (currency === null)
      setcurrency(data[0].currency.toUpperCase())
    setCurrencies(currencies);
  };
  const handleSelect = (value) => {
    setcurrency(value.value);

  };
  const handleSelectType = (value) => {
    console.log(value.value)
    setType(value)
    if (value.value === "platform_to_wallet") {
      setCurrencyType("crypto")
    }

  };
  const handleCurrencyChange = (value) => {
    console.log(value)
    setcurrency(value.value)

  };
  const handleItemSelect = (value) => {
  };
  const getCurrency = useMemo(() => {
    return currencies.length ? currencies[0].value : null;
  }, [currencies]);

  const { balanceLoading, balanceData } = useFetchBalance({
    onSuccess: (data) => {
      console.log(data)
      setBalance(data)
    },
    onError: () => { },
    params: {
      currency: currency,
      // networks : true
    }
    // enabled: refetch,
  }, [currency]);

  const { isLoading } = useFetchCurrencies({
    onSuccess: onFetchCurrenciesSuccess,
    onError: () => { },
    params: {
      type: currencyType,
      // networks : true
    }
    // enabled: refetch,
  }, [currencyType]);

  useEffect(() => {
    console.log(toVault)

  }, [toVault]);
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  return (
    <Modal show={show} onClose={handleClose} size='md'>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
            New
            Transfer
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name='body'>
        <FormStyle>
          <div className='flex gap-3 '>
            <div>
              <Label title='Transfer type' error={""} />
              <Select
                onSelect={handleSelectType}
                path="assets/images/icons/cryptos"
                style={{ minWidth: "250px" }}
                options={types}
                value={type?.value}
              />

            </div>
            <div>
              <Label title='Currency Type' error={""} />
              <div className='flex flex-row gap-2'>
                <RadioButton
                  value={"crypto"}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setCurrencyType(e.target.value)
                  }}
                  name="currencytype"
                  checked={currencyType === "crypto"}
                  label="Crypto"
                />
                <RadioButton
                  value={"fiat"}
                  onChange={(e) => setCurrencyType(e.target.value)}
                  name="currencytype"
                  checked={currencyType === "fiat"}
                  label="Fiat"
                  disabled={type.value === "platform_to_wallet"}
                />

              </div>
            </div>
          </div>

          {
            type.value === "link" &&
            (
              <div className='gap-5'>
                <Label title='Link' error={""} />
                <SelectAccounts
                  placeholder="Find an link account"
                  currency={currencies[0]?.value}
                  type='one_time'
                  onSelect={handleItemSelect}
                  oneTime={() => { }}
                  createProduct={() => { }}
                  onClose={() => { }}
                />

                <div className='flex gap-3'>
                  <Checkbox
                    // {...register('metered')}
                    onChange={(e) => setchecklink(e.target.checked)}
                    color='primary'
                    className='mt-1'
                  // disabled={selected_product_price?.id}
                  />
                  <div className='text-light text-sm'>
                    Transfer from an exisiting payment
                  </div>
                </div>
                {
                  checklink &&
                  <div>
                    <Label title='Payment Id' error={""} />
                    <Input
                      placeholder="Enter payment id"
                      onChange={(e) => setPaymentId(e.target.value)}
                      value={paymentId}
                    />
                  </div>


                }
              </div>
            )

          }
          {
            type.value === "internal" &&
            <div>
              <Label title='Account' error={errors.destination} />

              <SelectAccounts
                placeholder="Find an account"
                currency={currencies[0]?.value}
                type='one_time'
                onSelect={handleItemSelect}
                oneTime={() => { }}
                createProduct={() => { }}
                onClose={() => { }}
              />
            </div>
          }
          {
            type.value === "platform_to_wallet" &&
            <div>
              <Label title='Wallet' error={""} />

              <SelectWallets
                placeholder="Find a wallet"
                currency={currencies[0]?.value}
                type='one_time'
                onSelect={handleItemSelect}
                oneTime={() => { }}
                createProduct={() => { }}
                onClose={() => { }}
              />
            </div>
          }
          {
            type.value === "vault" &&
            <div>
              <div className='flex items-center gap-3'>
                <Checkbox
                  // {...register('metered')}
                  onChange={(e) => setToVault(e.target.checked)}
                  color='primary'
                  className='mt-1'
                // disabled={selected_product_price?.id}
                />
                <div className='text-light text-sm'>
                  to vault
                </div>
              </div>

            </div>
          }
          <div>
            <Label title='Amount' error={errors.amount} />
            {
              currencyType === "crypto" ? (
                <InputPriceCrypto
                  block
                  min='0'
                  className="marg"
                  currency={currency}
                  currencies={currencies}
                  onChange={(e) => setamount(e.target.value)}

                  onCurrencyChange={handleCurrencyChange}
                  placeholder={'0.00'}
                  error={errors.amount}
                  value={amount}
                />
              ) : (
                <InputPrice
                  block
                  min='0'
                  // className="marg"
                  currency={currencies[0].value}
                  currencies={currencies}
                  placeholder={'0.00'}
                  onCurrencyChange={handleCurrencyChange}
                  error={errors.amount}
                  onChange={(e) => setamount(e.target.value)}
                  value={amount}
                />)
            }
            <span className='text-xs text-gray-500'>Current balance : {currencySymbol(balance?.converted_total_currency) + " " + balance?.converted_total_amount
            }</span>
          </div>
          <div>
            <Label title='Description' error={""} />
            <input onChange={(e) => setDescription(e.target.value)} placeholder='Description..' value={description} className='inputdisc' id="w3review" name="w3review" rows="4" cols="50" />

          </div>
          {
            type.value === "link" &&
            (
              <div>
                <Label title='Description for reciver' error={""} />
                <input placeholder='Description for reciver..' className='inputdisc' id="w3review" name="w3review" rows="4" cols="50" />
              </div>
            )
          }
        </FormStyle>

      </Modal.Slot>
      <Modal.Slot name='footer'>
        <div className='flex justify-end'>
          <Button onClick={(e) => {
            onsubmit(e)
          }} type='  '>
            Confirm

          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}

const FormStyle = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    >div{
        width: 100%;
        >.inputdisc{
            width: 100%;

            border: 1px solid #DCDEE6;
            border-radius: 8px;
            padding: 10px;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            opacity: 1;
            outline: none;
        }
            >.networks{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .active{
                border: 2px solid #47CE7D;
              }
              >label{
                padding:  0 10px;
                flex-basis : 49%;
                margin-bottom:10px ;
                height: 99px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #DCDEE6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                input
                {
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: none;
                  border: 0;
                  box-shadow: inset 0 0 0 3px #DCDEE6;
                  box-shadow: inset 0 0 0 3.5px #DCDEE6;
                  appearance: none;
                  padding: 0;
                  margin: 0;
                  transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
                  pointer-events: none;
                  margin: 2px;
                  &:focus
                  {

                    outline: none;
                  }
                  
                  &:checked
                  {

                    box-shadow: inset 0 0 0 6px #47CE7D;
                  }

                }
                >.first{
                  height: 100%;
                  justify-content: space-around;
                  display: flex;
                  align-items: flex-start;
                  flex-direction :column;
                  >img{

                    max-width: 100px;
                  }
                }

              }
            }
        
    }
    `;



export function RadioButton({
  label,
  name,
  value,
  onChange,
  checked,
  disabled,
  ...props
}) {
  return (
    <RadioStyle htmlFor={value} >
      <input
        id={value} type="radio" name={name} value={value} onChange={(e) => {

          onChange(e)
        }}
        checked={checked} disabled={disabled} {...props}
      />
      <label htmlFor={value}>{label}</label>
    </RadioStyle>
  )
}

const RadioStyle = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
 > input
  {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: none;
    border: 0;
    box-shadow: inset 0 0 0 3px #DCDEE6;
    box-shadow: inset 0 0 0 3.5px #DCDEE6;
    appearance: none;
    padding: 0;
    margin: 0;
    transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
    pointer-events: none;
    margin: 2px;
    &:focus
    {

      outline: none;
    }
    
    &:checked
    {

      box-shadow: inset 0 0 0 6px #47CE7D;
    }

  }
  >label{
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    margin-left: 10px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  }
`
