import Button from "../../../Common/Form/Button/Button";
import {useAuthResendEmail} from "../../../../api/auth/auth";
import Alert from "../../../Common/Alert/Alert";

function ResendConfirmationEmail({ email }) {
  const { mutate, data, error, isError, isSuccess, isLoading } = useAuthResendEmail();

  const handleResendEmail = () => mutate(email);

  return (
    <div>
      <div className="app-auth__card__message">
        <div className="app-auth__card__title">Please Confirm Your Email</div>
        If you cannot see the confirmation email in your inbox, please check the spam folder
      </div>
      {isError &&
        <Alert
          className="my-4"
          type="danger"
          message={error.response.data.error.message}
        />
      }
      {isSuccess &&
        <Alert
          className="my-4"
          type="success "
          message={data.data.message}
        />
      }
      <Button
        onClick={handleResendEmail}
        loading={isLoading}
        block
      >
        Resend Verification Email
      </Button>
    </div>
  )
}

export default ResendConfirmationEmail;