import "./Textarea.scss";
import clsx from "clsx";
import { forwardRef } from "react";

const Textarea = forwardRef(
  (
    {
      children,
      rows = 3,
      placeholder = "",
      className = "",
      block = false,
      error = "",
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <textarea
          ref={ref}
          rows={rows}
          className={clsx(
            className,
            "app-textarea placeholder-white",
            block && "app-textarea--block",
            error && "app-textarea--error"
          )}
          placeholder={placeholder}
          {...rest}
        >
          {children}
        </textarea>
        {error && <p className="app-textarea__help">{error}</p>}
      </>
    );
  }
);

export default Textarea;
