import React, { useState , useEffect } from 'react';
import Label from '../../../../../../Common/Form/Label/Label';
import InputPrice from '../../../../../../Common/Form/InputPrice/InputPrice';
import Checkbox from '../../../../../../Common/Form/Checkbox/Checkbox';
import { useSelector } from 'react-redux';
import TaxBehaviour from './PriceFields/TaxBehaviour';
import BillingType from './PriceFields/BillingType';
import BillingPeriod from './PriceFields/BillingPeriod';
import MeteredAggregation from './PriceFields/MeteredAggregation';
import { FIAT_CURRENCIES_SELECT } from '../../../../../../../utils/constants';
import { fiatCurrencies } from '../../../../../../../utils/string';
import { Desription } from './PackagePricing';
import { format } from 'prettier';
import { formatw } from 'components/Common/inputTable/EditableCell';
import ProductsModalCreatePrice from '../../ProductsModalCreate/AddPrice/ProductsModalCreatePrice';
import ProductsModalCreateBillingType from '../../ProductsModalCreate/AddPrice/ProductsModalCreateBillingType';
import ProductsModalCreateBillingPeriod from '../../ProductsModalCreate/AddPrice/ProductsModalCreateBillingPeriod';

const StandardPricing = ({
  register,
  setValue,
  fieldError,
  currency,
  amount,
  type,
  tax_behaviour,
  metered,
  aggregate_usage,
  interval,
  billing_period
}) => {
  
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  const handleCurrencyChange = (e) => {
    setValue('currency', e.value);
  };
  const handleBillingType = (type) => {
    if (selected_product_price?.id) return;

    setValue('type', type);
  };

  return (
    <> 
      <div>
        <Label title='Price' error={fieldError('amount')} />
        <InputPrice
          {...register('amount')}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={
            fiatCurrencies[currency].zero_decimal ? '0' : '0.00'
          }
          error={fieldError('amount')}
          value={amount}

          disabled={selected_product_price?.id}
        />
      </div>
      <div>
        <TaxBehaviour
          onSelectOption={(option) => {
            setValue('tax_behaviour', option.value);
          }}
          tax_behaviour={tax_behaviour}
          disabled={selected_product_price?.id && selected_product_price.tax_behaviour !== 'unspecified'}
        />
      </div>
      <div>
        <BillingType
          setValue={setValue}
          disabled={selected_product_price?.id}
          type={type}
        />
      </div>
      {type === 'recurring' && (
        <>
          <div>
            <BillingPeriod
              {...register('recurring.interval_count')}
              interval={interval}
              block
              onIntervalChange={(e) =>
                setValue('recurring.interval', e.value)
              }
              onBillingPeriodChange={(e) => {
                setValue('billing_period', e.value);
              }}
              billing_period={billing_period}
              placeholder='0'
              style={{ paddingLeft: '60px' }}
              error={fieldError('recurring.interval_count')}
              disabled={selected_product_price?.id}
            />
          </div>
          <div className='flex items-center gap-3'>
            <Checkbox
              {...register('metered')}
              color='primary'
              className='mt-1'
              disabled={selected_product_price?.id}
            />
            <div className='text-light text-sm'>
              Metered Usage
            </div>
          </div>
          {metered && (
            <>
            <div>
              <MeteredAggregation
                onAggregationChange={(e) =>
                  setValue(
                    'recurring.aggregate_usage',
                    e.value
                  )
                }
                error={fieldError('aggregate_usage')}
                disabled={selected_product_price?.id}
                aggregate_usage={aggregate_usage}
              />
            </div>
            {/* <div className='horizontal-divider' /> */}
            </>
          )}
          
        </>
      )}
     {/* <Desription register={register}/> */}
    </>
  );
};

export default StandardPricing;

export const StandardPricingCreate = ({
  register,
  errors,
  type,
  metered,
  billing_period,
  index,
  prices,
  update=()=>{},
}) => {
  
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
  }, [errors])
  
  return (
    <> 
    <ProductsModalCreatePrice
      price={prices[index]}
      index={index}
      register={register}
      update={update}
      errors={errors}
    />
    <div>
      <TaxBehaviour
        onSelectOption={(option) => {
          update(index, {
            ...prices[index],
            tax_behaviour: option.value,
          });
        }}
        tax_behaviour={prices[index]?.tax_behaviour ?? 'unspecified'}
        // disabled={tax_behaviour ? tax_behaviour : 'unspecified'}
      />
    </div>
    <ProductsModalCreateBillingType 
      index={index} 
      price={prices[index]} 
      update={update}
      type={type} 
    />
    {type === 'recurring' && (
      <>
        <ProductsModalCreateBillingPeriod
            {...register(`prices.${index}.recurring.interval_count`)}
            interval={prices[index].recurring.interval}
            onIntervalChange={(e) =>
              update(index, {
                ...prices[index],
                recurring: {
                  ...[prices[index].recurring],
                  interval: e.value
                }
              })
            }
            onBillingPeriodChange={(e) => update(index, {
                ...prices[index],
                billing_period: e.value,
              })
            }
            billing_period={billing_period}
            placeholder='0'
            style={{ paddingLeft: '60px' }}
            error={errors.prices?.[index]?.recurring?.interval_count?.message}
            prices={prices}
            index={index}
            //register={register}
        />
        <div className='flex items-center gap-3'>
          <Checkbox
            onChange={(e) => update(index, {
                ...prices[index],
                metered: e.target.checked,
              })
            }
            color='primary'
            className='mt-1'
            disabled={selected_product_price?.id}
          />
          <div className='text-light text-sm'>
            Metered Usage
          </div>
        </div>
        {metered && (
          <div>
            <MeteredAggregation
              onAggregationChange={(e) =>
                update(index, {
                  ...prices[index],
                  recurring: {
                    ...[prices[index].recurring],
                    aggregate_usage: e.value
                  }
                })
              }
              // error={fieldError('aggregate_usage')}
              disabled={selected_product_price?.id}
              aggregate_usage={prices[index].recurring.aggregate_usage}
            />
          </div>
        )}
        {/* <div className='horizontal-divider' /> */}
      </>
    )}
     {/* <Desription register={register}/> */}
    </>
  );
};


