import ModalFullScreen from "../../../../../Common/ModalFullScreen/ModalFullScreen";
import ReactTooltip from "react-tooltip";
import LabelTag from "../../../../../Common/LabelTag/LabelTag";
import Dropdown from "../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../assets/images/icons/dashboard/more.svg";
import { useDispatch, useSelector } from "react-redux";
import DashboardButton from "../../../../../Common/Form/DashboardButton/DashboardButton";
import {
  setKeyRevealed,
  setRefetchWebhook,
  setSelectedWebhook,
  setShowWebhookForm,
} from "../../../../../../store/webhook/webhookSlice";
import ReactDOMServer from "react-dom/server";
import { useUpdateWebhook } from "../../../../../../api/webhook/webhook";
import has from "lodash/has";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WebhooksModalDelete from "../WebhooksModalDelete/WebhooksModalDelete";
import WebhooksModalRollSecret from "../WebhooksModalRollSecret/WebhooksModalRollSecret";
import WebhooksModalReveal from "../WebhooksModalReveal/WebhooksModalReveal";
import WebhookDetailsEventsTable from "./WebhookDetails/WebhookDetailsEventsTable";
import CopyToClipboardBoxID from "../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";

function WebhooksModalDetails({ show, onClose }) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRollSecretModal, setShowRollSecretModal] = useState(false);
  const [showRevealModal, setShowRevealModal] = useState(false);
  const { selected_webhook, key_revealed } = useSelector(
    (state) => state.webhook
  );

  const { mutate: mutateUpdateWebhook } = useUpdateWebhook(
    selected_webhook?.id
  );

  useEffect(() => {
    return () => dispatch(setKeyRevealed(false));
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setSelectedWebhook(null));
    onClose && onClose();
  };

  const handleStatusUpdate = () => {
    mutateUpdateWebhook(
      {
        active: !selected_webhook.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setSelectedWebhook(data.data));
          dispatch(setRefetchWebhook(Date.now()));
          toast.success(
            `Webhook has been ${data.data.active ? "enabled" : "disabled"}`
          );
        },
      }
    );
  };

  const handleRevealClick = () => {
    if (has(selected_webhook, "secret")) {
      dispatch(setKeyRevealed(true));
      return;
    }

    setShowRevealModal(true);
  };

  const handleUpdateClick = () => {
    dispatch(setShowWebhookForm(true));
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleRollSecretClick = () => {
    setShowRollSecretModal(true);
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">Webhook</div>
            <CopyToClipboardBoxID text={selected_webhook?.id} />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          {has(selected_webhook, "id") && (
            <div className="space-y-7">
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="app-modal-fullscreen__big-title">
                      {selected_webhook.url}
                    </div>
                  </div>
                  <Dropdown
                    items={[
                      {
                        label: "Update",
                        onClick: handleUpdateClick,
                      },
                      {
                        label: selected_webhook.active ? "Disabled" : "Enable",
                        onClick: handleStatusUpdate,
                      },
                      {
                        label: "Roll secret",
                        onClick: handleRollSecretClick,
                      },
                      {
                        label: "Delete",
                        className: "text-danger font-semibold",
                        onClick: handleDeleteClick,
                      },
                    ]}
                  >
                    <Dropdown.Slot name="toggle">
                      <MoreIcon className="cursor-pointer" />
                    </Dropdown.Slot>
                  </Dropdown>
                </div>
                {selected_webhook.description && (
                  <div className="app-modal-fullscreen__value pt-2">
                    {selected_webhook.description}
                  </div>
                )}
              </div>
              <div className="horizontal-divider" />
              <div className="flex flex-wrap gap-5 divide-x">
                <div className="pr-5 pr-20">
                  <div className="app-modal-fullscreen__label">STATUS</div>
                  <div className="app-modal-fullscreen__value">
                    <LabelTag
                      color={selected_webhook.active ? "primary" : "danger"}
                    >
                      {selected_webhook.active ? "Active" : "Disabled"}
                    </LabelTag>
                  </div>
                </div>
                <div className="pl-5 pr-20">
                  <div className="app-modal-fullscreen__label">EVENTS</div>
                  <div className="app-modal-fullscreen__value">
                    <span className="app-webhook__modal__events-count">
                      {selected_webhook.enabled_events.includes("*") ? (
                        <div>All events</div>
                      ) : (
                        <div
                          data-html={true}
                          data-tip={ReactDOMServer.renderToString(
                            selected_webhook.enabled_events.map(
                              (event, index) => <div key={index}>{event}</div>
                            )
                          )}
                        >
                          {selected_webhook.enabled_events.length} events
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="pl-5 pr-20">
                  <div className="app-modal-fullscreen__label">SECRET</div>
                  <div className="app-modal-fullscreen__value">
                    {!key_revealed ? (
                      <DashboardButton
                        onClick={handleRevealClick}
                        color="light"
                        size="sm"
                      >
                        Reveal
                      </DashboardButton>
                    ) : (
                      <>
                        <span className="mr-2">{selected_webhook?.secret}</span>
                        <DashboardButton
                          onClick={() => dispatch(setKeyRevealed(false))}
                          color="light"
                          size="sm"
                        >
                          Hide
                        </DashboardButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <WebhookDetailsEventsTable />
            </div>
          )}
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      <ReactTooltip
        place="bottom"
        effect="solid"
        type="light"
        padding="10px"
        textColor="#1A1E23"
        backgroundColor="#ffffff"
        className="api-key__tooltip"
      />
      {showDeleteModal && (
        <WebhooksModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      {showRollSecretModal && (
        <WebhooksModalRollSecret
          show={showRollSecretModal}
          onClose={() => setShowRollSecretModal(false)}
        />
      )}
      {showRevealModal && (
        <WebhooksModalReveal
          show={showRevealModal}
          onClose={() => setShowRevealModal(false)}
        />
      )}
    </>
  );
}

export default WebhooksModalDetails;
