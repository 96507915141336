import Switch from "../../../../../../../Common/Form/Switch/Switch";
import {useUpdateInvoice} from "../../../../../../../../api/customer/invoice";
import {useDispatch, useSelector} from "react-redux";
import {setDraftInvoice} from "../../../../../../../../store/invoice/invoiceSlice";

function InvoicePartialPayment({ register }) {
  const dispatch = useDispatch();
  const { draft_invoice } = useSelector((state) => state.invoice);
  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  const handlePartialPaymentChange = (e) => {
    if (!draft_invoice) return;

    mutateUpdateInvoice({ partial_payment: e.target.checked }, {
      onSuccess: (data) => dispatch(setDraftInvoice(data.data))
    })
  }

  return (
    <div className="app-modal-fullscreen__text py-2">
      <Switch
        {...register("partial_payment", {
          onChange: handlePartialPaymentChange
        })}
        color="primary"
      />
      <span className="align-middle text-light">Partial Payments (Off)</span>
    </div>
  )
}

export default InvoicePartialPayment;