import "./SectionWhy.scss";
import Stack from "../../../Common/Stack/Stack";
import {ReactComponent as SectionWhyIllustration} from "../../../../assets/images/sections/why/why_walio.svg";
import ArrowRightDarkIcon from "../../../../assets/images/icons/arrow-right-dark.png";
import {ReactComponent as CompetitiveEdgeIcon} from "../../../../assets/images/sections/why/ce.svg";
import {ReactComponent as BNTIcon} from "../../../../assets/images/sections/why/bnt.svg";

function SectionWhy() {
  return (
    <div className="section-why">
      <div className="app-container">
        <Stack columns="1" gap="12" className="section-why__container lg:grid-cols-2">
          <div className="section-why__illustration">
            <SectionWhyIllustration />
          </div>
          <div>
            <h2 className="h2 section-why__title">Why Walio?</h2>
            <p className="text section-why__text">
              Walio offers the most powerful, flexible, and scalable cryptocurrency merchant platform available.
              <span className="block">
                Accept secure payments in over 21 Cryptocurrencies over multiple blockchain networks and create
                them in over 18 fiat currencies with our Bridged Blockchain Payment System!
              </span>
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.walio.io"
              className="section-why__button"
            >
              Read The Docs
              <img src={ArrowRightDarkIcon} alt="Read The Docs" />
            </a>
          </div>
        </Stack>
        <div className="section-why__features">
          <Stack columns="1" gap="12" className="lg:grid-cols-2">
            <div>
              <h4 className="h4 section-why__features__title">
                <CompetitiveEdgeIcon />
                Competitive Edge
              </h4>
              <p className="text-small section-why__features__text">
                Gain a competitive edge over your competition by integrating and accepting
                crypto payments for your products and services and widen your businesses
                customer reach to over 100+ Million cryptocurrency users!
              </p>
            </div>
            <div>
              <h4 className="h4 section-why__features__title">
                <BNTIcon />
                Bridged Network Technology
              </h4>
              <p className="text-small section-why__features__text">
                Empower your customers in how they spend their cryptocurrency.
                Walio offers a unique bridge blockchain payment system.
                If you accept a payment in Bitcoin for example, your customer can choose
                to pay in Bitcoin on the Bitcoin Mainnet, Bitcoin on the Binance Chain,
                or Binance Smart Chain, or in Wrapped Bitcoin on the Ethereum Blockchain.
              </p>
            </div>
          </Stack>
          <div className="section-why__features__info">At the end of the payment you still receive Bitcoin!</div>
        </div>
      </div>
    </div>
  )
}

export default SectionWhy;
