import api from '../../utils/axios';
import { useMutation, useQuery } from 'react-query';
import appStore from '../../store';

const fetchProducts = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/products', { params })
    : api.sandbox.get('/products', { params });

export const useFetchProducts = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchProducts', params],
    () => fetchProducts(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchProduct = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/products/${id}`, { params })
    : api.sandbox.get(`/products/${id}`, { params });

export const useFetchProduct = ({
  onSuccess,
  onError,
  enabled,
  id,
  params = null,
}) => {
  return useQuery(
    ['fetchProduct', id],
    () => fetchProduct(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const updateProduct = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/products/${id}`, data)
    : api.sandbox.patch(`/products/${id}`, data);

export const useUpdateProduct = (id) => {
  return useMutation(['updateProduct', id], (data) =>
    updateProduct(id, data)
  );
};

const storeProduct = (data) =>
  appStore.getState().app.livemode
    ? api.live.post('/products', data)
    : api.sandbox.post('/products', data);

export const useStoreProduct = () => {
  return useMutation('storeProduct', storeProduct);
};

const deleteProduct = (id) =>
  appStore.getState().app.livemode
    ? api.live.delete(`/products/${id}`)
    : api.sandbox.delete(`/products/${id}`);

export const useDeleteProduct = (id) => {
  return useMutation(['deleteProduct', id], () =>
    deleteProduct(id)
  );
};

const signMerchantUpload = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/sign_merchant_upload', { params })
    : api.sandbox.get('/sign_merchant_upload', { params });

export const useSignMerchantUpload = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    'signMerchantUpload',
    () => signMerchantUpload(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};
