// import "./Invoices.scss";
// import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import useTableFilters from "../../../../hooks/useTableFilters";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import DashboardNavbar from "layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as ExportIcon } from "assets/images/icons/export.svg";
import useTableFilters from "hooks/useTableFilters";
import DropdownFilters from "components/Common/DropdownFilters/DropdownFilters";
import { setSelectedTransaction } from "store/transactions/transactionsSlice";
import TransferTable from "./TransferTable";
import TransferCreateModal from "./TransferCreateModal";
import { useFetchTransfers } from "api/wallet/transfers";

const TABLE_FILTERS = [
  { label: "All", column: "status", value: "" },
  { label: "Drafts", column: "status", value: "draft" },
  { label: "Outstanding", column: "status", value: "issued" },
  { label: "Overdue", column: "status", value: "over_due" },
  { label: "Paid", column: "status", value: "paid" },
];

function Transfers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selected_transaction,
  } = useSelector((state) => state.transaction);
  const { id } = useParams();
  const [showDetailModal, setShowDetailsModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create"
  );
//   const [refreshInvoice, setRefreshInvoice] = useState(false);
  const [filters, setFilters] = useState({});
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';

//   useFetchInvoice({
//     onSuccess: (data) => {
//       dispatch(setSelectedInvoice(data));
//       setRefreshInvoice(false);
//     },
//     onError: (error) => {
//       setRefreshInvoice(false);
//       navigate(DASHBOARD_PATH + "/invoices");
//       toast.error(error.response?.data.error.message);
//     },
//     id,
//     enabled: refreshInvoice,
//   });

  const handleCloseDetailModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedTransaction(null));
    navigate(DASHBOARD_PATH + "/balance_transactions");
  }, [dispatch, navigate]);

  useEffect(() => {

    console.log("showPriceModal", location.pathname);
    if (showCreateModal) {
      if (!location.pathname.endsWith("/create" ) ) {
            navigate(location.pathname + "/create");
      } 
    }  
  
  
  }, [showCreateModal]);
  useEffect(() => {
    // if (!id) {
    //   handleCloseDetailModal();
    //   return;
    // }

    // setRefreshInvoice(true);
  }, []);

//   useEffect(() => {
//     return () => {
//       dispatch(setSelectedInvoice(null));
//     };
//   }, [dispatch]);

//   useEffect(() => {
//     setShowCreateModal(!!draft_invoice);
//   }, [draft_invoice]);

  // useEffect(() => {
  //   if (show_create_invoice) setShowCreateModal(true);
  // }, [show_create_invoice]);

  useEffect(() => {
    setShowDetailsModal(!!selected_transaction);
  }, [selected_transaction]);

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

//   const handleNewInvoiceClick = () => {
//     dispatch(setShowCreateInvoice(false));
//     dispatch(setSelectedInvoiceCustomer(null));
//     dispatch(setSelectedInvoiceItem(null));
//     dispatch(setShowProductsSelect(false));
//     dispatch(setSelectOneTime(false));
//     dispatch(setInvoiceAcceptedCryptos([]));
//     dispatch(setInvoiceCustomFields([]));
//     dispatch(setInvoiceLineItems([]));
//     dispatch(setInvoiceCurrency(""));
//     dispatch(setDraftInvoice(null));
//     setShowCreateModal(true);
//   };

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({ column: "status", value: filters?.status ?? "" });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
    );
  }, [activeFilter]);

//   const getFilters = useMemo(() => {
//     return {
//       ...filters,
//       ...(activeFilter.status ? activeFilter : null),
//     };
//   }, [filters, activeFilter]);

//   const handleFilterClick = (filter) => {
//     if (filter && filter.label === "All") {
//       setFilters((state) => {
//         const { status, ...rest } = state;

//         return rest;
//       });
//     }

//     setFilter(filter);
//   };

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Transfers</div>
              <span className="app-dashboard__card__header__filters">
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
            <DropdownFilters
                filters={[
                  "date",
                  "invoice_type",
                  "invoice_status",
                  "due_date",
                  "currency",
                  "amount_due",
                  "metadata",
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page="invoices"
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color='primary'
                outline
                icon='+'>
                <span className='text-dark'>
                  New Transfer
                </span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
              </div>
          </div>
          <TransferTable/>
        </div>
        {/* {showDetailModal && (
          <TransactionDetails
            show={showDetailModal}
            onClose={handleCloseDetailModal}
          />
        )} */}
        {showCreateModal && (
          <TransferCreateModal
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
        <ReactTooltip
          id="invoice-modal-create"
          place="bottom"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
        </ReactTooltip>
      </div>
    </div>
  );
}

export default Transfers;
