import { Outlet } from "react-router-dom";
import MainFooter from "./MainFooter/MainFooter";

function Main({ contactRef }) {
  return (
    <div className="h-screen">
      <Outlet />
      <MainFooter contactRef={contactRef} />
    </div>
  )
}

export default Main;
