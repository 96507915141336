import React, { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { useFetchProducts } from "../../../api/product/product";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { dropdownPriceDescription } from "../../../utils/price_utils";
import { useAsyncDebounce } from "react-table";

function SelectProductsSelected({
  value = null,
  placeholder = "",
  currency = "",
  type = null,
  error = "",
  disabled = false,
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  // Filter only prices that meet the provided currency and type;
  const filterProductPrices = (data) => {
    return data.map((item) => {
      return {
        ...item,
        prices: {
          ...item.prices,
          data: item.prices.data.filter((price) => {
            if (currency && type) return price.currency === currency && price.type === type;
            else if (currency) return price.currency === currency;
            else if (type) return price.type === type;
            else return true;
          })
        }
      }
    });
  };

  const { isLoading, refetch } = useFetchProducts({
    onSuccess: (data) => {
      setOptions(filterProductPrices(data.data));
    },
    onError: () => null,
    enabled: false,
    params: {
      sort: 'updated_at',
      include: ["prices"],
      active: true,
      ...bindParams,
    },
  });

  useEffect(() => {
    refetch();
  }, [currency, refetch]);

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const handleOptionClick = (option) => {
    setSelected(option);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const handleOpenClick = () => {
    if (disabled) return;

    setOpen(!open);
  };

  return (
    <div ref={selectRef} className="select-customer z-50">
      <div
        onClick={handleOpenClick}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active",
          disabled && "select-customer__input--disabled"
        )}
      >
        <div className="select-merchant__input__value">
          <span>
            {placeholder && !value
              ? placeholder
              : truncate(selected?.name, { length: 40, separator: "..." })}
          </span>
        </div>
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && (
        <div className="select-customer__options">
          <div className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search">
            <input
              autoFocus
              type="text"
              className="select-customer__input__value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {isLoading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {!isLoading &&
            options.map((product, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(product)}
                className={clsx(
                  "select-product__options__item flex justify-between",
                  selected &&
                    product.id === selected.id &&
                    "select-product__options__item--active"
                )}
              >
                <div>
                  {truncate(product.name, { length: 25, separator: "..." })}
                </div>
                {product.prices.data.length > 0 &&
                  (product.prices.data.length === 1 ? (
                    <span>
                      {dropdownPriceDescription(product.prices.data[0])}
                    </span>
                  ) : (
                    <span>{product.prices.data.length} prices</span>
                  ))}
                {product.prices.data.length === 0 && <span>No price</span>}
              </div>
            ))}
        </div>
      )}
      {error && <p className="app-input-price__input__help">{error}</p>}
    </div>
  );
}

export default SelectProductsSelected;
