import {useRef, useState} from "react";
import PlusIcon from "../../../../assets/images/icons/plus.png";
import CloseIcon from "../../../../assets/images/icons/close.png";
import "./AccordionItem.scss";
import clsx from "clsx";

function AccordionItem({ title, content }) {
  const [active, setActive] = useState(false);
  const contentRef = useRef();

  return (
    <li className="accordion__item">
      <button
        type="button"
        className="accordion__item__title"
        onClick={() => setActive(!active)}
      >
        {title}
        <img
          className="accordion__item__expand"
          src={active ? CloseIcon : PlusIcon}
          alt="Expand"
        />
      </button>
      <div
        ref={contentRef}
        className={clsx(
          'accordion__item__content',
          active && 'accordion__item__content--active'
        )}
        style={
          active
            ? { height: contentRef.current.scrollHeight }
            : { height: "0px" }
        }
      >
        {content}
      </div>
    </li>
  )
}

export default AccordionItem;
