import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
import authReducer from './app/authSlice';
import customerReducer from './customer/customerSlice';
import invoiceReducer from './invoice/invoiceSlice';
import paymentReducer from './payment/paymentSlice';
import paymentLinkReducer from './paymentLink/paymentLinkSlice';
import refundReducer from './refunds/refundSlice';
import productReducer from './product/productSlice';
import discountReducer from './discount/discountSlice';
import taxRateReducer from './tax_rate/taxRateSlice';
import webhookReducer from './webhook/webhookSlice';
import shippingRateReducer from './shipping_rate/shippingRateSlice';
import appInvoiceReducer from './app/invoiceAppSlice';
import transactionsReducer from './transactions/transactionsSlice';
import payoutsReducer from './payouts/payoutsSlice';
import topupsReducer from './topups/topupsSlice';
import swapsReducer from './swaps/swapsSlice';
import eventsReducer from './events/eventsSlice';
export default configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    customer: customerReducer,
    invoice: invoiceReducer,
    payment: paymentReducer,
    paymentLink: paymentLinkReducer,
    refund: refundReducer,
    product: productReducer,
    discount: discountReducer,
    tax_rate: taxRateReducer,
    webhook: webhookReducer,
    shipping_rate: shippingRateReducer,
    invoice_app: appInvoiceReducer,
    transaction: transactionsReducer,
    payout: payoutsReducer,
    topup: topupsReducer,
    swap: swapsReducer,
    event: eventsReducer,
  },
});
