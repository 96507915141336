import "./SelectCustomer.scss";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import PlusIcon from "../../../assets/images/icons/dashboard/plus.svg";
import useOutsideClick from "../../../hooks/useOutsideClick";
import {useEffect, useMemo, useRef, useState} from "react";
import {useFetchCustomers} from "../../../api/customer/customer";
import {useDispatch} from "react-redux";
import {setSelectedCustomer, setShowCustomerForm} from "../../../store/customer/customerSlice";
import truncate from "lodash/truncate";
import {useAsyncDebounce} from "react-table";
import {ReactComponent as Loader} from "../../../assets/images/loader.svg";

function SelectCustomer({ value = null, placeholder = "", ...rest }) {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? {query: inputValue} : null;
  }, [inputValue]);

  const {refetch, isLoading} = useFetchCustomers({
    onSuccess: (data) => setOptions(data.data),
    onError: () => null,
    enabled: false,
    params: bindParams
  });

  const handleOptionClick = (option) => {
    setSelected(option);
    setInputValue(option.name);
    setOpen(false);
    dispatch(setSelectedCustomer(null));
    rest.onSelect && rest.onSelect(option);
  }

  const handleAddCustomerClick = () => {
    dispatch(setSelectedCustomer({
      name: trimInputValue
    }));
    dispatch(setShowCustomerForm(true));
  }

  const handleInputChange = useAsyncDebounce(() => {
    setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange])

  const trimInputValue = useMemo(() => {
    return inputValue.trim();
  }, [inputValue]);

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <input
          type="text"
          className="select-customer__input__value"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
        <img className="select-customer__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open &&
      <div className="select-customer__options">
        <div onClick={handleAddCustomerClick} className="select-customer__add">
          <img className="select-customer__add__icon" src={PlusIcon} alt=""/>
          <span>Add {trimInputValue ? `'${trimInputValue}'` : "new customer"}</span>
        </div>
        {options.length > 0 &&
        <div className="select-customer__recent">Recent</div>
        }
        {isLoading &&
        <div className="select-customer__loader">
          <Loader/>
        </div>
        }
        {!isLoading && options.map((customer, index) =>
          <div
            key={index}
            onClick={() => handleOptionClick(customer)}
            className={clsx(
              "select-customer__options__item",
              selected && customer.id === selected.id && "select-customer__options__item--active"
            )}
          >
            <div>{truncate(customer.name, {'length': 20, 'separator': '...'})}</div>
            <span>{truncate(customer.email, {'length': 30, 'separator': '...'})}</span>
          </div>
        )}
      </div>
      }
    </div>
  )
}

export default SelectCustomer;
