import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchInvoices = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/invoices", { params })
    : api.sandbox.get("/invoices", { params });

export const useFetchInvoices = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchInvoices", params], () => fetchInvoices(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchInvoice = (id) =>
  appStore.getState().app.livemode
    ? api.live.get(`/invoices/${id}`, {
        params: {
          expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
        },
      })
    : api.sandbox.get(`/invoices/${id}`, {
        params: {
          expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
        },
      });

export const useFetchInvoice = ({ onSuccess, onError, enabled, id }) => {
  return useQuery(["fetchInvoice", id], () => fetchInvoice(id), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchInvoiceItems = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/invoice_items", { params })
    : api.sandbox.get("/invoice_items", { params });

export const useFetchInvoiceItems = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ["fetchInvoiceItems", params],
    () => fetchInvoiceItems(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchInvoiceItem = (params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/invoice_items/${params.id}`, { params })
    : api.sandbox.get(`/invoice_items/${params.id}`, { params });

export const useFetchInvoiceItem = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ["fetchInvoiceItem", params],
    () => fetchInvoiceItem(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const storeInvoice = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/invoices", data)
    : api.sandbox.post("/invoices", data);

export const useStoreInvoice = () => {
  return useMutation("storeInvoice", storeInvoice);
};

const updateInvoice = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/invoices/${id}`, data)
    : api.sandbox.patch(`/invoices/${id}`, data);

export const useUpdateInvoice = (id) => {
  return useMutation(["updateInvoice", id], (data) => updateInvoice(id, data));
};

const storeInvoiceItem = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/invoice_items", data)
    : api.sandbox.post("/invoice_items", data);

export const useStoreInvoiceItem = () => {
  return useMutation("storeInvoiceItem", storeInvoiceItem);
};

const updateInvoiceItem = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/invoice_items/${id}`, data)
    : api.sandbox.patch(`/invoice_items/${id}`, data);

export const useUpdateInvoiceItem = (id) => {
  return useMutation(["updateInvoiceItem", id], (data) =>
    updateInvoiceItem(id, data)
  );
};

const deleteInvoiceItem = (id) =>
  appStore.getState().app.livemode
    ? api.live.delete(`/invoice_items/${id}`)
    : api.sandbox.delete(`/invoice_items/${id}`);

export const useDeleteInvoiceItem = () => {
  return useMutation("deleteInvoiceItem", (id) => deleteInvoiceItem(id));
};

const issueInvoice = (id) =>
  appStore.getState().app.livemode
    ? api.live.post(`/invoices/${id}/issue`)
    : api.sandbox.post(`/invoices/${id}/issue`);

export const useIssueInvoice = (id) => {
  return useMutation(["issueInvoice", id], () => issueInvoice(id));
};

const deleteInvoice = (id) =>
  appStore.getState().app.livemode
    ? api.live.delete(`/invoices/${id}`)
    : api.sandbox.delete(`/invoices/${id}`);

export const useDeleteInvoice = (id) => {
  return useMutation(["deleteInvoice", id], () => deleteInvoice(id));
};

const voidInvoice = (id) =>
  appStore.getState().app.livemode
    ? api.live.post(`/invoices/${id}/void`)
    : api.sandbox.post(`/invoices/${id}/void`);

export const useVoidInvoice = (id) => {
  return useMutation(["voidInvoice", id], () => voidInvoice(id));
};

const uncollectibleInvoice = (id) =>
  appStore.getState().app.livemode
    ? api.live.post(`/invoices/${id}/uncollectible`)
    : api.sandbox.post(`/invoices/${id}/uncollectible`);

export const useUncollectibleInvoice = (id) => {
  return useMutation(["uncollectibleInvoice", id], () =>
    uncollectibleInvoice(id)
  );
};

const fetchInvoiceLineItems = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/invoices/${id}/line_items`, { params })
    : api.sandbox.get(`/invoices/${id}/line_items`, { params });

export const useFetchInvoiceLineItems = ({
  onSuccess,
  onError,
  enabled,
  id,
  params = null,
}) => {
  return useQuery(
    ["fetchInvoiceLineItems", { id, params }],
    () => fetchInvoiceLineItems(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchInvoiceHostedData = (params) =>
  api.live.get(`/invoice_hosted_data/${params?.merchant}/${params?.secret}`);

export const useFetchInvoiceHostedData = ({
  onSuccess,
  onError,
  enabled,
  params,
}) => {
  return useQuery(
    ["fetchInvoiceHostedData", params],
    () => fetchInvoiceHostedData(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchUpcomingInvoice = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/invoices/upcoming", { params })
    : api.sandbox.get("/invoices/upcoming", { params });

export const useFetchUpcomingInvoice = ({
  onSuccess,
  onError,
  enabled,
  params,
}) => {
  return useQuery(
    ["fetchUpcomingInvoice", params],
    () => fetchUpcomingInvoice(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchUpcomingInvoiceLineItems = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/invoices/upcoming/line_items", { params })
    : api.sandbox.get("/invoices/upcoming/line_items", { params });

export const useFetchUpcomingInvoiceLineItems = ({
  onSuccess,
  onError,
  enabled,
  params,
}) => {
  return useQuery(
    ["fetchUpcomingInvoiceLineItems", params],
    () => fetchUpcomingInvoiceLineItems(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};
