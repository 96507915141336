import Checkbox from "../../Form/Checkbox/Checkbox";
import clsx from "clsx";
import Radio from "../../Form/Radio/Radio";
import { useEffect } from "react";
import DropdownFiltersFormInput from "../DropdownFiltersForm/DropdownFiltersFormInput";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icons/dashboard/delete.svg";
import { useFieldArray } from "react-hook-form";
import InfoIcon from "../../../../assets/images/icons/info.svg";

function DropdownFiltersMetadata({
  form: {
    register,
    setValue,
    control,
    formState: { errors },
  },
  onToggle,
  state = false,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "metadata",
  });

  useEffect(() => {
    setValue("metadata_query", "and_query");
  }, [setValue]);

  const handleOpen = () => {
    onToggle && onToggle(!state);
  };

  const handleAddMetadata = () => {
    if (fields.length === 4) return;

    append({
      key: "",
      value: "",
    });
  };

  const handleDeleteMetadata = (index) => {
    remove(index);
  };

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
        />
        <span className="text-small">Metadata</span>
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <div className="flex items-center justify-center gap-6">
              <div className="flex gap-2">
                <Radio
                  color="primary"
                  value="and_query"
                  label="AND"
                  className="flex items-center cursor-pointer"
                  {...register("metadata_query")}
                />
                <img
                  className="mt-1"
                  data-tip="Filter resources that match all the provided key value pairs"
                  src={InfoIcon}
                  alt="Info"
                />
              </div>
              <div className="flex gap-2">
                <Radio
                  color="primary"
                  value="or_query"
                  label="OR"
                  className="flex items-center cursor-pointer"
                  {...register("metadata_query")}
                />
                <img
                  className="mt-1"
                  data-tip="Filter resources that contain at least 1 of the provided key value pairs"
                  src={InfoIcon}
                  alt="Info"
                />
              </div>
            </div>
            {fields.map((field, index) => (
              <div key={field.id} className="flex gap-2 pt-2">
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <DropdownFiltersFormInput
                      placeholder="Key"
                      {...register(`metadata.${index}.key`)}
                      error={errors.metadata?.[index]?.key?.message}
                    />
                  </div>
                  <div>
                    <DropdownFiltersFormInput
                      placeholder="Value"
                      {...register(`metadata.${index}.value`)}
                      error={errors.metadata?.[index]?.value?.message}
                    />
                  </div>
                </div>
                <div className="app-dropdown__filters__item__button__delete mt-2">
                  <DeleteIcon onClick={() => handleDeleteMetadata(index)} />
                </div>
              </div>
            ))}
            {fields.length < 4 && (
              <div
                onClick={handleAddMetadata}
                className="app-dropdown__filters__item__button__add pt-2"
              >
                + Add
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersMetadata;
