import { useEffect, useMemo, useState } from 'react';
// import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { useDispatch, useSelector } from 'react-redux';
import LabelTag from 'components/Common/LabelTag/LabelTag';
import { ReactComponent as DateIcon } from 'assets/images/icons/date.svg';
import clsx from 'clsx';
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from 'utils/string';
import { toast } from 'react-toastify';
import useTablePagination from 'hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import DashboardTable from 'components/Dashboard/DashboardTable/DashboardTable';
import { dateFormatter } from 'utils/date';
import { methodType, blockchainNetwork } from 'utils/crypto_utils';
import styled from 'styled-components';
import { setSelectedPayout } from 'store/payouts/payoutsSlice';
import { useFetchPayouts } from 'api/wallet/payouts';

function PayoutsTable({ filters = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchPayoutsSuccess = (data) => {
    setData(data);
    console.log(data.data)
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        crypto_currency: row.crypto_currency,
        currency : row.currency,
        type: row.type,
        status: row.status,
        crypto_amount: cryptocurrencyFormatter(row.crypto_amount),
        amount: (row.amount),
        description : "null",
        created: dateFormatter(row.created_at, true),
        method : row.method,
        failure_message : row.failure_message,
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchPayoutsError = (e) => {
    // setRefetch(false);
    console.log(e)
  };


  const { isFetching: isFetchingData } = useFetchPayouts({
    onSuccess: onFetchPayoutsSuccess,
    onError: onFetchPayoutsError,
    params: {
      expand: ['blockchain_transaction'],
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
    enabled : true,
  });

  const handleRowClick = (row) => {
    dispatch(setSelectedPayout(row._data));
    navigate(DASHBOARD_PATH + `/payouts/${row.id}`);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount',

        maxWidth: 60,
        // Width: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return  <CryptoAmountStyle>
            {row?.type === "crypto" &&           <div>
              <img src={getIconUrl(row?.crypto_currency) } />
              {row?.crypto_amount} 
              {" "}
              {row?.crypto_currency}
            </div>}
            {/* The font size of this fiat value must change if it is fiat or crypto */}
            <span>
            ( {
                currencyPrettier(row?.currency ,row?.amount)
              })
            </span>
          </CryptoAmountStyle>
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 60,
        Cell: (cell) => 
        {
          const row = cell.row.original;
          return ( <div
            data-for='payout-tooltip'
            data-tip={cell.value === 'failed' ? row?.failure_message : null}
          >
            <LabelTag
              color={clsx(
                cell.value === 'succeeded' && 'primary',
                cell.value === 'failed' && 'danger',
                cell.value === 'pending' && 'tertiary',
                cell.value === 'confirming' && 'warning',
                cell.value === 'cancelled' && 'secondary',
                cell.value === 'processing' && 'dark'
              )}>
              {cell.value}
            </LabelTag>
          </div>
          )
        }
      },
      {
        Header: 'Method',
        accessor: 'method',
        // maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div className='flex flex-col items start gap-2 font-semibold' >
              {methodType(cell.value)}
              {cell.value === "crypto" && <span className='font-normal'>{blockchainNetwork(row?._data?.blockchain_transaction?.network)}</span>}
            </div>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'created',
        maxWidth: 50,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              {cell.value && (
                <div>
                  <DateIcon className='mr-1.5' />
                </div>
              )}
              <span className='text-light'>
                {cell.value}
              </span>
            </div>
          );
        },
      },
     
    ],
    []
  );

  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount} 
          noRecordTag="Payouts"
        />
      </div>
    </>
  );
}

const CryptoAmountStyle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div{
    display: flex;
    align-items: center;
    gap: 5px;
    >img{
      width: 20px;
      height: 20px;
    }
  }
  > span{

  }

  `;
export default PayoutsTable;
