import { createSlice } from '@reduxjs/toolkit'

export const webhookSlice = createSlice({
  name: 'webhook',
  initialState: {
    selected_webhook: null,
    refetch_webhook: null,
    key_revealed: false,
    show_webhook_form: false,
    webhook_secret_key: null
  },
  reducers: {
    setSelectedWebhook: (state, payload) => {
      state.selected_webhook = payload.payload
    },
    setRefetchWebhook: (state, payload) => {
      state.refetch_webhook = payload.payload
    },
    setKeyRevealed: (state, payload) => {
      state.key_revealed = payload.payload
    },
    setShowWebhookForm: (state, payload) => {
      state.show_webhook_form = payload.payload
    },
    setWebhookSecretKey: (state, payload) => {
      state.webhook_secret_key = payload.payload
    },
  },
})

export const {
  setSelectedWebhook,
  setRefetchWebhook,
  setKeyRevealed,
  setShowWebhookForm,
  setWebhookSecretKey
} = webhookSlice.actions

export default webhookSlice.reducer
