import { createSlice } from '@reduxjs/toolkit'

export const taxRateSlice = createSlice({
  name: 'tax_rate',
  initialState: {
    selected_tax_rate: null,
    refresh_tax_rate: null,
    show_tax_rate_form: false,
  },
  reducers: {
    setSelectedTaxRate: (state, payload) => {
      state.selected_tax_rate = payload.payload
    },
    setRefetchTaxRate: (state, payload) => {
      state.refetch_tax_rate = payload.payload
    },
    setShowTaxRateForm: (state, payload) => {
      state.show_tax_rate_form = payload.payload
    },
  },
})

export const {
  setSelectedTaxRate,
  setRefetchTaxRate,
  setShowTaxRateForm,
} = taxRateSlice.actions

export default taxRateSlice.reducer
