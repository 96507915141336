import DashboardTable from '../../../../../Dashboard/DashboardTable/DashboardTable';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { useDispatch, useSelector } from 'react-redux';
import LabelTag from '../../../../../Common/LabelTag/LabelTag';
import {
  useDeleteInvoice,
  useFetchInvoices,
} from '../../../../../../api/customer/invoice';
import clsx from 'clsx';
import {
  currencyFormatter,
  currencySymbol,
} from '../../../../../../utils/string';
import { dateFormatter } from '../../../../../../utils/date';
import {
  setRefetchInvoices,
  setSelectedInvoice,
} from '../../../../../../store/invoice/invoiceSlice';
import ModalDelete from '../../../../../Dashboard/Modals/ModalDelete/ModalDelete';
import { toast } from 'react-toastify';
import InvoicesTableActions from './InvoicesTableActions/InvoicesTableActions';
import useTablePagination from '../../../../../../hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RefreshIcon } from '../../../../../../assets/images/icons/refresh.svg';

function InvoicesTable({ filters = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [deleteInvoice, setDeleteInvoice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] =
    useState(false);
  const [data, setData] = useState([]);
  const { current_account } = useSelector(
    (state) => state.auth
  );
  const { refetch_invoices } = useSelector(
    (state) => state.invoice
  );
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  const {
    mutate: mutateDeleteInvoice,
    isLoading: isDeleteLoading,
  } = useDeleteInvoice(deleteInvoice?.id);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_invoices, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchInvoicesSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        amount_due: currencyFormatter(row.currency, row.amount_due),
        currency: row.currency,
        status: row.status,
        id: row.id,
        invoice_number: row.invoice_number,
        subscription: row.subscription,
        customer: row.customer.email ?? '-',
        due: row.due_date
          ? dateFormatter(row.due_date)
          : null,
        created: dateFormatter(row.created_at, true),
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchInvoicesError = () => {
    setRefetch(false);
  };
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const { isFetching: isFetchingData } = useFetchInvoices({
    onSuccess: onFetchInvoicesSuccess,
    onError: onFetchInvoicesError,
    enabled: refetch,
    params: {
      expand: ['customer'],
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedInvoice(row._data));
    navigate(DASHBOARD_PATH + `/invoices/${row.id}`);
  };

  const handleDeleteClick = (invoice) => {
    setDeleteInvoice(invoice);
    setShowDeleteModal(true);
  };

  const handleModalDelete = () => {
    mutateDeleteInvoice(null, {
      onSuccess: () => {
        setDeleteInvoice(null);
        setShowDeleteModal(false);
        dispatch(setSelectedInvoice(null));
        dispatch(setRefetchInvoices(Date.now()));
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount_due',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div
              className='app-customers__modal__earning'
              style={{ fontSize: '1.2rem' }}>
              {currencySymbol(row.currency)}
              {cell.value}
              <span>{row.currency?.toUpperCase()}</span>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 70,
        Cell: (cell) => {
          return (
            <LabelTag
              color={clsx(
                cell.value === 'paid' && 'primary',
                cell.value === 'part_paid' && 'tertiary',
                cell.value === 'void' && 'warning',
                cell.value === 'issued' && 'secondary',
                cell.value === 'over_due' && 'danger',
                cell.value === 'uncollectible' && 'dark'
              )}>
              {cell.value}
            </LabelTag>
          );
        },
      },
      {
        Header: '',
        accessor: 'subscription',
        width: 1,
        Cell: (cell) => {
          return (
            <>
              {cell.value && (
                <span>
                  <RefreshIcon className='mr-1.5' />
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoice_number',
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className='flex items-center break-all'>
              {cell.value}
            </div>
          );
        },
      },
      {
        Header: 'Customer',
        accessor: 'customer',
        minWidth: 250,
        Cell: (cell) => {
          return (
            <div className='text-light'>{cell.value}</div>
          );
        },
      },
      {
        Header: 'Due',
        accessor: 'due',
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              {cell.value && (
                <div>
                  <DateIcon className='mr-1.5' />
                </div>
              )}
              <span className='text-light'>
                {cell.value ?? '-'}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created',
        maxWidth: 95,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              <div>
                <DateIcon className='mr-1.5' />
              </div>
              <span className='text-light'>
                {cell.value}
              </span>
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'icon',
        maxWidth: 15,
        Cell: (cell) => (
          <InvoicesTableActions
            invoice={cell.row.original}
            onDelete={handleDeleteClick}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Invoices"
        />
      </div>
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title='Delete Invoice'
          message='Are you sure you want to delete this invoice?'
        />
      )}
    </>
  );
}

export default InvoicesTable;
