import BigNumber from "bignumber.js";

export function capitalize(string = "") {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function snakeCaseToTitleCase(string = "") {
  return string.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );
}

export const fiatCurrencies = {
	aud: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	aed: {locale: 'global', symbol: 'د.إ.', symbol_before: false, zero_decimal: false},
	ars: {locale: 'europe', symbol: '$', symbol_before: true, zero_decimal: false},
	bdt: {locale: 'india', symbol: '৳', symbol_before: false, zero_decimal: false},
	bhd: {locale: 'global', symbol: 'د.ب.', symbol_before: false, zero_decimal: false},
	bgn: {locale: 'europe', symbol: 'Лв.', symbol_before: false, zero_decimal: false},
	bmd: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	brl: {locale: 'global', symbol: 'R$', symbol_before: true, zero_decimal: false},
	cad: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	chf: {locale: 'europe', symbol: 'Fr.', symbol_before: false, zero_decimal: false},
	clp: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: true},
	cny: {locale: 'global', symbol: '¥', symbol_before: true, zero_decimal: false},
	czk: {locale: 'europe', symbol: 'Kč.', symbol_before: false, zero_decimal: false},
	dkk: {locale: 'europe', symbol: 'Kr.', symbol_before: false, zero_decimal: false},
	egp: {locale: 'global', symbol: 'E£', symbol_before: true, zero_decimal: false},
	eur: {locale: 'europe', symbol: '€', symbol_before: true, zero_decimal: false},
	gel: {locale: 'europe', symbol: '₾', symbol_before: false, zero_decimal: false},
	gbp: {locale: 'global', symbol: '£', symbol_before: true, zero_decimal: false},
	hkd: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	hrk: {locale: 'europe', symbol: 'kn', symbol_before: false, zero_decimal: false},
	huf: {locale: 'europe', symbol: 'Ft', symbol_before: false, zero_decimal: false},
	idr: {locale: 'global', symbol: 'Rp', symbol_before: true, zero_decimal: true},
	ils: {locale: 'global', symbol: '₪', symbol_before: true, zero_decimal: false},
	inr: {locale: 'india', symbol: '₹', symbol_before: true, zero_decimal: false},
	jpy: {locale: 'global', symbol: '¥', symbol_before: true, zero_decimal: true},
	kwd: {locale: 'global', symbol: 'د.ك.', symbol_before: false, zero_decimal: false},
	krw: {locale: 'global', symbol: '₩', symbol_before: true, zero_decimal: true},
	lkr: {locale: 'india', symbol: 'Rs', symbol_before: false, zero_decimal: false},
	mad: {locale: 'global', symbol: 'د.م.', symbol_before: false, zero_decimal: false},
	mmk: {locale: 'india', symbol: 'K', symbol_before: false, zero_decimal: false},
	mxn: {locale: 'global', symbol: 'Mex$', symbol_before: true, zero_decimal: false},
	myr: {locale: 'global', symbol: 'RM', symbol_before: true, zero_decimal: false},
	nok: {locale: 'europe', symbol: 'kr', symbol_before: false, zero_decimal: false},
	ngn: {locale: 'global', symbol: '₦', symbol_before: true, zero_decimal: false},
	nzd: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	php: {locale: 'global', symbol: '₱', symbol_before: true, zero_decimal: false},
	pkr: {locale: 'india', symbol: 'Rs', symbol_before: false, zero_decimal: false},
	pln: {locale: 'europe', symbol: 'zł', symbol_before: false, zero_decimal: false},
	qar: {locale: 'global', symbol: 'ر.ق.', symbol_before: false, zero_decimal: false},
	ron: {locale: 'europe', symbol: 'lei', symbol_before: false, zero_decimal: false},
	rub: {locale: 'global', symbol: '₽', symbol_before: true, zero_decimal: false},
	sar: {locale: 'global', symbol: 'ر.س.', symbol_before: false, zero_decimal: false},
	sek: {locale: 'europe', symbol: 'kr', symbol_before: false, zero_decimal: false},
	sgd: {locale: 'global', symbol: 'S$', symbol_before: true, zero_decimal: false},
	thb: {locale: 'global', symbol: '฿', symbol_before: true, zero_decimal: false},
	try: {locale: 'global', symbol: '₺', symbol_before: true, zero_decimal: false},
	twd: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	uah: {locale: 'europe', symbol: '₴', symbol_before: true, zero_decimal: false},
	usd: {locale: 'global', symbol: '$', symbol_before: true, zero_decimal: false},
	vnd: {locale: 'global', symbol: '₫', symbol_before: false, zero_decimal: true},
	ves: {locale: 'global', symbol: 'Bs.S', symbol_before: true, zero_decimal: false},
	zar: {locale: 'global', symbol: 'R', symbol_before: true, zero_decimal: false},
};

const formatCurrency = (option, amount, zeroDecimal) => {
  const global = {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: ''
  };

  const europe = {
    prefix: '',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: ''
  }

  const india = {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 2,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: ''
  }

  BigNumber.config({ FORMAT: option === 'india' ? india : (option === 'europe' ? europe : global)});

  const decimalSplit = (amount.split('.'))[1];
	const decimalLength = decimalSplit && decimalSplit.length > 0 ? (!zeroDecimal ? (decimalSplit.length < 2 ? 2 : decimalSplit.length) : Math.min(decimalSplit.length, 12)) : (zeroDecimal ? 0 : 2);
  return BigNumber(amount).toFormat(decimalLength);
}

/**
 * Currency formatters
 */
export function cryptocurrencyFormatter(amount) {
  return BigNumber(BigNumber(amount).isNaN() ? 0 : amount).div(BigNumber(10).pow(8)).toFixed(8).toString();
}

/**
 * Will format the length of decimal points after a crypto value
 * @param {Number} value // cryptocurrency value unformatted
 * @example
 * formatCryptoValueLength(2523110540000)
 * @returns {String} 25231.10540
 */
export function formatCryptoValueLength(value) {
  const valLength = String(value).length || 1;

  let val = value / Math.pow(10, 8);

  if (valLength <= 10) return val.toFixed(8);
  if (valLength === 11) return val.toFixed(7);
  if (valLength === 12) return val.toFixed(6);
  if (valLength === 13) return val.toFixed(5);
  if (valLength === 14) return val.toFixed(4);
  if (valLength === 15) return val.toFixed(3);
  if (valLength >= 16) return val.toFixed(2);
}

// export function currencyFormatter(currency = "", amount) {
//   const requiresFormat = [
//     "usd", "aed", "ars", "aud", "bdt", "bhd", "bmd", "brl", "bgn", "cad", 
//     "chf", "cny", "hrk", "czk", "dkk", "egp", "eur", "gel", "gbp", "hkd", "huf", "ils", 
//     "inr", "kwd", "lkr", "mad", "mmk", "mxn", "myr", "ngn", "nok", "nzd", "php", "pkr", 
//     "pln", "qar", "ron", "rub", "sar", "sek", "sgd", "thb", "try", "twd", "uah", "ves", "zar"
//   ];
//   const indexOfCurrency = requiresFormat.indexOf(currency?.toLowerCase());
//   return indexOfCurrency >= 0 ? Number((amount / 100).toFixed(2)) : amount;
// }

export function currencyFormatter(currency = "usd", amount) {
  return formatCurrency(
    fiatCurrencies[currency.toLowerCase()].locale, 
    fiatCurrencies[currency.toLowerCase()].zero_decimal ? BigNumber(amount).toString() : BigNumber(amount).div(100).toString(),
    fiatCurrencies[currency.toLowerCase()].zero_decimal
  );
}

export function currencySymbol(currency = "usd") {
  return fiatCurrencies[currency.toLowerCase()]?.symbol ?? "";
}

// Converts standard currency value to zero decimal value (e.g $10.00 will become 1000)
export function currencyConverter(currency = "", amount, includeDecimals = false) {
  const requiresFormat = [
    "usd", "aed", "ars", "aud", "bdt", "bhd", "bmd", "brl", "bgn", "cad", 
    "chf", "cny", "hrk", "czk", "dkk", "egp", "eur", "gel", "gbp", "hkd", "huf", "ils", 
    "inr", "kwd", "lkr", "mad", "mmk", "mxn", "myr", "ngn", "nok", "nzd", "php", "pkr", 
    "pln", "qar", "ron", "rub", "sar", "sek", "sgd", "thb", "try", "twd", "uah", "ves", "zar"
  ];
  const indexOfCurrency = requiresFormat.indexOf(currency?.toLowerCase());
  return indexOfCurrency >= 0 ? (includeDecimals ? BigNumber(amount).times(100).toString() : BigNumber(amount).times(100).toFixed(0).toString()) : (includeDecimals ? BigNumber(amount).toString() : BigNumber(amount).toFixed(0).toString());
}

// Converts zero decimal value to standard currency value (e.g 1000 USD will become $10.00);
export function currencyPrettier(currency = 'usd', amount) {
  if (BigNumber(amount).isNaN()) {
    return `${currencySymbol(currency)}0.00`;
  }
  if (BigNumber(amount).gte(0)) {
    return `${fiatCurrencies[currency.toLowerCase()]?.symbol_before ? fiatCurrencies[currency.toLowerCase()].symbol : ''}${currencyFormatter(currency, amount)}${fiatCurrencies[currency.toLowerCase()]?.symbol_before ? '' : (fiatCurrencies[currency.toLowerCase()]?.symbol ?? '')}`;
  }
  return `-${fiatCurrencies[currency.toLowerCase()]?.symbol_before ? fiatCurrencies[currency.toLowerCase()].symbol : ''}${currencyFormatter(currency, BigNumber(amount).times(-1).toString())}${fiatCurrencies[currency.toLowerCase()]?.symbol_before ? '' : (fiatCurrencies[currency.toLowerCase()]?.symbol ?? '')}`;
}
// Converts zero decimal value to standard currency value (e.g 1000  will become 10.00) with no currenct symbol;
export function currencyPrettierNoSymbol(currency = 'usd', amount) {
  if (isNaN(amount)) {
    return `0.00`;
  }
  if (amount >= 0) {
    return `${currencyFormatter(currency, amount)}`;
  } 
  return `-${currencyFormatter(currency, BigNumber(amount).times(-1).toString())}`;
}


// 
/**
 * Makes input currency values prettier (E.g 10.1 will become 10.10, or 10 will become 10.00) as a STRING value
 * @param {String} currency The price's currency
 * @param {String} amount The amount input
 * @param {Boolean} extendDec If decimal places should be extended. When `true`, will allow decimal places up to 12 for zero decimal currencies and 14 for non-zero decimal currencies
 * @example
 * inputPricePrettier('usd', 10, false)
 * @returns {String} 10.00
 */
export function inputPricePrettier(currency = 'usd', amount = '0', extendDec = false) {
  
  if (isValidCurrency(currency))
  {
    if(amount === undefined || amount === null || amount === "")  
      amount = "0"
    console.log("currency : ", fiatCurrencies[currency])
    if (!fiatCurrencies[currency] )
      currency = "usd"
    if (isNaN(amount)) {
      return `${fiatCurrencies[currency]?.zero_decimal ? '0' : '0.00'}`;
    }
    amount =  amount + "";
    const splitAmount = amount.split('.');
    const preDecimal = splitAmount[0];
    let postDecimal
    if (splitAmount.length >= 1) {
     postDecimal = splitAmount[1];
    }
    else
      postDecimal = "00"
    if (!fiatCurrencies[currency]?.zero_decimal && postDecimal && postDecimal.length > 0 && postDecimal.length < 2) {
      // Add aditional 0
      postDecimal = postDecimal + '0';
    }
  
    return `${preDecimal ?? '0'}${postDecimal ? (fiatCurrencies[currency]?.zero_decimal ? (extendDec ? ['.' + postDecimal.substring(0, 12)] : '') :
      (extendDec ? ['.' + postDecimal.substring(0, 14)] : ['.' + postDecimal.substring(0, 2)])) : (fiatCurrencies[currency]?.zero_decimal ? '' : '.00')}`
  }
  return amount;
}

export function isValidCurrency(currency = "") {
  return [
    "usd", "aed", "ars", "aud", "bdt", "bhd", "bmd", "brl", "bgn", "cad", 
    "chf", "cny", "hrk", "czk", "dkk", "egp", "eur", "gel", "gbp", "hkd", "huf", "ils", 
    "inr", "kwd", "lkr", "mad", "mmk", "mxn", "myr", "ngn", "nok", "nzd", "php", "pkr", 
    "pln", "qar", "ron", "rub", "sar", "sek", "sgd", "thb", "try", "twd", "uah", "ves", "zar",
    "clp", "idr", "jpy", "krw", "vnd"
  ].includes(currency?.toLowerCase());
}

// limits decimal places to 12.
export function sanitizeUnitAmount(amount) {
  const splitAmount = amount.split('.');
  const preDecimal = splitAmount[0];
  let postDecimal = splitAmount[1];

  if (postDecimal.length > 12) postDecimal = postDecimal.slice(0, (postDecimal.length - 12) * -1);
  return `${preDecimal}.${postDecimal}`;
}