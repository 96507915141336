import {forwardRef} from "react";
import clsx from "clsx";

const DropdownFiltersFormInput = forwardRef(({
  type = "text",
  placeholder = "",
  className = "",
  error = "",
  ...rest
}, ref) => {
  return (
    <>
      <input
        ref={ref}
        type={type}
        className={clsx(
          "app-dropdown__filters__input",
          error && 'app-dropdown__filters__input--error',
          className,
        )}
        placeholder={placeholder}
        {...rest}
      />
      {error && <p className="app-input__help">{error}</p>}
    </>
  )
});

export default DropdownFiltersFormInput;