import ModalFullScreen from '../../../../Common/ModalFullScreen/ModalFullScreen';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdatePaymentLink } from '../../../../../api/payment/paymentLink';
import { toast } from 'react-toastify';
import {
  setRefetchPaymentLink,
  setSelectedPaymentLink,
} from '../../../../../store/paymentLink/paymentLinkSlice';
import DashboardButton from '../../../../Common/Form/DashboardButton/DashboardButton';
import PaymentLinkTableActions from '../PaymentLinksTable/PaymentLinkTableActions/PaymentLinkTableActions';
import useCopyToClipboard from '../../../../../hooks/useCopyToClipboard';
import DetailPaymentLinks from './DetailPaymentLinks';
import PaymentLinkMethod from './PaymentLinkMethod';
import MetadataForm from '../../../../Dashboard/MetadataForm/MetadataForm';
import PaymentLinkModalLogsTable from './PaymentLinkModalLogsTable';
import PaymentLinkModalEventsTable from './PaymentLinkModalEventsTable';
import Dropdown from 'components/Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../assets/images/icons/dashboard/more.svg';
import { ReactComponent as ImagePlaceholder } from '../../../../../assets/images/dashboard/product-placeholder.svg';
import NotesModule from 'components/Dashboard/Notes/Notes';
import { currencyFormatter } from 'utils/string';

function PaymentLinksModalDetails({
  show = false,
  onClose = null,
  text = '',
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [setShowActionModal] = useState(false);
  const [description, setDescription] = useState('');
  const [showCaptureModal, setShowCaptureModal] =
    useState(false);
  const { selected_payment_link } = useSelector(
    (state) => state.paymentLink
  );
  const [copy] = useCopyToClipboard(text);
  const {
    mutate: mutateUpdatePaymentLink,
    isLoading: isUpdateLoading,
  } = useUpdatePaymentLink(selected_payment_link?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  const retrieveProductDescription = () => {
    if (selected_payment_link.description) {
      setDescription(selected_payment_link.description);
    } else {
      const products = selected_payment_link.line_items.data;
      if (products.length > 0) {
        if (products.length > 2) {
          const firstProduct = products[0].product;
          const remainingItems = products.length - 1;
          setDescription(`${firstProduct.name} and ${remainingItems} more`);
        } else if (products.length === 2) {
          const firstProduct = products[0].product;
          const secondProduct = products[1].product;
          setDescription(`${firstProduct.name} and ${secondProduct.name}`);
        } else {
          const firstProduct = products[0].product;
          setDescription(firstProduct.name);
        }
      } else {
        setDescription('-');
      }
    }
  };

  console.log(
    'selected payment linkkkk',
    selected_payment_link
  );

  useEffect(() => {
    if (selected_payment_link) {
      retrieveProductDescription();
    }
  }, [selected_payment_link]);

  const handleMetadataSave = (updatePaymentLink) => {
    mutateUpdatePaymentLink(
      { updatePaymentLink },
      {
        onSuccess: (data) => {
          dispatch(setSelectedPaymentLink(data.data));
          dispatch(setRefetchPaymentLink(Date.now()));
          toast.success('Metadata Updated');
        },
      }
    );
  };

  const handleActionClick = () => {
    setShowActionModal(true);
  };

  const handleCaptureClick = () => {
    setShowCaptureModal(true);
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Payment Link
            </div>
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div className='app-payments__modal__body'>
            <div className='space-y-6'>
              <div className='flex flex-col'>
                <h4 className='font-semibold text-3xl'>
                  {description}
                </h4>
                <div className='font-semibold text-xl'>
                  {currencyFormatter(
                    selected_payment_link?.currency,
                    selected_payment_link?.computed_amounts?.amount
                  )} {selected_payment_link?.currency?.toUpperCase()}
                </div>
              </div>
              <div>
                <span className='text-sm text-gray-500 block mb-2'>Share this link to start accepting payments</span>
                <div className='flex items-center gap-3'>
                  <div className='app-modal-fullscreen__header__tag'>
                    {selected_payment_link?.url}
                  </div>
                  <div>
                    <DashboardButton
                      onClick={copy}
                      dense
                      color='primary'>
                      Copy
                    </DashboardButton>
                  </div>
                  <div>
                    <Dropdown
                      items={[
                        {
                          label: "Edit description",
                          onClick: handleActionClick,
                        },
                        {
                          label: 'Edit',
                          onClick: handleActionClick,
                        },
                        {
                          label: selected_payment_link.active
                            ? 'Deactivate'
                            : 'Reactivate',
                          onClick: handleActionClick,
                        },
                      ]}>
                      <Dropdown.Slot name='toggle'>
                        <MoreIcon />
                      </Dropdown.Slot>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <NotesModule enabled parent_object={selected_payment_link} />
              <div className=''>
                <div className='app-modal-fullscreen__title mb-2'>
                  Product
                </div>
                <div className='horizontal-divider' />
                <table className='w-full mt-4'>
                  <thead>
                    <tr className='uppercase text-left text-gray-500 text-sm border-b border-gray-200'>
                      <th className='font-light py-2'>Product</th>
                      <th className='font-light py-2'>Quantity</th>
                      <th className='font-light py-2'>ADJUSTABLE QUANTITY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selected_payment_link?.line_items?.data?.map(
                      (item, index) => (
                        <tr
                          key={index}
                          className='border-b border-gray-200'>
                          <td className='py-3'>
                            <div className='flex items-center'>
                              {item.product.image_url[0] ?
                                (
                                  <img src={item.product.image_url[0]} className='w-16 h-16 mr-2' />
                                ) : (
                                  <div className='w-16 h-16 mr-2 bg-white border-2 border-gray-200 rounded-md grid place-items-center'>
                                    <ImagePlaceholder className='w-6 h-auto' />
                                  </div>
                                )}
                              <div className='flex flex-col'>
                                <span className='font-bold text-xl'>
                                  {item.product.name}
                                </span>
                                <span className=' text-gray-400'>
                                  {currencyFormatter(
                                    item.price.currency,
                                    item.price.unit_amount
                                  )} {item.price.currency.toUpperCase()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className='py-3'>
                            {item.quantity || 'Metred usage'}
                          </td>
                          <td className='py-3'>
                            {item.quantity_adjustable?.enabled ? 'Yes' : 'No'} {' '}
                            {item.quantity_adjustable?.enabled && (<>({`between ${item.quantity_adjustable.minimum} and ${item.quantity_adjustable.maximum}`})</>)}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <div className=''>
                <div className='app-modal-fullscreen__title mb-2'>
                  Payment Methods
                </div>
                <div className='horizontal-divider' />
                <div className=''>
                  {selected_payment_link?.allowed_payment_methods?.map(
                    (method, index) => (
                      <div
                        key={index}
                        className='flex items-center gap-3 py-2'>
                        <div className='app-modal-fullscreen__header__tag'>
                          {method}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <DetailPaymentLinks />
              {/* <PaymentLinkMethod
                enabled={!!selected_payment_link}
              /> */}
              <div className='space-y-1.5 pb-2'>
                <MetadataForm
                  data={selected_payment_link.metadata}
                  onSave={handleMetadataSave}
                  loading={isUpdateLoading}
                />
              </div>
              <PaymentLinkModalLogsTable
                enabled={!!selected_payment_link}
              />
              <PaymentLinkModalEventsTable
                enabled={!!selected_payment_link}
              />
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {/* {showModal && (
        <PaymentsModalUpdate
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      {showCaptureModal && (
        <PaymentsModalCapture
          show={showCaptureModal}
          onClose={() => setShowCaptureModal(false)}
        />
      )} */}
    </>
  );
}

export default PaymentLinksModalDetails;
