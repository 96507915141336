import "./SectionTax.scss";
import {ReactComponent as TaxIcon} from "../../../../assets/images/icons/tax.svg";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/tax/illustration.svg";
import {ReactComponent as RatesIcon} from "../../../../assets/images/sections/tax/rates.svg";
import Stack from "../../../Common/Stack/Stack";

function SectionTax() {
  return (
    <div id="tax" className="section-tax">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <TaxIcon className="section-products__section__subtitle__icon" />
              Tax
            </h3>
            <h1 className="h1 section-products__section__title">
              Tax tools to help you Meet your obligations.
            </h1>
            <p className="text section-products__section__text">
              Account for tax on your invoices, orders, or payments to
              keep up with your tax obligations and records.
              Record your tax collections and make your compliance stronger,
              even when accepting Cryptocurrency.
            </p>
            <h5 className="h5 section-products__section__info section-tax__title">
              Tax Rates
            </h5>
            <div className="section-tax__content">
              <div className="section-tax__icon">
                <RatesIcon />
              </div>
              <p className="text-small section-tax__text">
                Create multiple types of tax rates to attach to your customers invoices or payments.
                You can make them inclusive, or exclusive, and define them by country or state.
                <span>Make your taxing easier and more reliable.</span>
              </p>
            </div>
          </div>
          <div className="section-tax__illustration">
            <Illustration />
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default SectionTax;
