import { Outlet } from "react-router-dom";
import MainFooter from "./MainFooter/MainFooter";

function MainWithoutFaq() {
  return (
    <div className="h-screen">
      <Outlet />
      <MainFooter faq={false} />
    </div>
  )
}

export default MainWithoutFaq;
