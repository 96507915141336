import "./Products.scss";
import DashboardNavbar from "../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../components/Common/Form/DashboardButton/DashboardButton";
import ProductsTable from "../../../components/Pages/Dashboard/Products/ProductsTable/ProductsTable";
import { ReactComponent as ExportIcon } from "../../../assets/images/icons/export.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProductsModalDetails from "../../../components/Pages/Dashboard/Products/ProductsModals/ProductsModalDetails/ProductsModalDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditProduct,
  setSelectedProduct,
  setSelectedProductPrice,
} from "../../../store/product/productSlice";
import clsx from "clsx";
import DropdownFilters from "../../../components/Common/DropdownFilters/DropdownFilters";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchProduct } from "../../../api/product/product";
import { toast } from "react-toastify";
import ProductAddPriceModal from "../../../components/Pages/Dashboard/Products/ProductsModals/ProductsModalDetails/ProductDetails/ProductAddPriceModal";
import useTableFilters from "../../../hooks/useTableFilters";
import ProductsModalCreate from "../../../components/Pages/Dashboard/Products/ProductsModals/ProductsModalCreate/ProductsModalCreate";

const TABLE_FILTERS = [
  { label: "Active", column: "active", value: true },
  { label: "Archived", column: "active", value: false },
];

function Products() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    selected_product,
    refetch_product,
    selected_product_price,
    edit_product,
  } = useSelector((state) => state.product);
  const { id } = useParams();
  const [filters, setFilters] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create" 
  );
  const [refreshProduct, setRefreshProduct] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(
  );
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector(
    (state) => state.auth.current_account
  );

  const DASHBOARD_PATH = !live_mode_access ? "/dashboard/test" : "/dashboard";

  useFetchProduct({
    onSuccess: (data) => {
      dispatch(setSelectedProduct(data));
      if (location.pathname.endsWith("/edit"))
        dispatch(setEditProduct(data))
      setRefreshProduct(false);
    },
    onError: (error) => {
      setRefreshProduct(false);

      navigate(DASHBOARD_PATH + "/products");
      toast.error(error.response?.data.error.message);
    },
    id: id,
    enabled: refreshProduct,
    params: {
      include: ["prices"],
    },
  });

  const handleCloseModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedProduct(null));
    navigate(DASHBOARD_PATH + "/products");
  }, [dispatch, navigate]);

  useEffect(() => {
    console.log("showPriceModal", location.pathname);
    if (showPriceModal) {
      if (!location.pathname.endsWith("/create_price" ) && !location.pathname.endsWith("/edit_price") ) {
          if (selected_product_price) {
            navigate(location.pathname + "/edit_price");
          }
          else{
            navigate(location.pathname + "/create_price");
          }
      } 
    } else navigate(location.pathname.replace("/create_price", "").replace("/edit_price", ""));

  }, [showPriceModal]);
  useEffect(() => {

    console.log("showCreateModal", location.pathname);
    if (showCreateModal) {
      if (!location.pathname.endsWith("/create" ) && !location.pathname.endsWith("/edit") ) {
          if (edit_product) {
            navigate(location.pathname + "/edit");
          }
          else{
            navigate(location.pathname + "/create");
          }
      } 
    }
  }, [showCreateModal]);

  useEffect(() => {
    console.log("id", id);
    if (!id) {
      setShowCreateModal(!!edit_product);
      handleCloseModal();
      return;
    }

    // if (!selected_product) {
    //   setRefreshProduct(true);
    //   return;
    // }

    if (id !== "create" && id !== "edit") {
      setShowCreateModal(!!edit_product);

      setRefreshProduct(true);
      return;
    } else {
      if (id === "create") {
        setShowCreateModal(true);

        // navigate(DASHBOARD_PATH + "/products/create");
      }
    }

    // setRefreshProduct(true);
  }, [refetch_product, id, handleCloseModal, edit_product]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedProduct(null));
    };
  }, [dispatch]);

  useEffect(() => {
    setShowDetailsModal(!!selected_product);
  }, [selected_product]);

  useEffect(() => {
    setShowPriceModal(!!selected_product_price);
  }, [selected_product_price]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const handleAddPriceModalClose = () => {
    dispatch(setSelectedProductPrice(null));
    setShowPriceModal(false);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setEditProduct(null));
  };

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...activeFilter,
    };
  }, [filters, activeFilter]);

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Products</div>
              <span className="app-dashboard__card__header__filters">
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => setFilter(filter)}
                    className={clsx(
                      "app-dashboard__card__header__filters__item",
                      isFilterActive(filter) &&
                        "app-dashboard__card__header__filters__item--active"
                    )}
                  >
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
              <DropdownFilters
                filters={["date", "name", "shippable", "url", "metadata"]}
                onChange={handleFiltersChange}
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color="primary"
                outline
                icon="+"
              >
                <span className="text-dark">Add Product</span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
            </div>
          </div>
          <ProductsTable filters={getFilters} />
        </div>
        {showDetailsModal && (
          <ProductsModalDetails
            show={showDetailsModal}
            onClose={handleCloseModal}
            setShowPriceModal={setShowPriceModal}
          />
        )}
        {showPriceModal && (
          <ProductAddPriceModal
            show={showPriceModal}
            onClose={handleAddPriceModalClose}
          />
        )}
        {showCreateModal && (
          <ProductsModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
      </div>
    </div>
  );
}

export default Products;
