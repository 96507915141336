import { createSlice } from '@reduxjs/toolkit';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    selected_product: null,
    refetch_product: null,
    refetch_product_price: null,
    selected_product_price: null,
    delete_product_price: null,
    selected_product_price_details: null,
    edit_product: null,
  },
  reducers: {
    setSelectedProduct: (state, payload) => {
      state.selected_product = payload.payload;
    },
    setRefetchProduct: (state, payload) => {
      state.refetch_product = payload.payload;
    },
    setRefetchProductPrice: (state, payload) => {
      state.refetch_product_price = payload.payload;
    },
    setSelectedProductPrice: (state, payload) => {
      state.selected_product_price = payload.payload;
    },
    setDeleteProductPrice: (state, payload) => {
      state.delete_product_price = payload.payload;
    },
    setSelectedProductPriceDetails: (state, payload) => {
      state.selected_product_price_details =
        payload.payload;
    },
    setEditProduct: (state, payload) => {
      state.edit_product = payload.payload;
    },
  },
});

export const {
  setSelectedProduct,
  setRefetchProduct,
  setRefetchProductPrice,
  setSelectedProductPrice,
  setDeleteProductPrice,
  setSelectedProductPriceDetails,
  setEditProduct,
} = productSlice.actions;

export default productSlice.reducer;
