import appStore from "../../store";
import api from "../../utils/axios";
import {useQuery} from "react-query";

const fetchEvents = (params) => appStore.getState().app.livemode
  ? api.live.get("/events", { params })
  : api.sandbox.get("/events", { params });

export const useFetchEvents = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchEvents", params],
    () => fetchEvents(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchEvent = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/events/${id}`, { params })
    : api.sandbox.get(`/events/${id}`, { params });

export const useFetchEvent = ({ onSuccess, onError, enabled, id, params = null }) => {
  return useQuery(["fetchEvent", id], () => fetchEvent(id, params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};