import { ReactComponent as Aave } from 'assets/images/icons/cryptos/aave.svg';
import { ReactComponent as Avax } from 'assets/images/icons/cryptos/avax.svg';
import { ReactComponent as Ada } from 'assets/images/icons/cryptos/ada.svg';
import { ReactComponent as Bat } from 'assets/images/icons/cryptos/bat.svg';
import { ReactComponent as Bch } from 'assets/images/icons/cryptos/bch.svg';
import { ReactComponent as Bnb } from 'assets/images/icons/cryptos/bnb.svg';
import { ReactComponent as Btc } from 'assets/images/icons/cryptos/btc.svg';
import { ReactComponent as Busd } from 'assets/images/icons/cryptos/busd.svg';
import { ReactComponent as Cake } from 'assets/images/icons/cryptos/cake.svg';
import { ReactComponent as Cro } from 'assets/images/icons/cryptos/cro.svg';
import { ReactComponent as Doge } from 'assets/images/icons/cryptos/doge.svg';
import { ReactComponent as Dot } from 'assets/images/icons/cryptos/dot.svg';
import { ReactComponent as Eth } from 'assets/images/icons/cryptos/eth.svg';
// import { ReactComponent as Etc } from 'assets/images/icons/cryptos/etc.svg';
// import { ReactComponent as Fil } from 'assets/images/icons/cryptos/fil.svg';	
// import { ReactComponent as Gnt } from 'assets/images/icons/cryptos/gnt.svg';
import { ReactComponent as Kcs } from 'assets/images/icons/cryptos/kcs.svg';
import { ReactComponent as Link } from 'assets/images/icons/cryptos/link.svg';
import { ReactComponent as Ltc } from 'assets/images/icons/cryptos/ltc.svg';
import { ReactComponent as Matic } from 'assets/images/icons/cryptos/matic.svg';
import { ReactComponent as Ftm } from 'assets/images/icons/cryptos/ftm.svg';
import { ReactComponent as Sushi } from 'assets/images/icons/cryptos/sushi.svg';
import { ReactComponent as Trx } from 'assets/images/icons/cryptos/trx.svg';
import { ReactComponent as Uni } from 'assets/images/icons/cryptos/uni.svg';	
import { ReactComponent as Usdt } from 'assets/images/icons/cryptos/usdt.svg';
import { ReactComponent as Usdc } from 'assets/images/icons/cryptos/usdc.svg';
import { ReactComponent as Xlm } from 'assets/images/icons/cryptos/xlm.svg';
import { ReactComponent as Xrp } from 'assets/images/icons/cryptos/xrp.svg';

const crypto_currencies = [
	{
		label: 'AAVE',
		name: 'Aave',
		icon: <Aave/>
	},
	{
		label: 'AVAX',
		name: 'Avalanche',
		icon: <Avax/>
	},
	{
		label: 'ADA',
		name: 'Cardano',
		icon: <Ada/>
	},
	{
		label: 'BAT',
		name: 'Basic Attention Token',
		icon: <Bat/>
	},
	{
		label: 'BCH',
		name: 'Bitcoin Cash',
		icon: <Bch/>
	},
	{
		label: 'BNB',
		name: 'Binance Coin',
		icon: <Bnb/>
	},
	{
		label: 'BTC',
		name: 'Bitcoin',
		icon: <Btc/>
	},
	{
		label: 'BUSD',
		name: 'Binance USD',
		icon: <Busd/>
	},
	{
		label: 'CAKE',
		name: 'PancakeSwap',
		icon: <Cake/>
	},
	{
		label: 'CRO',
		name: 'Cronos',
		icon: <Cro/>
	},
	{
		label: 'DOGE',
		name: 'DogeCoin',
		icon: <Doge/>
	},
	{
		label: 'DOT',
		name: 'Polkadot',
		icon: <Dot/>
	},
	{
		label: 'ETH',
		name: 'Ethereum',
		icon: <Eth/>
	},
	{
		label: 'FTM',
		name: 'Fantom',
		icon: <Ftm/>
	},
	{
		label: 'LINK',
		name: 'ChainLink',
		icon: <Link/>
	},
	{
		label: 'LTC',
		name: 'Litecoin',
		icon: <Ltc/>
	},
	{
		label: 'MATIC',
		name: 'Polygon',
		icon: <Matic/>
	},
	
	{
		label: 'SUSHI',
		name: 'SushiSwap',
		icon: <Sushi/>
	},
	{
		label: 'TRX',
		name: 'Tron',
		icon: <Trx/>
	},
	{
		label: 'UNI',
		name: 'Uniswap',
		icon: <Uni/>
	},
	{
		label: 'USDT',
		name: 'Tether',
		icon: <Usdt/>
	},
	{
		label: 'USDC',
		name: 'USD Coin',
		icon: <Usdc/>
	},
	{
		label: 'XLM',
		name: 'Stellar',
		icon: <Xlm/>
	},
	{
		label: 'XRP',
		name: 'Ripple',
		icon: <Xrp/>
	},
	{
		label: 'KCS',
		name: 'KuCoin Token',
		icon: <Kcs/>
	},
	{
		label: 'WALIO',
		name: 'Walio',
		icon: <Kcs/>
	}
];

export default crypto_currencies;