import React from "react";

function CustomerDetailsSubscriptionsTable() {
  return (
    <div className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Subscriptions</div>
        <div className="app-modal-fullscreen__sm-link text-primary">Create</div>
      </div>
      <table className="app-modal-fullscreen__table">
        <thead>
        <tr>
          <th width="20%" className="app-modal-fullscreen__label">TITLE</th>
          <th width="20%" className="app-modal-fullscreen__label">BILLING</th>
          <th width="40%" className="app-modal-fullscreen__label">NEXT INVOICE</th>
          <th width="15%" className="app-modal-fullscreen__label">STATUS</th>
          <th width="10%" className="app-modal-fullscreen__label"/>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colSpan="5" className="app-modal-fullscreen__value mt-2 text-center">No Subscriptions found</td>
        </tr>
        {/*<tr>
                    <td className="app-modal-fullscreen__value font-semibold">
                      <span className="text-dark">Gold Subscription</span>
                    </td>
                    <td className="app-modal-fullscreen__value">Every month</td>
                    <td>
                      <div className="flex items-center">
                        <DateIcon className="mr-1.5"/>
                        <span className="app-modal-fullscreen__value">Sep 12, 2021 for £22.00</span>
                      </div>
                    </td>
                    <td>
                      <LabelTag color="primary">ACTIVE</LabelTag>
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <EditIcon/>
                        <DeleteIcon/>
                        <Dropdown
                          items={[
                            {label: "Update"},
                            {label: "Cancel"},
                            {label: "Pause"},
                            {label: "View Subscription"},
                          ]}
                        >
                          <Dropdown.Slot name="toggle">
                            <MoreIcon className="cursor-pointer" />
                          </Dropdown.Slot>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="app-modal-fullscreen__value font-semibold">
                      <span className="text-dark">Special Package</span>
                    </td>
                    <td className="app-modal-fullscreen__value">Every year</td>
                    <td>
                      <div className="flex items-center">
                        <DateIcon className="mr-1.5"/>
                        <span className="app-modal-fullscreen__value">Dec 3, 2021 for  £120.50</span>
                      </div>
                    </td>
                    <td>
                      <LabelTag color="danger">TRIAL END Sep 2</LabelTag>
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <EditIcon/>
                        <DeleteIcon/>
                        <Dropdown
                          items={[
                            {label: "Update"},
                            {label: "Cancel"},
                            {label: "Pause"},
                            {label: "View Subscription"},
                          ]}
                        >
                          <Dropdown.Slot name="toggle">
                            <MoreIcon className="cursor-pointer" />
                          </Dropdown.Slot>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>*/}
        </tbody>
      </table>
    </div>
  )
}

export default CustomerDetailsSubscriptionsTable;
