import Label from "../../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../../Common/Form/Input/Input";
import clsx from "clsx";
import ArrowIcon from "../../../../../../../../../assets/images/icons/dashboard/arrow_down.svg";
import { ReactComponent as CloseIcon } from "../../../../../../../../../assets/images/icons/modal-close.svg";
import { useEffect, useMemo, useState } from "react";
import {
  currencyConverter,
  currencyFormatter,
  currencySymbol,
  sanitizeUnitAmount
} from "../../../../../../../../../utils/string";
import {
  dropdownPriceDescription
} from "../../../../../../../../../utils/price_utils";
import SelectPrice from "../../../../../../../../Dashboard/SelectPrice/SelectPrice";
import SelectDiscounts from "../../../../../../../../Dashboard/SelectDiscounts/SelectDiscounts";
import SelectTaxRates from "../../../../../../../../Dashboard/SelectTaxRates/SelectTaxRates";
import DashboardButton from "../../../../../../../../Common/Form/DashboardButton/DashboardButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setRefetchInvoiceItems,
  setSelectedInvoiceItem,
  setShowProductsSelect,
} from "../../../../../../../../../store/invoice/invoiceSlice";
import { useStoreInvoiceItem } from "../../../../../../../../../api/customer/invoice";
import SelectProductsSelected from "../../../../../../../../Dashboard/SelectProductsSelected/SelectProductsSelected";
import { toast } from "react-toastify";
import * as yup from "yup";
import { PRICE_REGEX } from "../../../../../../../../../utils/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const formSchema = yup
  .object({
    price: yup
      .string()
      .required()
      .trim()
      // .matches(
      //   PRICE_REGEX,
      //   "Price must be greater than 0 and must not exceed 12 decimal places"
      // )
      .label("Price"),
    quantity: yup
      .number()
      .required()
      .min(1)
      .label("Quantity")
      .typeError("Quantity is a required field"),
  })
  .required();

function InvoiceItemsSelectedProduct() {
  const dispatch = useDispatch();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [priceData, setPriceData] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [showDiscountsSelect, setShowDiscountsSelect] = useState(true);
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(true);
  const {
    selected_invoice_item,
    selected_invoice_customer,
    draft_invoice,
    invoice_currency,
  } = useSelector((state) => state.invoice);

  const {
    watch,
    register,
    trigger,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: "",
      quantity: 1,
    },
    resolver: yupResolver(formSchema),
  });

  const price = watch("price");

  const { mutate: mutateStoreInvoiceItem, isLoading } = useStoreInvoiceItem();

  const getSelectedItemPricesOptions = useMemo(() => {
    if (!selected_invoice_item) return [];

    const prices = selected_invoice_item.prices.data;
    return prices
      .filter((price) => price.currency === draft_invoice.currency && price.type === 'one_time' && price.active)
      .map((price) => ({
        label: `${dropdownPriceDescription(price)}`,
        value: `${dropdownPriceDescription(price)}`,
        price,
      }));
  }, [selected_invoice_item, draft_invoice]);

  useEffect(() => {
    if (priceData === null) {
      setPriceData(getSelectedItemPricesOptions?.[0]?.price);
      setValue("price", getSelectedItemPricesOptions?.[0]?.value);
    }
  }, [priceData, getSelectedItemPricesOptions, setValue]);

  const handleDiscountSelect = (discount) => {
    setSelectedDiscount(discount);
    setShowDiscountsSelect(false);
  };

  const handleDeleteDiscountClick = () => {
    setSelectedDiscount(null);
    setShowDiscountsSelect(true);
  };

  const handleTaxRateSelect = (tax) => {
    if (selectedTaxRates.find((item) => item.id === tax.id)) {
      return;
    }

    setSelectedTaxRates([...selectedTaxRates, tax]);
    setShowTaxRatesSelect(false);
  };

  const handleDeleteTaxRateClick = (tax) => {
    setSelectedTaxRates(selectedTaxRates.filter((item) => item.id !== tax.id));
  };

  const handleAddTaxRateClick = () => {
    setShowTaxRatesSelect(true);
  };

  const handleCancelClick = () => {
    dispatch(setSelectedInvoiceItem(null));
    dispatch(setShowProductsSelect(false));
  };

  const handleQuantityChange = (e) => {
    setValue("quantity", e.target.value);
    trigger("quantity");
  };

  const handlePriceSelect = (item) => {
    if (item.price) {
      setPriceData(item.price);
      setValue("price", item.value);
      clearErrors("price");
      return;
    }

    setPriceData(item.value);
    setValue("price", item.value);
    trigger("price");
  };

  const handleProductSelect = (item) => {
    dispatch(setSelectedInvoiceItem(item));
  };

  const handleSaveClick = (formData, add_another = false) => {
    dispatch(setShowProductsSelect(false));

    const _data = {
      customer: selected_invoice_customer.id,
      quantity: formData.quantity,
      invoice: draft_invoice?.id,
    };

    if (selectedDiscount) {
      _data.discounts = [{ discount: selectedDiscount.id }];
    }

    if (selectedTaxRates.length) {
      _data.tax_rates = selectedTaxRates.map((tax) => tax.id);
    }

    if (priceData?.id) {
      _data.price = priceData.id;
    } else {
      let priceDataObj = {
        product: selected_invoice_item?.id,
        currency: invoice_currency,
      };

      const unitAmount = currencyConverter(invoice_currency, price, true);
      if (unitAmount.includes('.')) {
        priceDataObj.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
      } else priceDataObj.unit_amount = Number(unitAmount);

      _data.price_data = priceDataObj
    }

    mutateStoreInvoiceItem(_data, {
      onSuccess: () => {
        dispatch(setSelectedInvoiceItem(null));
        dispatch(setRefetchInvoiceItems(Date.now()));

        if (add_another) dispatch(setShowProductsSelect(true));
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  return (
    <>
      <div className="space-y-1.5 my-4 w-4/5">
        <div className="grid gap-5 grid-cols-6">
          <div className="col-span-3">
            <Label title="Item" />
            <SelectProductsSelected
              value={selected_invoice_item}
              placeholder="Find or add an item"
              currency={invoice_currency}
              type="one_time"
              onSelect={handleProductSelect}
            />
          </div>
          <div>
            <Label title="Qty" />
            <Input
              type="number"
              block
              {...register("quantity", {
                onChange: handleQuantityChange,
              })}
              className="text-light"
              placeholder="1"
              min="1"
              error={fieldError("quantity")}
            />
          </div>
          <div className="col-span-2">
            <Label title="Price" />
            <SelectPrice
              value={getSelectedItemPricesOptions?.[0]?.value}
              options={getSelectedItemPricesOptions}
              currency={invoice_currency}
              onSelect={handlePriceSelect}
              placeholder="00.00"
              min="0"
              error={fieldError("price")}
            />
          </div>
        </div>
        <div
          className={clsx(
            "app-invoices__modal__collapse pt-2",
            showAdvanced && "app-invoices__modal__collapse--open"
          )}
        >
          <div
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="app-invoices__modal__collapse__menu app-modal-fullscreen__small-title mb-3"
          >
            <span className="text-secondary">Items taxes and discounts</span>
            <img
              className="app-invoices__modal__collapse__menu__icon"
              src={ArrowIcon}
              alt=""
            />
          </div>
          <div className="space-y-8 app-invoices__modal__collapse__item">
            <div className="grid grid-cols-3 gap-6">
              <div>
                <Label title="Item taxes" />
                <div>
                  {selectedTaxRates.map((tax, index) => (
                    <div
                      key={index}
                      className="app-invoices__modal__discount-tax mb-1"
                    >
                      <div>
                        <span className="mr-2">{tax.display_name}</span>
                        {`(${tax.percent}%${tax.inclusive && ' incl.'})`}
                      </div>
                      <CloseIcon
                        onClick={() => handleDeleteTaxRateClick(tax)}
                      />
                    </div>
                  ))}
                </div>
                <div className="mt-2">
                  {showTaxRatesSelect ? (
                    <>
                      <SelectTaxRates
                        onSelect={handleTaxRateSelect}
                        data={selectedTaxRates.map((tax) => tax.id)}
                      />
                    </>
                  ) : (
                    <span
                      onClick={handleAddTaxRateClick}
                      className="app-invoices__modal__link"
                    >
                      Add another tax rate
                    </span>
                  )}
                </div>
              </div>
              <div>
                <Label title="Item discounts" />
                {selectedDiscount && (
                  <div>
                    <div className="app-invoices__modal__discount-tax mb-1">
                      <div>
                        <span className="mr-2">{selectedDiscount.name}</span>
                        {selectedDiscount.amount_off && (
                          <>
                            {`- ${currencySymbol(selectedDiscount.currency)} ${currencyFormatter(
                              selectedDiscount.currency,
                              selectedDiscount.amount_off
                            )} off`}
                          </>
                        )}
                        {selectedDiscount.percent_off && (
                          <>{`- ${selectedDiscount.percent_off}% off`}</>
                        )}
                      </div>
                      <CloseIcon onClick={() => handleDeleteDiscountClick()} />
                    </div>
                  </div>
                )}
                <div className="mt-2">
                  {!selectedDiscount && showDiscountsSelect && (
                    <SelectDiscounts onSelect={handleDiscountSelect} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="horizontal-divider" />
        <div className="flex justify-end items-center mt-4 gap-3">
          <div
            onClick={handleCancelClick}
            className="text-secondary font-semibold cursor-pointer"
          >
            Cancel
          </div>
          <DashboardButton
            onClick={() =>
              handleSubmit((data) => handleSaveClick(data, true))()
            }
            type="button"
            dense
            color="light"
          >
            Save and add another
          </DashboardButton>
          <DashboardButton
            disabled={isLoading}
            type="button"
            onClick={() => handleSubmit((data) => handleSaveClick(data))()}
            dense
            color="primary"
          >
            Save
          </DashboardButton>
        </div>
      </div>
    </>
  );
}

export default InvoiceItemsSelectedProduct;
