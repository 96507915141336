import Modal from "../../../Common/Modal/Modal";
import Button from "../../../Common/Form/Button/Button";
import Alert from "../../../Common/Alert/Alert";

function ModalDelete({
  show = false,
  onClose = null,
  onDelete = null,
  loading = false,
  title = "Delete",
  message = "Are you sure you want to delete this item?"
}) {
  const handleClose = () => {
    onClose && onClose();
  }

  const handleDeleteClick = () => {
    onDelete && onDelete();
  }

  return (
    <Modal show={show} onClose={handleClose}>
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {title}
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <Alert
          message={message}
          type="danger"
        />
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button
            onClick={handleDeleteClick}
            disabled={loading}
            type="button"
            color="danger"
          >
            Delete
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}

export default ModalDelete;