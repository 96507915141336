import DashboardNavbar from 'layouts/Dashboard/DashboardNavbar/DashboardNavbar'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DateIcon } from '../../../assets/images/icons/date.svg';
import ModalFullScreen from 'components/Common/ModalFullScreen/ModalFullScreen';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'components/Common/Form/Checkbox/Checkbox';
import DashboardButton from 'components/Common/Form/DashboardButton/DashboardButton';
import { dateFormatter } from 'utils/date';
import UpdatePassword from './Modals/UpdatePassword';
import Toggle2FA from './Modals/Toggle2FA';
import "./index.scss";

const UserProfile = () => {
  const [showUpadtePassword, setShowUpadtePassword] = useState(false);
  const [showEnable2FA, setShowEnable2FA] = useState(false);
  const [showDisable2FA, setShowDisable2FA] = useState(false);
  const { accounts, user } = useSelector((state) => state.auth);

  return (
    <div className='app-dashboard-container'>
      <DashboardNavbar />
      <div className='app-dashboard__content'>
        <div className='app-dashboard__card'>
          <div className='app-dashboard__card__header'>
            <div className='flex'>
              <div className='app-dashboard__card__title'>
                Profile Settings
              </div>
            </div>
            <div className='horizontal-divider mt-3 mb-6' />
          </div>
          <div className='app-customers__modal__body '>
            <div className='app-customers__modal__body__left'>
              <div className='space-y-5'>
                <div className='flex flex-col items-start'>
                  <div className='app-modal-fullscreen__big-title'>
                    {user?.first_name} {user?.last_name}
                  </div>
                  <div className='app-modal-fullscreen__subtitle'>
                    {user?.email}
                  </div>
                  <DashboardButton
                    onClick={() => console.log("edit")}
                    outline
                    color='light'
                    className='border-secondary text-secondary self-end'
                    size='sm'>
                    Edit
                  </DashboardButton>
                </div>
                <div className='horizontal-divider' />
                <div className='flex flex-col gap-2 pb-5' >
                  <div>
                    <div className='app-modal-fullscreen__label uppercase'>
                      email
                    </div>
                    <span className='app-modal-fullscreen__value'>
                      {user?.email}
                    </span>
                  </div>
                  <div>
                    <div className='app-modal-fullscreen__label uppercase'>
                      name
                    </div>
                    <span className='app-modal-fullscreen__value'>
                      {user?.first_name} {user?.last_name}
                    </span>
                  </div>
                  <div>
                    <div className='app-modal-fullscreen__label flex justify-between uppercase'>
                      password
                      <button className='capitalize text-secondary text-sm font-semibold' onClick={
                        () => setShowUpadtePassword(true)
                      }>
                        Update Password
                        {showUpadtePassword && <UpdatePassword
                          show={showUpadtePassword}
                          onClose={() => setShowUpadtePassword(false)}
                        />}
                      </button>
                    </div>
                    <span className='app-modal-fullscreen__value'>
                      ********
                    </span>
                  </div>
                  <div>
                    <div className='app-modal-fullscreen__label uppercase'>
                      VERIFIED
                    </div>
                    <span className='app-modal-fullscreen__value'>
                    </span>
                  </div>
                </div>
              </div>
              <div className='space-y-1.5 pb-7'>
                <div className='flex justify-between'>
                  <div className='app-modal-fullscreen__title'>
                    Two Factor Authentification
                  </div>
                </div>
                <div className='horizontal-divider' />
                <div className='flex items-center gap-2'>
                  <span>
                    Authenticator App
                  </span>
                  <span className='toggle mt-1.5'>
                    <label className="switch">
                      <input type="checkbox" checked={
                        user?.two_factor_authentication_enabled
                      } onChange={
                        (e) => {
                          if (e.target.checked) {
                            setShowEnable2FA(true)
                          } else {
                            setShowDisable2FA(true)
                          }
                        }
                      } />
                      <span className="slider round"></span>
                    </label>
                  </span>
                  <span className='app-modal-fullscreen__label'>
                    {user?.two_factor_authentication_enabled ? 'Disable' : 'Enable'}
                  </span>
                  {showEnable2FA && <Toggle2FA
                    show={showEnable2FA}
                    onClose={() => setShowEnable2FA(false)}
                    type='enable'
                  />}
                  {showDisable2FA && <Toggle2FA
                    show={showDisable2FA}
                    onClose={() => setShowDisable2FA(false)}
                    type='disable'
                  />}
                </div>
              </div>
            </div>
            <div className='app-customers__modal__body__right'>
              <div className='mb-10'>
                <div className='pb-3'>
                  <div className='app-modal-fullscreen__title'>
                    Login History
                  </div>
                </div>
                <div className='horizontal-divider' />
                <div className=''>
                  <table className='w-full border-collapse text-light'>
                    <thead>
                      <tr className='border-b border-[#b0b5be37]'>
                        <th className='uppercase font-normal text-sm text-left py-3'>Location</th>
                        <th className='uppercase font-normal text-sm text-left py-3'>Device (OS)</th>
                        <th className='uppercase font-normal text-sm text-left py-3'>IP Address</th>
                        <th className='uppercase font-normal text-sm text-left py-3'>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user?.login_history?.map((login, index) =>
                      (<>
                        <tr key={index} className='border-b border-[#b0b5be37]'>
                          <td className='text-left py-3 text-dark font-semibold'>{login?.location?.city_name}, {login?.location?.country}</td>
                          <td className='text-left py-3'>{login?.user_agent.split(';').at(0)}</td>
                          <td className='text-left py-3'>{login?.ip}</td>
                          <td className='text-left py-3'>
                            {dateFormatter(login?.date, true)}
                          </td>
                        </tr>
                      </>)
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className='pb-3'>
                  <div className='app-modal-fullscreen__title'>
                    Accounts
                  </div>
                  <div className='app-modal-fullscreen__label'>Those are the accounts you are a member of.</div>
                </div>
                <div className='horizontal-divider' />
                <div className=''>
                  <table className='w-full border-collapse text-light'>
                    <thead>
                      <tr className='border-b border-[#b0b5be37]'>
                        <th className='uppercase font-normal text-sm text-left py-3'>name</th>
                        <th className='uppercase font-normal text-sm text-left py-3'>role</th>
                        <th className='uppercase font-normal text-sm text-left py-3'>id</th>
                        <th className='uppercase font-normal text-sm text-left py-3'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts?.map((account, index) =>
                      (<>
                        <tr key={index} className='border-b border-[#b0b5be37]'>
                          <td className='text-left py-3 text-dark font-semibold'>{account?.name}</td>
                          <td className='text-left py-3'>{account?.role}</td>
                          <td className='text-left py-3'>{account?.id}</td>
                          <td className='text-left py-3'>
                          </td>
                        </tr>
                      </>)
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile