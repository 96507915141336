import ModalFullScreen from '../../../../../../Common/ModalFullScreen/ModalFullScreen';
import Button from '../../../../../../Common/Form/Button/Button';
import ArrowIcon from '../../../../../../../assets/images/icons/dashboard/arrow_down.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDraftInvoice,
  setInvoiceAcceptedCryptos,
  setInvoiceCustomFields,
  setInvoiceLineItems,
  setRefetchInvoice,
  setRefetchInvoices,
} from '../../../../../../../store/invoice/invoiceSlice';
import { setShowCustomerForm } from '../../../../../../../store/customer/customerSlice';
import CustomersModalCreate from '../../../../Customers/CustomersModals/CustomersModalCreate/CustomersModalCreate';
import DashboardButton from '../../../../../../Common/Form/DashboardButton/DashboardButton';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useFetchInvoice,
  useFetchInvoiceHostedData,
  useFetchInvoiceLineItems,
  useIssueInvoice,
  useStoreInvoice,
} from '../../../../../../../api/customer/invoice';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import InvoiceItems from './InvoiceItems/InvoiceItems';
import InvoiceCustomer from './InvoicesModalCreateForm/InvoiceCustomer';
import InvoicePaymentMethod from './InvoicesModalCreateForm/InvoicePaymentMethod';
import InvoiceDueDate from './InvoicesModalCreateForm/InvoiceDueDate';
import InvoiceCryptoPadding from './InvoicesModalCreateForm/InvoiceCryptoPadding';
import InvoiceAcceptedCryptos from './InvoicesModalCreateForm/InvoiceAcceptedCryptos';
import InvoiceMemo from './InvoicesModalCreateForm/InvoiceMemo';
import InvoiceCustomFields from './InvoicesModalCreateForm/InvoiceCustomFields';
import InvoiceFooter from './InvoicesModalCreateForm/InvoiceFooter';
import InvoicePreview from './InvoicePreview/InvoicePreview';
import InvoicePartialPayment from './InvoicesModalCreateForm/InvoicePartialPayment';
import InvoiceDiscounts from './InvoiceDiscounts/InvoiceDiscounts';
import InvoiceTaxRates from './InvoiceTaxRates/InvoiceTaxRates';
import InvoiceShippingRates from './InvoiceShippingRates/InvoiceShippingRates';
import ReactTooltip from 'react-tooltip';
import { useLocation, useNavigate } from 'react-router-dom';

function InvoicesModalCreate({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [fetchHostedData, setFetchHostedData] =
    useState(false);
  const [hostedData, setHostedData] = useState(null);
  const {
    selected_invoice_customer,
    draft_invoice,
    refetch_invoice_items,
    invoice_currency,
    invoice_accepted_cryptos,
    invoice_custom_fields,
    selected_invoice_item,
    select_one_time,
    select_new_product,
  } = useSelector((state) => state.invoice);
  const { show_customer_form } = useSelector(
    (state) => state.customer
  );
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    return () => {
      navigate(location.pathname.replace("/create", "").replace("/edit", ""));

    }
  }, [])
  const { current_account } = useSelector((state) => state.auth);

  const { handleSubmit, watch, register, setValue } =
    useForm({
      defaultValues: {
        due_date: draft_invoice?.due_date ?? null,
        days_until_due:
          draft_invoice ? (draft_invoice.days_until_due ?? null) : (current_account?.invoice_settings?.default_days_due ?? null),
        partial_payment:
          draft_invoice?.partial_payment ?? false,
        sending_method:
          draft_invoice ? draft_invoice.sending_method : (current_account?.invoice_settings?.send_invoice ? 'auto' : 'manual'),
        crypto_payment_padding: draft_invoice ? draft_invoice.crypto_payment_padding : (current_account?.settings?.default_crypto_payment_padding ?? 0),
        memo: draft_invoice ? draft_invoice.memo : current_account?.invoice_settings?.default_memo,
        footer: draft_invoice ? draft_invoice.footer : current_account?.invoice_settings?.default_footer,
      },
    });

  useEffect(() => {
    dispatch(
      setInvoiceAcceptedCryptos(
        draft_invoice ? draft_invoice.crypto_payment_currencies : (current_account?.settings?.default_crypto_payment_currencies ?? [])
      )
    );
    dispatch(
      setInvoiceCustomFields(
        draft_invoice ? (draft_invoice.custom_fields
          ? Object.entries(
              draft_invoice?.custom_fields
            ).map(([k, v]) => ({
              [k]: v,
            })) : [])
          : (current_account?.invoice_settings?.default_custom_fields && Object.keys(current_account.invoice_settings.default_custom_fields).length > 0 
          ? Object.entries(current_account.invoice_settings.default_custom_fields).map(([k, v]) => ({
            [k]: v,
          })) : [])
      )
    );
  }, [dispatch, draft_invoice]);

  useEffect(() => {
    register('due_date');
    register('days_until_due');
  }, [register]);

  const due_date = watch('due_date');
  const days_until_due = watch('days_until_due');

  const isCreateDraftVisible = useMemo(() => {
    return (
      !draft_invoice &&
      invoice_currency &&
      selected_invoice_customer
    );
  }, [
    draft_invoice,
    invoice_currency,
    selected_invoice_customer,
  ]);

  const {
    mutate: mutateStoreInvoice,
    isLoading: isStoreLoading,
  } = useStoreInvoice();
  const {
    mutate: mutateIssueInvoice,
    isLoading: isIssueLoading,
  } = useIssueInvoice(draft_invoice?.id);
  const { refetch: refetchInvoiceLineItems } =
    useFetchInvoiceLineItems({
      onSuccess: (data) =>
        dispatch(setInvoiceLineItems(data.reverse())),
      onError: () => null,
      enabled: !!draft_invoice,
      id: draft_invoice?.id,
      params: {
        expand: [
          'price',
          'product',
          'discounts',
          'tax_rates',
        ],
      },
    });

  const { refetch: refetchInvoice } = useFetchInvoice({
    onSuccess: (data) => dispatch(setDraftInvoice(data)),
    onError: () => null,
    enabled: false,
    id: draft_invoice?.id,
  });

  useFetchInvoiceHostedData({
    onSuccess: (data) => setHostedData(data),
    enabled: fetchHostedData,
    params: {
      merchant: draft_invoice?.merchant,
      secret: draft_invoice?.secret,
    },
  });

  useEffect(() => {
    if (draft_invoice) {
      refetchInvoice();
      setFetchHostedData(true);
    }
  }, [
    refetch_invoice_items,
    refetchInvoice,
    draft_invoice,
  ]);

  useEffect(() => {
    if (refetch_invoice_items !== null)
      refetchInvoiceLineItems();
  }, [refetch_invoice_items, refetchInvoiceLineItems]);

  const handleClose = () => {
    dispatch(setRefetchInvoices(Date.now()));
    onClose && onClose();
  };

  const handleCreateModalClose = () => {
    dispatch(setShowCustomerForm(false));
  };

  const handleCreateDraftClick = (formData) => {
    // Clean up custom fields form empty keys
    const _filteredCustomFields =
      invoice_custom_fields.filter(
        (data) => Object.keys(data)[0] !== ''
      );

    // Flatten the array into single object
    const _customFields = Object.assign(
      {},
      ..._filteredCustomFields
    );

    // Clean up custom fields form empty or null values
    const _formData = JSON.parse(JSON.stringify(formData));
    Object.keys(_formData).forEach(
      (key) =>
        (_formData[key] === '' ||
          _formData[key] === null) &&
        delete _formData[key]
    );

    if (invoice_accepted_cryptos.length)
      _formData.crypto_payment_currencies =
        invoice_accepted_cryptos;
    _formData.customer = selected_invoice_customer?.id;
    _formData.currency = invoice_currency;
    _formData.custom_fields = _customFields;

    mutateStoreInvoice(_formData, {
      onSuccess: (data) => {
        dispatch(setDraftInvoice(data.data));
        dispatch(setRefetchInvoices(Date.now()));
        toast.success('Invoice draft created successfully');
        setFetchHostedData(true);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleIssueInvoiceClick = () => {
    if (!draft_invoice) return;

    mutateIssueInvoice(null, {
      onSuccess: () => {
        handleClose();
        dispatch(setRefetchInvoices(Date.now()));
        dispatch(setRefetchInvoice(Date.now()));
        toast.success('The invoice has been issued');
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const isAddItemVisible = useMemo(() => {
    return (
      !selected_invoice_item &&
      !select_one_time &&
      !select_new_product
    );
  }, [
    selected_invoice_item,
    select_one_time,
    select_new_product,
  ]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <ModalFullScreen
        show={show}
        onClose={handleClose}
        fullscreen>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='flex items-center'>
              <div className='app-modal-fullscreen__header__title'>
                {draft_invoice ? 'Edit' : 'Create an'}{' '}
                invoice
              </div>
            </div>
            <div>
              <Button
                onClick={handleIssueInvoiceClick}
                disabled={!draft_invoice || isIssueLoading}>
                Issue Invoice
              </Button>
            </div>
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div
            className='app-container app-invoices__modal__body'
            style={{
              height: 'calc(100vh - 172px)',
              overflow: 'hidden',
            }}>
            <form
              id='new-invoice'
              onSubmit={handleSubmit(
                handleCreateDraftClick
              )}
              className='app-invoices__modal__body__left overflow-y-scroll'>
              <div className='space-y-7'>
                <div
                  className={clsx(
                    !isAddItemVisible && 'app-overlay'
                  )}>
                  <div className='app-modal-fullscreen__title mb-2.5'>
                    Customer
                  </div>
                  <InvoiceCustomer register={register} />
                </div>
                {isCreateDraftVisible && (
                  <DashboardButton
                    form='new-invoice'
                    type='submit'
                    disabled={isStoreLoading}
                    dense>
                    Create draft invoice
                  </DashboardButton>
                )}
                <div
                  className={clsx(
                    !draft_invoice && 'app-overlay'
                  )}>
                  <InvoiceItems />
                  <div
                    className={clsx(
                      'grid grid-cols-6 pr-5 pt-10',
                      !isAddItemVisible && 'app-overlay'
                    )}>
                    <div className='col-start-4 col-span-3 space-y-2'>
                      <InvoiceDiscounts
                        refetchInvoice={refetchInvoice}
                      />
                      <InvoiceTaxRates
                        refetchInvoice={refetchInvoice}
                      />
                      <InvoiceShippingRates
                        refetchInvoice={refetchInvoice}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={clsx(
                    'pb-7 pt-2',
                    !isAddItemVisible && 'app-overlay'
                  )}>
                  <div className='pr-5'>
                    <InvoicePaymentMethod
                      register={register}
                    />
                    <div className='space-y-3 mt-3'>
                      <InvoiceDueDate
                        due_date={due_date}
                        days_until_due={days_until_due ?? current_account?.invoice_settings?.default_days_due }
                        setValue={setValue}
                      />
                      <InvoicePartialPayment
                        register={register}
                      />
                      <InvoiceCryptoPadding
                        register={register}
                      />
                      <InvoiceAcceptedCryptos />
                    </div>
                    <div className='space-y-8 mt-8 w-1/2'>
                      <div className='horizontal-divider' />
                      <div
                        className={clsx(
                          'app-invoices__modal__collapse',
                          showAdvanced &&
                            'app-invoices__modal__collapse--open'
                        )}>
                        <div
                          onClick={() =>
                            setShowAdvanced(!showAdvanced)
                          }
                          className='app-invoices__modal__collapse__menu app-modal-fullscreen__title mb-3'>
                          <span>Advanced options</span>
                          <img
                            className='app-invoices__modal__collapse__menu__icon'
                            src={ArrowIcon}
                            alt=''
                          />
                        </div>
                        <div className='space-y-8 app-invoices__modal__collapse__item'>
                          <InvoiceMemo
                            register={register}
                          />
                          <InvoiceCustomFields />
                          <InvoiceFooter
                            register={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className='app-invoices__modal__body__right overflow-auto px-5 pb-5'>
              <InvoicePreview hostedData={hostedData} />
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {show_customer_form && (
        <CustomersModalCreate
          show={show_customer_form}
          onClose={handleCreateModalClose}
        />
      )}
    </>
  );
}

export default InvoicesModalCreate;
