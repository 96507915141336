import { useSelector } from 'react-redux';
import {
  capitalize,
} from 'utils/string';
import { blockchainNetwork } from 'utils/crypto_utils';
import moment from 'moment';
import { useEffect, useState , useMemo , useRef } from 'react';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import Logo from "assets/images/logo-small.svg";
import QRCodeStyling from "qr-code-styling";
import CopyToClipboardBoxID from 'components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';

const TopupMethod = () => {
  const { selected_topup } = useSelector(
    (state) => state.topup
  );
  const [expiryTime, setExpiryTime] = useState(null);
  const qrRef = useRef(null);

  const handleNewEstimates = (estimates) => {
  }
  useEffect(() => {

    const interval = setInterval(() => {
      const diff = moment
        .unix(selected_topup?.blockchain_transaction?.expiry)
        .diff(moment(), "seconds");
      const estimation = moment
        .duration(diff, "seconds")
      setExpiryTime({
        minutes: estimation._data.minutes,
        seconds: estimation._data.seconds,
      });
    }, 1000);


    return () => clearInterval(interval);
  }, [selected_topup]);

  // const getExpiry = (selected_topup) => {
  //   const diff = moment
  //     .unix(selected_topup?.blockchain_transaction?.expiry)
  //     .diff(moment(), "seconds");
  //   const estimation = moment
  //     .duration(diff, "seconds");
  //   return {
  //       minutes: estimation._data.minutes,
  //       seconds: estimation._data.seconds,
  //     }  
  // }
  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 180,
        height: 180,
        type: "svg",
        data: selected_topup?.blockchain_transaction?.to_addr,
        image: Logo,
        dotsOptions: {
          color: "#000",
          type: "rounded",
        },
        cornersSquareOptions: { color: "#47CE7D", type: "extra-rounded" },
        cornersDotOptions: { color: "#6d5dd9" },
        // imageOptions: {
        //   crossOrigin: "anonymous",
        //   margin: 2,
        // },
      }),
    [selected_topup]
  );
  useEffect(() => {
    qrCode.append(qrRef.current);
  }, [qrCode]
  );
  return (
    <div className='space-y-1.5 pb-2'>
      <div className='app-modal-fullscreen__title'>
        Topup Method
      </div>
      <div className='horizontal-divider' />
      <div className='pt-4'>
        {selected_topup.method === 'crypto' && 
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 space-y-2'>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transaction ID
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_topup?.blockchain_transaction?.id ?? '-'}
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Method
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {capitalize(selected_topup.method)}
                </div>
              </div>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Network
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_topup?.blockchain_transaction?.network ? blockchainNetwork(
                    selected_topup.blockchain_transaction.network
                  ) : '-'}
                </div>
              </div>
              {selected_topup?.blockchain_transaction?.status === 'confirming' &&
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Status
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  Confirming - {`(${selected_topup.blockchain_transaction.confirmations} / ${selected_topup.blockchain_transaction.confirmations_required}) confirmations`}
                </div>
              </div>}
              {selected_topup.status === "pending" && <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Expiry
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='app-invoice__form__estimation'>
                    {expiryTime?.minutes > 0 && expiryTime?.seconds > 0 ? (
                      <div className='flex  justify-between items-center'>

                        <div className="app-invoice__form__estimation__header">
                          Expires
                        </div>
                        <div className="flex items-start gap-2">
                          <DateIcon className="mt-1.5" />
                          <div className="app-invoice__form__estimation__time">
                            <span>{expiryTime?.minutes}</span>
                            <div className="app-invoice__form__estimation__time__unit">
                              min
                            </div>
                          </div>
                          <span>:</span> 
                          <div className="app-invoice__form__estimation__time">
                            <span>{expiryTime?.seconds}</span>
                            <div className="app-invoice__form__estimation__time__unit">
                              sec
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="app-invoice__form__estimation__header">
                          Estimate expired
                        </div>
                        <div>
                          <span
                            onClick={handleNewEstimates}
                            className="font-semibold underline cursor-pointer"
                          >
                            Get new estimates
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>}
              
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Address
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {
                    selected_topup?.blockchain_transaction
                      ?.from_addr ? selected_topup.blockchain_transaction
                      .from_addr : '-'
                  }
                </div>
              </div>
              {selected_topup?.blockchain_transaction
                ?.memo && (
                  <div className='app-modal-fullscreen__kv'>
                    <div className='app-modal-fullscreen__kv__key'>
                      Transaction Memo
                    </div>
                    <div className='app-modal-fullscreen__kv__value'>
                      {
                        selected_topup.blockchain_transaction
                          .memo
                      }
                    </div>
                  </div>
                )}
              {selected_topup?.status === "pending" &&   <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  QR code
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                <div ref={qrRef} />
                {/* <CopyToClipboardBoxID text={selected_topup?.blockchain_transaction?.to_addr} /> */}
                </div>
              </div>}
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transaction Hash
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_topup?.blockchain_transaction?.tx_hash ? (selected_topup.blockchain_transaction
                    .explorer ? (
                    <a
                      href={
                        selected_topup
                          .blockchain_transaction.explorer
                      }
                      target='_blank'
                      rel='noreferrer'
                      className='text-tertiary'>
                      {
                        selected_topup
                          .blockchain_transaction.tx_hash
                      }
                    </a>
                  ) : (
                    <>
                      {
                        selected_topup
                          .blockchain_transaction.tx_hash
                      }
                    </>
                  )) : '-'}
                </div>
              </div>
            </div>
          </div>
        }
        {selected_topup.method === 'crypto' && 
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 space-y-2'>
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Sending Merchant
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_topup?.internal_account ?? '-'}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default TopupMethod;
