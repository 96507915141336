import Dropdown from '../../../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../../../assets/images/icons/dashboard/more.svg';
import SelectCustomer from '../../../../../../../Dashboard/SelectCustomer/SelectCustomer';
import Label from '../../../../../../../Common/Form/Label/Label';
import Select from '../../../../../../../Common/Form/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInvoiceCurrency,
  setSelectedInvoiceCustomer,
} from '../../../../../../../../store/invoice/invoiceSlice';
import {
  setSelectedCustomer,
  setShowCustomerForm,
} from '../../../../../../../../store/customer/customerSlice';
import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import Checkbox from '../../../../../../../Common/Form/Checkbox/Checkbox';
import InfoIcon from '../../../../../../../../assets/images/icons/info.svg';
import { FIAT_CURRENCIES_SELECT } from '../../../../../../../../utils/constants';

function InvoiceCustomer({ register }) {
  const dispatch = useDispatch();
  const { current_account } = useSelector(
    (state) => state.auth
  );
  const {
    selected_invoice_customer,
    invoice_currency,
    draft_invoice,
  } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (draft_invoice) return;

    dispatch(
      setInvoiceCurrency(
        current_account.settings.reporting_currency ?? ''
      )
    );
  }, [
    draft_invoice,
    current_account.settings.reporting_currency,
    dispatch,
  ]);

  const handleCustomerSelect = (option) => {
    dispatch(setSelectedInvoiceCustomer(option));
  };

  const handleSwitchCustomerClick = useCallback(() => {
    dispatch(setSelectedInvoiceCustomer(null));
  }, [dispatch]);

  const handleEditCustomerClick = useCallback(() => {
    dispatch(
      setSelectedCustomer(selected_invoice_customer)
    );
    dispatch(setShowCustomerForm(true));
  }, [dispatch, selected_invoice_customer]);

  const handleCurrencySelect = (item) => {
    dispatch(setInvoiceCurrency(item.value));
  };

  const dropDownItems = useMemo(() => {
    const _items = [
      {
        label: 'Edit customer information',
        onClick: handleEditCustomerClick,
      },
    ];

    if (!draft_invoice) {
      _items.push({
        label: 'Switch customer',
        onClick: handleSwitchCustomerClick,
      });
    }

    return _items;
  }, [
    draft_invoice,
    handleEditCustomerClick,
    handleSwitchCustomerClick,
  ]);

  const getCurrency = useMemo(() => {
    return draft_invoice
      ? draft_invoice?.currency
      : invoice_currency;
  }, [draft_invoice, invoice_currency]);

  return (
    <>
      <div className='grid grid-cols-3 items-end gap-6'>
        {selected_invoice_customer && (
          <div className='flex items-center gap-6'>
            <div>
              <div className='app-modal-fullscreen__text'>
                {selected_invoice_customer.name}
              </div>
              <div className='app-modal-fullscreen__value'>
                {selected_invoice_customer.email}&nbsp;
              </div>
            </div>
            <Dropdown items={dropDownItems}>
              <Dropdown.Slot name='toggle'>
                <MoreIcon className='cursor-pointer' />
              </Dropdown.Slot>
            </Dropdown>
          </div>
        )}
        {!selected_invoice_customer && (
          <SelectCustomer
            placeholder='Find or add a customer'
            onSelect={handleCustomerSelect}
          />
        )}
        <div>
          <Label title='Currency' />
          <Select
            className='z-30'
            disabled={draft_invoice}
            value={getCurrency}
            options={FIAT_CURRENCIES_SELECT}
            onSelect={handleCurrencySelect}
          />
        </div>
      </div>
      {!draft_invoice && (
        <div className='flex items-center gap-3 mt-4'>
        <Checkbox
          {...register('include_pending_items')}
          color='primary'
          size='sm'
          className='mt-1'
          disabled={draft_invoice}
        />
        <div className='text-light text-sm'>
          Include pending invoice items
        </div>
        <img
          data-tip='Create an invoice that includes any pending invoice items in the selected currency that the customer has not been billed for yet'
          data-for='invoice-modal-create'
          src={InfoIcon}
          alt='Info'
        />
      </div>
      )}
    </>
  );
}

export default InvoiceCustomer;
