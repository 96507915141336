import appStore from "../../store";
import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";

const fetchNotes = (params) => appStore.getState().app.livemode
  ? api.live.get("/notes", { params })
  : api.sandbox.get("/notes", { params });

export const useFetchNotes = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchNotes", params],
    () => fetchNotes(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storeNote = (data) => appStore.getState().app.livemode
  ? api.live.post("/notes", data)
  : api.sandbox.post("/notes", data);

export const useStoreNote = () => {
  return useMutation(
    "storeNote",
    storeNote
  );
}
