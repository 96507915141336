import { useDeleteApiKey, useDeleteAtApiKey, useRollApiKey } from 'api/api_key/api_key'
import { useGetVerficationCode } from 'api/merchant/merchant'
import Button from 'components/Common/Form/Button/Button'
import InfoIcon from "assets/images/icons/info.svg";
import Input from 'components/Common/Form/Input/Input'
import InputDatePicker from 'components/Common/Form/InputDatePicker/InputDatePicker'
import Label from 'components/Common/Form/Label/Label'
import Radio from 'components/Common/Form/Radio/Radio'
import ReactTooltip from "react-tooltip";
import Switch from 'components/Common/Form/Switch/Switch'
import Modal from 'components/Common/Modal/Modal'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'


export default function ApikeyRollModal({
  show = false,
  onClose = null,
  isDelete = false,
  selectedApiKey = null,
}) {
  const getVerficationCode = useGetVerficationCode()
  const { current_account, user } = useSelector((state) => state.auth);
  const [deleteAt, setDeleteAt] = React.useState(false)
  const [date, setDate] = React.useState()
  const [time, setTime] = React.useState()
  const [timezone, setTimezone] = React.useState("UTC")
  const [otp, setOtp] = React.useState()
  const [verificationCode, setVerificationCode] = React.useState()
  const rollApiKey = useRollApiKey()
  const deleteApiKey = useDeleteApiKey()
  const deleteAtApiKey = useDeleteAtApiKey()
  const [errors, setErrors] = React.useState({
    otp: "",
    verificationCode: "",
    date: "",
  });

  const handleErrors = (data) => {
    if (data.error.param === "otp") {
      setErrors({
        ...errors,
        otp: data.error.message
      })
    }
    if (data.error.param === "verification_code") {
      setErrors({
        ...errors,
        verificationCode: data.error.message
      })
    }
  }

  const handleClose = () => {
    onClose && onClose()
  }

  useEffect(() => {
    console.log(current_account)
  }, [current_account]);

  const getCode = () => {
    getVerficationCode.mutate({
      reason: "delete_key"
    }, {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  };

  const getClientTimeZone = useMemo(() => {
    return moment().tz(current_account.timezone).zoneAbbr();
  }, [current_account]);

  const handleSubmit = () => {
    let data = {
      id: selectedApiKey.id,
      otp: otp ? otp : undefined,
      verification_code: verificationCode ? verificationCode : undefined,
    }

    if (deleteAt) {
      if (!date || !time) {
        setErrors((prev) => ({
          ...prev,
          date: "Date and time is required"
        }))
        return
      }
      const dateOnly = moment(date).format("YYYY-MM-DD")
      let unixTime = parseInt(moment(`${dateOnly} ${time} ${timezone}`).format("X"))

      if (moment().format("X") > unixTime) {
        setErrors((prev) => ({
          ...prev,
          date: "Selected time must be in the future"
        }))
        return
      } else {
        setErrors((prev) => ({
          ...prev,
          date: ""
        }))
      }

      data = {
        delete_at: unixTime,
        ...data,
      }
    }

    if (isDelete) {
      if (deleteAt) {
        deleteAtApiKey.mutate({
          id: selectedApiKey.id,
          otp: otp,
          verification_code: verificationCode,
          delete_at: data.delete_at
        }, {
          onSuccess: (data) => {
            if (data.status === 200) {
              handleClose()
            } else if (data.status === 206) {
              handleErrors(data.data)
            }
          },
          onError: (error) => {
            console.log(error)
          }
        })
      } else {
        deleteApiKey.mutate(data, {
          onSuccess: (data) => {
            if (data.status === 200) {
              handleClose()
            } else if (data.status === 206) {
              handleErrors(data.data)
            }
          },
          onError: (error) => {
            console.log(error)
          }
        });
      }
    } else {
      if (deleteAt) {
        console.log(data)
        rollApiKey.mutate({
          id: selectedApiKey.id,
          otp: otp,
          delete_at: data.delete_at
        }, {
          onSuccess: (data) => {
            console.log(data)
            if (data.status === 200) {
              handleClose()
            } else if (data.status === 206) {
              handleErrors(data.data)
            }
          },
          onError: (error) => {
            console.log(error)
          }
        });
      } else {
        rollApiKey.mutate({
          id: selectedApiKey.id,
          otp: otp,
        }, {
          onSuccess: (data) => {
            if (data.status === 200) {
              handleClose()
            } else if (data.status === 206) {
              handleErrors(data.data)
            }
          },
          onError: (error) => {
            console.log(error)
          }
        });
      }
    }
  }

  useEffect(() => {
    if (selectedApiKey && selectedApiKey.delete_at_unix) {
      const date = moment.unix(selectedApiKey.delete_at_unix).toDate()
      const time = moment.unix(selectedApiKey.delete_at_unix).format("HH:mm")
      setDate(date)
      setTime(time)
      setTimezone("UTC")
      setDeleteAt(true)
    }

    ReactTooltip.rebuild();
  }, [selectedApiKey]);

  return (
    <Modal style={{ overflow: "visible" }} show={show} onClose={handleClose} size='md'>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
            {isDelete ? "Delete API Key" : "Roll API Key"}
          </div>
        </div>
      </Modal.Slot>
      
      <Modal.Slot name='body'>
        <div className="text-xs bg-red-100 text-red-400 w-full p-3 border-t border-b border-red-200 absolute top-0 left-0">
          {isDelete
            ? "By deleting this API KEY, it will not longer be able to make requests for your application. Api keys will be deleted within 5 minutes of the selected time or within 5 minutes from deleting immediately."
            : "By rolling this API KEY, it will not longer be able to make requests for your application. A new API key will be generated."}
        </div>
        <form className='w-full relative z-[1000] mt-12'>
          <div className='flex flex-col gap-1'>
            <div className='flex items-center mb-2'>
              <Switch
                color='primary'
                checked={deleteAt}
                onChange={(e) => {
                  setDeleteAt(e.target.checked)
                }}
              />
              <Label
                title={`Delete at ${errors.date ? `(${errors.date})` : ""}`}
                tooltip={`Choose to delete this API key at a specific date, up to 90 days in the future.${isDelete ? 'A new API key will be created to replace this key immediately.' : ''}`}
                tooltipFor="tooltip-api"
                icon={InfoIcon}
                tooltipHtml={true}
                error={errors.date}
              />
            </div>
            {deleteAt && (
              <div className='flex gap-3 '>
                <div className=''>
                  <Label
                    title='Date'
                  />
                  <InputDatePicker
                    className=''
                    min={moment().toDate()}
                    max={moment().add(3, 'months').toDate()}
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={(date) => {
                      setDate(date)
                    }}
                  />
                </div>
                <div className=''>
                  <Label
                    title='Time'
                  />
                  <Input
                    type='time'
                    placeholder='Time'
                    value={time}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setTime(e.target.value)
                    }}
                  />
                </div>
                <div className=''>
                  <Label
                    title='Timezone'
                  />
                  <div className='flex items-center gap-4 mt-3'>
                    <div className='flex items-center'>
                      <Radio
                        color="primary"
                        value={"UTC"}
                        className="cursor-pointer"
                        checked={timezone === "UTC"}
                        onChange={(e) => {
                          setTimezone(e.target.value)
                        }}
                      />
                      <span className='-ml-1 text-sm'>
                        UTC
                      </span>
                    </div>
                    <div className='flex items-center'>
                      <Radio
                        color="primary"
                        value={getClientTimeZone}
                        className="cursor-pointer"
                        checked={timezone === getClientTimeZone}
                        onChange={(e) => {
                          setTimezone(e.target.value)
                        }}
                      />
                      <span className='-ml-1 text-sm'>
                        {getClientTimeZone}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {user?.two_factor_authentication_enabled && (
            <div className='w-full mt-4'>
              <Label
                title='2FA Code'
                error={errors.otp}
              />
              <Input
                className='w-full'
                type='text'
                name='otp'
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value)
                }}
                error={errors.otp}
                placeholder='Enter 2FA Code'
              />
            </div>
          )}
          {current_account?.otp.enabled.api_key_delete && isDelete && (
            <div className='w-full mt-4'>
              <Label
                title='Verification code'
                error={errors.verificationCode}
              />
              <div className='flex gap-2'>
                <Input
                  className='w-full'
                  type='text'
                  name='verification_code'
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value)
                  }}
                  error={errors.verificationCode}
                  placeholder='Enter Verification Code'
                />
                <button className="rounded-md bg-primary py-2 px-3.5 whitespace-nowrap text-white text-sm font-semibold" type="button" onClick={() => {
                  getCode()
                }}>Get code</button>
              </div>
            </div>
          )}
        </form>
      </Modal.Slot>
      <Modal.Slot name='footer'>
        <div className='flex justify-end'>
          <Button
            color='danger'
            onClick={() => {
              console.log("clicked")
              handleSubmit()
            }}
          >
            {isDelete ? "Delete" : "Roll"} API Key
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}

