import ModalFullScreen from "../../../../../Common/ModalFullScreen/ModalFullScreen";
import { useFetchCustomerBalanceTransactions } from "../../../../../../api/customer/balance_transaction";
import { useSelector } from "react-redux";
import {
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
  snakeCaseToTitleCase,
} from "../../../../../../utils/string";
import React, { useEffect, useMemo, useState } from "react";
import Select from "../../../../../Common/Form/Select/Select";
import Label from "../../../../../Common/Form/Label/Label";
import DashboardModalTable from "../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import useTablePagination from "../../../../../../hooks/useTablePagination";
import { ReactComponent as DateIcon } from "../../../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../../../utils/date";

function CustomersModalBalance({ show, onClose, currency = null }) {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const { selected_customer } = useSelector((state) => state.customer);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchTransactionsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  };

  const { isFetching: isFetchingData } = useFetchCustomerBalanceTransactions({
    onSuccess: onFetchTransactionsSuccess,
    onError: () => null,
    enabled: true,
    id: selected_customer?.id,
    params: {
      limit: limitQuery,
      ...requestQuery,
      currency: selectedCurrency?.currency,
    },
  });

  const getCurrencies = useMemo(() => {
    return Object.entries(selected_customer.balance).map(([key, value]) => ({
      currency: key,
      balance: value,
    }));
  }, [selected_customer]);

  const getCurrenciesOptions = useMemo(() => {
    return getCurrencies.map((currency) => ({
      label: currency.currency.toUpperCase(),
      value: currency.currency,
    }));
  }, [getCurrencies]);

  useEffect(() => {
    if (!getCurrencies.length) {
      return;
    }

    let foundCurrency = getCurrencies[0];

    if (currency) {
      const getCurrency = getCurrencies.find(
        (value) => value.currency === currency
      );
      if (getCurrency) {
        foundCurrency = getCurrency;
      }
    }

    setSelectedCurrency(foundCurrency);
  }, [getCurrencies, currency]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCurrencyChange = (e) => {
    const _currency = getCurrencies.find(
      (currency) => currency.currency === e.value
    );
    setSelectedCurrency(_currency);
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Reason",
        accessor: "type",
        maxWidth: 70,
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value">
            {snakeCaseToTitleCase(cell.value)}
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value">{cell.value ?? "-"}</div>
        ),
      },
      {
        Header: "Debit",
        id: "debit",
        maxWidth: 40,
        Cell: (cell) => {
          const row = cell.row.original;
          const amount = row.amount.toString().replace("-", "");
          return (
            <div className="app-modal-fullscreen__value">
              {row.amount < 0 ? (
                <span className="font-semibold">
                  {currencySymbol(row.currency)}
                  {currencyFormatter(row.currency, amount)}
                </span>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        Header: "Credit",
        id: "credit",
        maxWidth: 40,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className="app-modal-fullscreen__value">
              {row.amount > 0 ? (
                <span className="font-semibold">
                  {currencySymbol(row.currency)}
                  {currencyFormatter(row.currency, row.amount)}
                </span>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        Header: "Balance",
        accessor: "ending_balance",
        maxWidth: 40,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className="app-modal-fullscreen__value">
              <span className="font-semibold">
                {currencyPrettier(row.currency, cell.value)}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        maxWidth: 80,
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value break-all">
            {cell.value ?? "-"}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "created_at",
        maxWidth: 60,
        Cell: (cell) => (
          <div className="flex items-center">
            <DateIcon className="mr-1.5" />
            <span className="app-modal-fullscreen__value">
              {dateFormatter(cell.value, true)}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <ModalFullScreen show={show} onClose={handleClose}>
      <ModalFullScreen.Slot name="header">
        <div className="app-modal-fullscreen__header__title">
          Customer Balance
        </div>
      </ModalFullScreen.Slot>
      <ModalFullScreen.Slot name="body">
        <div className="space-y-7">
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex items-center gap-3">
                  <div className="app-modal-fullscreen__big-title">
                    {currencyPrettier(
                      selectedCurrency?.currency,
                      selectedCurrency?.balance
                    )}
                    <small> {selectedCurrency?.currency.toUpperCase()}</small>
                  </div>
                  <span className="text-light font-semibold">for</span>
                  <div className="app-customers__modal__balance">
                    {selected_customer.name}
                  </div>
                </div>
              </div>
              <div>
                <Label title="Currencies" />
                <Select
                  onSelect={handleCurrencyChange}
                  options={getCurrenciesOptions}
                  value={
                    getCurrenciesOptions.length
                      ? getCurrenciesOptions[0].value
                      : ""
                  }
                  style={{ minWidth: "100px" }}
                />
              </div>
            </div>
          </div>
          <div className="space-y-1.5">
            <div className="app-modal-fullscreen__title">
              Customer Balance Adjustments
            </div>
            <DashboardModalTable
              columns={tableColumns}
              data={tableRows}
              pageCount={pageCount}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              loading={isFetchingData}
              count={dataCount}
              noRecordTag="Customer Balance Transactions"
            />
          </div>
        </div>
      </ModalFullScreen.Slot>
    </ModalFullScreen>
  );
}

export default CustomersModalBalance;
