import MainNavbar from "../../../layouts/Main/MainNavbar/MainNavbar";
import "./Faq.scss";
import Stack from "../../../components/Common/Stack/Stack";
import {ReactComponent as HouseIcon} from "../../../assets/images/icons/house.svg";
import {ReactComponent as BagIcon} from "../../../assets/images/icons/bag.svg";
import Accordion from "../../../components/Common/Accordion/Accordion";

const accordionData = {
  merchants: [
    {
      title: "I have a brick and mortar business, can I use Walio?",
      content: "Yes! Walio is designed to be used for both internet based businesses and brick and mortar businesses. Simply create payments through your dashboard and access the payment page for your customer to make payment"
    },
    {
      title: "I sell my products by subscription",
      content: "Walio provides automated subscription tools to invoice your customers at given intervals. This will easily allow you to accept cryptocurrency payments for subscription based services or purchases"
    },
    {
      title: "Can I include tax and shipping on my payments?",
      content: "You sure can. Walio provides tools for factoring in taxes, discounts and shipping rates on sales"
    },
    {
      title: "Can I receive my payments in Fiat Currency instead of cryptocurrency?",
      content: "Currently Walio does not provide Crypto to Fiat conversions. This feature will be added in the very near future"
    },
    {
      title: "How can I avoid volatile crypto prices when taking a payment?",
      content: "Walio provides some great features to allow you to secure the value of your sales. Check out Walio's Volatile product on the Products page."
    },
    {
      title: "Can I limit the kind of cryptocurrencies I receive for a payment?",
      content: "When creating an invoice or order, you can easily restrict the cryptocurrencies accepted for payments on them"
    },
    {
      title: "What benefits are there in accepting cryptocurrency for payments?",
      content: "Cryptocurrency payments offer a more secure method of payment with no charge backs. It also opens your business up to a growing customer base of over 100 million cryptocurrency users worldwide"
    },
    {
      title: "How do I withdraw my funds I receive from payments?",
      content: "You can create a payout from your merchant account directly through your Walio dashboard"
    },
    {
      title: "How can I make a refund to my customer?",
      content: "Depending on the type of sale you made, you can issue refunds very easily. Refunds can be made through Credit Notes for Invoices, and by using the Order Returns tools for Orders"
    },
    {
      title: "Can I have different accounts for different businesses?",
      content: "Every user can create multiple merchant accounts. This is a great way to seperate different aspects of your business or if you have multiple businesses"
    },
    {
      title: "How can I secure my account?",
      content: "The best way to secure your account is to NEVER SHARE YOUR PASSWORD. Walio also provides Two Factor Authentication (2FA) to help make your account more secure. Remember to backup your 2FA secret in a secure location!"
    },
    {
      title: "I lost my Two Factor Authentication (2FA) device, what should I do?",
      content: "You should always back up your 2FA secret incase you loose the device you have your authenticator app on. Unfortunantly there is not much we can do, as we cannot recover your 2FA secret. If this ever does occur, contact Support for more information"
    },
    {
      title: "How do I verify my account?",
      content: "Your account can only be verified once. This can be done through your Walio dashboard. See your account settings for more information"
    },
    {
      title: "How can I close my account?",
      content: "We would be sad to see you go but in the unfortunate event that you wish to close your account, contact Support"
    }
  ],
  shoppers: [
    {
      title: "I sent 2 payments to the same payment address",
      content: "It is important to remember to only send one cryptocurrency transaction per payment you make. Contact the Merchant you first made the payment with to get more help"
    },
    {
      title: "My payment expired before I was able to send my crypto",
      content: "That's fine, you can always revisit the payment page to start a new payment attempt"
    },
    {
      title: "I haven’t received an order from one of your merchants",
      content: "It is best to contact the Merchant directly to get an update on your purchase. If you do not have any success, please contact us using the form below"
    },
    {
      title: "Can I get a refund?",
      content: "Receiving a refund depends on the refund policy of the Merchant you made your purchase with. Contact them to find out how you can request a refund"
    }
  ]
}

function Faq() {
  return (
    <>
      <div className="app-faq-header">
        <div className="app-container h-full">
          <div className="app-products-header__illustration md:block hidden" />
          <MainNavbar />
          <div className="app-products-header__content app-faq-header__content">
            <h1 className="h1 app-products-header__headline">
              Walio is <span>cryptocurrency payments </span>
              provider that provides global cryptocurrency payment solutions.
            </h1>
            <p className="text app-products-header__text">
              Providing a range of powerful products and tools to allow businesses, developer and 
              individuals more easily integrate and accept worldwide cryptocurrency payments, 
              or tokenization, Walio has a soultion for any business model.
            </p>
            <p className="text app-products-header__text">
              From corportate businesses to individual sellers, Walio products can provide a 
              a faster to market solution to get you up and running with 
              accepting cryptocurrency in minutes
            </p>
          </div>
        </div>
      </div>
      <div className="section-faq">
        <div className="section-faq__content">
          <div className="w-1/2 section-faq__left-side md:block hidden" />
          <div className="w-1/2 section-faq__right-side md:block hidden" />
          <div className="app-container relative">
            <div className="section-faq__wrapper">
              <h3 className="h3 section-faq__title">Frequently Asked Questions</h3>
              <div className="section-faq__divider" />
              <div className="section-faq__container">
                <Stack columns="1" gap="7" className="md:grid-cols-3 section-faq__container--gap">
                  <div className="section-faq__type__left">
                    <div>
                      <HouseIcon />
                      <span className="h4 section-faq__type__name section-faq__type__name--blue">Merchants</span>
                    </div>
                    <div className="section-faq__type__divider section-faq__type__divider--blue" />
                  </div>
                  <div className="col-span-2">
                    <Accordion data={accordionData.merchants} />
                  </div>
                </Stack>
                <Stack columns="1" gap="7" className="md:grid-cols-3">
                  <div className="section-faq__type__left">
                    <div>
                      <BagIcon />
                      <span className="h4 section-faq__type__name section-faq__type__name--green">Shoppers</span>
                    </div>
                    <div className="section-faq__type__divider section-faq__type__divider--green" />
                  </div>
                  <div className="col-span-2">
                    <Accordion data={accordionData.shoppers} />
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq;
