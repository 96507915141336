import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../Common/Form/Button/Button';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useUpdatePaymentLink } from 'api/payment/paymentLink';
import { setCheckoutBranding } from 'store/paymentLink/paymentLinkSlice';
import { useFetchCheckoutBranding } from 'api/payment/paymentLink';
import ArrowIcon from '../../../../../assets/images/icons/dashboard/arrow_down.svg';
import Loader from 'components/Loader/Loader';
import { currencyFormatter } from 'utils/string';
import { ReactComponent as ImagePlaceholder } from '../../../../../assets/images/dashboard/product-placeholder.svg';
import { generatePreviewData, computePaymentLinkPreview } from 'utils/payment_links';
import { paymentLinkData } from '../PaymentLinksTable/paymentLinkData';
import InputPrice from 'components/Common/Form/InputPrice/InputPrice';
import { formatw } from 'components/Common/inputTable/EditableCell';
import { countryList } from 'utils/countries';


const PaymentLinkPreview = ({ selected_payment_link }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchCheckoutBranding, setFetchCheckoutBranding] =
    useState(true);
  const { checkout_branding } =
    useSelector((state) => state.paymentLink);
  const { current_account } = useSelector(
    (state) => state.auth
  );

  const [previewData, setPreviewData] = useState({});
  const [computedPaymentLinkPreview, setComputedPaymentLinkPreview] = useState({});

  const [priceDetails, setPriceDetails] = useState({
    editable: true,
    price: "",
  });

  console.log(
    'selected_payment_link ->>>>>>>>>>>>',
    selected_payment_link
  );
  console.log(JSON.stringify(selected_payment_link))

  console.log(
    'checkout_branding ->>>>>>>>>>>>',
    checkout_branding
  );

  useEffect(() => {
    if (selected_payment_link && checkout_branding) {
      // compute values
      const previewData = generatePreviewData(
        checkout_branding.display_name,
        selected_payment_link?.computed_amounts,
        selected_payment_link?.subscription_data?.trial_period_days,
        selected_payment_link?.line_items?.data,
        selected_payment_link?.currency,
      );
      const computedPaymentLinkPreview = computePaymentLinkPreview(
        selected_payment_link?.line_items?.data,
        selected_payment_link?.subscription_data?.trial_period_days,
      );
      // set computed values
      setComputedPaymentLinkPreview(computedPaymentLinkPreview);
      setPreviewData(previewData);
      // debug
      console.log(
        'previewData ->>>>>>>>>>>>>>>',
        previewData
      );
      console.log(
        'computedPaymentLinkPreview ->>>>>>>>>>>>>>>',
        computedPaymentLinkPreview
      );
    }

    // for when the user chooses amount
    // when preset is defined
    const price = selected_payment_link?.line_items?.data[0]?.price?.custom_unit_amount?.preset ?
      currencyFormatter(selected_payment_link?.currency, selected_payment_link?.line_items?.data[0]?.price?.custom_unit_amount?.preset) :
      "00.00"
    setPriceDetails({
      editable: selected_payment_link?.line_items?.data[0]?.price?.custom_unit_amount?.preset ? false : true,
      price: price,
    });

  }, [selected_payment_link, checkout_branding]);

  useFetchCheckoutBranding({
    onSuccess: (data) => {
      dispatch(setCheckoutBranding(data));
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
      toast.error(error.response?.data.error.message);
      console.log(
        'useSetCheckoutbanding on error -',
        error
      );
    },
    enabled: fetchCheckoutBranding,
    params: {
      id: current_account?.id,
    },
  });

  const amountToPay = () => {
    if (selected_payment_link && previewData) {
      if (previewData?.amounts?.custom_chooses_amount && selected_payment_link?.line_items?.count === 1) {
        return (
          <div className='flex flex-col'>
            <input className='w-32 text-3xl border text-black border-gray-200 rounded-md px-2 py-1 outline-none' type='text' placeholder='00.00'
              value={priceDetails?.price}
              accept='number'
              onChange={(e) => {
                formatw(e, selected_payment_link?.currency);
                setPriceDetails({
                  ...priceDetails,
                  price: e.target.value,
                });
              }}
              disabled={!priceDetails?.editable}
            />
            {selected_payment_link?.line_items?.data[0]?.price?.custom_unit_amount?.preset && (
              <button className='text-white hover:underline font-semibold mt-2 w-fit'
                onClick={() => {
                  setPriceDetails({
                    ...priceDetails,
                    editable: true,
                  });
                }}
              >Edit</button>
            )}
          </div>
        )
      }
      if (selected_payment_link?.computed_amounts?.amount > 0) {
        return (
          <div className='flex gap-2 items-center'>
            <h4 className='text-5xl font-bold flex gap-1 items-end'>
              {previewData?.amounts?.amount}
            </h4>
            {previewData?.amounts?.due_today ?
              <span className='font-light leading-4'>Due <br /> Today</span> :
              selected_payment_link?.computed_amounts?.interval ?
                <>
                  {selected_payment_link?.computed_amounts?.has_metered_price || selected_payment_link?.computed_amounts?.has_one_time_price ?
                    <span className='font-light leading-4'>Due <br /> Today</span>
                    : selected_payment_link?.computed_amounts?.interval_count <= 1 ?
                      <span className='font-light'>Per month</span> : <span className='font-light'>Every 6 months</span>
                  }
                </> : null
            }
          </div>
        )
      }
      if (selected_payment_link?.computed_amounts?.amount < 1 && selected_payment_link?.computed_amounts?.interval) {
        return (
          <div className=''>
            <h4 className='text-4xl font-bold'>
              {selected_payment_link?.subscription_data?.trial_period_days > 0
                ? `${selected_payment_link?.subscription_data?.trial_period_days} days free`
                : (
                  <>
                    $0.00
                    <span className='font-light'>
                      Due <br /> today
                    </span>
                  </>
                )}
            </h4>
          </div>
        )
      } else {
        return (
          <>No Condition</>
        )
      }
    }
  }

  return (
    <div className='flex flex-col w-full'>
      <div className='space-y-7 mt-2 mb-4'>
        <div>
          <div className='app-modal-fullscreen__tab app-modal-fullscreen__tab--active'>
            Preview
          </div>
          <p className='app-modal-fullscreen__text mt-3'>
            <span className='text-light'>
              Change how this page looks in
            </span>
            <span className='text-tertiary cursor-pointer hover:underline'>
              {' '}
              branding settings
            </span>
            .
          </p>
        </div>
      </div>
      {selected_payment_link && (
        <div className='w-full rounded-lg shadow-md overflow-hidden grid grid-cols-2 border border-gray-200'>
          <div className={`flex flex-col p-6 text-white`}
            style={{
              backgroundColor: checkout_branding?.branding?.primary_colour,
            }}
          >
            <div className=''>
              {checkout_branding?.branding?.logo && checkout_branding?.branding?.use_logo ? (
                <img className='h-8 w-auto mx-auto' src={checkout_branding?.assets?.logo} />
              ) : (
                <div className='flex items-end gap-2'>
                  <div className='text-3xl font-bold'>
                    {checkout_branding?.display_name}
                  </div>
                  <div className='text-2xl font-bold'>
                    {checkout_branding?.assets?.icon && (
                      <img className='h-8 w-auto mx-auto' src={checkout_branding?.assets?.icon} />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='mt-6 mb-2 text-lg font-semibold'>
              {previewData?.call_to_action ?? `Pay ${checkout_branding?.display_name}`}
            </div>
            <div className=''>
              {amountToPay()}
            </div>
            <div className=''>
              <p className='text-light'>
                {previewData?.tag}
              </p>
            </div>
            <div>
              {selected_payment_link?.line_items?.count === 1 ? (
                <>
                  {selected_payment_link?.amounts?.has_metered_price}
                  {(selected_payment_link?.line_items?.data[0]?.price?.type === 'one_time' ||
                    (selected_payment_link?.line_items?.data[0]?.price?.type === 'recurring' && !selected_payment_link?.amounts?.has_metered_price))
                    && (
                      <div className=''>
                        <p>
                          Qty {selected_payment_link?.line_items?.data[0]?.quantity}, {currencyFormatter(selected_payment_link?.currency, selected_payment_link?.line_items?.data[0]?.price?.unit_amount)} each
                        </p>
                        {selected_payment_link?.line_items?.data[0].quantity_adjustable.enabled && (
                          <div className='mt-6'>
                            {selected_payment_link?.line_items?.data[0]?.product?.description && (
                              <p className='text-lg font-medium mb-4'>
                                {selected_payment_link?.line_items?.data[0]?.product?.description}
                              </p>
                            )}
                            {selected_payment_link?.line_items?.data[0]?.product?.image_url?.length > 0 ? (
                              <img
                                className='w-1/2 h-32 object-contain'
                                src={selected_payment_link?.line_items?.data[0]?.product?.image_url[0]}
                                alt=""
                              />) : (
                              <div className='w-1/2 h-32 border-2 border-gray-400 rounded-lg bg-white grid place-items-center'>
                                <ImagePlaceholder className='w-6 h-auto' />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  {selected_payment_link?.line_items?.data[0]?.price?.type === 'one_time'
                    && !selected_payment_link?.line_items?.data[0].quantity_adjustable.enabled
                    && (
                      <div className=''>
                        <p>
                          {selected_payment_link?.line_items?.data[0]?.product?.description}
                        </p>
                      </div>
                    )}
                </>
              ) : (<div className='mt-16'>
                {
                  computedPaymentLinkPreview?.items?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className='flex justify-between items-center py-2 border-b border-white border-opacity-10'
                      >
                        <div className=''>
                          <h4 className='text-lg font-semibold'>
                            {item?.name}
                            {item?.metered_price && (
                              <span
                                className='text-sm font-light px-1'
                              >
                                {item.quantity}
                              </span>)}
                            {item?.recurring_price && item.billing_frequency && (
                              <span
                                className='text-sm font-light px-3'
                              >
                                {item.billing_frequency}
                              </span>)}
                          </h4>
                          {item?.description && (
                            <p className='text-sm'>
                              {item?.description}
                            </p>
                          )}
                        </div>
                        <div className='flex flex-col'>
                          {
                            item?.recurring_price && selected_payment_link.subscription_data?.trial_period_days > 0 ?
                              (
                                <small className='text-sm font-light'>
                                  {`${selected_payment_link.subscription_data?.trial_period_days} days free`}
                                </small>
                              ) : (
                                <>
                                  {item?.metered_price ?
                                    <small className='text-sm font-light'>
                                      Price varies
                                    </small>
                                    : previewData?.amounts?.custom_chooses_amount ? <>
                                      {
                                        selected_payment_link?.line_items.data[0]?.price?.custom_unit_amount?.preset ?
                                          <small>
                                            {currencyFormatter(selected_payment_link?.currency, selected_payment_link?.line_items.data[0]?.price?.custom_unit_amount?.preset)}
                                          </small> :
                                          <div>
                                            <input className='w-16 text-lg border text-black border-gray-200 rounded-md px-2 py-1 outline-none' type='text' placeholder='00.00'
                                              value={priceDetails?.price}
                                              accept='number'
                                              onChange={(e) => {
                                                formatw(e, selected_payment_link?.currency);
                                                setPriceDetails({
                                                  ...priceDetails,
                                                  price: e.target.value,
                                                });
                                              }}
                                              disabled
                                            />
                                          </div>
                                      }
                                    </> : (
                                      <span>
                                        {currencyFormatter(selected_payment_link?.currency, item?.amount)}
                                      </span>
                                    )
                                  }
                                </>
                              )}
                          {
                            item?.billing_description ?
                              <span>
                                {item?.billing_description}
                              </span>
                              : !item?.description && item?.unit_bucket_size ? <span>per {item?.unit_bucket_size}</span> :
                                item?.bill_at_period_end ? <span>Bill at period end</span> : null
                          }
                        </div>
                      </div>
                    )
                  })}
              </div>)}
            </div>
          </div>
          <div className='p-6 text-black'>
            <form className='flex flex-col mt-4 gap-3'>
              <h4 className='text-2xl font-semibold'>
                {!selected_payment_link?.shipping_address_collection || !selected_payment_link?.shipping_address_collection.enabled ? "Customer " : "Shipping "} Details
              </h4>
              <div className='flex gap-2 w-full flex-col'>
                <label className='text-gray-500' htmlFor='email'>Email</label>
                <input className='border border-gray-200 rounded-md px-2 py-1' type='text' placeholder='Enter email' />
              </div>
              <div className='flex gap-2 w-full flex-col'>
                <label className='text-gray-500' htmlFor='name'>Full Name</label>
                <input className='border border-gray-200 rounded-md px-2 py-1' type='text' placeholder='Enter Phone Number' />
              </div>
              {selected_payment_link?.shipping_address_collection?.enabled && (
                <>
                  <div className='flex gap-2 w-full flex-col'>
                    <label className='text-gray-500' htmlFor='addressLineOne'>Address Line 1</label>
                    <input className='border border-gray-200 rounded-md px-2 py-1' type='text' placeholder='Enter email' />
                  </div>
                  <div className='flex gap-2 w-full flex-col'>
                    <label className='text-gray-500' htmlFor='addressLineTwo'>Address Line 2</label>
                    <input className='border border-gray-200 rounded-md px-2 py-1' type='text' placeholder='Enter Phone Number' />
                  </div>
                  <div className='w-full grid grid-cols-2 gap-4'>
                    <div className='flex gap-2 w-full flex-col'>
                      <label className='text-gray-500' htmlFor='addressLineOne'>City / Town</label>
                      <input className='border border-gray-200 rounded-md px-2 py-1' type='text' placeholder='Enter email' />
                    </div>
                    <div className='flex gap-2 w-full flex-col'>
                      <label className='text-gray-500' htmlFor='addressLineTwo'>Zip Code</label>
                      <input className='border border-gray-200 rounded-md px-2 py-1' type='text' placeholder='Enter Phone Number' />
                    </div>
                  </div>
                  <div className='flex gap-2 w-full flex-col'>
                    <label className='text-gray-500' htmlFor='addressLineTwo'>Country</label>
                    <select className='border bg-transparent border-gray-200 rounded-md px-2 py-1.5 text-gray-400' name="country" id="country">
                      <>
                        <option value="">Select Country</option>
                        {countryList.map((country, index) => {
                          return (
                            <option key={index} value={country}>{country}</option>
                          )
                        })}
                      </>
                    </select>
                  </div>
                </>
              )}
              <>{
                selected_payment_link?.shipping_rates?.length > 0 && (
                  <div className=''>
                    <label className='text-gray-500' htmlFor='shippingRate'>Shipping Methods</label>
                    <div className='flex flex-col rounded-lg border border-gray-300 overflow-hidden mt-2'>
                      {selected_payment_link?.shipping_rates?.map((rate, index) => {
                        return (
                          <div key={index} className={`${index + 1 !== selected_payment_link?.shipping_rates?.length ? "border-b border-gray-300" : ""
                            } flex items-center px-3 py-2 justify-between`}>
                            <div className='flex gap-2'>
                              <input type="radio" name="shippingRate" id={`shipping - ${index}`} />
                              <div className='flex flex-col'>
                                <label className='' htmlFor={`shipping - ${index}`}>{rate?.description || "Standard"}</label>
                                <span className='text-xs text-gray-500 ml-1'>2-3 days</span>
                              </div>
                            </div>
                            <span className=''>
                              £{currencyFormatter(selected_payment_link?.currency, rate?.amount)}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              }
              </>
              <button className='text-white rounded-lg py-2 font-semibold mt-4'
                style={{
                  backgroundColor: checkout_branding?.branding?.secondary_colour,
                }}
              >Pay Now</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentLinkPreview;
