import React, { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { useFetchProducts } from "../../../api/product/product";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { dropdownPriceDescription } from "../../../utils/price_utils";
import { useAsyncDebounce } from "react-table";

function SelectProductsPrices({
  value = null,
  product = null,
  placeholder = "",
  currency = "",
  type = null,
  error = "",
  disabled = false,
  noEmptyPrices = false,
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  // Filter only prices that meet the provided currency and type;
  const filterProductPrices = (data) => {
    return data.map((item) => {
      return {
        ...item,
        prices: {
          ...item.prices,
          data: item.prices.data.filter((price) => {
            console.log(price.type, type);
            if (currency && type) return price.currency === currency && price.type === type;
            else if (currency) return price.currency === currency;
            else if (type) return price.type === type;
            else return true;
          })
        }
      }
    });
  };

  const { isLoading, refetch } = useFetchProducts({
    onSuccess: (data) => {
      setOptions(filterProductPrices(data.data));
    },
    onError: () => null,
    enabled: false,
    params: {
      sort: 'updated_at',
      include: ["prices"],
      active: true,
      ...bindParams,
    },
  });

  useEffect(() => {
    refetch();
  }, [currency, refetch, type]);

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  useEffect(() => {
    if (product) setSelectedProduct(product);
  }, [product]);

  const handleOptionClick = (product, price) => {
    setSelected(price);
    setSelectedProduct(product);
    setOpen(false);
    rest.onSelect && rest.onSelect(product, price);
  };

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const handleOpenClick = () => {
    if (disabled) return;

    setOpen(!open);
  };

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={handleOpenClick}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active",
          disabled && "select-customer__input--disabled"
        )}
      >
        <div className="select-merchant__input__value">
          <span>
            {placeholder && (
              <>
                {selectedProduct && (
                  <>
                    <span>
                      {truncate(selectedProduct?.name, {
                        length: 40,
                        separator: "...",
                      })}
                    </span>
                    <span className="mx-2">-</span>
                    <span className="font-semibold">
                      {dropdownPriceDescription(selected)}
                    </span>
                  </>
                )}
                {!selectedProduct && placeholder}
              </>
            )}
          </span>
        </div>
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && (
        <div className="select-customer__options">
          <div className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search">
            <input
              autoFocus
              type="text"
              className="select-customer__input__value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {isLoading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {!isLoading &&
            options.map((product, index) => (
              <div
                key={index}
                className={
                  "select-product__options__item select-product__options__item__prices"
                }
              >
                {noEmptyPrices ? <>
                  {
                    product.prices.data.length > 0 &&
                    <>
                      <div>
                        {truncate(product.name, { length: 25, separator: "..." })}
                      </div>
                      {product.prices.data.map((price, i) => (
                        <span
                          className={clsx(
                            "select-product__options__item__price",
                            selected &&
                            price.id === selected.id &&
                            "select-product__options__item--active"
                          )}
                          key={i}
                          onClick={() => handleOptionClick(product, price)}
                        >
                          {dropdownPriceDescription(product.prices.data[i])}
                        </span>
                      ))}
                    </>

                  }
                </> :
                  <>
                    <div>
                      {truncate(product.name, { length: 25, separator: "..." })}
                    </div>
                    {product.prices.data.map((price, i) => (
                      <span
                        className={clsx(
                          "select-product__options__item__price",
                          selected &&
                          price.id === selected.id &&
                          "select-product__options__item--active"
                        )}
                        key={i}
                        onClick={() => handleOptionClick(product, price)}
                      >
                        {dropdownPriceDescription(product.prices.data[i])}
                      </span>
                    ))}
                    {product.prices.data.length === 0 && <span>No price</span>}
                  </>}
              </div>
            ))}
        </div>
      )}
      {error && <p className="app-input-price__input__help">{error}</p>}
    </div>
  );
}

export default SelectProductsPrices;
