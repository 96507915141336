import { useState, useEffect } from "react";
import "./Label.scss";

function Label({
  title = "",
  subtitle = null,
  required = false,
  icon = null,
  tooltip = "",
  tooltipFor = null,
  tooltipHtml = false,
  error = "",
  optional = false,
}) {
  return (
    <div className="app-input__label">
      <span className={error ? "app-input__label--error" : ""}>
        {title}
      </span>
      {optional && <span className="text-xs text-light">{" "} (optional)</span>}
      {required && <span className="app-input__label--error"> *</span>}
      {subtitle && <span className="app-input__label__subtitle"> {subtitle}</span>}
      {icon && (
        <div className="app-input__label__icon">
          <img
            data-html={tooltipHtml}
            data-for={tooltipFor}
            data-tip={tooltip}
            src={icon}
            alt={title}
          />
        </div>
      )}
    </div>

  );
}

export default Label;

export function LabelX({
  title = "",
  subtitle = null,
  required = false,
  icon = null,
  tooltip = "",
  tooltipFor = null,
  tooltipHtml = false,
  options,
  error = "",
  selectedOption = { selectedOption }
}) {
  const [active, setactive] = useState(0)
  useEffect(() => {
    console.log("selectedOption", selectedOption);
    for (let i = 0; i < options.length; i++) {
      if (options[i].id == selectedOption.id) {
        setactive(i)
      }

    }
  }, [selectedOption])

  return (
    <div className="app-input__label">
      <span className={error ? "app-input__label--error" : ""}>{title}</span>
      {required && <span className="app-input__label--error"> *</span>}
      {subtitle && <span className="app-input__label__subtitle"> {subtitle}</span>}
      {icon && (
        <div className="app-input__label__icon">
          <div className="img" >
            <div onMouseOver={() => {
              console.log("mouse enter");
              var f = document.getElementsByClassName("tooltip");
              f[0].style.display = "block";
            }}
              onMouseLeave={() => {
                console.log("mouse enter");
                var f = document.getElementsByClassName("tooltip");
                f[0].style.display = "none";
              }}
              className="tooltip">
              <div className="row">
                <strong>{options[active].optionsDisplayName}</strong>
                <div className="buttns">
                  <span style={{ color: active === 0 ? "black" : "#3f51b5" }} onClick={() => {
                    if (active - 1 <= options.length - 1 && active - 1 >= 0)
                      setactive(active - 1)
                  }} className="buttns__item">{"<"}</span>
                  <span style={{ color: active === options.length - 1 ? "black" : "#3f51b5" }} onClick={() => {
                    if (active + 1 <= options.length - 1 && active + 1 >= 0)
                      setactive(active + 1)
                  }} className="buttns__item">{">"}</span>
                </div>

              </div>
              {options[active].toolTipDisplayData}

            </div>
            <img
              onMouseOver={() => {
                console.log("mouse enter");
                var f = document.getElementsByClassName("tooltip");
                f[0].style.display = "block";
              }}
              // data-html={tooltipHtml}
              // data-for={tooltipFor}
              // data-tip={tooltip}
              src={icon}
              alt={title}
            />

          </div>
        </div>
      )}
    </div>

  );
}

