import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchAccounts = () => api.live.get("/accounts");
export const useFetchAccounts = ({onSuccess, onError}) => {
  return useQuery(
    "fetchAccounts",
    fetchAccounts,
    {
      onSuccess,
      onError,
      select: (data) => data.data.accounts,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchCurrentMerchant = () => api.live.get("/current_merchant");
export const useCurrentMerchantAccount = ({onSuccess, enabled}) => {
  return useQuery(
    "fetchCurrentMerchantAccount",
    fetchCurrentMerchant,
    {
      onSuccess,
      enabled,
      select: (data) => data.data.id,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchMerchantAccount = (merchant_id) => api.live.get(`/merchant/${merchant_id}`);
export const useMerchantAccount = () => {
  return useMutation(
    "fetchMerchantAccount",
    (merchant_id) => fetchMerchantAccount(merchant_id)
  );
}

const getVerficationCode = (data) => api.live.post("/verification_code", data);

export const useGetVerficationCode = () => {
  return useMutation(
    "getVerficationCode",
    (data) => getVerficationCode(data)
  );
}
