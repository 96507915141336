import "./Home.scss";
import Illustration from "../../../assets/images/bg/bg_main.svg";
import ArrowRightIcon from "../../../assets/images/icons/arrow-right.png";
import BackgroundIllustration from "../../../assets/images/bg/bg_auth.png";
import Button from "../../../components/Common/Form/Button/Button";
import MainNavbar from "../../../layouts/Main/MainNavbar/MainNavbar";
import SectionFlexible from "../../../components/Pages/Home/SectionFlexible/SectionFlexible";
import SectionWhy from "../../../components/Pages/Home/SectionWhy/SectionWhy";
import SectionHow from "../../../components/Pages/Home/SectionHow/SectionHow";
import {Link} from "react-router-dom";

function Home() {
  return (
    <>
      <div className="app-home-header">
        <div className="app-container h-full">
          <img src={Illustration} alt="" className="app-home-header__illustration lg:block hidden" />
          <img src={BackgroundIllustration} alt="" className="app-home-header__illustration__mobile lg:hidden block" />
          <MainNavbar />
          <div className="app-home-header__content">
            <header>
              <h1 className="h1 app-home-header__headline">
                Power your business
                <span className="sm:block inline"> with cryptocurrency</span>
              </h1>
              <p className="text app-home-header__text">
                Start accepting worldwide cryptocurrency payments for all your business needs.
                Create orders, invoices, simple payment requests or game tokenization with our easy to use API Infrastructure.
              </p>
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="secondary" className="app-home-header__button">
                  Start Now
                </Button>
              </Link>
            </header>
          </div>
        </div>
      </div>
      <SectionFlexible />
      <SectionWhy />
      <SectionHow />
    </>
  )
}

export default Home;
