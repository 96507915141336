import { useSelector } from 'react-redux';
import {
  capitalize,
  snakeCaseToTitleCase,
} from '../../../../../utils/string';

const RefundMethod = () => {
  const { selected_refund } = useSelector(
    (state) => state.refund
  );

  return (
    <div className='space-y-1.5 pb-2'>
      <div className='app-modal-fullscreen__title'>
        Refund Method
      </div>
      <div className='horizontal-divider' />
      <div className='pt-4'>
        <div className='grid grid-cols-5 gap-4'>
          <div className='col-span-2 space-y-2'>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Transaction ID
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_refund.blockchain_transaction.id}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Refund method
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {capitalize(selected_refund.method)}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Network
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {snakeCaseToTitleCase(
                  selected_refund.blockchain_transaction
                    .network
                )}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Address
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {
                  selected_refund.blockchain_transaction
                    .from_addr
                }
              </div>
            </div>
            {selected_refund.blockchain_transaction
              .memo && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transaction Memo
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {
                    selected_refund.blockchain_transaction
                      .memo
                  }
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Transaction Hash
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_refund.blockchain_transaction
                  .explorer ? (
                  <a
                    href={
                      selected_refund.blockchain_transaction
                        .explorer
                    }
                    target='_blank'
                    rel='noreferrer'
                    className='text-tertiary'>
                    {
                      selected_refund.blockchain_transaction
                        .tx_hash
                    }
                  </a>
                ) : (
                  <>
                    {
                      selected_refund.blockchain_transaction
                        .tx_hash
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundMethod;
