import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchSwaps = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/swaps", { params })
    : api.sandbox.get("/swaps", { params });

export const useFetchSwaps = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchSwaps", params], () => fetchSwaps(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchSwap = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/swaps/${id}`, { params })
    : api.sandbox.get(`/swaps/${id}`, { params });

export const useFetchSwap = ({ onSuccess, onError, enabled, id, params = null }) => {
  return useQuery(["fetchSwap", id], () => fetchSwap(id, params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const storeSwap = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/swaps", data)
    : api.sandbox.post("/swaps", data);

export const useStoreSwap = () => {
  return useMutation("storeSwap", storeSwap);
};

const updateSwap = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/swaps/${id}`, data)
    : api.sandbox.patch(`/swaps/${id}`, data);

export const useUpdateSwap = (id) => {
  return useMutation(["updateSwap", id], (data) =>
    updateSwap(id, data)
  );
};