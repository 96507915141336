import Modal from "../../../../../Common/Modal/Modal";
import Stack from "../../../../../Common/Stack/Stack";
import Button from "../../../../../Common/Form/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useCapturePayment } from "../../../../../../api/customer/payment";
import { setRefetchPayment } from "../../../../../../store/payment/paymentSlice";
import Checkbox from "../../../../../Common/Form/Checkbox/Checkbox";
import InfoIcon from "../../../../../../assets/images/icons/info.svg";
import React, { useMemo } from "react";
import crypto_currencies from "../../../../../../utils/crypto_currencies";
import Label from "../../../../../Common/Form/Label/Label";
import Select from "../../../../../Common/Form/Select/Select";
import { toast } from "react-toastify";

function PaymentsModalCapture({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const { selected_payment } = useSelector((state) => state.payment);
  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: {
      auto_convert: false,
      auto_convert_currency: null,
    },
  });

  const auto_convert = watch("auto_convert");
  const auto_convert_currency = watch("auto_convert_currency");

  const { mutate: mutateCapturePayment, isLoading } = useCapturePayment(
    selected_payment?.id
  );

  const cryptoCurrenciesOptions = useMemo(() => {
    return crypto_currencies.map((currency) => ({
      label: currency.label,
      name: `${currency.name}${currency.label ? [' (' + currency.label + ')'] : ''}`,
      value: currency.label.toLowerCase(),
      icon: currency.icon,
    }));
  }, []);

  const handleClose = () => {
    onClose && onClose();
  };

  const onSubmit = (formData) => {
    const data = {
      auto_convert: formData.auto_convert,
      payment_forwarding: formData.payment_forwarding,
    };

    if (formData.auto_convert_currency) {
      data.auto_convert_currency = formData.auto_convert_currency;
    }

    mutateCapturePayment(data, {
      onSuccess: () => {
        dispatch(setRefetchPayment(Date.now()));
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleCryptoCurrencySelect = (currency) => {
    setValue(
      "auto_convert_currency",
      currency ? currency?.value?.toUpperCase() : null
    );
  };

  return (
    <Modal show={show} onClose={handleClose} size="sm" overflowVisible>
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">Capture Payment</div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form id="update-payment" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1">
            <div className="flex items-center gap-3">
              <Checkbox
                {...register("auto_convert")}
                color="primary"
                className="mt-1"
              />
              <div>Auto Convert</div>
              <img
                data-tip="To convert the incoming payment into another cryptocurrency"
                data-for="refunds-tooltip"
                src={InfoIcon}
                alt="Info"
              />
            </div>
            {auto_convert && (
              <div>
                <Label title="Cryptocurrency" />
                <Select
                  onSelect={handleCryptoCurrencySelect}
                  path="assets/images/icons/cryptos"
                  options={cryptoCurrenciesOptions}
                  value={auto_convert_currency?.toLowerCase()}
                />
              </div>
            )}
            <div className="flex items-center gap-3">
              <Checkbox
                {...register("payment_forwarding")}
                color="primary"
                className="mt-1"
              />
              <div>Forward Payment</div>
              <img
                data-tip="Forward the payment instantly to an external blockchain wallet. You must have a valid external account setup for the payment currency, or if using Auto-convert, the converted currency"
                data-for="refunds-tooltip"
                src={InfoIcon}
                alt="Info"
              />
            </div>
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <Button
          className="w-full"
          disabled={isLoading}
          form="update-payment"
          type="submit"
        >
          Capture
        </Button>
      </Modal.Slot>
    </Modal>
  );
}

export default PaymentsModalCapture;
