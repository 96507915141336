import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardSidebar from "./DashboardSidebar/DashboardSidebar";
import "./Dashboard.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { useEffect } from "react";

function Dashboard() {
  const { current_account } = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    console.log(current_account); 
    console.log(location.pathname.split("/"));
    //  add test to the path if the account is not live

    if (current_account) {
      if (current_account.live_mode_access === false) {
        if (location.pathname.split("/")[2] !== "test") {
          const path = location.pathname.split("/").splice(2).join("/");
          navigate("/dashboard/test" + (path[0] === "/" ? path : "/"+ path));

        }
      }


    }
  }, [])
  
  return (
    <div className="app-dashboard">
      <DashboardSidebar />
      <Outlet />
      <ToastContainer position="top-center" />
    </div>
  )
}

export default Dashboard;
