import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../../utils/string";
import LabelTag from "../../../../../../../Common/LabelTag/LabelTag";
import clsx from "clsx";
import { ReactComponent as DateIcon } from "../../../../../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../../../../../utils/date";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTablePagination from "../../../../../../../../hooks/useTablePagination";
import DashboardModalTable from "../../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import { useFetchInvoices } from "../../../../../../../../api/customer/invoice";
import CustomerDetailsInvoicesTableActions from "./CustomerDetailsInvoicesTableActions";
import { useNavigate } from "react-router-dom";
import {
  setSelectedInvoiceCustomer,
  setShowCreateInvoice,
} from "../../../../../../../../store/invoice/invoiceSlice";
import { ReactComponent as RefreshIcon } from "../../../../../../../../assets/images/icons/refresh.svg";

function CustomerDetailsInvoicesTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const { selected_customer } = useSelector((state) => state.customer);
  const { refetch_invoices } = useSelector((state) => state.invoice);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchPaymentsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchInvoices({
    onSuccess: onFetchPaymentsSuccess,
    onError: () => null,
    enabled: refetch,
    params: {
      customer: selected_customer?.id,
      limit: limitQuery,
      ...requestQuery,
    },
  });

  useEffect(() => {
    setRefetch(true);
  }, [refetch_invoices]);

  const tableColumns = useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "amount_due",
        maxWidth: 40,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div 
              className="app-customers__modal__earning"
              style={{ fontSize: "1.2rem" }}
            >
              {currencySymbol(row.currency)}
              {currencyFormatter(row.currency, row.amount_due)}{" "}
              <span>{row.currency?.toUpperCase()}</span>
            </div>
          );
        },
      },
      {
        Header: () => null,
        accessor: "status",
        maxWidth: 40,
        Cell: (cell) => {
          return (
            <LabelTag
              color={clsx(
                cell.value === "paid" && "primary",
                cell.value === "part_paid" && "tertiary",
                cell.value === "void" && "warning",
                cell.value === "issued" && "secondary",
                cell.value === "over_due" && "danger",
                cell.value === "uncollectible" && "dark"
              )}
            >
              {cell.value}
            </LabelTag>
          );
        },
      },
      {
        Header: "",
        accessor: "subscription",
        width: 1,
        Cell: (cell) => {
          return (
            <>
              {cell.value && (
                <span>
                  <RefreshIcon className="mr-1.5" />
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Invoice Number",
        accessor: "invoice_number",
        width: 200,
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value">{cell.value}</div>
        ),
      },
      {
        Header: "Due",
        accessor: "due_date",
        maxWidth: 40,
        Cell: (cell) =>
          cell.value ? (
            <div className="flex items-center">
              <DateIcon className="mr-1.5" />
              <span className="app-modal-fullscreen__value">
                {dateFormatter(cell.value)}
              </span>
            </div>
          ) : (
            <span className="app-modal-fullscreen__value">-</span>
          ),
      },
      {
        Header: "Created",
        accessor: "created_at",
        maxWidth: 60,
        Cell: (cell) => (
          <div className="flex items-center">
            <DateIcon className="mr-1.5" />
            <span className="app-modal-fullscreen__value">
              {dateFormatter(cell.value, true)}
            </span>
          </div>
        ),
      },
      {
        Header: "",
        id: "icon",
        width: 10,
        Cell: (cell) => (
          <CustomerDetailsInvoicesTableActions invoice={cell.row.original} />
        ),
      },
    ],
    []
  );

  const handleCreateInvoiceClick = () => {
    dispatch(setSelectedInvoiceCustomer(selected_customer));
    dispatch(setShowCreateInvoice(true));
    navigate("/dashboard/invoices");
  };

  return (
    <div className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Invoices</div>
        <div
          onClick={handleCreateInvoiceClick}
          className="app-modal-fullscreen__sm-link text-primary"
        >
          Create
        </div>
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Invoices"
      />
    </div>
  );
}

export default CustomerDetailsInvoicesTable;
