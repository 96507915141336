import Checkbox from "../../../../../../Common/Form/Checkbox/Checkbox";
import React from "react";

function ProductsModalCreateMetered({ index, register }) {
  return (
    <div className="flex items-center gap-3">
      <Checkbox
        {...register(`prices.${index}.metered`)}
        color="primary"
        className="mt-1"
      />
      <div className="text-light text-sm">Metered Usage</div>
    </div>
  )
}

export default ProductsModalCreateMetered;
