import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchTopups = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/topups", { params })
    : api.sandbox.get("/topups", { params });

export const useFetchTopups = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchTopups", params], () => fetchTopups(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchTopup = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/topups/${id}`, { params })
    : api.sandbox.get(`/topups/${id}`, { params });

export const useFetchTopup = ({ onSuccess, onError, enabled ,id , params = null }) => {
  return useQuery(["fetchTopup", id], () => fetchTopup(id, params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const storeTopup = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/topups", data)
    : api.sandbox.post("/topups", data);

export const useStoreTopup = () => {
  return useMutation("storeTopup", storeTopup);
};

const updateTopup = (id, data, params) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/topups/${id}`, data, { params })
    : api.sandbox.patch(`/topups/${id}`, data, { params });

export const useUpdateTopup = (id, params = null) => {
  return useMutation(["updateTopup", id], (data) =>
    updateTopup(id, data, params)
  );
};