import Dropdown from '../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../assets/images/icons/dashboard/more.svg';
import {
  setEditProduct,
  setRefetchProduct,
} from '../../../../../../store/product/productSlice';
import { toast } from 'react-toastify';
import { useUpdateProduct } from '../../../../../../api/product/product';
import { useDispatch } from 'react-redux';

function ProductsTableActions({ product, onDelete }) {
  const dispatch = useDispatch();

  const { mutate: mutateUpdateProduct } = useUpdateProduct(
    product?.id
  );

  const handleEditClick = () => {
    dispatch(setEditProduct(product));
  };

  const handleDeleteClick = () => {
    onDelete && onDelete(product);
  };

  const handleArchiveClick = () => {
    mutateUpdateProduct(
      {
        active: !product.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setRefetchProduct(Date.now()));
          toast.success(
            `Product ${
              data.data.active ? 'unarchived' : 'archived'
            }`
          );
        },
      }
    );
  };

  return (
    <>
      <Dropdown
        items={[
          {
            label: 'Edit',
            onClick: handleEditClick,
          },
          {
            label: product.active ? 'Archive' : 'Unarchive',
            onClick: handleArchiveClick,
          },
          {
            label: 'Delete',
            className: 'text-danger font-semibold',
            onClick: handleDeleteClick,
          },
        ]}>
        <Dropdown.Slot name='toggle'>
          <MoreIcon />
        </Dropdown.Slot>
      </Dropdown>
    </>
  );
}

export default ProductsTableActions;
