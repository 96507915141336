import DashboardButton from "../../../../../../Common/Form/DashboardButton/DashboardButton";
import Dropdown from "../../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../../assets/images/icons/dashboard/more.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setDeleteProductPrice,
  setRefetchProductPrice,
  setSelectedProductPrice,
} from "../../../../../../../store/product/productSlice";
import { useUpdatePrice } from "../../../../../../../api/product/price";
import { toast } from "react-toastify";
import PaymentLinksModalCreate from "components/Pages/Dashboard/Payments/PaymentLinksModal/PaymentLinksModalCreate";

function ProductDetailsPricingTableActions({ price }) {
  const dispatch = useDispatch();


  const { mutate: mutateUpdatePrice } = useUpdatePrice(price?.id);

  const handleEditClick = () => {
    dispatch(setSelectedProductPrice(price));
  };

  const handleDeleteClick = () => {
    dispatch(setDeleteProductPrice(price));
  };

  const handleArchiveClick = () => {
    mutateUpdatePrice(
      { active: !price.active },
      {
        onSuccess: () => {
          toast.success(`Price ${price.active ? "archived" : "unarchived"}`);
          dispatch(setRefetchProductPrice(Date.now()));
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      }
    );
  };

  return (
    <div className="flex items-center justify-end gap-4">
      <DashboardButton icon="+" size="sm" color="light" onClick={() => {
        console.log("Create payment link", price);
      }}>
        Create payment link
      </DashboardButton>
      <Dropdown
        items={[
          {
            label: "Edit",
            onClick: handleEditClick,
          },
          {
            label: price.active ? "Archive" : "Unarchive",
            onClick: handleArchiveClick,
          },
          {
            label: "Delete",
            className: "text-danger font-semibold",
            onClick: handleDeleteClick,
          },
        ]}
      >
        <Dropdown.Slot name="toggle">
          <MoreIcon className="cursor-pointer" />
        </Dropdown.Slot>
      </Dropdown>

    </div>
  );
}

export default ProductDetailsPricingTableActions;
