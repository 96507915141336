import { useEffect, useMemo, useState } from "react";
// import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { useDispatch, useSelector } from "react-redux";
import LabelTag from "components/Common/LabelTag/LabelTag";
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import clsx from "clsx";
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "utils/string";
// import { dateFormatter } from '../../../../../../utils/date';
// import {
//   setRefetchInvoices,
//   setSelectedInvoice,
// } from '../../../../../../store/invoice/invoiceSlice';
import { toast } from "react-toastify";
// import InvoicesTableActions from './InvoicesTableActions/InvoicesTableActions';
// import useTablePagination from '../../../../hooks/useTablePagination';
import useTablePagination from "hooks/useTablePagination";
import { useNavigate } from "react-router-dom";
import DashboardTable from "components/Dashboard/DashboardTable/DashboardTable";
import { useFetchBalanceTransactions } from "api/wallet/balance_transactions";
import { dateFormatter } from "utils/date";
import styled from "styled-components";
import { setSelectedTransaction } from "store/transactions/transactionsSlice";
import { typesTable } from "utils/transactions";
// import { ReactComponent as RefreshIcon } from '../../../../../../assets/images/icons/refresh.svg';

function TransactionTable({ filters = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [deleteInvoice, setDeleteInvoice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const { refetch_transaction } = useSelector(
    (state) => state.transaction
  );

  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  const onFetchBalanceTransactionsSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      console.log(row);
      return {
        id: row.id,
        crypto_currency: row.crypto_currency,
        currency: row.currency,
        type: (
          <div className={"font-semibold text-" + typesTable[row.type].colour}>
            {typesTable[row.type].displayValue}
          </div>
        ),
        debit:
          typesTable[row.type].creditDebit === "D"
            ? cryptocurrencyFormatter((row.crypto ? row.crypto_net : row.net))
            : "-",
        credit:
          typesTable[row.type].creditDebit === "C"
            ? cryptocurrencyFormatter((row.crypto ? row.crypto_net : row.net))
            : "-",
        crypto_amount: cryptocurrencyFormatter(row.crypto_amount),
        crypto_fee: cryptocurrencyFormatter(row.crypto_fee),
        crypto_net: cryptocurrencyFormatter(row.crypto_net),
        amount: row.amount,
        ending_balance: row.crypto ? cryptocurrencyFormatter(row.ending_balance) : currencyPrettier(row.currency, row.ending_balance),
        net: row.net,
        crypto: row.crypto,
        created: dateFormatter(row.created_at, true),
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchBalanceTransactionsError = (e) => {
    setRefetch(false);
    console.log(e);
  };

  useEffect(() => {
    setRefetch(true);
  }, [ refetch_transaction, filters]);
  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const { isFetching: isFetchingData } = useFetchBalanceTransactions({
    onSuccess: onFetchBalanceTransactionsSuccess,
    onError: onFetchBalanceTransactionsError,
    params: {
      // expand: ['customer'],
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
    enabled: refetch,
  });

  const handleRowClick = (row) => {
    dispatch(setSelectedTransaction(row._data));
    navigate(`/dashboard/balance_transactions/${row.id}`);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className="flex items-center text-left ">
              <DateIcon className="mr-1.5" />

              <span className="font-normal text-light  ">{row.created}</span>
            </div>
          );
        },
      },
      {
        Header: "Reason",
        accessor: "type",
        // maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div
              className="  flex  gap-1 flex-col items-start text-left truncate
              "
            >
              {row?.type}
            </div>
          );
        },
      },
      {
        Header: "Debit",
        accessor: "debit",
        maxWidth: 60,

        // width: 1,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <CryptoAmountStyle>
              {row?.debit !== "-" ? (
                row.crypto ? (
                  <div className="text-danger font-semibold">
                    <img src={getIconUrl(row?.crypto_currency)} />
                    {row?.debit} {row?.crypto_currency}
                  </div>
                ) : (
                  <div>
                    {currencyPrettier(row?.currency, row?.debit)}
                  </div>
                )
              ) : (
                "-"
              )}
            </CryptoAmountStyle>
          );
        },
      },

      {
        Header: "Credit",
        accessor: "credit",

        maxWidth: 60,
        // Width: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <CryptoAmountStyle>
             {row?.credit !== "-" ? (
                row.crypto ? (
                  <div className="text-primary font-semibold">
                    <img src={getIconUrl(row?.crypto_currency)} />
                    {row?.credit} {row?.crypto_currency}
                  </div>
                ) : (
                  <div>
                    {currencyPrettier(row?.currency, row?.credit)}
                  </div>
                )
              ) : (
                "-"
              )}
            </CryptoAmountStyle>
          );
        },
      },
      {
        Header: "Balance",
        accessor: "balance",

        maxWidth: 60,
        // Width: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <CryptoAmountStyle>
              {row.crypto ? (<div style={{fontWeight: 600}}>
                <img src={getIconUrl(row?.crypto_currency)} />
                {row?.ending_balance} {row?.crypto_currency}
              </div>) : (<div style={{fontWeight: 600}}>({currencyPrettier(row?.currency, row?.ending_balance)})</div>)}
              {/* <span>( {currencyPrettier(row?.currency, row?.fee)})</span> */}
            </CryptoAmountStyle>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Transiction"
        />
      </div>
      {/* {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={ false}//isDeleteLoading}
          title='Delete Invoice'
          message='Are you sure you want to delete this invoice?'
        />
      )} */}
    </>
  );
}

const CryptoAmountStyle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  > span {
  }
`;
export default TransactionTable;
