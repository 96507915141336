import {setCurrentAccount} from "../store/app/authSlice";
import {useMerchantAccount} from "../api/merchant/merchant";
import {useDispatch} from "react-redux";

function useSwitchMerchantAccount() {
  const dispatch = useDispatch();

  const {
    mutate: mutateMerchantAccount,
    isLoading: isMerchantAccountLoading
  } = useMerchantAccount();

  const onMerchantAccountSuccess = (data) => {
    dispatch(setCurrentAccount(data.data));
  }

  const switchMerchantAccount = (merchantId) => {
    mutateMerchantAccount(merchantId, {
      onSuccess: onMerchantAccountSuccess
    });
  }

  return {
    switchAccount: switchMerchantAccount,
    isLoading: isMerchantAccountLoading
  }
}

export default useSwitchMerchantAccount;