import clsx from 'clsx';
import Select from 'components/Common/Form/Select/Select';
import LabelTag from 'components/Common/LabelTag/LabelTag';
import ModalFullScreen from 'components/Common/ModalFullScreen/ModalFullScreen'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { currencyPrettier, currencySymbol } from 'utils/string';
import CreditNotTable from './CreditNotesTable';
import CreditNoteTotal from './CreditNoteTotal';

export default function CreditNoteModal({
  show = false,
  onClose = null,

}) {
  const handleClose = () => {
    onClose && onClose();
  };
  const { selected_invoice } = useSelector((state) => state.invoice);
  useEffect(() => {
    console.log("selected_invoice", selected_invoice);
  }, [])
  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">Issue a credit note</div>
            {/* <CopyToClipboardBoxID text={selected_event?.id} /> */}
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          <div className="space-y-7">
            <div className="space-y-1">
              <div className="text-lg font-semibold">Invoice</div>
              <div className="flex items-center gap-1">
                {selected_invoice?.invoice_number}
                <span className='text-light'>
                  {" "}   for {" "}
                </span>
                <div className='font-semibold'>
                  {
                    currencyPrettier(
                      selected_invoice?.currency,
                      selected_invoice?.total,
                    )
                  }
                </div>
                {
                  selected_invoice?.currency.toUpperCase()
                }
                <LabelTag
                  color={clsx(
                    selected_invoice.status === "paid" && "primary",
                    selected_invoice.status === "part_paid" && "tertiary",
                    selected_invoice.status === "void" && "warning",
                    selected_invoice.status === "issued" && "secondary",
                    selected_invoice.status === "over_due" && "danger",
                    selected_invoice.status === "uncollectible" && "dark"
                  )}
                  className="ml-6"
                >
                  {selected_invoice.status}
                </LabelTag>
              </div>
            </div>
            <div className="horizontal-divider" />
            <div className='space-y-1.5'>
              <div className='flex justify-between'>
                <div className='app-modal-fullscreen__title'>
                  Reason
                </div>

              </div>
              <div>
                <Select
                  options={[
                    { label: "Order Change", value: "order_change" },
                    { label: "Duplicate", value: "duplicate" },
                    { label: "Product Unsatisfactory", value: "product_unsatisfactory" },
                    { label: "Other", value: "other" },
                  ]}
                  placeholder="Select reason"
                  className="max-w-sm"
                  value={"hello"}
                />

              </div>
            </div>
            <div className='text-primary  text-sm cursor-pointer font-semibold'>
                   Add Internal note 
            </div>
            <div className="horizontal-divider" />
            <CreditNotTable />
            <div
              className=' flex justify-end items-center gap-2
              '
            >

            <CreditNoteTotal />
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
    </>
  )
}
