import Label from "../../../../../../../Common/Form/Label/Label";
import InputPrice from "../../../../../../../Common/Form/InputPrice/InputPrice";
import React from "react";
import { useSelector } from "react-redux";
import { FIAT_CURRENCIES_SELECT } from "../../../../../../../../utils/constants";
import { fiatCurrencies } from '../../../../../../../../utils/string';

function DiscountsModalCreateAmountOff({
  currency,
  register,
  setValue,
  error = "",
}) {
  const { selected_discount } = useSelector((state) => state.discount);

  return (
    <div>
      <Label title="Value" error={error} />
      <InputPrice
        {...register("amount_off")}
        block
        currencies={FIAT_CURRENCIES_SELECT}
        onCurrencyChange={(e) => setValue("currency", e?.value)}
        currency={currency}
        placeholder={
          currency
            ? fiatCurrencies[currency]
                .zero_decimal
              ? '0'
              : '0.00'
            : '0.00'
        }
        error={error}
        disabled={selected_discount?.id}
      />
    </div>
  );
}

export default DiscountsModalCreateAmountOff;
