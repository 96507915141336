import "./SectionRetail.scss";
import Stack from "../../../Common/Stack/Stack";
import {ReactComponent as RetailIcon} from "../../../../assets/images/icons/retail.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/retail/illustration.svg";
import {Link} from "react-router-dom";

const retailData = [
  {
    icon: "flows",
    title: "Order Flows",
    text: `Manage Orders from Cart creation, Payment Processing, 
      Order fulfilment and record keeping. Have peace of mind when processing orders`
  },
  {
    icon: "stock",
    title: "Inventory Stock Management",
    text: `Manage your SKU Inventory Stocks with Walio SKU tools. 
      Never fall behind on your inventory with real time data on stock levels.`
  },
  {
    icon: "features",
    title: "Delightful features",
    text: `Allow your customers to claim Discounts using Promotional codes, 
      add Shipping rates to give your Customer more flexible shipping options.`
  }
]

function SectionRetail({ scrollToContact }) {
  return (
    <div id="retail" className="section-retail">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <RetailIcon className="section-products__section__subtitle__icon" />
              Retail
            </h3>
            <h1 className="h1 section-products__section__title">
              Powerful Retail tools for a busy business.
            </h1>
            <p className="text section-products__section__text">
              Make Order creation, management, and flow simple with Walio Retail.
              Whether you are an E-commerce retailer or Brick and Mortar store, Walio Retail won’t let you down.
            </p>
            <div className="section-retail__buttons">
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
                  Start Now
                </Button>
              </Link>
              <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
            </div>
          </div>
          <div className="section-retail__illustration">
            <Illustration />
          </div>
        </Stack>
        <Stack columns="1" gap="12" className="md:grid-cols-3 sm:grid-cols-2 section-retail__features">
          {retailData.map((retail, index) => {
            const SVGIcon = require(`../../../../assets/images/sections/retail/${retail.icon}.svg?svgr`).default;
            return (
              <div key={index} className="section-retail__features__content">
                <div className="section-retail__features__content__icon">
                  <SVGIcon />
                </div>
                <h5 className="h5 section-products__section__info section-retail__features__content__title">
                  {retail.title}
                </h5>
                <p className="text-small section-retail__features__content__text">
                  {retail.text}
                </p>
              </div>
            )
          })}
        </Stack>
      </div>
    </div>
  )
}

export default SectionRetail;
