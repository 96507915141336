import Checkbox from "../../../../../../Common/Form/Checkbox/Checkbox";
import Label from "../../../../../../Common/Form/Label/Label";
import InfoIcon from "../../../../../../../assets/images/icons/info.svg";
import React from "react";
import { useSelector } from "react-redux";

function ProductsModalShippable({ register, error }) {
  const { edit_product } = useSelector((state) => state.product);

  return (
    <div className="flex gap-2">
      <Checkbox
        {...register("shippable")}
        color="primary"
        disabled={edit_product?.id}
      />
      <Label
        title="Shippable"
        tooltipFor="product-modal-create"
        tooltip="If the product is a physical good that will be delivered or collected"
        icon={InfoIcon}
        error={error}
      />
    </div>
  );
}

export default ProductsModalShippable;
