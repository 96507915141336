import { useUpdatePassword } from 'api/user/user'
import Button from 'components/Common/Form/Button/Button'
import Input from 'components/Common/Form/Input/Input'
import Label from 'components/Common/Form/Label/Label'
import Modal from 'components/Common/Modal/Modal'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const UpdatePassword = ({
  show = false,
  onClose = null,
}) => {
  const { user } = useSelector((state) => state.auth);
  const handleClose = () => {
    console.log("close");
    console.log(onClose);
    onClose && onClose()
  }

  const [password, setPassword] = useState({
    old: '',
    new: '',
    confirm: '',
    otp: '',
  })

  const [error, setError] = useState({
    old: '',
    new: '',
    confirm: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }))
    if (name === 'new') {
      return validatePassword(value)
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target
    if (!value) {
      return setErrors(name, `${name} is required`)
    }
    if (name === 'new') {
      return validatePassword(value)
    }
    if (name === 'confirm') {
      if (value !== password.new) {
        return setErrors('confirm', 'Password does not match')
      } else {
        return setErrors('confirm', '')
      }
    }
    setErrors(name, '')
  }

  const setErrors = (name, value) => {
    setError((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validatePassword = (password) => {
    if (password.length < 8) {
      return setErrors('new', 'Password must be at least 8 characters long')
    }
    if (!password.match(/[a-z]/g)) {
      return setErrors('new', 'Password must contain at least one lowercase letter')
    }
    if (!password.match(/[A-Z]/g)) {
      return setErrors('new', 'Password must contain at least one uppercase letter')
    }
    if (!password.match(/[0-9]/g)) {
      return setErrors('new', 'Password must contain at least one number')
    }
    if (!password.match(/[%&$!£$()]/g)) {
      return setErrors('new', 'Password must contain at least one special character')
    }
    setErrors('new', '')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!password.old) {
      return setErrors('old', 'Old password is required')
    }
    if (!password.new) {
      return setErrors('new', 'New password is required')
    }
    if (!password.confirm) {
      return setErrors('confirm', 'Confirm password is required')
    }
    if (password.new !== password.confirm) {
      return setErrors('confirm', 'Password does not match')
    }
    const data = {
      old_password: password.old,
      password: password.new,
    }
    if (user?.two_factor_authentication_enabled) {
      data = {
        ...data,
        otp: password.otp,
      }
    }
    mutate(data)
  }

  const { mutate, isLoading, isError } = useUpdatePassword({
    onSuccess: (data) => {
      console.log(data)
      handleClose()
    },
    onError: (error) => {
      console.log(error)
    }
  })

  return (
    <Modal style={{ overflow: "visible" }} show={show} onClose={handleClose} size='sm'>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
            Update Password
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name='body'>
        <div className=' rounded-md border border-blue-400 text-blue-400 bg-blue-100 p-2 mb-2'>
          To update your name or email address, please contact support
        </div>
        <form className='w-full'>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-col'>
              <Label title='Old Password' />
              <Input
                className='w-full'
                type='password'
                placeholder='Old Password'
                value={password.old}
                onChange={handleChange}
                name='old'
                error={error.old}
                onBlur={handleBlur}
              />
            </div>
            <div className='flex flex-col'>
              <Label title='New Password' />
              <Input
                className='w-full'
                type='password'
                placeholder='New Password'
                value={password.new}
                onChange={handleChange}
                name='new'
                error={error.new}
                onBlur={handleBlur}
              />
            </div>
            <div className='flex flex-col'>
              <Label title='Confirm Password' />
              <Input
                className='w-full'
                type='password'
                placeholder='Confirm Password'
                value={password.confirm}
                onChange={handleChange}
                name='confirm'
                error={error.confirm}
                onBlur={handleBlur}
              />
            </div>
            {(user?.two_factor_authentication_enabled) &&
              <div className="w-full">
                <Label title="2FA Code" />
                <Input
                  className="w-full"
                  type="text"
                  placeholder="Enter your 2FA code"
                  value={password.otp}
                  onChange={handleChange}
                  name="otp"
                />
              </div>
            }
            <div className='w-full flex flex-col mt-2'>
              <Button
                className='w-fit self-end -mb-4'
                type='submit'
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </Modal.Slot>
    </Modal>
  )
}

export default UpdatePassword