import { createSlice } from '@reduxjs/toolkit';

export const refundSlice = createSlice({
  name: 'refund',
  initialState: {
    selected_refund: null,
    refetch_refund: null,
  },
  reducers: {
    setSelectedRefund: (state, payload) => {
      state.selected_refund = payload.payload;
    },
    setRefetchRefund: (state, payload) => {
      state.refetch_refund = payload.payload;
    },
  },
});

export const { setSelectedRefund, setRefetchRefund } =
  refundSlice.actions;

export default refundSlice.reducer;
