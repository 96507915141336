import "./App.scss";
import {useRef} from "react";
import useAuthentication from "./hooks/useAuthentication";
import AppRoutes from "./utils/routes";
import Loader from "./components/Loader/Loader";

function App() {
  const contactRef = useRef(null);
  const { isFetchingData } = useAuthentication();

  return (
    <>
      {isFetchingData
        ? <Loader />
        : <AppRoutes contactRef={contactRef} />
      }
    </>
  );
}

export default App;
