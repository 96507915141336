import "./Accordion.scss";
import AccordionItem from "./AccordionItem/AccordionItem";

function Accordion({ data, className = "" }) {
  return (
    <ul className={`accordion ${className}`}>
      {data && data.map(({title = "", content = ""}, index) =>
        <AccordionItem
          key={index}
          title={title}
          content={content}
        />
      )}
    </ul>
  )
}

export default Accordion;
