import Label from "../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../Common/Form/Input/Input";
import React from "react";
import {useSelector} from "react-redux";

function DiscountsModalCreateQuantityGte({ register, error = ""}) {
  const { selected_discount } = useSelector((state) => state.discount);

  return (
    <div>
      <Label
        title="Quantity"
        error={error}
      />
      <Input
        {...register("quantity_gte")}
        block
        type="text"
        error={error}
        disabled={selected_discount?.id}
      />
    </div>
  )
}

export default DiscountsModalCreateQuantityGte;