import api from '../../utils/axios';
import { useQuery } from 'react-query';
import appStore from '../../store';

const fetchPayments = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/payments', { params })
    : api.sandbox.get('/payments', { params });

export const useFetchPayments = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchPayments', params],
    () => fetchPayments(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchPayment = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/payments/${id}`, { params })
    : api.sandbox.get(`/payments/${id}`, { params });

export const useFetchPayment = ({
  id,
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchPayment', id],
    () => fetchPayment(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};
