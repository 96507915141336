import DashboardTable from '../../../../Dashboard/DashboardTable/DashboardTable';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DateIcon } from '../../../../../assets/images/icons/date.svg';
import { useSelector } from 'react-redux';
import { useFetchRefunds } from '../../../../../api/payment/refunds';
import { dateFormatter } from '../../../../../utils/date';
import {
  currencyFormatter,
  currencySymbol,
  formatCryptoValueLength,
} from '../../../../../utils/string';
import LabelTag from '../../../../Common/LabelTag/LabelTag';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

const RefundsTable = ({ filters = {} }) => {
  const navigate = useNavigate();
  const { current_account } = useSelector(
    (state) => state.auth
  );
  const { selected_refund } = useSelector(
    (state) => state.refund
  );
  const [refetch, setRefetch] = useState(true);
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters, 15);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, selected_refund, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchRefundSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        date: dateFormatter(row.created_at, true),
        crypto_amount: row.crypto_amount,
        crypto_currency: row.crypto_currency,
        amount: row.amount,
        currency: row.currency,
        status: row.status,
        description: row.description ?? '-',
        reason: row.reason?.name ?? '-',
        id: row.id,
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchRefundError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchRefunds({
    onSuccess: onFetchRefundSuccess,
    onError: onFetchRefundError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters,
      expand: [
        'balance_transaction',
        'blockchain_transaction',
      ],
    },
  });

  const handleRowClick = (row) => {
    navigate(`/dashboard/refunds/${row.id}`);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: () => 'Amount',
        accessor: 'crypto_amount',
        width: 70,
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className='flex items-start gap-2'>
              <div>
                <div className='app-modal-fullscreen__value'>
                  <div className='flex gap-1'>
                    <div
                      className='text-dark font-semibold'
                      style={{ fontSize: '1.2rem' }}>
                      {currencySymbol(
                        cell.row.original.currency
                      )}
                      {currencyFormatter(
                        cell.row.original.currency,
                        cell.row.original.amount
                      )}
                    </div>
                  </div>
                </div>
                {cell.row.original.crypto_amount && (
                  <div className='app-modal-fullscreen__table__amount'>
                    <div className='flex items-center gap-1'>
                      <img
                        src={getIconUrl(
                          cell.row.original.crypto_currency
                        )}
                        alt={
                          cell.row.original.crypto_currency
                        }
                        style={{ width: '1.2rem' }}
                      />
                      <div className='font-normal'>
                        {formatCryptoValueLength(
                          cell.row.original.crypto_amount
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 60,
        Cell: (cell) => {
          const row = cell.row;
          return (
            <>
              {cell.value === 'under_paid' && (
                <LabelTag color='primary'>
                  <div
                    data-for='refunds-tooltip'
                    data-tip={`This payment was underpaid by 
                      ${currencySymbol(
                        row.original.currency
                      )}${currencyFormatter(
                      row.original.currency,
                      row._data.amount_underpaid
                    )} 
                      amount (${currencyFormatter(
                        row.original.crypto_currency,
                        row._data.original
                          .crypto_amount_underpaid
                      )} ${row.original.crypto_currency})`}
                    className='flex items-center gap-1'>
                    <span>Succeeded</span>
                    <FaArrowAltCircleDown className='text-danger' />
                  </div>
                </LabelTag>
              )}
              {cell.value === 'over_paid' && (
                <LabelTag color='primary'>
                  <div
                    data-for='refunds-tooltip'
                    data-tip={`This payment was overpaid by 
                      ${currencySymbol(
                        row.original.currency
                      )}${currencyFormatter(
                      row.original.currency,
                      row._data.amount_underpaid
                    )} 
                      amount (${currencyFormatter(
                        row.original.crypto_currency,
                        row._data.crypto_amount_underpaid
                      )} ${row.original.crypto_currency})`}
                    className='flex items-center gap-1'>
                    <span>Succeeded</span>
                    <FaArrowAltCircleUp className='text-tertiary' />
                  </div>
                </LabelTag>
              )}
              {cell.value === 'failed' && (
                <div
                  data-for='refunds-tooltip'
                  data-tip={row._data.failure_message}>
                  <LabelTag color='danger'>Failed</LabelTag>
                </div>
              )}
              {cell.value === 'pending' && (
                <LabelTag color='tertiary'>
                  Pending
                </LabelTag>
              )}
              {cell.value === 'cancelled' && (
                <LabelTag color='dark'>Cancelled</LabelTag>
              )}
              {cell.value === 'succeeded' && (
                <LabelTag color='primary'>
                  Succeeded
                </LabelTag>
              )}
            </>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 180,
        Cell: (cell) => {
          return (
            <span className='text-light font-normal'>
              {cell.value}
            </span>
          );
        },
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        align: 'right',
        width: 100,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: 'Date',
        accessor: 'date',
        width: 70,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              <div>
                <DateIcon className='mr-1.5' />
              </div>
              <span className='text-light font-normal'>
                {cell.value}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Refunds"
        />
      </div>
    </>
  );
};

export default RefundsTable;
