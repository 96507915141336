
import Select from 'components/Common/Form/Select/Select'
import React from 'react'
export  default function CreditTotalDetails() {
    return (
      <>
      <div className='flex w-full items-center justify-between font-semibold'>
                  <div>
                      Subtotal
                  </div>
                  <div>
                      0.00
                      <span className='font-normal'>
                          {" "}USD
                      </span>
                  </div>
              </div>
              <div className='flex w-full items-center justify-between font-semibold'>
                  <div>
                      Total amount to credit
                  </div>
                  <div>
                      0.00
                      <span className='font-normal'>
                          {" "}USD
                      </span>
                  </div>
              </div>
              <div className='flex w-full items-center justify-between font-semibold'>
                  <div>
                      Invoice amount
                  </div>
                  <div>
                      0.00
                      <span className='font-normal'>
                          {" "}USD
                      </span>
                  </div>
              </div>
              <div className='flex w-full items-center justify-between font-semibold'>
                  <div>
                      Adjusted invoice amount
                  </div>
                  <div>
                      0.00
                      <span className='font-normal'>
                          {" "}USD
                      </span>
                  </div>
              </div>
      </>
    )
  }
  