import { format } from 'prettier';
import React , {useState , useEffect} from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { currencyPrettier, currencySymbol, inputPricePrettier } from 'utils/string';
import { ref } from 'yup';
import Label from '../Form/Label/Label';
import './EditableCell.scss';
const Input = styled.div`
  /* position: relative; */
  &:hover{
    .show{
    display: block;
  }
}
  .label{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 12px;
    color: #000;
    font-weight: 500;
    /* background-color: #000; */
  }
  >input{

    padding-left: 20px;
  }
  .warning{

  }
  >.error{
    position: absolute;
    right: 105%;
    white-space: nowrap;
    /* width: 250px; */
    display: inline;
    background-color: white;
    display: none;
    border: 2px solid #dcdee6;


    z-index: 3000;
    padding: 5px 5px;
    border-radius: 5px;
    color: rgb(255 ,91, 124);
    font-size: 12px;
    font-weight: 600;
  }
  .show{
    display: none;
  }
`;
const EditableCell = ({
  value = "",
  row: { index },
  column: { id },
  fieldName,
  type,
  pattern,
  onKeyPress,
  last = false,
  change,
  placeholder = null,
  label,
  checkerror,
  disabled = false
}) => {

  return (
    <>
          <Input>
            { last !== "editable-cell-input-last"   &&
            
              <div className='label'>
                {currencySymbol(label)}
                
              </div>
            }
           { <div  className={last +'-error error'}>
              
            </div>}
            <input
                    name={`${fieldName}[${index}].${id}`}

              style={{paddingLeft: last !== "editable-cell-input-last" ? '20px' : '0px'}}
              disabled={value === "inf" || disabled}
              // ref={ref}

              type={type}
              className={last }
              onBlur={(e)=>{
                if (last === "editable-cell-input-last") checkerror(e);

              }}
              onKeyPress={(e)=>{
                var i = e.target.value.indexOf('.');
                var start = e.target.selectionStart
                if (last !== "editable-cell-input-last" && e.key === "." && start ===i)
                {
                  e.target.selectionStart = i+1;
                  e.target.selectionEnd= i+1;
                }
                if (!/[0-9]/.test(e.key)  ) {
                              
                  e.preventDefault();
                }


              }}
              onChange={(e)=>{
                var elms =  document.getElementsByClassName(last);
                var elmshoe =  document.getElementsByClassName(last + '-error');
                for (var i = 0; i < elms.length; i++) {
                  elms[i].classList.remove("warning");
                }
                for (var i = 0; i < elmshoe.length; i++) {
                  elmshoe[i].classList.remove("show");
                }

                var i = e.target.value.indexOf('.');
                var start = e.target.selectionStart;
                if (last !== "editable-cell-input-last") formatw(e, label);                
                change(e) ;
              }}
              placeholder={placeholder ??  '0.00'}
              value={value === "inf" ? "∞"  : value}
            />
            </Input>

    </>
  );
};

export const formatw =(e, label) =>{

  var i = e.target.value.indexOf('.');
  var start = e.target.selectionStart;
  if (e.target.value === "") {
    e.target.value = inputPricePrettier(label, 0, false);;
    e.target.selectionStart = 0;
    e.target.selectionEnd= 0;

    return
  }
  if (e.nativeEvent.inputType === 'deleteContentBackward')
  {
    console.log('deleteContentBackward ', e.target.value)
    if (i === -1 ) {
      
      e.target.value =  e.target.value.slice(0, start - 1)   +"." + e.target.value.slice(start , e.target.value.length   );
      if (start === 1) e.target.value = "0" + e.target.value; 
      e.target.selectionStart = start- 1;
      e.target.selectionEnd= start - 1;
      
      return;
      
    }else if (i === 0){
      e.target.value = "0"
      e.target.selectionStart =0;
      e.target.selectionEnd= 0;
    }
  }
  if (i !== -1 && start >= i  && e.target.value.length > i + 2 ) {
    if (start === i + 2 || start === i + 3 || start === i +1){
      e.target.value = e.target.value.slice(0, start) + e.target.value.slice(start + 1);
      e.target.selectionStart = start;
      e.target.selectionEnd= start;
      
    }
  }
  else
  {
    if (start === 1&& i !==-1)
    {
      
      e.target.value = e.target.value.slice(0, start) + e.target.value.slice(start + 1);
      e.target.selectionStart = start;
      e.target.selectionEnd= start;
    }
    else{
      
      e.target.value = inputPricePrettier(label, e.target.value, false);
      e.target.selectionStart = start;
      e.target.selectionEnd= start;
      console.log('start ', e.target.value)

      }
}

        
}
export default EditableCell;