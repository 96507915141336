import { Link, NavLink } from "react-router-dom";
import Logo from "../../../assets/images/logo.svg";
import Stack from "../../../components/Common/Stack/Stack";
import LoginIcon from "../../../assets/images/icons/login.svg";
import DashboardIcon from "../../../assets/images/icons/dashboard/home.svg";
import ButtonGradient from "../../../components/Common/Form/Button/ButtonGradient/ButtonGradient";
import { animated, useSpring } from "react-spring";
import { useRef, useState } from "react";
import { ReactComponent as PaymentsIcon } from "../../../assets/images/icons/payments.svg";
import { ReactComponent as InvoiceIcon } from "../../../assets/images/icons/invoice.svg";
import { ReactComponent as SubscriptionsIcon } from "../../../assets/images/icons/subscriptions.svg";
import { ReactComponent as RetailIcon } from "../../../assets/images/icons/retail.svg";
import { ReactComponent as PaymentLinksIcon } from "../../../assets/images/icons/payment-links.svg";
import { ReactComponent as LinksIcon } from "../../../assets/images/icons/links.svg";
import { ReactComponent as GameIcon } from "../../../assets/images/icons/game.svg";
import { ReactComponent as CarbonZeroIcon } from "../../../assets/images/icons/carbon-zero.svg";
import { ReactComponent as VolatileIcon } from "../../../assets/images/icons/volatile.svg";
import { ReactComponent as TaxIcon } from "../../../assets/images/icons/tax.svg";
import { ReactComponent as SwapIcon } from "../../../assets/images/icons/swap.svg";
import { ReactComponent as RewardsIcon } from "../../../assets/images/icons/rewards.svg";
import "./MainNavbar.scss";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useSelector } from "react-redux";

const springConfig = (active) => {
  return {
    config: { tension: 180, friction: 20 },
    to: {
      opacity: active ? 1 : 0,
      height: active ? "auto" : 0,
      overflow: active ? "visible" : "hidden",
    },
    from: { opacity: 0, height: 0, overflow: "hidden" },
  };
};

function MainNavbar() {
  const resourcesRef = useRef();
  const productsRef = useRef();
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [resourcesActive, setResourcesActive] = useState(false);
  const [productsActive, setProductsActive] = useState(false);
  const resourcesProps = useSpring(springConfig(resourcesActive));
  const productsProps = useSpring(springConfig(productsActive));
  const mobileProps = useSpring({
    transform: mobileNavbar ? `translate3d(0,0%,0)` : `translate3d(0,-100%,0)`,
  });
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  useOutsideClick(resourcesRef, () => setResourcesActive(false));
  useOutsideClick(productsRef, () => setProductsActive(false));

  return (
    <div className="app-nav">
      <Link to="/">
        <img className="app-nav__logo" src={Logo} alt="Walio" />
      </Link>
      <div
        className="app-nav__menu__mobile__hamburger"
        onClick={() => setMobileNavbar(true)}
      >
        <div />
        <div />
        <div />
      </div>
      <animated.div style={mobileProps} className="app-nav__menu__mobile">
        <div
          className="app-nav__menu__mobile__close"
          onClick={() => setMobileNavbar(false)}
        >
          &times;
        </div>
        <div className="app-nav__menu__mobile__container">
          <img className="app-nav__menu__mobile__logo" src={Logo} alt="Walio" />
          <Link to="/products" className="app-nav__menu__mobile__item">
            Products
          </Link>
          <div className="app-nav__menu__mobile__item">
            <span onClick={() => setProductsActive(!productsActive)}>
              Resources
              <div className="app-nav__menu__mobile__item__arrow" />
            </span>
            {productsActive && (
              <div className="app-nav__menu__mobile__submenu">
                <div className="app-nav__menu__mobile__submenu__item">
                  <NavLink
                    to="/faq"
                    className={({ isActive }) =>
                      isActive
                        ? "app-nav__menu__submenu__item--active"
                        : undefined
                    }
                  >
                    About Us
                  </NavLink>
                </div>
                <div className="app-nav__menu__mobile__submenu__item">
                  <NavLink
                    to="/faq"
                    className={({ isActive }) =>
                      isActive
                        ? "app-nav__menu__submenu__item--active"
                        : undefined
                    }
                  >
                    FAQ
                  </NavLink>
                </div>
                <div className="app-nav__menu__mobile__submenu__item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.walio.io"
                  >
                    API Docs
                  </a>
                </div>
                <div className="app-nav__menu__mobile__submenu__item">
                  Guides
                </div>
                <div className="app-nav__menu__mobile__submenu__item">
                  Whitepaper
                </div>
              </div>
            )}
          </div>
          {isAuthenticated ? (
            <Link to="/dashboard" className="app-nav__menu__mobile__item">
              Dashboard
            </Link>
          ) : (
            <Link to="/login" className="app-nav__menu__mobile__item">
              Login
            </Link>
          )}
        </div>
      </animated.div>
      <Stack
        as="nav"
        columns="5"
        gap="8"
        className="app-nav__menu grid-flow-col"
      >
        <div ref={productsRef} className="app-nav__menu__submenu">
          <div
            onClick={() => setProductsActive(!productsActive)}
            className="app-nav__menu__item"
          >
            Products
          </div>
          <animated.div
            style={{
              ...productsProps,
              padding: productsActive ? "25px" : 0,
            }}
            className="app-nav__menu__submenu__items"
          >
            <div>
              <Link
                to="/products#payments"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <PaymentsIcon />
                Payments
              </Link>
              <Link
                to="/products#invoice"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <InvoiceIcon />
                Invoice
              </Link>
              <Link
                to="/products#subscription"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <SubscriptionsIcon />
                Automated Subscriptions
              </Link>
              <Link
                to="/products#retail"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <RetailIcon />
                Retail
              </Link>
              <Link
                to="/products#payment-links"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <PaymentLinksIcon />
                Payment Links
              </Link>
              <Link
                to="/products#links"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <LinksIcon />
                Links
              </Link>
              <Link
                to="/products#gds"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <GameIcon />
                Game Developer Suite – GDS
              </Link>
            </div>
            <div>
              <Link
                to="/products#carbon-zero"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <CarbonZeroIcon />
                Carbon Zero
              </Link>
              <Link
                to="/products#volatile"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <VolatileIcon />
                Volatile
              </Link>
              <Link
                to="/products#tax"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <TaxIcon />
                Tax
              </Link>
              <Link
                to="/products#swap"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <SwapIcon />
                Swap
              </Link>
              <Link
                to="/products#rewards"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                <RewardsIcon />
                Rewards
              </Link>
              <Link
                to="/products#additional-features"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
                style={{ lineHeight: "36px" }}
              >
                {/*<RewardsIcon />*/}
                Additional Features
              </Link>
              <Link
                to="/products#fees"
                className="app-nav__menu__submenu__item app-nav__menu__submenu__item--icon"
              >
                {/*<RewardsIcon />*/}
                Fees
              </Link>
            </div>
          </animated.div>
        </div>
        <div ref={resourcesRef} className="app-nav__menu__submenu">
          <span
            onClick={() => setResourcesActive(!resourcesActive)}
            className="app-nav__menu__item"
          >
            Resources
          </span>
          <animated.div
            style={{
              ...resourcesProps,
              padding: resourcesActive ? "25px" : 0,
            }}
            className="app-nav__menu__submenu__items"
          >
            <div>
              <div className="app-nav__menu__submenu__item">
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? "app-nav__menu__submenu__item--active"
                      : undefined
                  }
                >
                  About Us
                </NavLink>
              </div>
              <div className="app-nav__menu__submenu__item">
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? "app-nav__menu__submenu__item--active"
                      : undefined
                  }
                >
                  FAQ
                </NavLink>
              </div>
              <div className="app-nav__menu__submenu__item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.walio.io"
                >
                  API Docs
                </a>
              </div>
              <div className="app-nav__menu__submenu__item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.walio.io"
                >
                  Guides
                </a>
              </div>
              {/* <div className="app-nav__menu__submenu__item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://whitepaper.walio.io"
                >
                  Whitepaper
                </a>
              </div> */}
            </div>
          </animated.div>
        </div>
        <div className="app-nav__menu__divider" />
        {isAuthenticated ? (
          <Link to="/dashboard" className="app-nav__menu__item">
            <img
              className="app-nav__menu__item__icon"
              src={DashboardIcon}
              alt="Dashboard"
            />
            <span>Dashboard</span>
          </Link>
        ) : (
          <Link to="/login" className="app-nav__menu__item">
            <img
              className="app-nav__menu__item__icon"
              src={LoginIcon}
              alt="Login"
            />
            <span>Login</span>
          </Link>
        )}
        <Link to="/register">
          <ButtonGradient>Get Started</ButtonGradient>
        </Link>
      </Stack>
    </div>
  );
}

export default MainNavbar;
