import InfoIcon from "assets/images/icons/info.svg";
import ReactTooltip from 'react-tooltip';
import { useEffect } from 'react';
import { ToolTip } from "pages/Dashboard/Balance/Swaps/SwapsTable";

const PermissionCheckbox = ({
  permissionType,
  resourceIndex,
  subRecourceIndex = -1,
  setResourcePermission,
  resource,
  isNotNone = false,
  disabled = false
}) => {

  if (resource === undefined || (resource?.read === undefined && resource?.write === undefined)) return null;

  return (
    <div className={`bg-[#fafafa] rounded-lg border border-gray-200 w-fit text-sm font-medium px-0.5 ${disabled && "opacity-70 cursor-not-allowed"}`}>
      <label className={`px-1.5 py-2 cursor-pointer
          ${(resource?.read === false || resource?.read === undefined) && (resource?.write === false || resource?.write === undefined) && !isNotNone ? "text-purple-600 font-semibold" : ""} `}>
        None
        <input
          type="checkbox"
          className="hidden"
          checked={(resource?.read === false || resource?.read === undefined) && (resource?.write === false || resource?.write === undefined)}
          onChange={(e) => {
            if (disabled) return;
            const value = e.target.checked;
            setResourcePermission(
              resourceIndex,
              subRecourceIndex,
              permissionType,
              resource?.read !== undefined ? !value : undefined,
              resource?.write !== undefined ? !value : undefined
            )
          }}
        />
      </label>
      {resource?.read !== undefined && (
        <label className={`px-1.5 py-2 cursor-pointer ${resource.read ? "text-purple-600 font-semibold" : ""}`}>
          Read
          <input
            type="checkbox"
            className="hidden"
            checked={resource?.read}
            onChange={(e) => {
              if (disabled) return;
              setResourcePermission(resourceIndex, subRecourceIndex, permissionType, e.target.checked, false)
            }}
          />
        </label>
      )}
      {resource?.write !== undefined && (
        <label className={`px-1.5 py-2 cursor-pointer ${resource?.write ? "text-purple-600 font-semibold" : ""}`}>
          Write
          <input
            type="checkbox"
            className="hidden"
            checked={resource?.write}
            onChange={(e) => {
              if (disabled) return;
              setResourcePermission(resourceIndex, subRecourceIndex, permissionType, false, e.target.checked)
            }}
          />
        </label>
      )}
    </div>
  )
}

const Permission = ({ permission, setResourcePermission, index }) => {
  const isNotNone_Permission = permission.subPermisssions.some((subPermission) => {
    return subPermission.permissions.read || subPermission.permissions.write
  })
  const isNotNone_LinkAccountPermission = permission.subPermisssions.some((subPermission) => {
    return subPermission?.linkAccountPermissions?.read || subPermission?.linkAccountPermissions?.write
  })
  return (
    <table className="table-fixed w-full">
      <tr className='bg-slate-100 border-b'>
        <th className="w-1/2 px-2 py-2">
          <span className='font-normal capitalize text-sm'>
            {permission.name}
          </span>
        </th>
        <th className="w-1/4">
          <PermissionCheckbox
            setResourcePermission={setResourcePermission}
            resourceIndex={index}
            permissionType="permissions"
            resource={permission.permissions}
            isNotNone={isNotNone_Permission}
            disabled={false}
          />
        </th>
        <th className="w-1/4">
          <PermissionCheckbox
            setResourcePermission={setResourcePermission}
            resourceIndex={index}
            permissionType="linkAccountPermissions"
            resource={permission?.linkAccountPermissions}
            isNotNone={isNotNone_LinkAccountPermission}
            disabled={false}
          />
        </th>
      </tr>
      {permission.subPermisssions.map((subPermission, i) => (
        <tr key={i} className='border-b'>
          <td className="w-1/2 px-4 py-2">
            <span className='font-normal capitalize text-sm text-gray-600'>
              {subPermission.name}
            </span>
          </td>
          <td className="w-1/4">
            <PermissionCheckbox
              resourceIndex={index}
              subRecourceIndex={i}
              permissionType="permissions"
              setResourcePermission={setResourcePermission}
              resource={subPermission.permissions}
              disabled={permission?.permissions?.read === true || permission?.permissions?.write === true}
            />
          </td>
          <td className="w-1/4">
            <PermissionCheckbox
              resourceIndex={index}
              subRecourceIndex={i}
              permissionType="linkAccountPermissions"
              resource={subPermission?.linkAccountPermissions}
              setResourcePermission={setResourcePermission}
              disabled={permission?.linkAccountPermissions?.read === true || permission?.linkAccountPermissions?.write === true}
            />
          </td>
        </tr>
      ))}
    </table>
  )
}

const PermissionsTable = ({ permissions, setPermissions }) => {
  const setResourcePermission = (resourceIndex, subRecourceIndex, permissionType, read, write) => {
    setPermissions((state) => {
      const newState = [...state];
      if (subRecourceIndex === undefined || subRecourceIndex === -1) {
        if (permissionType === "permissions") {
          newState[resourceIndex].permissions = {
            read,
            write,
          }
        } else {
          newState[resourceIndex].linkAccountPermissions = {
            read,
            write,
          }
        }
        newState[resourceIndex].subPermisssions.forEach((subPermission) => {
          if (permissionType === "permissions") {
            subPermission.permissions = {
              read: subPermission.permissions.read !== undefined ? read : undefined,
              write: subPermission.permissions.write !== undefined ? write : undefined,
            }
            if (write === true && subPermission.permissions.write === undefined) {
              subPermission.permissions = {
                read: true,
              }
            }
          }
          else {
            subPermission.linkAccountPermissions = {
              read: subPermission?.linkAccountPermissions?.read !== undefined ? read : undefined,
              write: subPermission?.linkAccountPermissions?.write !== undefined ? write : undefined,
            }
            if (write === true && subPermission.linkAccountPermissions.write === undefined) {
              subPermission.linkAccountPermissions = {
                read: true,
              }
            }
          }
        })
      } else {
        if (permissionType === "permissions") {
          newState[resourceIndex].subPermisssions[subRecourceIndex].permissions = {
            read,
            write,
          }
          if (read === false) {
            newState[resourceIndex].permissions = {
              read,
              write: newState[resourceIndex].permissions.write,
            }
          }
          if (write === false) {
            newState[resourceIndex].permissions = {
              read: newState[resourceIndex].permissions.read,
              write,
            }
          }
          if (read === true || write === true) {
            let allRead = true;
            let allWrite = true;
            newState[resourceIndex].subPermisssions.forEach((subPermission) => {
              if (subPermission.permissions.read === false) {
                allRead = false;
              }
              if (subPermission.permissions.write === false) {
                allWrite = false;
              }
            })
            newState[resourceIndex].permissions.read = allRead;
            newState[resourceIndex].permissions.write = allWrite;
          }
        } else {
          newState[resourceIndex].subPermisssions[subRecourceIndex].linkAccountPermissions = {
            read,
            write,
          }
          if (read === false) {
            newState[resourceIndex].linkAccountPermissions = {
              read,
              write: newState[resourceIndex].linkAccountPermissions.write,
            }
          } if (write === false) {
            newState[resourceIndex].linkAccountPermissions = {
              write,
              read: newState[resourceIndex]?.linkAccountPermissions?.read,
            }
          }
          if (read === true || write === true) {
            let allRead = true;
            let allWrite = true;
            newState[resourceIndex].subPermisssions.forEach((subPermission) => {
              if (subPermission?.linkAccountPermissions?.read === false) {
                allRead = false;
              }
              if (subPermission?.linkAccountPermissions?.write === false) {
                allWrite = false;
              }
            })
            newState[resourceIndex].linkAccountPermissions.read = allRead;
            newState[resourceIndex].linkAccountPermissions.write = allWrite;
          }
        }
      }
      return newState;
    })
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className='w-full flex flex-col'>
      <table className='my-2'>
        <tr className='flex'>
          <th className="w-1/2">
            <span className='text-xs font-normal uppercase'>
              Resource Type
            </span>
          </th>
          <th className="w-1/4 flex">
            <div className="flex items-center gap-1">
              <span className='text-xs font-normal uppercase'>Permissions</span>
              <ToolTip
                tooltip="These permissions apply when using this API key to access this account."
                alt="Perm"
                icon={InfoIcon}
                tooltipFor={'tooltip-api'}
                tooltipHtml={true}
              />
              {/* <img
                data-tip="These permissions apply when using this API key to access this account."
                src={InfoIcon}
                alt="Note"
                className='w-3'
              /> */}
            </div>
            {/* <ReactTooltip
              place="right"
              effect="solid"
              type="light"
              padding="10px"
              textColor="#1A1E23"
              backgroundColor='#ffffff'
              className='api-key__tooltip'
            /> */}
          </th>
          <th className="w-1/4 flex">
            <div className="flex items-center gap-1">
              <span className='text-xs font-normal uppercase'>Link Permissions</span>
              <ToolTip
                tooltip="These permissions apply when using this API key to access Link accounts."
                alt="LinkPerm"
                icon={InfoIcon}
                tooltipFor={'tooltip-api'}
                tooltipHtml={true}
              />
              {/* <img
                data-tip="These permissions apply when using this API key to access Link accounts."
                src={InfoIcon}
                alt="Note"
                className='w-3'
              /> */}
            </div>
            {/* <ReactTooltip
              place="right"
              effect="solid"
              type="light"
              padding="10px"
              textColor="#1A1E23"
              backgroundColor='#ffffff'
              className='api-key__tooltip'
            /> */}
          </th>
        </tr>
      </table>
      <div className='overflow-y-scroll max-h-[30vh]'>
        {permissions.map((permission, index) => (
          <Permission
            setResourcePermission={setResourcePermission}
            key={index}
            index={index}
            permission={permission}
          />
        ))}
      </div>
    </div>
  )
}

export default PermissionsTable