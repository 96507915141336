import clsx from "clsx";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/images/icons/dashboard/plus.svg";
import SelectProducts from "../../../../../../../Dashboard/SelectProducts/SelectProducts";
import { useEffect, useMemo, useState } from "react";
import InvoiceItemsList from "./InvoiceItemsList/InvoiceItemsList";
import InvoiceItemsSelectedProduct from "./InvoiceItemsSelectedProduct/InvoiceItemsSelectedProduct";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditInvoiceItem,
  setInvoiceCurrency,
  setSelectedInvoiceItem,
  setSelectNewProduct,
  setSelectOneTime,
  setShowProductsSelect,
} from "../../../../../../../../store/invoice/invoiceSlice";
import InvoiceItemsOneTime from "./InvoiceItemsOneTime/InvoiceItemsOneTime";
import InvoiceItemsCreateNewProduct from "./InvoiceItemsCreateNewProduct/InvoiceItemsCreateNewProduct";

function InvoiceItems() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const {
    selected_invoice_item,
    select_one_time,
    select_new_product,
    show_products_select,
    draft_invoice,
    invoice_currency,
  } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (draft_invoice) {
      dispatch(setInvoiceCurrency(draft_invoice.currency));
    }
  }, [draft_invoice, dispatch]);

  const handleAddItemClick = () => {
    if (!draft_invoice) return;
    dispatch(setShowProductsSelect(true));
  };

  const handleItemSelect = (item) => {
    console.log('In handleItemSelect');
    setItems([...items, item]);
    dispatch(setShowProductsSelect(false));
    dispatch(setSelectedInvoiceItem(item));
  };

  const handleOneTimeClick = () => {
    dispatch(setEditInvoiceItem(null));
    dispatch(setSelectOneTime(true));
  };

  const handleNewProductClick = () => {
    dispatch(setSelectNewProduct(true));
  };

  const handleCloseProductsSelect = () => {
    dispatch(setShowProductsSelect(false));
  };

  const isAddItemVisible = useMemo(() => {
    return !selected_invoice_item && !select_one_time && !select_new_product;
  }, [selected_invoice_item, select_one_time, select_new_product]);

  return (
    <div className="py-2">
      <div className="pr-5">
        <div className="app-modal-fullscreen__title">Items</div>
        {draft_invoice && (
          <div className={clsx(!isAddItemVisible && "app-overlay")}>
            <InvoiceItemsList />
          </div>
        )}
        {selected_invoice_item && <InvoiceItemsSelectedProduct />}
        {select_one_time && <InvoiceItemsOneTime />}
        {select_new_product && <InvoiceItemsCreateNewProduct />}
      </div>
      {isAddItemVisible &&
        (!show_products_select ? 
          <div
            onClick={handleAddItemClick}
            className="app-invoices__modal__link text-primary inline-block mt-3"
          >
            <div
              className={clsx(
                "flex items-center",
                !draft_invoice && "opacity-70"
              )}
            >
              <PlusIcon />
              <span className="ml-2">Add item</span>
            </div>
          </div>
        : 
          <div className="mt-4 w-1/3">
            <SelectProducts
              placeholder="Find or add an item"
              currency={invoice_currency}
              type='one_time'
              onSelect={handleItemSelect}
              oneTime={handleOneTimeClick}
              createProduct={handleNewProductClick}
              onClose={handleCloseProductsSelect}
            />
          </div>
        )}
    </div>
  );
}

export default InvoiceItems;
