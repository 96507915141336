import './PaymentLinks.scss';
import DashboardNavbar from '../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar';
import DashboardButton from '../../../../components/Common/Form/DashboardButton/DashboardButton';
import { ReactComponent as PaymentLinkIcon } from '../../../../assets/images/icons/payment-links.svg';
import PaymentLinksTable from '../../../../components/Pages/Dashboard/Payments/PaymentLinksTable/PaymentLinksTable';
import PaymentLinksModalDetails from '../../../../components/Pages/Dashboard/Payments/PaymentLinksModal/PaymentLinksModalDetails';
import PaymentLinksModalCreate from '../../../../components/Pages/Dashboard/Payments/PaymentLinksModal/PaymentLinksModalCreate';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEditPaymentLink,
  setSelectedPaymentLink,
} from '../../../../store/paymentLink/paymentLinkSlice';
import clsx from 'clsx';
import useTableFilters from '../../../../hooks/useTableFilters';
import { toast } from 'react-toastify';
import { useFetchPaymentLink, useRetrievePaymentLink } from '../../../../api/payment/paymentLink';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownFilters from '../../../../components/Common/DropdownFilters/DropdownFilters';
import ReactTooltip from 'react-tooltip';

const TABLE_FILTERS = [
  { label: 'All', column: 'status', value: '' },
  { label: 'Active', column: 'active', value: true },
  { label: 'Archived', column: 'active', value: false },
];

function PaymentLinks() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    selected_payment_link,
    // new_payment_link,
    refetch_payment_link,
    // delete_payment_link,
    // selected_payment_link_details,
    edit_payment_link,
  } = useSelector((state) => state.paymentLink);
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

  const DASHBOARD_PATH = !live_mode_access ? '/dashboard/test' : '/dashboard';
  const [filters, setFilters] = useState({});
  const [showDetailsModal, setShowDetailsModal] =
    useState(false);
  const [showCreateModal, setShowCreateModal] =
    useState(false);
  const [refreshPaymentLink, setRefreshPaymentLink] =
    useState(false);
  const { activeFilter, setFilter, isFilterActive } =
    useTableFilters({
      filters: TABLE_FILTERS,
      defaultFilter: TABLE_FILTERS[0],
    });

  useRetrievePaymentLink({
    onSuccess: (data) => {
      console.log('data from payment link', data);
    },
    onError: (error) => {
      console.log('error from payment link', error);
    },
    id,
    params: {
      expand: [
        "price",
        "product",
        "tax_rate",
      ],
    },
  });

  useFetchPaymentLink({
    onSuccess: (data) => {
      dispatch(setSelectedPaymentLink(data));
      setRefreshPaymentLink(false);
    },
    onError: (error) => {
      setRefreshPaymentLink(false);
      navigate(DASHBOARD_PATH + '/payment_link');
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshPaymentLink,
    params: {
      expand: [
        'customer',
        'balance_transaction',
        'blockchain_transaction',
        'line_items.data.product',
        'line_items.data.price',
        'shipping_rates',
      ],
    },
  });

  const handleCloseModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedPaymentLink(null));
    navigate(DASHBOARD_PATH + '/payment_link');
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_payment_link);
  }, [selected_payment_link]);

  // useEffect(() => {
  //   setShowNewPaymentLinkModal(!!new_payment_link)
  // }, [new_payment_link])

  useEffect(() => {
    setShowCreateModal(!!edit_payment_link);
  }, [edit_payment_link]);

  useEffect(() => {
    if (!id) {
      handleCloseModal();
      return;
    }

    setRefreshPaymentLink(true);
  }, [refetch_payment_link, id, handleCloseModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedPaymentLink());
    };
  }, [dispatch]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({
      column: 'status',
      value: filters?.status ?? '',
    });
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setEditPaymentLink(null));
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(
        ([_, v]) => v != null && v !== ''
      )
    );
  }, [activeFilter]);

  const getFilters = useMemo(() => {
    if (activeFilterFiltered?.status === 'active') {
      activeFilterFiltered.unarchived = false;
    }
    return {
      ...filters,
      ...activeFilterFiltered,
    };
  }, [filters, activeFilterFiltered]);

  const handleFilterClick = (filter) => {
    if (filter && filter.label === 'All') {
      setFilters((state) => {
        const { status, ...rest } = state;

        return rest;
      });
    }

    setFilter(filter);
  };

  return (
    <div className='app-dashboard-container'>
      <DashboardNavbar />
      <div className='app-dashboard__content'>
        <div className='app-dashboard__card'>
          <div className='app-dashboard__card__header'>
            <div className='flex'>
              <div className='app-dashboard__card__title'>
                Payments Link
              </div>
              <span className='app-dashboard__card__header__filters'>
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() =>
                      handleFilterClick(filter)
                    }
                    className={clsx(
                      'app-dashboard__card__header__filters__item',
                      isFilterActive(filter) &&
                      'app-dashboard__card__header__filters__item--active'
                    )}>
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className='app-dashboard__card__buttons'>
              <DropdownFilters
                filters={[
                  'currency',
                  'contains_recurring_price',
                  'price',
                  'product',
                  'date',
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page='payment_link'
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color='tertiary'
                outline
                icon={<PaymentLinkIcon />}>
                <span className='text-dark'>
                  New Payment Link
                </span>
              </DashboardButton>
            </div>
          </div>
          <PaymentLinksTable filters={getFilters} />
        </div>

        {showDetailsModal && (
          <PaymentLinksModalDetails
            show={showDetailsModal}
            onClose={handleCloseModal}
          />
        )}

        {showCreateModal && (
          <PaymentLinksModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}

        <ReactTooltip
          id='refunds-tooltip'
          place='top'
          effect='solid'
          type='light'
          padding='10px'
          textColor='#1A1E23'
          backgroundColor='#ffffff'
          className='api-key__tooltip'>
          { }
        </ReactTooltip>
        <ReactTooltip
          id='fees-tooltip'
          place='right'
          effect='solid'
          type='light'
          padding='10px'
          textColor='#1A1E23'
          backgroundColor='#ffffff'
          className='api-key__tooltip api-key__tooltip--shadow api-key__tooltip--opacity api-key__tooltip--padding'>
          { }
        </ReactTooltip>
      </div>
    </div>
  );
}

export default PaymentLinks;
