import Modal from "../../../../../Common/Modal/Modal";
import Label from "../../../../../Common/Form/Label/Label";
import Stack from "../../../../../Common/Stack/Stack";
import Textarea from "../../../../../Common/Form/Textarea/Textarea";
import Button from "../../../../../Common/Form/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useUpdatePayment} from "../../../../../../api/customer/payment";
import {setSelectedPayment, setRefetchPayment} from "../../../../../../store/payment/paymentSlice";

function PaymentsModalUpdate({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const {selected_payment} = useSelector((state) => state.payment);
  const {
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      description: selected_payment.description ?? ""
    }
  });

  const { mutate: mutateUpdatePayment, isLoading } = useUpdatePayment(selected_payment?.id);

  const handleClose = () => {
    onClose && onClose();
  }

  const onSubmit = (formData) => {
    mutateUpdatePayment({
      description: formData.description
    }, {
      onSuccess: (data) => {
        dispatch(setSelectedPayment(data.data));
        dispatch(setRefetchPayment(Date.now()));
        handleClose();
      }
    })
  }

  return (
    <Modal show={show} onClose={handleClose} size="lg">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">Update Payment</div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form id="update-payment" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1">
            <div>
              <Label title="Description"/>
              <Textarea
                {...register("description")}
                block
                rows="3"
              />
            </div>
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button disabled={isLoading} form="update-payment" type="submit">Save</Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}

export default PaymentsModalUpdate;