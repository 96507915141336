import "./Radio.scss";
import {forwardRef} from "react";
import clsx from "clsx";

const Radio = forwardRef(({
   label = "",
   color = "",
   className = "",
   ...rest
 }, ref) => {
  return (
    <label className={className}>
      <div className="app-radio">
        <input
          ref={ref}
          type="radio"
          className={clsx(
            "app-radio__input",
            color && `app-radio__input--${color}`
          )}
          {...rest}
        />
        <span className={clsx(
          "app-radio__label",
          rest.disabled && "app-radio__label--disabled"
        )} />
      </div>
      <span className="pt-1">{label}</span>
    </label>
  )
});

export default Radio;
