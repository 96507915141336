import React, { useEffect, useState, useRef } from "react";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import TierPreview from "../ProductsModalDetails/ProductDetails/TierPreview";
import { getTieredTableData } from "utils/price_utils";
import { currencyFormatter } from 'utils/string';
import styled from "styled-components";

function ProductsModalPriceTiersTable({ price }) {
  const [data, setdata] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const previewRef = useRef(null);

  useEffect(() => {
    console.log("tiers :", price);
    setdata(getTieredTableData(price));
  }, [price]);
  
  useEffect(() => {
    console.log("showMore :", showMore);
    if (!showMore)
    {
      setdata(getTieredTableData(price).slice(0, 10));
      return
    }
    setdata(getTieredTableData(price));

  }, [showMore]);

  const tiers = price?.tiers?.map((item , id) => {
    return {
      first_unit: id !== 0 ? price?.tiers[id-1].up_to + 1 : price?.tiers_mode === "volume" ? 1 : 0,
      last_unit: item?.up_to ? item?.up_to : 'inf',
      unit_amount: item?.unit_amount_decimal ? currencyFormatter(
        price.currency,
        item.unit_amount_decimal
      ) : '',
      flat_amount: item?.flat_amount_decimal ? currencyFormatter(
        price?.currency,
        item?.flat_amount_decimal
      ) : '',
      currency: price?.currency,
    };
  })
  return (
    <ProductStyle className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Tiers</div>
        {/* Add Preview here. */}
        <TierPreview
          previewRef={previewRef}
          tiers={tiers}
          currency={price.currency}
          tiers_mode={price.tiers_mode}
        />
      </div>
      
      <div className="horizontal-divider" />
      <table>
        <thead>
          <tr className="app-modal-fullscreen__label">
            <th>QUANTITY</th>
            <th>PRICE PER UNIT</th>
            <th>FLAT AMOUNT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((tier, index) => {
            return (
              <tr className="text-light" key={index}>
                <td>
                    <>
                    <span className="flex gap-1">
                      {
                        price.tiers_mode === "volume" ? "If the total quantity is" : 
                        (index === 0 ? "For the first" : 
                        (index === data.length - 1 ? "For the rest" : "For the next"))
                      }
                      {/* Only show this if price index is not 0 and graduated price */}
                      <div className="text-dark" style={{fontWeight: 600}}>{price.tiers_mode === "graduated" && index === data.length - 1 ? '' : tier.quantity}</div>
                    </span>
                    </>
                </td>
                <td className="text-dark">{tier.unitAmount}</td>
                <td className="text-dark">{tier.flatAmount}</td>
                <td>
                  <div></div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Only show this button if tiers length > 10 */}
      {price.tiers.length > 10 && <DashboardButton
        outline
        color="light"
        size="sm"
        icon={""}
        onClick={()=> setShowMore(!showMore)}
      >
        {showMore ? "Show less" : `Show ${price.tiers.length - 10} more`}
      </DashboardButton>}
    </ProductStyle>
  );
}

const ProductStyle = styled.div`
  /* width: 100%; */
  > table {
    width: 100%;
    > tbody {
      > tr {
        /* width : auto; */
        /* width: 100%; */

        > td {
          /* width : 100px;
          max-width: 100px; */
          padding: 8px 10px;
          font-weight: 500;
          text-align: right;
        }
        > td:first-child {
          text-align: left;
        }
        border-top: 1px solid #e5e7eb;
        > td:last-child {
          text-align: right;
          width: 60%;
          @media (max-width: 1600px) {
            width: 50%;
          }
          @media (max-width: 1350px) {
            width: 30%;
          }
          @media (max-width: 1000px) {
            width: 10%;
          }
        }
        /* border-bottom: 1px solid #e5e7 eb; */
        border-top: 1px solid #e5e7eb;
      }
    }
    > thead {
      > tr {
        > th {
          text-align: right;
          padding: 0 10px;
          font-weight: 500;
        }
        > th:first-child {
          text-align: left;
        }
        > th:last-child {
          text-align: right;
          width: 60%;
          @media (max-width: 1600px) {
            width: 20%;
          }
          @media (max-width: 1350px) {
            width: 10%;
          }
          @media (max-width: 1000px) {
            width: 2%;
          }
        }
      }
    }
  }
  /* >.head{
    display: flex;
    align-items: center;
    flex-direction: row;
  } */
`;

export default ProductsModalPriceTiersTable;
