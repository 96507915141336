import "./DashboardNavbar.scss";
import WalletIcon from "../../../assets/images/icons/dashboard/wallet.svg";
import LogoSmall from "../../../assets/images/logo-small.svg";
import NotificationIcon from "../../../assets/images/icons/dashboard/notification.svg";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import AccountIcon from "../../../assets/images/icons/dashboard/account.svg";
import SelectMerchants from "../../../components/Dashboard/SelectMerchants/SelectMerchants";
import {useSelector} from "react-redux";

function DashboardNavbar({ testmode = true }) {
  const { livemode } = useSelector((state) => state.app);

  return (
    <>
      <div className="navbar">
        <div className="navbar__left-side">
          <SelectMerchants />
        </div>
        <div className="navbar__right-side">
          <div className="navbar__wallet">
            <img className="navbar__icon" src={WalletIcon} alt="Tokens" />
            <div className="navbar__tokens">
              <img className="navbar__tokens__logo" src={LogoSmall} alt="Walio" />
              <span className="navbar__tokens__figure">0</span>
              <span className="navbar__tokens__walio">WALIO</span>
            </div>
          </div>
          <div className="navbar__divider" />
          <div className="navbar__notification">
            <img className="navbar__icon" src={NotificationIcon} alt="Notification" />
            <img className="navbar__arrow-down" src={ArrowDownIcon} alt="" />
          </div>
          <div className="navbar__account">
            <img className="navbar__icon" src={AccountIcon} alt="Account" />
            <span className="navbar__account__online" />
          </div>
        </div>
      </div>
      {(!livemode && testmode)
        ?
        <div className="navbar__testmode">
          <div className="navbar__border navbar__border--testmode"/>
          <div className="navbar__testmode__text">
            <span>Test data</span>
          </div>
        </div>
        :
        <div className="navbar__border"/>
      }
    </>
  )
}

export default DashboardNavbar;
