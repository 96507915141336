import Stack from "../../../components/Common/Stack/Stack";
import "./MainFooter.scss";
import Accordion from "../../../components/Common/Accordion/Accordion";
import { ReactComponent as HouseIcon } from "../../../assets/images/icons/house.svg";
import { ReactComponent as BagIcon } from "../../../assets/images/icons/bag.svg";
import Logo from "../../../assets/images/logo.svg";
import Input from "../../../components/Common/Form/Input/Input";
import Textarea from "../../../components/Common/Form/Textarea/Textarea";
import Button from "../../../components/Common/Form/Button/Button";
import FacebookIcon from "../../../assets/images/social/facebook.png";
import TwitterIcon from "../../../assets/images/social/twitter.png";
import TelegramIcon from "../../../assets/images/social/telegram.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSendContactEmail } from "../../../api/contact/contact";
import { toast } from "react-toastify";
import Alert from "../../../components/Common/Alert/Alert";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
// import {ReactComponent as QuoteIcon} from "../../../assets/images/icons/quote.svg";
// import {ReactComponent as ProfileIcon} from "../../../assets/images/icons/profile.svg";

const accordionData = [
  {
    title: "I have a brick and mortar business, can I use Walio?",
    content:
      "Yes! Walio is designed to be used for both internet based businesses and brick and mortar businesses. Simply create payments through your dashboard and access the payment page for your customer to make payment",
  },
  {
    title: "Can I include tax and shipping on my payments?",
    content:
      "You sure can. Walio provides tools for factoring in taxes, discounts and shipping rates on sales",
  },
  {
    title: "I sent 2 payments to the same payment address",
    content:
      "It is important to remember to only send one cryptocurrency transaction per payment you make. Contact the Merchant you first made the payment with to get more help",
  },
];

/*const testimonialData = [
  {
    name: "Maya Halin",
    message: "This product has a bunch of amazing tools which improves our business."
  },
  {
    name: "Leopold Nilsen",
    message: "Wow! Using Walio has helped our company constantly improve."
  },
  {
    name: "Felicia Satzke",
    message: "This product helped me grow my business by inbound marketing and sales education."
  }
]*/

const brandsData = [
  "etherscan",
  "quicknode",
  "coingecko",
  "coinmarketcap",
  "bitcoin",
  "litecoin",
  "binance-smart-chain",
  "tron",
  "ripple",
  "stellar",
  // "prontoetech-services",
  // "doubleparked",
];

const formSchema = yup
  .object({
    name: yup.string().required().label("Name"),
    email: yup.string().email().required().label("Email"),
    subject: yup.string().required().max(200).label("Subject"),
    body: yup.string().required().max(2000).label("Body"),
  })
  .required();

function MainFooter({ faq = true, contactRef }) {
  const [contactAlertMessage, setContactAlertMessage] = useState(null);
  const [contactType, setContactType] = useState("merchant");

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { mutate, isLoading, isSuccess } = useSendContactEmail();

  const handleContactTypeClick = (type) => {
    setContactType(type);
  };

  const onSubmit = (formData) => {
    setContactAlertMessage(null);

    const { name, email, subject, body } = formData;
    const data = {
      name,
      email,
      subject,
      message: body,
      type: contactType,
    };

    if (contactType === "shopper") {
      data.receipt_number = formData.receipt_number;
    }

    mutate(data, {
      onSuccess: () => {
        reset();
        toast.success(
          "Enquiry submitted, one of our team members will get back to you shortly"
        );
        setContactAlertMessage(
          "Enquiry submitted, one of our team members will get back to you shortly"
        );
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
        setContactAlertMessage(error.response?.data.error.message);
      },
    });
  };

  return (
    <div className="app-footer">
      <div className="app-container">
        {faq && (
          <>
            <Stack columns="1" className="app-footer__faq md:grid-cols-3">
              <div>
                <h3 className="h3 app-footer__faq__title">FAQ</h3>
                <div className="text app-footer__faq__text">
                  More questions? Check out our full
                  <Link to="/faq">
                    <span> FAQ</span>
                  </Link>
                </div>
              </div>
              <Accordion className="col-span-2" data={accordionData} />
            </Stack>
            <div className="app-footer__divider" />
          </>
        )}
        {/* <div className="app-footer__testimonial">
          <h2 className="h2 app-footer__testimonial__title">What our clients say</h2>
          <p className="text app-footer__testimonial__text">
            Over 20.000 clients use Walio to understand their business and their market better.
          </p>
          <Stack columns="1" className="app-footer__testimonial__items lg:grid-cols-3 md:grid-cols-2">
            {testimonialData.map((testimonial, index) =>
              <div key={index} className="app-footer__testimonial__card">
                <div>
                  <QuoteIcon className="text-small app-footer__testimonial__card__quote" />
                  <p className="text-small app-footer__testimonial__card__message">
                    {testimonial.message}
                  </p>
                </div>
                <div className="app-footer__testimonial__card__name">
                  <ProfileIcon className="app-footer__testimonial__card__profile" />
                  {testimonial.name}
                </div>
              </div>
            )}
          </Stack>
        </div> */}
      </div>

      <div className="app-footer__contact-us" ref={contactRef}>
        <div className="w-1/2 app-footer__contact-us__left-side md:block hidden" />
        <div className="w-1/2 app-footer__contact-us__right-side md:block hidden" />
        <div
          className="app-container app-footer__contact-us__left-side md:hidden block"
          style={{ marginLeft: "0", marginRight: "auto" }}
        />
        <div className="app-container app-footer__contact-us__container">
          <div className="app-footer__contact-us__content">
            <h3 className="h3 app-footer__contact-us__title">Contact Us</h3>
            <Stack columns="1" gap="4" className="lg:grid-cols-2">
              <div>
                <div
                  onClick={() => handleContactTypeClick("merchant")}
                  className={clsx(
                    "app-footer__contact-us__type",
                    contactType === "merchant" &&
                      "app-footer__contact-us__type--active"
                  )}
                >
                  <div className="app-footer__contact-us__type__radio" />
                  <div className="app-footer__contact-us__type__content">
                    <HouseIcon className="app-footer__contact-us__type__icon" />
                    <div>
                      <div className="app-footer__contact-us__type__name">
                        Merchant
                      </div>
                      <div className="app-footer__contact-us__type__description">
                        (I am interested in using Walio)
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => handleContactTypeClick("shopper")}
                  className={clsx(
                    "app-footer__contact-us__type",
                    contactType === "shopper" &&
                      "app-footer__contact-us__type--active"
                  )}
                >
                  <div className="app-footer__contact-us__type__radio" />
                  <div className="app-footer__contact-us__type__content">
                    <BagIcon className="app-footer__contact-us__type__icon" />
                    <div>
                      <div className="app-footer__contact-us__type__name">
                        Shopper
                      </div>
                      <div className="app-footer__contact-us__type__description">
                        (I use crypto to make purchase online at one of Walio
                        merchants)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {contactAlertMessage && (
                  <Alert
                    className="mb-4"
                    type={isSuccess ? "success" : "danger"}
                    message={contactAlertMessage}
                    icon={isSuccess ? <AiOutlineCheck /> : <AiOutlineClose />}
                  />
                )}
                <Stack columns="2">
                  <div>
                    <Input
                      {...register("name")}
                      error={errors.name?.message}
                      block
                      size="md"
                      type="text"
                      placeholder="Name"
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                  <div>
                    <Input
                      {...register("email")}
                      error={errors.email?.message}
                      block
                      size="md"
                      type="email"
                      placeholder="Email"
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                  {contactType === "shopper" && (
                    <div className="col-span-2">
                      <Input
                        {...register("receipt_number")}
                        error={errors.receipt_number?.message}
                        block
                        size="md"
                        type="text"
                        placeholder="Receipt"
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                  )}
                  <div className="col-span-2">
                    <Input
                      {...register("subject")}
                      error={errors.subject?.message}
                      block
                      size="md"
                      type="text"
                      placeholder="Subject"
                      style={{ backgroundColor: "white" }}
                      maxLength="200"
                    />
                  </div>
                  <div className="col-span-2">
                    <Textarea
                      {...register("body")}
                      error={errors.body?.message}
                      block
                      rows="5"
                      placeholder="Please provide as much detailed information as possible. Thank you"
                      style={{ backgroundColor: "white" }}
                      maxLength="2000"
                    />
                  </div>
                </Stack>
                <Button
                  loading={isLoading}
                  className="app-footer__contact-us__button"
                  color="secondary"
                >
                  Send Message
                </Button>
              </form>
            </Stack>
          </div>
        </div>
      </div>
      <div className="app-footer__brands">
        <div className="app-container">
          <div className="app-footer__brands__container">
            {brandsData.map((brand, index) => (
              <img
                key={index}
                className="app-footer__brands__item"
                src={require(`../../../assets/images/icons/brands/${brand}.png`)}
                alt={brand}
              />
            ))}
          </div>
          <div className="app-footer__divider" />
          <div className="app-footer__bottom">
            <Stack columns="1" gap="24" className="md:grid-cols-2">
              <div className="app-footer__bottom__left-side">
                <img
                  className="app-footer__bottom__logo"
                  src={Logo}
                  alt="Walio"
                />
                <p className="app-footer__bottom__text">
                  Walio is a{" "}
                  <span>business cryptocurrency solutions provider</span>. We
                  provide a wide range of products and tools that allow
                  businesses, developers and individuals to faster and more
                  easily integrate worldwide Web3 cryptocurrency payments, and
                  tokenization into their business model.
                </p>
                <div className="app-footer__bottom__social">
                  <img
                    src={FacebookIcon}
                    alt=""
                    href="https://www.facebook.com/mywalio.io/"
                  />
                  <img
                    src={TwitterIcon}
                    alt=""
                    href="https://twitter.com/walio_official"
                  />
                  <img
                    src={TelegramIcon}
                    alt=""
                    href="https://t.me/walio_official"
                  />
                </div>
              </div>
              <div className="app-footer__bottom__right-side">
                <Stack columns="2" className="app-footer__bottom__links">
                  <div>
                    <div className="app-footer__bottom__links__title">
                      Products
                    </div>
                    <ul>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#payments">Payments</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#invoice">Invoice</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#subscription">
                          Automated Subscriptions
                        </Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#retail">Retail</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#payment-links">Payment Links</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#links">Links</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#gds">
                          Game Developer Suite – GDS
                        </Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#carbon-zero">Carbon Zero</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#volatile">Volatile</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#tax">Tax</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#swap">Swap</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#rewards">Rewards</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#additional-features">
                          Additional Features
                        </Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/products#fees">Fees</Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="app-footer__bottom__links__title">
                      Resources
                    </div>
                    <ul>
                      <li className="app-footer__bottom__links__item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://docs.walio.io"
                        >
                          API Docs
                        </a>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://docs.walio.io/docs"
                        >
                          Guides
                        </a>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/faq">About Us</Link>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <Link to="/faq">FAQ</Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="app-footer__bottom__links__title">
                      Legal
                    </div>
                    <ul>
                      <li className="app-footer__bottom__links__item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://docs.walio.io/docs/terms-of-service-agreement"
                        >
                          Terms of Service
                        </a>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://docs.walio.io/docs/privacy-policy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="app-footer__bottom__links__item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://docs.walio.io/docs/cookie-policy"
                        >
                          Cookie Policy
                        </a>
                      </li>
                      {/* <li className="app-footer__bottom__links__item">
                        <Link to="/faq">FAQ</Link>
                      </li> */}
                    </ul>
                  </div>
                </Stack>
              </div>
            </Stack>
            <div className="app-footer__copyright">
              <div className="app-footer__copyright__text">
                © Walio All rights reserved.
              </div>
              {/*<div className="app-footer__copyright__by">
                Designed by
                <img src={BeioInverse} alt="Beio Inverse" />
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFooter;
