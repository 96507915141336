import { createSlice } from '@reduxjs/toolkit';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    selected_transaction: null,
    refetch_transaction: null,
  },
  reducers: {
    setSelectedTransaction: (state, payload) => {
      state.selected_transaction = payload.payload;
    },
    setRefetchTransaction: (state, payload) => {
      state.refetch_transaction = payload.payload;
    },
  },
});

export const {
  setSelectedTransaction,
  setRefetchTransaction
} = transactionSlice.actions;

export default transactionSlice.reducer;
