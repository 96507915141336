import { useState, useEffect } from "react";
import Modal from "../../../../../Common/Modal/Modal";
import InfoIconImage from "../../../../../../assets/images/icons/info.svg";
import { ReactComponent as WarningIcon } from "../../../../../../assets/images/icons/warning.svg";


import CopyToClipboardBoxID from "../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import {
  cryptocurrencyFormatter,
  currencyConverter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
  inputPricePrettier,
} from "../../../../../../utils/string";
import { useSelector } from "react-redux";
import Label from "components/Common/Form/Label/Label";
import InputPrice, {
  InputPriceCrypto,
} from "components/Common/Form/InputPrice/InputPrice";
import { FIAT_CURRENCIES_SELECT, PRICE_REGEX } from "utils/constants";
import { set, useForm } from "react-hook-form";
import * as yup from "yup";
import { useFetchCurrencies } from "api/wallet/currencies";
import SelectNetwork from "components/Dashboard/SelectNetwork/SelectNetwork";
import Input from "components/Common/Form/Input/Input";
import Checkbox from "components/Common/Form/Checkbox/Checkbox";
import { ToolTip } from "pages/Dashboard/Balance/Swaps/SwapsTable";
import { useCreateRefund, useFetchRefundEstimate } from "api/payment/refunds";
import { Amount } from "pages/Dashboard/Balance/BalanceTransactions/TransactionDetails";
import steem_validator from "@swyftx/api-crypto-address-validator/src/steem_validator";
import Button from "components/Common/Form/Button/Button";
import ExpiryTimer from "components/Common/HelperComponents/ExpiryTimer";
// import { validateAddress } from "utils/addressValidator";

const IssueRefundModal = ({ show = false, onClose = null }) => {
  const [currencies, setCurrencies] = useState([]);
  const [isActiveCurrency, setIsActiveCurrency] = useState(true);
  const [networks, setNetworks] = useState([]);
  const { selected_payment } = useSelector((state) => state.payment);
  const [refundEstimate, setRefundEstimate] = useState(null);
  const [showAdvance, setShowAdvance] = useState(false);

  const [formErrors, setFormErrors] = useState({
    cryptoCurrency: "",
    network: "",
    currency: "",
    amount: "",
    other: "",
  });

  const sortNetworks = (networks) => {
    const active = networks.filter((network) => network.is_active);
    const inactive = networks.filter((network) => !network.is_active);
    return [...active, ...inactive];
  };

  const onFetchCurrenciesSuccess = (data) => {
    const currenciestmp = data.map((data) => {
      return {
        ...data,
        is_active: data.is_active,
        label: data.currency.toUpperCase(),
        value: data.currency.toUpperCase(),
        icon: data.currency.toLowerCase(),
      };
    });

    setCurrencies(currenciestmp);

    // find networks of the selected crypto currency
    const elem = data.find(
      (data) => data.currency === selected_payment.crypto_currency
    );

    setNetworks(sortNetworks(elem.networks));

    register(
      "cryptoCurrency",
      {
        required: true,
      },
      {
        value: currenciestmp?.[0]?.value.toLowerCase(),
      }
    );

    register(

      "network",
      {
        required: true,
      },
      {
        value: elem.networks?.[0]?.network.toLowerCase(),
      }
    );
  };

  useFetchCurrencies(
    {
      onSuccess: onFetchCurrenciesSuccess,
      onError: () => { },
      params: {
        type: "crypto",
        networks: true,
      },
    },
    []
  );

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      refundAmount: currencyFormatter(
        selected_payment.currency,
        selected_payment.amount
      ),
      currency: selected_payment.currency,
      cryptoCurrency: selected_payment.crypto_currency,
      cryptoAmount: "",
      network: selected_payment.blockchain_transaction.network,
      memo: selected_payment.blockchain_transaction?.memo,
      address: selected_payment.blockchain_transaction?.from_addr,
      useOriginalExchangeRate: false,
      refundApplicationFee: false,
      reverseTransaction: false,
    },
  });

  const { current_account, user } = useSelector((state) => state.auth);

  const currency = watch("currency");
  const cryptoCurrency = watch("cryptoCurrency");
  const cryptoAmount = watch("cryptoAmount");
  const network = watch("network");
  const refundAmount = watch("refundAmount");
  const memo = watch("memo");
  const address = watch("address");
  const useOriginalExchangeRate = watch("useOriginalExchangeRate");
  const refundApplicationFee = watch("useOriginalExchangeRate");
  const reverseTransaction = watch("reverseTransaction");

  const [otp, setOtp] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState({
    otp: "",
    verificationCode: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === "otp") {
      setOtp(value);
      if (value.length === 6) {
        setError({
          ...error,
          otp: "",
        });
      }
      if (value.length > 6) {
        setError({
          ...error,
          otp: "Please enter 6 digit code",
        });
      }
    } else if (name === "verificationCode") {
      setVerificationCode(value);
      if (value.length === 7) {
        setError({
          ...error,
          verificationCode: "",
        });
      }
      if (value.length > 7) {
        setError({
          ...error,
          verificationCode: "Please enter 7 digit code",
        });
      }
    }
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  const onFetchRefundEstimateSuccess = (data) => {
    setRefundEstimate(data.data);
    setValue("cryptoAmount", cryptocurrencyFormatter(data.data.crypto_amount));
    setFormErrors({
      ...formErrors,
      cryptoCurrency: "",
      network: "",
      currency: "",
      amount: "",
      other: "",
    });
  };

  const createdRefund = useCreateRefund();

  const fetchRefundsEstimate = useFetchRefundEstimate(
    {
      onSuccess: onFetchRefundEstimateSuccess,
      onError: (err) => {
        const error = err?.error;
        if (error?.param === "amount") {
          setFormErrors({
            ...formErrors,
            amount: error?.message,
          });
        } else if (error?.param === "currency") {
          setFormErrors({
            ...formErrors,
            currency: error?.message,
          });
        } else if (error?.param === "network") {
          setFormErrors({
            ...formErrors,
            network: error?.message,
          });
        } else if (error?.param === "crypto_currency") {
          setFormErrors({
            ...formErrors,
            cryptoCurrency: error?.message,
          });
        } else {
          setFormErrors({
            ...formErrors,
            other: error?.message,
          });
        }
      },
      params: {
        amount: selected_payment.amount,
        currency: currency,
        crypto_currency: cryptoCurrency,
        network: network
      },
    }, [], [refundAmount, currency, cryptoCurrency, network]);

  useEffect(() => {
    fetchRefundsEstimate.refetch();
  }, [currency, cryptoCurrency, network, refundAmount])

  const handleNetworkSelect = (value) => {
    setValue("network", value.network);
    fetchRefundsEstimate.refetch();
  };

  const getAvailableRefundableAmount = () => {
    const amountReceived = selected_payment.amount_received;
    const amountRefunded = selected_payment.amount_refunded;
    const total = amountReceived - amountRefunded;
    return total;
  };

  const getAmountReceived = () => {
    if (selected_payment.amount_received !== selected_payment.amount) {
      return selected_payment.amount_received;
    }
    return -1;
  };

  const getAmountOverpaid = () => {
    if (selected_payment.amount_overpaid > 0) {
      return selected_payment.amount_overpaid;
    }
    return -1;
  };

  const handleCurrencyChange = (e) => {
    console.log("handleCurrencyChange", e);
    setValue(
      "currency",
      e.value,
    )
  };

  const handleCryptoCurrencyChange = (e) => {
    console.log("handleCryptoCurrencyChange", e);
    setValue(
      "cryptoCurrency",
      e.value,
    )
    setIsActiveCurrency(e.is_active);
    setNetworks(sortNetworks(e.networks));
  };


  const onSubmit = () => {
    const data = {
      payment: selected_payment.id,
      address: address,
      amount: Number(currencyConverter(currency, refundAmount, true)),
      memo: memo || "",
      network: network,
      use_original_exchange_rate: useOriginalExchangeRate,
    };

    if (user.two_factor_authentication_enabled) {
      data.otp = otp;
    }

    if (current_account?.otp?.enabled?.refund) {
      data.verification_code = verificationCode;
    }

    createdRefund.mutate({
      data: data
    }, {
      onSuccess: (data) => {
        console.log("onSuccess", data);
      },
      onError: (err) => {
        console.log("onError", err);
      }
    });
  };

  return (
    <Modal show={show} onClose={handleClose} size="lg">
      <Modal.Slot name="header">
        <div>
          <div>Issue a Refund</div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        {selected_payment.crypto_currency !== cryptoCurrency && (
          <div className="bg-orange-200 border border-orange-300 text-orange-500 rounded-md py-1 px-2 w-full text-sm mb-1">
            <WarningIcon className="w-4 h-4 inline-block mr-1" />
            It is advised to issue refunds in the original currency the payment was made in.
          </div>)}
        {formErrors.other && (
          <div className="bg-red-200 border border-red-300 text-red-500 rounded-md py-1 px-2 w-full text-sm mb-1">
            <WarningIcon className="w-4 h-4 inline-block mr-1" />
            An unknown error has occurred
          </div>)}
        <form id="issueRefundForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-end">
            <CopyToClipboardBoxID text={selected_payment?.id} />
          </div>
          <div className="app-payments__big-amount">
            <div>
              <div className="app-modal-fullscreen__header__title">Payment</div>
              <div className="app-payments__big-amount__digits text-dark">
                {currencySymbol(selected_payment.currency)}
                {currencyFormatter(
                  selected_payment.currency,
                  selected_payment.amount
                )}{" "}
                <span>{selected_payment.currency?.toUpperCase()}</span>
              </div>
            </div>
            {getAmountReceived() !== -1 && (
              <div>
                <div className="app-modal-fullscreen__header__title">
                  Amount Received
                </div>
                <div className="app-payments__big-amount__digits text-dark">
                  {currencySymbol(selected_payment.currency)}
                  {currencyFormatter(
                    selected_payment.currency,
                    getAmountReceived()
                  )}{" "}
                  <span>{selected_payment.currency?.toUpperCase()}</span>
                </div>
              </div>
            )}
          </div>
          <div className="grid justify-items-start mt-5">
            <div className="grid justify-items-start grid-cols-3 w-full">
              {getAmountOverpaid() !== -1 && (
                <div>
                  <div className="app-modal-fullscreen__label">
                    Amount Overpaid
                  </div>
                  <div className="text-light text-xl">
                    {currencySymbol(selected_payment.currency)}
                    {currencyFormatter(
                      selected_payment.currency,
                      getAmountOverpaid()
                    )}{" "}
                    <span className="text-thin">
                      {selected_payment.currency?.toUpperCase()}
                    </span>
                  </div>
                </div>
              )}
              <div>
                <div className="app-modal-fullscreen__label">Amount Refunded</div>
                <div className="text-light text-lg font-semibold">
                  {currencySymbol(selected_payment.currency)}
                  {currencyFormatter(
                    selected_payment.currency,
                    selected_payment.amount_refunded
                  )}{" "}
                  <span className="text-semi">
                    {selected_payment.currency?.toUpperCase()}
                  </span>
                </div>
              </div>

              <div>
                <div className="app-modal-fullscreen__label">Available</div>
                <div className="text-tertiary font-semibold" >
                  {currencySymbol(selected_payment.currency)}
                  {currencyFormatter(
                    selected_payment.currency,
                    getAvailableRefundableAmount()
                  )}{" "}
                  <span className="font-semibold">
                    {selected_payment.currency?.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-10 mt-5">
            <div className="flex-1">
              <Label title="Refund amount" error={""} />
              <InputPrice
                {...register("refundAmount")}
                block
                currencies={FIAT_CURRENCIES_SELECT}
                onCurrencyChange={handleCurrencyChange}
                placeholder="0.00"
                currency={currency}
                value={refundAmount}
                onChange={(e) => {
                  setValue(
                    "refundAmount",
                    e.target.value,
                  )
                }}
                error={
                  refundAmount > currencyFormatter(selected_payment.currency, getAvailableRefundableAmount())
                    ? "Amount is greater than available" : formErrors.currency ? formErrors.currency : ""
                }
              />
            </div>
            <div className="flex-1">
              <Label title="Currency" error={""} />
              <InputPriceCrypto
                {...register("cryptoAmount")}
                block
                currencies={currencies}
                onCurrencyChange={handleCryptoCurrencyChange}
                placeholder="0.00"
                currency={cryptoCurrency.toUpperCase()}
                error={!isActiveCurrency ? "This currency is currently not available for refunds" : formErrors.cryptoAmount ? formErrors.cryptoAmount : ""}
                value={cryptoAmount}
                notChangable={true}
              />
            </div>
          </div>
          <div
            style={{ margin: "0 auto" }}
            className="text-center text-sm text-light"
          >
            Approx exchange rate: 1 {" "} {cryptoCurrency.toUpperCase()} = {refundEstimate?.exchange_rate} {" "} {currency.toUpperCase()}
          </div>
          <div className="flex gap-10 mt-5">
            <div className="flex-1">
              <Label title="Network" error={""} />
              <SelectNetwork
                error={formErrors.network}
                value={network}
                disabled={false}
                options={networks}
                onSelect={handleNetworkSelect}
                warning={
                  selected_payment.blockchain_transaction.network !== network ? "It is advised to issue refunds on the original network the payment was made on." : ""
                }
                placeholder="Select network"
              />
            </div>
            <div className="flex-1">
              <Label title="Address" error={""} />
              <Input
                className="w-full"
                placeholder="Enter address"
                value={address}
                error={
                  selected_payment.blockchain_transaction?.from_addr.toLowerCase() !== address.toLowerCase() ? "It is advised to issue refunds to the original address the payment was made from." : ""
                }
                onChange={(e) => {
                  setValue(
                    "address",
                    e.target.value,
                  )
                }}
              />
            </div>

          </div>
          {network === "ripple" ||
            network === "stellar" ||
            network === "binance_smart_chain" ? (
            <div className="mt-4">
              <Label
                icon={InfoIconImage}
                tooltipHtml={true}
                optional={true}
                tooltip={`Some blockchain networks may require an optional transaction memo or tag.
                            If this is not included the transaction may not reach its intended target
                            and the resulting transaction cannot be reversed.`}
                title={network === "ripple" ? "Destination tag" : "Memo"}
                error={""}
              />
              <Input
                className=""
                placeholder="Enter destination tag"
                value={memo}
                onChange={(e) => {
                  setValue(
                    "memo",
                    e.target.value,
                  )
                }}
              />
            </div>
          ) : null}
          <div className="app-modal-fullscreen__header__title mt-5 mb-3 cursor-pointer"
            onClick={() => {
              if (!showAdvance && useOriginalExchangeRate) {
                setShowAdvance(true)
              } else if (showAdvance && useOriginalExchangeRate) {
                return;
              }
              setShowAdvance(!showAdvance)
              fetchRefundsEstimate.refetch();
            }}
          >Advanced</div>
          {showAdvance && (<>
            <div className="flex items-center gap-3 ">
              <Checkbox
                {...register("useOriginalExchangeRate")}
                color="primary"
                className="mt-1"
                onChange={(e) => {
                  setValue(
                    "useOriginalExchangeRate",
                    e.target.checked,
                  )
                  if (e.target.checked) {
                    setValue(
                      "cryptoAmount",
                      refundEstimate?.crypto_amount,
                    )
                  } else {
                    setValue(
                      "cryptoAmount",
                      refundEstimate?.crypto_amount,
                    )
                  }
                }}
                disabled={
                  cryptoCurrency !== selected_payment.crypto_currency
                }
              />

              <div className="text-light text-sm">Use original exchange rate</div>
              <ToolTip
                tooltip={
                  "Use the cryptocurrency exchange rate from the original payment. If not selected, the current exchange rate is used to calculate the refund amount. This is only valid when refunding the same cryptocurrency as the original payment."
                }
                icon={InfoIconImage}
              />
            </div>
            {selected_payment.application_fee && <div className="flex items-center gap-3 ">
              <Checkbox
                {...register("refundApplicationFee")}
                color="primary"
                className="mt-1"

              />
              <div className="text-light text-sm">Refund Application Fee</div>
            </div>}
            {selected_payment.transfer_data && <div className="flex items-center gap-3 ">
              <Checkbox
                {...register("reverseTransaction")}
                color="primary"
                className="mt-1"

              />
              <div className="text-light text-sm">Reverse Transaction</div>
            </div>}
          </>)}
        </form>
      </Modal.Slot>
      <Modal.Slot name="test">
        <div className="w-full bg-[#E3F7FF] h-[112px] grid justify-items-start grid-cols-3 p-5">
          <div>
            <div className="app-modal-fullscreen__label">
              Exchange Rate
            </div>
            <div className="text-light text-lg font-semibold">
              {refundEstimate &&
                <div className="flex gap-1 items-start">
                  <img className="w-5 h-5 mt-1" src={getIconUrl(cryptoCurrency.toLowerCase())} alt="icon" />
                  <div className="flex flex-col items-start">
                    <span className="">1 = {refundEstimate?.exchange_rate} {" "} {currency.toUpperCase()}</span>
                    <ExpiryTimer expiry={refundEstimate?.expiry} handleNewEstimates={
                      () => {
                        fetchRefundsEstimate.refetch();
                      }
                    } compact={true} />
                  </div>
                </div>}
            </div>
          </div>
          <div>
            <div className="app-modal-fullscreen__label">
              Network Fee
            </div>
            <div className="text-light text-lg font-semibold">
              {refundEstimate &&
                <div className="flex gap-1 items-center">
                  <img className="w-5 h-5" src={getIconUrl(refundEstimate?.network_data?.network_fee_currency?.toLowerCase())} alt="icon" />
                  <span>{cryptocurrencyFormatter(refundEstimate?.network_data?.network_fee)}</span>
                </div>}
            </div>
          </div>
          <div>
            <div className="app-modal-fullscreen__label">
              Total Refund
            </div>
            <div className="text-light text-lg font-semibold">
              {refundEstimate &&
                <div className="flex gap-1 items-start">
                  <img className="w-5 h-5 mt-1" src={getIconUrl(refundEstimate?.crypto_currency?.toLowerCase())} alt="icon" />
                  <div className="flex flex-col items-start">
                    <span className="">{cryptocurrencyFormatter(refundEstimate?.network_data?.network_fee)} {""} {refundEstimate?.crypto_currency?.toUpperCase()}</span>
                    <span className="text-sm text-light"> ({currencyFormatter(selected_payment.currency, refundEstimate?.total_refund)} {refundEstimate?.currency.toUpperCase()} ) </span>
                  </div>
                </div>}
            </div>
          </div>
        </div>
        <div className="flex gap-10 p-5">
          {user?.two_factor_authentication_enabled &&
            <div className="w-1/2">
              <Label title="2FA Code" error={""} />
              <Input
                className="w-full"
                placeholder="Enter your 2FA code"
                value={otp}
                error={error.otp}
                onChange={handleOnChange}
              />
            </div>
          }

          {current_account?.otp?.enabled?.refund &&
            <div className="w-1/2">
              <Label title="Verification code" error={""} />
              <div className="flex gap-2">
                <Input
                  className="w-full"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  error={error.verificationCode}
                  onChange={handleOnChange}
                />
                <button className="rounded-md bg-primary py-2 px-3.5 whitespace-nowrap text-white text-sm font-semibold" type="button" onClick={() => {
                  console.log("get code")
                }}>Get code</button>
              </div>
            </div>
          }
        </div>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end gap-3">
          <Button
            type="submit"
            form='issueRefundForm'
            onClick={() => {
              onSubmit()
            }}
            disabled={(refundAmount > currencyFormatter(selected_payment.currency, getAvailableRefundableAmount())) || !isActiveCurrency}
          >
            Submit
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
};

export default IssueRefundModal;
