import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useFetchTopup } from 'api/wallet/topups';
import DashboardNavbar from "layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as ExportIcon } from "assets/images/icons/export.svg";
import useTableFilters from "hooks/useTableFilters";
import DropdownFilters from "components/Common/DropdownFilters/DropdownFilters";
import { setRefetchTopup, setSelectedTopup } from "store/topups/topupsSlice";
import TopupsTable from "./TopupsTable";
import TopupsCreateModel from "./TopupsCreateModel";
import TopupDetails from "./TopupDetails";

const TABLE_FILTERS = [
  { label: "All", column: "type", value: "" },
  { label: "Crypto", column: "type", value: 'crypto' },
  { label: "Fiat", column: "type", value: 'fiat'},
];

function TopUps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selected_topup, refetch_topup } = useSelector((state) => state.topup);
  const [showDetailModal, setShowDetailsModal] = useState(false);
  const { id } = useParams();
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create"
  );
  const [refreshTopup, setRefreshTopup] = useState(true);
  const [refreshTopupList, setRefreshTopupList] = useState(true);
  const [filters, setFilters] = useState({});
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

  const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchTopup({
    onSuccess: (data) => {
      dispatch(setSelectedTopup(data));
      setRefreshTopup(false);
    },
    onError: (error) => {
      setRefreshTopup(false);
      navigate(DASHBOARD_PATH + "/topups");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: !!id && (id !== "create" && id !== "edit"),
    params: {
      expand: [
        'blockchain_transaction',
      ],
    },
  });
  // Details modal:
  const handleCloseDetailModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedTopup(null));
    navigate(DASHBOARD_PATH + "/topups");
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_topup);
  }, [selected_topup]);

  useEffect(() => {
    console.log("id", id);
    if (!id) {
      handleCloseDetailModal();
      return;
    }
   
    
    if (id !== "create" && id !== "edit") {

      setRefetchTopup(false);

      return;
    } else {
      if (id === "create") {
        setRefetchTopup(false);
        setShowCreateModal(true);

        // navigate(DASHBOARD_PATH + "/products/create");
      }
    }

  }, [refetch_topup, id, handleCloseDetailModal]);
  // Closes modal
  useEffect(() => {
    return () => {
      dispatch(setSelectedTopup(null));
    };
  }, [dispatch]);

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setSelectedTopup(null));
    dispatch(setRefetchTopup(Date.now()));
    setFilter( TABLE_FILTERS[0])
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);
    setFilter({ column: "status", value: filters?.status ?? "" });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
    );
  }, [activeFilter]);

//   const getFilters = useMemo(() => {
//     return {
//       ...filters,
//       ...(activeFilter.status ? activeFilter : null),
//     };
//   }, [filters, activeFilter]);

//   const handleFilterClick = (filter) => {
//     if (filter && filter.label === "All") {
//       setFilters((state) => {
//         const { status, ...rest } = state;

//         return rest;
//       });
//     }

//     setFilter(filter);
//   };
 
// refresh the <TopupsTable/> list  when TopCreateModal is closed

useEffect(() => {

  console.log("showPriceModal", location.pathname);
  if (showCreateModal) {
    if (!location.pathname.endsWith("/create" ) ) {
          navigate(location.pathname + "/create");
    } 
  }  


}, [showCreateModal]);


  
  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Top ups</div>
              <span className="app-dashboard__card__header__filters">
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
            <DropdownFilters
                filters={[
                  "currency_type",
                  "topup_method",
                  "topup_status",
                  "amount",
                  "crypto_amount",
                  "currency",
                  "crypto_currency",
                  "crypto_from_address",
                  "tx_hash",
                  "date",
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page="topups"
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color='primary'
                outline
                icon='+'>
                <span className='text-dark'>
                  New Topup
                </span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
              </div>
          </div>
          {/* <TransactionTable/> */}
          <TopupsTable 
            refreshTopupList={refreshTopupList}
          filters={filters} />
        </div>
        {showDetailModal && (
          <TopupDetails
            show={showDetailModal}
            onClose={handleCloseDetailModal}
          />
        )}
        {showCreateModal && (
          <TopupsCreateModel
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
        <ReactTooltip
          id="topup-tooltip"
          place="top"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
        </ReactTooltip>
      </div>
    </div>
  );
}

export default TopUps;
