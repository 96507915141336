import Label from "../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../Common/Form/Input/Input";
import React from "react";

function ProductsModalCreateDescription({ register }) {
  return (
    <div>
      <Label
        title="Description" subtitle='(optional)'
      />
      <Input
        {...register("description")}
        block
      />
    </div>
  )
}

export default ProductsModalCreateDescription;
