import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchShippingRates = (params) => appStore.getState().app.livemode
  ? api.live.get("/shipping_rates", { params })
  : api.sandbox.get("/shipping_rates", { params });

export const useFetchShippingRates = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchShippingRates", params],
    () => fetchShippingRates(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchShippingRate = (id) => appStore.getState().app.livemode
  ? api.live.get(`/shipping_rates/${id}`)
  : api.sandbox.get(`/shipping_rates/${id}`);

export const useFetchShippingRate = ({id, onSuccess, onError, enabled}) => {
  return useQuery(
    ["fetchShippingRate", id],
    () => fetchShippingRate(id),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storeShippingRate = (data) => appStore.getState().app.livemode
  ? api.live.post("/shipping_rates", data)
  : api.sandbox.post("/shipping_rates", data);

export const useStoreShippingRate = () => {
  return useMutation(
    "storeShippingRate",
    storeShippingRate
  );
}

const updateShippingRate = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/shipping_rates/${id}`, data)
  : api.sandbox.patch(`/shipping_rates/${id}`, data);

export const useUpdateShippingRate = (id) => {
  return useMutation(
    ["updateShippingRate", id],
    (data) => updateShippingRate(id, data)
  );
}

const deleteShippingRate = (id) => appStore.getState().app.livemode
  ? api.live.delete(`/shipping_rates/${id}`)
  : api.sandbox.delete(`/shipping_rates/${id}`);

export const useDeleteShippingRate = (id) => {
  return useMutation(
    ["deleteShippingRate", id],
    () => deleteShippingRate(id)
  );
}