const PermissionsData = [
  {
    name: "core resources",
    permissions: {
      read: false,
      write: false,
    },
    linkAccountPermissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "balance",
        permissions: {
          read: false,
        },
        linkAccountPermissions: {
          read: false,
        }
      },
      {
        name: "balance transaction",
        permissions: {
          read: false,
        },
        linkAccountPermissions: {
          read: false,
        }
      },
      {
        name: "blockchain transaction",
        permissions: {
          read: false,
        },
        linkAccountPermissions: {
          read: false,
        }
      },
      {
        name: "currency",
        permissions: {
          read: false,
        },
        linkAccountPermissions: {
          read: false,
        }
      },
      {
        name: "customer",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "event",
        permissions: {
          read: false,
        },
        linkAccountPermissions: {
          read: false,
        }
      },
      {
        name: "payment",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "refund",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "payout",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "topup",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "swap",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "product",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "shipping rate",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "test clock",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
    ]
  },
  {
    name: "Billing resources",
    permissions: {
      read: false,
      write: false,
    },
    linkAccountPermissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "customer balance transaction",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "credit note",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "discount",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "invoice",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "price",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "subscription",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "tax rate",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "usage record",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
    ]
  },
  {
    name: "checkout session resources",
    permissions: {
      read: false,
      write: false,
    },
    linkAccountPermissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "checkout session",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
    ]
  },
  {
    name: "order resources",
    permissions: {
      read: false,
      write: false,
    },
    linkAccountPermissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "order",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "sku",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
    ]
  },
  {
    name: "payment links",
    permissions: {
      read: false,
      write: false,
    },
    linkAccountPermissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "payment link",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
    ]
  },
  {
    name: "walio link resources",
    permissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "account",
        permissions: {
          read: false,
          write: false,
        },
      },
      {
        name: "application fee",
        permissions: {
          read: false,
          write: false,
        },
      },
      {
        name: "login link",
        permissions: {
          write: false,
        },
      },
      {
        name: "account link",
        permissions: {
          write: false,
        },
      },
      {
        name: "transfer",
        permissions: {
          read: false,
          write: false,
        },
      },
    ]
  },
  {
    name: "webhook resources",
    permissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "webhook",
        permissions: {
          read: false,
          write: false,
        },
      },
    ]
  },
  {
    name: "game Developer Suite resources",
    permissions: {
      read: false,
      write: false,
    },
    linkAccountPermissions: {
      read: false,
      write: false,
    },
    subPermisssions: [
      {
        name: "wallet",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "wallet payout",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "wallet swap",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "wallet topup",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
      {
        name: "transfer",
        permissions: {
          read: false,
          write: false,
        },
        linkAccountPermissions: {
          read: false,
          write: false,
        }
      },
    ]
  },
]

export default PermissionsData;