import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchApiKeys = (params) => appStore.getState().app.livemode
  ? api.live.get("/api_keys", { params })
  : api.sandbox.get("/api_keys", { params });

export const useFetchApiKeys = ({ onSuccess, onError, enabled, params = null }) => {
  return useQuery(
    ["fetchApiKeys", params],
    () => fetchApiKeys(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const revealSecretKey = (id) => appStore.getState().app.livemode
  ? api.live.post(`/api_keys/${id}/reveal`)
  : api.sandbox.post(`/api_keys/${id}/reveal`);

export const useRevealSecretKey = () => {
  return useMutation(
    "revealSecretKey",
    revealSecretKey
  );
}

const createApiKey = (data) => appStore.getState().app.livemode
  ? api.live.post("/api_keys", data)
  : api.sandbox.post("/api_keys", data);

export const useCreateApiKey = () => {
  return useMutation(["createApiKey"], (data) => createApiKey(data));
}

const rollApiKey = (data, id) => appStore.getState().app.livemode
  ? api.live.post(`/api_keys/${id}/roll`, data)
  : api.sandbox.post(`/api_keys/${id}/roll`, data);

export const useRollApiKey = () => {
  ;
  return useMutation(["rollApiKey"], (data) => {
    return rollApiKey({
      otp: data.otp,
      delete_at: data.delete_at
    }, data.id);
  })
}

const deleteApiKey = (id, data) => appStore.getState().app.livemode
  ? api.live.delete(`/api_keys/${id}`, { data })
  : api.sandbox.delete(`/api_keys/${id}`, { data });

export const useDeleteApiKey = () => {
  return useMutation(["deleteApiKey"], (data) => {
    console.log(data);
    return deleteApiKey(data.id, {
      otp: data.otp,
      verification_code: data.verification_code,
    });
  });
}

const deleteAtApiKey = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/api_keys/${id}`, data)
  : api.sandbox.patch(`/api_keys/${id}`, data);

export const useDeleteAtApiKey = () => {
  return useMutation(["deleteAtApiKey"], (data) => {
    return deleteAtApiKey(data.id, {
      delete_at: data.delete_at,
      otp: data.otp,
      verification_code: data.verification_code,
    });
  });
}

const updateApiKey = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/api_keys/${id}`, data)
  : api.sandbox.patch(`/api_keys/${id}`, data);

export const useUpdateApiKey = () => {
  return useMutation(["updateApiKey"], (data) => {
    return updateApiKey(data.id, {
      name: data.name,
      note: data.note,
    });
  });
}

