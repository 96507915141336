import clsx from "clsx";
import ArrowDownIcon from "../../../../../assets/images/icons/dashboard/arrow_down.svg";
import {useRef, useState} from "react";
import useOutsideClick from "../../../../../hooks/useOutsideClick";
import Checkbox from "../../../../Common/Form/Checkbox/Checkbox";

const ALL_EVENTS = "*";

function WebhookSelectEvents({
  options = [],
  value = [],
  style,
  className = "",
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  useOutsideClick(selectRef, () => setOpen(false));

  const handleOptionClick = (option) => {
    rest.onSelect && rest.onSelect(option);
  }

  return (
    <div
      ref={selectRef}
      className={clsx(
        className,
        "app-select"
      )}
      style={style}
    >
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "app-select__input",
          open && "app-select__input--active"
        )}
      >
        <div className="app-select__input__value">
          <div className="flex items-center gap-3">
            <span>Events</span>
            <span className="vertical-divider" />
            <span className="text-tertiary">{value.length ?? 0}</span>
          </div>
        </div>
        <img className="app-select__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open &&
      <div className="app-select__options">
        {options.map((option, index) =>
          <div
            key={index}
            className="app-select__options__item app-select__options__item--hoverless"
          >
            <div className="app-webhook__modal__select__events">
              <div className="app-webhook__modal__select__events__label">{Object.keys(option)[0]}</div>
              <div className="app-webhook__modal__select__events__item">
                {option[Object.keys(option)[0]].map((item, index) => (
                  <div key={`event-${Object.keys(option)[0]}-${index}`} className="app-webhook__modal__select__events__item__label">
                    <span>{item}</span>
                    <Checkbox
                      disabled={item !== ALL_EVENTS && value.includes(ALL_EVENTS)}
                      color="primary"
                      size="sm"
                      onChange={() => handleOptionClick(item)}
                      checked={value.includes(item)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      }
    </div>
  )
}

export default WebhookSelectEvents;