import React, { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { useAsyncDebounce } from "react-table";
import { useFetchTaxRates } from "../../../api/product/tax_rate";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/modal-close.svg";

function CustomSelectTaxRates({
  value = null,
  placeholder = "",
  disabled = false,
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  const { isLoading, refetch } = useFetchTaxRates({
    onSuccess: (data) => setOptions(data.data),
    onError: () => null,
    enabled: false,
    params: {
      active: true,
      ...bindParams,
    },
  });

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const handleOptionClick = (option) => {
    setSelected(option);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const handleClearSelection = (e) => {
    e.stopPropagation();

    setSelected(null);
    rest.onSelect && rest.onSelect(null);
  };

  const handleOpenClick = () => {
    if (disabled) return;

    setOpen(!open);
  };

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={handleOpenClick}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active",
          disabled && "select-customer__input--disabled"
        )}
      >
        <div className="select-merchant__input__value">
          {!selected ? (
            <span>Select tax rate</span>
          ) : (
            <div className="select-merchant__input__selected">
              <div>
                {truncate(selected.display_name, {
                  length: 50,
                  separator: "...",
                })}
              </div>
              <span>-</span>
              <span>{selected.percent}%</span>
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-1">
          {selected && (
            <CloseIcon
              onClick={handleClearSelection}
              className="select-customer__input__clear"
            />
          )}
          <img
            className="select-customer__input__arrow"
            src={ArrowDownIcon}
            alt=""
          />
        </div>
      </div>
      {open && (
        <div className="select-customer__options">
          <div className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search">
            <input
              autoFocus
              type="text"
              className="select-customer__input__value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {isLoading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {!isLoading &&
            options.map((tax, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(tax)}
                className={clsx(
                  "select-customer__options__item",
                  selected &&
                    tax.id === selected.id &&
                    "select-customer__options__item--active"
                )}
              >
                <div className="font-normal">
                  {truncate(tax.display_name, { length: 50, separator: "..." })}
                </div>
                <span>({tax.percent})%</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default CustomSelectTaxRates;
