import { useDispatch, useSelector } from "react-redux";
import { useCurrentMerchantAccount, useFetchAccounts } from "../api/merchant/merchant";
import {
  logoutUser,
  setAuthenticated,
  setCurrentAccount,
  setMerchantAccounts,
  setUser
} from "../store/app/authSlice";
import useSwitchMerchantAccount from "./useMerchantAccount";
import { useFetchProfile } from "api/user/user";

function useAuthentication() {
  const accounts = useSelector((state) => state.auth.accounts);
  const dispatch = useDispatch();
  const { switchAccount, isLoading: isMerchantAccountLoading } = useSwitchMerchantAccount();

  const onFetchAccountsError = (error) => {
    if (error?.response?.status === 401) {
      dispatch(logoutUser());
    }
  }

  const onFetchAccountsSuccess = (accounts) => {
    dispatch(setMerchantAccounts(accounts));
    dispatch(setAuthenticated(true));
  }

  const {
    isLoading: isAccountsLoading,
    isFetched: isAccountsFetched,
    isSuccess: isAccountsSuccess
  } = useFetchAccounts({
    onError: onFetchAccountsError,
    onSuccess: onFetchAccountsSuccess
  });

  const onFetchProfileSuccess = (user) => {
    dispatch(setUser(user));
  }

  const onFetchProfileError = (error) => {
    if (error?.response?.status === 401) {
      dispatch(logoutUser());
    }
  }

  const {
    isLoading: isUserLoading
  } = useFetchProfile({
    onSuccess: onFetchProfileSuccess,
    onError: onFetchProfileError
  });

  const onCurrentMerchantAccountSuccess = (id) => {
    if (id === null && accounts.length) {
      dispatch(setCurrentAccount(accounts[0]));
      switchAccount(accounts[0].id);
      return;
    }

    const accountExist = accounts.find((account) => account.id === id);
    if (!accountExist) {
      dispatch(setCurrentAccount(accounts[0]));
      switchAccount(accounts[0].id);
      return;
    }

    dispatch(setCurrentAccount(accountExist));
    switchAccount(id);
  }

  const {
    isLoading: isCurrentMerchantAccountsLoading
  } = useCurrentMerchantAccount({
    onSuccess: onCurrentMerchantAccountSuccess,
    enabled: isAccountsFetched && isAccountsSuccess
  });

  const isFetchingData = isMerchantAccountLoading || isAccountsLoading || isCurrentMerchantAccountsLoading || isUserLoading;

  return {
    isFetchingData
  };
}

export default useAuthentication;