import EditableCell from "./EditableCell";
import React from "react";

const NumberEditableCell = (props) => (
  
  <EditableCell
  Label={props.label}
    {...props}
    type="text"
    label={props.label}
    pattern="[0-9]*"
    onKeyPress={(event) => {
      if (!/[0-9]/.test(event.key) && event.key !== ".") {
        event.preventDefault();
      }
    }}
    
  />
);

export default NumberEditableCell;
