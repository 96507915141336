import Label from "../../../../../../../Common/Form/Label/Label";
import InputDatePicker from "../../../../../../../Common/Form/InputDatePicker/InputDatePicker";
import React from "react";

function DiscountsModalCreateExpires({ end_date, setValue }) {
  return (
    <div>
      <Label
        title="Expires"
      />
      <InputDatePicker
        date={end_date}
        onChange={(date) => setValue("end_date", date)}
        min={new Date()}
      />
    </div>
  )
}

export default DiscountsModalCreateExpires;
