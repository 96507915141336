import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    selected_payment: null,
    refetch_payment: null,
  },
  reducers: {
    setSelectedPayment: (state, payload) => {
      state.selected_payment = payload.payload;
      console.log(
        'testing selelected payment data from reducer',
        payload
      );
    },
    setRefetchPayment: (state, payload) => {
      state.refetch_payment = payload.payload;
    },
  },
});

export const { setSelectedPayment, setRefetchPayment } =
  paymentSlice.actions;

export default paymentSlice.reducer;
