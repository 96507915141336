import DashboardTable from '../../../../Dashboard/DashboardTable/DashboardTable';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DateIcon } from '../../../../../assets/images/icons/date.svg';
import { ReactComponent as ImagePlaceholder } from '../../../../../assets/images/dashboard/product-placeholder.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDeleteProduct,
  useFetchProducts,
} from '../../../../../api/product/product';
import { dateFormatter } from '../../../../../utils/date';
import {
  setRefetchProduct,
  setSelectedProduct,
} from '../../../../../store/product/productSlice';
import { toast } from 'react-toastify';
import ModalDelete from '../../../../Dashboard/Modals/ModalDelete/ModalDelete';
import ProductsTableActions from './ProductsTableActions/ProductsTableActions';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { simplePriceDescription } from '../../../../../utils/price_utils';
import { useNavigate } from 'react-router-dom';

function ProductsTable({ filters = {} }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current_account } = useSelector(
    (state) => state.auth
  );
  const { refetch_product } = useSelector(
    (state) => state.product
  );
  const { live_mode_access } = useSelector(
    (state) => state.auth.current_account
  );
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [showDeleteModal, setShowDeleteModal] =
    useState(false);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  const {
    mutate: mutateDeleteProduct,
    isLoading: isDeleteLoading,
  } = useDeleteProduct(deleteProduct?.id);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_product, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchProductsSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        updated: row.updated_at
          ? dateFormatter(row?.updated_at, true)
          : null,
        created: dateFormatter(row.created_at),
        image_url: row.image_url,
        name: row.name,
        description: row.description ?? '-',
        id: row.id,
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchProductsError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchProducts({
    onSuccess: onFetchProductsSuccess,
    onError: onFetchProductsError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters,
      include: ['prices'],
    },
  });

  const DASHBOARD_PATH = !live_mode_access ? "/dashboard/test" : "/dashboard";
  const handleRowClick = (row) => {
    // dispatch(setSelectedProduct(row._data));

    navigate(DASHBOARD_PATH + `/products/${row.id}`);
  };

  const handleDeleteClick = (product) => {
    setDeleteProduct(product);
    setShowDeleteModal(true);
  };

  const handleModalDelete = () => {
    mutateDeleteProduct(null, {
      onSuccess: () => {
        setDeleteProduct(null);
        setShowDeleteModal(false);
        dispatch(setSelectedProduct(null));
        dispatch(setRefetchProduct(Date.now()));
        toast.success(`Product deleted successfully`);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'image',
        width: 3,
        Cell: (cell) => (
          <div className='app-products__table__image'>
            {cell.row.original.image_url.length ? (
              <img
                src={cell.row.original.image_url[0]}
                alt='Product'
              />
            ) : (
              <ImagePlaceholder />
            )}
          </div>
        ),
      },
      {
        Header: 'Name',
        id: 'name',
        maxWidth: 100,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div>
              <div className='font-semibold'>
                {row.name}
              </div>
              <div className='app-customers__modal__earning'>
                {row._data.prices.data.length > 0 &&
                  (row._data.prices.data.length === 1 ? (
                    <div className='text-light'>
                      {simplePriceDescription(
                        row._data.prices.data[0]
                      )}
                    </div>
                  ) : (
                    <div className='text-light'>
                      {row._data.prices.data.length} prices
                    </div>
                  ))}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        maxWidth: 120,
        Cell: (cell) => {
          return (
            <div className='app-modal-fullscreen__value'>
              {cell.value}
            </div>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created',
        maxWidth: 40,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              <DateIcon className='mr-1.5' />
              <span className='text-light'>
                {cell.value}
              </span>
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'icon',
        width: 10,
        Cell: (cell) => (
          <ProductsTableActions
            product={cell.row.original._data}
            onDelete={handleDeleteClick}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Products"
        />
      </div>
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title='Delete Product'
          message='By deleting this product, any prices or SKUs associated with it will also be deleted. Are you sure you want to delete this product?'
        />
      )}
    </>
  );
}

export default ProductsTable;
