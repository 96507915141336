import {
	useFetchQrCode,
	useDisableTwoFactorAuthentication,
	useEnableTwoFactorAuthentication,
	useFetchProfile,
} from 'api/user/user'
import Modal from 'components/Common/Modal/Modal'
import QRCodeStyling from 'qr-code-styling'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import Logo from 'assets/images/logo-small.svg'
import Input from 'components/Common/Form/Input/Input'
import Button from 'components/Common/Form/Button/Button'
import Label from 'components/Common/Form/Label/Label'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'store/app/authSlice'

const Toggle2FA = ({
	show = false,
	onClose = null,
	type = 'enable',
}) => {
	const handleClose = () => {
		onClose && onClose()
	}
	const [otp, setOtp] = useState('');
	const [error, setError] = useState('');

	const dispatch = useDispatch();
	// const { accounts, user } = useSelector((state) => state.auth);
	const { refetch } = useFetchProfile(
		{
			onSuccess: (user) => {
				console.log(user)
				dispatch(setUser(user));
			},
			onError: (error) => {
				console.log(error)
			}
		}
	);

	const [data, setData] = useState(null);
	const qrRef = useRef(null);

	useFetchQrCode({
		onSuccess: (res) => {
			console.log(res)
			setData(res)
		},
		onError: (err) => {
			console.log(err)
		}
	});

	const { mutate: enableTwoFactorAuthentication } = useEnableTwoFactorAuthentication({
		onSuccess: (res) => {
			console.log(res)
			refetch();
			handleClose();
		},
		onError: (err) => {
			if (err?.error?.message) {
				setError(err?.error?.message)
			}
		}
	});

	const { mutate: disableTwoFactorAuthentication } = useDisableTwoFactorAuthentication({
		onSuccess: (res) => {
			console.log(res)
			refetch();
			handleClose();
		},
		onError: (err) => {
			if (err?.error?.message) {
				setError(err?.error?.message)
			}
		}
	});

	const qrCode = useMemo(
		() =>
			new QRCodeStyling({
				width: 200,
				height: 200,
				type: "svg",
				data: data?.data,
				image: Logo,
				dotsOptions: {
					color: "#000",
					type: "rounded",
				},
				cornersSquareOptions: { color: "#47CE7D", type: "extra-rounded" },
				cornersDotOptions: { color: "#6d5dd9" },

			}),
		[data]
	);

	useEffect(() => {
		if (qrRef.current && type === 'enable') {
			qrCode.append(qrRef.current);
		}
	}, [qrCode]);

	const handleSubmit = (e) => {
		const data = {
			otp: otp,
		}
		console.log(data)
		e.preventDefault();
		if (otp.length !== 6) {
			setError('Please enter a valid OTP')
			return;
		}
		if (type === 'enable') {
			enableTwoFactorAuthentication(data)
		}
		if (type === 'disable') {
			disableTwoFactorAuthentication(data)
		}
	}

	const handleCopyToClipBoard = () => {
	}

	return (
		<div>
			<Modal style={{ overflow: "visible" }} show={show} onClose={handleClose} size='sm'>
				<Modal.Slot name='header'>
					<div className='app-invoice__modal__title capitalize '>
						{type} Authenticator App
					</div>
				</Modal.Slot>
				<Modal.Slot name='body'>
					{type === 'enable' && (
						<div className='text-xs text-red-500 mb-2'>
							* Please enable 2FA Authentification to make withdrawals. If you loose access to your Authentificator App, we cannot recover your account. Back up your Secret in a secure place!
						</div>
					)}
					{type === 'disable' && (
						<div className='text-orange-400 border border-orange-400 bg-orange-100 text-xs p-2 rounded-md mb-4'>
							Disabling Two Factor Authentication will compromise the security of your account. It will also prevent you from doing certain actions such as Payouts and Refunds.
						</div>
					)}
					{type === 'enable' && (
						<div className='flex flex-col items-center my-8'>
							<div ref={qrRef} />
						</div>
					)}
					<form className='flex flex-col gap-3'>
						{type === 'enable' && (
							<div className='flex flex-col'>
								<Label title='Secret' />
								<Input
									className='w-full'
									type='text'
									placeholder='Secret'
									value={data?.secret || ''}
									name='secret'
								/>
							</div>
						)}
						<div className='flex flex-col'>
							<Label title='2FA Code' />
							<Input
								className='w-full'
								type='text'
								placeholder='Enter 2FA Code'
								value={otp}
								onChange={(e) => {
									if (e.target.value.length > 6) {
										setError('Please enter a valid OTP')
									}
									if (e.target.value.length === 6) {
										setError('')
									}
									setOtp(e.target.value)
								}}
								error={error}
								name='otp'
							/>
						</div>
						<div className='w-full flex flex-col mt-2'>
							<Button
								className='w-fit self-end -mb-4 capitalize'
								type='submit'
								onClick={handleSubmit}
								disabled={!otp || otp.length < 6}
							>
								{type}
							</Button>
						</div>
					</form>
				</Modal.Slot>
			</Modal>
		</div>
	)
}

export default Toggle2FA