import Label from '../../../../../../../Common/Form/Label/Label';
import Select from '../../../../../../../Common/Form/Select/Select';
import moment from 'moment';
import { useUpdateInvoice } from '../../../../../../../../api/customer/invoice';
import { useDispatch, useSelector } from 'react-redux';
import { setDraftInvoice } from '../../../../../../../../store/invoice/invoiceSlice';
import InputDatePicker from '../../../../../../../Common/Form/InputDatePicker/InputDatePicker';
import { useEffect, useState } from 'react';

const DUE_DATE_OPTIONS = [
  {
    label: 'Tomorrow',
    value: 1,
    // value: moment().startOf("day").add(1, "days").endOf("day").unix(),
  },
  {
    label: '7 days',
    value: 7,
    // value: moment().startOf("day").add(7, "days").endOf("day").unix(),
  },
  {
    label: '14 days',
    value: 14,
    // value: moment().startOf("day").add(14, "days").endOf("day").unix(),
  },
  {
    label: '30 days',
    value: 30,
    // value: moment().startOf("day").add(30, "days").endOf("day").unix(),
  },
  {
    label: '45 days',
    value: 45,
    // value: moment().startOf("day").add(45, "days").endOf("day").unix(),
  },
  {
    label: '60 days',
    value: 60,
    // value: moment().startOf("day").add(60, "days").endOf("day").unix(),
  },
  {
    label: '90 days',
    value: 90,
    // value: moment().startOf("day").add(90, "days").endOf("day").unix(),
  },
  { label: 'Custom', value: 'custom' },
];

function InvoiceDueDate({
  due_date,
  days_until_due,
  setValue,
}) {
  const dispatch = useDispatch();
  const [date, setDate] = useState('');
  const [selected, setSelected] = useState(null);
  const { draft_invoice } = useSelector(
    (state) => state.invoice
  );
  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(
    draft_invoice?.id
  );

  const handleDueDateSelect = (item) => {
    setSelected(item.value);
    setValue('days_until_due', item.value);

    if (!draft_invoice) return;
    if (item?.value === 'custom') return;

    mutateUpdateInvoice(
      { days_until_due: item.value },
      {
        onSuccess: (data) =>
          dispatch(setDraftInvoice(data.data)),
      }
    );
  };

  const handleDatePickerChange = (date) => {
    setDate(date);

    const _date = moment(new Date(date))
      .endOf('day')
      .unix();
    setValue('due_date', _date);

    if (!draft_invoice) return;

    mutateUpdateInvoice(
      { due_date: _date },
      {
        onSuccess: (data) =>
          dispatch(setDraftInvoice(data.data)),
      }
    );
  };

  useEffect(() => {
    if (!date) {
      if (days_until_due) {
        setSelected(days_until_due);
      } else {
        const _date = moment.unix(due_date).toDate();
        setDate(_date);
        setSelected('custom');
      }
    }
  }, [setValue, due_date, days_until_due, date]);

  return (
    <div>
      <Label title='Due Date' />
      <div className='flex gap-3'>
        <Select
          className='w-1/4'
          value={selected}
          options={DUE_DATE_OPTIONS}
          onSelect={handleDueDateSelect}
        />
        {selected === 'custom' && (
          <InputDatePicker
            date={date}
            min={new Date()}
            onChange={handleDatePickerChange}
          />
        )}
      </div>
    </div>
  );
}

export default InvoiceDueDate;
