import Modal from '../../../../../../Common/Modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import Stack from '../../../../../../Common/Stack/Stack';
import clsx from 'clsx';
import ArrowIcon from '../../../../../../../assets/images/icons/dashboard/arrow_down.svg';
import Button from '../../../../../../Common/Form/Button/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFormSchemaDiscount,
  setFormSchemaDiscount,
  setRefetchDiscount,
} from '../../../../../../../store/discount/discountSlice';
import {
  useStoreDiscount,
  useUpdateDiscount,
} from '../../../../../../../api/product/discount';
import { toast } from 'react-toastify';
import {
  currencyConverter,
  currencyFormatter,
} from '../../../../../../../utils/string';
import {
  ITEM_PRICE_REGEX
} from '../../../../../../../utils/constants';
import DiscountsModalCreateName from './DiscountsModalCreateForm/DiscountsModalCreateName';
import DiscountsModalCreatePromoCode from './DiscountsModalCreateForm/DiscountsModalCreatePromoCode';
import DiscountsModalCreateType from './DiscountsModalCreateForm/DiscountsModalCreateType';
import DiscountsModalCreateBehavior from './DiscountsModalCreateForm/DiscountsModalCreateBehavior';
import DiscountsModalCreateAmountOff from './DiscountsModalCreateForm/DiscountsModalCreateAmountOff';
import DiscountsModalCreatePercentOff from './DiscountsModalCreateForm/DiscountsModalCreatePercentOff';
import DiscountsModalCreateProducts from './DiscountsModalCreateForm/DiscountsModalCreateProducts';
import DiscountsModalCreateAmountGte from './DiscountsModalCreateForm/DiscountsModalCreateAmountGte';
import DiscountsModalCreateQuantityGte from './DiscountsModalCreateForm/DiscountsModalCreateQuantityGte';
import DiscountsModalCreateMaxRedemptions from './DiscountsModalCreateForm/DiscountsModalCreateMaxRedemptions';
import DiscountsModalCreateExpires from './DiscountsModalCreateForm/DIscountsModalCreateExpires';
import ReactTooltip from 'react-tooltip';

function DiscountsModalCreate({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { form_schema_discount, selected_discount } =
    useSelector((state) => state.discount);

  const { current_account } = useSelector((state) => state.auth);
  const getFormSchema = useMemo(() => {
    const validationSchema = {};

    validationSchema.name = yup
      .string()
      .required()
      .label('Name');
    validationSchema.type = yup
      .string()
      .required()
      .label('Type');
    validationSchema.max_redemptions = yup
      .string()
      .label('Maximum redemptions');

    if (form_schema_discount.behavior === 'amount_off') {
      validationSchema.amount_off = yup
        .string()
        .required()
        .trim()
        .matches(
          ITEM_PRICE_REGEX,
          'Amount off can be have a maximum of 2 decimals'
        )
        .label('Value');
      validationSchema.currency = yup
        .string()
        .required()
        .label('Currency');
    }

    if (form_schema_discount.behavior === 'percent_off') {
      validationSchema.percent_off = yup
        .string()
        .required()
        .trim()
        .matches(
          /^([0-9]{1,2}(\.[0-9]{1,2})?|100(\.00+)?)$/,
          'Precent must be between 0-100 and can have a maximum of 2 decimals'
        )
        .label('Value');
    }

    if (form_schema_discount.type === 'amount_gte') {
      validationSchema.amount_gte = yup
        .string()
        .required()
        .trim()
        .matches(
          ITEM_PRICE_REGEX,
          'Sale amount can be have a maximum of 2 decimals'
        )
        .label('Amount');
    }

    if (form_schema_discount.type === 'amount_gte' && form_schema_discount.behavior !== 'amount_off') {
      validationSchema.currency = yup
        .string()
        .required()
        .label('Currency');  
    }

    if (form_schema_discount.type === 'product_quantity') {
      validationSchema.quantity_gte = yup
        .number()
        .min(1)
        .max(99999999)
        .required()
        .label('Quantity');
    }
    
    return validationSchema;
  }, [form_schema_discount]);

  const {
    register,
    watch,
    setValue,
    trigger,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: selected_discount?.name ?? '',
      promo_code: selected_discount?.promo_code ?? '',
      type: selected_discount?.type ?? 'standard',
      behavior: selected_discount?.id
        ? selected_discount?.percent_off
          ? 'percent_off'
          : 'amount_off'
        : 'amount_off',
      percent_off: selected_discount?.percent_off ?? '',
      amount_off: selected_discount?.amount_off
        ? currencyFormatter(
            selected_discount?.currency,
            selected_discount?.amount_off
          )
        : '',
      currency: selected_discount?.currency ?? current_account?.settings?.reporting_currency,
      amount_gte: selected_discount?.amount_off
        ? currencyFormatter(
            selected_discount?.currency,
            selected_discount?.amount_gte
          )
        : '',
      quantity_gte: selected_discount?.quantity_gte ?? '',
      max_redemptions:
        selected_discount?.max_redemptions ?? '',
      end_date: selected_discount?.end_date
        ? moment.unix(selected_discount?.end_date).toDate()
        : null,
      products: selected_discount?.products ?? [],
    },
    resolver: yupResolver(
      yup.object(getFormSchema).required()
    ),
  });

  const type = watch('type');
  const behavior = watch('behavior');
  const currency = watch('currency');
  const end_date = watch('end_date');

  const { mutate: mutateStoreDiscount } =
    useStoreDiscount();
  const { mutate: mutateUpdateDiscount } =
    useUpdateDiscount(selected_discount?.id);

  useEffect(() => {
    if (!selected_discount) return;

    dispatch(
      setFormSchemaDiscount({
        behavior: selected_discount?.percent_off
          ? 'percent_off'
          : 'amount_off',
        type: selected_discount?.type,
      })
    );
  }, [selected_discount, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetFormSchemaDiscount());
    };
  }, [dispatch]);

  const handleClose = () => {
    onClose && onClose();
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const onSubmit = (formData) => {
    if (selected_discount?.id) {
      const _data = { name: formData.name };
      if (formData.end_date)
        _data.end_date = moment(formData.end_date).unix();

      mutateUpdateDiscount(_data, {
        onSuccess: () => {
          dispatch(resetFormSchemaDiscount());
          dispatch(setRefetchDiscount(Date.now()));
          toast.success('Discount updated successfully');
          handleClose();
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      });
      return;
    }

    const _data = {
      name: formData.name,
      type: formData.type,
      start_date: 'now',
    };

    if (formData.behavior === 'amount_off') {
      _data.amount_off = Number(currencyConverter(
        formData.currency,
        formData.amount_off
      ));
      _data.currency = formData.currency;
    }

    if (formData.behavior === 'percent_off') {
      _data.percent_off = Number(formData.percent_off);
    }

    if (formData.type === 'amount_gte') {
      _data.amount_gte = Number(currencyConverter(
        formData.currency,
        formData.amount_gte
      ));
      _data.currency = formData.currency;
    }

    if (
      ['product', 'product_quantity'].includes(
        formData.type
      )
    ) {
      if (formData.products.length) {
        _data.products = formData.products.map(
          (product) => product.id
        );
      }
    }

    if (formData.promo_code)
      _data.promo_code = formData.promo_code;
    if (formData.type === 'product_quantity')
      _data.quantity_gte = formData.quantity_gte;
    if (formData.end_date)
      _data.end_date = moment(formData.end_date)
        .startOf('day')
        .unix();
    if (formData.max_redemptions)
      _data.max_redemptions = parseInt(
        formData.max_redemptions,
        10
      );

    mutateStoreDiscount(_data, {
      onSuccess: () => {
        dispatch(resetFormSchemaDiscount());
        dispatch(setRefetchDiscount(Date.now()));
        toast.success('Discount created successfully');
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      size='sm'
      overflowVisible>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
            {selected_discount?.id
              ? 'Edit'
              : 'Create a new'}{' '}
            discount
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name='body'>
        <form
          id='create-discount'
          onSubmit={handleSubmit(onSubmit)}>
          <small className='text-light block mb-5'>
            Discounts can be used on invoices,
            subscriptions, orders or purchases by customers
            at payment time.
          </small>
          <Stack columns='1' gap='4'>
            <DiscountsModalCreateName
              register={register}
              error={fieldError('name')}
            />
            <DiscountsModalCreatePromoCode
              register={register}
            />
            <DiscountsModalCreateType
              setValue={setValue}
              trigger={trigger}
              type={type}
            />
            {form_schema_discount.type !== 'standard' && (
              <Stack columns='1' gap='4' className='mt-2'>
                {['product', 'product_quantity'].includes(
                  form_schema_discount.type
                ) && (
                  <DiscountsModalCreateProducts
                    control={control}
                    currency={currency}
                  />
                )}
                {form_schema_discount.type ===
                  'amount_gte' && (
                  <DiscountsModalCreateAmountGte
                    currency={currency}
                    setValue={setValue}
                    register={register}
                    error={fieldError('amount_gte')}
                  />
                )}
                {form_schema_discount.type ===
                  'product_quantity' && (
                  <DiscountsModalCreateQuantityGte
                    register={register}
                    error={fieldError('quantity_gte')}
                  />
                )}
              </Stack>
            )}
            <DiscountsModalCreateBehavior
              behavior={behavior}
              setValue={setValue}
            />
            <div>
              {behavior === 'amount_off' && (
                <DiscountsModalCreateAmountOff
                  currency={currency}
                  register={register}
                  error={fieldError('amount_off')}
                  setValue={setValue}
                />
              )}
              {behavior === 'percent_off' && (
                <DiscountsModalCreatePercentOff
                  register={register}
                  error={fieldError('percent_off')}
                />
              )}
            </div>
            <div className='mt-2'>
              <div
                className={clsx(
                  'app-invoices__modal__collapse',
                  showAdvanced &&
                    'app-invoices__modal__collapse--open'
                )}>
                <div
                  onClick={() =>
                    setShowAdvanced(!showAdvanced)
                  }
                  className='app-invoices__modal__collapse__menu app-modal-fullscreen__title mb-3'>
                  <img
                    className='app-invoices__modal__collapse__menu__icon'
                    src={ArrowIcon}
                    alt=''
                  />
                  <span>Advanced</span>
                </div>
                <div className='app-invoices__modal__collapse__item'>
                  <Stack columns='1' gap='4'>
                    <DiscountsModalCreateMaxRedemptions
                      register={register}
                    />
                    <DiscountsModalCreateExpires
                      end_date={end_date}
                      setValue={setValue}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          </Stack>
        </form>
        <ReactTooltip
          id='discount-modal-create'
          multiline
          place='bottom'
          effect='solid'
          type='light'
          padding='10px'
          textColor='#1A1E23'
          backgroundColor='#ffffff' //'#D3D8E0'
          className='api-key__tooltip'
        />
      </Modal.Slot>
      <Modal.Slot name='footer'>
        <div className='flex justify-end'>
          <Button form='create-discount' type='submit'>
            {selected_discount?.id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default DiscountsModalCreate;
