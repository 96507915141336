import "./Discounts.scss";
import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../../components/Common/Form/DashboardButton/DashboardButton";
import {ReactComponent as ExportIcon} from "../../../../assets/images/icons/export.svg";
import DiscountsTable from "../../../../components/Pages/Dashboard/Products/Discounts/DiscountsTable/DiscountsTable";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import DiscountsModalDetails
  from "../../../../components/Pages/Dashboard/Products/Discounts/DiscountsModals/DiscountsModalDetails/DiscountsModalDetails";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedDiscount, setShowDiscountForm} from "../../../../store/discount/discountSlice";
import clsx from "clsx";
import useTableFilters from "../../../../hooks/useTableFilters";
import DropdownFilters from "../../../../components/Common/DropdownFilters/DropdownFilters";
import {toast} from "react-toastify";
import {useFetchDiscount} from "../../../../api/product/discount";
import {useNavigate, useParams} from "react-router-dom";
import DiscountsModalCreate
  from "../../../../components/Pages/Dashboard/Products/Discounts/DiscountsModals/DiscountsModalCreate/DiscountsModalCreate";

const TABLE_FILTERS = [
  { label: "Active", column: "active", value: true },
  { label: "Expired", column: "expired", value: true },
  { label: "Archived", column: "active", value: false },
]

function Discounts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

  const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const {
    selected_discount,
    refetch_discount,
    show_discount_form
  } = useSelector((state) => state.discount);
  const [filters, setFilters] = useState({});
  const [refreshDiscount, setRefreshDiscount] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { id } = useParams();
  const {
    activeFilter,
    setFilter,
    isFilterActive
  } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0]
  });

  useFetchDiscount({
    onSuccess: (data) => {
      dispatch(setSelectedDiscount(data));
      setRefreshDiscount(false);
    },
    onError: (error) => {
      setRefreshDiscount(false);
      navigate(DASHBOARD_PATH + "/discounts");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshDiscount,
    params: {
      expand: ["products"]
    }
  })

  const handleCloseModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedDiscount(null));
    navigate(DASHBOARD_PATH + "/discounts");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!id) {
      handleCloseModal();
      return;
    }

    setRefreshDiscount(true);
  }, [refetch_discount, id, handleCloseModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDiscount(null));
    }
  }, [dispatch]);

  useEffect(() => {
    setShowDetailsModal(!!selected_discount);
  }, [selected_discount]);

  useEffect(() => {
    setShowCreateModal(show_discount_form);
  }, [show_discount_form]);

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setShowDiscountForm(false));
  }

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  }

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...activeFilter
    };
  }, [filters, activeFilter]);

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Discounts</div>
              <span className="app-dashboard__card__header__filters">
                {TABLE_FILTERS.map((filter, index) =>
                  <span
                    key={index}
                    onClick={() => setFilter(filter)}
                    className={clsx(
                      "app-dashboard__card__header__filters__item",
                      isFilterActive(filter) && "app-dashboard__card__header__filters__item--active"
                    )}
                  >
                    {filter.label}
                  </span>
                )}
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
              <DropdownFilters
                filters={["currency", "discount_type", "name", "discount_terms"]}
                onChange={handleFiltersChange}
              />
              <DashboardButton onClick={() => setShowCreateModal(true)} color="primary" outline icon="+">
                <span className="text-dark">New Discount</span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
            </div>
          </div>
          <DiscountsTable
            filters={getFilters}
          />
        </div>
        {showDetailsModal &&
        <DiscountsModalDetails show={showDetailsModal} onClose={handleCloseModal} />
        }
        {showCreateModal &&
        <DiscountsModalCreate show={showCreateModal} onClose={handleCloseCreateModal} />
        }
      </div>
    </div>
  )
}

export default Discounts;
