import { ReactComponent as PDFIcon } from "../../../../../../../../assets/images/icons/pdf.svg";
import Dropdown from "../../../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../../../assets/images/icons/dashboard/more.svg";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  setDraftInvoice,
  setInvoiceLineItems,
  setSelectedInvoiceCustomer,
} from "../../../../../../../../store/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";

function CustomerDetailsInvoicesTableActions({ invoice }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected_customer } = useSelector((state) => state.customer);

  const handleViewClick = useCallback(() => {
    navigate(`/dashboard/invoices/${invoice?.id}`);
  }, [invoice, navigate]);

  const handleEditClick = useCallback(() => {
    dispatch(setInvoiceLineItems([]));
    dispatch(setSelectedInvoiceCustomer(selected_customer));
    dispatch(setDraftInvoice(invoice));
    navigate(`/dashboard/invoices/${invoice?.id}`);
  }, [invoice, selected_customer, dispatch, navigate]);

  const dropDownItems = useMemo(() => {
    if (invoice.status === "draft") {
      return [
        { label: "View", onClick: handleViewClick },
        { label: "Edit", onClick: handleEditClick },
        { label: "Delete", className: "text-danger font-semibold" },
      ];
    }

    return [
      { label: "Download", icon: <PDFIcon /> },
      { label: "View", onClick: handleViewClick },
    ];
  }, [invoice, handleViewClick, handleEditClick]);

  return (
    <Dropdown items={dropDownItems}>
      <Dropdown.Slot name="toggle">
        <MoreIcon className="cursor-pointer" />
      </Dropdown.Slot>
    </Dropdown>
  );
}

export default CustomerDetailsInvoicesTableActions;
