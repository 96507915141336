import React, { useEffect, useState } from "react";
import Input from "./Input/Input";
import Select from "./Select/Select";
import Modal from "../Modal/Modal";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/modal-close.svg";
import Button from "./Button/Button";
import SelectShippingRates from "components/Dashboard/SelectShippingRates/SelectShippingRates";
export const CustomInputsInput = ({
  onChnage,
}) => {
  const [addCustomInput, setAddCustomInput] = useState(false);
  const [addOptionsModal, setAddOptionsModal] = useState(false);
  const [customInputs, setCustomInput] = useState([{
    type: "text",
    label: "",
    optional: false,
    options: [],
    limit: {
      enabeld: false,
      type: "Less than", // less, more, between, equal
      min: 1,
      max: 255,
    }
  }]);
  const options = [
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Numeric",
      value: "numeric",
    },
    {
      label: "Select",
      value: "select",
    }
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [typedOption, setTypedOption] = useState("")

  const handleAddCustomInput = () => {
    const newInputs = [...customInputs];
    if (newInputs.length === 3) return;
    newInputs.push({
      type: "text",
      label: "",
      optional: false,
      options: [],
      limit: {
        enabeld: false,
        type: "Less than", // less, more, between, equal
        min: 1,
        max: 255,
      }
    });
    setCustomInput(newInputs);
  }

  useEffect(() => {
    console.log("selectedOptions", selectedOptions);
  }, [selectedOptions]);

  return (
    <div className="">
      <div className="flex gap-2 mb-3">
        <input className="cursor-pointer" type="checkbox" checked={addCustomInput} onChange={() => setAddCustomInput(!addCustomInput)} />
        <label>Add custom fields</label>
      </div>
      {addCustomInput && (
        <div className="flex flex-col ml-3 gap-8">
          {customInputs?.map((input, index) => (
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 w-full mb-2">
                <div className="w-1/4">
                  <Select
                    options={options}
                    value={input.type}
                    onSelect={(e) => {
                      const newInputs = [...customInputs];
                      newInputs[index].type = e.value;
                      setCustomInput(newInputs);
                    }}
                  />
                </div>
                <Input
                  value={input.label}
                  className="w-1/3"
                  placeholder="Label name"
                  onChange={(e) => {
                    const newInputs = [...customInputs];
                    newInputs[index].label = e.target.value;
                    setCustomInput(newInputs);
                  }}
                />
              </div>
              {input.type === "select" ? (
                <div className="border border-gray-200 rounded p-2 w-1/2 bg-slate-200 bg-opacity-30">
                  <div className="">
                    {input.options?.length > 3 ? `${input.options[0], input.options[1]}... +${input.options.length - 2}` : input.options?.length > 1 ?
                      input.options.map((option, index) => (
                        <span className="mr-0.5">{option},</span>
                      )) : "no options added"
                    }
                  </div>
                  <button className="text-blue-500 hover:underline cursor-pointer" onClick={() => setAddOptionsModal(true)}>Add options</button>
                </div>
              ) : (
                <>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <input className="cursor-pointer" type="checkbox" checked={input.limit.enabeld} onChange={(e) => {
                        const value = e.target.checked;
                        let newInputs = [...customInputs];
                        newInputs[index].limit.enabeld = value;
                        setCustomInput(newInputs);
                      }} />
                      <label>Set limits</label>
                    </div>
                    {input.limit.enabeld && (
                      <div className="flex gap-2 items-center">
                        <div className="w-1/5">
                          <Select
                            options={[
                              {
                                label: "Less than",
                                value: "Less than",
                              },
                              {
                                label: "More than",
                                value: "More than",
                              },
                              {
                                label: "Between",
                                value: "Between",
                              },
                              {
                                label: "Exactly equal to",
                                value: "Exactly equal to",
                              },
                            ]}
                            value={input.limit.type}
                            onSelect={(e) => {
                              let newInputs = [...customInputs];
                              newInputs[index].limit.type = e.value;
                              setCustomInput(newInputs);
                            }}
                          />
                        </div>
                        <Input type="number" className="w-16" value={input.limit.type === "Less than" || input.limit.type === "Exactly equal to" ? input.limit.max : input.limit.min}
                          onChange={(e) => {
                            let newInputs = [...customInputs];
                            if (input.limit.type === "Less than" || input.limit.type === "Exactly equal to")
                              newInputs[index].limit.max = e.target.value;
                            else
                              newInputs[index].limit.min = e.target.value;
                            setCustomInput(newInputs);
                          }} />
                        {input.limit.type === "Between" && (
                          <>
                            <span>and</span>
                            <Input type="number"
                              value={input.limit.max}
                              className="w-16"
                              onChange={(e) => {
                                const newInputs = [...customInputs];
                                newInputs[index].limit.max = e.target.value;
                                setCustomInput(newInputs);
                              }} />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <input className="cursor-pointer" type="checkbox" checked={input.optional} onChange={(e) => {
                      const value = e.target.checked;
                      const newInputs = [...customInputs];
                      newInputs[index].optional = value;
                      setCustomInput(newInputs);
                    }} />
                    <label>Mark as optional</label>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
      {addCustomInput && customInputs?.length < 3 && (
        <button className="text-blue-500 hover:underline cursor-pointer mt-3" onClick={handleAddCustomInput}
        >Add another field</button>
      )}
      {addOptionsModal && (
        <Modal
          show={addOptionsModal}
          size="sm"
          onClose={() => setAddOptionsModal(false)}
        >
          <Modal.Slot name="header">
            <div>
              <div className="app-invoice__modal__title">
                Add options
              </div>
            </div>
          </Modal.Slot>
          <Modal.Slot name="body">
            <div className="flex flex-col gap-1">
              {selectedOptions?.map((option, index) => (
                <div className="flex justify-between px-2 py-1 border border-gray-200 rounded items-center bg-gray-100">
                  <span>{option}</span>
                  <CloseIcon className="cursor-pointer" onClick={() => {
                    const newOptions = [...selectedOptions];
                    newOptions.splice(index, 1);
                    setSelectedOptions(newOptions);
                  }} />
                </div>
              ))}
            </div>
            <div className="mt-2">
              <Input placeholder="Option name"
                value={typedOption}
                onChange={(e) => setTypedOption(e.target.value)}
                className="w-full"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const newOptions = [...selectedOptions];
                    newOptions.push(typedOption);
                    setSelectedOptions(newOptions);
                    setTypedOption("");
                  }
                }}
              />
            </div>
          </Modal.Slot>
          <Modal.Slot name="footer">
            <div className="flex justify-end">
              <div className='flex gap-3 items-center mt-3 self-end'>
                <Button
                  color='light'
                  className='w-fit mt-2'
                  onClick={() => setAddOptionsModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className='w-fit mt-2'
                  onClick={() => {
                    const newInputs = [...customInputs];
                    newInputs[newInputs.length - 1].options = selectedOptions;
                    setCustomInput(newInputs);
                    setAddOptionsModal(false);
                    setSelectedOptions([]);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Slot>
        </Modal>
      )}
    </div>
  );
};

export const CustomOptions = () => {
  const [collectPhoneNumber, setCollectPhoneNumber] = useState(false);
  const [collectShippingAddress, setCollectShippingAddress] = useState(false);
  const [addressType, setAddressType] = useState("billing");
  return (
    <div className="flex flex-col gap-2 mt-3">
      <div className="flex gap-2">
        <input className="cursor-pointer"
          type="checkbox"
          checked={collectPhoneNumber}
          onChange={(e) => {
            const value = e.target.checked;
            setCollectPhoneNumber(value);
          }}
        />
        <label>
          Collect customer phone number
        </label>
      </div>
      <div className="flex gap-2">
        <input className="cursor-pointer"
          type="checkbox"
          checked={collectShippingAddress}
          onChange={(e) => {
            const value = e.target.checked;
            setCollectShippingAddress(value);
          }}
        />
        <label>
          Collect Shipping Address
        </label>
      </div>
      {collectShippingAddress && (
        <div className="ml-3">
          <div className="flex gap-2">
            <input className="cursor-pointer"
              type="radio"
              checked={collectShippingAddress}
              name="address"
              onChange={(e) => {
                const value = e.target.checked;
                if (value) setAddressType("billing");
              }}
            />
            <label>
              Billing Address only
            </label>
          </div>
          <div className="flex gap-2">
            <input className="cursor-pointer"
              type="radio"
              checked={collectShippingAddress}
              name="address"
              onChange={(e) => {
                console.log("e", e.target.checked);
                const value = e.target.checked;
                console.log("value", value);
                if (value) setAddressType("shipping");
              }}
            />
            <label>
              Shipping and Billing Address
            </label>
          </div>
          <div className="text-blue-500 hover:underline cursor-pointer mt-2">
            Edit countries
          </div>
          <div className="mt-3 w-64">
            <SelectShippingRates />
          </div>
        </div>
      )}
    </div>
  )
}