import Button from 'components/Common/Form/Button/Button'
import Input from 'components/Common/Form/Input/Input'
import Label from 'components/Common/Form/Label/Label'
import Select from 'components/Common/Form/Select/Select'
import CopyToClipboardBoxID from 'components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID'
import React from 'react'
import CreditTotalDetails from './CreditTotalDetails'

export default function CreditNoteTotal() {
    return (
        <div className='w-1/2
    flex
    flex-col
    justify-center
    items-center
    gap-2
    '
        >
            <CreditTotalDetails />
            <div className='flex w-full items-end justify-between font-semibold'>
                <Select
                    options={[
                        {
                            label: "refunded",
                            value: "refunded",
                        },
                        {
                            label: "credited",
                            value: "credited",
                        },
                    ]}
                    value="refunded"
                    onChange={() => { }}
                    className="w-1/4"
                />
                <div className='w-1/5 h-6 border border-gray-300 rounded-lg text-sm px-2 flex justify-between '>
                    <div>
                        $
                    </div>
                    <div>
                        0.00
                        <span className='font-normal'>

                            {" "}USD
                        </span>
                    </div>
                </div>

            </div>
            <div className='w-full flex items-center gap-4'>
                <div className='w-1/2 flex flex-col'>
                    <Label
                        title="Currency"
                    />
                    <Select
                        className='w-full'
                        options={[
                            {
                                label: "USD",
                                value: "USD",
                            },
                            {
                                label: "INR",
                                value: "INR",
                            },
                        ]}
                        value="USD"
                        path="assets/images/icons/cryptos" />
                </div>
                <div className='flex-1 flex flex-col'>
                    <Label
                        title="Network"
                    />
                    <Select
                        className='w-full'
                        options={[
                            {
                                label: "USD",
                                value: "USD",
                            },
                            {
                                label: "INR",
                                value: "INR",
                            },
                        ]}
                        value="USD"
                        path="assets/images/icons/cryptos" />
                </div>
            </div>
            <div className='w-full flex flex-col'>
                <Label
                    title="Address"
                />
                <CopyToClipboardBoxID
                    text="Address ID"
                />
            </div>
            <div className='w-full flex flex-col' >
                <Label
                    title="memo"
                />
                <Input
                    className='w-full'
                    placeholder="memo"
                />
            </div>
            <div className='w-full gap-4 flex justify-end' >
                <Button
                    className='w-1/4 whitespace-nowrap'
                    color='light'
                >
                    Cancel
                </Button>
                <Button
                    className='w-1/4 whitespace-nowrap'
                >
                    Issue a credit note
                </Button>
            </div>
        </div>
    )
}


