import Label from "../../../../../../../Common/Form/Label/Label";
import InvoiceCryptoSelect from "../../../InvoiceCryptoSelect/InvoiceCryptoSelect";
import crypto_currencies from "../../../../../../../../utils/crypto_currencies";
import {useDispatch, useSelector} from "react-redux";
import {setDraftInvoice, setInvoiceAcceptedCryptos} from "../../../../../../../../store/invoice/invoiceSlice";
import {useEffect, useRef, useState} from "react";
import useOutsideClick from "../../../../../../../../hooks/useOutsideClick";
import {useUpdateInvoice} from "../../../../../../../../api/customer/invoice";

function InvoiceAcceptedCryptos() {
  const acceptedCryptosRef = useRef(null);
  const dispatch = useDispatch();
  const [lastAcceptedCryptos, setLastAcceptedCryptos] = useState([]);
  const { draft_invoice, invoice_accepted_cryptos } = useSelector((state) => state.invoice);
  useOutsideClick(acceptedCryptosRef, handleAcceptedCryptosBlur);

  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  useEffect(() => {
    if (!lastAcceptedCryptos.length) setLastAcceptedCryptos(invoice_accepted_cryptos);
  }, [lastAcceptedCryptos, invoice_accepted_cryptos]);

  const handleAcceptedCryptoSelect = (value) => {
    if (invoice_accepted_cryptos.includes(value.label)) {
      const _data = invoice_accepted_cryptos.filter(item => item !== value.label);

      dispatch(setInvoiceAcceptedCryptos(_data));
      return;
    }

    const _data = [
      ...invoice_accepted_cryptos,
      value.label
    ];

    dispatch(setInvoiceAcceptedCryptos(_data));
  }

  const handleAcceptedCryptoClear = () => {
    dispatch(setInvoiceAcceptedCryptos([]));
  }

  function handleAcceptedCryptosBlur() {
    if (!draft_invoice) return;

    if (JSON.stringify(invoice_accepted_cryptos) === JSON.stringify(lastAcceptedCryptos)) {
      return;
    }

    setLastAcceptedCryptos(invoice_accepted_cryptos);

    mutateUpdateInvoice({ crypto_payment_currencies: invoice_accepted_cryptos }, {
      onSuccess: (data) => dispatch(setDraftInvoice(data.data))
    })
  }

  return (
    <div ref={acceptedCryptosRef}>
      <Label title="Accepted Crypto Currencies" />
      <InvoiceCryptoSelect
        value={invoice_accepted_cryptos}
        options={crypto_currencies}
        onSelect={handleAcceptedCryptoSelect}
        onClear={handleAcceptedCryptoClear}
        className="w-1/2"
      />
    </div>
  )
}

export default InvoiceAcceptedCryptos;