import Label from '../../../../../../Common/Form/Label/Label';
import React from 'react';
import InfoIcon from '../../../../../../../assets/images/icons/info.svg';
import clsx from 'clsx';

function ProductsModalCreateBillingType({
  index,
  price,
  update,
  type,
}) {
  const handleBillingType = (type) => {
    update(index, {
      ...price,
      type,
    });
  };

  return (
    <div>
      <Label
        title='Billing Type'
        tooltip={`
        Choose <code style="background:#d3d8e0;font:normal;border-radius:3px">&nbsp;Recurring&nbsp;</code> for subscriptions or
        <code style="background:#d3d8e0;font:normal;border-radius:3px">&nbsp;One Time&nbsp;</code> for one-off purchases 
          `}
        tooltipHtml={true}
        icon={InfoIcon}
      />
      <div>
        <div className='flex gap-3' style={{width:"60%"}}>
          <div
            onClick={() => handleBillingType('recurring')}
            className={clsx(
              'cursor-pointer duration-300 border rounded-lg w-full shadow text-center text-light px-2 py-1',
              type === 'recurring' &&
                'border-tertiary text-tertiary_darken'
            )}>
            <span>Recurring</span>
          </div>
          <div
            onClick={() => handleBillingType('one_time')}
            className={clsx(
              'cursor-pointer duration-300 border rounded-lg w-full shadow text-center text-light px-2 py-1',
              type === 'one_time' &&
                'border-tertiary text-tertiary_darken'
            )}>
            <span>One time</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsModalCreateBillingType;
