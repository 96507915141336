import './ShippingRates.scss';
import DashboardNavbar from '../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar';
import DashboardButton from '../../../../components/Common/Form/DashboardButton/DashboardButton';
import { ReactComponent as ExportIcon } from '../../../../assets/images/icons/export.svg';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import useTableFilters from '../../../../hooks/useTableFilters';
import DropdownFilters from '../../../../components/Common/DropdownFilters/DropdownFilters';
import ShippingRatesTable from '../../../../components/Pages/Dashboard/Products/ShippingRates/ShippingRatesTable/ShippingRatesTable';
import {
  setSelectedShippingRate,
  setShowShippingRateForm,
} from '../../../../store/shipping_rate/shippingRateSlice';
import { useFetchShippingRate } from '../../../../api/product/shipping_rates';
import ShippingRatesModalCreate from '../../../../components/Pages/Dashboard/Products/ShippingRates/ShippingRatesModals/ShippingRatesModalCreate/ShippingRatesModalCreate';
import ShippingRatesModalDetails from '../../../../components/Pages/Dashboard/Products/ShippingRates/ShippingRatesModals/ShippingRatesModalDetails/ShippingRatesModalDetails';

const TABLE_FILTERS = [
  { label: 'Active', column: 'active', value: true },
  { label: 'Archived', column: 'active', value: false },
];

function ShippingRates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

  const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const {
    selected_shipping_rate,
    refetch_shipping_rate,
    show_shipping_rate_form,
  } = useSelector((state) => state.shipping_rate);
  const [filters, setFilters] = useState({});
  const [refreshShippingRate, setRefreshShippingRate] =
    useState(false);
  const [showDetailsModal, setShowDetailsModal] =
    useState(false);
  const [showCreateModal, setShowCreateModal] =
    useState(false);
  const { id } = useParams();
  const { activeFilter, setFilter, isFilterActive } =
    useTableFilters({
      filters: TABLE_FILTERS,
      defaultFilter: TABLE_FILTERS[0],
    });

  useFetchShippingRate({
    onSuccess: (data) => {
      dispatch(setSelectedShippingRate(data));
      setRefreshShippingRate(false);
    },
    onError: (error) => {
      setRefreshShippingRate(false);
      navigate(DASHBOARD_PATH + '/shipping_rates');
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshShippingRate,
  });

  const handleCloseDetailsModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedShippingRate(null));
    navigate(DASHBOARD_PATH + '/shipping_rates');
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_shipping_rate);
  }, [selected_shipping_rate]);

  useEffect(() => {
    setShowCreateModal(show_shipping_rate_form);
  }, [show_shipping_rate_form]);

  useEffect(() => {
    if (!id) {
      handleCloseDetailsModal();
      return;
    }

    setRefreshShippingRate(true);
  }, [refetch_shipping_rate, id, handleCloseDetailsModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedShippingRate(null));
    };
  }, [dispatch]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setShowShippingRateForm(false));
  };

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...activeFilter,
    };
  }, [filters, activeFilter]);

  return (
    <div className='app-dashboard-container'>
      <DashboardNavbar />
      <div className='app-dashboard__content'>
        <div className='app-dashboard__card'>
          <div className='app-dashboard__card__header'>
            <div className='flex'>
              <div className='app-dashboard__card__title'>
                Shipping Rates
              </div>
              <span className='app-dashboard__card__header__filters'>
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => setFilter(filter)}
                    className={clsx(
                      'app-dashboard__card__header__filters__item',
                      isFilterActive(filter) &&
                        'app-dashboard__card__header__filters__item--active'
                    )}>
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className='app-dashboard__card__buttons'>
              <DropdownFilters
                filters={[
                  'currency',
                  'shipping_description',
                ]}
                onChange={handleFiltersChange}
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color='primary'
                outline
                icon='+'>
                <span className='text-dark'>
                  New Shipping Rate
                </span>
              </DashboardButton>
              <DashboardButton
                color='tertiary'
                outline
                icon={<ExportIcon />}>
                <span className='text-dark'>Export</span>
              </DashboardButton>
            </div>
          </div>
          <ShippingRatesTable filters={getFilters} />
        </div>
        {showDetailsModal && (
          <ShippingRatesModalDetails
            show={showDetailsModal}
            onClose={handleCloseDetailsModal}
          />
        )}
        {showCreateModal && (
          <ShippingRatesModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
      </div>
    </div>
  );
}

export default ShippingRates;
