import React, { useEffect } from 'react';
// import InfoIcon from '../../../../../../../assets/images/icons/info.svg';
import TieredPricingTable from './TieredPricingTable';
import ProductPricingDropDown from './ProductPricingDropDown';
import TaxBehaviour from './PriceFields/TaxBehaviour';
import BillingType from './PriceFields/BillingType';
import BillingPeriod from './PriceFields/BillingPeriod';
import MeteredAggregation from './PriceFields/MeteredAggregation';
import ProductsModalCreateBillingType from '../../ProductsModalCreate/AddPrice/ProductsModalCreateBillingType';
import ProductsModalCreateBillingPeriod from '../../ProductsModalCreate/AddPrice/ProductsModalCreateBillingPeriod';
import { useSelector } from 'react-redux';
import Checkbox from 'components/Common/Form/Checkbox/Checkbox';
import { Desription } from './PackagePricing';
// import { PRODUCT_MODEL_VALUES } from './ProductAddPriceModal';

export const TIER_MODES = {
  volume: {
    id: 'volume',
    value: 'volume',
    toolTipDisplayName: `Volume pricing is when you charge the same price for each<br />unit based on the total number of units sold.<br /> 
    For example, you might charge €10.00 per unit for 50 units,<br /> 
    and €7.00 per unit for 100 units.`,
    optionsDisplayName: 'Volume Tiering',
  },
  graduated: {
    id: 'graduated',
    value: 'graduated',
    toolTipDisplayName: `Graduated pricing is when you use pricing tiers 
    that may result in a different price for some units in an order. For example, 
    you might charge $10.00 per unit for the first 100 units and then $8.00 per
     unit for the next 50.`,
    optionsDisplayName: 'Graduated Tiering',
  },
};

const TieredPricing = ({
  formMethods,
  tierFields,
  tierAppend,
  tierRemove,
  tierUpdate,
  getValues,
  setValue,
  tiers,
  tiers_mode,
  fieldError,
  interval,
  billing_period,
  currency,
  billing_scheme,
  tax_behaviour,
  type,
  register,
  metered,
  aggregate_usage,
}) => {
  const handleCurrencyChange = (e) => {
    setValue('currency', e);
  };
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  
  const handleBillingType = (type) => {
    if (selected_product_price?.id) return;
    
    setValue('type', type);
  };

  return (
    <>
      <div>
        <ProductPricingDropDown
        price={true}
          optionsTitle={'Tier Mode'}
          toolTipData={
            TIER_MODES[tiers_mode].toolTipDisplayName
          }
          options={Object.values(TIER_MODES)}
          selectedOption={TIER_MODES[tiers_mode]}
          onSelectOption={(option) => {
            if (option.value === 'graduated') {
              tierUpdate(0, {
                ...tiers[0],
                first_unit: 0
              });
            } else {
              tierUpdate(0, {
                ...tiers[0],
                first_unit: 1
              });
            }

            setValue('tiers_mode', option.value);
          }}
        />
      </div>
      <div>
        <TieredPricingTable
          tiers_mode={tiers_mode}
          formMethods={formMethods}
          fieldName={'tiers'}
          billing_scheme={billing_scheme}
          tiers={tiers}
          setvalue={setValue}
          tierAppend={tierAppend}
          tierFields={tierFields}
          tierRemove={tierRemove}
          tierUpdate={tierUpdate}
          currency={currency}
          setCurrency={handleCurrencyChange}
          disabled={selected_product_price?.id ? true : false}
        />
        
      </div>
      <div>
        <TaxBehaviour
          onSelectOption={(option) => {
            setValue('tax_behaviour', option.value);
          }}
          tax_behaviour={tax_behaviour}
          disabled={selected_product_price?.id && selected_product_price.tax_behaviour !== 'unspecified'}
        />
      </div>
      <div>
        <BillingType
          setValue={setValue}
          disabled={selected_product_price?.id}
          type={type}
        />
      </div>
      {type === 'recurring' && (
        <>
          <div>
            <BillingPeriod
              {...register('recurring.interval_count')}
              interval={interval}
              block
              onIntervalChange={(e) =>
                setValue('recurring.interval', e.value)
              }
              onBillingPeriodChange={(e) => {
                setValue('billing_period', e.value);
              }}
              billing_period={billing_period}
              placeholder='0'
              style={{ paddingLeft: '60px' }}
              error={fieldError('recurring.interval_count')}
              disabled={selected_product_price?.id}
            />
          </div>
          <div className='flex items-center gap-3'>
            <Checkbox
              {...register('metered')}
              color='primary'
              className='mt-1'
              disabled={selected_product_price?.id}
            />
            <div className='text-light text-sm'>
              Metered Usage
            </div>
          </div>
          {metered && (
            <div>
              <MeteredAggregation
                onAggregationChange={(e) =>
                  setValue(
                    'recurring.aggregate_usage',
                    e.value
                  )
                }
                error={fieldError('aggregate_usage')}
                disabled={selected_product_price?.id}
                aggregate_usage={aggregate_usage}
              />
            </div>
          )}
          {/* <div className='horizontal-divider' /> */}
        </>
      )}
      {/* <Desription register={register}/> */}
    </>
  );
};

export default TieredPricing;


export const TieredPricingCreate = ({
  formMethods,
  tierFields,
  tierAppend,
  tierRemove,
  tierUpdate,
  getValues,
  setValue,
  prices,
  index,
  tiers,
  tiers_mode,
  fieldError,
  currency,
  billing_scheme,
  type,
  register,
  metered,
  otherErrors,
  update=()=>{},
}) => {

  console.log(`Tiers id ${index}`, tiers);
  console.log('Metered', metered);
  const handleCurrencyChange = (e) => {
    update(index, {
      ...prices[index],
      currency: e
    });
  };
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  const handleBillingType = (type) => {
    if (selected_product_price?.id) return;
    
    setValue('type', type);
  };

  return (
    <>
      <div>
        <ProductPricingDropDown
          price={true}
          optionsTitle={'Tier Mode'}
          toolTipData={
            TIER_MODES[tiers_mode]?.toolTipDisplayName
          }
          options={Object.values(TIER_MODES)}
          selectedOption={TIER_MODES[tiers_mode]}
          onSelectOption={(option) => {
            if (option.value === 'graduated') {
              tierUpdate(0, {
                ...tiers[0],
                first_unit: 0
              });
            } else {
              tierUpdate(0, {
                ...tiers[0],
                first_unit: 1
              });
            }

            update(index, {
              ...prices[index],
              tiers_mode: option.value
            });
          }}
        />
      </div>
      <div>
        <TieredPricingTable
          tiers_mode={tiers_mode}
          formMethods={formMethods}
          fieldName={'tiers'}
          billing_scheme={billing_scheme}
          tiers={tiers}
          setvalue={setValue}
          tierAppend={tierAppend}
          tierFields={tierFields}
          tierRemove={tierRemove}
          tierUpdate={tierUpdate}
          currency={currency}
          setCurrency={handleCurrencyChange}
        />
        
      </div>
      <div>
        <TaxBehaviour
          onSelectOption={(option) => {
            update(index, {
              ...prices[index],
              tax_behaviour: option.value,
            });
          }}
          tax_behaviour={prices[index]?.tax_behaviour ?? 'unspecified'}
          disabled={selected_product_price?.id && selected_product_price.tax_behaviour !== 'unspecified'}
        />
      </div>
      <ProductsModalCreateBillingType 
        index={index} 
        price={prices[index]} 
        update={update}
        type={type} 
      />
      {type === 'recurring' && (
        <>
          <ProductsModalCreateBillingPeriod
            {...register(`prices.${index}.recurring.interval_count`)}
            interval={prices[index].recurring.interval}
            onIntervalChange={(e) =>
              update(index, {
                ...prices[index],
                recurring: {
                  ...[prices[index].recurring],
                  interval: e.value
                }
              })
            }
            onBillingPeriodChange={(e) => update(index, {
                ...prices[index],
                billing_period: e.value,
              })
            }
            billing_period={prices[index].billing_period}
            placeholder='0'
            style={{ paddingLeft: '60px' }}
            error={otherErrors.prices?.[index]?.recurring?.interval_count?.message}
            prices={prices}
            index={index}
            //register={register}
          />
          <div className='flex items-center gap-3'>
            <Checkbox
              onChange={(e) => update(index, {
                ...prices[index],
                metered: e.target.checked,
              })}
              color='primary'
              className='mt-1'
              disabled={selected_product_price?.id}
            />
            <div className='text-light text-sm'>
              Metered Usage
            </div>
          </div>
          {metered && (
            <div>
              <MeteredAggregation
                onAggregationChange={(e) =>
                  update(index, {
                    ...prices[index],
                    recurring: {
                      ...[prices[index].recurring],
                      aggregate_usage: e.value
                    }
                  })
                }
                // error={fieldError('aggregate_usage')}
                disabled={selected_product_price?.id}
                aggregate_usage={prices[index].recurring.aggregate_usage}
              />
            </div>
          )}
          {/* <div className='horizontal-divider' /> */}
        </>
      )}
    </>
  );
};