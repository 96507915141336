import Input from "../../../../../../../Common/Form/Input/Input";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/images/icons/dashboard/delete.svg";
import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../../../../../../hooks/useOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateInvoice } from "../../../../../../../../api/customer/invoice";
import {
  setDraftInvoice,
  setInvoiceCustomFields,
} from "../../../../../../../../store/invoice/invoiceSlice";
import InfoIcon from "../../../../../../../../assets/images/icons/info.svg";

function InvoiceCustomFields() {
  const dispatch = useDispatch();
  const customFieldsRef = useRef(null);
  const [lastCustomFields, setLastCustomFields] = useState([]);
  const { draft_invoice, invoice_custom_fields } = useSelector(
    (state) => state.invoice
  );
  useOutsideClick(customFieldsRef, handleCustomFieldsBlur);

  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  useEffect(() => {
    if (!lastCustomFields.length) setLastCustomFields(invoice_custom_fields);
  }, [lastCustomFields, invoice_custom_fields]);

  const handleAddCustomFieldClick = () => {
    const _data = [...invoice_custom_fields, { "": "" }];

    dispatch(setInvoiceCustomFields(_data));
  };

  const handleCustomFieldKeyChange = (value, index) => {
    const _data = invoice_custom_fields.map((meta, i) =>
      i === index ? { [value]: meta[Object.keys(meta)[0]] } : meta
    );

    dispatch(setInvoiceCustomFields(_data));
  };

  const handleCustomFieldValueChange = (value, index) => {
    const _data = invoice_custom_fields.map((meta, i) =>
      i === index ? { [Object.keys(meta)[0]]: value } : meta
    );

    dispatch(setInvoiceCustomFields(_data));
  };

  const handleCustomFieldDeleteClick = (index) => {
    if (!Object.keys(invoice_custom_fields[index])[0]) {
      dispatch(
        setInvoiceCustomFields(
          invoice_custom_fields.filter((meta, i) => i !== index)
        )
      );
      return;
    }

    dispatch(
      setInvoiceCustomFields(
        invoice_custom_fields.map((meta, i) => {
          if (i === index) {
            return { [Object.keys(meta)[0]]: "", deleted: true };
          }
          return meta;
        })
      )
    );
  };

  function handleCustomFieldsBlur() {
    if (!draft_invoice) return;

    if (
      JSON.stringify(invoice_custom_fields) === JSON.stringify(lastCustomFields)
    ) {
      return;
    }

    setLastCustomFields(invoice_custom_fields);

    // Clean up custom fields form empty keys
    const _filteredCustomFields = invoice_custom_fields.filter(
      (data) => Object.keys(data)[0] !== ""
    );

    // Force empty value for deleted keys
    const _formattedKeys = _filteredCustomFields.map((meta) => {
      const _key = Object.keys(meta)[0];
      return {
        [_key]: meta.deleted ? "" : meta[_key],
      };
    });

    // Flatten the array into single object
    const _customFields = Object.assign({}, ..._formattedKeys);

    mutateUpdateInvoice(
      { custom_fields: _customFields },
      {
        onSuccess: (data) => dispatch(setDraftInvoice(data.data)),
      }
    );
  }

  return (
    <div ref={customFieldsRef}>
      <div className="app-modal-fullscreen__small-title mb-3">
        <div className="flex items-center gap-3">
          <span>Custom invoice fields</span>
          <div>
            <img
              data-tip="Custom invoice fields let you include extra information, such as a purchase order number, in the header of an invoice PDF."
              data-for="invoice-modal-create"
              src={InfoIcon}
              alt="Info"
            />
          </div>
        </div>
      </div>
      {invoice_custom_fields.map(
        (value, index) =>
          !value.deleted && (
            <div
              key={`field-${index}`}
              className="grid grid-flow-col items-center gap-3 mb-3"
            >
              <Input
                placeholder="Field Name"
                value={Object.keys(value)[0]}
                onChange={(e) =>
                  handleCustomFieldKeyChange(e.target.value, index)
                }
              />
              <Input
                placeholder="Field Value"
                value={value[Object.keys(value)[0]]}
                onChange={(e) =>
                  handleCustomFieldValueChange(e.target.value, index)
                }
              />
              <DeleteIcon
                onClick={() => handleCustomFieldDeleteClick(index)}
                className="cursor-pointer"
              />
            </div>
          )
      )}
      {invoice_custom_fields.length <= 3 && (
        <div
          onClick={handleAddCustomFieldClick}
          className="app-modal-fullscreen__sm-link text-primary inline-block"
        >
          Add custom field
        </div>
      )}
    </div>
  );
}

export default InvoiceCustomFields;
