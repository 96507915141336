import "./SectionInvoice.scss";
import {ReactComponent as InvoiceIcon} from "../../../../assets/images/icons/invoice.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/invoice/illustration.svg";
import {ReactComponent as SolutionIcon} from "../../../../assets/images/sections/invoice/solutions.svg";
import Stack from "../../../Common/Stack/Stack";
import {Link} from "react-router-dom";

function SectionInvoice({ scrollToContact }) {
  return (
    <div id="invoice" className="section-invoice">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <InvoiceIcon className="section-products__section__subtitle__icon" />
              Invoice
            </h3>
            <h1 className="h1 section-products__section__title">
              Powerful Invoicing for your Business.
            </h1>
            <p className="text section-products__section__text">
              Create and manage simple and complex invoices for your Customers.
              Produce detailed and itemised invoices through the Dashboard and integrate with our Powerful APIs.
            </p>
            <div className="section-invoice__buttons">
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
                  Start Now
                </Button>
              </Link>
              <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
            </div>
            <h5 className="h5 section-products__section__info section-invoice__solution-title">
              Full Invoicing solutions
            </h5>
            <div className="section-invoice__solution-title__content">
              <div className="section-invoice__solution-title__content__icon">
                <SolutionIcon />
              </div>
              <p className="text-small section-invoice__solution-title__content__text">
                Create itemised invoices for your products and reach global customers.
                With a wide range of tools, you can manage your Business invoicing in the most flexible way.
              </p>
            </div>
          </div>
          <div className="section-invoice__illustration">
            <Illustration />
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default SectionInvoice;
