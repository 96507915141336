import DashboardButton from 'components/Common/Form/DashboardButton/DashboardButton'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const avaliableLinks = [
	{
		identifier: "customer",
		path: "/dashboard/customers",
	},
	{
		identifier: "payment",
		path: "/dashboard/payments",
	},
	{
		identifier: "refund",
		path: "/dashboard/refunds",
	},
	{
		identifier: "invoice",
		path: "/dashboard/invoices",
	},
	{
		identifier: "product",
		path: "/dashboard/products",
	},
	{
		identifier: "price",
		path: "/dashboard/prices",
	},
	{
		identifier: "discount",
		path: "/dashboard/discounts",
	},
	{
		identifier: "tax_rate",
		path: "/dashboard/tax_rates",
	},
	{
		identifier: "shipping_rate",
		path: "/dashboard/shipping_rates",
	},
	{
		identifier: "transfer",
		path: "/dashboard/transfers",
	},
	{
		identifier: "swap",
		path: "/dashboard/swaps",
	},
	{
		identifier: "payout",
		path: "/dashboard/payouts",
	},
	{
		identifier: "topup",
		path: "/dashboard/topups",
	},
]

export default function JsonData({
	data = null,
}) {
	const [jsonObject, setJsonObject] = useState([])
	const [length, setLength] = useState(0)
	const [showMore, setShowMore] = useState(false)

	const getIsLink = (key, value, obj) => {
		let identifier = ""
		if (key === "id") {
			identifier = obj.object
		} else {
			identifier = key
		}
		const isLink = avaliableLinks.some((link) => identifier === link.identifier)
		return {
			isLink: isLink,
			link: isLink ? avaliableLinks.find((link) => identifier === link.identifier).path + "/" + value.slice(1, -1) : null,
		}
	}

	function jsonToLineItems(obj, level = 1) {
		let result = [];

		for (const key in obj) {
			if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
				// Object start
				result.push({ line: `${key}: {`, level: level });
				result = result.concat(jsonToLineItems(obj[key], level + 1));
				// Object end
				result.push({ line: "},", level: level });
			} else if (Array.isArray(obj[key])) {
				if (obj[key].length === 0) {
					// Empty array
					result.push({ line: `${key}: [],`, level: level });
				} else {
					// Non-empty array start
					result.push({ line: `${key}: [`, level: level });
					obj[key].forEach((item) => {
						if (typeof item === 'object') {
							result = result.concat(jsonToLineItems(item, level + 1));
						} else {
							// Array item
							result.push({ line: JSON.stringify(item), level: level + 1, valueType: !item ? "null" : typeof item });
						}
					});
					// Non-empty array end
					result.push({ line: "],", level: level });
					// Maybe i should do the same with objects??? (empty objects)
				}
			} else {
				// Key-value pair
				let lineValue = JSON.stringify(obj[key]);
				// get the type for this one only for the moment
				// handling hyperlinks
				const linkData = getIsLink(key, lineValue, obj);
				if (!linkData.isLink) {
					result.push({ line: `${key}: ${lineValue}`, level: level, valueType: obj[key] === null ? "null" : typeof obj[key] })
				} else {
					result.push({ line: `${key}: ${lineValue}`, level: level, valueType: "link", link: linkData.link })
				}
			}
		}

		return result;
	}

	const getJsonParsed = () => {
		const rawData = jsonToLineItems(data);
		const finalData = rawData.map((item, index) => {
			return (
				<div className="flex">
					<span className="opacity-75 text-light w-6 block"
						style={{ marginRight: `${item.level * 40}px` }}
					>{index + 1}</span>
					{!item.valueType ?
						<span>{item.line}</span> : item.valueType === "link" ?
							<span>
								<span>{item.line.slice(0, item.line.indexOf(":") + 1)}</span>
								<Link to={item.link} className='text-purple-600 hover:underline'>{item.line.slice(item.line.indexOf(":") + 1)}</Link>
								<span className='ml-1'>,</span>
							</span>
							:
							<span>
								<span>{item.line.slice(0, item.line.indexOf(":") + 1)}</span>
								<span className={
									`${item.valueType === "string" ? "text-primary_darken" : item.valueType === "number" ? "text-red-500" : item.valueType === "boolean" ? "text-blue-500 font-bold" : item.valueType === "object" ? "text-primary_darken" : "text-orange-500"}`
								}>
									{item.line.slice(item.line.indexOf(":") + 1)}
								</span>
								<span className='ml-1'>,</span>
							</span>
					}
				</div>
			)
		});
		setLength(finalData.length);
		if (showMore)
			setJsonObject(finalData)
		else
			setJsonObject(finalData.slice(0, 10))
	}

	useEffect(() => {
		getJsonParsed();
	}, [data, showMore])

	return (
		<div className='space-y-1.5'>
			<div className='flex justify-between'>
				<div className='app-modal-fullscreen__title'>
					EventData
				</div>
			</div>
			<div className='horizontal-divider' />
			<div className='font-mono text-sm text-gray-700'>
				{jsonObject.map((item, index) => {
					return (
						<div className={(index === jsonObject.length - 1 && !showMore && length > 10) ? "opacity-25" : ""} key={index}>
							{item}
						</div>
					)
				})}
				{length <= 10 ? null :
					<DashboardButton
						className='mt-5'
						onClick={() => setShowMore(!showMore)}
						label={showMore ? "Show Less" : "Show More"}
						size='sm'
						color="light"
					>
						{showMore ? "Show Less" : "Show All" + `(${length} lines)`}
					</DashboardButton>
				}
			</div>
		</div>
	)
}
