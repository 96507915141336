import Checkbox from "../../../Form/Checkbox/Checkbox";
import clsx from "clsx";
import DropdownFiltersFormSelect from "../../DropdownFiltersForm/DropdownFiltersFormSelect";
import { useMemo, useEffect } from "react";
import crypto_currencies from "../../../../../utils/crypto_currencies";

function DropdownFiltersCrypto({
  form: {
    trigger,
    setValue,
    watch,
    formState: { errors },
  },
  onToggle,
  state = false,
  placeholder = "",
  field = "",
}) {
  const fieldValue = watch(field);

  const cryptoCurrenciesOptions = useMemo(() => {
    return crypto_currencies.map((currency) => ({
      name: `${currency.name}${currency.label ? [' (' + currency.label + ')'] : ''}`,
      label: currency.label,
      value: currency.label.toLowerCase(),
      icon: currency.label.toLowerCase(),
    }));
  }, []);

  useEffect(() => {
    // Set defaults
    setValue(field, cryptoCurrenciesOptions[0].value);
    
  }, [field, cryptoCurrenciesOptions]);

  const handleOpen = () => {
    onToggle && onToggle(!state);
  };

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
        />
        <span className="text-small">{placeholder}</span>
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <DropdownFiltersFormSelect
              onChange={(value) => {
                setValue(field, value);
                trigger(field);
                // if (field === "currency") setValue("crypto_currency", "");
                // if (field === "crypto_currency") setValue("currency", "");
              }}
              options={cryptoCurrenciesOptions}
              iconPath={"icons/cryptos"}
              error={errors?.[field]?.message}
              value={fieldValue}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersCrypto;
