import { ReactComponent as ClipboardIcon } from '../../../assets/images/icons/clipboard.svg';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';

function CopyToClipboardBoxID({ text = '', index = null }) {
  const [copy, copyStatus] = useCopyToClipboard(text);

  return (
    <>
      <div
        onClick={copy}
        className='app-modal-fullscreen__header__tag'
        data-tip={''}
        data-for={`copy-clipboard-${index}`}>
        <span>{text}</span>
        <div className='vertical-divider' />
        <ClipboardIcon />
      </div>
      <ReactTooltip
        id={`copy-clipboard-${index}`}
        place='bottom'
        effect='solid'
        type='light'
        padding='10px'
        textColor='#1A1E23'
        backgroundColor='#ffffff'
        className='api-key__tooltip'
        getContent={() =>
          copyStatus === 'copied'
            ? 'Copied!'
            : 'Copy'
        }
      />
    </>
  );
}

export default CopyToClipboardBoxID;
