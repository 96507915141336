import "./LabelTag.scss";
import clsx from "clsx";

function LabelTag({ children, className = "", color = null }) {
  return (
    <div className={clsx(
      `app-label-tag ${className}`,
      color && `app-label-tag--${color}`
    )}>
      {children}
    </div>
  )
}

export default LabelTag;
