import "./Notes.scss";
import clsx from "clsx";
import DOMPurify from 'dompurify';
import moment from "moment";
import {useCallback, useMemo, useState} from "react";
import { MentionsInput, Mention } from 'react-mentions';
import {toast} from "react-toastify";
import {useFetchNotes, useStoreNote} from "../../../api/note/note";
import {useFetchTeams} from "../../../api/team/team";
import {dateFormatter} from "../../../utils/date";
import DashboardButton from "../../Common/Form/DashboardButton/DashboardButton";
import {ReactComponent as PlusIcon} from "../../../assets/images/icons/dashboard/plus.svg";
import {ReactComponent as DateIcon} from "../../../assets/images/icons/date.svg";

function NotesModule({ 
  enabled = false,
  parent_object = null
}) {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [addNote, setAddNote] = useState(false);

  const { data: teamsData } = useFetchTeams({
    onSuccess: () => null,
    onError: () => null,
    enabled,
  });

  const { refetch: refetchNotes } = useFetchNotes({
    onSuccess: (data) => setNotes(data),
    onError: () => null,
    enabled,
    params: {
      parent: parent_object?.id
    }
  });

  const { mutate: mutateStoreNote, isLoading: isStoreNoteLoading } = useStoreNote();

  const mentionsData = useMemo(() => {
    if (!teamsData?.data) return [];
    return teamsData?.data.map(team => ({id: team.user, display: team.name, email: team.email}));
  }, [teamsData]);

  const getFormattedNote = useCallback((note) => {
    let message = note.note ?? "";

    note.mentions.forEach(mention => {
      // Mention
      const regexMention = new RegExp(`\\[(${mention.id})]`, "g");
      message = message.replaceAll(regexMention, mention.name);
    });

    // Bold
    const regexBold = new RegExp(`\\*\\*(.*)\\*\\*`, "g");
    message = message.replaceAll(regexBold, "<strong>$1</strong>");

    // Italic
    const regexItalic = new RegExp(`_(.*)_`, "g");
    message = message.replaceAll(regexItalic, "<em>$1</em>");

    return message
  }, []);

  const handleAddNoteClick = () => {
    setAddNote(!addNote);
  }

  const handleCancelNoteClick = () => {
    setAddNote(false);
    setNote("");
  }

  const handleSaveNoteClick = () => {
    const filteredNote = note.replaceAll(/START_REMOVE_DISPLAY_NAME(.*)END_REMOVE_DISPLAY_NAME+/g, "");
    mutateStoreNote({
      parent: parent_object?.id,
      note: filteredNote
    }, {
      onSuccess: () => {
        refetchNotes();
        setNote("");
        setAddNote(false);
        toast.success("Note has been added successfully");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      }
    })
  }

  return (
    <>
      <div className="space-y-1.5 pb-2">
        <div className="flex justify-between">
          <div className="app-modal-fullscreen__title">Notes</div>
          <div className="flex gap-4">
            <DashboardButton
              onClick={handleAddNoteClick}
              outline
              color="primary"
              size="sm"
              icon={<PlusIcon />}
            >
              <span className="text-dark">Add Note</span>
            </DashboardButton>
          </div>
        </div>
        <div className="horizontal-divider"/>
        {addNote &&
        <div className="pt-4">
          <div className="app-mention">
            <div className="app-mention__input-wrapper">
              <MentionsInput
                className="app-mention__input"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Add a note..."
              >
                <Mention
                  className="app-mention__input__highlighter__suggestion"
                  trigger="@"
                  data={mentionsData}
                  markup="[__id__]START_REMOVE_DISPLAY_NAME(__display__)END_REMOVE_DISPLAY_NAME"
                  displayTransform={(id, display) => display}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => (
                    <div
                      className={clsx(
                        "app-mention__input__highlighter__suggestion__item",
                        focused && "app-mention__input__highlighter__suggestion__item--hover"
                      )}
                    >
                      <div>{highlightedDisplay}</div>
                      <div style={{fontSize: ".85rem"}}>{suggestion.email}</div>
                    </div>
                  )}
                />
              </MentionsInput>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="space-x-3 text-light text-sm">
              <span>@mention</span>
              <span className="font-bold">**bold**</span>
              <span className="font-italic">_italic_</span>
            </div>
            <div className="space-x-2 pt-2">
              <DashboardButton
                onClick={handleCancelNoteClick}
                color="light"
                size="sm"
              >
                Cancel
              </DashboardButton>
              <DashboardButton
                onClick={handleSaveNoteClick}
                disabled={!note || isStoreNoteLoading}
                size="sm"
              >
                Add Note
              </DashboardButton>
            </div>
          </div>
        </div>
        }
      </div>
      <div className="space-y-3">
        {notes.map((note, index) =>
          <div key={index} className="flex gap-2">
            <div>
              <DateIcon className="mt-0.5 "/>
              <div className="h-full py-0.5">
                {(notes.length !== index + 1) &&
                <div className="border-r border-dashed border-gray-300 h-full w-px mx-auto"/>
                }
              </div>
            </div>
            <div>
              <div
                className="app-modal-fullscreen__text"
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(getFormattedNote(note))}}
              />
              <span className="app-modal-fullscreen__value">
                {dateFormatter(moment(note.created_at), true)}{" "}
                by{" "}
                {note.creator.name}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default NotesModule;
