import Dropdown from '../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../assets/images/icons/dashboard/more.svg';
import {
  setEditPaymentLink,
  // setRefetchPaymentLink,
} from '../../../../../../store/paymentLink/paymentLinkSlice';
// import { toast } from 'react-toastify';
// import { useUpdatePaymentLink } from '../../../../../../api/payment/paymentLink';
import { useDispatch } from 'react-redux';

function PaymentLinkTableActions({
  paymentLink,
  showActionModal,
}) {
  const dispatch = useDispatch();

  // const { mutate: mutateUpdatePaymentLink } =
  //   useUpdatePaymentLink(paymentLink?.active);

  const handleEditClick = () => {
    dispatch(setEditPaymentLink(paymentLink));
  };

  //   const handleDeactivateClick = () => {
  //     onDeactivate && onDeactivate(paymentLink);
  //   };

  // const handleDeactivateClick = () => {
  //   mutateUpdatePaymentLink(
  //     {
  //       active: !paymentLink.active,
  //     },
  //     {
  //       onSuccess: (data) => {
  //         dispatch(setRefetchPaymentLink(Date.now()));
  //         toast.success(
  //           `PaymentLink ${
  //             data.data.status ? 'Inactive' : 'active'
  //           }`
  //         );
  //       },
  //     }
  //   );
  // };

  return (
    <>
      <Dropdown
        items={[
          //     {
          //     label: product.active ? "Archive" : "Unarchive",
          //     onClick: handleArchiveClick,
          //   },
          {
            label: 'Edit',
            onClick: handleEditClick,
          },

          // {
          //   label: paymentLink.status
          //     ? 'Active'
          //     : 'Inactive',
          //   onClick: handleDeactivateClick,
          // },
          // console.log('hhh', paymentLink),
        ]}>
        <Dropdown.Slot name='toggle'>
          <MoreIcon />
        </Dropdown.Slot>
      </Dropdown>
    </>
  );
}

export default PaymentLinkTableActions;
