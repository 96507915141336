import ModalFullScreen from "components/Common/ModalFullScreen/ModalFullScreen";
import React, { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelTag from "components/Common/LabelTag/LabelTag";
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import { toast } from "react-toastify";
import {
    capitalize,
    cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "utils/string";
import clsx from "clsx";
import moment from "moment";
import { dateFormatter } from "utils/date";
import { methodType, blockchainNetwork } from 'utils/crypto_utils';
import CopyToClipboardBoxID from "components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import MetadataForm from "components/Dashboard/MetadataForm/MetadataForm";
import { useUpdateTopup } from 'api/wallet/topups';
import { Link } from "react-router-dom";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import Input from "components/Common/Form/Input/Input";
import {
  setRefetchTopup,
  setSelectedTopup,
} from 'store/topups/topupsSlice';
import Details from './TopupModalDetails/Details';
import TopupMethod from "./TopupModalDetails/TopupMethod";
import TopupLogsTable from "./TopupLogsTable";
import TopupEventTable from "./TopupEventTable";

function TopupDetails({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const { selected_topup } = useSelector((state) => state.topup);

useEffect(() => {
    console.log("selected_topup", selected_topup);
}, [])

  const handleClose = () => {
    onClose && onClose();
  };
  
  const {
    mutate: mutateUpdateTopup,
    isLoading: isUpdateLoading,
  } = useUpdateTopup(selected_topup?.id, {
    expand: [
      'blockchain_transaction'
    ],
  });

  const handleMetadataSave = (metadata) => {
    mutateUpdateTopup(
      { metadata },
      {
        onSuccess: (data) => {
          dispatch(setSelectedTopup(data.data));
          // dispatch(setRefetchTopup(Date.now()));
          toast.success('Metadata updated');
        },
      }
    );
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };
  const getIconBrandUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">Top up</div>
            <CopyToClipboardBoxID text={selected_topup?.id} />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          <div className="space-y-7">
            <div className="space-y-1">
              <div style={{padding :"10px 0"}} className="flex items-start gap-3">
                   {selected_topup.type === "crypto"  ? <>
                <img style={{width : "40px" , height : "40px"}} alt={selected_topup?.crypto_currency}  src={getIconUrl(selected_topup?.crypto_currency)}/>
                <div style={{display : "flex" , flexDirection : "column" , alignItems: "flex-start"}} className="app-customers__modal__balance">
                  <span style={{lineHeight: "1em", fontSize: "40px", fontWeight: 600}} className="text-light font-semibold">
                    {cryptocurrencyFormatter(selected_topup?.crypto_amount)}  
                  </span>
                  <span style={{fontSize: "15px"}} className="text-light">
                    ({currencyPrettier(selected_topup?.currency , selected_topup?.amount)})
                  </span>
                </div>

                <span className="text-light ">{selected_topup.crypto_currency.toUpperCase()}</span>

                  </>
                  : <div style={{lineHeight: "1em", fontSize: "40px", fontWeight: 600}} className="text-light font-semibold">
                      {currencyPrettier(selected_topup?.currency , selected_topup?.amount)}
                    </div>
                
                }
                <LabelTag
                  color={clsx(
                    selected_topup?.status === 'succeeded' && 'primary',
                    selected_topup?.status === 'failed' && 'danger',
                    selected_topup?.status === 'pending' && 'tertiary',
                    selected_topup?.blockchain_transaction?.status === 'confirming' && 'warning',
                    selected_topup?.status === 'cancelled' && 'secondary',
                    selected_topup?.status === 'processing' && 'dark'
                  )}
                >
                  {selected_topup?.blockchain_transaction?.status === 'confirming' ? 'confirming' : selected_topup.status}
                </LabelTag>
              </div>
            </div>
            <div className="horizontal-divider" />
            <div className='flex flex-wrap gap-5 divide-x pb-7'>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  DATE
                </div>
                <div className='flex items-center'>
                  <DateIcon className='mr-1.5' />
                  <span className='app-modal-fullscreen__value'>
                    {dateFormatter(
                      selected_topup.created_at,
                      true
                    )}
                  </span>
                </div>
              </div>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  TYPE
                </div>
                <div className='app-modal-fullscreen__value'>
                  <span className='text-dark'  >
                    {capitalize(selected_topup.type)}
                  </span>
                </div>
              </div>
              <div className='pl-5 flex'>
                <div>
                  <div className='app-modal-fullscreen__label'>
                    METHOD
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    <span className='text-dark'>
                      {methodType(selected_topup.method)}
                    </span>
                  </div>
                </div>
                {selected_topup.method === 'crypto' && <img   className="self-end"
                  style={{maxHeight: "40px", marginLeft : "10px"}}
                  src={getIconBrandUrl(selected_topup.blockchain_transaction.network)} 
                  alt={blockchainNetwork(selected_topup.blockchain_transaction.network)} 
                />}
              </div>
            </div>
            <Details />
            <TopupMethod/>
            <div className='space-y-1.5 pb-2'>
              <MetadataForm
                data={selected_topup.metadata}
                onSave={handleMetadataSave}
                loading={isUpdateLoading}
              />
            </div> 
            <TopupLogsTable
            enabled={!!selected_topup}
            /> 
            <TopupEventTable
            enabled={!!selected_topup}
            />
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
    </>
  );
}

export default TopupDetails;
