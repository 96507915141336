import Checkbox from "../../Form/Checkbox/Checkbox";
import clsx from "clsx";
import DropdownFiltersFormInput from "../DropdownFiltersForm/DropdownFiltersFormInput";
import InfoIcon from "../../../../assets/images/icons/info.svg";

function DropdownFiltersTextbox({
  form: {
    register,
    formState: { errors },
  },
  title = null,
  placeholder = "",
  inputPlaceholder = "",
  field = "",
  info = null,
  onToggle,
  state = false,
}) {
  const handleOpen = () => {
    onToggle && onToggle(!state);
  };

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
        />
        <span className="text-small">{title ?? placeholder}</span>
        {info && <img data-tip={info} src={InfoIcon} alt="Info" />}
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <DropdownFiltersFormInput
              {...register(field)}
              error={errors[field]?.message}
              placeholder={inputPlaceholder}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersTextbox;
