import DashboardButton from "../../Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/dashboard/edit.svg";
import { Fragment, useMemo, useState } from "react";
import Input from "../../Common/Form/Input/Input";
import { ReactComponent as DeleteIcon } from "../../../assets/images/icons/dashboard/delete.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/icons/dashboard/plus.svg";

function MetadataForm({
  data = [],
  onSave = null,
  loading = false,
  title = null,
  key_cols = "2",
  value_cols = "10",
}) {
  const [metadata, setMetadata] = useState(
    data
      ? Object.entries(data)
          .map(([k, v]) => ({ [k]: v }))
          .map((data) => ({ ...data, saved: true }))
      : []
  );
  const [editableMetadata, setEditableMetadata] = useState(false);

  const handleAddMetadataClick = () => {
    setMetadata([...metadata, { "": "" }]);
  };

  const handleMetadataKeyChange = (value, index) => {
    setMetadata(
      metadata.map((meta, i) => {
        if (i === index) {
          return { [value]: meta[Object.keys(meta)[0]] };
        }
        return meta;
      })
    );
  };

  const handleMetadataValueChange = (value, index) => {
    setMetadata(
      metadata.map((meta, i) => {
        if (i === index) {
          return { [Object.keys(meta)[0]]: value };
        }
        return meta;
      })
    );
  };

  const handleMetadataDeleteClick = (index) => {
    if (!Object.keys(metadata[index])[0]) {
      setMetadata(metadata.filter((meta, i) => i !== index));
      return;
    }

    setMetadata(
      metadata.map((meta, i) => {
        if (i === index) {
          return { ...meta, deleted: true };
        }
        return meta;
      })
    );
  };

  const handleMetadataCancelClick = () => {
    setMetadata(
      metadata
        .filter((meta) => meta?.saved)
        .map((meta) => {
          delete meta.deleted;
          return meta;
        })
    );
    setEditableMetadata(false);
  };

  const handleMetadataSaveClick = () => {
    setMetadata(metadata.map((meta) => ({ ...meta, saved: true })));

    // Clean up metadata form empty keys
    const _cleanMetadata = metadata.filter(
      (data) => Object.keys(data)[0] !== ""
    );

    // Force empty value for deleted keys
    const _formattedKeys = _cleanMetadata.map((meta) => {
      const _key = Object.keys(meta)[0];
      return {
        [_key]: meta.deleted ? "" : meta[_key],
      };
    });

    // Flatten the array into single object
    const _metadata = Object.assign({}, ..._formattedKeys);

    setEditableMetadata(false);
    onSave && onSave(_metadata);
  };

  const hasMetadataRecords = useMemo(() => {
    return metadata.filter((meta) => !meta?.deleted).length;
  }, [metadata]);

  return (
    <>
      <div className="flex justify-between">
        {title ? (
          title
        ) : (
          <div className="app-modal-fullscreen__title">Metadata</div>
        )}
        <div className="flex gap-4">
          <DashboardButton
            outline
            color="light"
            size="sm"
            icon={<EditIcon />}
            onClick={() => setEditableMetadata(!editableMetadata)}
            type="button"
          >
            Edit
          </DashboardButton>
        </div>
      </div>
      <div className="horizontal-divider" />
      <div className="pt-2">
        {metadata.map(
          (value, index) =>
            !value.deleted && (
              <Fragment key={`metadata-${index}`}>
                {!editableMetadata && (
                  <div className="grid grid-cols-5">
                    {/*app-modal-fullscreen__kv__metadata*/}
                    <div
                      className={`col-span-${key_cols} app-modal-fullscreen__kv`}
                    >
                      <div className="app-modal-fullscreen__kv__key">
                        {Object.keys(value)[0]}
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {value[Object.keys(value)[0]]}
                      </div>
                    </div>
                  </div>
                )}
                {editableMetadata && (
                  <div className="grid grid-cols-12 gap-3 mb-3">
                    <Input
                      className={`col-span-${key_cols}`}
                      placeholder="Key"
                      block
                      value={Object.keys(value)[0]}
                      onChange={(e) =>
                        handleMetadataKeyChange(e.target.value, index)
                      }
                    />
                    <div
                      className={`col-span-${value_cols} flex items-center gap-3`}
                    >
                      <Input
                        placeholder="Value"
                        block
                        value={value[Object.keys(value)[0]]}
                        onChange={(e) =>
                          handleMetadataValueChange(e.target.value, index)
                        }
                      />
                      <DeleteIcon
                        className="cursor-pointer"
                        onClick={() => handleMetadataDeleteClick(index)}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            )
        )}
        {!hasMetadataRecords && (
          <div className="app-modal-fullscreen__value text-center">
            No metadata recorded
          </div>
        )}
        {editableMetadata && (
          <div className="flex items-center justify-between">
            <div
              onClick={handleAddMetadataClick}
              className="flex items-center app-modal-fullscreen__sm-link text-dark"
            >
              <PlusIcon />
              <span className="ml-2">Add another Key</span>
            </div>
            <div className="float-right space-x-2 pt-2">
              <DashboardButton
                onClick={handleMetadataCancelClick}
                color="light"
                size="sm"
                type="button"
              >
                Cancel
              </DashboardButton>
              <DashboardButton
                disabled={loading}
                onClick={handleMetadataSaveClick}
                size="sm"
                type="button"
              >
                Save
              </DashboardButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MetadataForm;
