import Button from "../../../components/Common/Form/Button/Button";
import "./Verify.scss";
import {Link, useLocation} from "react-router-dom";
import Logo from '../../../assets/images/logo.svg';
import FacebookIcon from '../../../assets/images/social/facebook.png';
import TwitterIcon from '../../../assets/images/social/twitter.png';
import TelegramIcon from '../../../assets/images/social/telegram.png';
import {useAuthVerifyEmail} from "../../../api/auth/auth";
import {useEffect, useState} from "react";
import Alert from "../../../components/Common/Alert/Alert";

function Verify() {
  const location = useLocation();
  const { mutate, isError, isSuccess } = useAuthVerifyEmail();
  const [error, setError] = useState("");

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (token) {
      mutate(token, {
        onError: (error) => setError(error.response.data.error.message)
      })
    } else {
      setError("Invalid Token");
    }
  }, [mutate, location]);

  return (
    <div className="app-auth min-h-screen">
      <div className="app-container app-auth__container">
        <div className="app-auth__content app-verified__content">
          <div className="app-auth__logo">
            <Link to="/">
              <img src={Logo} alt="Walio" />
            </Link>
          </div>
          <div className="app-auth__card app-auth__card__login">
            {isSuccess
            ?
              <>
                <div className="app-auth__card__title app-verified__title">This email is verified</div>
                <p className="app-verified__help">
                  You’re all set, your email is now verified.
                </p>
                <Link to="/" className="app-verified__button">
                  <Button>Return to Sign in</Button>
                </Link>
              </>
            :
              <>
                <div className="app-auth__card__title app-verified__title">Verifying Email</div>
                {(isError || error) &&
                  <Alert
                    className="my-6"
                    type="danger"
                    message={error}
                  />
                }
                {isError &&
                  <Link to="/register" className="app-verified__button">
                    <Button>Register Here</Button>
                  </Link>
                }
                <Link to="/login" className="app-verified__link">
                  Return to Sign in
                </Link>
              </>
            }
          </div>
        </div>
        <div className="app-auth__footer">
          <div>© Walio All rights reserved.</div>
          <div className="app-auth__footer__social">
            <img src={FacebookIcon} alt="" href="https://www.facebook.com/mywalio.io/"/>
            <img src={TwitterIcon} alt="" href="https://twitter.com/walio_official"/>
            <img src={TelegramIcon} alt="" href="https://t.me/walio_official"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Verify;
