import DashboardModalTable from 'components/Dashboard/DashboardModalTable/DashboardModalTable'
import useTablePagination from 'hooks/useTablePagination';
import React , {useState , useEffect, useMemo, useCallback} from 'react'
import { useSelector } from 'react-redux';
import { dateFormatter } from 'utils/date';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import Checkbox from 'components/Common/Form/Checkbox/Checkbox';
import { useFetchCreditNotes } from 'api/customer/credit_note';
import "./styles.scss"
import { currencyPrettierNoSymbol } from 'utils/string';
export default function CreditNotTable() {
    const [data, setData] = useState([]);
    const [tableRows, setTableRows] = useState([1,1,1]);
    const [activeRow, setactiveRow] = useState([false , false , false]);
    const [all, setAll] = useState(true);
    const { selected_invoice } = useSelector(
      (state) => state.invoice
    );
    const {
      canNextPage,
      canPreviousPage,
      dataCount,
      pageCount,
      requestQuery,
      limitQuery,
      onPreviousPage,
      onNextPage,
    } = useTablePagination(data, tableRows);

    const onFetchEventsSuccess = (data) => {
      setData(data);
      console.log(data)
    };
    const { isFetching: isFetchingData } = useFetchCreditNotes({
      onSuccess: onFetchEventsSuccess,
      onError: () => null,
      
      params: {
        limit: limitQuery,
        ...requestQuery,
      },
    });
  
    const CheckBoxChange = (e, id) => {
        console.log(e.target.checked)
        console.log(id)
      var temp = activeRow;
        temp[id] = e.target.checked;
        setactiveRow([...temp])
    }
    useEffect(() => {
      var newData = selected_invoice.line_items.data.map((item) => {
        return {
          desc : item.description,
          qty : item.quantity,
          price : currencyPrettierNoSymbol(item.currency,item.unit_amount),
          tax : item.tax,
          credit_amount : currencyPrettierNoSymbol(item.currency , item.amount),
          currency : item.currency,
          _data : item
        };
      }
      );
      setTableRows(newData);
    }, [])
      const selectAllChange =(e) => {
          console.log(all) 

        var temp = activeRow;
        temp.map((item, index) => {

            temp[index] = e.target.attributes.checked;
        })
        setactiveRow([...temp])

        }

        const handleAddCredit = () => {
          var temp = activeRow;
          temp.push(true)
          setactiveRow([...temp])
            setTableRows([...tableRows, 1])
        }

    const tableColumns = useMemo(
        () => [
          {
            Header: <div className='flex gap-2 items-center'>
                <Checkbox
                    color='primary'
                    name='ll'
                    id='select_all'
                    // checked={all ? true : false}
                    onChange={(e)=>{
                      e.target.attributes.checked = !e.target.attributes.checked

                        selectAllChange(e)
                    }} 
                    className='mr-2 w-4 h-4'
                    size='sm'
                />
                Description 
            </div>,
            accessor: 'description',
            Cell: (cell) => {
              const row = cell.row.original;
              var index = cell.row.index;
              return (
                <div className='flex items-center gap-2 '>
                    <Checkbox
                        color='primary'
                        size='sm'
                    name={"row" + index}
                    id={"row" + index}
                    checked={activeRow[index]}
                    onChange={(e)=>{
                      CheckBoxChange(e, index)
                    }}
                    className='mr-2'

                />
                  <input
                  disabled={!activeRow[cell.row.index]}
                    value={row?.desc}
                    type='text'
                    className={'w-full h-6 border border-gray-300 rounded-lg text-sm px-2 input-credit' } // todo
                    placeholder='Description'
                  />
                
                </div>  
              );
            },
          },
          {
            Header: 'CREDIT QTY',
            accessor: 'created_at',
            maxWidth: 70,
            Cell: (cell) => {
              const row = cell.row.original;
              return (
                <div className='flex items-center'>
                {row._data.amount > 0 &&  <input
                  disabled={!activeRow[cell.row.index]}
                    value={row?.qty}
                    type='text'
                    className={'w-10 h-6 border border-gray-300 rounded-lg text-sm px-2 input-credit' } // todo
                    placeholder='Description'
                  />}
                </div>
              );
            },
          },
          {
            Header: 'price',
            accessor: 'price',
            width: 50,
            Cell: (cell) => {
              const row = cell.row.original;
              return (
                <div className='flex items-end'>
                  {row._data.amount  > 0 &&  <input
                   disabled={!activeRow[cell.row.index]}
                    value={row?.price}
                    type='text'
                    className={'w-20 h-6 border border-gray-300 rounded-lg text-sm px-2 input-credit' } // todo
                    placeholder='Description'
                  />}

                </div>
              );
            },
          },
          {
            Header: 'TAX',
            accessor: 'tax',
            width: 50,
            Cell: (cell) => {
              const row = cell.row.original;
              return (
                <div>
                  {row._data.amount > 0 &&  <div>0.00</div>}
                </div>
              );
            },
          },
          {
            Header: 'Cedit Amount',
            accessor: 'credit_amount',
            width: 50,
            Cell: (cell) => {
              const row = cell.row.original;
              return (
                <div className='text-end'>
                  {
                    row._data.amount > 0 &&  <div>
                        {row?.credit_amount}
                    </div>
                  }
                  
                </div>
              );
            },
          },
   
        ],
        []
      );

  return (
    <div className='space-y-1.5 pb-2 mt-10'>
    <div className='app-modal-fullscreen__title space-x-4'>
      <span>Items to credit</span>

    </div>

    <DashboardModalTable
      columns={tableColumns}
      data={tableRows}
      pageCount={pageCount}
      onPreviousPage={onPreviousPage}
      onNextPage={onNextPage}
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      loading={false}
      count={dataCount}
      noRecordTag="credit"
    />
    <div onClick={handleAddCredit} className='text-primary  text-sm cursor-pointer font-semibold'>
        Add Item 
    </div>
  </div>
  )
}
