import Checkbox from "../../../Form/Checkbox/Checkbox";
import React, { useEffect } from "react";
import clsx from "clsx";
import DropdownFiltersFormSelect from "../../DropdownFiltersForm/DropdownFiltersFormSelect";
import DropdownFiltersFormInput from "../../DropdownFiltersForm/DropdownFiltersFormInput";
import { useDispatch } from "react-redux";
import { setFilters } from "../../../../../store/app/appSlice";

const AMOUNT_OPTIONS = [
  { label: "is equal to", value: "is_equal_to" },
  { label: "is between", value: "is_between" },
  { label: "is greater than", value: "is_greater_than" },
  { label: "is less than", value: "is_less_than" },
];

function DropdownFiltersCryptoAmount({
  form: {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
  },
  onToggle,
  state = false,
  placeholder = "Crypto Amount",
  filter_field = "crypto_amount",
}) {
  const dispatch = useDispatch();

  const cryptoValue = watch("crypto_currency");
  const amount_option = watch(`${filter_field}_option`);

  useEffect(() => {
    dispatch(setFilters({ [`${filter_field}_option`]: "is_equal_to" }));
    setValue(`${filter_field}_option`, "is_equal_to");
  }, [dispatch, filter_field, setValue]);

  const handleOpen = () => {
    onToggle && onToggle(!state);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
        />
        <span className="text-small">{placeholder}</span>
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <DropdownFiltersFormSelect
              options={AMOUNT_OPTIONS}
              onChange={(value) => {
                setValue(`${filter_field}_option`, value);
                trigger(`${filter_field}_option`);
                dispatch(setFilters({ [`${filter_field}_option`]: value }));
              }}
              value={amount_option}
            />
            <div className="flex items-start gap-2 mt-2">
              {cryptoValue && (
                <img
                  className="app-dropdown__filters__select__options__item__icon mt-2"
                  style={{ marginRight: "0" }}
                  src={getIconUrl(cryptoValue)}
                  alt="cryptoValue"
                />
              )}
              {amount_option === "is_equal_to" && (
                <div className="w-full">
                  <DropdownFiltersFormInput
                    type="number"
                    min="0"
                    {...register(`${filter_field}`)}
                    error={errors?.[`${filter_field}`]?.message}
                  />
                </div>
              )}
              {amount_option === "is_between" && (
                <>
                  <div className="w-full">
                    <DropdownFiltersFormInput
                      type="number"
                      min="0"
                      placeholder="MIN"
                      {...register(`${filter_field}_gte`)}
                      error={errors?.[`${filter_field}_gte`]?.message}
                    />
                  </div>
                  <div className="w-full">
                    <DropdownFiltersFormInput
                      type="number"
                      min="0"
                      placeholder="MAX"
                      {...register(`${filter_field}_lte`)}
                      error={errors?.[`${filter_field}_lte`]?.message}
                    />
                  </div>
                </>
              )}
              {amount_option === "is_greater_than" && (
                <div className="w-full">
                  <DropdownFiltersFormInput
                    type="number"
                    min="0"
                    {...register(`${filter_field}_gt`)}
                    error={errors?.[`${filter_field}_gt`]?.message}
                  />
                </div>
              )}
              {amount_option === "is_less_than" && (
                <div className="w-full">
                  <DropdownFiltersFormInput
                    type="number"
                    min="0"
                    {...register(`${filter_field}_lt`)}
                    error={errors?.[`${filter_field}_lt`]?.message}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersCryptoAmount;
