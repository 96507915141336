import { createSlice } from '@reduxjs/toolkit'

export const shippingRateSlice = createSlice({
  name: 'shipping_rate',
  initialState: {
    selected_shipping_rate: null,
    refresh_shipping_rate: null,
    show_shipping_rate_form: false,
  },
  reducers: {
    setSelectedShippingRate: (state, payload) => {
      state.selected_shipping_rate = payload.payload
    },
    setRefetchShippingRate: (state, payload) => {
      state.refetch_shipping_rate = payload.payload
    },
    setShowShippingRateForm: (state, payload) => {
      state.show_shipping_rate_form = payload.payload
    },
  },
})

export const {
  setSelectedShippingRate,
  setRefetchShippingRate,
  setShowShippingRateForm,
} = shippingRateSlice.actions

export default shippingRateSlice.reducer
