export const DASHBOARD_TABLE_LIMIT = 10;
export const ITEM_NEGATIVE_PRICE_REGEX = "^-?\\d+(\\.\\d{0,2})?$";
export const ITEM_PRICE_REGEX = "^\\d+(\\.\\d{0,2})?$";
export const INR_PRICE_REGEX = "^\\d+(\\.\\d{0,14})?$";
export const PRICE_REGEX = "^\\d+(\\.\\d{0,12})?$";
export const DISCOUNT_TYPES = {
  standard: "Standard Discount",
  amount_gte: "Sale Amount",
  product: "Product Specific",
  product_quantity: "Product Quantity",
};
// export const FIAT_CURRENCIES = [
//   "usd", "aed", "ars", "aud", "bdt", "bhd", "bmd", "brl", "bgn", "cad", 
//   "chf", "cny", "hrk", "czk", "dkk", "egp", "eur", "gel", "gbp", "hkd", "huf", "ils", 
//   "inr", "kwd", "lkr", "mad", "mmk", "mxn", "myr", "ngn", "nok", "nzd", "php", "pkr", 
//   "pln", "qar", "ron", "rub", "sar", "sek", "sgd", "thb", "try", "twd", "uah", "ves", "zar",
//   "clp", "idr", "jpy", "krw", "vnd"
// ];

export const FIAT_CURRENCIES = [
	{currency: 'usd', name: 'US Dollar', symbol: '$', zero_decimal: false, colour: 'blue' },
	{currency: 'aed', name: 'UAE Dirham', symbol: 'Dhs', zero_decimal: false, colour: 'green' },
	{currency: 'ars', name: 'Argentine Peso', symbol: '$', zero_decimal: false, colour: 'red' }, 
	{currency: 'aud', name: 'Australian Dollar', symbol: '$', zero_decimal: false, colour: 'black' }, 
	{currency: 'bdt', name: 'Bangladeshi Taka', symbol: '৳', zero_decimal: false }, 
	{currency: 'bhd', name: 'Bahraini Dinar', symbol: 'BD', zero_decimal: false }, 
	{currency: 'bmd', name: 'Bermudan Dollar', symbol: '$', zero_decimal: false }, 
	{currency: 'brl', name: 'Brazilian Real', symbol: 'R$', zero_decimal: false }, 
	{currency: 'bgn', name: 'Bulgarian Lev', symbol: 'Лв', zero_decimal: false }, 
	{currency: 'cad', name: 'Canadian Dollar', symbol: '$', zero_decimal: false }, 
	{currency: 'chf', name: 'Swiss Franc', symbol: 'Fr', zero_decimal: false }, 
	{currency: 'clp', name: 'Chilean Peso', symbol: '$', zero_decimal: true }, 
	{currency: 'cny', name: 'Chinese Yuan', symbol: '¥', zero_decimal: false }, 
	{currency: 'czk', name: 'Czech Koruna', symbol: 'Kč', zero_decimal: false }, 
	{currency: 'dkk', name: 'Danish Krone', symbol: 'Kr', zero_decimal: false }, 
	{currency: 'egp', name: 'Egyptian Pound', symbol: 'E£', zero_decimal: false }, 
	{currency: 'eur', name: 'Euro', symbol: '€', zero_decimal: false }, 
	{currency: 'gel', name: 'Georgian Lari', symbol: '₾', zero_decimal: false }, 
	{currency: 'gbp', name: 'British Pound', symbol: '£', zero_decimal: false }, 
	{currency: 'hkd', name: 'Hong Kong Dollar', symbol: '$', zero_decimal: false }, 
	{currency: 'huf', name: 'Hungarian Forint', symbol: 'Ft', zero_decimal: false }, 
	{currency: 'idr', name: 'Indonesian Rupiah', symbol: 'Rp', zero_decimal: true }, 
	{currency: 'ils', name: 'Israeli New Shekel', symbol: '₪', zero_decimal: false }, 
	{currency: 'inr', name: 'Indian Rupee', symbol: '₹', zero_decimal: false }, 
	{currency: 'jpy', name: 'Japanese Yen', symbol: '¥', zero_decimal: true }, 
	{currency: 'krw', name: 'South Korean Won', symbol: '₩', zero_decimal: true }, 
	{currency: 'kwd', name: 'Kuwaiti Dinar', symbol: 'KD', zero_decimal: false }, 
	{currency: 'lkr', name: 'Sri Lankan Rupee', symbol: 'Rs', zero_decimal: false }, 
	{currency: 'mad', name: 'Moroccan Dirham', symbol: 'DH', zero_decimal: false }, 
	{currency: 'mmk', name: 'Myanmar Kyat', symbol: 'K', zero_decimal: false }, 
	{currency: 'mxn', name: 'Mexican Peso', symbol: 'Mex$', zero_decimal: false }, 
	{currency: 'myr', name: 'Malaysian Ringgit', symbol: 'RM', zero_decimal: false }, 
	{currency: 'ngn', name: 'Nigerian Naira', symbol: '₦', zero_decimal: false }, 
	{currency: 'nok', name: 'Norwegian Krone', symbol: 'kr', zero_decimal: false }, 
	{currency: 'nzd', name: 'New Zeland Dollar', symbol: '$', zero_decimal: false }, 
	{currency: 'php', name: 'Philippine Peso', symbol: '₱', zero_decimal: false }, 
	{currency: 'pkr', name: 'Pakistani Rupee', symbol: 'Rs', zero_decimal: false }, 
	{currency: 'pln', name: 'Polish Zloty', symbol: 'zł', zero_decimal: false }, 
	{currency: 'qar', name: 'Qatari Rial', symbol: 'QR', zero_decimal: false }, 
	{currency: 'ron', name: 'Romanian Leu', symbol: 'lei', zero_decimal: false }, 
	{currency: 'rub', name: 'Russian Ruble', symbol: '₽', zero_decimal: false }, 
	{currency: 'sar', name: 'Saudi Riyal', symbol: 'SR', zero_decimal: false }, 
	{currency: 'sek', name: 'Swedish Krona', symbol: 'kr', zero_decimal: false }, 
	{currency: 'sgd', name: 'Singapore Dollar', symbol: 'S$', zero_decimal: false }, 
	{currency: 'thb', name: 'Thai Baht', symbol: '฿', zero_decimal: false }, 
	{currency: 'try', name: 'Turkish Lira', symbol: '₺', zero_decimal: false }, 
	{currency: 'twd', name: 'New Taiwan Dollar', symbol: '$', zero_decimal: false }, 
	{currency: 'uah', name: 'Ukrainian Hryvnia', symbol: '₴', zero_decimal: false }, 
	{currency: 'ves', name: 'Venezuelan Bolivar', symbol: 'Bs', zero_decimal: false }, 
	{currency: 'vnd', name: 'Vietnamese Dong', symbol: '₫', zero_decimal: true }, 
	{currency: 'zar', name: 'South African Rand', symbol: 'R', zero_decimal: false }
];

// export const FIAT_CURRENCIES_SELECT = FIAT_CURRENCIES.map((currency) => {
//   return { label: currency?.toUpperCase(), value: currency };
// });

export const FIAT_CURRENCIES_SELECT = FIAT_CURRENCIES.map((obj) => {
  return { label: obj.currency.toUpperCase(), name:`${obj.currency.toUpperCase()} - ${obj.name}`, value: obj.currency };
});

// Only return currencies that are provided:
/**
 * Will return only the currency options specified.
 * @param {Array} currencies An array of currency string values to return data for.
 * @returns {String} An array containing the specified currencies data.
 */
export const limitFiatCurrencies = (currencies = []) => {
	let limitedCurrencies = [];

	if (currencies.length <= 0) limitedCurrencies = FIAT_CURRENCIES.map((obj) => {
		return { label: obj.currency.toUpperCase(), name:`${obj.currency.toUpperCase()} - ${obj.name}`, value: obj.currency };
	});
	else {
		for (let i = 0; i < currencies.length; i++) {
			const currencyObj = FIAT_CURRENCIES.find((obj) => obj.currency === currencies[i].toLowerCase());

			if (currencyObj) limitedCurrencies.push({
				label: currencyObj.currency.toUpperCase(), 
				name:`${currencyObj.currency.toUpperCase()} - ${currencyObj.name}`, 
				value: currencyObj.currency
			});
    }
	}


	return limitedCurrencies;
}
