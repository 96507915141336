import DashboardTable from "../../../../Dashboard/DashboardTable/DashboardTable";
import React, {useEffect, useMemo, useState} from "react";
import {ReactComponent as WebhookLinkIcon} from "../../../../../assets/images/icons/webhook_link.svg";
import {useSelector} from "react-redux";
import {useFetchWebhooks} from "../../../../../api/webhook/webhook";
import LabelTag from "../../../../Common/LabelTag/LabelTag";
import useTablePagination from "../../../../../hooks/useTablePagination";
import {useNavigate} from "react-router-dom";
import WebhooksTableActions from "./WebhooksTableActions/WebhooksTableActions";
import WebhooksModalDelete from "../WebhooksModals/WebhooksModalDelete/WebhooksModalDelete";

function WebhooksTable() {
  const navigate = useNavigate();
  const { refetch_webhook } = useSelector((state) => state.webhook);
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage
  } = useTablePagination(data, tableRows);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_webhook]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchWebhooksSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        url: row.url,
        active: row.active,
        description: row.description,
        id: row.id,
        _data: row,
      }
    });
    setTableRows(tableData);
    setRefetch(false);
  }

  const onFetchWebhooksError = () => {
    setRefetch(false);
  }

  const { isFetching: isFetchingData } = useFetchWebhooks({
    onSuccess: onFetchWebhooksSuccess,
    onError: onFetchWebhooksError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery
    }
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedWebhook(row._data));
    navigate(`/dashboard/webhooks/${row.id}`);
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  }

  const tableColumns = useMemo(() => [
    {
      Header: "URL Link",
      accessor: "url",
      Cell: (cell) => {
        return (
          <div className="flex items-center gap-2">
            <WebhookLinkIcon/>
            <span>{cell.value}</span>
          </div>
        );
      }
    },
    {
      Header: "Description",
      accessor: "description",
      maxWidth: 120,
      Cell: (cell) => {
        return (
          <div className="app-modal-fullscreen__value">{cell.value}</div>
        )
      }
    },
    {
      Header: "Status",
      accessor: "active",
      maxWidth: 20,
      Cell: (cell) => {
        return (
          <LabelTag
            color={cell.value ? "primary" : "danger"}
          >
            {cell.value ? "Active" : "Disabled"}
          </LabelTag>
        );
      }
    },
    {
      Header: "",
      id: "icon",
      width: 5,
      Cell: (cell) =>
        <WebhooksTableActions
          webhook={cell.row.original}
          onDelete={handleDeleteClick}
        />
    },
  ], []);

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Webhooks"
        />
      </div>
      {showDeleteModal &&
      <WebhooksModalDelete show={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
      }
    </>
  )
}

export default WebhooksTable;
