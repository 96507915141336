import api from "../../utils/axios";
import {useQuery} from "react-query";
import appStore from "../../store";

const fetchCreditNotes = (params) => appStore.getState().app.livemode
  ? api.live.get("/credit_notes", { params })
  : api.sandbox.get("/credit_notes", { params });

export const useFetchCreditNotes = ({ onSuccess, onError, enabled, params = null }) => {
  return useQuery(
    ["fetchCreditNotes", params],
    () => fetchCreditNotes(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
