import React, { useState, useEffect } from 'react'
import { ReactComponent as DateIcon } from '../../../assets/images/icons/date.svg';
import moment from 'moment';
export default function ExpiryTimer(
    {
        expiry,
        handleNewEstimates,
        compact = false,
    }
) {
    const [expiryTime, setExpiryTime] = useState(null);
    useEffect(() => {
        const interval = setInterval(() => {
            const diff = moment
                .unix(expiry)
                .diff(moment(), "seconds");
            const estimation = moment
                .duration(diff, "seconds")
            setExpiryTime({
                minutes: estimation._data.minutes,
                seconds: estimation._data.seconds,
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    if (compact && expiryTime !== null) {
        return (<>
            <div className="flex items-center gap-2 text-sm text-tertiary_darken">
                {expiryTime.minutes > 0 && expiryTime.seconds > 0 ? (
                    <>
                        Expires in <DateIcon className="mt-0.5" /> {expiryTime.minutes} min : {expiryTime.seconds} sec
                    </>
                ) : (
                    <>
                        <span className="cursor-pointer underline" onClick={() => {
                            console.log('handleNewEstimates')
                            handleNewEstimates()
                        }} >Get new estimates</span>
                    </>
                )}
            </div>
        </>)
    }

    return (
        <div>
            {
                expiryTime !== null && (
                    <>
                        <div className="  app-invoice__form__estimation">
                            {expiryTime.minutes > 0 && expiryTime.seconds > 0 ? (
                                <div className='flex  justify-around items-center w-full'>

                                    <div className="app-invoice__form__estimation__header">
                                        Expires
                                    </div>
                                    <div className="flex items-start gap-2">
                                        <DateIcon className="mt-1.5" />
                                        <div className="app-invoice__form__estimation__time">
                                            <span>{expiryTime.minutes}</span>
                                            <div className="app-invoice__form__estimation__time__unit">
                                                min
                                            </div>
                                        </div>
                                        <span>:</span>
                                        <div className="app-invoice__form__estimation__time">
                                            <span>{expiryTime.seconds}</span>
                                            <div className="app-invoice__form__estimation__time__unit">
                                                sec
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="app-invoice__form__estimation__header">
                                        Estimate expired
                                    </div>
                                    <div>
                                        <span
                                            onClick={handleNewEstimates}
                                            className="font-semibold underline cursor-pointer"
                                        >
                                            Get new estimates
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
        </div>
    )
}
