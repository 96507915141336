import DashboardTable from "../../../../../Dashboard/DashboardTable/DashboardTable";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as DateIcon } from "../../../../../../assets/images/icons/date.svg";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../../../../../utils/date";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../utils/string";
import useTablePagination from "../../../../../../hooks/useTablePagination";
import CopyToClipboardBoxID from "../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { useNavigate } from "react-router-dom";
import { useFetchShippingRates } from "../../../../../../api/product/shipping_rates";

function ShippingRatesTable({ filters = {} }) {
  const navigate = useNavigate();
  const { refetch_shipping_rate } = useSelector((state) => state.shipping_rate);
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_shipping_rate, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchShippingRatesSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        description: row.description,
        shipping_time: row.shipping_time,
        amount: row.amount,
        currency: row.currency,
        created: dateFormatter(row.created_at),
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchShippingRatesError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchShippingRates({
    onSuccess: onFetchShippingRatesSuccess,
    onError: onFetchShippingRatesError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedShippingRate(row._data));
    navigate(`/dashboard/shipping_rates/${row.id}`);
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        maxWidth: 110,
        Cell: (cell) => {
          return (
            <div
              className="font-normal break-all"
              onClick={(e) => e.stopPropagation()}
            >
              <CopyToClipboardBoxID text={cell.value} index={cell.row.index} />
            </div>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        minWidth: 220,
        Cell: (cell) => {
          const row = cell.row.original;
          const daysWord = (days) => (days > 1 ? "days" : "day");

          return (
            <div>
              {cell.value ?? "-"}
              {row?.shipping_time && (
                <div className="text-light">
                  {row.shipping_time.to === null &&
                    `up to ${row.shipping_time.from} business ${daysWord(
                      row.shipping_time.from
                    )}`}
                  {row.shipping_time.to !== null &&
                    row.shipping_time.to !== row.shipping_time.from &&
                    `${row.shipping_time.from} to ${
                      row.shipping_time.to
                    } business ${daysWord(row.shipping_time.to)}`}
                  {row.shipping_time.to === row.shipping_time.from &&
                    `${row.shipping_time.from} business ${daysWord(
                      row.shipping_time.from
                    )}`}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        maxWidth: 40,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className="app-customers__modal__earning">
              {row.amount > 0 ? (
                <>
                  {currencySymbol(row.currency)}
                  {currencyFormatter(row.currency, row.amount)}
                  <span>{row.currency?.toUpperCase()}</span>
                </>
              ) : (
                "Free"
              )}
            </div>
          );
        },
      },
      {
        Header: "Created",
        accessor: "created",
        maxWidth: 60,
        Cell: (cell) => {
          return (
            <div className="flex items-center">
              <div>
                <DateIcon className="mr-1.5" />
              </div>
              <span className="text-light">{cell.value}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Shipping Rates"
        />
      </div>
    </>
  );
}

export default ShippingRatesTable;
