import "./Checkbox.scss";
import {forwardRef} from "react";
import clsx from "clsx";

const Checkbox = forwardRef(({
  label = "",
  color = "",
  size = "",
  className = "",
  ...rest
}, ref) => {
  return (
    <label className={className}>
      <input
        ref={ref}
        className={clsx(
          "app-checkbox",
          color && `app-checkbox--${color}`,
          size && `app-checkbox--${size}`
        )}
        type="checkbox"
        {...rest}
      />
      <div className="">
      {label}

      </div>
    </label>
  )
});

export default Checkbox;
