import Modal from "../../../../../Common/Modal/Modal";
import Stack from "../../../../../Common/Stack/Stack";
import Label from "../../../../../Common/Form/Label/Label";
import Input from "../../../../../Common/Form/Input/Input";
import Button from "../../../../../Common/Form/Button/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {setRefetchWebhook, setSelectedWebhook} from "../../../../../../store/webhook/webhookSlice";
import {toast} from "react-toastify";
import {useUpdateWebhook} from "../../../../../../api/webhook/webhook";
import {useDispatch, useSelector} from "react-redux";
import Alert from "../../../../../Common/Alert/Alert";

const formSchema = yup.object({
  code: yup.string().required().label('2FA Code'),
}).required();

function WebhooksModalRollSecret({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const {selected_webhook} = useSelector((state) => state.webhook);

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(formSchema)
  });

  const { mutate: mutateUpdateWebhook, isLoading } = useUpdateWebhook(selected_webhook?.id);

  const fieldError = (field) => {
    return errors[field]?.message/* || (error && error.response?.data.error.param === field);*/
  }

  const handleClose = () => {
    onClose && onClose();
  }

  const onSubmit = (formData) => {
    mutateUpdateWebhook({
      roll_secret: true,
      otp: formData.code
    }, {
      onSuccess: (data) => {
        if (data.status === 206) {
          toast.warning(data.data.error.message);
          return;
        }

        handleClose();
        dispatch(setSelectedWebhook(data.data));
        dispatch(setRefetchWebhook(Date.now()));
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      }
    })
  }

  return (
    <Modal show={show} onClose={handleClose} size="sm">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            Roll Secret
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <Alert
          message="A new secret will be generated to sign webhook events."
          type="danger"
          className="mb-4"
        />
        <form id="roll-secret-webhook" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1">
            <div>
              <Label
                title="Enter 2FA Code"
                error={fieldError("code")}
              />
              <Input
                {...register("code")}
                type="password"
                block
                error={fieldError("code")}
              />
            </div>
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button disabled={isLoading} form="roll-secret-webhook" type="submit" color="danger">Roll Key</Button>
        </div>
      </Modal.Slot>
    </Modal>
  )
}

export default WebhooksModalRollSecret;