import {
  currencyFormatter,
  currencySymbol,
} from '../../../../../utils/string';
import { useSelector } from 'react-redux';

const RefundDetailsItems = () => {
  const { selected_refund } = useSelector(
    (state) => state.refund
  );

  return (
    <div className='space-y-1.5 pb-2'>
      <div className='app-modal-fullscreen__title'>
        Refund Breakdown
      </div>
      {selected_refund.type === 'invoice' &&
        selected_refund.breakdown.length > 1 && (
          <table className='app-modal-fullscreen__table'>
            <thead>
              <tr>
                <th
                  width='50%'
                  className='app-modal-fullscreen__label'>
                  Payment
                </th>
                <th
                  width='50%'
                  className='app-modal-fullscreen__label'>
                  Amount Refunded
                </th>
              </tr>
            </thead>
            <tbody className='app-modal-fullscreen__table--align-top'>
              <tr>
                <td>
                  <span className='app-modal-fullscreen__value'>
                    <span className='text-dark font-semibold'>
                      {selected_refund.breakdown.payment}
                    </span>
                  </span>
                </td>
                <td>
                  <div className='font-normal'>
                    {currencySymbol(
                      selected_refund.currency
                    )}
                    {currencyFormatter(
                      selected_refund.breakdown.amount
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
    </div>
  );
};

export default RefundDetailsItems;
