import Dropdown from "../../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../../assets/images/icons/dashboard/more.svg";
import React, { useCallback } from "react";
import { setSelectedPendingInvoiceItem } from "../../../../../../../store/customer/customerSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function CustomerDetailsPendingItemsTableActions({
  row,
  setShowCreateInvoiceItemModal,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewProductClick = useCallback(
    (item) => {
      navigate(`/dashboard/products/${item?.product}`);
    },
    [navigate]
  );

  const handleViewPriceClick = useCallback(
    (item) => {
      navigate(`/dashboard/prices/${item?.price}`);
    },
    [navigate]
  );

  const handleEditClick = useCallback(
    (item) => {
      dispatch(setSelectedPendingInvoiceItem(item));
      setShowCreateInvoiceItemModal(true);
    },
    [dispatch, setShowCreateInvoiceItemModal]
  );

  return (
    <Dropdown
      items={[
        {
          label: "Edit",
          onClick: () => handleEditClick(row),
        },
        {
          label: "View Price Details",
          onClick: () => handleViewPriceClick(row),
        },
        {
          label: "View Product Details",
          onClick: () => handleViewProductClick(row),
        },
        { label: "Delete", className: "text-danger font-semibold" },
      ]}
    >
      <Dropdown.Slot name="toggle">
        <MoreIcon className="cursor-pointer" />
      </Dropdown.Slot>
    </Dropdown>
  );
}

export default CustomerDetailsPendingItemsTableActions;
