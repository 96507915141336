import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchBalanceTransactions = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/balance_transactions", { params })
    : api.sandbox.get("/balance_transactions", { params });

export const useFetchBalanceTransactions = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchBalanceTransactions", params], () => fetchBalanceTransactions(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchBalanceTransaction = (id) =>
  appStore.getState().app.livemode
    ? api.live.get(`/balance_transactions/${id}`, {
        params: {
          expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
        },
      })
    : api.sandbox.get(`/balance_transactions/${id}`, {
        params: {
          expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
        },
      });

export const useFetchBalanceTransaction = ({ onSuccess, onError, enabled, id }) => {
  return useQuery(["fetchBalanceTransaction", id], () => fetchBalanceTransaction(id), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};
