import { dateFormatter } from "../../../../../../../utils/date";
import React, { useMemo, useState } from "react";
import DashboardModalTable from "../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import { useSelector } from "react-redux";
import useTablePagination from "../../../../../../../hooks/useTablePagination";
import { useFetchEvents } from "../../../../../../../api/event/event";
import LabelTag from "../../../../../../Common/LabelTag/LabelTag";
import { snakeCaseToTitleCase } from "../../../../../../../utils/string";
import clsx from "clsx";

function WebhookDetailsEventsTable() {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_webhook } = useSelector((state) => state.webhook);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchEventsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  };

  const { isFetching: isFetchingData } = useFetchEvents({
    onSuccess: onFetchEventsSuccess,
    onError: () => null,
    enabled: true,
    params: {
      webhook: selected_webhook?.id,
      limit: limitQuery,
      ...requestQuery,
    },
  });

  const tableColumns = useMemo(
    () => [
      {
        Header: "Event",
        accessor: "data.object.id",
        maxWidth: 100,
        Cell: (cell) => {
          return (
            <div className="app-modal-fullscreen__value">{cell.value}</div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        minWidth: 100,
        Cell: (cell) => {
          return (
            <div className="app-modal-fullscreen__value">{cell.value}</div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        maxWidth: 40,
        Cell: (cell) => {
          return (
            <div className="app-modal-fullscreen__value">
              {dateFormatter(cell.value, true)}
            </div>
          );
        },
      },
      {
        Header: "Response",
        accessor: "attempts",
        maxWidth: 35,
        Cell: (cell) => {
          const response = cell.value[cell.value.length - 1].response;
          return (
            <div className="app-modal-fullscreen__value">
              <LabelTag
                color={clsx(
                  (response >= 400 || response === "no_response") && "danger",
                  response >= 300 && "warning",
                  response >= 200 && "primary"
                )}
              >
                {snakeCaseToTitleCase(response)}
              </LabelTag>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="space-y-1.5">
      <div className="app-modal-fullscreen__title">Events</div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Events"
      />
    </div>
  );
}

export default WebhookDetailsEventsTable;
