export function blockchainNetwork(network) {
	let networkName = '';
	switch (network) {		
		case 'litecoin':
			networkName = 'Litecoin';
			break;
		 
		case 'binance_smart_chain': 
			networkName = 'Binance Smart Chain';
			break;
		case 'kucoin':
			networkName = 'KuCoin';
			break;
		case 'ethereum':
			networkName = 'Ethereum';
			break;
		case 'polygon': 
			networkName = 'Polygon';
			break;
		case 'fantom': 
			networkName = 'Fantom';
			break;
		case 'avalanche':
			networkName = 'Avalanche';
			break;
		case 'ripple':
			networkName = 'Ripple';
			break;
		case 'stellar':
			networkName = 'Stellar';
			break;
		case 'tron':
			networkName = 'Tron';
			break;
		case 'binance_chain':
			networkName = 'Binance Chain';
			break;
		default:
			networkName = 'Bitcoin';
	}

	return networkName;
}

export function methodType(method) {
	let methodName = '';
	switch (method) {		
		case 'crypto':
			methodName = 'Blockchain';
			break;
		case 'binance_pay': 
			methodName = 'Binance';
			break;
		case 'crypto_com_pay':
			methodName = 'Crypto.com';
			break;
		case 'paypal':
			methodName = 'Paypal';
			break;
		case 'bank_account': 
			methodName = 'Bank Transfer';
			break;
		case 'card': 
			methodName = 'Card';
			break;
		case 'wechat':
			methodName = 'WeChat';
			break;
		default:
			methodName = 'Unknown';
	}

	return methodName;
}