import ModalFullScreen from '../../../../Common/ModalFullScreen/ModalFullScreen';
import { ReactComponent as DateIcon } from '../../../../../assets/images/icons/date.svg';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatter } from '../../../../../utils/date';
import {
  capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencySymbol,
} from '../../../../../utils/string';
import LabelTag from '../../../../Common/LabelTag/LabelTag';
import MetadataForm from '../../../../Dashboard/MetadataForm/MetadataForm';
import NotesModule from '../../../../Dashboard/Notes/Notes';
import { useUpdateRefund } from '../../../../../api/payment/refunds';
import { toast } from 'react-toastify';
import {
  setRefetchRefund,
  setSelectedRefund,
} from '../../../../../store/refunds/refundSlice';
import RefundsModalLogsTable from '../RefundsTable/RefundsModalLogsTable';
import CopyToClipboardBoxID from '../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DetailRefunds from './DetailRefunds';
import RefundDetailsItems from './RefundDetailsItems';
import RefundMethod from './RefundMethod';

function RefundsModalDetails({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [showCaptureModal, setShowCaptureModal] =
    useState(false);
  const { selected_refund } = useSelector(
    (state) => state.refund
  );

  const {
    mutate: mutateUpdateRefund,
    isLoading: isUpdateLoading,
  } = useUpdateRefund(selected_refund?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleMetadataSave = (metadata) => {
    mutateUpdateRefund(
      { metadata },
      {
        onSuccess: (dataRefunds) => {
          dispatch(setSelectedRefund(dataRefunds.data));
          dispatch(setRefetchRefund(Date.now()));
          toast.success('Metadata updated');
        },
      }
    );
  };

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  const handleCaptureClick = () => {
    setShowCaptureModal(true);
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Refund
            </div>
            <CopyToClipboardBoxID
              text={selected_refund?.id}
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div className='app-payments__modal__body'>
            <div className='space-y-6'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <div className='flex items-center gap-8'>
                    <div className='app-payments__big-amount'>
                      <div>
                        <div className='app-payments__big-amount__digits text-light'>
                          {currencySymbol(
                            selected_refund.currency
                          )}
                          {currencyFormatter(
                            selected_refund.currency,
                            selected_refund.amount
                          )}{' '}
                          <span>
                            {selected_refund.currency?.toUpperCase()}
                          </span>
                        </div>
                        {selected_refund.crypto_amount && (
                          <div className='flex items-center gap-1'>
                            <img
                              src={getIconUrl(
                                selected_refund.crypto_currency.toLowerCase()
                              )}
                              alt={
                                selected_refund.crypto_currency
                              }
                            />
                            <div className='app-payments__big-amount__crypto text-light font-medium'>
                              {cryptocurrencyFormatter(
                                selected_refund.crypto_amount
                              )}{' '}
                              {
                                selected_refund.crypto_currency
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <>
                      {selected_refund.status ===
                        'under_paid' && (
                          <LabelTag color='primary'>
                            <div
                              data-for='refunds-tooltip'
                              data-tip={`This payment was underpaid by 
                  ${currencySymbol(
                                selected_refund.currency
                              )}${currencyFormatter(
                                selected_refund.currency,
                                selected_refund.amount_underpaid
                              )} 
                  amount (${currencyFormatter(
                                selected_refund.crypto_currency,
                                selected_refund.crypto_amount_underpaid
                              )} ${selected_refund.crypto_currency})`}
                              className='flex items-center gap-1'>
                              <span>Succeeded</span>
                              <FaArrowAltCircleDown className='text-danger' />
                            </div>
                          </LabelTag>
                        )}
                      {selected_refund.status ===
                        'over_paid' && (
                          <LabelTag color='primary'>
                            <div
                              data-for='refunds-tooltip'
                              data-tip={`This payment was overpaid by 
                  ${currencySymbol(
                                selected_refund.currency
                              )}${currencyFormatter(
                                selected_refund.currency,
                                selected_refund.amount_underpaid
                              )} 
                  amount (${currencyFormatter(
                                selected_refund.crypto_currency,
                                selected_refund.crypto_amount_underpaid
                              )} ${selected_refund.crypto_currency})`}
                              className='flex items-center gap-1'>
                              <span>Succeeded</span>
                              <FaArrowAltCircleUp className='text-tertiary' />
                            </div>
                          </LabelTag>
                        )}
                      {selected_refund.status ===
                        'failed' && (
                          <div
                            data-for='refunds-tooltip'
                            data-tip={
                              selected_refund.failure_message
                            }>
                            <LabelTag color='danger'>
                              Failed
                            </LabelTag>
                          </div>
                        )}
                      {selected_refund.status ===
                        'pending' && (
                          <LabelTag color='tertiary'>
                            Pending
                          </LabelTag>
                        )}
                      {selected_refund.status ===
                        'cancelled' && (
                          <LabelTag color='dark'>
                            Cancelled
                          </LabelTag>
                        )}
                      {selected_refund.status ===
                        'succeeded' && (
                          <LabelTag color='primary'>
                            Succeeded
                          </LabelTag>
                        )}
                    </>
                  </div>
                </div>
              </div>
              <div className='horizontal-divider' />
              <div className='flex flex-wrap gap-5 divide-x pb-7'>
                <div className='pl-5 pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    DATE
                  </div>
                  <div className='flex items-center'>
                    <DateIcon className='mr-1.5' />
                    <span className='app-modal-fullscreen__value'>
                      {dateFormatter(
                        selected_refund.created_at,
                        true
                      )}
                    </span>
                  </div>
                </div>
                <div className='pl-5 pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    CUSTOMER NAME
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    {selected_refund.customer?.name ? (
                      <Link
                        to={`/dashboard/customers/${selected_refund.customer?.id}`}
                        className='text-tertiary'
                        target='_blank'>
                        {selected_refund.customer?.name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className='pl-5'>
                  <div className='app-modal-fullscreen__label'>
                    SOURCE
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    <Link to={`/dashboard/payments/${selected_refund.source}`} className='text-dark'>
                      {capitalize(selected_refund.source)}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <NotesModule
              enabled={!!selected_refund}
              parent_object={selected_refund}
            />
            <DetailRefunds />
            <div className='space-y-1.5 pb-2'>
              <MetadataForm
                data={selected_refund.metadata}
                onSave={handleMetadataSave}
                loading={isUpdateLoading}
              />
            </div>
            <RefundDetailsItems
              enabled={!!selected_refund}
            />

            <RefundMethod enabled={!!selected_refund} />
            <RefundsModalLogsTable
              enabled={!!selected_refund}
            />
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
    </>
  );
}

export default RefundsModalDetails;
