import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useFetchSwap } from 'api/wallet/swaps';
import DashboardNavbar from "layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as ExportIcon } from "assets/images/icons/export.svg";
import { ReactComponent as SwapIcon } from "assets/images/icons/swap.svg";
import useTableFilters from "hooks/useTableFilters";
import DropdownFilters from "components/Common/DropdownFilters/DropdownFilters";
import { setSelectedSwap } from "store/swaps/swapsSlice";
import SwapsTable from "./SwapsTable";
import SwapDetails from "./SwapDetails";
import SwapsCreateModal from "./SwapsCreateModal";

const TABLE_FILTERS = [
  { label: "All", column: "type", value: "" },
  { label: "Instant", column: "automatic", value: false },
  { label: "Auto", column: "automatic", value: true },
];

function Swaps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selected_swap, refetch_swap } = useSelector((state) => state.swap);
  const [showDetailModal, setShowDetailsModal] = useState(false);
  const { id } = useParams();
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create"
  );
  const [refreshSwap, setRefreshSwap] = useState(false);
  const [filters, setFilters] = useState({});
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchSwap({
    onSuccess: (data) => {
      dispatch(setSelectedSwap(data));
      setRefreshSwap(false);
    },
    onError: (error) => {
      setRefreshSwap(false);
      navigate( DASHBOARD_PATH+ "/swaps");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled:  !!id && (id !== "create" && id !== "edit"), 
    params: {
      expand: [
        'balance_transaction',
        'swap_balance_transaction'
      ]
    }
  });
  // Details modal:
  const handleCloseDetailModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedSwap(null));
    navigate( DASHBOARD_PATH+ "/swaps");
  }, [dispatch, navigate]);



  useEffect(() => {

    console.log("showPriceModal", location.pathname);
    if (showCreateModal) {
      if (!location.pathname.endsWith("/create" ) ) {
            navigate(location.pathname + "/create");
      } 
    }  
  
  
  }, [showCreateModal]);
  useEffect(() => {
    setShowDetailsModal(!!selected_swap);
  }, [selected_swap]);

  useEffect(() => {
    if (!id) {
      handleCloseDetailModal();
      return;
    }

    setRefreshSwap(true);
  }, [refetch_swap, id, handleCloseDetailModal]);

  // Closes modal
  useEffect(() => {
    return () => {
      dispatch(setSelectedSwap(null));
    };
  }, [dispatch]);

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({ column: "type", value: filters?.type ?? "" });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
    );
  }, [activeFilter]);

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...(activeFilter.type ? activeFilter : null),
    };
  }, [filters, activeFilter]);

//   const handleFilterClick = (filter) => {
//     if (filter && filter.label === "All") {
//       setFilters((state) => {
//         const { status, ...rest } = state;

//         return rest;
//       });
//     }

//     setFilter(filter);
//   };

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Swaps</div>
              <span className="app-dashboard__card__header__filters">
              </span>
              <span className='app-dashboard__card__header__filters'>
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => setFilter(filter)}
                    className={clsx(
                      'app-dashboard__card__header__filters__item',
                      isFilterActive(filter) &&
                        'app-dashboard__card__header__filters__item--active'
                    )}>
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
            <DropdownFilters
                filters={[
                  "date",
                  "invoice_type",
                  "invoice_status",
                  "due_date",
                  "currency",
                  "amount_due",
                  "metadata",
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page="swaps"
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color='primary'
                outline
                icon={<SwapIcon/>}>
                <span className='text-dark'>
                  Swap
                </span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
              </div>
          </div>
          <SwapsTable filters={getFilters} />
        </div>
        {showDetailModal && (
          <SwapDetails
            show={showDetailModal}
            onClose={handleCloseDetailModal}
          />
        )}
        {showCreateModal && (
          <SwapsCreateModal
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
        <ReactTooltip
          id="invoice-modal-create"
          place="bottom"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
        </ReactTooltip>
      </div>
    </div>
  );
}

export default Swaps;
