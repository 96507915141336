import api from '../../utils/axios';
import { useMutation, useQuery } from 'react-query';
import appStore from '../../store';

const fetchPayments = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/payments', { params })
    : api.sandbox.get('/payments', { params });

export const useFetchPayments = ({
  onSuccess,
  onError,
  enabled,
  params,
}) => {
  return useQuery(
    ['fetchPayments', params],
    () => fetchPayments(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const updatePayment = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/payments/${id}`, data, {
      params: {
        expand: ["customer", "balance_transaction", "blockchain_transaction"],
      }
    })
    : api.sandbox.patch(`/payments/${id}`, data, {
      params: {
        expand: ["customer", "balance_transaction", "blockchain_transaction"],
      }
    });

export const useUpdatePayment = (id) => {
  return useMutation(['updatePayment', id], (data) =>
    updatePayment(id, data)
  );
};

const capturePayment = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.post(`/payments/${id}/capture`, data)
    : api.sandbox.post(`/payments/${id}/capture`, data);

export const useCapturePayment = (id) => {
  return useMutation(['capturePayment', id], (data) =>
    capturePayment(id, data)
  );
};
