import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as WebhookLinkIcon } from "assets/images/icons/webhook_link.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFetchEvents } from "api/event/event";
import useTablePagination from "hooks/useTablePagination";
import LabelTag from "components/Common/LabelTag/LabelTag";
import DashboardTable from "components/Dashboard/DashboardTable/DashboardTable";
import { timeForamtter, dateFormatter } from "utils/date";
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";

function EventsTable({ filters = {} }) {
  const navigate = useNavigate();
  const { refetch_webhook } = useSelector((state) => state.webhook);
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage
  } = useTablePagination(data, tableRows, filters, 15);

  useEffect(() => {
    console.log('testing state from events table', filters);
  }, [filters]);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_webhook, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchWebhooksSuccess = (data) => {
    setData(data);
    console.log(data.data);
    const tableData = data.data.map((row) => {
      return {
        event: row.type,
        id: row.id,
        created: row.created_at,
        _data: row,
      }
    });
    setTableRows(tableData);
    setRefetch(false);
  }

  const onFetchWebhooksError = () => {
    setRefetch(false);
  }

  const { isFetching: isFetchingData } = useFetchEvents({
    onSuccess: onFetchWebhooksSuccess,
    onError: onFetchWebhooksError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters
    }
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedWebhook(row._data));
    navigate(`/dashboard/events/${row.id}`);
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  }

  const tableColumns = useMemo(() => [
    {
      Header: "Event",
      accessor: "event",
      Cell: (cell) => {
        var row = cell.row.original;
        return (
          <div className=" flex items-center gap-2">
            <span>{row.event}</span>
          </div>
        );
      }
    },
    {
      Header: "Id",
      accessor: "id",
      align: "right",
      maxWidth: 100,
      Cell: (cell) => {
        var row = cell.row.original;

        return (
          <div className="app-modal-fullscreen__value">{row.id}</div>
        )
      }
    },
    {
      Header: "Created",
      accessor: "date",
      maxWidth: 50,
      Cell: (cell) => {
        var row = cell.row.original;
        return (
          <div className="flex items-center text-light font-normal gap-2" >
            <DateIcon />
            {dateFormatter(row.created)}, {" "}{timeForamtter(row.created)}
          </div>
        );
      }
    },
  ], []);

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="events"
        />
      </div>

      {/* {showDeleteModal &&
      <WebhooksModalDelete show={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
      } */}
    </>
  )
}

export default EventsTable;
