import Label from "../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../Common/Form/Input/Input";
import React from "react";
import {useSelector} from "react-redux";

function DiscountsModalCreateMaxRedemptions({ register }) {
  const { selected_discount } = useSelector((state) => state.discount);

  return (
    <div>
      <Label
        title="Maximum redemptions"
      />
      <Input
        {...register("max_redemptions")}
        block
        type="number"
        placeholder="0"
        disabled={selected_discount?.id}
      />
    </div>
  )
}

export default DiscountsModalCreateMaxRedemptions;