import {dateFormatter} from "../../../../../../../../utils/date";
import {useFetchEvents} from "../../../../../../../../api/event/event";
import {useSelector} from "react-redux";
import DashboardModalTable from "../../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import React, {useMemo, useState} from "react";
import useTablePagination from "../../../../../../../../hooks/useTablePagination";

function InvoiceDetailsEventTable({ enabled = false }) {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_invoice } = useSelector((state) => state.invoice);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage
  } = useTablePagination(data, tableRows);

  const onFetchEventsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  }

  const {
    isFetching: isFetchingData,
  } = useFetchEvents({
    onSuccess: onFetchEventsSuccess,
    onError: () => null,
    enabled,
    params: {
      parent: selected_invoice?.id,
      limit: limitQuery,
      ...requestQuery
    }
  });

  const tableColumns = useMemo(() => [
    {
      Header: "",
      accessor: "id",
      width: 60,
      Cell: (cell) => {
        return (
          <div className="app-modal-fullscreen__value">
            {cell.value}
          </div>
        )
      }
    },
    {
      Header: "",
      accessor: "created_at",
      Cell: (cell) => {
        return (
          <div className="app-modal-fullscreen__value text-right">
            {dateFormatter(cell.value, true)}
          </div>
        )
      }
    },
  ], []);

  return (
    <div className="space-y-1.5">
      <div className="app-modal-fullscreen__title">Events</div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        hideColumns
        noRecordTag="Events"
      />
    </div>
  )
}

export default InvoiceDetailsEventTable;
