import ModalFullScreen from "../../../../../Common/ModalFullScreen/ModalFullScreen";
import React, { useEffect, useState } from "react";
import CopyToClipboardBoxID from "../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { useNavigate } from "react-router-dom";
import DashboardButton from "../../../../../Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/icons/dashboard/edit.svg";
import InfoIcon from "../../../../../../assets/images/icons/info.svg";
import { simplePriceDescription } from "../../../../../../utils/price_utils";
import {
  currencyPrettier,
  snakeCaseToTitleCase,
} from "../../../../../../utils/string";
import {
  setRefetchProductPrice,
  setSelectedProductPrice,
} from "../../../../../../store/product/productSlice";
import { useDispatch, useSelector } from "react-redux";
import MetadataForm from "../../../../../Dashboard/MetadataForm/MetadataForm";
import { toast } from "react-toastify";
import {
  fetchPriceSubscriptionsCount,
  useUpdatePrice,
} from "../../../../../../api/product/price";
import ProductsModalPriceTiersTable from "./ProductsModalPriceTiersTable";
import ProductsModalPriceLogsTable from "./ProductsModalPriceLogsTable";
import ProductsModalPriceEventsTable from "./ProductsModalPriceEventsTable";

function ProductsModalPrice({ show, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected_product_price_details: price } = useSelector(
    (state) => state.product
  );
  const { selected_product_price, refetch_product_price } = useSelector((state) => state.product);

  const { mutate: mutateUpdatePrice, isLoading: isUpdateLoading } =
    useUpdatePrice(price?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  // TODO: fetchPriceSubscriptionsCount line 134
  useEffect(() => {
    // alert('hi')
    console.log(price);
  }, [selected_product_price]);

  const handleProductRedirect = () => {
    if (!price.product?.id) return;

    navigate(`/dashboard/products/${price.product.id}`);
  };

  const handleEditPrice = () => {
    dispatch(setSelectedProductPrice(price));
  };

  const handleMetadataSave = (metadata) => {
    mutateUpdatePrice(
      { metadata },
      {
        onSuccess: () => {
          dispatch(setRefetchProductPrice(Date.now()));
          toast.success("Metadata updated");
        },
      }
    );
  };

  return (
    <ModalFullScreen show={show} onClose={handleClose}>
      <ModalFullScreen.Slot name="header">
        <div className="app-customers__modal__header">
          <div className="app-modal-fullscreen__header__title">Price</div>
          <CopyToClipboardBoxID text={price?.id} />
        </div>
      </ModalFullScreen.Slot>
      <ModalFullScreen.Slot name="body">
        <div className="space-y-5">
          <div className="flex items-center">
            <div>
              <div className="app-modal-fullscreen__big-title">
                {price.description ? (
                  price.description
                ) : (
                  <>Price for {price.product.name}</>
                )}
              </div>
            </div>
          </div>
          <div className="space-y-1.5">
            <div className="flex justify-end">
              <DashboardButton
                onClick={handleEditPrice}
                outline
                color="light"
                size="sm"
                icon={<EditIcon />}
              >
                Edit
              </DashboardButton>
            </div>
            <div className="horizontal-divider" />
          </div>
          <div className="flex gap-5 flex-wrap divide-x pb-7">
            <div className="pr-5">
              <div className="app-modal-fullscreen__label">Product</div>
              <span
                onClick={handleProductRedirect}
                className="app-modal-fullscreen__value"
              >
                <span className="app-modal-fullscreen__link text-secondary">
                  {price.product.name}
                </span>
              </span>
            </div>
            <div className="pl-5">
              <div className="app-modal-fullscreen__label">Price per unit</div>
              <span className="app-modal-fullscreen__value">
                <div className="app-modal-fullscreen__table__amount pt-1">
                  <div className="text-light">
                    {simplePriceDescription(price)}
                  </div>
                </div>
              </span>
            </div>
            {price.type === "recurring" && (
              <div className="pl-5">
                <div className="app-modal-fullscreen__label">Subscriptions</div>
                <span className="app-modal-fullscreen__value">
                  <div className="app-modal-fullscreen__table__amount pt-1">
                    <div className="text-light">0 active</div>
                  </div>
                </span>
              </div>
            )}
          </div>
          <div className="space-y-1.5 pb-2">
            <MetadataForm
              data={price.metadata}
              onSave={handleMetadataSave}
              loading={isUpdateLoading}
            />
          </div>
          <div className="pb-2">
            <div className="space-y-1.5">
              <div className="app-modal-fullscreen__title">Details</div>
            </div>
            <div className="horizontal-divider" />
            <div className="pt-4">
              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-2 space-y-2">
                  <div className="app-modal-fullscreen__kv">
                    <div className="app-modal-fullscreen__kv__key">ID</div>
                    <div className="app-modal-fullscreen__kv__value">
                      {price.id}
                    </div>
                  </div>
                  {price.type === "recurring" && (
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Usage type
                      </div>
                      <div
                        className="app-modal-fullscreen__kv__value"
                        tooltip={`${
                          price.recurring.usage_type === "metered"
                            ? "Customers subscribed to this pricing plan will be billed based on their reported metered usage every period."
                            : "Customers subscribed to this pricing plan will be billed for the same set quantity every period."
                        }`}
                        maxwidth="50px"
                        icon={InfoIcon}
                      >
                        {price.recurring.usage_type === "metered"
                          ? "Metered usage"
                          : "Recurring usage"}
                      </div>
                    </div>
                  )}
                  {price.recurring?.aggregate_usage && (
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Aggregate usage
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {price.recurring.aggregate_usage === "sum"
                          ? "Sum of usage during period"
                          : price.recurring.aggregate_usage === "max"
                          ? "Maximum usage during period"
                          : price.recurring.aggregate_usage === "last_in_period"
                          ? "Last reported usage during period"
                          : "Last ever reported usage"}
                      </div>
                    </div>
                  )}
                  <div className="app-modal-fullscreen__kv">
                    <div className="app-modal-fullscreen__kv__key">
                      Currency
                    </div>
                    <div className="app-modal-fullscreen__kv__value">
                      {price.currency.toUpperCase()}
                    </div>
                  </div>
                  <div className="app-modal-fullscreen__kv">
                    <div className="app-modal-fullscreen__kv__key">
                      Interval
                    </div>
                    <div className="app-modal-fullscreen__kv__value">
                      {price.type === "recurring" ? (
                        <>
                          {price.recurring.interval_count > 1
                            ? `Every ${price.recurring.interval_count} ${price.recurring.interval}s`
                            : price.recurring.interval === "day"
                            ? "Daily"
                            : `${snakeCaseToTitleCase(
                                price.recurring.interval
                              )}ly`}
                        </>
                      ) : (
                        "One-time"
                      )}
                    </div>
                  </div>
                  <div className="app-modal-fullscreen__kv">
                    <div className="app-modal-fullscreen__kv__key">
                      Price per unit
                    </div>
                    <div className="app-modal-fullscreen__kv__value">
                      <div className="app-modal-fullscreen__table__amount">
                        {price.billing_scheme === "tiered" ? (
                          <div className="text-light">
                            Depends on quantity, see tiers below
                          </div>
                        ) : !price.unit_amount_decimal ? (
                          <div className="text-light">Customer chooses</div>
                        ) : (
                          <div className="text-light">
                            {currencyPrettier(
                              price.currency,
                              price.unit_amount_decimal
                            )}
                            {price.transform_quantity?.divide_by
                              ? ` per group of ${price.transform_quantity.divide_by}`
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {price.custom_unit_amount?.preset && (
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Preset amount
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        <div className="app-modal-fullscreen__table__amount">
                          <div className="text-light">
                            {currencyPrettier(
                              price.currency,
                              price.custom_unit_amount.preset
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {price.custom_unit_amount?.minimum && (
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Minimum amount
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        <div className="app-modal-fullscreen__table__amount">
                          <div className="text-light">
                            {currencyPrettier(
                              price.currency,
                              price.custom_unit_amount.minimum
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {price.custom_unit_amount?.maximum && (
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Maximum amount
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        <div className="app-modal-fullscreen__table__amount">
                          <div className="text-light">
                            {currencyPrettier(
                              price.currency,
                              price.custom_unit_amount.maximum
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {price.transform_quantity?.divide_by && (
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Rounding
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {`Partial quantity groups will be rounded ${price.transform_quantity.round} and billed as a complete group.`}
                      </div>
                    </div>
                  )}
                  <div className="app-modal-fullscreen__kv">
                    <div className="app-modal-fullscreen__kv__key">
                      Tax behaviour
                    </div>
                    <div className="app-modal-fullscreen__kv__value">
                      {snakeCaseToTitleCase(price.tax_behaviour)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {price.tiers && (
            <ProductsModalPriceTiersTable price={price} />
          )}

          <ProductsModalPriceLogsTable enabled={!!price} />
          <ProductsModalPriceEventsTable enabled={!!price} />
        </div>
      </ModalFullScreen.Slot>
    </ModalFullScreen>
  );
}

export default ProductsModalPrice;
