import "./Register.scss";
import Stack from "../../../components/Common/Stack/Stack";
import {Link} from "react-router-dom";
import Logo from '../../../assets/images/logo.svg';
import FacebookIcon from '../../../assets/images/social/facebook.png';
import TwitterIcon from '../../../assets/images/social/twitter.png';
import TelegramIcon from '../../../assets/images/social/telegram.png';
import CheckIcon from '../../../assets/images/icons/check.png';
import ReactTooltip from "react-tooltip";
import {useAuthRegister} from "../../../api/auth/auth";
import RegisterForm from "../../../components/Pages/Auth/RegisterForm/RegisterForm";
import ResendConfirmationEmail from "../../../components/Pages/Auth/ResendConfirmationEmail/ResendConfirmationEmail";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const registerSchema = yup.object({
  first_name: yup.string().required().label('First Name'),
  last_name: yup.string().required().label('Last   Name'),
  business_name: yup.string().required().label('Business Name'),
  email: yup.string().email().required().label('Email'),
  password: yup.string().required().label('Password'),
  country: yup.string().required().label('Country'),
}).required();

function Register() {
  const form = useForm({
    resolver: yupResolver(registerSchema)
  });
  const registerMutation = useAuthRegister();

  return (
    <div className="app-auth min-h-screen">
      <div className="app-container app-auth__container">
        <Stack columns="1" gap="16" className="lg:grid-cols-2 justify-items-center">
          <div className="app-register__left">
            <div className="app-register__logo">
              <Link to="/">
                <img src={Logo} alt="Walio" />
              </Link>
            </div>
            <Stack className="app-register__features" columns="1" gap="5">
              <div className="app-register__features__item">
                <img
                  className="app-register__features__item__icon"
                  src={CheckIcon}
                  alt="Start accepting cryptocurrency immediately"
                />
                <div>
                  <h4 className="app-register__features__item__title">Start accepting cryptocurrency immediately</h4>
                  <p className="app-register__features__item__text">
                    Create invoices, orders and payments with no coding needed through your dashboard,
                    or integrate with our developer-friendly APIs.
                  </p>
                </div>
              </div>
              <div className="app-register__features__item">
                <img
                  className="app-register__features__item__icon"
                  src={CheckIcon}
                  alt="Designed for any business model"
                />
                <div>
                  <h4 className="app-register__features__item__title">Designed for any business model</h4>
                  <p className="app-register__features__item__text">
                    Whether you are a hobby seller, e-commerce website, small or large business,
                    or app developer, we have a solution designed for you.
                  </p>
                </div>
              </div>
              <div className="app-register__features__item">
                <img
                  className="app-register__features__item__icon"
                  src={CheckIcon}
                  alt="Open your business to over 100+ million people"
                />
                <div>
                  <h4 className="app-register__features__item__title">Open your business to over 100+ million people</h4>
                  <p className="app-register__features__item__text">
                    Expand your customer or user base, with exposure of your services to over
                    100+ million cryptocurrency users globally.
                  </p>
                </div>
              </div>
            </Stack>
          </div>
          <div className="app-register__right">
            <div className="app-auth__content app-register__content">
              <div className="app-auth__card app-auth__card__register">
                {registerMutation.isSuccess
                ?
                  <ResendConfirmationEmail
                    email={form.getValues("email")}
                  />
                :
                  <RegisterForm
                    form={form}
                    mutation={registerMutation}
                  />
                }
              </div>
              <div className="app-auth__card__footer">
                <div>Have an account? <Link to="/login" className="app-auth__card__footer__link">Sign in</Link></div>
              </div>
            </div>
          </div>
        </Stack>
        <div className="app-container app-auth__footer app-register__footer">
          <div>© Walio All rights reserved.</div>
          <div className="app-auth__footer__social">
            <img src={FacebookIcon} alt="" href="https://www.facebook.com/mywalio.io/"/>
            <img src={TwitterIcon} alt="" href="https://twitter.com/walio_official"/>
            <img src={TelegramIcon} alt="" href="https://t.me/walio_official"/>
          </div>
        </div>
      </div>
      <ReactTooltip
        multiline
        effect="solid"
        type="light"
        padding="10px"
        textColor="#1A1E23"
        backgroundColor="#ffffff"
        className='api-key__tooltip'
      />
    </div>
  )
}

export default Register;
