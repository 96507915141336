import { useDispatch, useSelector } from 'react-redux';
import ModalFullScreen from '../../../../../../Common/ModalFullScreen/ModalFullScreen';
import React, { useState } from 'react';
import DashboardButton from '../../../../../../Common/Form/DashboardButton/DashboardButton';
import Dropdown from '../../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../../assets/images/icons/dashboard/more.svg';
import { ReactComponent as DateIcon } from '../../../../../../../assets/images/icons/date.svg';
import { dateFormatter } from '../../../../../../../utils/date';
import { ReactComponent as EditIcon } from '../../../../../../../assets/images/icons/dashboard/edit.svg';
import LabelTag from '../../../../../../Common/LabelTag/LabelTag';
import { toast } from 'react-toastify';
import ModalDelete from '../../../../../../Dashboard/Modals/ModalDelete/ModalDelete';
import {
  currencyFormatter,
  currencySymbol,
} from '../../../../../../../utils/string';
import {
  setRefetchShippingRate,
  setSelectedShippingRate,
  setShowShippingRateForm,
} from '../../../../../../../store/shipping_rate/shippingRateSlice';
import {
  useDeleteShippingRate,
  useUpdateShippingRate,
} from '../../../../../../../api/product/shipping_rates';
import CopyToClipboardBoxID from '../../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';

function ShippingRatesModalDetails({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] =
    useState(false);
  const { selected_shipping_rate } = useSelector(
    (state) => state.shipping_rate
  );

  console.log(
    'testing shipping rate store value in shiping rate modal',
    selected_shipping_rate
  );
  const { shipping_time = null } = selected_shipping_rate;

  const { mutate: mutateUpdateShippingRate } =
    useUpdateShippingRate(selected_shipping_rate?.id);
  const {
    mutate: mutateDeleteShippingRate,
    isLoading: isDeleteLoading,
  } = useDeleteShippingRate(selected_shipping_rate?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleArchiveClick = () => {
    mutateUpdateShippingRate(
      {
        active: !selected_shipping_rate.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setSelectedShippingRate(data.data));
          dispatch(setRefetchShippingRate(Date.now()));
          toast.success(
            `Shipping rate ${
              selected_shipping_rate.active
                ? 'archived'
                : 'unarchived'
            }`
          );
        },
      }
    );
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleModalDelete = () => {
    mutateDeleteShippingRate(null, {
      onSuccess: () => {
        setShowDeleteModal(false);
        handleClose();
        dispatch(setSelectedShippingRate(null));
        dispatch(setRefetchShippingRate(Date.now()));
        toast.success('Shipping rate deleted');
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleEditClick = () => {
    dispatch(setShowShippingRateForm(true));
  };

  const daysWord = (days) => (days > 1 ? 'days' : 'day');

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Shipping Rate
            </div>
            <CopyToClipboardBoxID
              text={selected_shipping_rate?.id}
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div className='space-y-7'>
            <div className='space-y-1'>
              {selected_shipping_rate?.description && (
                <div className='text'>
                  <span className='font-semibold'>
                    {selected_shipping_rate?.description}
                  </span>
                </div>
              )}
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <div className='flex items-center gap-3'>
                    <div className='app-modal-fullscreen__big-title'>
                      {selected_shipping_rate.amount > 0 ? (
                        <>
                          {currencySymbol(
                            selected_shipping_rate.currency
                          )}
                          {currencyFormatter(
                            selected_shipping_rate.currency,
                            selected_shipping_rate.amount
                          )}
                          <small>
                            {' '}
                            {selected_shipping_rate.currency?.toUpperCase()}
                          </small>
                        </>
                      ) : (
                        'Free'
                      )}
                    </div>
                    <LabelTag
                      color={`${
                        selected_shipping_rate.active
                          ? 'primary'
                          : 'light'
                      }`}
                      className='ml-3 mt-1'>
                      {selected_shipping_rate.active
                        ? 'Active'
                        : 'Archived'}
                    </LabelTag>
                  </div>
                </div>
                <div className='flex items-center gap-3'>
                  <DashboardButton
                    size='sm'
                    color='light'
                    disabled>
                    Copy to livemode
                  </DashboardButton>
                  <Dropdown
                    items={[
                      {
                        label: 'Delete',
                        className:
                          'text-danger font-semibold',
                        onClick: handleDeleteClick,
                      },
                      {
                        label: selected_shipping_rate.active
                          ? 'Archive'
                          : 'Unarchive',
                        onClick: handleArchiveClick,
                      },
                    ]}>
                    <Dropdown.Slot name='toggle'>
                      <MoreIcon className='cursor-pointer' />
                    </Dropdown.Slot>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className='horizontal-divider' />
            <div className='flex flex-wrap gap-5 divide-x'>
              <div className='pr-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  AMOUNT
                </div>
                <div className='app-modal-fullscreen__value'>
                  <div className='app-customers__modal__earning'>
                    <div className='text-light'>
                      {currencySymbol(
                        selected_shipping_rate.currency
                      )}
                      {currencyFormatter(
                        selected_shipping_rate.currency,
                        selected_shipping_rate.amount
                      )}
                      <span>
                        {selected_shipping_rate.currency.toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  CREATED
                </div>
                <div className='app-modal-fullscreen__value'>
                  <div className='flex items-center'>
                    <DateIcon className='mr-1.5' />
                    <span className='app-modal-fullscreen__value'>
                      {dateFormatter(
                        selected_shipping_rate.created_at
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='space-y-1.5'>
              <div className='flex justify-between'>
                <div className='app-modal-fullscreen__title'>
                  Details
                </div>
                <div className='flex gap-4'>
                  <DashboardButton
                    outline
                    color='light'
                    size='sm'
                    icon={<EditIcon />}
                    onClick={handleEditClick}>
                    Edit
                  </DashboardButton>
                </div>
              </div>
              <div className='horizontal-divider' />
              <div className='pt-4'>
                <div className='grid grid-cols-5 gap-4'>
                  <div className='col-span-2 space-y-2'>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        ID
                      </div>
                      <div className='app-modal-fullscreen__kv__value break-all'>
                        {selected_shipping_rate.id}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Amount
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        <div className='app-customers__modal__earning'>
                          <div className='text-light'>
                            {currencySymbol(
                              selected_shipping_rate.currency
                            )}
                            {currencyFormatter(
                              selected_shipping_rate.currency,
                              selected_shipping_rate.amount
                            )}
                            <span>
                              {selected_shipping_rate.currency.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Currency
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_shipping_rate.currency?.toUpperCase()}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Description
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_shipping_rate?.description ??
                          '-'}
                      </div>
                    </div>
                  </div>
                  <div className='col-span-2 space-y-2'>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Shipping time Est.
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {shipping_time ? (
// TODO: Fix this
                          <>
                            {shipping_time.to === null &&
                              `Up to ${
                                shipping_time.from
                              } business ${daysWord(
                                shipping_time.from
                              )}`}
                            {shipping_time.to !== null &&
                              shipping_time.to !==
                                shipping_time.from &&
                              `${shipping_time.from} to ${
                                shipping_time.to
                              } business ${daysWord(
                                shipping_time.to
                              )}`}
                            {shipping_time.to ===
                              shipping_time.from &&
                              `${
                                shipping_time.from
                              } business ${daysWord(
                                shipping_time.from
                              )}`}
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Status
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_shipping_rate.active
                          ? 'Active'
                          : 'Archived'}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Created
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        <div className='flex items-center'>
                          <DateIcon className='mr-1.5' />
                          <span className='app-modal-fullscreen__value'>
                            {dateFormatter(
                              selected_shipping_rate.created_at
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title='Delete Shipping Rate'
          message='Are you sure you want to delete this shipping rate?'
        />
      )}
    </>
  );
}

export default ShippingRatesModalDetails;
