import {
  currencyFormatter,
  currencySymbol,
  formatCryptoValueLength,
} from "../../../../../../../../utils/string";
import { ReactComponent as DateIcon } from "../../../../../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../../../../../utils/date";
import LabelTag from "../../../../../../../Common/LabelTag/LabelTag";
import { ReactComponent as EyeIcon } from "../../../../../../../../assets/images/icons/dashboard/eye.svg";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useTablePagination from "../../../../../../../../hooks/useTablePagination";
import { useFetchPayments } from "../../../../../../../../api/payment/payment";
import DashboardModalTable from "../../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";

function InvoiceDetailsPaymentsTable({ enabled = false }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_invoice } = useSelector((state) => state.invoice);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchPaymentsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  };

  const { isLoading: isFetchingData } = useFetchPayments({
    onSuccess: onFetchPaymentsSuccess,
    onError: () => null,
    enabled,
    params: {
      source: selected_invoice?.id,
      customer: selected_invoice?.customer?.id,
      limit: limitQuery,
      ...requestQuery,
    },
  });

  const handlePaymentViewClick = useCallback(
    (id) => {
      navigate(`/dashboard/payments/${id}`);
    },
    [navigate]
  );

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "amount",
        width: 70,
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className="flex items-start gap-2">
              <div>
                <div className="app-modal-fullscreen__value">
                  <div className="flex gap-1">
                    <div
                      className="text-dark font-semibold"
                      style={{ fontSize: "1.2rem" }}
                    >
                      {currencySymbol(cell.row.original.currency)}
                      {currencyFormatter(
                        cell.row.original.currency,
                        cell.row.original.amount
                      )}
                    </div>
                  </div>
                </div>
                {cell.row.original.crypto_amount && (
                  <div className="app-modal-fullscreen__table__amount">
                    <div className="flex items-center gap-1">
                      <img
                        className="w-4 mt-1"
                        src={getIconUrl(cell.row.original.crypto_currency)}
                        alt={cell.row.original.crypto_currency}
                        style={{ width: "1.2rem"}}
                      />
                      <div className="font-normal">
                        {formatCryptoValueLength(
                          cell.row.original.crypto_amount
                        )}
                        {/* <span
                          className="leading-6"
                          style={{ fontSize: ".825rem" }}
                        >
                          {cell.row.original.crypto_currency?.toUpperCase()}
                        </span> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-x-3">
                {row.status === "under_paid" && row.captured && (
                  <LabelTag color="primary">
                    <div
                      data-for="refunds-tooltip"
                      data-tip={`This payment was underpaid by 
                  ${currencySymbol(row.currency)}${currencyFormatter(
                        row.currency,
                        row.amount_underpaid
                      )} 
                  amount (${formatCryptoValueLength(
                    row.crypto_amount_underpaid
                  )} ${row.crypto_currency})`}
                      className="flex items-center gap-1"
                    >
                      <span>Succeeded</span>
                      <FaArrowAltCircleDown className="text-danger" />
                    </div>
                  </LabelTag>
                )}
                {row.status === "over_paid" && row.captured && (
                  <LabelTag color="primary">
                    <div
                      data-for="refunds-tooltip"
                      data-tip={`This payment was overpaid by 
                  ${currencySymbol(row.currency)}${currencyFormatter(
                        row.currency,
                        row.amount_underpaid
                      )} 
                  amount (${formatCryptoValueLength(
                    row.crypto_amount_underpaid
                  )} ${row.crypto_currency})`}
                      className="flex items-center gap-1"
                    >
                      <span>Succeeded</span>
                      <FaArrowAltCircleUp className="text-tertiary" />
                    </div>
                  </LabelTag>
                )}
                {row.status === "succeeded" && row.captured && (
                  <LabelTag color="primary">Succeeded</LabelTag>
                )}
                {["under_paid", "over_paid", "succeeded"].includes(
                  cell.value
                ) &&
                  !row.captured && <LabelTag>Uncaptured</LabelTag>}
                {row.refunded && (
                  <LabelTag color="secondary">Refunded</LabelTag>
                )}
                {row.status === "failed" && (
                  <div
                    data-for="refunds-tooltip"
                    data-tip={row.failure_message}
                  >
                    <LabelTag color="danger">Failed</LabelTag>
                  </div>
                )}
                {row.status === "pending" && (
                  <LabelTag color="tertiary">Pending</LabelTag>
                )}
                {row.status === "cancelled" && (
                  <LabelTag color="dark">Cancelled</LabelTag>
                )}
                {/*{!row.captured && <LabelTag>Uncaptured</LabelTag>}*/}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        width: 180,
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value">{cell.value ?? "-"}</div>
        ),
      },
      {
        Header: "Date",
        accessor: "created_at",
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center">
                <div>
                  <DateIcon className="mr-1.5" />
                </div>
                <span className="app-modal-fullscreen__value">
                  {dateFormatter(cell.value, true)}
                </span>
              </div>
              <div>
                <EyeIcon
                  onClick={() => handlePaymentViewClick(row.id)}
                  className="cursor-pointer"
                />
              </div>
            </div>
          );
        },
      },
    ],
    [handlePaymentViewClick]
  );

  return (
    <div className="space-y-1.5 pb-2">
      <div className="app-modal-fullscreen__title">Payments</div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Payments"
      />
    </div>
  );
}

export default InvoiceDetailsPaymentsTable;
