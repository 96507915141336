import "./Products.scss";
import MainNavbar from "../../../layouts/Main/MainNavbar/MainNavbar";
import SectionPayments from "../../../components/Pages/Products/SectionPayments/SectionPayments";
import SectionInvoice from "../../../components/Pages/Products/SectionInvoice/SectionInvoice";
import SectionSubscriptions from "../../../components/Pages/Products/SectionSubscriptions/SectionSubscriptions";
import SectionRetail from "../../../components/Pages/Products/SectionRetail/SectionRetail";
import SectionPaymentLinks from "../../../components/Pages/Products/SectionPaymentLinks/SectionPaymentLinks";
import SectionLinks from "../../../components/Pages/Products/SectionLinks/SectionLinks";
import SectionGame from "../../../components/Pages/Products/SectionGame/SectionGame";
import SectionCarbonZero from "../../../components/Pages/Products/SectionCarbonZero/SectionCarbonZero";
import SectionVolatile from "../../../components/Pages/Products/SectionVolatile/SectionVolatile";
import SectionTax from "../../../components/Pages/Products/SectionTax/SectionTax";
import SectionSwap from "../../../components/Pages/Products/SectionSwap/SectionSwap";
import SectionRewards from "../../../components/Pages/Products/SectionRewards/SectionRewards";
import SectionAdditionalFeatures from "../../../components/Pages/Products/SectionAdditionalFeatures/SectionAdditionalFeatures";
import SectionFeatures from "../../../components/Pages/Products/SectionFeatures/SectionFeatures";
import SectionPricing from "../../../components/Pages/Products/SectionPricing/SectionPricing";
import { useEffect } from "react";

function scrollToHash() {
  const id = window.location.hash.substr(1);

  if (id) {
    const anchor = document.getElementById(id);

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }
}

function Products({ contactRef }) {
  const scrollToContact = () =>
    contactRef.current.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    setTimeout(() => {
      scrollToHash();
    }, 1000);
  }, []);

  return (
    <>
      <div className="app-products-header">
        <div className="app-container h-full">
          <div className="app-products-header__illustration md:block hidden" />
          <MainNavbar />
          <div className="app-products-header__content">
            <h1 className="h1 app-products-header__headline">
              Start accepting <span>cryptocurrency payments</span> now
            </h1>
            <p className="text app-products-header__text">
              Create customer subscriptions, invoicing, order list and product
              payment links with our fully integrated platform, to make
              accepting cryptocurrency more easier and faster than ever before.
            </p>
            <div className="app-products-header__bottomline">
              Whatever your requirements, we have a solution built for you.
            </div>
          </div>
        </div>
      </div>
      <SectionPayments scrollToContact={scrollToContact} />
      <SectionInvoice scrollToContact={scrollToContact} />
      <SectionSubscriptions scrollToContact={scrollToContact} />
      <SectionRetail scrollToContact={scrollToContact} />
      <SectionPaymentLinks scrollToContact={scrollToContact} />
      <SectionLinks scrollToContact={scrollToContact} />
      <SectionGame scrollToContact={scrollToContact} />
      <SectionCarbonZero />
      <SectionVolatile />
      <SectionTax />
      <SectionSwap />
      <SectionRewards />
      <SectionAdditionalFeatures />
      <SectionFeatures />
      <SectionPricing />
    </>
  );
}

export default Products;
