const webhooks = [
	{
		'All': ['*']
	},
	{
		'Application Fee': [
			'application_fee.created',
			'application_fee.deleted',
			'application_fee.updated',
			'application_fee.refunded',
		]
	},
	{
		'Balance': [
			'balance.updated',
		]
	},
	{
		'Customer': [
			'customer.created',
			'customer.deleted',
			'customer.updated',
		]
	},
	{
		'Credit Note': [
			'credit_note.created',
			'credit_note.updated',
			'credit_note.voided',
		]
	},
	{
		'Discount': [
			'discount.created',
			'discount.deleted',
			'discount.updated',
		]
	},
	{
		'Invoice': [
			'invoice.created',
			'invoice.deleted',
			'invoice.updated',
			'invoice.paid',
			'invoice.issued',
			'invoice.uncollectible',
			'invoice.voided',
			'invoice.sent',
		]
	},
	{
		'Invoice Payment': [
			'invoice.payment.succeeded',
			'invoice.payment.failed',
			'invoice.payment.underpaid',
			'invoice.payment.overpaid',
		]
	},
	{
		'Invoice Refund': [
			'invoice.refund.created',
			'invoice.refund.succeeded',
			'invoice.refund.failed',
		]
	},
	{
		'Invoice Item': [
			'invoice_item.created',
			'invoice_item.deleted',
			'invoice_item.updated',
		]
	},
	{
		'Payment': [
			'payment.succeeded',
			'payment.failed',
			'payment.underpaid',
			'payment.overpaid',
		]
	},
	{
		'Refund': [
			'refund.created',
			'refund.succeeded',
			'refund.failed',
		]
	},
	{
		'Price': [
			'price.created',
			'price.deleted',
			'price.updated',
		]
	},
	{
		'Product': [
			'product.created',
			'product.deleted',
			'product.updated',
		]
	},
	{
		'Tax Rate': [
			'tax_rate.created',
			'tax_rate.deleted',
			'tax_rate.updated',
		]
	},
	{
		'Shipping Rate': [
			'shipping_rate.created',
			'shipping_rate.deleted',
			'shipping_rate.updated',
		]
	},
	{
		'Subscription': [
			'subscription.created',
			'subscription.updated',
			'subscription.deleted',

			'subscription.trial_will_end'
		]
	},
	{
		'Order': [
			'order.created',
			'order.deleted',
			'order.updated',
			'order.paid',
			'order.cancelled',
			'order.returned',
		]
	},
	{
		'Order Payment': [
			'order.payment.succeeded',
			'order.payment.failed',
			'order.payment.underpaid',
			'order.payment.overpaid',
		]
	},
	{
		'Order Item': [
			'order_item.created',
			'order_item.deleted',
			'order_item.updated',
		]
	},
	{
		'Order Return': [
			'order_return.created',

			'order_return.refund.created',
			'order_return.refund.succeeded',
			'order_return.refund.failed',
		]
	},
	{
		'SKU': [
			'sku.created',
			'sku.deleted',
			'sku.updated',
		]
	},
	{
		'Topup': [
			'topup.created',
			'topup.failed',
			'topup.succeeded',
		]
	},
	{
		'Payout': [
			'payout.created',
			'payout.failed',
			'payout.succeeded',
		]
	},
	{
		'Transfer': [
			'transfer.created',
		]
	},
	{
		'Wallet': [
			'wallet.created',
			'wallet.updated',
		]
	},
	{
		'Wallet Topup': [
			'wallet.topup.created',
			'wallet.topup.failed',
			'wallet.topup.succeeded',
		]
	},
	{
		'Wallet Payout': [
			'wallet.payout.created',
			'wallet.payout.failed',
			'wallet.payout.succeeded',
		]
	},
	{
		'Wallet Transfer': [
			'wallet.transfer.created',
		]
	},
];

export default webhooks;