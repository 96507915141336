import ModalFullScreen from "components/Common/ModalFullScreen/ModalFullScreen";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelTag from "components/Common/LabelTag/LabelTag";
import NotesModule from 'components/Dashboard/Notes/Notes';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import {
  capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "utils/string";
import clsx from "clsx";
import { dateFormatter } from "utils/date";
import {
  setSelectedTransaction,
} from 'store/transactions/transactionsSlice';

import CopyToClipboardBoxID from "components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { Link } from "react-router-dom";
import { typesTable } from "utils/transactions";

function TransactionDetails({ show = false, onClose = null }) {
  const { selected_transaction } = useSelector((state) => state.transaction);
  const getUrlsource = (link) => {
    var url = link.split("_");
    if (url[0] === "swap")
      return "swaps";
    else if (url[0] === "topup")
      return "topups";
    else if (url[0] === "po")
      return "payouts";
    else if (url[0] === "pay")
      return "payments";
    else if (url[0] === "tr")
      return "transfers";
    else if (url[0] === "rf")
      return "refunds";
    else if (url[0] === "lpay")
      return "link_payments";
    else if (url[0] === "afee")
      return "application_fees";
    else if (url[0] === "fr")
      return "application_fees_refunds";
  }

  const handleClose = () => {
    onClose && onClose();
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };
  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">Balance Transaction</div>
            <CopyToClipboardBoxID text={selected_transaction?.id} />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          <div className="space-y-7">
            <div className="space-y-1">
              <div style={{ padding: "10px 0" }} className="flex items-start gap-3">
                {selected_transaction.crypto ? <>
                  <img style={{ width: "40px", height: "40px" }} alt={selected_transaction?.crypto_currency} src={getIconUrl(selected_transaction?.crypto_currency)} />
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} className="app-customers__modal__balance">
                    <div style={{ lineHeight: "1em", fontSize: "40px" }} className="text-light font-semibold">
                      {cryptocurrencyFormatter(selected_transaction?.crypto_amount)}

                    </div>
                    <span style={{ fontSize: "15px" }} className="text-light">
                      ( {currencyPrettier(selected_transaction?.currency, selected_transaction?.amount)})
                    </span>
                  </div>
                  <span className="text-light ">{selected_transaction.crypto_currency.toUpperCase()}</span>
                </> : 
                  <div style={{lineHeight: "1em", fontSize: "40px"}} className="text-light font-semibold" >
                    {currencyPrettier(selected_transaction?.currency , selected_transaction?.amount)}
                  </div>
                }
                <LabelTag
                  color={clsx(
                    selected_transaction.status === "processed" && "primary",
                    selected_transaction.status === "pending" && "tertiary",
                    selected_transaction.status === "failed" && "danger",
                    selected_transaction.status === "cancelled" && "dark"
                  )}
                  className="ml-6"
                >
                  {selected_transaction.status}
                </LabelTag>
              </div>
            </div>
            <div className="horizontal-divider" />
            <div className='flex flex-wrap gap-5 divide-x pb-7'>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  DATE
                </div>
                <div className='flex items-center'>
                  <DateIcon className='mr-1.5' />
                  <span className='app-modal-fullscreen__value'>
                    {dateFormatter(
                      selected_transaction.created_at,
                      true
                    )}
                  </span>
                </div>
              </div>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  TYPE
                </div>
                <div className='app-modal-fullscreen__value'>
                  <span className='text-dark'  >
                    {capitalize(typesTable[selected_transaction.type].displayValue)}
                  </span>
                </div>
              </div>
              <div className='pl-5'>
                <div className='app-modal-fullscreen__label'>
                  REPORTING
                </div>
                <div className='app-modal-fullscreen__value'>
                  <span className='text-dark'>
                    {capitalize(typesTable[selected_transaction.type].reportingValue)}
                  </span>
                </div>
              </div>
            </div>
            <NotesModule
              enabled={!!selected_transaction}
              parent_object={selected_transaction}
            />
            <div className='space-y-1.5'>
              <div className='flex justify-between'>
                <div className='app-modal-fullscreen__title'>
                  Details
                </div>
              </div>
              <div className='horizontal-divider' />
              <div className='pt-4'>
                <div className='grid grid-cols-5 gap-4'>
                  <div className='col-span-2 space-y-2'>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Source
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>

                        {selected_transaction?.source ?
                          <Link
                            to={`/dashboard/${getUrlsource(selected_transaction?.source)}/${selected_transaction?.source}`}
                            className='text-tertiary'
                            target='_blank'>
                            {selected_transaction?.source}
                          </Link>
                          :

                          "-"

                        }
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Currency Type
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {
                          selected_transaction?.crypto ?
                            <span className='text-tertiary'>
                              Cryptocurrency
                            </span>
                            :
                            <span className='text-dark'>
                              Fiat
                            </span>
                        }

                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Amount
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {
                          selected_transaction?.crypto ?
                            <Amount
                              crypto_amount={selected_transaction.crypto_amount}
                              crypto_currency={selected_transaction.crypto_currency}
                              amount={selected_transaction.amount}
                              currency={selected_transaction.currency}
                            />
                            :
                            <span className='text-dark'>
                              {currencyPrettier(selected_transaction.currency, selected_transaction?.amount)}
                            </span>


                        }
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Net Amount
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {
                          selected_transaction?.crypto ?
                            <Amount
                              crypto_amount={selected_transaction.crypto_net}
                              crypto_currency={selected_transaction.crypto_currency}
                              amount={selected_transaction.net}
                              currency={selected_transaction.currency}
                            />
                            :
                            <span className='text-dark'>
                              {currencyPrettier(selected_transaction.currency, selected_transaction?.net)}
                            </span>
                        }
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Ending Balance
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {
                          selected_transaction?.crypto ?
                            <Amount
                              crypto_amount={selected_transaction.ending_balance}
                              crypto_currency={selected_transaction.crypto_currency}
                            />
                            :
                            <span className='text-dark'>
                              {currencyPrettier(selected_transaction.currency, selected_transaction?.ending_balance)}
                            </span>
                        }
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Fees
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {
                          selected_transaction?.crypto ?
                            <>
                              {selected_transaction.crypto_fee !== "0" ? <Amount
                                crypto_amount={selected_transaction.crypto_fee}
                                crypto_currency={selected_transaction.crypto_currency}
                                amount={selected_transaction.fee}
                                currency={selected_transaction?.currency}
                              /> : "-"}
                            </>
                            :
                            <span className='text-dark'>
                              {selected_transaction?.fee !== 0 ? currencyPrettier(selected_transaction.currency, selected_transaction?.fee) : "-"}
                            </span>
                        }
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Reporting Amount
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {
                          <span className='text-dark'>
                            {currencyPrettier(selected_transaction.reporting_currency, selected_transaction?.reporting_amount)}
                          </span>
                        }
                      </div>
                    </div>
                    {
                      selected_transaction?.swap &&
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          Auto Swap
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          {
                            <Link
                              to={`/dashboard/swaps/${selected_transaction?.swap}`}
                              className='text-tertiary'
                              target='_blank'>
                              {selected_transaction?.swap}
                            </Link>

                          }
                        </div>
                      </div>
                    }
                    {
                      selected_transaction?.payout &&
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          Auto Payout
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          {
                            <Link
                              to={`/dashboard/payouts/${selected_transaction?.payout}`}
                              className='text-tertiary'
                              target='_blank'>
                              {selected_transaction?.payout}
                            </Link>

                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {selected_transaction?.fee_details.length > 0
              && <>
                <div className='app-modal-fullscreen__title'>
                  Fee Details
                </div>
                <div className='horizontal-divider' />
              </>}
            {
              selected_transaction?.fee_details?.map((fee, index) => {
                return <div key={index} className='flex flex-wrap gap-5 divide-x pb-7'>
                  <div className='pl-5 pr-20'>
                    <div className='app-modal-fullscreen__label'>
                      AMOUNT
                    </div>
                    <div className='flex items-center'>
                      <span className='app-modal-fullscreen__value'>
                        <Amount
                          crypto_amount={fee.crypto_amount}
                          crypto_currency={fee.crypto_currency}
                          amount={fee.amount}
                          currency={fee.currency}
                        />
                      </span>
                    </div>
                  </div>
                  <div className='pl-5 pr-20'>
                    <div className='app-modal-fullscreen__label'>
                      DISCRIPTION
                    </div>
                    <div className='flex items-center'>
                      <span className='app-modal-fullscreen__value'>
                        {fee.description}
                      </span>
                    </div>
                  </div>
                </div>
              })
            }

          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {/* {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title="Delete Invoice"
          message="Are you sure you want to delete this invoice?"
        />
      )} */}
    </>
  );
}

export default TransactionDetails;


export function Amount({ crypto_currency, crypto_amount, currency, amount }) {
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };
  return (
    <div className="flex items-start gap-1">
      <img style={{ width: "15px", height: "15px" }} alt={crypto_currency} src={getIconUrl(crypto_currency)} />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: "12px" }} className="app-customers__modal__balance">
        <span style={{ lineHeight: "1em", fontSize: "13px" }} className="text-light font-semibold">
          {cryptocurrencyFormatter(crypto_amount)}
          <span style={{ fontSize: "10px" }} className="text-light ">{crypto_currency?.toUpperCase()}</span>
        </span>
        <span style={{ fontSize: "12px" }} className="text-light">
          ( {currencyPrettier(currency, amount)})
        </span>
      </div>
    </div>
  )
}
