import { currencyPrettier } from './string';

const BN = require('bignumber.js');

/**
 * Will create and return a payment link's amount.
 * @param {Object} plink The payment link object.
 * @param {Object} price The payment links first line item's price. plink.line_items.data[0].price.
 * @returns {String} A string value of the payment link's amount;
 */
const paymentLinkAmount = (plink, price) => {
  let description = '';
  if (
    !plink.computed_amounts.amount_initial &&
    !plink.computed_amounts.amount_recurring
  ) {
    if (
      plink.subscription_data &&
      plink.subscription_data.trial_period_days
    ) {
      // It is a trial;
      if (plink.computed_amounts.amount_initial) {
        // There is an intial amount with the trial period;
        // TODO: format the amount and currency symbol;
        description = `${plink.currency}${plink.computed_amounts.amount_initial} and a ${plink.subscription_data.trial_period_days} day trial`;
      } else {
        // there is no initial amount and just a trial period
        description = `${plink.subscription_data.trial_period_days} day trial`;
      }
    } else {
      // It is a payment link for a 'Customer chooses amount' price:
      if (price.custom_unit_amount.preset) {
        description = `${plink.currency}${price.custom_unit_amount.preset} (Preset)`;
      } else
        description = `Customer chooses amount - ${plink.currency.toUpperCase()}`;
    }
  } else {
    // It is for a normal purchase or to start a subscription;
    if (plink.contains_recurring_price) {
      // Payment link is for a future subscription
      if (!plink.has_metered_price) {
        if (plink.computed_amounts.amount_initial) {
          description = `${plink.currency}${plink.computed_amounts.amount_initial
            } then ${plink.currency}${plink.computed_amounts.amount_recurring
            } / ${plink.subscription_data.interval_count > 1
              ? [
                plink.subscription_data.interval_count +
                ' ' +
                plink.subscription_data.interval +
                's',
              ]
              : plink.subscription_data.interval
            }`;
        } else {
          // No initial amount
          description = `${plink.currency}${plink.computed_amounts.amount_recurring
            } / ${plink.subscription_data.interval_count > 1
              ? [
                plink.subscription_data.interval_count +
                ' ' +
                plink.subscription_data.interval +
                's',
              ]
              : plink.subscription_data.interval
            }`;
        }
      } else {
        // metered price is present;
        if (plink.computed_amounts.amount_initial) {
          description = `${plink.currency}${plink.computed_amounts.amount_initial} then `;
        }

        if (plink.computed_amounts.amount_recurring) {
          description =
            description +
            `${plink.currency}${plink.computed_amounts.amount_recurring
            } + metered usage / ${plink.subscription_data.interval_count > 1
              ? [
                plink.subscription_data.interval_count +
                ' ' +
                plink.subscription_data.interval +
                's',
              ]
              : plink.subscription_data.interval
            }`;
        } else {
          description =
            description +
            `metered usage / ${plink.subscription_data.interval_count > 1
              ? [
                plink.subscription_data.interval_count +
                ' ' +
                plink.subscription_data.interval +
                's',
              ]
              : plink.subscription_data.interval
            }`;
        }
      }
    } else {
      // Payment link is for a normal purchase
      description = `${plink.currency}${plink.computed_amounts.amount_initial}`;
    }
  }
  return description;
};


/**
 * Will generate a payment link call to action.
 * @param {String} merchantName The merchant's name, this is taken from `brandingData.display_name`.
 * @param {Object} firstItem This is the Payment Link's first Line Item. Ensure the `price` and `product` have been expanded.
 * @param {Number} numberOfItems This is the total number of Payment Link Line items.
 * @param {Number} numberOfRecurringItems This is the total number of recurring Payment Link Line Items.
 * @param {Boolean} includesTrial If this Payment Link contains a free trial. Only valid when there are `recurring` items.
 * @returns {String} The Payment Links Call To Action String;
 */
const generateCallToAction = (
  merchantName = 'Seller',
  firstItem,
  numberOfItems = 1,
  numberOfRecurringItems = 0,
  includesTrial = false
) => {
  try {
    let cta = ''; // defaults to 'empty string'

    if (firstItem.price.type === 'one_time') {
      // first item is a one time item (i.e not recurring/part of a subscription)
      if (numberOfItems < 2) {
        // If there is only 1 item and it is a 'one_time' priced item, we just display its product name
        cta = firstItem.product.name;
      } else {
        // There is more than 1 item (can be multiple 'one_time' items and 'recurring' items)
        cta = `Pay ${merchantName}`;
      }
    } else {
      // This is a recurring item.
      cta = `${includesTrial ? 'Try' : 'Subscribe to'} ${firstItem.product.name
        }${numberOfRecurringItems > 1
          ? [
            ' and ' +
            (numberOfRecurringItems - 1) +
            ' more',
          ]
          : ''
        }`;
    }

    return cta;
  } catch (e) {
    throw e;
  }
};

/**
 * Will generate a payment link recurring amount;
 * @param {Array} recurringItems An array of the Payment Links recurring prices. Ensure their `price` is expanded.
 * @param {Boolean} hasMeteredPrice If the Payment Link contains a metered price.
 * @param {Number} recurringAmount The Payment Link's recurring amount.
 * @param {String} interval The recurring interval of the future subscription. Can be one of `day`, `week`, `month` or `year`.
 * @param {Number} intervalCount The number of `interval` for the subscription. E.g One month would have the value of 1.
 * @returns {String} The Payment Links recurring amount. It can be null;
 */
const generateRecurringAmountString = (
  recurringItems = [],
  hasMeteredPrice = false,
  recurringAmount = 0,
  interval = 'month',
  intervalCount = 1
) => {
  try {
    let recurringAmountString;

    if (recurringItems.length > 0) {
      if (hasMeteredPrice) {
        if (recurringAmount > 0) {
          recurringAmountString = `Then starting at ${recurringAmount} ${intervalCount > 1
            ? [
              'every ' +
              intervalCount +
              ' ' +
              interval +
              's',
            ]
            : ['per ' + interval]
            }`;
        } else {
          // the recurring amount = 0 which means the total amount is not known.
          // This could be because there is only 1 recurring price that is metered, or multiple metered prices:
          if (recurringItems.length > 1) {
            // there is more than 1 recurring metered price:
            recurringAmountString = `Then billed ${intervalCount > 1
              ? [
                'every ' +
                intervalCount +
                ' ' +
                interval +
                's',
              ]
              : interval === 'day'
                ? 'daily'
                : [interval + 'ly']
              } based on usage`;
          } else {
            // There is only one recurring item and it is 'metered'
            let item = recurringItems[0];
            if (item.price.billing_scheme === 'tiered') {
              const firstTierUnitAmount =
                item.price.tiers[0].unit_amount_decimal;
              const firstTierFlatFee =
                item.price.tiers[0].flat_amount_decimal;

              // This is a tiering priced metered item;
              // So we describe the amount to pay based on the items first tier values;
              recurringAmountString = `Then starting at ${firstTierUnitAmount
                ? [
                  firstTierUnitAmount +
                  ' per unit' +
                  (firstTierFlatFee
                    ? [' + ' + firstTierFlatFee]
                    : ''),
                ]
                : firstTierFlatFee
                } billed ${intervalCount > 1
                  ? [
                    'every ' +
                    intervalCount +
                    ' ' +
                    interval +
                    's',
                  ]
                  : interval === 'day'
                    ? 'daily'
                    : [interval + 'ly']
                } based on usage`;
            } else {
              // It is standard recurring pricing;
              // It's value are fixed and not quantity dependant;
              recurringAmountString = `Then ${item.price.unit_amount_decimal
                } per unit billed ${intervalCount > 1
                  ? [
                    'every ' +
                    intervalCount +
                    ' ' +
                    interval +
                    's',
                  ]
                  : interval === 'day'
                    ? 'daily'
                    : [interval + 'ly']
                } based on usage`;
            }
          }
        }
      } else {
        // There is no metered price, so the next billable amount is known;
        recurringAmountString = `Then ${recurringAmount} ${intervalCount > 1
          ? [
            'every ' +
            intervalCount +
            ' ' +
            interval +
            's',
          ]
          : ['per ' + interval]
          }`;
      }
    }

    return recurringAmountString;
  } catch (e) {
    throw e;
  }
};

/**
 * Will return the computed quantity of an item, based on the settings
 * of the price provided. E.g if the price has 'transform_quantity' set, the
 * quantity will be transformed and returned, otherwise the 'initialQuantity'
 * is returned.
 * @param {Object} price A price object
 * @param {Number} initialQuantity The initial quantity of an item
 * @returns {Number} An integer - The calculated quantity for the item;
 */
const computeQuantity = (price, initialQuantity = 0) => {
  try {
    let quantity = BN(initialQuantity).toString();

    if (price && price.transform_quantity) {
      if (price.transform_quantity.round === 'up')
        quantity = BN(quantity)
          .div(price.transform_quantity.divide_by)
          .integerValue(BN.ROUND_CEIL)
          .toString();
      else
        quantity = BN(quantity)
          .div(price.transform_quantity.divide_by)
          .integerValue(BN.ROUND_FLOOR)
          .toString(); // round down;
    }

    return Number(BN(quantity).toString());
  } catch (e) {
    throw e;
  }
};

/**
 * Will return an array of billable values calculated from a price's tiers based on the supplied quantity.
 * This includes both flat fee amounts and unit amounts.
 * @param {Array} priceTiers An array of tiering values. At least 2 should be provided.
 * @param {String} tierMode The tier mode to calculate from. One of `volume` or `graduated`
 * @param {Number} quantity The quantity to calculate the amount for.
 * @returns {Object} Containing:
 * 	* `amount` - Number - The total billable amount.
 * 	* `tiers` - Array - A break down of each tier that contributed to the total amount.
 */
const previewTiers = (
  priceTiers = [],
  tierMode = 'volume',
  quantity = 0
) => {
  try {
    if (priceTiers.length < 1)
      throw new Error(
        'Provide atleast two valid pricing tiers'
      );
    let itemTotal = '0';
    let tiers = [];

    // Handle volume type tier:
    // In this method, we loop each tier to find the one that is valid;
    // When found, we calculate amount for item based on the valid tier's data;
    if (tierMode === 'volume') {
      for (let i = 0; i < priceTiers.length; i++) {
        let tierValid = false;
        if (typeof priceTiers[i].up_to === 'number') {
          // Check if we meet this tier:
          if (quantity <= priceTiers[i].up_to)
            tierValid = true;
        } else {
          // This is the highest tier and open ended quantity limit;
          tierValid = true;
        }

        if (tierValid) {
          // Unit amount for tier check:
          let tierAmount = '0';
          let unitAmount;
          let flatAmount;
          if (priceTiers[i].unit_amount_decimal) {
            tierAmount = BN(tierAmount)
              .plus(
                BN(priceTiers[i].unit_amount_decimal)
                  .times(quantity)
                  .toFixed(0)
              )
              .toFixed(0)
              .toString();
            unitAmount = priceTiers[i].unit_amount_decimal;
          }
          // Flat fee for tier check:
          if (priceTiers[i].flat_amount_decimal) {
            tierAmount = BN(tierAmount)
              .plus(priceTiers[i].flat_amount_decimal)
              .toFixed(0)
              .toString();
            flatAmount = priceTiers[i].flat_amount_decimal;
          }

          itemTotal = tierAmount;

          tiers.push({
            flat_amount: flatAmount ? flatAmount : null,
            quantity,
            tier: i + 1,
            tier_total: tierAmount,
            unit_amount: unitAmount ? unitAmount : null,
          });
          // break the loop
          break;
        }
      }
    }

    // Handle graduated type tier:
    // In this method, every tier is valid.
    // we deduct the quantity used in each period from 'quantityRemaining' after each tier.
    // if quantityRemaining = 0, then we break the loop;
    if (tierMode === 'graduated') {
      let quantityRemaining = quantity;
      let previousTier;
      for (let i = 0; i < priceTiers.length; i++) {
        let tierQuantity = 0; // the quantity to use to calculate the tiers amount

        // 1. Work out what the quantity value to use for this tier;
        if (typeof priceTiers[i].up_to === 'number') {
          // check if the quantity is greater than the tiers 'up_to' value
          if (quantity > priceTiers[i].up_to) {
            // The quantity we use for this tier is 'tier.up_to' value;
            tierQuantity = Math.min(
              quantityRemaining,
              previousTier
                ? priceTiers[i].up_to - previousTier.up_to
                : priceTiers[i].up_to
            );
          } else {
            // The quantity is NOT greater than the tiers 'up_to' value so must be <= to it;
            // quantity is the smallest of 'quantityRemaining' or 'tier.up_to';
            tierQuantity = Math.min(
              quantityRemaining,
              priceTiers[i].up_to
            );
          }
        } else {
          // This is an open ended tier (the last tier available);
          // meaning that any quantity left, will be billed at this amount;
          tierQuantity = quantityRemaining;
        }

        // 2. Unit amount for tier check:
        let tierAmount = '0';
        let unitAmount;
        let flatAmount;
        if (priceTiers[i].unit_amount_decimal) {
          tierAmount = BN(tierAmount)
            .plus(
              BN(priceTiers[i].unit_amount_decimal)
                .times(tierQuantity)
                .toFixed(0)
            )
            .toFixed(0)
            .toString();
          unitAmount = priceTiers[i].unit_amount_decimal;
        }
        // 3. Flat fee for tier check:
        if (priceTiers[i].flat_amount_decimal) {
          tierAmount = BN(tierAmount)
            .plus(priceTiers[i].flat_amount_decimal)
            .toFixed(0)
            .toString();
          flatAmount = priceTiers[i].flat_amount_decimal;
        }

        itemTotal = tierAmount;

        tiers.push({
          amount: Number(tierAmount),
          flat_amount: flatAmount ? flatAmount : null,
          quantity: tierQuantity,
          tier: i + 1,
          tier_label: previousTier
            ? `Next ${tierQuantity}`
            : `First ${tierQuantity}`,
          unit_amount: unitAmount ? unitAmount : null,
        });

        // 4. Reduce quantity remaining by what was consumed in this tier;
        quantityRemaining -= tierQuantity;

        // 5. If no quantity is remaining, then break the loop to end the calculations;
        // otherwise we continue on to the next tier (if there is one);
        if (quantityRemaining < 1) break;
        else previousTier = tiers[i]; // set previous tier for the next iteration:
      }
    }

    return { amount: Number(itemTotal), tiers };
  } catch (e) {
    throw e;
  }
};

/**
 * Computes a Payment Link Items details;
 * @param {Object} item A Payment Link Line Item. Ensure it's `price` and `product` is expanded.
 * @param {Number} quantity The Payment Link items quantity. No quantity should be passed in for metered items. This is only passed in if the user is changing quantity in the preview
 * @param {Number} customAmount The custom amount for `custom_unit_amount` prices. This is only passed in if the user is changing the amount in the preview.
 * @param {Number} trialDays The Payment Link trial days (if any).
 * @returns {Object} An object containing details for the item;
 */

const computeItem = (item, quantity = null, customAmount = null, trialDays = 0) => {
  try {
    const description = item.product.description;
    const billAtPeriodEnd =
      typeof item.bill_at_period_end === 'boolean'
        ? item.bill_at_period_end
        : false;
    let name = `${item.product.name}`;
    let itemQuantity = typeof quantity === 'number' ? quantity : item.quantity;
    let isMetered = false;
    let isRecurring = false;
    let itemAmount;
    let itemBillingDescription;
    let billingFrequency;
    let intervalCount = item.price.recurring.interval_count;
    let interval = item.price.recurring.interval;

    if (item.price.type === 'one_item') {
      if (item.product.unit_label)
        name = `${name} (per ${item.product.unit_label})`;

      if (item.price.billing_scheme === 'tiered') {
        const tierAmount = previewTiers(
          item.price.tiers,
          item.price.tiers_mode,
          itemQuantity
        );
        itemAmount = tierAmount.amount;

        const firstTierUnitAmount =
          item.price.tiers[0].unit_amount_decimal;
        const firstTierFlatFee =
          item.price.tiers[0].flat_amount_decimal;
        itemBillingDescription = `Starting at ${firstTierUnitAmount
          ? [
            firstTierUnitAmount +
            ' per unit' +
            (firstTierFlatFee
              ? [' + ' + firstTierFlatFee]
              : ''),
          ]
          : firstTierFlatFee
          }`;
      } else {
        // Only calculate non 'Customer chooses amount' items
        if (item.price.unit_amount_decimal) {
          const transformedQuantity = computeQuantity(
            item.price,
            itemQuantity
          );
          itemAmount = Number(
            BN(item.price.unit_amount_decimal)
              .times(transformedQuantity)
              .toFixed(0)
              .toString()
          );
          if (quantity > 1)
            itemBillingDescription = `${item.price.unit_amount_decimal
              } ${item.price.transform_quantity
                ? [
                  'per ' +
                  item.price.transform_quantity
                    .divide_by +
                  ' units',
                ]
                : 'each'
              }`;
        }
      }
    } else {
      // It is a recurring price;

      if (item.price.recurring.usage_type === 'licenced') {
        if (item.price.billing_scheme === 'tiered') {
          const tierAmount = previewTiers(
            item.price.tiers,
            item.price.tiers_mode,
            itemQuantity
          );

          if (trialDays > 0) {
            itemBillingDescription = `Then ${tierAmount.amount
              } / ${intervalCount > 1
                ? [intervalCount + ' ' + interval + 's']
                : interval
              }`;
          } else {
            itemAmount = billAtPeriodEnd
              ? 0
              : tierAmount.amount;

            const firstTierUnitAmount =
              item.price.tiers[0].unit_amount_decimal;
            const firstTierFlatFee =
              item.price.tiers[0].flat_amount_decimal;
            itemBillingDescription = `Starting at ${firstTierUnitAmount
              ? [
                firstTierUnitAmount +
                ' per unit' +
                (firstTierFlatFee
                  ? [' + ' + firstTierFlatFee]
                  : ''),
              ]
              : firstTierFlatFee
              }`;
          }
        } else {
          const transformedQuantity = computeQuantity(
            item.price,
            itemQuantity
          );
          const itemComputedAmount = Number(
            BN(item.price.unit_amount_decimal)
              .times(transformedQuantity)
              .toFixed(0)
              .toString()
          );

          if (trialDays > 0) {
            itemBillingDescription = `Then ${itemComputedAmount} / ${intervalCount > 1
              ? [intervalCount + ' ' + interval + 's']
              : interval
              }`;
          } else {
            itemAmount = billAtPeriodEnd
              ? 0
              : itemComputedAmount;
            if (quantity > 1)
              itemBillingDescription = `${item.price.unit_amount_decimal
                } ${item.price.transform_quantity
                  ? [
                    'per ' +
                    item.price.transform_quantity
                      .divide_by +
                    ' units',
                  ]
                  : 'each'
                }`;
          }
        }

        if (trialDays < 1) {
          billingFrequency = `Billed ${intervalCount > 1
            ? [
              'every ' +
              intervalCount +
              ' ' +
              interval +
              's',
            ]
            : interval === 'day'
              ? 'daily'
              : [interval + 'ly']
            }`;
        }
      } else {
        // It is a metered price
        if (item.price.billing_scheme === 'tiered') {
          const firstTierUnitAmount =
            item.price.tiers[0].unit_amount_decimal;
          const firstTierFlatFee =
            item.price.tiers[0].flat_amount_decimal;
          itemBillingDescription = `${trialDays > 0 ? 'Then starting' : 'Starting'
            } at ${firstTierUnitAmount
              ? [
                firstTierUnitAmount +
                ' per unit' +
                (firstTierFlatFee
                  ? [' + ' + firstTierFlatFee]
                  : ''),
              ]
              : firstTierFlatFee
            }`;
        } else {
          itemBillingDescription = `${trialDays > 0 ? 'Then ' : ''
            }${item.price.unit_amount_decimal} per ${item.price.transform_quantity
              ? [
                item.price.transform_quantity.divide_by +
                ' units',
              ]
              : 'unit'
            }`;
        }

        billingFrequency = `Billed ${intervalCount > 1
          ? [
            'every ' +
            intervalCount +
            ' ' +
            interval +
            's',
          ]
          : interval === 'day'
            ? 'daily'
            : [interval + 'ly']
          } based on usage`;
        itemQuantity = null;
        isMetered = true;
      }

      isRecurring = true;
    }

    return {
      amount: itemAmount ? Number(itemAmount) : null,
      bill_at_period_end: trialDays < 1 ? billAtPeriodEnd : false, // If trial, it wont be bill at period end
      billing_description: itemBillingDescription,
      billing_frequency: billingFrequency,
      description,
      metered_price: isMetered,
      name,
      recurring_price: isRecurring,
      quantity: typeof itemQuantity === 'number' ? quantity : null, // We return the provided quantity if itemQuantity is a number or null
      unit_bucket_size: item.price.transform_quantity ? item.price.transform_quantity.divide_by : null,
    };
  } catch (e) {
    throw e;
  }
};

// This can include pre-created prices;
// Pre created price are only for custom_unit_amount prices;
// It structure is a normal payment link line item or in the following format:
// itemStructure: {
//   id: // if No id already, set some random id.
//   custom_amount: null, // If the custom amount was changed in the preview;
//   price: {}, // a price object, an existing price object or created preview price (see previewPrice structure)
//   product: {}, // a product object, an existing product object or created for preview price (see previewProduct structure).
//   quantity: 0, // the items quantity. If the price is metered, then should be null, otherwise defaults to 1
//   quantity_adjustable: {}, // if the item quantiy is adjustable
//   quantity_preview: {}, // If the quantity was changed in the preview;
// }

// You can set 'custom_amount' and 'quantity_preview' attributes for existing line items also to generate data for the Preview.

// previewPrice: {
//   id: 'preview',
//   billing_scheme: 'per_unit',
//   currency: 'the selected currency',
//   custom_unit_amount: {
//     enabled: true, // This MUST be set
//     maximum: null, // This can be optionally set by the user.
//     minimum: null, // This can be optionally set by the user.
//     preset: null, // This can be optionally set by the user.
//   }
//   type: 'one_time',
// }
// previewProduct: {
//   id: 'preview', // must be set
//   name: 'The name of the product', // MUST be set
//   description: null // This can optionally be set by the user
// }
/**
 * Calculates a payment links computed amounts.
 * @param {Array} lineItems An array of payment link line items with their `price` and `product` populated.
 * @param {Number} trialDays If there is a trial for the payment link, the number of days the trial is for.
 * @returns {Object} An object with the computed amounts for a payment link.
 */
export const computePaymentLinkPreview = (lineItems = [], trialDays = 0) => {
  try {
    let hasOneTimePrice = false;
    let hasMeteredPrice = false;
    let numberOfRecurringItems = 0;
    let amount = '0';
    let recurringAmount = '0';
    let interval = null;
    let intervalCount;
    let items = []; // The computed items for the preview;

    for (const item of lineItems) {
      const price = item.price;
      const product = typeof item.product === 'object' ? item.product : price.product;

      const description = product.description;
      let billAtPeriodEnd = typeof item.bill_at_period_end === 'boolean'
        ? item.bill_at_period_end
        : false;
      let name = `${product.name}`;
      let itemQuantity = typeof item.quantity_preview === 'number' ? item.quantity_preview : item.quantity;
      let isMetered = false;
      let isRecurring = false;
      let itemAmount;
      let unitAmount;
      let itemBillingDescription;
      let billingFrequency;
      console.log(">>>>>", price);
      console.log(">>>>>", price.type);


      // Handle one time items
      if (price.type === 'one_time') {
        if (product.unit_label) name = `${name} (per ${product.unit_label})`;

        if (price.billing_scheme === 'tiered') {
          const tierAmount = previewTiers(
            price.tiers,
            price.tiers_mode,
            itemQuantity
          );
          itemAmount = tierAmount.amount;

          const firstTierUnitAmount = price.tiers[0].unit_amount_decimal;
          const firstTierFlatFee = price.tiers[0].flat_amount_decimal;
          itemBillingDescription = `Starting at ${firstTierUnitAmount
            ? [
              currencyPrettier(price.currency, firstTierUnitAmount) +
              ' per unit' +
              (firstTierFlatFee
                ? [' + ' + currencyPrettier(price.currency, firstTierFlatFee)]
                : ''),
            ]
            : firstTierFlatFee
            }`;
          // TODO: add tier values to display on checkout page;
        } else {
          if (price.custom_unit_amount?.enabled) {
            unitAmount = typeof item.custom_amount === 'number' ? item.custom_amount : (typeof price.custom_unit_amount.preset === 'number' ? price.custom_unit_amount.preset : '0');
            itemAmount = Number(
              BN(unitAmount)
                .times(itemQuantity)
                .toFixed(0)
                .toString()
            );
            if (itemQuantity > 1) itemBillingDescription = `${currencyPrettier(price.currency, unitAmount)} each`;
          }

          if (price.unit_amount_decimal) {
            const transformedQuantity = computeQuantity(
              price,
              itemQuantity
            );
            itemAmount = Number(
              BN(price.unit_amount_decimal)
                .times(transformedQuantity)
                .toFixed(0)
                .toString()
            );
            if (itemQuantity > 1)
              itemBillingDescription = `${currencyPrettier(price.currency, price.unit_amount_decimal)
                } ${price.transform_quantity
                  ? [
                    'per ' +
                    price.transform_quantity
                      .divide_by +
                    ' units',
                  ]
                  : 'each'
                }`;
          }
        }
        hasOneTimePrice = true;
      } else { // Handle recurring items (for subscriptions only)
        // It is a recurring price;
        numberOfRecurringItems++;
        // lets set the intervals;
        console.table(item.id);
        if (!interval) {
          interval = price.recurring.interval;
          intervalCount = price.recurring.interval_count;
        }
        if (price.recurring.usage_type === "metered") {
          hasMeteredPrice = true;
        }

        if (price.recurring.usage_type === 'licenced') {
          // Check if this item should be marked as 'bill_at_period_end=true';
          // find a metered price with the same product:
          const meteredItem = lineItems.find((obj) => obj.price.type === 'recurring' &&
            obj.price.recurring.usage_type === 'metered' && obj.product.id === item.product.id);
          if (meteredItem) billAtPeriodEnd = true;
          // ---- end of bill_at_period_end check -------

          if (price.billing_scheme === 'tiered') {
            const tierAmount = previewTiers(
              price.tiers,
              price.tiers_mode,
              itemQuantity
            );

            if (trialDays > 0) {
              itemBillingDescription = `Then ${currencyPrettier(price.currency, tierAmount.amount)
                } / ${intervalCount > 1
                  ? [intervalCount + ' ' + interval + 's']
                  : interval
                }`;
            } else {
              itemAmount = tierAmount.amount;

              const firstTierUnitAmount = price.tiers[0].unit_amount_decimal;
              const firstTierFlatFee = price.tiers[0].flat_amount_decimal;
              itemBillingDescription = `Starting at ${firstTierUnitAmount
                ? [
                  currencyPrettier(price.currency, firstTierUnitAmount) +
                  ' per unit' +
                  (firstTierFlatFee
                    ? [' + ' + currencyPrettier(price.currency, firstTierFlatFee)]
                    : ''),
                ]
                : firstTierFlatFee
                }`;

              // TODO: add tier values to display on checkout page;  
            }
          } else {
            const transformedQuantity = computeQuantity(
              price,
              itemQuantity
            );
            const itemComputedAmount = Number(
              BN(price.unit_amount_decimal)
                .times(transformedQuantity)
                .toFixed(0)
                .toString()
            );

            if (trialDays > 0) {
              itemBillingDescription = `Then ${currencyPrettier(price.currency, itemComputedAmount)} / ${intervalCount > 1
                ? [intervalCount + ' ' + interval + 's']
                : interval
                }`;
            } else {
              itemAmount = itemComputedAmount;
              if (itemQuantity > 1)
                itemBillingDescription = `${currencyPrettier(price.currency, price.unit_amount_decimal)
                  } ${price.transform_quantity
                    ? [
                      'per ' +
                      price.transform_quantity
                        .divide_by +
                      ' units',
                    ]
                    : 'each'
                  }`;
            }
          }

          if (trialDays < 1) {
            billingFrequency = `Billed ${intervalCount > 1
              ? [
                'every ' +
                intervalCount +
                ' ' +
                interval +
                's',
              ]
              : interval === 'day'
                ? 'daily'
                : [interval + 'ly']
              }`;
          }
        } else {
          // It is a metered price
          if (price.billing_scheme === 'tiered') {
            const firstTierUnitAmount = price.tiers[0].unit_amount_decimal;
            const firstTierFlatFee = price.tiers[0].flat_amount_decimal;
            itemBillingDescription = `${trialDays > 0 ? 'Then starting' : 'Starting'
              } at ${firstTierUnitAmount
                ? [
                  currencyPrettier(price.currency, firstTierUnitAmount) +
                  ' per unit' +
                  (firstTierFlatFee
                    ? [' + ' + currencyPrettier(price.currency, firstTierFlatFee)]
                    : ''),
                ]
                : firstTierFlatFee
              }`;
          } else {
            itemBillingDescription = `${trialDays > 0 ? 'Then ' : ''
              }${currencyPrettier(price.currency, price.unit_amount_decimal)} per ${price.transform_quantity
                ? [
                  price.transform_quantity.divide_by +
                  ' units',
                ]
                : 'unit'
              }`;
          }

          billingFrequency = `Billed ${intervalCount > 1
            ? [
              'every ' +
              intervalCount +
              ' ' +
              interval +
              's',
            ]
            : interval === 'day'
              ? 'daily'
              : [interval + 'ly']
            } based on usage`;
          itemQuantity = null;
          isMetered = true;
        }

        isRecurring = true;
      }

      items.push({
        amount: itemAmount ? (billAtPeriodEnd ? 0 : Number(itemAmount)) : null,
        bill_at_period_end: trialDays < 1 ? billAtPeriodEnd : false, // If trial, it wont be bill at period end
        billing_description: itemBillingDescription,
        billing_frequency: billingFrequency,
        custom_amount: typeof item.custom_amount === 'number' ? item.custom_amount : null,
        description,
        metered_price: isMetered,
        name,
        recurring_price: isRecurring,
        quantity: isMetered ? null : (typeof item.quantity === 'number' ? item.quantity : null),
        quantity_adjustable: item.quantity_adjustable,
        quantity_preview: isMetered ? null : (typeof item.quantity_preview === 'number' ? item.quantity_preview : null),
        unit_bucket_size: price.transform_quantity ? price.transform_quantity.divide_by : null,
      });

      if (price.type === 'recurring') {
        recurringAmount = BN(recurringAmount).plus(itemAmount).toFixed(0).toString();
        if (!billAtPeriodEnd && trialDays < 1 && itemAmount) {
          // this item is not being billed at the end of a period and it is not a trial;
          amount = BN(amount).plus(itemAmount).toFixed(0).toString();
        }
      } else {
        if (itemAmount) amount = BN(amount).plus(itemAmount).toFixed(0).toString();
      }
    }

    // 2) Compute the total amounts;
    const computedAmounts = {
      amount: Number(amount),// total amount due,
      amount_recurring: BN(recurringAmount).gt(0) ? Number(recurringAmount) : null,
      has_one_time_price: hasOneTimePrice, // use to display 'X amount, then $x.00 per month'
      has_metered_price: hasMeteredPrice, // display 'then starting from ...xxxx'
      interval: interval ? interval : null,  // null when no subscription
      interval_count: typeof intervalCount === 'number' ? intervalCount : null,
    }

    return {
      items,
      computed_amounts: computedAmounts,
    }
  } catch (e) {
    throw e;
  }
}

/**
 * Will generate the Payment Link Preview data;
 * @param {String} merchantName The merchant's name, this is taken from `brandingData.display_name`.
 * @param {Object} computedAmounts The Payment Link's computed amounts. This is from an existing Payment Link or generated while creating one (see plinkComputedAmounts).
 * @param {Number} trialDays The Payment Link trial days (if any), from an existing Payment Link 'subscription_data.trial_period_days' or generated when creating one.
 * @param {Array} plinkItems An Array of Payment Link line item objects, ensure their `price` and `product` are expanded.
 * @param {String} currency The Payment Links currency (either payment_link.currency or generated when creating one)
 * @returns {Object} An object containing details to display in the Payment Link Preview;
 */
export const generatePreviewData = (merchantName = 'Seller', computedAmounts, trialDays = 0, plinkItems = [], currency = 'usd') => {
  try {
    const includesTrial = computedAmounts?.interval ? true : false;
    let numberOfRecurringItems = 0;
    // Count how many recurring items:
    for (let i = 0; i < plinkItems.length; i++) {
      if (plinkItems[i].price?.type === 'recurring') numberOfRecurringItems++;
    }
    // 1) Handle Call To Action First:
    let cta = ""; // defaults to 'empty string'

    let firstItem;
    let firstItemFound = false;

    // We run a while loop to ensure we create the appropraite CTA.
    // If the first item is a 'one_time' item, and its value is 0, then we
    // need to keep looping through items to find one that is either recurring or 'one_time' with a value;
    let itemIndex = 0;
    while (!firstItemFound) {
      firstItem = plinkItems[itemIndex];

      if (firstItem) {
        if (firstItem.price.type === 'one_time') {
          // if first item is a one_time NON 'custom_unit_amount' price;
          // check the next price;
          // if same, check next price, etc...
          // if no price; revert back to first price;
          if (firstItem.price.custom_unit_amount?.enabled) {
            firstItemFound = true;
          } else {
            // it is not a custom_unit_amount price
            if (firstItem.price.billing_scheme === 'tiered') {
              // if it is a tiered item, we break the loop;
              firstItemFound = true;
            } else {
              // It's not a tiered price;
              // If it's unit amount is > 0 we break the loop;
              if (BN(firstItem.price.unit_amount_decimal).gt(0)) {
                firstItemFound = true;
              }
            }
          }
        } else {
          // It is a recurring price, we can use this instead (if conditions met);
          firstItemFound = true;
          // if (firstItem.price.billing_scheme === 'tiered') {
          //   // if it is a tiered item, we break the loop;
          //   firstItemFound = true;
          // } else {
          //     // It's not a tiered price;
          //     // If it's unit amount is > 0 we break the loop;
          //   if (BN(firstItem.price.unit_amount_decimal).gt(0)) {
          //     firstItemFound = true;
          //   }
          // }
        }
      } else {
        // We break the loop and revert back to the original first price;
        // This can happen if all items do not match the conditions in the above block;
        firstItem = plinkItems[0];
        firstItemFound = true;
      }
      itemIndex++;
    }

    if (firstItem) {
      const itemProduct = firstItem.product ?? firstItem.price.product;
      if (firstItem.price.type === 'one_time') {
        // first item is a one time item (i.e not recurring/part of a subscription)
        if (plinkItems.length < 2) {
          // If there is only 1 item and it is a 'one_time' priced item, we just display its product name
          cta = itemProduct.name;
        } else {
          // There is more than 1 item (can be multiple 'one_time' items and 'recurring' items)
          cta = `Pay ${merchantName}`;
        }
      } else {
        // This is a recurring item.
        cta = `${includesTrial ? 'Try' : 'Subscribe to'} ${itemProduct.name
          }${numberOfRecurringItems > 1
            ? [
              ' and ' +
              (numberOfRecurringItems - 1) +
              ' more',
            ]
            : ''
          }`;
      }
    }

    // 2) Handle the Amount:
    let amount = computedAmounts?.amount;
    let dueToday = true;
    let trialVal;
    let customerChooses = false;

    if (numberOfRecurringItems > 0) {
      if (computedAmounts.amount < 1) {
        if (trialDays > 0) {
          // It is a full trial (i.e nothing is due today)
          if (computedAmounts.has_one_time_price) {
            // Even though the computed amount is 0, there is a 'one_time' (or multiple) price present;
            // This may happen in 2 situations:
            //  - One time prices are 0 value;
            //  - A 'custom_unit_amount' price is present that has no preset value set;

            // It could also be a combination of the above (such as one time prices that are 0 value and a 'custom_unit_amount' price);
            amount = 0;
          } else {
            trialVal = `${trialDays} day${trialDays > 1 ? 's' : ''} free`;
            dueToday = false;
          }

        } else {
          // There is acutally nothing for the customer to pay initially
          dueToday = true;
        }
      } else {
        // There is an amount due today;
        if (trialDays > 0 && computedAmounts.has_one_time_price) {
          // There may be some one time items, which means that there is an amount due today;
          amount = Number(BN(amount).minus(computedAmounts.amount_recurring).toFixed(0).toString());
        }
      }
    } else {
      if (plinkItems.length === 1) {
        // Only 1 item, lets check if it is a customer chooses amount type:
        if (!plinkItems[0].price.unit_amount_decimal) {
          // This is a customer chooses amount type price:
          customerChooses = true;
          dueToday = false;
        }
      }
    }

    // 3) Handle the Description Tag:
    let tag = "";

    
    if (numberOfRecurringItems > 0) {
      // Lets see if there is a metered item;
      console.table(computedAmounts);

      if (computedAmounts.has_metered_price) {
        if (computedAmounts.amount_recurring > 0) {
          tag = `Then starting at ${currencyPrettier(currency, computedAmounts.amount_recurring)} ${computedAmounts.interval_count > 1
            ? [
              'every ' +
              computedAmounts.interval_count +
              ' ' +
              computedAmounts.interval +
              's',
            ]
            : ['per ' + computedAmounts.interval]
            }`;
        } else {
          // the recurring amount = 0 which means the total amount is not known.
          // This could be because there is only 1 recurring price that is metered, or multiple metered prices:
          if (numberOfRecurringItems > 1) {
            // there is more than 1 recurring metered price:
            tag = `Then billed ${computedAmounts.interval_count > 1
              ? [
                'every ' +
                computedAmounts.interval_count +
                ' ' +
                computedAmounts.interval +
                's',
              ]
              : computedAmounts.interval === 'day'
                ? 'daily'
                : [computedAmounts.interval + 'ly']
              } based on usage`;
          } else {
            // There is only one recurring item and it is 'metered'
            const item = plinkItems.find((obj) => obj.price.type === 'recurring');
            if (item.price.billing_scheme === 'tiered') {
              const firstTierUnitAmount =
                item.price.tiers[0].unit_amount_decimal;
              const firstTierFlatFee =
                item.price.tiers[0].flat_amount_decimal;

              // This is a tiering priced metered item;
              // So we describe the amount to pay based on the items first tier values;
              tag = `Then starting at ${firstTierUnitAmount
                ? [
                  currencyPrettier(currency, firstTierUnitAmount) +
                  ' per unit' +
                  (firstTierFlatFee
                    ? [' + ' + currencyPrettier(currency, firstTierFlatFee)]
                    : ''),
                ]
                : currencyPrettier(currency, firstTierFlatFee)
                } billed ${computedAmounts.interval_count > 1
                  ? [
                    'every ' +
                    computedAmounts.interval_count +
                    ' ' +
                    computedAmounts.interval +
                    's',
                  ]
                  : computedAmounts.interval === 'day'
                    ? 'daily'
                    : [computedAmounts.interval + 'ly']
                } based on usage`;
            } else {
              // It is standard recurring pricing;
              // It's value are fixed and not quantity dependant;
              tag = `Then ${currencyPrettier(item.price.currency, item.price.unit_amount_decimal)
                } per unit billed ${computedAmounts.interval_count > 1
                  ? [
                    'every ' +
                    computedAmounts.interval_count +
                    ' ' +
                    computedAmounts.interval +
                    's',
                  ]
                  : computedAmounts.interval === 'day'
                    ? 'daily'
                    : [computedAmounts.interval + 'ly']
                } based on usage`;
            }
          }
        }
      } else {
        // There is no metered price, so the next billable amount is known;
        tag = `Then ${currencyPrettier(currency, computedAmounts.amount_recurring)} ${computedAmounts.interval_count > 1
          ? [
            'every ' +
            computedAmounts.interval_count +
            ' ' +
            computedAmounts.interval +
            's',
          ]
          : ['per ' + computedAmounts.interval]
          }`;
      }
    } else {
      if (plinkItems.length === 1) {
        const itemProduct = firstItem.product ?? firstItem.price.product;
        if (itemProduct.description) tag = itemProduct.description;
      }
    }

    return {
      amounts: {
        amount: currencyPrettier(currency, amount),
        custom_chooses_amount: customerChooses, // Should display a text box to enter a value
        due_today: dueToday, // If it should say 'due today'
        trial_value: trialVal ?? null, // If it is a full trial (i.e nothing due at all)
      },
      call_to_action: cta,
      tag,
    }

  } catch (e) {
    throw e;
  }
}