import React, { useEffect, useMemo, useState, useRef} from 'react';
import InputTable from 'components/Common/inputTable/InputTable';
import { useSelector } from 'react-redux';
import EditableCell from 'components/Common/inputTable/EditableCell';
import Dropdown from '../../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as EyeIcon } from '../../../../../../../assets/images/icons/dashboard/eye.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/images/icons/dashboard/delete.svg';
import LabelEditableCell from '../../../../../../Common/inputTable/LabelEditableCell';
import { currencySymbol, inputPricePrettier } from '../../../../../../../utils/string';
import { FIAT_CURRENCIES_SELECT } from '../../../../../../../utils/constants';
import Select from '../../../../../../Common/Form/Select/Select';
import TierPreview from './TierPreview';
import './style.scss';
import { calculateAmount, PreviewTiers } from 'utils/price_utils';
import styled from 'styled-components';
import useOutsideClick from 'hooks/useOutsideClick';

const MAX_TIERS = 250;
const TieredPricingTable = ({
  formMethods,
  fieldName,
  tiers,
  tierFields,
  tierAppend,
  tierRemove,
  tierUpdate,
  currency,
  setCurrency,
  tiers_mode,
  disabled = false
}) => {
  const previewRef = useRef(null);

  useEffect(() => {
    // console.log('tierFields -> ', tierFields);
  }, [tierFields]);

  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  const handleCurrencyChange = (currencyOption) => {
    const selectedCurrency = currencyOption.value;
    setCurrency(selectedCurrency);
    tiers.forEach((item, index) => {
      tierUpdate(index, {
        ...tiers[index],
        currency: selectedCurrency,
      });
    });
  };

  const handleAddTableRowClick = async() => {
    if (tiers.length >= MAX_TIERS) return;

    if (!tiers[tiers.length - 1]) throw new Error('Tier init required');

    else {
      // Update Original Last Tier:
      var lastTier = tiers[tiers.length - 1];
      var value = tiers[tiers.length - 1].first_unit + 1

      tierUpdate(tiers.length - 1, {
        ...lastTier,
        last_unit : value,
      });
      // Then create newly added tier:
      tierAppend( {
        first_unit: value + 1,
        last_unit: "inf",
        unit_amount: "",
        flat_amount: "",
      });
      // tierUpdate(tiers.length - 1, {
      //   ...lastTier,
      //   last_unit : value,
      //   unit_amount : "",
      //   flat_amount :"",

      // });
      // tierAppend( {
      //   ...lastTier,
      //   first_unit : value + 1,
      // });
    }
  };

  const RemoveTier = (index) => {
    if (disabled) return;
    const tier = tiers[index];
    if (index !== tiers.length - 1) {
      tierUpdate(index + 1, {
        ...tiers[index + 1],
        first_unit : tier.first_unit
      })    
    } else {
      tierUpdate(index - 1, {
        ...tiers[index - 1],
        last_unit : "inf"
      })
    }

    tierRemove(index)
  };

  const tierUpdateTierQuantity = (
    index = 0,
    quantity = 0
  ) => {
    if (tiers.length < 1)
      throw new Error('Tier init required');
    // Validate the tier index exists:
    if (!tiers[index])
      throw new Error('Tier does not exist');
    else {
      // Check the quantity they are setting is valid (i.e must be greater than or equal to the 'first_unit' value);

      if (quantity < tiers[index].first_unit)
        throw new Error(
          `Tier quantity must be greater than ${tiers[index].first_unit}`
        );
      else {
        // We can set the new quantity
        tiers[index].last_unit = quantity;

        // now adjust any further tiers 'first_unit', and if needed, their 'first_unit' values;
        let lastTierValidation = tiers[index];
        for (let i = index + 1; i < tiers.length; i++) {
          if (tiers[i]) {
            // tierUpdate tiers 'first_unit' value if needed;
            if (
              tiers[i].first_unit <
              lastTierValidation.last_unit
            ) {
              tiers[i].first_unit =
                lastTierValidation.last_unit + 1;
            }
            // Now check if the 'last_unit' value needs to be tierUpdated;
            if (tiers[i].last_unit < tiers[i].first_unit)
              tiers[i].last_unit = tiers[i].first_unit + 1;
          }
          lastTierValidation = tiers[i];
        }
      }
    }
  };

  // Will return an array of tier errors (if any);
  const  validateTiers = (tierData) => {
    // Array containing all errors:
    let tierErrors = [];

    for (let i = 0; i < tierData.length; i++) {
      // 1. validate 'last_unit' is => 'first_unit'
      if (tierData[i].last_unit !== 'inf' &&
      tierData[i].last_unit < tierData[i].first_unit) {
          tierErrors.push({index: i, field: 'last_unit', message: `Must be greater than or equal to ${tierData[i].first_unit}`});
      }
      if (!tierData[i].unit_amount && !tierData[i].flat_amount) {
        tierErrors.push({index: i, field: 'unit_amount', message: `Requires at least one Per Unit or Flat Fee value`});
      }
    }

    return tierErrors;
  };

  function validateNumber(event) {
    var key =event.charCode;
    if (key >= 48 &&  key <= 57) {
        return false;
      }
      return true;
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'tier_mood',
        accessor: 'tier_mood',
        Cell: (cell) => {
          if (tiers_mode === 'graduated') {
            if (cell.row.index === 0) {
              return <div>FOR THE FIRST</div>;
            } else {
              return <div>FOR THE NEXT</div>;
            }
          } else if (tiers_mode === 'volume') {
            return <div>TOTAL UNITS</div>;
          }
        },
      },
      {
        Header: 'FIRST UNIT',
        accessor: 'first_unit',
        Cell: (cell) => <div style={{width : "100%",textAlign: "start" , padding: "2px"}}>{cell.value}</div>,
      },
      {
        Header: 'LAST UNIT',
        accessor: 'last_unit',
        Cell: (cell)=> EditableCell({
          ...cell, 
          last : "editable-cell-input-last",
          disabled,
          placeholder: '0', 
          change:async (e)  => {
            var val = e.target.value;
            tierUpdate(cell.row.index , {
              ...tiers[cell.row.index],
              last_unit: val,
            });
            var d = document.getElementsByClassName("editable-cell-input-last");
            d[cell.row.index].focus();  
          },
          onKeyPress :(e)=>{
            if (validateNumber(e)) e.preventDefault()
          },
          checkerror : async(e)=>{
            if (e.target.value === "")
    
            {
              await tierUpdate(cell.row.index  , {
                ...tiers[cell.row.index  ],
                last_unit: tiers[cell.row.index].first_unit + 1 ,
              });
            }
            var d = document.getElementsByClassName("editable-cell-input-last");
            var errorsElm = document.getElementsByClassName("editable-cell-input-last-error");
            if (e.target.value === "" || parseInt(e.target.value) < tiers[cell.row.index].first_unit) {
              d[cell.row.index].classList.add("warning");
              errorsElm[cell.row.index].classList.add("show");
              errorsElm[cell.row.index].innerHTML = "Must be greater than or equal to " + tiers[cell.row.index].first_unit;
            } 
            else 
            {
              errorsElm[cell.row.index].classList.remove("show");
              errorsElm[cell.row.index].innerHTML = "" ;
              d[cell.row.index].classList.remove("warning"); 
            }
          }
        })
      },
      {
        Header: 'PER UNIT',
        accessor: 'unit_amount',
        Cell: (cell)=> LabelEditableCell({
          ...cell,
          last: "editable-cell-input",
          disabled,
          change: async(e)=>{
            var i = cell.row.index;
            await tierUpdate(i ,{...tiers[i] ,
              unit_amount: inputPricePrettier(currency, e.target.value, true)
            })
            var d = document.getElementsByClassName("editable-cell-input");


          },
          label :currency,
          
          // pattern={fiatCurrencies[currency].zero_decimal ? "^\d*(\.\d{0,12})?$" : "^\d*(\.\d{0,14})?$"}
          onKeyPress:(event) => {
            if (!/[0-9]/.test(event.key)  ) {
              event.preventDefault();
            }}
            }
        ),
      },
      {
        Header: 'FLAT FEE',
        accessor: 'flat_amount',
        Cell: (cell) => (
          <LabelEditableCell

            // label={currencySymbol(
            //   currency
            // )}
            {...cell}
            disabled={disabled}
          />
        ),
      },
      {
        Header: 'x',
        accessor: 'icon',
        Cell: (cell) => {
          if (cell.row.index === 0 || disabled) {
            return <div></div>;
          } else {
            return (
              <div className='self-start cursor-pointer'>
                <span
                  onClick={() =>
                    RemoveTier(cell.row.index)
                  }>
                  X
                </span>
              </div>
            );
          }
        },
      },
    ],
    [tiers_mode, tierFields, tierUpdate, tiers, RemoveTier, currency]
  );

  return (
    <div className='disableSelection'>
      <div className='flex justify-between  '>
        <div className='mt-1'>
          <Select
            className='app-select__currency-input' //'z-30'
            value={currency}
            disabled={disabled}
            options={FIAT_CURRENCIES_SELECT}
            onSelect={handleCurrencyChange}
            left={true}
          />
        </div>
        <div className='app-tax_rates__button-link flex justify-end mt-2'>
          {!disabled && tiers.length < MAX_TIERS && (
          <div id="addTier" 
          onClick={handleAddTableRowClick}
          >
            {tiers.length > 2
              ? '+ Add another Tier'
              : '+ Add a Tier'}
          </div>)}
          <TierPreview
            previewRef={previewRef} 
            currency={currency} 
            tiers={tiers} 
            tiers_mode={tiers_mode}
          />

          {/* <div  onClick={(e)=>{
            previewRef?.current.classList.toggle("show")
          }}
              className='app-tax_rates__button-link flex mx-2'>
            <EyeIcon
              // onClick={() =>
              //   handlePaymentViewClick(cell.row.original.id)
              // }
              className='cursor-pointer app-tax_rates__button-link mx-1 mt-1'
            />
          
            <div className='pre-cont' >
              <span 
                
                name='toggle'
                style={{position: "relative"}}
                className='cursor-pointer'>
                Preview
              </span>
  
              <Preview previewRef={previewRef} preview={preview} setpreview={(e)=>setpreview(e)} currency={currency} tiers={tiers} tiers_mode={tiers_mode}/>
            
            </div>
          </div> */}
        </div>
      </div>

      <div style={{marginTop: "0.5rem"}}>
        <InputTable
          removeTier = {RemoveTier}
          columns={tableColumns}
          formMethods={formMethods}
          fieldName={fieldName}
          data={tiers}
          tierUpdateMyData={null}
          skipPageReset={true}
          tiers_mode={tiers_mode}
          currency={currency}
          tierUpdate={tierUpdate}
          tierAppend={tierAppend}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TieredPricingTable;
