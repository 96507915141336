import { createSlice } from '@reduxjs/toolkit'

export const discountSlice = createSlice({
  name: 'discount',
  initialState: {
    selected_discount: null,
    refetch_discount: null,
    show_discount_form: false,
    form_schema_discount: {
      behavior: "amount_off",
      type: "standard",
    }
  },
  reducers: {
    setSelectedDiscount: (state, payload) => {
      state.selected_discount = payload.payload
    },
    setRefetchDiscount: (state, payload) => {
      state.refetch_discount = payload.payload
    },
    setFormSchemaDiscount: (state, payload) => {
      state.form_schema_discount = payload.payload
    },
    resetFormSchemaDiscount: (state) => {
      state.form_schema_discount = {
        behavior: "amount_off",
        type: "standard",
      }
    },
    setShowDiscountForm: (state, payload) => {
      state.show_discount_form = payload.payload
    },
  },
})

export const {
  setSelectedDiscount,
  setRefetchDiscount,
  setFormSchemaDiscount,
  resetFormSchemaDiscount,
  setShowDiscountForm,
} = discountSlice.actions

export default discountSlice.reducer
