import "./InputGroup.scss";
import clsx from "clsx";
import {forwardRef} from "react";

const Input = forwardRef(({
  type = "text",
  placeholder = "",
  className = "",
  size = null,
  block = false,
  error = "",
  append = null,
  prepend = null,
  ...rest
}, ref) => {
  return (
    <>
      <div className={clsx(
        className,
        'app-input-group',
        prepend && 'app-input-group__prepend',
        append && 'app-input-group__append',
      )}>
        {prepend && <div className="app-input-group__prepend__item">
          <div>{prepend}</div>
          <div className="app-input-group__divider" />
        </div>}
        <input
          ref={ref}
          className={clsx(
            'app-input-group__input placeholder-white',
            block && 'app-input-group__input--block',
            size && `app-input-group--${size}`,
            error && 'app-input-group--error',
          )}
          type={type}
          placeholder={placeholder}
          {...rest}
        />
        {append &&
        <div className="app-input-group__append__item">
          <div className="app-input-group__divider" />
          <div>{append}</div>
        </div>
        }
      </div>
      {error && <p className="app-input__help">{error}</p>}
    </>
  )
});

export default Input;
