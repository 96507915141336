import { createSlice } from '@reduxjs/toolkit';

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    selected_invoice: null,
    selected_invoice_customer: null,
    draft_invoice: null,
    selected_invoice_item: null,
    select_one_time: false,
    select_new_product: false,
    refetch_invoice: null,
    refetch_invoices: null,
    refetch_invoice_items: null,
    show_products_select: false,
    invoice_currency: '',
    invoice_accepted_cryptos: [],
    invoice_custom_fields: [],
    invoice_line_items: [],
    search_product_input: '',
    edit_invoice_item: null,
    show_create_invoice: false,
    invoice_now_currencies: [],
    refetch_pending_items: null,
  },
  reducers: {
    setSelectedInvoice: (state, payload) => {
      state.selected_invoice = payload.payload;
    },
    setSelectedInvoiceCustomer: (state, payload) => {
      state.selected_invoice_customer = payload.payload;
    },
    setDraftInvoice: (state, payload) => {
      console.log('setDraftInvoice', payload);
      state.draft_invoice = payload.payload;
    },
    setSelectedInvoiceItem: (state, payload) => {
      state.selected_invoice_item = payload.payload;
    },
    setSelectOneTime: (state, payload) => {
      state.select_one_time = payload.payload;
    },
    setSelectNewProduct: (state, payload) => {
      state.select_new_product = payload.payload;
    },
    setRefetchInvoice: (state, payload) => {
      state.refetch_invoice = payload.payload;
    },
    setRefetchInvoices: (state, payload) => {
      state.refetch_invoices = payload.payload;
    },
    setRefetchInvoiceItems: (state, payload) => {
      state.refetch_invoice_items = payload.payload;
    },
    setShowProductsSelect: (state, payload) => {
      state.show_products_select = payload.payload;
    },
    setInvoiceCurrency: (state, payload) => {
      state.invoice_currency = payload.payload;
    },
    setInvoiceAcceptedCryptos: (state, payload) => {
      state.invoice_accepted_cryptos = payload.payload;
    },
    setInvoiceCustomFields: (state, payload) => {
      state.invoice_custom_fields = payload.payload;
    },
    setInvoiceLineItems: (state, payload) => {
      state.invoice_line_items = payload.payload;
    },
    setSearchProductInput: (state, payload) => {
      state.search_product_input = payload.payload;
    },
    setEditInvoiceItem: (state, payload) => {
      state.edit_invoice_item = payload.payload;
    },
    setShowCreateInvoice: (state, payload) => {
      state.show_create_invoice = payload.payload;
    },
    setInvoiceNowCurrencies: (state, payload) => {
      state.invoice_now_currencies = payload.payload;
    },
    setRefetchPendingItems: (state, payload) => {
      state.refetch_pending_items = payload.payload;
    },
  },
});

export const {
  setSelectedInvoice,
  setSelectedInvoiceCustomer,
  setDraftInvoice,
  setSelectedInvoiceItem,
  setSelectOneTime,
  setSelectNewProduct,
  setRefetchInvoice,
  setRefetchInvoices,
  setRefetchInvoiceItems,
  setShowProductsSelect,
  setInvoiceCurrency,
  setInvoiceAcceptedCryptos,
  setInvoiceCustomFields,
  setInvoiceLineItems,
  setSearchProductInput,
  setEditInvoiceItem,
  setShowCreateInvoice,
  setInvoiceNowCurrencies,
  setRefetchPendingItems,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
