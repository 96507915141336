import "./Switch.scss";
import {forwardRef} from "react";
import clsx from "clsx";

const Switch = forwardRef(({
   label = "",
   color = "",
   className = "",
   ...rest
 }, ref) => {
  return (
    <label className={className}>
      <div
        className={clsx(
          "app-switch",
          color && `app-switch--${color}`
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          {...rest}
        />
        <span className="app-switch__slider" />
      </div>
      {label}
    </label>
  )
});

export default Switch;
