import { createSlice } from '@reduxjs/toolkit';

export const topupSlice = createSlice({
  name: 'topup',
  initialState: {
    selected_topup: null,
    refetch_topup: null,
  },
  reducers: {
    setSelectedTopup: (state, payload) => {
      state.selected_topup = payload.payload;
    },
    setRefetchTopup: (state, payload) => {
      state.refetch_topup = payload.payload;
    },
  },
});

export const {
  setSelectedTopup,
  setRefetchTopup
} = topupSlice.actions;

export default topupSlice.reducer;
