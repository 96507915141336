import appStore from "../../store";
import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";

const fetchCustomerBalanceTransactions = (id, params) => appStore.getState().app.livemode
  ? api.live.get(`/customers/${id}/balance_transactions`, { params })
  : api.sandbox.get(`/customers/${id}/balance_transactions`, { params });

export const useFetchCustomerBalanceTransactions = ({onSuccess, onError, enabled, id, params = null}) => {
  return useQuery(
    ["fetchCustomerBalanceTransactions", params],
    () => fetchCustomerBalanceTransactions(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storeCustomerBalanceTransaction = (id, data) => appStore.getState().app.livemode
  ? api.live.post(`/customers/${id}/balance_transactions`, data)
  : api.sandbox.post(`/customers/${id}/balance_transactions`, data);

export const useStoreCustomerBalanceTransaction = (id) => {
  return useMutation(
    ["storeCustomerBalanceTransaction", id],
    (data) => storeCustomerBalanceTransaction(id, data)
  );
}