import DashboardTable from "../../../../../Dashboard/DashboardTable/DashboardTable";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as DateIcon } from "../../../../../../assets/images/icons/date.svg";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../../../../../utils/date";
import { snakeCaseToTitleCase } from "../../../../../../utils/string";
import { useFetchTaxRates } from "../../../../../../api/product/tax_rate";
import useTablePagination from "../../../../../../hooks/useTablePagination";
import CopyToClipboardBoxID from "../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { useNavigate } from "react-router-dom";

function TaxRatesTable({ filters = {} }) {
  const navigate = useNavigate();
  const { refetch_tax_rate } = useSelector((state) => state.tax_rate);
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, refetch_tax_rate, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchTaxRatesSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        type: row.type,
        region: row.country,
        state: row.state,
        description: row.description,
        rate: row.percent,
        created: dateFormatter(row.created_at),
        active: row.active,
        inclusive: row.inclusive,
        display_name: row.display_name,
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchTaxRatesError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchTaxRates({
    onSuccess: onFetchTaxRatesSuccess,
    onError: onFetchTaxRatesError,
    enabled: refetch,
    params: {
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedTaxRate(row._data));
    navigate(`/dashboard/tax_rates/${row.id}`);
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 110,
        Cell: (cell) => {
          return (
            <div
              className="font-normal break-all"
              onClick={(e) => e.stopPropagation()}
            >
              <CopyToClipboardBoxID text={cell.value} index={cell.row.index} />
            </div>
          );
        },
      },
      {
        Header: () => <span className="w-full text-right">Type</span>,
        accessor: "display_name",
        width: 40,
        Cell: (cell) => {
          return (
            <span className="w-full text-right">
              {snakeCaseToTitleCase(cell.value ?? "-")}
            </span>
          );
        },
      },
      {
        Header: "Region",
        accessor: "region",
        width: 55,
        Cell: (cell) => {
          return (
            <span>
              {cell.value
                ? `${cell.value}${
                    cell.row.original.state
                      ? `, ${cell.row.original.state}`
                      : ""
                  }`
                : "-"}
            </span>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        width: 160,
        Cell: (cell) => {
          return <span>{cell.value ?? "-"}</span>;
        },
      },
      {
        Header: () => <span className="w-full text-right">Rate</span>,
        accessor: "rate",
        width: 55,
        Cell: (cell) => {
          const inclusive = cell.row.original.inclusive;
          return (
            <span className="w-full text-right">
              <span className="font-semibold">{cell.value} %</span>
              {inclusive ? " (incl.)" : ""}
            </span>
          );
        },
      },
      {
        Header: "Created",
        accessor: "created",
        width: 50,
        Cell: (cell) => {
          return cell.value ? (
            <div className="flex items-center">
              {cell.value && <DateIcon className="mr-1.5" />}
              <span className="text-light">{cell.value}</span>
            </div>
          ) : (
            "-"
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-6">
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Tax Rates"
        />
      </div>
    </>
  );
}

export default TaxRatesTable;
