import { useDispatch, useSelector } from 'react-redux';
import ModalFullScreen from '../../../../../Common/ModalFullScreen/ModalFullScreen';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ImagePlaceholder } from '../../../../../../assets/images/dashboard/product-placeholder.svg';
import DashboardButton from '../../../../../Common/Form/DashboardButton/DashboardButton';
import Dropdown from '../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../assets/images/icons/dashboard/more.svg';
import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { dateFormatter } from '../../../../../../utils/date';
import { ReactComponent as EditIcon } from '../../../../../../assets/images/icons/dashboard/edit.svg';
import { capitalize } from '../../../../../../utils/string';
import {
  useDeleteProduct,
  useUpdateProduct,
} from '../../../../../../api/product/product';
import {
  setDeleteProductPrice,
  setEditProduct,
  setRefetchProduct,
  setRefetchProductPrice,
  setSelectedProduct,
} from '../../../../../../store/product/productSlice';
import { toast } from 'react-toastify';
import LabelTag from '../../../../../Common/LabelTag/LabelTag';
import ModalDelete from '../../../../../Dashboard/Modals/ModalDelete/ModalDelete';
import MetadataForm from '../../../../../Dashboard/MetadataForm/MetadataForm';
import ProductDetailsPricingTable from './ProductDetails/ProductDetailsPricingTable';
import CopyToClipboardBoxID from '../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import { useDeletePrice } from '../../../../../../api/product/price';
import ProductsModalDetailsLogsTable from './ProductsModalDetailsLogsTable';
import ProductsModalDetailsEventsTable from './ProductsModalDetailsEventsTable';
import 'lightgallery.js/dist/css/lightgallery.css';
import {
  LightgalleryProvider,
  LightgalleryItem,
} from 'react-lightgallery';
import PaymentLinksModalCreate from 'components/Pages/Dashboard/Payments/PaymentLinksModal/PaymentLinksModalCreate';

function ProductsModalDetails({
  show = false,
  onClose = null,
  setShowPriceModal,
}) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] =
    useState(false);
  const [showDeletePriceModal, setShowDeletePriceModal] =
    useState(false);
  const { selected_product, delete_product_price } =
    useSelector((state) => state.product);

  const [showCreateModal, setShowCreateModal] =
    useState(false);

  const {
    mutate: mutateUpdateProduct,
    isLoading: isUpdateLoading,
  } = useUpdateProduct(selected_product?.id);
  const {
    mutate: mutateDeleteProduct,
    isLoading: isDeleteLoading,
  } = useDeleteProduct(selected_product?.id);
  const {
    mutate: mutateDeletePrice,
    isLoading: isDeletePriceLoading,
  } = useDeletePrice(delete_product_price?.id);

  useEffect(() => {
    setShowDeletePriceModal(!!delete_product_price);
  }, [delete_product_price]);

  const handleClose = () => {
    onClose && onClose();
  };

  const [basePrice, setBasePrice] = useState(null);

  const handleArchiveClick = () => {
    mutateUpdateProduct(
      {
        active: !selected_product.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setSelectedProduct(data.data));
          dispatch(setRefetchProduct(Date.now()));
          toast.success(
            `Product ${data.data.active ? 'unarchived' : 'archived'
            }`
          );
        },
      }
    );
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleModalDelete = () => {
    mutateDeleteProduct(null, {
      onSuccess: () => {
        setShowDeleteModal(false);
        handleClose();
        dispatch(setSelectedProduct(null));
        dispatch(setRefetchProduct(Date.now()));
        toast.success(`Product deleted successfully`);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleEditClick = () => {
    dispatch(setEditProduct(selected_product));
  };

  const handleMetadataSave = (metadata) => {
    mutateUpdateProduct(
      { metadata },
      {
        onSuccess: (data) => {
          dispatch(setSelectedProduct(data.data));
          dispatch(setRefetchProduct(Date.now()));
          toast.success('Metadata updated');
        },
      }
    );
  };

  const handlePriceDelete = () => {
    mutateDeletePrice(null, {
      onSuccess: () => {
        toast.success('Price deleted successfully');
        dispatch(setDeleteProductPrice(null));
        dispatch(setRefetchProductPrice(Date.now()));
        setShowDeletePriceModal(false);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handlePriceDeleteModalClose = () => {
    setShowDeletePriceModal(false);
    dispatch(setDeleteProductPrice(null));
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Product
            </div>
            <CopyToClipboardBoxID
              text={selected_product?.id}
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div className='space-y-7'>
            <div className='space-y-1'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <div className='app-products__modal__header__image'>
                    {selected_product.image_url.length ? (
                      <img
                        src={selected_product.image_url[0]}
                        alt='Product'
                      />
                    ) : (
                      <ImagePlaceholder />
                    )}
                  </div>
                  <div className='flex items-center gap-8'>
                    <div className='app-modal-fullscreen__big-title'>
                      {selected_product.name}
                    </div>
                    {!selected_product.active && (
                      <LabelTag className='mt-1'>
                        Archived
                      </LabelTag>
                    )}
                  </div>
                </div>
                <div className='flex items-center gap-3'>
                  <DashboardButton
                    size='sm'
                    color='light'
                    disabled>
                    Copy to livemode
                  </DashboardButton>
                  <Dropdown
                    items={[
                      {
                        label: selected_product.active
                          ? 'Archive'
                          : 'Unarchive',
                        onClick: handleArchiveClick,
                      },
                      {
                        label: 'Delete',
                        className:
                          'text-danger font-semibold',
                        onClick: handleDeleteClick,
                      },
                    ]}>
                    <Dropdown.Slot name='toggle'>
                      <MoreIcon className='cursor-pointer' />
                    </Dropdown.Slot>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className='horizontal-divider' />
            <div className='flex flex-wrap gap-5 divide-x pb-7'>
              <div className=' pr-20'>
                <div className='app-modal-fullscreen__label'>
                  Shippable
                </div>
                <div className='app-modal-fullscreen__value'>
                  {selected_product.shippable
                    ? 'Yes'
                    : 'No'}
                </div>
              </div>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  Unit Label
                </div>
                <div className='app-modal-fullscreen__value'>
                  {!selected_product.unit_label
                    ? 'per item'
                    : `${capitalize(
                      selected_product.unit_label
                    )}`}
                </div>
              </div>
            </div>
            <div className='space-y-1.5'>
              <div className='flex justify-between'>
                <div className='app-modal-fullscreen__title'>
                  Details
                </div>
                <div className='flex gap-4'>
                  <DashboardButton
                    onClick={handleEditClick}
                    outline
                    color='light'
                    size='sm'
                    icon={<EditIcon />}>
                    Edit
                  </DashboardButton>
                </div>
              </div>
              <div className='horizontal-divider' />
              <div className='pt-4'>
                <div className='grid grid-cols-5 gap-4'>
                  <div className='col-span-2 space-y-2'>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Name
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_product.name}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Description
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_product.description ??
                          '-'}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        ID
                      </div>
                      <div className='app-modal-fullscreen__kv__value break-all'>
                        {selected_product.id}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Created
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        <div className='flex items-center'>
                          <DateIcon className='mr-1.5' />
                          <span className='app-modal-fullscreen__value'>
                            {dateFormatter(
                              selected_product.created_at
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-2 space-y-2'>
                    {selected_product.url && (
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          URL
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          {selected_product.url}
                        </div>
                      </div>
                    )}
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Shippable
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {selected_product.shippable
                          ? 'Yes'
                          : 'No'}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Unit Label
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        {!selected_product.unit_label
                          ? 'per item'
                          : `${capitalize(
                            selected_product.unit_label
                          )}`}
                      </div>
                    </div>
                    <div className='app-modal-fullscreen__kv'>
                      <div className='app-modal-fullscreen__kv__key'>
                        Image
                      </div>
                      <div className='app-modal-fullscreen__kv__value'>
                        <div className='app-products__modal__header__image'>
                          <div className='flex gap-3'>
                            <LightgalleryProvider
                              plugins={['lg-thumbnail.js']}>
                              {selected_product.image_url.map(
                                (image, index) => (
                                  <LightgalleryItem
                                    key={index}
                                    src={image}
                                    group='thumb'>
                                    <img
                                      src={image}
                                      alt='Product'
                                      className='cursor-pointer'
                                    />
                                  </LightgalleryItem>
                                )
                              )}
                            </LightgalleryProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ProductDetailsPricingTable
              setShowPriceModal={setShowPriceModal}
            />
            <div className='space-y-1.5 pb-2'>
              <MetadataForm
                data={selected_product.metadata}
                onSave={handleMetadataSave}
                loading={isUpdateLoading}
                key_cols='4'
                value_cols='8'
              />
            </div>
            <ProductsModalDetailsLogsTable
              enabled={!!selected_product}
            />
            <ProductsModalDetailsEventsTable
              enabled={!!selected_product}
            />
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showCreateModal && (<PaymentLinksModalCreate
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        basePrice={basePrice}
      />)}
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title='Delete Product'
          message='By deleting this product, any prices or SKUs associated with it will also be deleted. Are you sure you want to delete this product?'
        />
      )}
      {showDeletePriceModal && (
        <ModalDelete
          show={showDeletePriceModal}
          onClose={handlePriceDeleteModalClose}
          onDelete={handlePriceDelete}
          loading={isDeletePriceLoading}
          title='Delete Price'
          message='Deleting this price will no longer make it available for new purchases. Are you sure you want to delete this price?'
        />
      )}
    </>
  );
}

export default ProductsModalDetails;
