import ModalFullScreen from '../../../../../Common/ModalFullScreen/ModalFullScreen';
import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatter } from '../../../../../../utils/date';
import {
  capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencySymbol,
} from '../../../../../../utils/string';
import DashboardButton from '../../../../../Common/Form/DashboardButton/DashboardButton';
import LabelTag from '../../../../../Common/LabelTag/LabelTag';

import PaymentsModalUpdate from '../PaymentsModalUpdate/PaymentsModalUpdate';
import MetadataForm from '../../../../../Dashboard/MetadataForm/MetadataForm';
import { useUpdatePayment } from '../../../../../../api/customer/payment';
import { toast } from 'react-toastify';
import {
  setRefetchPayment,
  setSelectedPayment,
} from '../../../../../../store/payment/paymentSlice';
import NotesModule from '../../../../../Dashboard/Notes/Notes';
import CopyToClipboardBoxID from '../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import PaymentDetailsRefundsTable from './PaymentDetails/PaymentDetailsRefundsTable';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
} from 'react-icons/fa';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import PaymentsModalCapture from '../PaymentsModalCapture/PaymentsModalCapture';

import PaymentsModalLogsTable from './PaymentsModalLogsTable';
import PaymentsModalEventsTable from './PaymentsModalEventsTable';
import PaymentMethod from './PaymentMethod';
import Details from './Details';
import IssueRefundModal from './IssueRefundModal';

function PaymentsModalDetails({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showCaptureModal, setShowCaptureModal] =
    useState(false);
  const [showIssueRefundModal, setShowIssueRefundModal] =
    useState(false);
  const { selected_payment } = useSelector(
    (state) => state.payment
  );
  console.log(
    'testing selected payment from payments modal details',
    selected_payment
  );
  const {
    mutate: mutateUpdatePayment,
    isLoading: isUpdateLoading,
  } = useUpdatePayment(selected_payment?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  const navigate = useNavigate();

  const handleMetadataSave = (metadata) => {
    mutateUpdatePayment(
      { metadata },
      {
        onSuccess: (data) => {
          dispatch(setSelectedPayment(data.data));
          dispatch(setRefetchPayment(Date.now()));
          toast.success('Metadata updated');
        },
      }
    );
  };

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  const handleIssueRefundClick = () => {
    setShowIssueRefundModal(true);
    navigate(
      `/dashboard/payments/${selected_payment.id}/refund`
    );
  };

  const handleCloseIssueRefundModal = () => {
    setShowIssueRefundModal(false);
    navigate(`/dashboard/payments/${selected_payment.id}`);
  };

  const handleCaptureClick = () => {
    setShowCaptureModal(true);
  };

  useEffect(() => {
    if (window.location.pathname.includes('refund')) {
      setShowIssueRefundModal(true);
    }
  }, [selected_payment, location]);

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Payment
            </div>
            <CopyToClipboardBoxID
              text={selected_payment?.id}
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div className='app-payments__modal__body'>
            <div className='space-y-6'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <div className='flex items-center gap-8'>
                    <div className='app-payments__big-amount'>
                      <div>
                        <div className='app-payments__big-amount__digits text-light'>
                          {currencySymbol(
                            selected_payment.currency
                          )}
                          {currencyFormatter(
                            selected_payment.currency,
                            selected_payment.amount
                          )}{' '}
                          <span>
                            {selected_payment.currency?.toUpperCase()}
                          </span>
                        </div>
                        {selected_payment.crypto_amount && (
                          <div className='flex items-center gap-1'>
                            <img
                              src={getIconUrl(
                                selected_payment.crypto_currency.toLowerCase()
                              )}
                              alt={
                                selected_payment.crypto_currency
                              }
                            />
                            <div className='app-payments__big-amount__crypto text-light font-medium'>
                              {cryptocurrencyFormatter(
                                selected_payment.crypto_amount
                              )}{' '}
                              {
                                selected_payment.crypto_currency
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <>
                      {selected_payment.status ===
                        'under_paid' && selected_payment.captured && (
                          <LabelTag color='primary'>
                            <div
                              data-for='refunds-tooltip'
                              data-tip={`This payment was underpaid by 
                  ${currencySymbol(
                                selected_payment.currency
                              )}${currencyFormatter(
                                selected_payment.currency,
                                selected_payment.amount_underpaid
                              )} 
                  amount (${cryptocurrencyFormatter(
                                selected_payment.crypto_amount_underpaid
                              )} ${selected_payment.crypto_currency})`}
                              className='flex items-center gap-1'>
                              <span>Succeeded</span>
                              <FaArrowAltCircleDown className='text-danger' />
                            </div>
                          </LabelTag>
                        )}
                      {selected_payment.status ===
                        'over_paid' && selected_payment.captured && (
                          <LabelTag color='primary'>
                            <div
                              data-for='refunds-tooltip'
                              data-tip={`This payment was overpaid by 
                  ${currencySymbol(
                                selected_payment.currency
                              )}${currencyFormatter(
                                selected_payment.currency,
                                selected_payment.amount_underpaid
                              )} 
                  amount (${cryptocurrencyFormatter(
                                selected_payment.crypto_amount_underpaid
                              )} ${selected_payment.crypto_currency})`}
                              className='flex items-center gap-1'>
                              <span>Succeeded</span>
                              <FaArrowAltCircleUp className='text-tertiary' />
                            </div>
                          </LabelTag>
                        )}
                      {selected_payment.status ===
                        'succeeded' &&
                        selected_payment.captured && (
                          <LabelTag color='primary'>
                            Succeeded
                          </LabelTag>
                        )}
                      {[
                        'under_paid',
                        'over_paid',
                        'succeeded',
                      ].includes(selected_payment.status) &&
                        !selected_payment.captured && (
                          <LabelTag>Uncaptured</LabelTag>
                        )}
                      {selected_payment.refunded && (
                        <LabelTag color='secondary'>
                          Refunded
                        </LabelTag>
                      )}
                      {selected_payment.status ===
                        'failed' && (
                          <div
                            data-for='refunds-tooltip'
                            data-tip={
                              selected_payment.failure_message
                            }>
                            <LabelTag color='danger'>
                              Failed
                            </LabelTag>
                          </div>
                        )}
                      {selected_payment.status ===
                        'pending' && (
                          <LabelTag color='tertiary'>
                            Pending
                          </LabelTag>
                        )}
                      {selected_payment.status ===
                        'cancelled' && (
                          <LabelTag color='dark'>
                            Cancelled
                          </LabelTag>
                        )}
                    </>
                  </div>
                </div>
                {[
                  'under_paid',
                  'over_paid',
                  'succeeded',
                ].includes(selected_payment.status) &&
                  !selected_payment.captured ? (
                  <DashboardButton
                    onClick={handleCaptureClick}
                    color='primary'>
                    <span className='px-6'>Capture</span>
                  </DashboardButton>
                ) : (
                  ![
                    'cancelled',
                    'failed',
                    'pending',
                  ].includes(selected_payment.status) && (
                    <DashboardButton
                      color='secondary'
                      onClick={handleIssueRefundClick}>
                      <span className='px-6'>Refund</span>
                    </DashboardButton>
                  )
                )}
              </div>
              <div className='horizontal-divider' />
              <div className='flex flex-wrap gap-5 divide-x pb-7'>
                <div className='pl-5 pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    DATE
                  </div>
                  <div className='flex items-center'>
                    <DateIcon className='mr-1.5' />
                    <span className='app-modal-fullscreen__value'>
                      {dateFormatter(
                        selected_payment.created_at,
                        true
                      )}
                    </span>
                  </div>
                </div>
                <div className='pl-5 pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    CUSTOMER NAME
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    {selected_payment.customer?.name ? (
                      <Link
                        to={`/dashboard/customers/${selected_payment.customer?.id}`}
                        className='text-tertiary'
                        target='_blank'>
                        {selected_payment.customer?.name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <div className='pl-5'>
                  <div className='app-modal-fullscreen__label'>
                    PAYMENT METHOD
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    <span className='text-dark'>
                      {capitalize(selected_payment.method)}
                    </span>
                  </div>
                </div>
              </div>
              <NotesModule
                enabled={!!selected_payment}
                parent_object={selected_payment}
              />
              {/* <PaymentDetailsNotes
                enabled={!!selected_payment}
              /> */}
              <Details />
              <PaymentDetailsRefundsTable
                enabled={!!selected_payment}
              />
              <PaymentMethod enabled={!!selected_payment} />
              {/*{selected_payment.shipping && (
                <div className="space-y-1.5 pb-2">
                  <div className="app-modal-fullscreen__title">
                    Customer Shipping Details
                  </div>
                  <div className="horizontal-divider" />
                  <div className="pt-4">
                    <div className="grid grid-cols-5 gap-4">
                      <div className="col-span-2 space-y-2">
                        <div className="app-modal-fullscreen__kv">
                          <div className="app-modal-fullscreen__kv__key">
                            First line
                          </div>
                          <div className="app-modal-fullscreen__kv__value">
                            {selected_payment.shipping.first_line}
                          </div>
                        </div>
                        <div className="app-modal-fullscreen__kv">
                          <div className="app-modal-fullscreen__kv__key">
                            Second line
                          </div>
                          <div className="app-modal-fullscreen__kv__value">
                            {selected_payment.shipping.second_line}
                          </div>
                        </div>
                        <div className="app-modal-fullscreen__kv">
                          <div className="app-modal-fullscreen__kv__key">
                            State
                          </div>
                          <div className="app-modal-fullscreen__kv__value">
                            {selected_payment.shipping.state}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 space-y-2">
                        <div className="app-modal-fullscreen__kv">
                          <div className="app-modal-fullscreen__kv__key">
                            Postalcode
                          </div>
                          <div className="app-modal-fullscreen__kv__value">
                            {selected_payment.shipping?.postalcode}
                          </div>
                        </div>
                        <div className="app-modal-fullscreen__kv">
                          <div className="app-modal-fullscreen__kv__key">
                            Email
                          </div>
                          <div className="app-modal-fullscreen__kv__value">
                            {selected_payment.shipping?.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}*/}
              <div className='space-y-1.5 pb-2'>
                <MetadataForm
                  data={selected_payment.metadata}
                  onSave={handleMetadataSave}
                  loading={isUpdateLoading}
                />
              </div>
              <PaymentsModalLogsTable
                enabled={!!selected_payment}
              />
              <PaymentsModalEventsTable
                enabled={!!selected_payment}
              />
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showIssueRefundModal && (
        <IssueRefundModal
          show={showIssueRefundModal}
          onClose={handleCloseIssueRefundModal}
        />
      )}
      {showModal && (
        <PaymentsModalUpdate
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      {showCaptureModal && (
        <PaymentsModalCapture
          show={showCaptureModal}
          onClose={() => setShowCaptureModal(false)}
        />
      )}
    </>
  );
}

export default PaymentsModalDetails;
