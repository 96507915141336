import DashboardButton from "../../../../../../../Common/Form/DashboardButton/DashboardButton";
import Dropdown from "../../../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../../../assets/images/icons/dashboard/more.svg";
import React, { useCallback, useMemo } from "react";
import {
  setDraftInvoice,
  setInvoiceLineItems,
  setRefetchInvoices,
  setSelectedInvoiceCustomer,
} from "../../../../../../../../store/invoice/invoiceSlice";
import { toast } from "react-toastify";
import {
  useIssueInvoice,
  useStoreInvoice,
  useUncollectibleInvoice,
  useVoidInvoice,
} from "../../../../../../../../api/customer/invoice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function InvoiceDetailsButtons({
  handleClose,
  isDeleteLoading,
  setShowDeleteModal,
  setShowCreditNoteModal,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected_invoice } = useSelector((state) => state.invoice);

  const { mutate: mutateIssueInvoice, isLoading: isIssueLoading } =
    useIssueInvoice(selected_invoice?.id);
  const { mutate: mutateVoidInvoice, isLoading: isVoidLoading } =
    useVoidInvoice(selected_invoice?.id);
  const {
    mutate: mutateUncollectibleInvoice,
    isLoading: isUncollectibleLoading,
  } = useUncollectibleInvoice(selected_invoice?.id);

  const { mutate: mutateStoreInvoice, isLoading: isStoreLoading } =
    useStoreInvoice();

  const handleDeleteClick = useCallback(() => {
    if (isDeleteLoading) return;
    setShowDeleteModal(true);
  }, [isDeleteLoading, setShowDeleteModal]);

  const handleIssueInvoiceClick = () => {
    mutateIssueInvoice(null, {
      onSuccess: () => {
        dispatch(setRefetchInvoices(Date.now()));
        handleClose();
        toast.success("The invoice has been issued");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleEditInvoiceClick = () => {
    dispatch(setInvoiceLineItems([]));
    dispatch(setSelectedInvoiceCustomer(selected_invoice.customer));
    dispatch(setDraftInvoice(selected_invoice));
    // handleClose();
  };

  const handlePayClick = () => {
    if (!selected_invoice.hosted_invoice) return;
    window.open(selected_invoice.hosted_invoice, "_blank").focus();
  };

  const handleVoidClick = useCallback(() => {
    if (isVoidLoading) return;
    mutateVoidInvoice(null, {
      onSuccess: () => {
        dispatch(setRefetchInvoices(Date.now()));
        handleClose();
        toast.success("The invoice has been void");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  }, [isVoidLoading, dispatch, mutateVoidInvoice, handleClose]);

  const handleMarkUncollectibleClick = useCallback(() => {
    if (isUncollectibleLoading) return;
    mutateUncollectibleInvoice(null, {
      onSuccess: () => {
        dispatch(setRefetchInvoices(Date.now()));
        handleClose();
        toast.success("The invoice has been marked as uncollectible");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  }, [
    isUncollectibleLoading,
    dispatch,
    mutateUncollectibleInvoice,
    handleClose,
  ]);

  const handleDuplicateClick = useCallback(() => {
    if (isStoreLoading) return;

    const data = {
      customer: selected_invoice.customer.id,
      currency: selected_invoice.currency,
      crypto_payment_padding: selected_invoice.crypto_payment_padding,
    };

    if (selected_invoice?.crypto_payment_currencies.length) {
      data.crypto_payment_currencies =
        selected_invoice.crypto_payment_currencies;
    }
    if (selected_invoice?.custom_fields) {
      data.custom_fields = selected_invoice.custom_fields;
    }
    if (selected_invoice?.footer) {
      data.footer = selected_invoice.footer;
    }
    if (selected_invoice?.memo) {
      data.memo = selected_invoice.memo;
    }

    mutateStoreInvoice(data, {
      onSuccess: (data) => {
        dispatch(setRefetchInvoices(Date.now()));
        navigate(`/dashboard/invoices/${data.data?.id}`);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  }, [
    selected_invoice,
    mutateStoreInvoice,
    navigate,
    isStoreLoading,
    dispatch,
  ]);

  const dropDownItems = useMemo(() => {
    const _items = [
      {
        label: "Duplicate",
        onClick: handleDuplicateClick,
      },
    ];
    if (selected_invoice.status === "draft") {
      _items.push({
        label: "Delete",
        className: "text-danger font-semibold",
        onClick: handleDeleteClick,
      });
    }

    if (
      selected_invoice.status === "over_due" ||
      selected_invoice.status === "part_paid" ||
      selected_invoice.status === "issued"
    ) {
      

        _items.push({
          label: "Create a credit note",
          onClick:()=> setShowCreditNoteModal(true),
        });
      
      _items.push({
        label: "Mark uncollectible",
        onClick: handleMarkUncollectibleClick,
      });

      _items.push({
        label: "Void",
        className: "text-danger font-semibold",
        onClick: handleVoidClick,
      });
    }

    return _items;
  }, [
    selected_invoice,
    handleDeleteClick,
    handleVoidClick,
    handleMarkUncollectibleClick,
    handleDuplicateClick,
  ]);

  const isInvoiceStatus = (status) => selected_invoice.status === status;

  return (
    <div className="flex items-center gap-3">
      {isInvoiceStatus("draft") && (
        <>
          <DashboardButton
            onClick={handleEditInvoiceClick}
            outline
            dense
            color="light"
          >
            <span className="text-dark">Edit</span>
          </DashboardButton>
          <DashboardButton
            onClick={handleIssueInvoiceClick}
            disabled={isIssueLoading}
            dense
            color="primary"
          >
            Issue
          </DashboardButton>
        </>
      )}
      {(isInvoiceStatus("issued") ||
        isInvoiceStatus("over_due") ||
        isInvoiceStatus("part_paid") ||
        isInvoiceStatus("pastdue")) && (
        <DashboardButton onClick={handlePayClick} dense color="primary">
          Pay
        </DashboardButton>
      )}
      {!isInvoiceStatus("draft") && (
        <a href={selected_invoice.invoice_pdf} download >
          <DashboardButton outline dense color="light">
            <span className="text-dark">Download</span>
          </DashboardButton>
        </a>
      )}
      <Dropdown items={dropDownItems}>
        <Dropdown.Slot name="toggle">
          <MoreIcon className="cursor-pointer" />
        </Dropdown.Slot>
      </Dropdown>
    </div>
  );
}

export default InvoiceDetailsButtons;
