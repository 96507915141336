import "./SectionAdditionalFeatures.scss";
import Stack from "../../../Common/Stack/Stack";
import CryptoSphere from "../../../../assets/images/crypto-sphere.png";

const currenciesData = [
  [
    { flag: "us", name: "US Dollar" },
    { flag: "ae", name: "UAE Dirham" },
    { flag: "ar", name: "Argentine Peso" },
    { flag: "au", name: "Australian Dollar" },
    { flag: "bd", name: "Bangladeshi Taka" },
    { flag: "bh", name: "Bahraini Dinar" },
    { flag: "bm", name: "Bermudan Dollar" },
    { flag: "br", name: "Brazilian Real" },
    { flag: "ca", name: "Canadian Dollar" },
    { flag: "ch", name: "Swiss Franc" },
    { flag: "cl", name: "Chilean Peso" },
    { flag: "cn", name: "Chinese Yuan" },
  ],
  [
    { flag: "cz", name: "Czech Koruna" },
    { flag: "dk", name: "Danish Krone" },
    { flag: "eu", name: "Euro" },
    { flag: "gb", name: "British Pound" },
    { flag: "hk", name: "Hong Kong Dollar" },
    { flag: "hu", name: "Hungarian Forint" },
    { flag: "id", name: "Indonesian Rupiah" },
    { flag: "il", name: "Israeli New Shekel" },
    { flag: "in", name: "Indian Rupee" },
    { flag: "jp", name: "Japanese Yen" },
    { flag: "kr", name: "South Korean won" },
    { flag: "kw", name: "Kuwaiti Dinar" },
  ],
  [
    { flag: "lk", name: "Sri Lankan Rupee" },
    { flag: "mm", name: "Myanmar Kyat" },
    { flag: "mx", name: "Mexican Peso" },
    { flag: "my", name: "Malaysian Ringgit" },
    { flag: "ng", name: "Nigerian Naira" },
    { flag: "no", name: "Norwegian Krone" },
    { flag: "nz", name: "New Zealand Dollar" },
    { flag: "ph", name: "Philippine peso" },
    { flag: "pk", name: "Pakistan Rupee" },
    { flag: "pl", name: "Poland złoty" },
    { flag: "ru", name: "Russian Rubel" },
    { flag: "sa", name: "Saudi Riyal" },
  ],
  [
    { flag: "se", name: "Swedish Krona" },
    { flag: "sg", name: "Singapore Dollar" },
    { flag: "th", name: "Thai Baht" },
    { flag: "tr", name: "Turkish Lira" },
    { flag: "tw", name: "New Taiwan dollar" },
    { flag: "ua", name: "Ukrainian hryvnia" },
    { flag: "ve", name: "Venezuelan Bolivar" },
    { flag: "vn", name: "Vietnamese dong" },
    { flag: "za", name: "South African Rand" },
  ],
];

const cryptosData = [
  [
    { flag: "btc", name: "Bitcoin" },
    { flag: "ltc", name: "Litecoin" },
    { flag: "eth", name: "Ethereum" },
    { flag: "bnb", name: "Binance Coin" },
    { flag: "trx", name: "Tron" },
  ],
  [
    { flag: "avax", name: "Avalanche" },
    { flag: "usdt", name: "USD Tether" },
    { flag: "kcs", name: "KuCoin Token" },
    { flag: "xrp", name: "Ripple" },
    { flag: "xlm", name: "Stellar" },
  ],
  [
    { flag: "busd", name: "Binance USD" },
    { flag: "usdc", name: "USD Coin" },
    { flag: "ftm", name: "Fantom" },
    { flag: "bch", name: "Bitcoin Cash" },
    { flag: "aave", name: "Aave" },
  ],
  [
    { flag: "link", name: "ChainLink" },
    { flag: "bat", name: "Basic Attention Token" },
    { flag: "matic", name: "Polygon Matic" },
    { flag: "ada", name: "Cardano" },
    { flag: "dot", name: "Polkadot" },
  ],
  [
    
    { flag: "cro", name: "Cronos" },
    { flag: "cake", name: "PancakeSwap" },
    { flag: "uni", name: "UniSwap" },
    { flag: "sushi", name: "Sushi" },
    { flag: "doge", name: "Dogecoin" },
  ],
];

// const cryptosData = [
//   [
//     { flag: "btc", name: "Bitcoin" },
//     { flag: "ltc", name: "Litecoin" },
//     { flag: "eth", name: "Ethereum" },
//     { flag: "bnb", name: "Binance Coin" },
//     { flag: "trx", name: "Tron" },
//     { flag: "avax", name: "Avalanche" },
//   ],
//   [
//     { flag: "xrp", name: "Ripple" },
//     { flag: "xlm", name: "Stellar" },
//     { flag: "usdt", name: "USD Tether" },
//     { flag: "busd", name: "Binance USD" },
//     { flag: "ftm", name: "Fantom" },
//     { flag: "bch", name: "Bitcoin Cash" },
//   ],
//   [
//     { flag: "link", name: "ChainLink" },
//     { flag: "aave", name: "Aave" },
//     { flag: "bat", name: "Basic Attention Token" },
//     { flag: "matic", name: "Polygon Matic" },
//     { flag: "ada", name: "Cardano" },
//     { flag: "dot", name: "Polkadot" },
//   ],
//   [
//     { flag: "usdc", name: "USD Coin" },
//     { flag: "cro", name: "Crypto.com Coin" },
//     { flag: "cake", name: "PancakeSwap" },
//     { flag: "uni", name: "UniSwap" },
//     { flag: "sushi", name: "Sushi" },
//     { flag: "doge", name: "Dogecoin" },
//   ],
// ];

function SectionAdditionalFeatures() {
  return (
    <div id="additional-features" className="section-additional">
      <div className="app-container">
        <div>
          <h1 className="h1 section-additional__title">Additional Features</h1>
          <h3 className="h3 section-additional__subtitle">
            <div>Create invoices, orders and payments</div>
            in{" "}
            <span className="section-additional__subtitle__currencies">
              45 different fiat currencies
            </span>
          </h3>
        </div>
        <Stack
          columns="2"
          className="md:grid-cols-4 sm:grid-cols-3 section-additional__currencies"
        >
          {currenciesData.map((stack, index) => (
            <div key={index}>
              {stack.map((currency, index) => (
                <div
                  key={crypto.flag + index.toString()}
                  className="section-additional__currencies__item"
                >
                  <img
                    className="section-additional__currencies__item__flag"
                    src={require(`../../../../assets/images/icons/flags/${currency.flag}.png`)}
                    alt={currency.name}
                  />
                  <span className="section-additional__currencies__item__name">
                    {currency.name}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </Stack>
        <h3 className="h3 section-additional__subtitle">
          Accept payments in
          <span className="section-additional__subtitle__currencies">
            {" "}
            20+ different cryptocurrencies
          </span>
        </h3>
        <Stack
          columns="2"
          className="md:grid-cols-5 sm:grid-cols-3 section-additional__currencies"
        >
          {cryptosData.map((stack, index) => (
            <div key={index}>
              {stack.map((crypto, index) => (
                <div
                  key={crypto.flag + index.toString()}
                  className="section-additional__currencies__item"
                >
                  <img
                    className="section-additional__currencies__item__flag"
                    src={require(`../../../../assets/images/icons/cryptos/${crypto.flag}.png`)}
                    alt={crypto.name}
                  />
                  <span className="section-additional__currencies__item__name">
                    {crypto.name}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </Stack>
        <h3 className="h3 section-additional__subtitle">
          <div>Bridged Blockchain technology</div>
          for
          <span className="section-additional__subtitle__currencies">
            {" "}
            faster settlements and cheaper transaction fees
          </span>
        </h3>
        <div className="section-additional__sphere">
          <img
            className="section-additional__sphere__image"
            src={CryptoSphere}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default SectionAdditionalFeatures;
