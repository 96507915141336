import {useDispatch, useSelector} from "react-redux";
import {currencyPrettier} from "../../../../../../../../../utils/string";
import {ReactComponent as DeleteIcon} from "../../../../../../../../../assets/images/icons/dashboard/delete.svg";
import {ReactComponent as EditIcon} from "../../../../../../../../../assets/images/icons/dashboard/edit.svg";
import {
  setEditInvoiceItem,
  setRefetchInvoiceItems,
  setSelectOneTime
} from "../../../../../../../../../store/invoice/invoiceSlice";
import {useDeleteInvoiceItem} from "../../../../../../../../../api/customer/invoice";
import {toast} from "react-toastify";

function InvoiceItemsList() {
  const dispatch = useDispatch();
  const { invoice_line_items } = useSelector((state) => state.invoice);

  const { mutate: mutateDeleteInvoiceItem } = useDeleteInvoiceItem();

  const handleEditItemClick = (item) => {
    dispatch(setEditInvoiceItem(item));
    dispatch(setSelectOneTime(true));
  }

  const handleDeleteItemClick = (item) => {
    mutateDeleteInvoiceItem(item.invoice_item, {
      onSuccess: () => {
        dispatch(setRefetchInvoiceItems(Date.now()));
        toast.success("Item deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      }
    })
  }

  return (
    <>
      {invoice_line_items.map((item, index) =>
      <div key={index} className="space-y-1.5">
        <div className="flex justify-between mt-4">
          <div className="app-modal-fullscreen__text">
            {item.product.name} x {item.quantity}
          </div>
          <div className="flex items-center gap-x-3">
            {item.discounts.map((discount, index) =>
              <div key={index} className="app-invoices__badge">
                {discount.amount_off &&
                `${currencyPrettier(discount.currency, discount.amount_off)} off`
                }
                {discount.percent_off && `${discount.percent_off}% off`}
              </div>
            )}
            {item.tax_rates.map((tax_rate, index) =>
              <div key={index} className="app-invoices__badge">
                {`${tax_rate.percent}%${tax_rate.inclusive ? " incl." : ""} tax`}
              </div>
            )}
            <div className="app-customers__modal__earning mr-2">
              {currencyPrettier(item.price.currency, item.amount)}
            </div>
            <EditIcon
              onClick={() => handleEditItemClick(item)}
              className="cursor-pointer"
            />
            <DeleteIcon
              onClick={() => handleDeleteItemClick(item)}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="horizontal-divider"/>
      </div>
      )}
    </>
  )
}

export default InvoiceItemsList;
