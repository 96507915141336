import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabelTag from 'components/Common/LabelTag/LabelTag';
import { ReactComponent as DateIcon } from 'assets/images/icons/date.svg';
import clsx from 'clsx';
import { methodType, blockchainNetwork } from 'utils/crypto_utils';
import { dateFormatter } from 'utils/date';
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from 'utils/string';
import { toast } from 'react-toastify';
import useTablePagination from 'hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import DashboardTable from 'components/Dashboard/DashboardTable/DashboardTable';

import styled from 'styled-components';
import { setSelectedTopup } from 'store/topups/topupsSlice';
import { useFetchTopups } from 'api/wallet/topups';
import ReactTooltip from 'react-tooltip';
// import { ReactComponent as RefreshIcon } from '../../../../../../assets/images/icons/refresh.svg';

function TopupsTable({ filters = {} , refreshTopupList = false}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const { refetch_topup } = useSelector(
    (state) => state.topup
  );
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchTopupsSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        crypto_currency: row.crypto_currency,
        currency : row.currency,
        type: row.type,
        status: row.blockchain_transaction?.status === 'confirming' ? 'confirming' : row.status,
        crypto_amount: cryptocurrencyFormatter(row.crypto_amount),
        amount: (row.amount),
        description : "null",
        created: dateFormatter(row.created_at, true),
        failure_message: row.failure_message,
        method : row.method,
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchTopupsError = (e) => {
    setRefetch(false);
    console.log(e)
  };
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useEffect(() => {
    setRefetch(true);
  }, [ refetch_topup, filters]);
  useEffect(() => {
    if (requestQuery) setRefetch(true);
    
  }, [requestQuery]);

  const { isFetching: isFetchingData } = useFetchTopups({
    onSuccess: onFetchTopupsSuccess,
    onError: onFetchTopupsError,
    params: {
      expand: ['blockchain_transaction' , "balance_transaction"],
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
    enabled : refetch,
  }  );

  const handleRowClick = (row) => {
    dispatch(setSelectedTopup(row._data));
    navigate(DASHBOARD_PATH + `/topups/${row.id}`);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount',

        maxWidth: 60,
        // Width: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return  <CryptoAmountStyle>

        {row?.type === "crypto" &&           <div>
            <img src={getIconUrl(row?.crypto_currency) } />
            {row?.crypto_amount} 
            {" "}
            {row?.crypto_currency}
          </div>}
          {/* The font size of this fiat value must change if it is fiat or crypto */}
          <span>
           ( {
              currencyPrettier(row?.currency ,row?.amount)
            })
          </span>
      </CryptoAmountStyle>

} ,
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 60,
        Cell: (cell) => 
        {
          const row = cell.row.original;
          return ( <div
            data-for='topup-tooltip'
            data-tip={cell.value === 'failed' ?  row?.failure_message :
            cell.value === "confirming" ? `This topup has ${row._data.blockchain_transaction.confirmations} / ${row._data.blockchain_transaction.confirmations_required} confirmations` : null
            }
          >
            <LabelTag
              color={clsx(
                cell.value === 'succeeded' && 'primary',
                cell.value === 'failed' && 'danger',
                cell.value === 'pending' && 'tertiary',
                cell.value === 'confirming' && 'warning',
                cell.value === 'cancelled' && 'secondary',
                cell.value === 'processing' && 'dark'
              )}>
              {cell.value === "confirming" ? `confirming - (${row._data.blockchain_transaction.confirmations} / ${row._data.blockchain_transaction.confirmations_required})` : cell.value}
            </LabelTag>

          </div>
          )
        }
      },
      {
        Header: 'Method',
        accessor: 'method',
        // maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div className='flex flex-col items start gap-2 font-semibold' >
              {methodType(cell.value)}
              {cell.value === "crypto" && <span className='font-normal'>{blockchainNetwork(row?._data?.blockchain_transaction?.network)}</span>}
            </div>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'created',
        maxWidth: 50,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              {cell.value && (
                <div>
                  <DateIcon className='mr-1.5' />
                </div>
              )}
              <span className='text-light'>
                {cell.value}
              </span>
            </div>
          );
        },
      },
     
    ],
    []
  );
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount} 
          noRecordTag="Topups"
        />

       </div>
      {/* {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={ false}//isDeleteLoading}
          title='Delete Invoice'
          message='Are you sure you want to delete this invoice?'
        />
      )} */}
    </>
  );
}

const CryptoAmountStyle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div{
    display: flex;
    align-items: center;
    gap: 5px;
    >img{
      width: 20px;
      height: 20px;
    }
  }
  > span{

  }

  `;
export default TopupsTable;
