import DashboardNavbar from "../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import React, {useEffect, useState} from "react";
import ProductsModalPrice
  from "../../../components/Pages/Dashboard/Products/ProductsModals/ProductsModalPrice/ProductsModalPrice";
import {setSelectedProductPrice, setSelectedProductPriceDetails} from "../../../store/product/productSlice";
import {useDispatch, useSelector} from "react-redux";
import {useFetchProductPrice} from "../../../api/product/price";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ProductAddPriceModal
  from "../../../components/Pages/Dashboard/Products/ProductsModals/ProductsModalDetails/ProductDetails/ProductAddPriceModal";
import {toast} from "react-toastify";

function Prices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { live_mode_access } = useSelector(
    (state) => state.auth.current_account
  );

  const DASHBOARD_PATH = !live_mode_access ? "/dashboard/test" : "/dashboard";
  const [showPriceDetailsModal, setShowPriceDetailsModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(
   false 
  );
  const [refreshPrice, setRefreshPrice] = useState(false);
  const { selected_product_price, refetch_product_price } = useSelector((state) => state.product);
  const { id } = useParams();
  const { selected_product_price_details } = useSelector(
    (state) => state.product
  );
  const { isSuccess } = useFetchProductPrice({
    onSuccess: (data) => {
      dispatch(setSelectedProductPriceDetails(data));
      console.log("second", location.pathname);
      if (location.pathname.endsWith("/edit"))
        dispatch(setSelectedProductPrice(data))
      // {

      // }
        // setShowPriceModal(true);
      
      setShowPriceDetailsModal(true);
      setRefreshPrice(false);
    },
    onError: (error) => {
      setRefreshPrice(false);
      navigate(DASHBOARD_PATH + "/products");
      toast.error(error.response?.data.error.message);
    },
    id,
    params: {
      expand: ["product"]
    },
    enabled: refreshPrice
  })

  useEffect(() => {
    //   console.log(!!selected_product_price)
      console.log(selected_product_price)
      if (selected_product_price)
      {
        setShowPriceModal(!!selected_product_price);
      }
      else
      {
        // navigate(location.pathname.replace("/create", "").replace("/edit", ""));

      }
    }, [selected_product_price]);

  useEffect(() => {

    console.log("showPriceModal", location.pathname);
    if (showPriceModal) {
      if (!location.pathname.endsWith("/create" ) && !location.pathname.endsWith("/edit") ) {
          // if (selected_product_price)
            navigate(location.pathname + "/edit");
          // }
          // else{
          //   navigate(location.pathname + "/create");
          // }
      } 
    } else 
    {
      console.log("selected_product_price", selected_product_price);
      if (!selected_product_price)
      {
        // navigate(location.pathname.replace("/create", "").replace("/edit", ""));
      }
    }

  }, [showPriceModal]);
  useEffect(() => {
    if (!id) {
      return;
    }

    // if (location.pathname.endsWith("/edit")) {
    //   console.log(selected_product_price)
    //   if (selected_product_price)
    //     setShowPriceModal(true);
    //   else
    //     setRefreshPrice(true);
    // }
    // else
    // {
    //   if (selected_product_price)
    //     setShowPriceModal(true);
    //   // alert("showPriceModal")
    //   setRefreshPrice(true);
    // }
  
    setRefreshPrice(true);
  }, [refetch_product_price, id]);

 

  const handlePriceDetailsModalClose = () => {
    setShowPriceDetailsModal(false);
    dispatch(setSelectedProductPriceDetails(null));
    navigate("/dashboard/products");
  }

  const handleAddPriceModalClose = () => {
    dispatch(setSelectedProductPrice(null));
    setShowPriceModal(false);
  }

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        {(showPriceDetailsModal && isSuccess) &&
        <ProductsModalPrice
          show={showPriceDetailsModal}
          onClose={handlePriceDetailsModalClose}
        />
        }
        {showPriceModal &&
        <ProductAddPriceModal show={showPriceModal} onClose={handleAddPriceModalClose} />
        }
      </div>
    </div>
  )
}

export default Prices;