import { createSlice } from '@reduxjs/toolkit';

export const payoutSlice = createSlice({
  name: 'payout',
  initialState: {
    selected_payout: null,
    refetch_payout: null,
  },
  reducers: {
    setSelectedPayout: (state, payload) => {
      state.selected_payout = payload.payload;
    },
    setRefetchPayout: (state, payload) => {
      state.refetch_payout = payload.payload;
    },
  },
});

export const {
  setSelectedPayout,
  setRefetchPayout
} = payoutSlice.actions;

export default payoutSlice.reducer;
