import Stack from "../../../Common/Stack/Stack";
import Label from "../../../Common/Form/Label/Label";
import Input from "../../../Common/Form/Input/Input";
import InfoIcon from "../../../../assets/images/icons/info.svg";
import ReactFlagsSelect from "react-flags-select";
import Checkbox from "../../../Common/Form/Checkbox/Checkbox";
import Button from "../../../Common/Form/Button/Button";
import { useEffect, useState } from "react";
import Alert from "../../../Common/Alert/Alert";
import clsx from "clsx";
import { snakeCaseToTitleCase } from "../../../../utils/string";
import { forbiddenCountries } from "../../../../utils/countries";

function RegisterForm({
  form: {
    handleSubmit,
    setValue,
    register,
    reset,
    clearErrors,
    formState: { errors },
  },
  mutation: { mutate, isLoading, isError, error },
}) {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    register("country");
  }, [register]);

  const onSubmit = (formData) => {
    mutate(formData, {
      // onSuccess: () => reset(),
    });
  };

  const fieldError = (field) => {
    return (
      errors[field]?.message ||
      (error && error.response?.data.error.param === field)
    );
  };

  return (
    <>
      <div className="app-auth__card__title">Create your Walio account</div>
      {isError && (
        <Alert
          className="my-4"
          type="danger"
          message={snakeCaseToTitleCase(error.response.data.error.message)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack columns="1">
          <Stack columns="1" className="sm:grid-cols-2">
            <div>
              <Label title="First Name" error={fieldError("first_name")} />
              <Input
                {...register("first_name")}
                type="text"
                block
                error={fieldError("first_name")}
              />
            </div>
            <div>
              <Label title="Last Name" error={fieldError("last_name")} />
              <Input
                {...register("last_name")}
                type="text"
                block
                error={fieldError("last_name")}
              />
            </div>
          </Stack>
          <div>
            <Label
              title="Business Name"
              icon={InfoIcon}
              tooltip="This will be the name of your first Walio Merchant Account"
              error={fieldError("business_name")}
            />
            <Input
              {...register("business_name")}
              type="text"
              block
              error={fieldError("business_name")}
            />
          </div>
          <div>
            <Label title="Email Address" error={fieldError("email")} />
            <Input
              {...register("email")}
              type="email"
              block
              error={fieldError("email")}
            />
          </div>
          <div>
            <Label
              title="Password"
              icon={InfoIcon}
              tooltip={`Minimum: <br />
                8 Characters long, <br />
                1 Uppercase character, <br />
                1 Number, <br />
                1 Special character (%&$£$)`}
              error={fieldError("password")}
            />
            <Input
              {...register("password")}
              type="password"
              block
              error={fieldError("password")}
            />
          </div>
          <div>
            <Label title="Country" error={errors.country?.message} />
            <ReactFlagsSelect
              countries={forbiddenCountries}
              blacklistCountries
              selected={selected}
              onSelect={(code) => {
                setValue("country", code);
                setSelected(code);
                clearErrors("country");
              }}
              searchable
              className="app-react-flag-select"
              selectButtonClassName={clsx(
                "app-select__input app-select__input__country",
                errors.country && "app-select__input__country--error"
              )}
            />
            {fieldError("country") && (
              <p className="app-input__help -mt-1">{fieldError("country")}</p>
            )}
          </div>
          <div className="app-register__checkbox">
            <Checkbox {...register("marketing_opt_out")} className="mt-1" />
            <div className="app-register__newsletter">
              Don’t email me about product updates. If this box is left
              unchecked, Walio will occasionally send helpful and relevant
              emails. You can unsubscribe at any time.{" "}
              <span className="app-register__privacy-policy">
                Privacy Policy
              </span>
            </div>
          </div>
          <Button loading={isLoading}>Create Account</Button>
        </Stack>
      </form>
    </>
  );
}

export default RegisterForm;
