import Label , {LabelX} from "../../../../../../Common/Form/Label/Label";
import Select from "../../../../../../Common/Form/Select/Select";
import { useSelector } from "react-redux";
import React from "react";

import InfoIcon from "../../../../../../../assets/images/icons/info.svg";

const ProductPricingDropDown = ({
  optionsTitle,
  toolTipData,
  selectedOption,
  options,
  price= false,
  onSelectOption,
}) => {
  const { selected_product_price } = useSelector((state) => state.product);

  return (
    <div>
      {price?<Label
        title={optionsTitle}
        icon={InfoIcon}
        tooltipHtml={true}
        tooltip={toolTipData}

      /> :   <LabelX
      title={optionsTitle}
      icon={InfoIcon}
      tooltipHtml={true}
      tooltip={toolTipData}
      options={options}
      selectedOption={selectedOption}
    />}
     
      <Select
        onSelect={onSelectOption}
        placeholder={selectedOption.optionsDisplayName}
        options={options.map((item) => {
          return {
            label: item.optionsDisplayName,
            value: item.id,
          };
        })}
        disabled={selected_product_price?.id}
        value={selectedOption.optionsDisplayName}
      />
    </div>
  );
};

export default ProductPricingDropDown;
