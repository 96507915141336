import Label from "../../../../../../Common/Form/Label/Label";
import DashboardButton from "../../../../../../Common/Form/DashboardButton/DashboardButton";
import {ReactComponent as UploadIcon} from "../../../../../../../assets/images/icons/dashboard/upload.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../../assets/images/icons/dashboard/delete.svg";
import React, {useState} from "react";
import {useDropzone} from "react-dropzone";

const MAX_IMAGES = 10;
const MAX_FILE_SIZE = 2097152; // 2MB

function ProductsModalCreateUpload({ setFiles, files, setImages, images, imagesLength, className = null }) {
  const [uploadError, setUploadError] = useState("");

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg,image/png',
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles, fileRejections) => {
      setUploadError("");

      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setUploadError("File size must not exceed 2MB");
          }

          if (err.code === "file-invalid-type") {
            setUploadError("File must be of type image (jpg, png)");
          }
        });
      });

      if ((imagesLength + acceptedFiles.length) > MAX_IMAGES) {
        setUploadError(`Upload is limited to ${MAX_IMAGES} images`);
        return;
      }

      setFiles([
        ...files,
        ...acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      ]);
    }
  });

  const handleDeleteFile = (e, index) => {
    e.preventDefault();
    setFiles(files.filter((file, fileIndex) => fileIndex !== index));
    setUploadError("");
  }

  const handleDeleteImage = (e, index) => {
    e.preventDefault();
    setImages(images.filter((image, i) => index !== i));
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <Label
          title="Image"
        />
        {uploadError && <p className="app-input__help">{uploadError}</p>}
      </div>
      <label
        {...getRootProps({
          onClick: event => imagesLength === MAX_IMAGES && event.stopPropagation()
        })}
        className="app-products__upload"
      >
        <div className={className ?? "app-products__upload__drag-drop"}>
          {imagesLength > 0 &&
          <>
            <div className="flex items-center justify-between">
              <div className="app-products__upload__count">
                Images: {imagesLength}
                <small className="font-normal ml-3">({MAX_IMAGES} images max)</small>
              </div>
              <div className="app-products__upload__button">
                <DashboardButton disabled={imagesLength === MAX_IMAGES} type="button" color="secondary" size="sm">
                  <div className="flex items-center gap-2">
                    <UploadIcon/>
                    <span>Upload</span>
                  </div>
                </DashboardButton>
              </div>
            </div>
            <div className="app-products__upload__images">
              {images.map((file, index) =>
                <div key={index} onClick={(e) => e.stopPropagation()} className="app-products__upload__image shadow">
                  <img src={file} alt="" />
                  <span
                    onClick={(e) => handleDeleteImage(e, index)}
                    className="app-products__upload__image__delete"
                  >
                    <DeleteIcon />
                  </span>
                </div>
              )}
              {files.map((file, index) =>
                <div key={index} onClick={(e) => e.stopPropagation()} className="app-products__upload__image shadow">
                  <img src={file.preview} alt="" />
                  <span
                    onClick={(e) => handleDeleteFile(e, index)}
                    className="app-products__upload__image__delete"
                  >
                            <DeleteIcon />
                          </span>
                </div>
              )}
            </div>
          </>
          }
          {!imagesLength &&
          <div className="app-products__upload__drag-drop__placeholder">
            <div className="app-products__upload__text">
              <UploadIcon/>
              <span>Upload</span>
            </div>
            <p className="app-products__upload__text-help">
              DRAG & DROP FILES HERE
            </p>
          </div>
          }
        </div>
        {/* <input {...getInputProps()} type="file" className="hidden"/> */}
      </label>
    </div>
  )
}

export default ProductsModalCreateUpload;
