import "./DashboardButton.scss";
import clsx from "clsx";

function DashboardButton({
   color = "primary",
   outline = false,
   dense = false,
   className = "",
   icon = "",
   size = "",
   children,
   ...rest
}) {
  return (
    <button
      {...rest}
      className={clsx(
        className,
        `app-dashboard-button app-dashboard-button--${color}`,
        outline && `app-dashboard-button--${color}--outline`,
        dense && `app-dashboard-button--dense`,
          size && `app-dashboard-button--${size}`
      )}
    >
      <span className="app-dashboard-button__content">
        {icon && <i className="app-dashboard-button__icon">{icon}</i>}
        <span>{children}</span>
      </span>
    </button>
  )
}

export default DashboardButton;
