import './InputPrice.scss';
import clsx from 'clsx';
import React, {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import Select from '../Select/Select';
import { fiatCurrencies, currencySymbol } from '../../../../utils/string';
import { useSelector } from 'react-redux';
import { formatw } from 'components/Common/inputTable/EditableCell';

const InputPrice = forwardRef(
  (
    {
      placeholder = '',
      className = '',
      block = false,
      extendStep = true,
      error = '',
      currencies = [],
      onCurrencyChange = null,
      label = null,
      currency = null,
      disableCurrencies = false,
      hideCurrency = false,
      ...rest
    },
    ref
  ) => {
    // const { current_account } = useSelector(
    //   (state) => state.auth
    // );
    const [selectedCurrency, setSelectedCurrency] =
      useState("usd");

    useEffect(() => {
      if (currency) setSelectedCurrency(
        currency
      );
    }, [currency]);

    const handleCurrencySelect = (e) => {
      setSelectedCurrency(e.value);
      onCurrencyChange && onCurrencyChange(e);
    };

    return (
      <div className='app-input-price'>
        <div className='app-input-price__currency'>
          {currency
            ? currencySymbol(currency)
            : ''}
        </div>
        <input
          value={rest.value}
          ref={ref}
          className={clsx(
            className,
            'app-input-price__input placeholder-white',
            block && 'app-input-price__input--block',
            error && 'app-input-price__input--error'
          )}
          // pattern={fiatCurrencies[currency].zero_decimal ? null : "^\d*(\.\d{0,2})?$"}
          onKeyPress={(e) => {
            var i = e.target.value.indexOf('.');
            var start = e.target.selectionStart
            if (e.key === "." && start === i) {
              e.target.selectionStart = i + 1;
              e.target.selectionEnd = i + 1;
            }
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          step={extendStep ? (fiatCurrencies[selectedCurrency].zero_decimal ? '0.000000000001' : '0.00000000000001') : (fiatCurrencies[selectedCurrency].zero_decimal ? '0' : '0.01')}
          placeholder={placeholder}
          onChange={(e) => {
            console.log(e.target.value)
            formatw(e, selectedCurrency);
            rest.onChange && rest.onChange(e);
            //
          }}
          name={rest.name}
        // {...rest}
        />
        {label && (
          <div className='app-input-price__label'>
            {label}
          </div>
        )}
        {!hideCurrency && (
          <div className='app-input-price__currencies'>
            <div className='app-input-price__currencies__select'>
              <div className='app-input-price__divider' />
              <Select
                onSelect={handleCurrencySelect}
                // path="assets/images/icons/cryptos"

                options={currencies}
                value={selectedCurrency}
                borderLess
                readOnly={rest.readOnly ?? false}
                disabled={rest.disabled || disableCurrencies}
              />
            </div>
          </div>
        )}
        {error && (
          <p className='app-input-price__input__help'>
            {error}
          </p>
        )}
      </div>
    );
  }
);

export default InputPrice;



export const InputPriceCrypto = forwardRef(
  (
    {
      placeholder = '',
      className = '',
      block = false,
      extendStep = true,
      error = '',
      currencies = [],
      onCurrencyChange = null,
      label = null,
      currency = null,
      disableCurrencies = false,
      notChangable = false,
      ...rest
    },
    ref
  ) => {
    const [selectedCurrency, setSelectedCurrency] =
      useState("BTC");
    const { current_account } = useSelector(
      (state) => state.auth
    );

    useEffect(() => {
      console.log(current_account?.settings?.reporting_currency.toUpperCase())
      setSelectedCurrency(
        currency ? currency : "BTC"
      );
    }, [currency, currencies, current_account]);

    const handleCurrencySelect = (e) => {
      console.log(e.value)
      setSelectedCurrency(e.value);
      onCurrencyChange && onCurrencyChange(e);
    };

    return (
      <div className='app-input-price '>

        <input
          disabled={notChangable}
          ref={ref}
          className={clsx(
            className,
            'app-input-price__input placeholder-white',
            block && 'app-input-price__input--block',
            error && 'app-input-price__input--error'
          )}
          // pattern={fiatCurrencies[currency].zero_decimal ? null : "^\d*(\.\d{0,2})?$"}
          onKeyPress={(e) => {
            var i = e.target.value.indexOf('.');
            var start = e.target.selectionStart
            if (e.key === "." && start === i) {
              e.target.selectionStart = i + 1;
              e.target.selectionEnd = i + 1;
            }
            if (!/[0-9]/.test(e.key)) {

              e.preventDefault();
            }
          }}
          step={extendStep ? (fiatCurrencies[selectedCurrency.toLowerCase()]?.zero_decimal ? '0.000000000001' : '0.00000000000001') : (fiatCurrencies[selectedCurrency].zero_decimal ? '0' : '0.01')}
          placeholder={placeholder}
          onChange={(e) => {

            formatw(e, selectedCurrency);
            console.log(e.target.value)
            rest.onChange && rest.onChange(e);
            //
          }}
          name={rest.name}
        // {...rest}
        />
        {label && (
          <div className='app-input-price__label'>

            {/* <img 
              src={require(`assets/images/icons/cryptos/${selectedCurrency}.svg`)}
              alt={selectedCurrency}
            /> */}
            {label}
          </div>
        )}
        <div className='app-input-price__currencies'>
          <div className='app-input-price__currencies__select'>
            <div className='app-input-price__divider' />
            <Select
              onSelect={handleCurrencySelect}
              path="assets/images/icons/cryptos"
              options={currencies}
              value={selectedCurrency}
              borderLess
              readOnly={rest.readOnly ?? false}
            // disabled={rest.disabled || disableCurrencies}
            // disabled={notChangable}
            />
          </div>
        </div>
        {error && (
          <p className='app-input-price__input__help'>
            {error}
          </p>
        )}
      </div>
    );
  }
);
