import Label from "../../../../../../../Common/Form/Label/Label";
import InputGroup from "../../../../../../../Common/Form/InputGroup/InputGroup";
import {useDispatch, useSelector} from "react-redux";
import {useUpdateInvoice} from "../../../../../../../../api/customer/invoice";
import {setDraftInvoice} from "../../../../../../../../store/invoice/invoiceSlice";

function InvoiceCryptoPadding({ register }) {
  const dispatch = useDispatch();
  const { draft_invoice } = useSelector((state) => state.invoice);
  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  const handleCryptoPaddingBlur = (e) => {
    if (!draft_invoice) return;

    if (draft_invoice.crypto_payment_padding.toString() === e.target.value) return;

    const _amount = Number(parseFloat(e.target.value).toFixed(2));
    mutateUpdateInvoice({ crypto_payment_padding: _amount ?? 0 }, {
      onSuccess: (data) => dispatch(setDraftInvoice(data.data))
    })
  }

  return (
    <div>
      <Label title="Cryptocurrency Padding" />
      <InputGroup
        {...register("crypto_payment_padding", {
          onBlur: handleCryptoPaddingBlur
        })}
        type="number"
        className="w-1/2"
        min="0"
        append="%"
      />
    </div>
  )
}

export default InvoiceCryptoPadding;