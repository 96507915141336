import Checkbox from "../../Form/Checkbox/Checkbox";
import clsx from "clsx";
import DropdownFiltersFormSelect from "../DropdownFiltersForm/DropdownFiltersFormSelect";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToolTip } from "pages/Dashboard/Balance/Swaps/SwapsTable";
import InfoIcon from "assets/images/icons/info.svg";

function DropdownFiltersSelect({
  form: {
    trigger,
    setValue,
    watch,
    formState: { errors },
  },
  onToggle,
  state = false,
  placeholder = "",
  field = "",
  options = [],
  disabled = false,
  tooltip = "",
  title = null,
  defaultVal = null
}) {
  const fieldValue = watch(field);
  const { current_account } = useSelector((state) => state.auth);

  useEffect(() => {
    // Set defaults
    if (field === "currency") {
      setValue("currency", current_account?.settings?.reporting_currency);
    }

    if (defaultVal) setValue(field, defaultVal);
  }, [field, defaultVal, setValue, current_account]);

  const handleOpen = () => {
    if (disabled) return;

    onToggle && onToggle(!state);
  };

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
          disabled={disabled}
        />
        <span className="text-small">{title ?? placeholder}
        
        </span>
        {
            tooltip && <ToolTip tooltipHtml={"true"} icon={InfoIcon} tooltip={tooltip} />
        }
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <DropdownFiltersFormSelect
              disabled={disabled}
              onChange={(value) => {
                setValue(field, value);
                trigger(field);
                if (field === "currency") setValue("crypto_currency", "");
                if (field === "crypto_currency") setValue("currency", "");
              }}
              placeholder={placeholder}
              options={options}
              error={errors?.[field]?.message}
              value={fieldValue}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersSelect;
