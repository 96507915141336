import {useCallback, useEffect, useState} from "react";

const useCopyToClipboard = (text, interval = 3000) => {
  const [copyStatus, setCopyStatus] = useState('inactive')
  const copy = useCallback(() => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text).then(
        () => setCopyStatus('copied'),
        () => setCopyStatus('failed'),
      )
    } else {
      document.execCommand('copy', true, text);
      setCopyStatus('copied');
    }
  }, [text])

  useEffect(() => {
    let id;

    if (copyStatus === "copied") {
      id = setTimeout(() => {
        setCopyStatus('inactive');
      },interval);
    }

    return () => {
      clearTimeout(id);
    };
  }, [copyStatus, interval])

  return [copy, copyStatus]
}

export default useCopyToClipboard;