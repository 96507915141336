import DashboardTable from '../../../../Dashboard/DashboardTable/DashboardTable';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DateIcon } from '../../../../../assets/images/icons/date.svg';
import { useSelector } from 'react-redux';
import { useFetchPaymentsLink } from '../../../../../api/payment/paymentLink';
import { dateFormatter } from '../../../../../utils/date';
import {
  currencyFormatter,
  currencySymbol,
} from '../../../../../utils/string';
import CopyToClipboardBoxID from '../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import LabelTag from '../../../../Common/LabelTag/LabelTag';
import useTablePagination from '../../../../../hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PaymentLinkTableActions from './PaymentLinkTableActions/PaymentLinkTableActions';

const PaymentLinksTable = ({ filters = {} }) => {
  const navigate = useNavigate();
  const { current_account } = useSelector(
    (state) => state.auth
  );
  const { selected_payment_link } = useSelector(
    (state) => state.paymentLink
  );
  const [refetch, setRefetch] = useState(true);
  const [data, setData] = useState([]);
  // const [deactivateInvoice, setDeactivatePaymentLink] =
  //   useState(null);
  const [showActionModal, setShowActionModal] =
    useState(false);
  const [tableRows, setTableRows] = useState([]);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows, filters, 15);

  useEffect(() => {
    setRefetch(true);
  }, [current_account, selected_payment_link, filters]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
  }, [requestQuery]);

  const onFetchPaymentLinkSuccess = (data) => {
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        url: row.url,
        active: row.active,
        description: retrieveProductDescription(row) ?? '-',
        price: paymentLinkAmount(
          row,
          row.line_items.data[0].price
        ),
        currency: row.currency,
        created: dateFormatter(row.created_at, true),
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const retrieveProductDescription = (row) => {
    if (row.description) {
      return row.description;
    } else {
      const products = row.line_items.data;
      if (products.length > 0) {
        if (products.length > 2) {
          const firstProduct = products[0].product;
          const remainingItems = products.length - 1;
          return `${firstProduct.name} and ${remainingItems} more`;
        } else if (products.length === 2) {
          const firstProduct = products[0].product;
          const secondProduct = products[1].product;
          return `${firstProduct.name} and ${secondProduct.name}`;
        } else {
          const firstProduct = products[0].product;
          return firstProduct.name;
        }
      } else {
        return '-';
      }
    }
  };

  const paymentLinkAmount = (row, price) => {
    let description = '';
    if (
      !row.computed_amounts.amount &&
      !row.computed_amounts.amount_recurring
    ) {
      if (
        row.subscription_data &&
        row.subscription_data.trial_period_days
      ) {
        if (row.computed_amounts.amount) {
          description = `${currencyFormatter(
            row.currency,
            row.computed_amounts.amount
          )} and a ${row.subscription_data.trial_period_days
            } day trial`;
        } else {
          description = `${row.subscription_data.trial_period_days} day trial`;
        }
      } else {
        if (price.custom_unit_amount.preset) {
          description = `${currencyFormatter(
            row.currency,
            price.custom_unit_amount.preset
          )} (Preset)`;
        } else
          description = `Customer chooses amount - ${row.currency.toUpperCase()}`;
      }
    } else {
      if (row.contains_recurring_price) {
        if (!row.has_metered_price) {
          if (row.computed_amounts.amount) {
            description = `
            ${currencyFormatter(
              row.currency,
              row.computed_amounts.amount
            )}
            } then 
            }${currencyFormatter(
              row.currency,
              row.computed_amounts.amount_recurring
            )}
            / ${row.subscription_data.interval_count > 1
                ? [
                  row.subscription_data.interval_count +
                  ' ' +
                  row.subscription_data.interval +
                  's',
                ]
                : row.subscription_data.interval
              }`;
          } else {
            description = `${currencyFormatter(
              row.currency,
              row.computed_amounts.amount_recurring
            )}
            / ${row.subscription_data.interval_count > 1
                ? [
                  row.subscription_data.interval_count +
                  ' ' +
                  row.subscription_data.interval +
                  's',
                ]
                : row.subscription_data.interval
              }`;
          }
        } else {
          if (row.computed_amounts.amount) {
            description = `${currencyFormatter(
              row.currency,
              row.computed_amounts.amount
            )} then `;
          }

          if (row.computed_amounts.amount_recurring) {
            description =
              description +
              `${currencyFormatter(
                row.currency,
                row.computed_amounts.amount_recurring
              )} + metered usage / ${row.subscription_data.interval_count > 1
                ? [
                  row.subscription_data.interval_count +
                  ' ' +
                  row.subscription_data.interval +
                  's',
                ]
                : row.subscription_data.interval
              }`;
          } else {
            description =
              description +
              `metered usage / ${row.subscription_data.interval_count > 1
                ? [
                  row.subscription_data.interval_count +
                  ' ' +
                  row.subscription_data.interval +
                  's',
                ]
                : row.subscription_data.interval
              }`;
          }
        }
      } else {
        description = `${currencyFormatter(
          row.currency,
          row.computed_amounts.amount
        )}`;
      }
    }
    return description;
  };

  const onFetchPaymentLinkError = () => {
    setRefetch(false);
  };

  const { isFetching: isFetchingData } =
    useFetchPaymentsLink({
      onSuccess: onFetchPaymentLinkSuccess,
      onError: onFetchPaymentLinkError,
      enabled: refetch,
      params: {
        limit: limitQuery,
        ...requestQuery,
        ...filters,
        expand: [
          'line_items.data.product',
          'line_items.data.price',
        ],
      },
    });

  const handleRowClick = (row) => {
    // dispatch(setSelectedPaymentLink(row._data));
    navigate(`/dashboard/payment_link/${row.id}`);
  };

  const handleActionClick = (row) => {
    setShowActionModal(true);
  };

  // const handleDeactivateClick = (row) => {
  //   setDeactivatePaymentLink(`${row.id}`);
  //   setShowDeleteModal(true);
  // };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'URL Link',
        accessor: 'url',
        width: 150,
        Cell: (cell) => {
          return (
            <div className='text-tertiary font-semibold'>
              <CopyToClipboardBoxID
                text={cell.value}
                className='text-tertiary'
                index={cell.row.index}
              />
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'active',
        width: 60,
        Cell: (cell) => {
          if (cell.value === true) {
            return (
              <LabelTag color='primary'>
                <div className='flex items-center gap-1'>
                  <span>Active</span>
                </div>
              </LabelTag>
            );
          } else {
            return (
              <LabelTag color='danger'>
                <div className='flex items-center gap-1'>
                  <span>Archived</span>
                </div>
              </LabelTag>
            );
          }

          // return (

          //   <>
          //     {cell.value === 'true' && (
          //       <LabelTag color='primary'>
          //         <div className='flex items-center gap-1'>
          //           <span>Active</span>
          //           <FaArrowAltCircleDown className='text-danger' />
          //         </div>
          //       </LabelTag>
          //     )}
          //     {cell.value === 'false' && (
          //       <LabelTag color='danger'>
          //         <div className='flex items-center gap-1'>
          //           <span>Archived</span>
          //           <FaArrowAltCircleUp className='text-tertiary' />
          //         </div>
          //       </LabelTag>
          //     )}
          // //   </>
          // );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        align: 'center',
        width: 100,
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: () => 'Price',
        accessor: 'price',
        width: 150,
        maxWidth: 80,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div
              className='app-customers__modal__earning'
              style={{ fontSize: '1.2rem' }}>
              {
                !cell.value.includes('Customer chooses amount') &&
                currencySymbol(row.currency)
              }
              {cell.value}
              <span>{
                !cell.value.includes('Customer chooses amount') &&
                row.currency?.toUpperCase()
              }</span>
            </div>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created',
        maxWidth: 95,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              <div>
                <DateIcon className='mr-1.5' />
              </div>
              <span className='text-light'>
                {cell.value}
              </span>
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'icon',
        width: 10,
        Cell: (cell) => (
          <PaymentLinkTableActions
            paymentLink={cell.row.original.data}
            showActionModal={handleActionClick}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="Payment Links"
        />
      </div>
    </>
  );
};

export default PaymentLinksTable;
