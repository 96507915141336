import "./SectionCarbonZero.scss";
import Stack from "../../../Common/Stack/Stack";
import {ReactComponent as CarbonZeroIcon} from "../../../../assets/images/icons/carbon-zero.svg";

const carbonData = [
  {
    icon: "image-1",
    title: "We are in this together!",
    text: `Walio has partnered with WWF to allow Merchants to make Climate change 
      donations from their payments. Together we can make the world have a better future.`
  },
  {
    icon: "image-2",
    title: "A greener and Brighter future",
    text: `Collect payments on behalf of your users, 
      then quickly and easily transfer their payments to their Link Account. 
      Linked Accounts can then create payouts and view the payments they have received from sales.`
  }
]

function SectionCarbonZero() {
  return (
    <div id="carbon-zero" className="section-carbon-zero">
      <div className="app-container">
        <h3 className="h3 section-products__section__subtitle">
          <CarbonZeroIcon className="section-products__section__subtitle__icon" />
          Carbon Zero
        </h3>
        <h1 className="h1 section-products__section__title section-carbon-zero__title">
          We care about Carbon emissions and Climate change, let your customer know you do too!
        </h1>
        <p className="text section-products__section__text">
          Join our Carbon Zero program and start contributing a percent for your
          payments to fight Carbon Emissions and Climate Change. Get a Carbon zero
          badge on your hosted checkout page to let your customers know that their
          purchase will be used to contribute to helping make the World a better place.
        </p>
        <Stack columns="1" gap="24" className="md:grid-cols-2 section-retail__features">
          {carbonData.map((item, index) => {
            const SVGIcon = require(`../../../../assets/images/sections/carbon-zero/${item.icon}.svg?svgr`).default;
            return (
              <div key={index} className="section-retail__features__content">
                <div className="section-retail__features__content__icon">
                  <SVGIcon />
                </div>
                <h5 className="h5 section-products__section__info section-retail__features__content__title">
                  {item.title}
                </h5>
                <p className="text-small section-retail__features__content__text">
                  {item.text}
                </p>
              </div>
            )
          })}
        </Stack>
        <p className="text section-products__section__text section-carbon-zero__text">
          Therefore, Walio aims to implement more greener solutions such as the use of renewable energy,
          reduce paper and plastic waste, and make regular contributions to various charities and
          projects working in the carbon emission and climate change fields.

          <span>We are proud to be able to give our Merchants the ability to participate as well!</span>
        </p>
      </div>
    </div>
  )
}

export default SectionCarbonZero;
