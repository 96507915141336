import "./SectionPayments.scss";
import {ReactComponent as PaymentsIcon} from "../../../../assets/images/icons/payments.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/payments/illustration.svg";
import Stack from "../../../Common/Stack/Stack";
import {Link} from "react-router-dom";

const benefitsData = [
  {
    icon: "house",
    text: `With payment solutions designed for any business type,
      from e-commerce to brick and mortar stores,
      <span> Walio has a solution that would meet your needs.</span>`
  },
  {
    icon: "cog",
    text: `<span>Start accepting payments within minutes.</span> 
      Through your account Dashboard or the powerful APIs, 
      securely accept and process Cryptocurrency payments.`
  },
  {
    icon: "charge",
    text: `No charge backs. Cryptocurrency payments offer a 
      secure and fraud free way of accepting payments. 
      Because they are immutable, they can <span>never be charged back</span>.`
  },
  {
    icon: "users",
    text: `<span>Expand your business reach</span>. With over 100+ million Cryptocurrency users, 
      expose your business to a near and growing market of customers.`
  },
  {
    icon: "customers",
    text: `<span>Create Happy Customers</span>. Offer more payment options, 
      giving your customers more freedom in how they make purchases. 
      Cryptocurrency payments are the most innovative payment solutions to date.`
  },
  {
    icon: "hosted",
    text: `<span>Hosted Payment Pages</span>. Your integration for Walio payments is made simpler 
      with our Hosted Payment pages. Customise the branding of them to suite your business needs. 
      They support discounts, tax rates as well as shipping rates and options.`
  }
]

const integrationsData = [
  {
    title: "Payment Links",
    icon: "links",
    text: "Create shareable payment links to start selling your products with or without a website!"
  },
  {
    title: "Powerful Invoicing",
    icon: "invoicing",
    text: "Create simple and complex invoices for whatever your business needs are."
  },
  {
    title: "Automated Subscriptions",
    icon: "subscriptions",
    text: "Sign your customer up for automated recurring payments, great for any subscription based business."
  }
]

function SectionPayments({ scrollToContact }) {
  return (
    <div id="payments" className="section-payments">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <PaymentsIcon className="section-products__section__subtitle__icon" />
              Payments
            </h3>
            <h1 className="h1 section-products__section__title">
              The most powerful and flexible Cryptocurrency Payment Platform.
            </h1>
            <p className="text section-products__section__text">
              Start accepting worldwide Cryptocurrency payments designed for all Business types.
              Increase conversions and expand out to international Customers.
            </p>
            <div>
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
                  Start Now
                </Button>
              </Link>
              <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
            </div>
          </div>
          <div className="section-payments__illustration">
            <Illustration />
          </div>
        </Stack>
        <div className="section-payments__benefits">
          <h5 className="h5 section-products__section__info section-payments__benefits__title">
            Benefits of Walio Payments
          </h5>
          <Stack columns="1" gap="12" className="sm:grid-cols-2 md:grid-cols-3">
            {benefitsData.map((benefit, index) => {
              const SVGIcon = require(`../../../../assets/images/sections/payments/${benefit.icon}.svg?svgr`).default;
              return (
                <div key={index}>
                  <div className="section-payments__benefits__icon">
                    <SVGIcon />
                  </div>
                  <p
                    className="text-small section-payments__benefits__text"
                    dangerouslySetInnerHTML={{__html: benefit.text}}
                  />
                </div>
              )
            })}
          </Stack>
          <div className="section-payments__integration">
            <div className="section-payments__integration__title">Payment integration types</div>
            <Stack columns="1" className="md:grid-cols-2 lg:grid-cols-3">
              {integrationsData.map((integration, index) => {
                const SVGIcon = require(`../../../../assets/images/sections/payments/${integration.icon}.svg?svgr`).default;
                return (
                  <div key={index} className="section-payments__integration__card">
                    <h4 className="h4 section-payments__integration__card__title">{integration.title}</h4>
                    <div className="section-payments__integration__card__content">
                      <div className="section-payments__integration__card__icon">
                        <SVGIcon  />
                      </div>
                      <p className="text-small section-payments__integration__card__text">{integration.text}</p>
                    </div>
                  </div>
                )
              })}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionPayments;
