import {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import useTableFilters from '../../../../hooks/useTableFilters';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRefund } from 'store/refunds/refundSlice';
import { useFetchRefund } from '../../../../api/payment/refunds';
import { toast } from 'react-toastify';
import RefundsModalDetails from '../../../../components/Pages/Dashboard/Payments/RefundsModals/RefundsModalDetails';
import DashboardNavbar from '../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar';
import DropdownFilters from '../../../../components/Common/DropdownFilters/DropdownFilters';
import DashboardButton from '../../../../components/Common/Form/DashboardButton/DashboardButton';
import { ReactComponent as ExportIcon } from '../../../../assets/images/icons/export.svg';
import RefundsTable from '../../../../components/Pages/Dashboard/Payments/RefundsTable/RefundsTable';
import clsx from 'clsx';

const TABLE_FILTERS = [
  { label: 'All', column: 'status', value: '' },
  { label: 'Refunds', column: 'status', value: 'refunds' },
];

const Refunds = () => {
  const [filters, setFilters] = useState({});
  const [refreshRefund, setRefreshRefund] = useState(false);
  const [showDetailsModal, setShowDetailsModal] =
    useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selected_refund, refetch_refund } = useSelector(
    (state) => state.refund
  );
  const { activeFilter, setFilter, isFilterActive } =
    useTableFilters({
      filters: TABLE_FILTERS,
      defaultFilter: TABLE_FILTERS[0],
    });
    const { live_mode_access } = useSelector((state) => state.auth.current_account);

    const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchRefund({
    onSuccess: (data) => {
      dispatch(setSelectedRefund(data));
      setRefreshRefund(false);
    },
    onError: (error) => {
      setRefreshRefund(false);
      navigate(DASHBOARD_PATH + '/refunds');
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshRefund,
    params: {
      limit: 10,
      expand: [
        'balance_transaction',
        'blockchain_transaction',
      ],
    },
  });

  const handleCloseModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedRefund(null));
    navigate(DASHBOARD_PATH + '/refunds');
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_refund);
  }, [selected_refund]);

  useEffect(() => {
    if (!id) {
      handleCloseModal();
      return;
    }
    setRefreshRefund(true);
  }, [refetch_refund, id, handleCloseModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedRefund(null));
    };
  }, [dispatch]);

  const handleFilterClick = (filter) => {
    if (filter && filter.label === 'All') {
      setFilters((state) => {
        const { status, ...rest } = state;

        return rest;
      });
    }

    setFilters(filter);
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({
      column: 'status',
      value: filters?.status ?? '',
    });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(
        ([_, v]) => v != null && v !== ''
      )
    );
  }, [activeFilter]);

  const getFilters = useMemo(() => {
    if (activeFilterFiltered?.status === 'refunds') {
      activeFilterFiltered.captured = true;
    }
    return {
      ...filters,
      ...activeFilterFiltered,
    };
  }, [filters, activeFilterFiltered]);

  return (
    <div className='app-dashboard-container'>
      <DashboardNavbar />
      <div className='app-dashboard__content'>
        <div className='app-dashboard__card'>
          <div className='app-dashboard__card__header'>
            <div className='flex'>
              <div className='app-dashboard__card__title'>
                Refunds
              </div>
              <span className='app-dashboard__card__header__filters'>
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() =>
                      handleFilterClick(filter)
                    }
                    className={clsx(
                      'app-dashboard__card__header__filters__item',
                      isFilterActive(filter) &&
                        'app-dashboard__card__header__filters__item--active'
                    )}>
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className='app-dashboard__card__buttons'>
              <DropdownFilters
                filters={[
                  'payment_status',
                  'customer',
                  'amount',
                  'crypto_amount',
                  'date',
                  'currency',
                  'crypto_currency',
                  'source',
                  'metadata',
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page='refunds'
              />
              <DashboardButton
                color='tertiary'
                outline
                icon={<ExportIcon />}>
                <span className='text-dark'>Export</span>
              </DashboardButton>
            </div>
          </div>
          <RefundsTable filters={getFilters} />
        </div>
        {showDetailsModal && (
          <RefundsModalDetails
            show={showDetailsModal}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default Refunds;
