import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchDiscounts = (params) => appStore.getState().app.livemode
  ? api.live.get("/discounts", { params })
  : api.sandbox.get("/discounts", { params });

export const useFetchDiscounts = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchDiscounts", params],
    () => fetchDiscounts(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchDiscount = (id, params) => appStore.getState().app.livemode
  ? api.live.get(`/discounts/${id}`, { params })
  : api.sandbox.get(`/discounts/${id}`, { params });

export const useFetchDiscount = ({onSuccess, onError, enabled, id, params = null}) => {
  return useQuery(
    ["fetchDiscount", id],
    () => fetchDiscount(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storeDiscount = (data) => appStore.getState().app.livemode
  ? api.live.post("/discounts", data)
  : api.sandbox.post("/discounts", data);

export const useStoreDiscount = () => {
  return useMutation(
    "storeDiscount",
    storeDiscount
  );
}

const updateDiscount = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/discounts/${id}`, data)
  : api.sandbox.patch(`/discounts/${id}`, data);

export const useUpdateDiscount = (id) => {
  return useMutation(
    ["updateDiscount", id],
    (data) => updateDiscount(id, data)
  );
}

const deleteDiscount = (id) => appStore.getState().app.livemode
  ? api.live.delete(`/discounts/${id}`)
  : api.sandbox.delete(`/discounts/${id}`);

export const useDeleteDiscount = (id) => {
  return useMutation(
    ["deleteDiscount", id],
    () => deleteDiscount(id)
  );
}