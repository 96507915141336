import "./Alert.scss";

function Alert({ message, type = "info", icon = null, className }) {
  return (
    <div className={`app-alert app-alert--${type} ${className}`}>
      {icon && icon}
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
}

export default Alert;
