import { useFetchInvoiceLineItems } from "../../../../../../../../api/customer/invoice";
import { useDispatch, useSelector  } from "react-redux";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../../utils/string";
import React, { useCallback, useEffect, useMemo , useState} from "react";
import clsx from "clsx";
import { setInvoiceLineItems } from "../../../../../../../../store/invoice/invoiceSlice";
import { generateInvoiceLineItemData, generateLineItemsTableRowaData, periodDateFormatter, testData2 } from "utils/invoice_utils";

function InvoiceDetailsItems({ enabled = false }) {
  const dispatch = useDispatch();
  const { selected_invoice, invoice_line_items } = useSelector(
    (state) => state.invoice
  );

  useFetchInvoiceLineItems({
    onSuccess: (data) => {console.log(data) ;dispatch(setInvoiceLineItems(data.reverse()))},
    onError: () => null,
    enabled,
    id: selected_invoice?.id,
    params: {
      expand: ["price", "product", "discounts", "tax_rates"],
    },
  });

  const getItemDiscountAmount = (discount_id, discounts) => {
    return discounts.find((item) => item?.discount === discount_id);
  };

  const getInvoiceDiscount = useMemo(() => {
    const discountGroup = selected_invoice.discount_amounts.find(
      (discount) => discount.source === "group"
    );

    if (discountGroup) {

      const discount = selected_invoice.discounts.find(
        (discount) => discount.id === discountGroup.discount
      );

      return { ...discount, amount: discountGroup.amount };
    }

    return null;
  }, [selected_invoice]);

  const getInvoiceTaxRates = useMemo(() => {
    return selected_invoice?.tax_amounts.filter(
      (tax_rate) => tax_rate.source === "group"
    );
  }, [selected_invoice]);

  const getItemTaxRates = useCallback((item) => {
    return item.tax_amounts.filter((tax_rate) => tax_rate.source === "item");
  }, []);
  const [tableData, settableData] = useState([])
  useEffect(() => {
    //to test with sub items invoice line item

    // var data = generateInvoiceLineItemData(testData2)
    var data = generateInvoiceLineItemData(invoice_line_items)
    settableData(generateLineItemsTableRowaData(data))
    console.log("Invoice data : ", generateLineItemsTableRowaData(data));
  }, [invoice_line_items]);
  return (
    <div className="space-y-1.5 pb-2">
      <div className="app-modal-fullscreen__title">Items</div>
      <table className="app-modal-fullscreen__table">
        <thead>
          <tr>
            <th width="45%" className="app-modal-fullscreen__label">
              ITEMS
            </th>
            <th width="10%" className="app-modal-fullscreen__label">
              QTY
            </th>
            <th width="15%" className="app-modal-fullscreen__label">
              PRICE
            </th>
            <th width="15%" className="app-modal-fullscreen__label">
              TAX
            </th>
            <th width="25%" className="app-modal-fullscreen__label text-right">
              AMOUNT
            </th>
          </tr>
        </thead>
        <tbody className="app-modal-fullscreen__table--align-top">
          {tableData.map((item, index) => {
            if (item.type === "period")
            {
            return <tr key={index}>
                <td>
                  <span className="app-modal-fullscreen__value">
                    <span className="text-dark font-semibold">
                      {
                       item.data
                 }
                    </span>
                  </span>

                </td>
            </tr>

            }
            else if (item.type === "sub_item")
            {
              return <tr key={index}>
   
              <td  >
                <span style={{marginLeft : "20px"}} className="app-modal-fullscreen__value">
                  <span className="text-dark font-normal">
                    {item.data.name}
                  </span>
                </span>

              </td>
              <td>{item.data.quantity}</td>
              <td>
                <div className="app-customers__modal__earning">
                  <div className="font-normal">
              {    item.data.unit_amount}
                  </div>
                </div>
              </td>
              <td>
                {/* <div className="space-x-2">
                  {getItemTaxRates(item).length > 0
                    ? getItemTaxRates(item).map((tax_rate, index) => (
                        <span key={index}>
                          {tax_rate.percent}%{tax_rate.inclusive ? " inc." : ""}
                          {getItemTaxRates(item).length > 1 &&
                            getItemTaxRates(item).length !== index + 1 &&
                            ", "}{" "}
                        </span>
                      ))
                    : getInvoiceTaxRates.length > 0 &&
                      getInvoiceTaxRates.map((tax_rate, index) => (
                        <span key={index}>
                          {tax_rate.percent}%{tax_rate.inclusive ? " inc." : ""}
                        </span>
                      ))}
                </div> */}
              </td>
              <td>
                <div className="app-customers__modal__earning justify-end">
                  <div className="font-normal">
                    {item.data.amount}
                  </div>
                </div>

              </td>
            </tr>
            }
            return <tr key={index}>
   
              <td>
                <span className="app-modal-fullscreen__value">
                  <span className="text-dark font-semibold">
                    {item.data.name}
                  </span>
                </span>

              </td>
              <td>{item.data.quantity}</td>
              <td>
                <div className="app-customers__modal__earning">
                  <div className="font-normal">
              {    item.data.unit_amount}
                  </div>
                </div>
              </td>
              <td>
                {/* <div className="space-x-2">
                  {getItemTaxRates(item).length > 0
                    ? getItemTaxRates(item).map((tax_rate, index) => (
                        <span key={index}>
                          {tax_rate.percent}%{tax_rate.inclusive ? " inc." : ""}
                          {getItemTaxRates(item).length > 1 &&
                            getItemTaxRates(item).length !== index + 1 &&
                            ", "}{" "}
                        </span>
                      ))
                    : getInvoiceTaxRates.length > 0 &&
                      getInvoiceTaxRates.map((tax_rate, index) => (
                        <span key={index}>
                          {tax_rate.percent}%{tax_rate.inclusive ? " inc." : ""}
                        </span>
                      ))}
                </div> */}
              </td>
              <td>
                <div className="app-customers__modal__earning justify-end">
                  <div className="font-normal">
                    {item.data.amount}
                  </div>
                </div>

              </td>
            </tr>
            
            
            
            // <tr key={index}>
            //   <td>
            //     <span className="app-modal-fullscreen__value">
            //       <span className="text-dark font-semibold">
            //         {item.name}
            //       </span>
            //     </span>

            //   </td>
            //   <td>{item.quantity}</td>
            //   <td>
            //     <div className="app-customers__modal__earning">
            //       <div className="font-normal">
            //   {    item.unit_amount}
            //       </div>
            //     </div>
            //   </td>
            //   <td>
            //     {/* <div className="space-x-2">
            //       {getItemTaxRates(item).length > 0
            //         ? getItemTaxRates(item).map((tax_rate, index) => (
            //             <span key={index}>
            //               {tax_rate.percent}%{tax_rate.inclusive ? " inc." : ""}
            //               {getItemTaxRates(item).length > 1 &&
            //                 getItemTaxRates(item).length !== index + 1 &&
            //                 ", "}{" "}
            //             </span>
            //           ))
            //         : getInvoiceTaxRates.length > 0 &&
            //           getInvoiceTaxRates.map((tax_rate, index) => (
            //             <span key={index}>
            //               {tax_rate.percent}%{tax_rate.inclusive ? " inc." : ""}
            //             </span>
            //           ))}
            //     </div> */}
            //   </td>
            //   <td>
            //     <div className="app-customers__modal__earning justify-end">
            //       <div className="font-normal">
            //         {item.amount}
            //       </div>
            //     </div>

            //   </td>
            // </tr>
          
          }
        )}
          <tr className="app-modal-fullscreen__table__tr--borderless">
            <td />
            <td />
            <td />
            <td
              className={clsx(selected_invoice.amount_paid > 0 && "border-b")}
            >
              <div className="flex">
                <div className="text-left">
                  <div className="font-semibold">Subtotal</div>
                  {getInvoiceDiscount && (
                    <div className="whitespace-nowrap">
                      {getInvoiceDiscount.name}{" "}
                      {getInvoiceDiscount.amount_off &&
                        `(${currencySymbol(
                          selected_invoice.currency
                        )}${currencyFormatter(
                          selected_invoice.currency,
                          getInvoiceDiscount.amount_off
                        )} off)`}
                      {getInvoiceDiscount.percent_off &&
                        `(${getInvoiceDiscount.percent_off}% off)`}
                    </div>
                  )}
                  {selected_invoice.shipping_rates.map(
                    (shipping_rate, index) => (
                      <div
                        key={index}
                        className="whitespace-nowrap font-semibold"
                      >
                        {shipping_rate?.description
                          ? shipping_rate.description
                          : "Shipping"}
                      </div>
                    )
                  )}
                  {selected_invoice.tax > 0 && (<div className="font-semibold">Total excluding tax</div>)}
                  {selected_invoice.tax_amounts.map((tax_rate, index) => (
                    <div className="whitespace-nowrap" key={index}>
                      {tax_rate.display_name} ({tax_rate.percent}%
                      {tax_rate.inclusive ? " incl." : ""})
                    </div>
                  ))}
                  <div className="font-semibold">Total</div>
                  {selected_invoice.applied_balance > 0 && (<div className="font-semibold">Applied Balance</div>)}
                  <div className="font-semibold">Amount due</div>
                  {selected_invoice.amount_paid > 0 && (
                    <div className="font-semibold">Paid</div>
                  )}
                  {selected_invoice.amount_remaining > 0 && (
                    <div className="font-semibold">Remaining</div>
                  )}
                </div>
              </div>
            </td>
            <td
              className={clsx(selected_invoice.amount_paid > 0 && "border-b")}
            >
              <div className="app-customers__modal__earning justify-end">
                {currencySymbol(selected_invoice.currency)}
                {currencyFormatter(
                  selected_invoice.currency,
                  selected_invoice.subtotal
                )}{" "}
              </div>
              {getInvoiceDiscount && (
                <div className="app-customers__modal__earning justify-end">
                  -{currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    getInvoiceDiscount.amount
                  )}
                </div>
              )}
              {selected_invoice.shipping_rates.map((shipping_rate, index) => (
                <div
                  key={index}
                  className="app-customers__modal__earning justify-end"
                >
                  {currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    shipping_rate.amount
                  )}
                </div>
              ))}
              {selected_invoice.tax > 0 && (
                <div className="app-customers__modal__earning justify-end">
                {currencySymbol(selected_invoice.currency)}
                {currencyFormatter(
                  selected_invoice.currency,
                  selected_invoice.total_excluding_tax
                )}
                </div>
              )}
              {selected_invoice.tax_amounts.map((tax_rate, index) => (
                <div
                  key={index}
                  className="app-customers__modal__earning justify-end"
                >
                  {currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    tax_rate.amount
                  )}
                </div>
              ))}
              <div className="app-customers__modal__earning justify-end">
                {currencySymbol(selected_invoice.currency)}
                {currencyFormatter(
                  selected_invoice.currency,
                  selected_invoice.total
                )}
              </div>
              {selected_invoice.applied_balance > 0 && (
                <div className="app-customers__modal__earning justify-end">
                  {currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    selected_invoice.applied_balance
                  )}
                </div>
              )}
              <div className="app-customers__modal__earning justify-end">
                {currencySymbol(selected_invoice.currency)}
                {currencyFormatter(
                  selected_invoice.currency,
                  selected_invoice.amount_due
                )}
              </div>
              {selected_invoice.amount_paid > 0 && (
                <div className="app-customers__modal__earning justify-end">
                  {selected_invoice.amount_paid > 0 && "-"}
                  {currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    selected_invoice.amount_paid
                  )}
                </div>
              )}
              {selected_invoice.amount_remaining > 0 && (
                <div className="app-customers__modal__earning justify-end">
                  {currencySymbol(selected_invoice.currency)}
                  {currencyFormatter(
                    selected_invoice.currency,
                    selected_invoice.amount_remaining
                  )}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default InvoiceDetailsItems;
