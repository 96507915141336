import Input from "../../../components/Common/Form/Input/Input";
import Stack from "../../../components/Common/Stack/Stack";
import Label from "../../../components/Common/Form/Label/Label";
import Button from "../../../components/Common/Form/Button/Button";
import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import Logo from '../../../assets/images/logo.svg';
import FacebookIcon from '../../../assets/images/social/facebook.png';
import TwitterIcon from '../../../assets/images/social/twitter.png';
import TelegramIcon from '../../../assets/images/social/telegram.png';
import {useForm} from "react-hook-form";
import {useForgotPassword} from "../../../api/auth/auth";
import Alert from "../../../components/Common/Alert/Alert";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

const forgotPasswordSchema = yup.object({
  email: yup.string().email().required().label('Email'),
}).required();

function ForgotPassword() {
  const { handleSubmit, register, reset, formState:{ errors } } = useForm({
    resolver: yupResolver(forgotPasswordSchema)
  });
  const { mutate, data, error, isError, isSuccess, isLoading } = useForgotPassword();

  const onSubmit = ({ email }) => {
    mutate(email, {
      onSuccess: () => reset()
    });
  }

  const fieldError = (field) => {
    return errors[field]?.message || (error && error.response?.data.error.param === field);
  }

  return (
    <div className="app-auth min-h-screen">
      <div className="app-container app-auth__container">
        <div className="app-auth__content">
          <div className="app-auth__logo">
            <Link to="/">
              <img src={Logo} alt="Walio" />
            </Link>
          </div>
          <div className="app-auth__card app-auth__card__login">
            <div className="app-auth__card__title app-password__title">Reset your password</div>
            {isError &&
              <Alert
                className="my-4"
                type="danger"
                message={error.response.data.error.message}
              />
            }
            {isSuccess &&
              <Alert
                className="my-4"
                type="success"
                message={data.data.message}
              />
            }
            <p className="app-password__help">
              Enter the email address associated with your account and we’ll send you a link to reset your password.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack columns="1">
                <div>
                  <Label
                    title="Email"
                    error={fieldError("email")}
                  />
                  <Input
                    {...register("email")}
                    type="email"
                    block
                    error={fieldError("email")}
                  />
                </div>
                <Button loading={isLoading}>Continue</Button>
                <Link className="app-password__link" to="/login">Return to Sign in</Link>
              </Stack>
            </form>
          </div>
          <div className="app-auth__card__footer">
            <div>Don’t have an account? <Link to="/register" className="app-auth__card__footer__link">Sign up</Link></div>
          </div>
        </div>
        <div className="app-auth__footer">
          <div>© Walio All rights reserved.</div>
          <div className="app-auth__footer__social">
            <img src={FacebookIcon} alt="" href="https://www.facebook.com/mywalio.io/"/>
            <img src={TwitterIcon} alt="" href="https://twitter.com/walio_official"/>
            <img src={TelegramIcon} alt="" href="https://t.me/walio_official"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;
