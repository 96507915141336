import SelectDiscounts from "../../../../../../../Dashboard/SelectDiscounts/SelectDiscounts";
import { useEffect, useState } from "react";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../../utils/string";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/images/icons/dashboard/delete.svg";
import { useSelector } from "react-redux";
import { useUpdateInvoice } from "../../../../../../../../api/customer/invoice";

function InvoiceDiscounts({ refetchInvoice }) {
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [showDiscountsSelect, setShowDiscountsSelect] = useState(false);
  const { draft_invoice } = useSelector((state) => state.invoice);

  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  useEffect(() => {
    if (draft_invoice?.discounts.length) {
      setSelectedDiscount(draft_invoice?.discounts[0]);
    }
  }, [draft_invoice]);

  const handleDiscountSelect = (discount) => {
    if (!draft_invoice) return;

    mutateUpdateInvoice(
      {
        discounts: [
          {
            discount: discount?.id,
            // promo_code: discount?.promo_code
          },
        ],
      },
      {
        onSuccess: () => {
          refetchInvoice();
          setSelectedDiscount(discount);
          setShowDiscountsSelect(false);
        },
      }
    );
  };

  const handleAddDiscountClick = () => {
    setShowDiscountsSelect(true);
  };

  const handleDeleteDiscountClick = () => {
    mutateUpdateInvoice(
      {
        discounts: "",
      },
      {
        onSuccess: () => {
          refetchInvoice();
          setSelectedDiscount(null);
          setShowDiscountsSelect(false);
        },
      }
    );
  };

  return (
    <div>
      <div className="app-invoices__modal__earning">Discount</div>
      {selectedDiscount && (
        <div>
          <div className="app-invoices__modal__invoice-discount-tax mb-1">
            <div>
              <span className="mr-2">{selectedDiscount.name}</span>
              {selectedDiscount.amount_off && (
                <>
                  {currencySymbol(selectedDiscount.currency)}{" "}
                  {currencyFormatter(
                    selectedDiscount.currency,
                    selectedDiscount.amount_off
                  )}
                </>
              )}
              {selectedDiscount.percent_off && (
                <>{selectedDiscount.percent_off}%</>
              )}
            </div>
            <DeleteIcon
              onClick={() => handleDeleteDiscountClick()}
              className="cursor-pointer"
            />
          </div>
        </div>
      )}
      {!selectedDiscount && showDiscountsSelect && (
        <div className="w-8/12">
          <SelectDiscounts onSelect={handleDiscountSelect} />
        </div>
      )}
      {!selectedDiscount && !showDiscountsSelect && (
        <span
          onClick={handleAddDiscountClick}
          className="app-invoices__modal__link"
        >
          Add discount
        </span>
      )}
    </div>
  );
}

export default InvoiceDiscounts;
