import "./Invoices.scss";
import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../../components/Common/Form/DashboardButton/DashboardButton";
import InvoicesTable from "../../../../components/Pages/Dashboard/Billing/Invoices/InvoicesTable/InvoicesTable";
import { ReactComponent as ExportIcon } from "../../../../assets/images/icons/export.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InvoicesModalDetails from "../../../../components/Pages/Dashboard/Billing/Invoices/InvoicesModals/InvoicesModalDetails/InvoicesModalDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  setDraftInvoice,
  setInvoiceAcceptedCryptos,
  setInvoiceCurrency,
  setInvoiceCustomFields,
  setInvoiceLineItems,
  setSelectedInvoice,
  setSelectedInvoiceCustomer,
  setSelectedInvoiceItem,
  setSelectOneTime,
  setShowCreateInvoice,
  setShowProductsSelect,
} from "../../../../store/invoice/invoiceSlice";
import InvoicesModalCreate from "../../../../components/Pages/Dashboard/Billing/Invoices/InvoicesModals/InvoicesModalCreate/InvoicesModalCreate";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useTableFilters from "../../../../hooks/useTableFilters";
import { toast } from "react-toastify";
import { useFetchInvoice } from "../../../../api/customer/invoice";
import DropdownFilters from "../../../../components/Common/DropdownFilters/DropdownFilters";
import ReactTooltip from "react-tooltip";
import CreditNoteModal from "pages/Dashboard/Creditnote/CreditNoteModal";

const TABLE_FILTERS = [
  { label: "All", column: "status", value: "" },
  { label: "Drafts", column: "status", value: "draft" },
  { label: "Outstanding", column: "status", value: "issued" },
  { label: "Overdue", column: "status", value: "over_due" },
  { label: "Paid", column: "status", value: "paid" },
];

function Invoices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selected_invoice,
    refetch_invoice,
    draft_invoice,
    show_create_invoice,
  } = useSelector((state) => state.invoice);
  const [showDetailModal, setShowDetailsModal] = useState(false);
  const { id } = useParams();
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create"  || location.pathname.endsWith("/edit")
  );
  const [showCreditNoteModal, setShowCreditNoteModal] = useState(false);
  const [refreshInvoice, setRefreshInvoice] = useState(false);
  const [filters, setFilters] = useState({});
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchInvoice({
    onSuccess: (data) => {
      dispatch(setSelectedInvoice(data));
      if (location.pathname.endsWith("/edit")) {
        // setShowCreateModal(true);
        dispatch(setDraftInvoice(data));
      }
      setRefreshInvoice(false);
    },
    onError: (error) => {
      setRefreshInvoice(false);
      navigate(DASHBOARD_PATH + "/invoices");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: !!id && (id !== "create" && id !== "edit"),

  });
  useEffect(() => {

    console.log("showPriceModal", location.pathname);
    if (showCreateModal) {
      if (!location.pathname.endsWith("/create" ) ) {
          if (!draft_invoice)
            navigate(location.pathname + "/create");
          else {
            navigate(location.pathname + "/edit");
          }
      } 
    }  
  
  }, [showCreateModal]);
  const handleCloseDetailModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedInvoice(null));
    navigate(DASHBOARD_PATH + "/invoices");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!id) {
      handleCloseDetailModal();
      return;
    }

    setRefreshInvoice(true);
  }, [refetch_invoice, id, handleCloseDetailModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedInvoice(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (draft_invoice) {
      setShowCreateModal(true);
    }
  }, [draft_invoice]);

  useEffect(() => {
    // if (show_create_invoice) setShowCreateModal(true);
  }, [show_create_invoice]);

  useEffect(() => {
    setShowDetailsModal(!!selected_invoice);
  }, [selected_invoice]);

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setDraftInvoice(null));
  };

  const handleNewInvoiceClick = () => {
    dispatch(setShowCreateInvoice(false));
    dispatch(setSelectedInvoiceCustomer(null));
    dispatch(setSelectedInvoiceItem(null));
    dispatch(setShowProductsSelect(false));
    dispatch(setSelectOneTime(false));
    dispatch(setInvoiceAcceptedCryptos([]));
    dispatch(setInvoiceCustomFields([]));
    dispatch(setInvoiceLineItems([]));
    dispatch(setInvoiceCurrency(""));
    dispatch(setDraftInvoice(null));
    setShowCreateModal(true);
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({ column: "status", value: filters?.status ?? "" });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
    );
  }, [activeFilter]);

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...(activeFilter.status ? activeFilter : null),
    };
  }, [filters, activeFilter]);

  const handleFilterClick = (filter) => {
    if (filter && filter.label === "All") {
      setFilters((state) => {
        const { status, ...rest } = state;

        return rest;
      });
    }

    setFilter(filter);
  };

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Invoices</div>
              <span className="app-dashboard__card__header__filters">
                
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => handleFilterClick(filter)}
                    className={clsx(
                      "app-dashboard__card__header__filters__item",
                      isFilterActive(filter) &&
                        "app-dashboard__card__header__filters__item--active"
                    )}
                  >
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
              <DropdownFilters
                filters={[
                  "date",
                  "invoice_type",
                  "invoice_status",
                  "due_date",
                  "currency",
                  "amount_due",
                  "metadata",
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page="invoices"
              />
              <DashboardButton
                onClick={handleNewInvoiceClick}
                color="primary"
                outline
                icon="+"
              >
                <span className="text-dark">New Invoice</span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
            </div>
          </div>
          <InvoicesTable filters={getFilters} />
        </div>
        {showDetailModal && (
          <InvoicesModalDetails
            show={showDetailModal}
            onClose={handleCloseDetailModal}
            setShowCreditNoteModal={setShowCreditNoteModal}
          />
        )}
        {showCreateModal && (
          <InvoicesModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}

        {
          showCreditNoteModal && (
            <CreditNoteModal
              show={showCreditNoteModal}
              onClose={() => setShowCreditNoteModal(false)}
            />
          )
        }
        <ReactTooltip
          id="invoice-modal-create"
          place="bottom"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
          {}
        </ReactTooltip>
      </div>
    </div>
  );
}

export default Invoices;
