import React, { useState } from 'react';
import Label from '../../../../../../../Common/Form/Label/Label';
import {ReactComponent as ArrowDownIcon} from "../../../../../../../../assets/images/icons/dashboard/arrow_down.svg";
import MetadataForm from '../../../../../../../../components/Dashboard/MetadataForm/MetadataForm';
import "./style.scss"
const AdditionalOptions = ({
	register,
	canEditMetadata = true,
	metadata = [],
  setValue
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div onClick={(e)=>{setOpen(!open) ;
         e.stopPropagation()}
         } style={{display : "flex" , alignItems: "center" , marginBottom: "10px", cursor: "pointer"}}>
      	Additional options 
        <ArrowDownIcon className={open  ? "flip" : ""} style={{width : "20px", cursor: "pointer"}}/>
      </div>
      {open && (
      <div>
        <Label title='Description' subtitle='(optional)'/>
        <input className='desc' style={{width : "100%", height: "35px" , fontSize: "0.85rem" , marginBottom: "10px"}} type="text"
          {...register('description')}
          block="true"
          rows='1'
        />
        {canEditMetadata && (
					<div className='space-y-1.5'>
						<MetadataForm
							data={metadata}
							onSave={(e) => setValue('metadata', e)}
							key_cols='4'
							value_cols='8'
							title={<Label title='Metadata' />}
							
						/>
					</div>
				)}
      </div>
      )}
    </div>
  )
};

export default AdditionalOptions;
