import Input from "../../../components/Common/Form/Input/Input";
import Stack from "../../../components/Common/Stack/Stack";
import Label from "../../../components/Common/Form/Label/Label";
import Button from "../../../components/Common/Form/Button/Button";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../../assets/images/logo.svg';
import FacebookIcon from '../../../assets/images/social/facebook.png';
import TwitterIcon from '../../../assets/images/social/twitter.png';
import TelegramIcon from '../../../assets/images/social/telegram.png';
import { useForm } from "react-hook-form";
import { useAuthLogin } from "../../../api/auth/auth";
import Alert from "../../../components/Common/Alert/Alert";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/app/authSlice";
import { useState } from "react";

const loginSchema = yup.object({
  email: yup.string().email().required().label('Email'),
  password: yup.string().required().label('Password'),
}).required();

function Login() {
  const { handleSubmit, register, reset, formState: { errors } } = useForm({
    resolver: yupResolver(loginSchema)
  });
  const { mutate, isLoading, isError, error } = useAuthLogin();
  // const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [is2FA, setIs2FA] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: '',
    otp: '',
  });

  const setUserAccount = (user) => {
    dispatch(setUser(user));
  }

  const onSubmit = (formData) => {
    mutate(formData, {
      onSuccess: (data) => {
        reset();
        if (data.data) {
          if (data.status === 206) {
            setIs2FA(true);
            setData({
              email: formData.email,
              password: formData.password,
            });
          } else {
            setUserAccount(data.data);
            window.location.href = "/dashboard";
          }
        }
      }
    });
  }

  const handleOnChange = (e) => {
    if (e.target.value.length > 6) return;
    setData((prev) => ({
      ...prev,
      otp: e.target.value,
    }));
    if (e.target.value.length === 6) {
      mutate({
        ...data,
        otp: e.target.value,
      }, {
        onSuccess: (data) => {
          if (data.data) {
            setUserAccount(data.data);
            window.location.href = "/dashboard";
          }
        }
      });
    }
  }

  const fieldError = (field) => {
    return errors[field]?.message || (error && error.response?.data.error.param === field);
  }

  return (
    <div className="app-auth min-h-screen">
      <div className="app-container app-auth__container">
        <div className="app-auth__content">
          <div className="app-auth__logo">
            <Link to="/">
              <img src={Logo} alt="Walio" />
            </Link>
          </div>
          <div className="app-auth__card">
            <div className="app-auth__card__title">
              {is2FA ? "Enter Your 2FA Code" : "Sign in to your account"}
            </div>
            {isError && !is2FA &&
              <Alert
                className="my-4"
                type="danger"
                message={error?.error?.message}
              />
            }
            <form onSubmit={handleSubmit(onSubmit)}>
              {is2FA ?
                (<>
                  <Stack columns="1">
                    <div>
                      <Label title="2FA Code" />
                      <Input
                        type="text"
                        value={data.otp}
                        onChange={handleOnChange}
                        className="w-full"
                        error={error?.error?.message}
                      />
                    </div>
                  </Stack>
                </>) :
                (<>
                  <Stack columns="1">
                    <div>
                      <Label
                        title="Email"
                        error={fieldError("email")}
                      />
                      <Input
                        {...register("email")}
                        type="email"
                        block
                        error={fieldError("email")}
                      />
                    </div>
                    <div>
                      <Label
                        title="Password"
                        error={fieldError("password")}
                      />
                      <Input
                        {...register("password")}
                        type="password"
                        block
                        error={fieldError("password")}
                      />
                      <Link to="/forgot_password" className="app-login__forgot-password">Forgot password</Link>
                    </div>
                    <Button loading={isLoading}>Login</Button>
                  </Stack>
                </>)}
            </form>
          </div>
          <div className="app-auth__card__footer">
            <div>Don’t have an account? <Link to="/register" className="app-auth__card__footer__link">Sign up</Link></div>
          </div>
        </div>
        <div className="app-auth__footer">
          <div>© Walio All rights reserved.</div>
          <div className="app-auth__footer__social">
            <img src={FacebookIcon} alt="" href="https://www.facebook.com/mywalio.io/" />
            <img src={TwitterIcon} alt="" href="https://twitter.com/walio_official" />
            <img src={TelegramIcon} alt="" href="https://t.me/walio_official" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
