import ModalFullScreen from "components/Common/ModalFullScreen/ModalFullScreen";
import React, { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelTag from "components/Common/LabelTag/LabelTag";
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import { toast } from "react-toastify";
import {
  capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "utils/string";
import clsx from "clsx";
import { dateFormatter } from "utils/date";
import { methodType, blockchainNetwork } from 'utils/crypto_utils';
import CopyToClipboardBoxID from "components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import MetadataForm from "components/Dashboard/MetadataForm/MetadataForm";
import { useUpdatePayout } from 'api/wallet/payouts';
import {
  setRefetchPayout,
  setSelectedPayout,
} from 'store/payouts/payoutsSlice';
import PayoutMethod from './PayoutModalDetails/PayoutMethod';
import Details from './PayoutModalDetails/Details';
import PayoutLogsTable from "./PayoutLogsTable";
import PayoutEventTable from "./PayoutEventTable";

function PayoutDetails({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const { selected_payout } = useSelector((state) => state.payout);

  useEffect(() => {
      console.log("selected_payout", selected_payout);
  }, []);

  const handleClose = () => {
    onClose && onClose();
  };

  const {
    mutate: mutateUpdatePayout,
    isLoading: isUpdateLoading,
  } = useUpdatePayout(selected_payout?.id, {
    expand: [
      'blockchain_transaction',
      'balance_transaction',
      'network_fee_balance_transaction'
    ],
  });

  const handleMetadataSave = (metadata) => {
    mutateUpdatePayout(
      { metadata },
      {
        onSuccess: (data) => {
          dispatch(setSelectedPayout(data.data));
          // dispatch(setRefetchPayout(Date.now()));
          toast.success('Metadata updated');
        },
      }
    );
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };
  const getIconBrandUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">
              Payout
            </div>
            <CopyToClipboardBoxID 
              text={selected_payout?.id} 
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          <div className="space-y-7">
            <div className="space-y-1">
              <div style={{padding :"10px 0"}} className="flex items-start gap-3">
              {selected_payout.type === "crypto"  ? <>
                <img style={{width : "40px" , height : "40px"}} alt={selected_payout?.crypto_currency}  src={getIconUrl(selected_payout?.crypto_currency)}/>
                <div style={{display : "flex" , flexDirection : "column" , alignItems: "flex-start"}} className="app-customers__modal__balance">
                  <span style={{lineHeight: "1em", fontSize: "40px", fontWeight: 600}} className="text-light font-semibold">
                    {cryptocurrencyFormatter(selected_payout?.crypto_amount)}  
                  </span>
                  <span style={{fontSize: "15px"}} className="text-light">
                    ({currencyPrettier(selected_payout?.currency , selected_payout?.amount)})
                  </span>
                </div>

                <span className="text-light ">{selected_payout.crypto_currency.toUpperCase()}</span>

                  </>
                  : <div style={{lineHeight: "1em", fontSize: "40px", fontWeight: 600}} className="text-light font-semibold" >
                      {currencyPrettier(selected_payout?.currency , selected_payout?.amount)}
                    </div>
                
                }
                <LabelTag
                  color={clsx(
                    selected_payout?.status === 'succeeded' && 'primary',
                    selected_payout?.status === 'failed' && 'danger',
                    selected_payout?.status === 'pending' && 'tertiary',
                    selected_payout?.status === 'confirming' && 'warning',
                    selected_payout?.status === 'cancelled' && 'secondary',
                    selected_payout?.status === 'processing' && 'dark'
                  )}
                >
                  {selected_payout.status}
                </LabelTag>
              </div>
            </div>
            <div className="horizontal-divider" />
            <div className='flex flex-wrap gap-5 divide-x pb-7'>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  DATE
                </div>
                <div className='flex items-center'>
                  <DateIcon className='mr-1.5' />
                  <span className='app-modal-fullscreen__value'>
                    {dateFormatter(
                        selected_payout.created_at,
                        true
                    )}
                  </span>
                </div>
              </div>
              <div className='pl-5 pr-20'>
                <div className='app-modal-fullscreen__label'>
                  TYPE
                </div>
                <div className='app-modal-fullscreen__value'>
                  <span className='text-dark'  >
                      {capitalize(selected_payout.type)}
                  </span>
                </div>
              </div>
              <div className='pl-5 flex'>
                <div>
                  <div className='app-modal-fullscreen__label'>
                  METHOD
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    <span className='text-dark'>
                    {methodType(selected_payout.method)}

                    </span>
                  </div>
                </div>
                {selected_payout.method === 'crypto' && <img   className="self-end"
                  style={{maxHeight: "40px", marginLeft : "10px"}}
                  src={getIconBrandUrl(selected_payout.blockchain_transaction.network)} 
                  alt={blockchainNetwork(selected_payout.blockchain_transaction.network)}
                />}
              </div>
            </div>
            <Details />

            <PayoutMethod/>
            <div className='space-y-1.5 pb-2'>
              <MetadataForm
                data={selected_payout.metadata}
                onSave={handleMetadataSave}
                loading={isUpdateLoading}
              />
            </div>                
            <PayoutLogsTable
              enabled={!!selected_payout} />
            <PayoutEventTable
              enabled={!!selected_payout} />
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
    </>
  );
}

export default PayoutDetails;
