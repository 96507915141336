import {useRef} from "react";
import clsx from "clsx";
import {currencySymbol} from "../../../utils/string";

function InputPrice({
  currency = "",
  value = null,
  style,
  placeholder = "",
  className = "",
  error = "",
  disabled = false,
  ...rest
}) {
  const selectRef = useRef();

  const handleChange = (e) => {
    rest.onChange && rest.onChange(e.target.value);
  }

  return (
    <>
      <div
        ref={selectRef}
        className={clsx(
          className,
          "app-select",
          disabled && "app-select--disabled"
        )}
        style={style}
      >
        <div
          className={clsx(
            "app-select__input",
            error && 'app-select__input--error'
          )}
        >
          <div className="app-select__input__value">
            <div className="relative">
              <input
                disabled={disabled}
                value={value}
                type="number"
                step="any"
                className="app-select__input__value__input"
                placeholder={placeholder}
                onChange={handleChange}
                min={rest.min ?? null}
              />
              {currency && (<div className="absolute left-0 top-0">
                {currencySymbol(currency)}
              </div>)}
            </div>
          </div>
        </div>
      </div>
      {error && <p className="app-input__help">{error}</p>}
    </>
  )
}

export default InputPrice;
