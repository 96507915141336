import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditIcon } from 'assets/images/icons/dashboard/edit.svg';
import {
	capitalize,
	cryptocurrencyFormatter,
	currencyFormatter,
	currencyPrettier,
	currencySymbol,
} from "utils/string";
import clsx from "clsx";
import moment from "moment";
import { dateFormatter } from "utils/date";
import CopyToClipboardBoxID from "components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { Link } from "react-router-dom";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import MetadataForm from "components/Dashboard/MetadataForm/MetadataForm";
import Input from "components/Common/Form/Input/Input";

const Details = () => {
	const [edit, setEdit] = useState(false);
	const { selected_topup } = useSelector((state) => state.topup);

	const handleEditClick = () => {
		setEdit(!edit);
	};

	const getIconUrl = (icon) => {
		try {
			return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
		} catch (err) {
			return null;
		}
	};

	return (
		<div className='space-y-1.5'>
			<div className='flex justify-between'>
				<div className='app-modal-fullscreen__title'>
					Details
				</div>
			</div>
			<div className='horizontal-divider' />
			<div className='pt-4'>
				<div className='grid grid-cols-5 gap-4'>
					<div className='col-span-2 space-y-2 gap-4'>
						{/* <div className='app-modal-fullscreen__kv'>
							<div className='app-modal-fullscreen__kv__key'>
								Topup Type
							</div>
							<div className='app-modal-fullscreen__kv__value'>
								{capitalize(selected_topup?.type)}
							</div>
						</div> */}
						<div className='app-modal-fullscreen__kv'>
							<div className='app-modal-fullscreen__kv__key'>
								{selected_topup?.type === 'crypto' ? 'Crypto Amount' : 'Amount'}
							</div>
							<div className='app-modal-fullscreen__kv__value'>
								{selected_topup?.type === 'crypto' ?
									<Amount
										crypto_amount={selected_topup?.crypto_amount}
										crypto_currency={selected_topup?.crypto_currency}
										amount={selected_topup?.amount}
										currency={selected_topup?.currency}

									/>
									:
									<div>
										{currencyPrettier(selected_topup?.currency, selected_topup?.amount)}
									</div>
								}
							</div>
						</div>
						<div className='app-modal-fullscreen__kv'>
							<div className='app-modal-fullscreen__kv__key'>
								{selected_topup?.type === 'crypto' ? 'Cryptocurrency' : 'Currency'}
							</div>
							<div className='app-modal-fullscreen__kv__value'>
								{selected_topup?.type === 'crypto' ? selected_topup?.crypto_currency : selected_topup?.currency}
							</div>
						</div>
						<div className='app-modal-fullscreen__kv'>
							<div className='app-modal-fullscreen__kv__key'>
								Description
							</div>
							<div className='app-modal-fullscreen__kv__value'>
								<div style={{ alignItems: "center" }} className="flex gap-5 ">
									<Input disabled={!edit} value={selected_topup?.description ?? ""} placeholder="Add a description .." />
									<div className='flex gap-4'>
										{!edit ?
											<DashboardButton
												outline
												color='light'
												size='sm'
												icon={<EditIcon />}
												onClick={handleEditClick}>
												Edit
											</DashboardButton>
											:
											<>
												<DashboardButton
													size='sm'
													onClick={handleEditClick}>
													Save
												</DashboardButton>
												<DashboardButton
													size='sm'
													color="light"
													onClick={handleEditClick}>
													Cancel
												</DashboardButton>
											</>
										}
									</div>
								</div>
							</div>
						</div>
						<div className='app-modal-fullscreen__kv'>
							<div className='app-modal-fullscreen__kv__key'>
								Status
							</div>
							<div className='app-modal-fullscreen__kv__value'>
								{capitalize(selected_topup?.status)}

							</div>
						</div>
						{
							selected_topup?.status === 'failed' &&
							<div className='app-modal-fullscreen__kv'>

								<div className='app-modal-fullscreen__kv__key'>
								Failure Message
								</div>
								<div className='app-modal-fullscreen__kv__value'>
									{capitalize(selected_topup?.failure_message)}

								</div>
							</div>
						}
						<div className='app-modal-fullscreen__kv'>
							<div className='app-modal-fullscreen__kv__key'>
							Balance Transaction
							</div>
							<div className='app-modal-fullscreen__kv__value'>
							{
								selected_topup?.balance_transaction?.id ?
								<Link  
									to={`/dashboard/balance_transactions/${selected_topup?.balance_transaction.id}`}
									className='text-tertiary'
									target='_blank'>
									{selected_topup?.balance_transaction.id}
								</Link>
								:
								"-"
							}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Details;

export function Amount({ crypto_currency, crypto_amount, currency, amount }) {
	const getIconUrl = (icon) => {
		try {
			return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
		} catch (err) {
			return null;
		}
	};
	return (
		<div className="flex items-start gap-1">
			<img style={{ width: "15px", height: "15px" }} alt={crypto_currency} src={getIconUrl(crypto_currency)} />
			<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: "12px" }} className="app-customers__modal__balance">
				<span style={{ lineHeight: "1em", fontSize: "13px" }} className="text-light font-semibold">
					{cryptocurrencyFormatter(crypto_amount)}
				</span>
				<span style={{ fontSize: "12px" }} className="text-light">
					( {currencyPrettier(currency, amount)})
				</span>
			</div>
			{/* <span style={{fontSize: "10px"}} className="text-light ">{crypto_currency.toUpperCase()}</span> */}
		</div>
	)
}