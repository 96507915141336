import Label from "../../../../../../Common/Form/Label/Label";
import React from "react";
import Input from "../../../../../../Common/Form/Input/Input";

function ProductsModalUrl({ register, error }) {
  return (
    <div>
      <Label title="URL" subtitle='(optional)' error={error} />
      <Input {...register("url")} block error={error} />
    </div>
  );
}

export default ProductsModalUrl;
