import { useFetchBalance } from 'api/wallet/balance';
import { useFetchCurrencies } from 'api/wallet/currencies';
import { useStoreTopup } from 'api/wallet/topups';
import Button from 'components/Common/Form/Button/Button';
import ButtonGradient from 'components/Common/Form/Button/ButtonGradient/ButtonGradient';
import Input from 'components/Common/Form/Input/Input';
import { InputPriceCrypto } from 'components/Common/Form/InputPrice/InputPrice';
import Select from 'components/Common/Form/Select/Select';
import Modal from 'components/Common/Modal/Modal'
import SelectNetwork from 'components/Dashboard/SelectNetwork/SelectNetwork';
import moment from 'moment';
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { currencySymbol } from 'utils/string';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import Logo from "assets/images/logo-small.svg";
import InfoIcon from "assets/images/icons/info.svg";
import "../../../styles.scss";
import QRCodeStyling from "qr-code-styling";
import CopyToClipboardBoxID from 'components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import Label from 'components/Common/Form/Label/Label';
import ExpiryTimer from 'components/Common/HelperComponents/ExpiryTimer';

const TopupFinish = ({ data, onClose, show, currencies , networks }) => {
	const [currency, setCurrency] = useState(data?.blockchain_transaction?.currency);
	const [network, setNetwork] = useState(data?.blockchain_transaction?.network);

	const handleClose = () => {
		onClose && onClose();
	};
	const handleNewEstimates = () => {
		onClose && onClose();
	};
	const qrRef = useRef(null);
	const qrCode = useMemo(

		() =>
			new QRCodeStyling({
				width: 180,
				height: 180,
				type: "svg",
				data: data?.blockchain_transaction?.to_addr,
				image: Logo,
				dotsOptions: {
					color: "#000",
					type: "rounded",
				},
				cornersSquareOptions: { color: "#47CE7D", type: "extra-rounded" },
				cornersDotOptions: { color: "#6d5dd9" },
				// imageOptions: {
				//   crossOrigin: "anonymous",
				//   margin: 2,
				// },
			}),
		[data]
	);
	useEffect(() => {
		console.log(data?.blockchain_transaction?.currency);
		qrCode.append(qrRef.current);
	}, [qrCode]
	);

	return (
		<Modal show={show} onClose={handleClose} >
			<Modal.Slot name='header'>
				<div>
					<div className='app-invoice__modal__title'>
						{false // if its alawis create 
							? 'Edit'
							: 'Create a'}{' '}
						Top Up
					</div>
				</div>
				<ExpiryTimer
					expiry={data.blockchain_transaction?.expiry}
					handleNewEstimates={handleNewEstimates}
				/>
			</Modal.Slot>
			<Modal.Slot name='underHeader'>
				<div
					className='w-full  bg-[#E3F7FF] h-[65px] flex justify-center items-center'
				>
					<span className='
				text-tertiary
				font-semibold
				text-sm
			'
					>
						Please make your deposit using the following details:
					</span>
				</div>
			</Modal.Slot>


			<Modal.Slot name='body'>
				<div className='flex items-center  flex-row  gap-4'>
					<div>
						<div ref={qrRef} />
					</div>
					<div className='flex flex-1 flex-col gap-4'>
						<div className='w-full h-auto'>
							<Label title='Currency' />
							<Select
								className='w-full'
								// placeholder='Select Currency'
								value={data?.blockchain_transaction?.currency}
								options={currencies}
	
								path="assets/images/icons/cryptos"
								disabled={true}
								
							/>
						</div>
						<div className='w-full h-auto'>
							<Label
								title='Network'
							/>
							<SelectNetwork
								className='w-full'
								options={networks}
								value={data?.blockchain_transaction?.network}
						
								disabled={true}
							/>
						</div>
						<div>
							<Label
								title='Address'
							/>
							<div className='w-full h-auto'>
								<CopyToClipboardBoxID 
									text={data?.blockchain_transaction?.to_addr}
									className='w-full'
								/>									
							</div>
						</div>
						{
							data?.blockchain_transaction?.memo && (
								<div>
									<Label
										title='Memo'
									/>
									<div className='w-full h-auto'>
										<CopyToClipboardBoxID 
											text={data?.blockchain_transaction?.memo}
											className='w-full'
										/>	
									</div>
								</div>	
							)
						}
					</div>
				</div>
			</Modal.Slot>
			<Modal.Slot name='footer'>
				<div className='flex justify-end'>
					<Button onClick={(e) => {
						
						handleClose()
					}} type='  '>
						Finish
					</Button>
				</div>
			</Modal.Slot>
		</Modal>
	)
}

export default TopupFinish;

const FormStyle = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 10px; */
    >div{
        width: 100%;

            >.networks{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .active{
                border: 2px solid #47CE7D;
              }
              >label{
                padding:  0 10px;
                flex-basis : 49%;
                margin-bottom:10px ;
                height: 99px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #DCDEE6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                input
                {
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: none;
                  border: 0;
                  box-shadow: inset 0 0 0 3px #DCDEE6;
                  box-shadow: inset 0 0 0 3.5px #DCDEE6;
                  appearance: none;
                  padding: 0;
                  margin: 0;
                  transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
                  pointer-events: none;
                  margin: 2px;
                  &:focus
                  {

                    outline: none;
                  }
                  
                  &:checked
                  {

                    box-shadow: inset 0 0 0 6px #47CE7D;
                  }

                }
                >.first{
                  height: 100%;
                  justify-content: space-around;
                  display: flex;
                  align-items: flex-start;
                  flex-direction :column;
                  >img{

                    max-width: 100px;
                  }
                }

              }
            }
        
    }
    `;