import "./NetworkSelect.scss";
import clsx from "clsx";
import ArrowDownIcon from "assets/images/icons/dashboard/arrow_down.svg";
import { useEffect, useMemo, useRef, useState } from "react";
// import useOutsideClick from "../../../../hooks/useOutsideClick";use
import useOutsideClick from "hooks/useOutsideClick";
import styled , {css}from "styled-components";

function NetworkSelect({
  path = "",
  options = [],
  value = null,
  style,
  placeholder = "",
  className = "",
  disabled = false,
  readOnly = false,
  borderLess = false,
  left = false,
  setNetwork,
  onSelect,
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  useOutsideClick(selectRef, () => setOpen(false));

  const handleOptionClick = (option, index) => {
    setActiveIndex(index);
    // setOpen(false);
    onSelect && onSelect(option);
  };

  useEffect(() => {
    setActiveIndex(options.findIndex((option) => option.value === value));
  }, [value, options]);

  const getActiveIndex = useMemo(() => {
    if (activeIndex === -1) {
      return null;
    }
    return options.length && options[activeIndex];
  }, [options, activeIndex]);

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../${path}/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  return (
    <div
      ref={selectRef}
      className={clsx(className, "app-select")}
      style={style}
    >
      
      <div
        onClick={() => !readOnly && !disabled && setOpen(!open)}
        className={clsx(
          "app-select__input",
          open && "app-select__input--active",
          disabled && "app-select__input--disabled",
          borderLess && "app-select__input--borderless"
        )}
      >
        <div className="app-select__input__value">
          {getActiveIndex ? (
            <>
              {getActiveIndex.icon &&
                getActiveIndex.icon !== "" &&
                (getIconUrl(getActiveIndex.icon) ? (
                  <img
                    className="app-select__input__icon"
                    src={getIconUrl(getActiveIndex.icon)}
                    alt=""
                  />
                ) : (
                  <span className="app-select__input__icon text-center">
                    {getActiveIndex.icon}
                    
                  </span>
                ))}
              <span>{getActiveIndex.network_name}</span>
            </>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>
        <img className="app-select__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open && (
        <div className={left ? "app-select__options optionsL" : "app-select__options"}>
          
          {options.map((option, index) => {
            return <NetworkComponent
            key={index}
            net={option}
            network={getActiveIndex}
            setNetwork={onSelect}
            handleOptionClick={() => handleOptionClick(option, index)}
            i={index}
          />
})}
        </div>
      )}
    </div>
  );
}

export default NetworkSelect;

export  function NetworkComponent({net , network , setNetwork , i , handleOptionClick}) {
    const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  return (
   <NetworkStyle onClick={handleOptionClick} is_active={!net.is_active} className={network === net ? "active" : ""} htmlFor={net.network_name}  key={i}>
                    <div className='first'>
                      <div>
                      <input tabIndex={i} onChange={(e)=>{
                      }} name="network" id={net.network_name} value={net.network}
                      checked={
                        network === net
                      }  type="radio"/>
                      <label >{net.network_name}</label>
                      </div>
                      <div  style={{fontWeight : "400"}}>
                       ({net.network_symbol})
                      </div>

                    </div>
                    <div className='first'>
      
                      <img src={getIconUrl(net.network)} alt="network" />
            
                      <a style={{fontWeight : "400"}} src={net.link} >
                        view accepted token
                      </a>

                    </div>
                    
                    </NetworkStyle>
  )
}

const  NetworkStyle = styled.label`
              padding:  0 10px;
                flex-basis : 49%;
                margin-bottom:10px ;
                height: 99px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #DCDEE6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                // condition blur when is_active is true
                ${props => props.is_active && css`
                  filter: blur(.5px);
                  background-color: #F8F8F8;
                `}
                input
                {
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: none;
                  border: 0;
                  box-shadow: inset 0 0 0 3px #DCDEE6;
                  box-shadow: inset 0 0 0 3.5px #DCDEE6;
                  appearance: none;
                  padding: 0;
                  transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
                  pointer-events: none;
                  margin: 10px;
                  &:focus
                  {

                    outline: none;
                  }
                  
                  &:checked
                  {

                    box-shadow: inset 0 0 0 6px #47CE7D;
                  }

                }
                >.first{
                  height: 100%;
                  justify-content: space-around;
                  display: flex;
                  align-items: flex-start;
                  flex-direction :column;
                  >img{

                    max-width: 100px;
                  }
                }
  `;