import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { animated, Spring } from 'react-spring';
import {
  useState,
  useCallback,
  Fragment,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';


function DashboardSidebarMenu() {

  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';

const menu = [
  { icon: 'home', name: 'Overview', link: DASHBOARD_PATH + '' },
  {
    icon: 'wallet',
    name: 'Balance',
    link: DASHBOARD_PATH + '/balance',
    subMenu: [
      {
        name: 'Balance',
        link: DASHBOARD_PATH + '/balance',
      },
      {
        name: 'Transactions',
        link: DASHBOARD_PATH + '/balance_transactions',
      },
      {
        name: 'Top Ups',
        link: DASHBOARD_PATH + '/topups',
      },
      {
        name: 'Payouts',
        link: DASHBOARD_PATH + '/payouts',
      },
      {
        name: 'Transfers',
        link: DASHBOARD_PATH + '/transfers',
      },
      {
        name: 'Swaps',
        link: DASHBOARD_PATH + '/swaps',
      },
    ],
  },
  {
    icon: 'customers',
    name: 'Customers',
    link: DASHBOARD_PATH + '/customers',
  },
  {
    icon: 'payments',
    name: 'Payments',
    link: DASHBOARD_PATH + '/payments',
    subMenu: [
      {
        name: 'Payments',
        link: DASHBOARD_PATH + '/payments',
      },
      {
        name: 'Refunds',
        link: DASHBOARD_PATH + '/refunds',
      },
      // {
      //   name: 'Invoices',
      //   link: DASHBOARD_PATH + '/invoices',
      // },
      // {
      //   name: 'Subscriptions',
      //   link: DASHBOARD_PATH + '/subscriptions',
      // },
      {
        name: 'Payment link',
        link: DASHBOARD_PATH + '/payment_link',
      },
    ],
  },
  {
    icon: 'products',
    name: 'Products',
    link: DASHBOARD_PATH + '/products',
    subMenu: [
      {
        name: 'Products',
        link: DASHBOARD_PATH + '/products',
      },
      {
        name: 'Discounts',
        link: DASHBOARD_PATH + '/discounts',
      },
      {
        name: 'Shipping Rates',
        link: DASHBOARD_PATH + '/shipping_rates',
      },
      {
        name: 'Tax Rates',
        link: DASHBOARD_PATH + '/tax_rates',
      },
    ],
  },
  {
    icon: 'billing',
    name: 'Billing',
    link: DASHBOARD_PATH + '/invoices',
    subMenu: [
      {
        name: 'Invoices',
        link: DASHBOARD_PATH + '/invoices',
      },
      {
        name: 'Subscriptions',
        link: DASHBOARD_PATH + '/subscriptions',
      },
    ],
  },
  {
    icon: 'orders',
    name: 'Orders',
    link: null,
    subMenu: [
      {
        name: 'Orders',
        link: DASHBOARD_PATH + '/orders',
      },
      {
        name: 'SKUs',
        link: DASHBOARD_PATH + '/skus',
      },
      {
        name: 'Returns',
        link: DASHBOARD_PATH + '/orders_returns',
      },
    ],
  },
  {
    icon: 'wallet-2',
    name: 'Wallets',
    link: DASHBOARD_PATH + '/wallets',
  },
  // {
  //   icon: 'tokens',
  //   name: 'Tokens',
  //   link: DASHBOARD_PATH + '/tokens',
  // },
  // {
  //   icon: 'nft',
  //   name: 'NFTs',
  //   link: DASHBOARD_PATH + '/nfts',
  // },
  // {
  //   icon: 'reward',
  //   name: 'Reward Pools',
  //   link: DASHBOARD_PATH + '/rewards',
  // },
  {
    icon: 'developers',
    name: 'Developers',
    link: DASHBOARD_PATH + '/api_keys',
    subMenu: [
      {
        name: 'API Keys',
        link: DASHBOARD_PATH + '/api_keys',
      },
      {
        name: 'Webhooks',
        link: DASHBOARD_PATH + '/webhooks',
      },
      { 
        name: 'Events', 
        link: DASHBOARD_PATH + '/events' 
      },
    ],
  },
  {
    icon: 'settings',
    name: 'Settings',
    link: DASHBOARD_PATH + '/settings',
  },
];
  const location = useLocation();
  const [active, setActive] = useState(-1);

  useEffect(() => {
    const activeMenu = menu.findIndex(
      (item) =>
        item.link === location.pathname ||
        item.subMenu?.find(
          (link) => link.link === location.pathname
        )
    );
    setActive(activeMenu);
  }, [location]);

  const springConfig = (index) => {
    return {
      config: {
        tension: 410,
        friction: 20,
        precision: 0.06,
      },
      from: {
        opacity: 0,
        height: 0,
        marginTop: '0px',
        marginBottom: '0px',
      },
      to: {
        opacity: isActiveMenu(index) ? 1 : 0,
        height: isActiveMenu(index) ? 'auto' : 0,
        marginTop: isActiveMenu(index) ? '10px' : '0px', // '20px' : '0px',
        marginBottom: isActiveMenu(index) ? '10px' : '0px',
      },
    };
  };

  const setActiveMenu = (index) => {
    setActive(index);
    // setActive(active === index ? -1 : index)
  };

  const getIconUrl = (icon) => {
    return require(`../../../../assets/images/icons/dashboard/${icon}.svg`);
  };

  const isActiveMenu = useCallback(
    (index) => active === index,
    [active]
  );

  const isLinkActive = useCallback(
    (index, item) => {
      return (
        isActiveMenu(index) ||
        (active === -1 && item.link === location.pathname)
      );
    },
    [location, isActiveMenu, active]
  );

  const isSubLinkActive = useCallback(
    (index, item) => {
      return (
        isActiveMenu(index) ||
        (active === -1 &&
          item.subMenu
            .map((menu) => menu.link)
            .includes(location.pathname))
      );
    },
    [location, isActiveMenu, active]
  );

  return (
    <>
      {menu.map((item, index) => {
        const Tag = item.link ? Link : 'div';
        return 'subMenu' in item &&
          item.subMenu.length > 0 ? (
          <Fragment key={item.name + index}>
            <Tag
              onClick={() => setActiveMenu(index)}
              to={item.link ?? item.subMenu[0].link}
              className='sidebar__menu__submenu'>
              <div
                className={clsx(
                  'sidebar__menu__item',
                  isSubLinkActive(index, item) &&
                    'sidebar__menu__item--active',
                  item.link === location.pathname &&
                    'sidebar__menu__item--text-active'
                )}>
                <div>
                  <img
                    src={getIconUrl(item.icon)}
                    alt={item.name}
                  />
                  <span>{item.name}</span>
                </div>
              </div>
            </Tag>
            {isActiveMenu(index) && (
              <Spring {...springConfig(index)}>
                {(styles) => (
                  <div className='sidebar__menu__submenu__items'>
                    <animated.div style={styles}>
                      {item.subMenu.map((menu, i) => (
                        <Link
                          to={menu.link}
                          key={menu.name + i}
                          className={clsx(
                            'sidebar__menu__submenu__item',
                            menu.link === location.pathname &&
                              'sidebar__menu__submenu__item--active'
                          )}>
                          {menu.name}
                        </Link>
                      ))}
                    </animated.div>
                  </div>
                )}
              </Spring>
            )}
          </Fragment>
        ) : (
          <Link
            onClick={() => setActiveMenu(index)}
            key={item.name + index}
            to={item.link}
            className={clsx(
              'sidebar__menu__item',
              isLinkActive(index, item) &&
                'sidebar__menu__item--active',
              item.link === location.pathname &&
                'sidebar__menu__item--text-active'
            )}>
            <div>
              <img
                src={getIconUrl(item.icon)}
                alt={item.name}
              />
              <span>{item.name}</span>
            </div>
          </Link>
        );
      })}
    </>
  );
}

export default DashboardSidebarMenu;
