import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { currencyFormatter, currencySymbol } from "../../../utils/string";
import { useAsyncDebounce } from "react-table";
import { useSelector } from "react-redux";
import { useFetchShippingRates } from "../../../api/product/shipping_rates";

function SelectShippingRates({
  value = null,
  placeholder = "",
  data = [],
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const { invoice_currency, draft_invoice } = useSelector(
    (state) => state.invoice
  );
  useOutsideClick(selectRef, () => setOpen(false));

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  const getCurrency = useMemo(() => {
    return draft_invoice ? draft_invoice?.currency : invoice_currency;
  }, [draft_invoice, invoice_currency]);

  const { isLoading, refetch } = useFetchShippingRates({
    onSuccess: (data) => setOptions(data.data),
    onError: () => null,
    enabled: false,
    params: {
      active: true,
      ...bindParams,
      currency: getCurrency,
    },
  });

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const handleOptionClick = (option) => {
    setSelected(option);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  const handleInputChange = useAsyncDebounce(() => {
    // setOpen(true);
    refetch();
  }, 300);

  const getFilteredOptions = useMemo(() => {
    return data.length
      ? options.filter((option) => !data.includes(option.id))
      : options;
  }, [options, data]);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  return (
    <div ref={selectRef} className="select-customer z-30">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <div className="select-merchant__input__value">
          <span>Select shipping rate</span>
        </div>
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && (
        <div className="select-customer__options">
          <div className="app-select__options__item app-select__options__item--hoverless app-select__options__item__search">
            <input
              autoFocus
              type="text"
              className="select-customer__input__value"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {isLoading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {!isLoading &&
            getFilteredOptions.map((shipping_rate, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(shipping_rate)}
                className={clsx(
                  "select-customer__options__item",
                  selected &&
                    shipping_rate.id === selected.id &&
                    "select-customer__options__item--active"
                )}
              >
                {shipping_rate?.description && (
                  <>
                    <div>
                      {truncate(shipping_rate.description, {
                        length: 50,
                        separator: "...",
                      })}
                    </div>
                    <span>-</span>
                  </>
                )}
                <span>
                  {currencySymbol(shipping_rate.currency)}{" "}
                  {currencyFormatter(
                    shipping_rate.currency,
                    shipping_rate.amount
                  )}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default SelectShippingRates;
