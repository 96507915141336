import Radio from "../../../../../../../Common/Form/Radio/Radio";
import {useUpdateInvoice} from "../../../../../../../../api/customer/invoice";
import {useDispatch, useSelector} from "react-redux";
import {setDraftInvoice} from "../../../../../../../../store/invoice/invoiceSlice";

function InvoicePaymentMethod({ register }) {
  const dispatch = useDispatch();
  const { draft_invoice } = useSelector((state) => state.invoice);
  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  const handleSendingMethodChange = (e) => {
    if (!draft_invoice) return;

    mutateUpdateInvoice({ sending_method: e.target.value }, {}, {
      onSuccess: (data) => dispatch(setDraftInvoice(data.data))
    })
  }

  return (
    <>
      <div className="app-modal-fullscreen__title">Payment</div>
      <div className="space-y-1.5">
        <div className="flex justify-between mt-4">
          <div className="app-modal-fullscreen__small-title">Send invoice</div>
        </div>
        <div className="horizontal-divider" />
        <div className="app-modal-fullscreen__text py-1">
          <Radio
            color="primary"
            value="auto"
            {...register("sending_method", {
              onChange: handleSendingMethodChange
            })}
          />
          <span className="text-light">Automatically</span>
        </div>
        <div className="horizontal-divider" />
        <div className="app-modal-fullscreen__text py-1">
          <Radio
            color="primary"
            value="manual"
            {...register("sending_method", {
              onChange: handleSendingMethodChange
            })}
          />
          <span className="text-light">Send later</span>
        </div>
      </div>
    </>
  )
}

export default InvoicePaymentMethod;