import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchProductPrices = (params) => appStore.getState().app.livemode
  ? api.live.get("/prices", { params })
  : api.sandbox.get("/prices", { params });

export const useFetchProductPrices = ({ onSuccess, onError, enabled, params = null }) => {
  return useQuery(
    ["fetchProductPrices", params],
    () => fetchProductPrices(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchProductPrice = (id, params) => appStore.getState().app.livemode
  ? api.live.get(`/prices/${id}`, { params })
  : api.sandbox.get(`/prices/${id}`, { params });

export const useFetchProductPrice = ({ onSuccess, onError, enabled, id, params = null }) => {
  return useQuery(
    ["fetchProductPrice", id],
    () => fetchProductPrice(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storePrice = (data) => appStore.getState().app.livemode
  ? api.live.post("/prices", data)
  : api.sandbox.post("/prices", data);

export const useStorePrice = () => {
  return useMutation(
    "storePrice",
    storePrice
  );
}

const updatePrice = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/prices/${id}`, data)
  : api.sandbox.patch(`/prices/${id}`, data);

export const useUpdatePrice = (id) => {
  return useMutation(
    ["updatePrice", id],
    (data) => updatePrice(id, data)
  );
}

const deletePrice = (id, data) => appStore.getState().app.livemode
  ? api.live.delete(`/prices/${id}`, data)
  : api.sandbox.delete(`/prices/${id}`, data);

export const useDeletePrice = (id) => {
  return useMutation(
    ["deletePrice", id],
    (data) => deletePrice(id, data)
  );
}

export const fetchPriceSubscriptionsCount = (id) => appStore.getState().app.livemode
  ? api.live.get(`/prices/${id}/subscription_count`)
  : api.sandbox.get(`/prices/${id}/subscription_count`);

export const useFetchPriceSubscriptionsCount = ({onSuccess, onError, enabled, id}) => {
  return useQuery(
    ["fetchPriceSubscriptionsCount", id],
    () => fetchPriceSubscriptionsCount(id),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
