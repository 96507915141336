import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "assets/images/icons/dashboard/edit.svg";
import {
  capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "utils/string";
import clsx from "clsx";
import moment from "moment";
import { dateFormatter } from "utils/date";
import CopyToClipboardBoxID from "components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { Link } from "react-router-dom";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import MetadataForm from "components/Dashboard/MetadataForm/MetadataForm";
import Input from "components/Common/Form/Input/Input";

const Details = () => {
  const [edit, setEdit] = useState(false);
  const { selected_payout } = useSelector((state) => state.payout);

  const handleEditClick = () => {
    setEdit(!edit);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };
  const getIconBrandUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  const getFees = () => {
    const fees = [];
    if (selected_payout?.balance_transaction.fee_details) {
      for (const fee of selected_payout?.balance_transaction.fee_details) {
        fees.push({
          amount: fee.amount,
          currency: fee.currency,
          crypto_amount: fee.crypto_amount,
          crypto_currency: fee.crypto_currency,
          description: fee.description,
        });
      }
    }
    // if (selected_payout?.network_fee_balance_transaction) {
    //   fees.push({
    //     amount: selected_payout?.network_fee_balance_transaction.amount,
    //     currency: selected_payout?.network_fee_balance_transaction.currency,
    //     crypto_amount:
    //       selected_payout?.network_fee_balance_transaction.crypto_amount,
    //     crypto_currency:
    //       selected_payout?.network_fee_balance_transaction.crypto_currency,
    //     description: "Network Fee",
    //   });
    // }
    return fees;
  };

  return (
    <div className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Details</div>
      </div>
      <div className="horizontal-divider" />
      <div className="pt-4">
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-2 space-y-2 gap-4">
            {/* <div className="app-modal-fullscreen__kv">
              <div className="app-modal-fullscreen__kv__key">Payout Type</div>
              <div className="app-modal-fullscreen__kv__value">
                {selected_payout?.type}
              </div>
            </div> */}
            {selected_payout?.automatic && (
              <div className="app-modal-fullscreen__kv">
                <div className="app-modal-fullscreen__kv__key">Auto Payout</div>
                <div className="app-modal-fullscreen__kv__value">Yes</div>
              </div>
            )}
            <div className="app-modal-fullscreen__kv">
              <div className="app-modal-fullscreen__kv__key">
                {selected_payout?.type === "crypto"
                  ? "Crypto Amount"
                  : "Amount"}
              </div>
              <div className="app-modal-fullscreen__kv__value">
                {selected_payout?.type === "crypto" ? (
                  <Amount
                    crypto_amount={selected_payout?.crypto_amount}
                    crypto_currency={selected_payout?.crypto_currency}
                    amount={selected_payout?.amount}
                    currency={selected_payout?.currency}
                  />
                ) : (
                  <div>
                    {currencyPrettier(
                      selected_payout?.currency,
                      selected_payout?.amount
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="app-modal-fullscreen__kv">
              <div className="app-modal-fullscreen__kv__key">
                {selected_payout?.type === "crypto"
                  ? "Cryptocurrency"
                  : "Currency"}
              </div>
              <div className="app-modal-fullscreen__kv__value">
                {selected_payout?.type === "crypto"
                  ? selected_payout?.crypto_currency
                  : selected_payout?.currency}
              </div>
            </div>
            <div className="app-modal-fullscreen__kv">
              <div className="app-modal-fullscreen__kv__key">
                Balance Transaction
              </div>
              <div className="app-modal-fullscreen__kv__value">
                {selected_payout?.balance_transaction ? (
                  <Link
                    className="text-tertiary"
                    to={`/dashboard/balance_transactions/${selected_payout?.balance_transaction.id}`}
                  >
                    {selected_payout?.balance_transaction.id}
                  </Link>
                ) : (
                  "-"
                )}
              </div>
            </div>
            {selected_payout?.network_fee_balance_transaction && (
              <div className="app-modal-fullscreen__kv">
                <div className="app-modal-fullscreen__kv__key">
                  Network Fee Balance Transaction
                </div>
                <div className="app-modal-fullscreen__kv__value">
                  <Link
                    className="text-tertiary"
                    to={`/dashboard/balance_transactions/${selected_payout?.network_fee_balance_transaction}`}
                  >
                    {selected_payout?.network_fee_balance_transaction}
                  </Link>
                </div>
              </div>
            )}
            {selected_payout?.status === "failed" && (
              <>
                <div className="app-modal-fullscreen__kv">
                  <div className="app-modal-fullscreen__kv__key">
                    Failure Message
                  </div>
                  <div className="app-modal-fullscreen__kv__value">
                    <div className="text-danger">
                      {selected_payout?.failure_message}
                    </div>
                  </div>
                </div>
                <div className="app-modal-fullscreen__kv">
                  <div className="app-modal-fullscreen__kv__key">
                    Failure Balance Transactiom
                  </div>
                  <div className="app-modal-fullscreen__kv__value">
                    <Link
                      className="text-tertiary"
                      to={`/dashboard/balance_transactions/${selected_payout?.failure_balance_transaction}`}
                    >
                      {selected_payout?.failure_balance_transaction}
                    </Link>
                  </div>
                </div>
              </>
            )}
            {selected_payout?.balance_transaction && ((selected_payout.type === "crypto" && selected_payout?.balance_transaction.crypto_fee) ||
            (selected_payout.type === "fiat" && selected_payout?.balance_transaction.fee > 0)) && (
              <div className="app-modal-fullscreen__kv">
                <div className="app-modal-fullscreen__kv__key">Fees</div>
                <div className="app-modal-fullscreen__kv__value">
                  {selected_payout.type === "crypto" ? (
                    <>
                      {getFees().map((fee, index) => {
                        return (
                          <div className="flex w-[300px] mt-[5px] items-start gap-10 whitespace-nowrap" key={index}>
                            <div className="flex items-start gap-4 ">
                              <Amount
                                crypto_amount={selected_payout?.crypto_amount}
                                crypto_currency={selected_payout?.crypto_currency}
                                amount={selected_payout?.amount}
                                currency={selected_payout?.currency}
                              />
                            </div>
                            <div className="flex items-start gap-4">
                              <div className="whitespace-nowrap">
                                - {fee.description}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <div>
                      {currencyPrettier(
                        selected_payout?.currency,
                        selected_payout?.amount
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {selected_payout?.network_fee_balance_transaction && (
              <div className="app-modal-fullscreen__kv">
                <div className="app-modal-fullscreen__kv__key">Network Fee</div>
                <div className="app-modal-fullscreen__kv__value">
                  <Amount
                    crypto_amount={selected_payout?.crypto_amount}
                    crypto_currency={selected_payout?.crypto_currency}
                    amount={selected_payout?.amount}
                    currency={selected_payout?.currency}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;

export function Amount({ crypto_currency, crypto_amount, currency, amount }) {
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };
  return (
    <div className="flex items-start gap-1">
      <img
        style={{ width: "15px", height: "15px" }}
        alt={crypto_currency}
        src={getIconUrl(crypto_currency)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          fontSize: "12px",
        }}
        className="app-customers__modal__balance"
      >
        <span
          style={{ lineHeight: "1em", fontSize: "13px" }}
          className="text-light font-semibold"
        >
          {cryptocurrencyFormatter(crypto_amount)}
          <span style={{ fontSize: "10px" }} className="text-light ">
            {crypto_currency.toUpperCase()}
          </span>
        </span>
        <span style={{ fontSize: "12px" }} className="text-light">
          ( {currencyPrettier(currency, amount)})
        </span>
      </div>
    </div>
  );
}
