import Label from "../../../../../../../Common/Form/Label/Label";
import {useSelector} from "react-redux";
import InfoIcon from "../../../../../../../../assets/images/icons/info.svg";
import ReactTooltip from "react-tooltip";
import InputPrice from "../../../../../../../Common/Form/InputPrice/InputPrice";
import {FIAT_CURRENCIES_SELECT} from "../../../../../../../../utils/constants";
import { fiatCurrencies } from '../../../../../../../../utils/string';

function DiscountsModalCreateAmountGte({ currency, setValue, register, error = "" }) {
  const { selected_discount, form_schema_discount } = useSelector((state) => state.discount);

  return (
    <div>
      <Label
        title="Sale Amount"
        error={error}
        icon={InfoIcon}
        tooltipFor="discount-modal-create"
        tooltip={`This discount will be active when the sale amount is equal to or greater than this`}
      />
      <InputPrice
        {...register("amount_gte")}
        block
        currencies={FIAT_CURRENCIES_SELECT}
        onCurrencyChange={(e) => {
          if (form_schema_discount.behavior === "amount_off") return;
          setValue("currency", e?.value);
        }}
        currency={currency}
        placeholder={
          currency
            ? fiatCurrencies[currency]
                .zero_decimal
              ? '0'
              : '0.00'
            : '0.00'
        }
        error={error}
        disabled={selected_discount?.id}
        disableCurrencies={form_schema_discount.behavior === "amount_off"}
      />
    </div>
  )
}

export default DiscountsModalCreateAmountGte;
