import "./SectionSwap.scss";
import {ReactComponent as SwapIcon} from "../../../../assets/images/icons/swap.svg";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/swap/illustration.svg";
import {ReactComponent as CryptoIcon} from "../../../../assets/images/sections/swap/cryptocurrency.svg";
import Stack from "../../../Common/Stack/Stack";

function SectionSwap() {
  return (
    <div id="swap" className="section-swap">
      <div className="app-container">
        <Stack columns="1" gap="0" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <SwapIcon className="section-products__section__subtitle__icon" />
              Swap
            </h3>
            <h1 className="h1 section-products__section__title section-swap__title">
              Easily Swap between currencies with the click of a button.
            </h1>
            <div className="section-swap__content">
              <div className="section-swap__icon">
                <CryptoIcon />
              </div>
              <p className="text-small section-swap__text">
                Swap and convert your balances with ease to any of our accept cryptocurrencies.
                This reduces the hassle of having to withdraw your balances and exchange them elsewhere.
                Access Live rates for your swaps.
              </p>
            </div>
          </div>
          <div className="section-swap__illustration">
            <Illustration />
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default SectionSwap;
