import React , {useEffect , useState} from 'react';
import clsx from 'clsx';
import './InputTable.scss';
import { useTable, usePagination } from 'react-table';
import { FormProvider } from 'react-hook-form';
import LabelEditableCell from './LabelEditableCell';
import { currencySymbol, inputPricePrettier, fiatCurrencies } from 'utils/string';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/dashboard/delete.svg';
import EditableCell from './EditableCell';
function  InputTable({
  removeTier,
  tierUpdate,
  tierAppend,
  columns,
  updateMyData,
  skipPageReset,
  fieldName,
  data,
  formMethods,
  tiers_mode,
  currency,
  disabled = false
}) {
  const [dd, setdd] = useState([])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    loading = false,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,co
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      updateMyData,
      initialState: { pageSize: 250 }
    },
    usePagination
  );

  const handleAddTableRowClick = async() => {
    var tiers = data
    if (tiers.length >= 250) return;

    if (!tiers[tiers.length - 1]) throw new Error('Tier init required');

    else {
      // Update Original Last Tier:
      var lastTier = tiers[tiers.length - 1];
      var value = tiers[tiers.length - 1].first_unit + 1

      tierUpdate(tiers.length - 2, {
        ...lastTier,
        last_unit : value,
        unit_amount : "3",
        flat_amount :"3",

      });
      // tierAppend( {
      //   ...lastTier,
      //   first_unit : value + 1,
      // });

    }
  };

useEffect(() => {
  // console.log(data)
}, [data])

  return (
    <>
      <FormProvider
        {...formMethods}
        className={clsx(
          'input-table',
          loading && 'app-overlay'
        )}>
        <div noValidate className='input-table__wrapper'>
          <table
            {...getTableProps()}
            className='input-table__content'>
            <thead className='input-table__header'>
              {headerGroups.map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className='input-table__tr'>
                  {headerGroup.headers.map((column) => 
                  
                 {
                  if (column.Header === "x" ) 
                    return <></>
                  return (
                    <th
                      key={column.id}
                      {...column.getHeaderProps()}
                      scope='col'
                      className='input-table__header__th'>
                      {column.Header === "tier_mood" ?<></> : column.render('Header')}
                    </th>
                  )}
                  
                  )}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className='input-table__body'>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr key={i}
                  
                    {...row.getRowProps()}
                    className={clsx('input-table__tr')}>
                    {row.cells.map((cell , j) => {
                      if (cell.column
                        .Header === "")
                      {
                        return   <td
                        key={j}
                        {...cell.getCellProps()}
                        className={clsx(
                          'input-table__body__td'
                        )}>
                        <div >For The First</div>
                          
          
                      </td>
              
                      
              
                      }
                       else if (cell.column.Header === "x" && i !== 0){
                        return (
                          <td 
                            className='remove'
                            key={j} 
                          >
                            {disabled ? <div key={j}></div> : <div onClick={()=>{removeTier(i)}}>&#x2716;</div>}
                          </td>
                        );
                      }
                      else if(cell.column.Header === "x" && i === 0){
                        return (
                           <td

                           className='remove'

                           key={j}>
                              <div key={j}></div>
                           </td>
                        
  
                          );
                      }
                      else if (cell.column.Header  === 'PER UNIT') {
                        return ( 
                          <td
                            key={j}
                            {...cell.getCellProps()}
                            className={clsx('input-table__body__td')}
                          >
                            <LabelEditableCell 
                            last={"editable-cell-input"}
                            disabled={disabled}
                              change={async(e)=>{
                               

                                await tierUpdate(i ,{...data[i] ,
                                  unit_amount: inputPricePrettier(currency, e.target.value, true)
                                })
                  
                              }}
                              label={
                                currency
                              }
                              pattern={fiatCurrencies[currency]?.zero_decimal ? "^\d*(\.\d{0,12})?$" : "^\d*(\.\d{0,14})?$"}
                              value = {cell.value}
                              {...cell}
                            />
                          </td>
                        );
                      }
                      else if (cell.column.Header  === 'FLAT FEE') {
                        return ( 
                          <td
                            key={j}
                            {...cell.getCellProps()}
                            className={clsx('input-table__body__td')}
                          >
                            <LabelEditableCell 
                            last={"editable-cell-input-flat"}
                            disabled={disabled}
                              change={ async(e)=>{
                
                                await tierUpdate(i ,{...data[i] ,
                                  flat_amount: inputPricePrettier(currency, e.target.value, false)
                                })
                              }}
                              label={(
                                currency
                              )}
                              pattern={fiatCurrencies[currency]?.zero_decimal ? null : "^\d*(\.\d{0,2})?$"}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              {...cell}
                            />
                          </td>
                        );
                      }
                      else if (cell.column.Header  === 'LAST UNIT') {
                        return ( 
                          <td
                            key={j}
                            {...cell.getCellProps()}
                            className={clsx('input-table__body__td')}
                          >
                            <EditableCell 
                     
                            last="editable-cell-input-last"
                            disabled={disabled}

                            placeholder='0'
                            change={async (e)  => {
                              var tiers = data;
                              var val = e.target.value;
                              tierUpdate(cell.row.index , {
                                ...tiers[cell.row.index],
                                last_unit: val,
                              });

                              if (i + 1 < tiers.length && val !== '') {
                                tierUpdate(i + 1, {
                                  ...tiers[i + 1],
                                  first_unit: parseInt(val) + 1,
                                });
                              }

                              var d = document.getElementsByClassName("editable-cell-input-last");
                            }}
                        
                            // pattern="[0-9]*"
                            value = {cell.value}
                            checkerror={ async(e)=>{
                              var tiers = data;

                              if (e.target.value === "")
    
                              {
                                await tierUpdate(cell.row.index  , {
                                  ...tiers[cell.row.index  ],
                                  last_unit: tiers[cell.row.index].first_unit + 1 ,
                                });
                              }
                              var d = document.getElementsByClassName("editable-cell-input-last");
                              var errorsElm = document.getElementsByClassName("editable-cell-input-last-error");
                              if (e.target.value === "" || parseInt(e.target.value) < tiers[cell.row.index].first_unit) {
                                d[cell.row.index].classList.add("warning");
                                errorsElm[cell.row.index].classList.add("show");
                                errorsElm[cell.row.index].innerHTML = "Must be greater than or equal to " + tiers[cell.row.index].first_unit;
                              } 
                              else 
                              {
                                errorsElm[cell.row.index].classList.remove("show");
                                errorsElm[cell.row.index].innerHTML = "" ;
                                d[cell.row.index].classList.remove("warning"); 
                            }}}
                            {...cell}
                            />
                          </td>
                        );
                      }
                       else if (cell.column.Header === "tier_mood"){
                        if (tiers_mode === 'graduated') {
                          if (cell.row.index === 0) {
                            return  <td
                            key={j}
                            {...cell.getCellProps()}
                            className={clsx(
                              'input-table__body__td'
                            )}>

                              <div>FOR THE FIRST</div>

                            </td>
                            
                          } else {
                            return  <td
                            key={j}
                            {...cell.getCellProps()}
                            className={clsx(
                              'input-table__body__td'
                            )}>

                              <div>FOR THE NEXT</div>

                            </td>
                          }
                        } else if (tiers_mode === 'volume') {
                          return  <td
                          key={j}

                          {...cell.getCellProps()}
                          className={clsx(
                            'input-table__body__td'
                          )}>

                            <div>TOTAL UNITS</div>

                          </td>
              
                        }
                        
                      }
                      return (
                        <td
                        key={j}
                          {...cell.getCellProps()}
                          className={clsx(
                            'input-table__body__td'
                          )}>
                            
                          {cell.render('Cell', {
                            fieldName,
                          })}
                        </td>
                      );

                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <div className='pagination'>
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}>
              {'>>'}
            </button>{' '}
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <button type='submit'>Submit</button> */}
        </div>
      </FormProvider>
    </>
  );
}

export default InputTable;
