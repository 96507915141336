import "./TaxRates.scss";
import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../../components/Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as ExportIcon } from "../../../../assets/images/icons/export.svg";
import TaxRatesTable from "../../../../components/Pages/Dashboard/Products/TaxRates/TaxRatesTable/TaxRatesTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TaxRatesModalDetails from "../../../../components/Pages/Dashboard/Products/TaxRates/TaxRatesModals/TaxRatesModalDetails/TaxRatesModalDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedTaxRate,
  setShowTaxRateForm,
} from "../../../../store/tax_rate/taxRateSlice";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchTaxRate } from "../../../../api/product/tax_rate";
import useTableFilters from "../../../../hooks/useTableFilters";
import DropdownFilters from "../../../../components/Common/DropdownFilters/DropdownFilters";
import TaxRatesModalCreate from "../../../../components/Pages/Dashboard/Products/TaxRates/TaxRatesModals/TaxRatesModalCreate/TaxRatesModalCreate";

const TABLE_FILTERS = [
  { label: "Active", column: "active", value: true },
  { label: "Archived", column: "active", value: false },
];

function TaxRates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected_tax_rate, refetch_tax_rate, show_tax_rate_form } =
    useSelector((state) => state.tax_rate);
  const [filters, setFilters] = useState({});
  const [refreshTaxRate, setRefreshTaxRate] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { id } = useParams();
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchTaxRate({
    onSuccess: (data) => {
      dispatch(setSelectedTaxRate(data));
      setRefreshTaxRate(false);
    },
    onError: (error) => {
      setRefreshTaxRate(false);
      navigate(DASHBOARD_PATH + "/tax_rates");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshTaxRate,
  });

  const handleCloseDetailsModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedTaxRate(null));
    navigate(DASHBOARD_PATH + "/tax_rates");
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_tax_rate);
  }, [selected_tax_rate]);

  useEffect(() => {
    setShowCreateModal(show_tax_rate_form);
  }, [show_tax_rate_form]);

  useEffect(() => {
    if (!id) {
      handleCloseDetailsModal();
      return;
    }

    setRefreshTaxRate(true);
  }, [refetch_tax_rate, id, handleCloseDetailsModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedTaxRate(null));
    };
  }, [dispatch]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    dispatch(setShowTaxRateForm(false));
  };

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...activeFilter,
    };
  }, [filters, activeFilter]);

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Tax Rates</div>
              <span className="app-dashboard__card__header__filters">
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() => setFilter(filter)}
                    className={clsx(
                      "app-dashboard__card__header__filters__item",
                      isFilterActive(filter) &&
                        "app-dashboard__card__header__filters__item--active"
                    )}
                  >
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
              <DropdownFilters
                filters={["date", "calculation", "metadata"]}
                onChange={handleFiltersChange}
              />
              <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color="primary"
                outline
                icon="+"
              >
                <span className="text-dark">New Tax Rate</span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
            </div>
          </div>
          <TaxRatesTable filters={getFilters} />
        </div>
        {showDetailsModal && (
          <TaxRatesModalDetails
            show={showDetailsModal}
            onClose={handleCloseDetailsModal}
          />
        )}
        {showCreateModal && (
          <TaxRatesModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
      </div>
    </div>
  );
}

export default TaxRates;
