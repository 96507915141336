import { Outlet } from "react-router-dom";
import "./Auth.scss";

function Auth() {
  return (
    <div className="min-h-screen">
      <Outlet />
    </div>
  )
}

export default Auth;