import moment from "moment";

export function dateFormatter(date = "", time = false) {
  if (!date) {
    return "Invalid date";
  }

  const momentDate = moment.unix(date);

  return momentDate.format(
    `DD MMM${momentDate.year() !== moment().year() ? " YYYY" : ""}${time ? " , HH:mm" : ""
    }`
  );
}


export function timeForamtter(date = "") {
  if (!date) {
    return "Invalid date";
  }
  const newDate = new Date(date);

  const enGB = newDate.toLocaleString("de-DE", {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return enGB;
}