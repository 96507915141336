import "./ButtonGradient.scss";

function ButtonGradient({ className = "", children }) {
  return (
    <button className={`app-button-gradient ${className}`}>
      {children}
    </button>
  )
}

export default ButtonGradient;