import appStore from "../../store";
import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";

const fetchWebhooks = (params) => appStore.getState().app.livemode
  ? api.live.get("/webhooks", { params })
  : api.sandbox.get("/webhooks", { params });

export const useFetchWebhooks = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchWebhooks", params],
    () => fetchWebhooks(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchWebhook = (id) => appStore.getState().app.livemode
  ? api.live.get(`/webhooks/${id}`)
  : api.sandbox.get(`/webhooks/${id}`);

export const useFetchWebhook = ({id, onSuccess, onError, enabled}) => {
  return useQuery(
    ["fetchWebhook", id],
    () => fetchWebhook(id),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const revealWebhookKey = (id, data) => appStore.getState().app.livemode
  ? api.live.post(`/webhooks/${id}/reveal_secret`, data)
  : api.sandbox.post(`/webhooks/${id}/reveal_secret`, data)

export const useRevealWebhookKey = (id) => {
  return useMutation(
    ["revealWebhookKey", id],
    (data) => revealWebhookKey(id, data)
  );
}

const storeWebhook = (data) => appStore.getState().app.livemode
  ? api.live.post("/webhooks", data)
  : api.sandbox.post("/webhooks", data);

export const useStoreWebhook = () => {
  return useMutation(
    "storeWebhook",
    storeWebhook
  );
}

const updateWebhook = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/webhooks/${id}`, data)
  : api.sandbox.patch(`/webhooks/${id}`, data);

export const useUpdateWebhook = (id) => {
  return useMutation(
    ["updateWebhook", id],
    (data) => updateWebhook(id, data)
  );
}

const deleteWebhook = (id, data) => appStore.getState().app.livemode
  ? api.live.delete(`/webhooks/${id}`, data)
  : api.sandbox.delete(`/webhooks/${id}`, data);

export const useDeleteWebhook = (id) => {
  return useMutation(
    ["deleteWebhook", id],
    (data) => deleteWebhook(id, data)
  );
}