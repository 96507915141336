import './DropdownFilters.scss';
import DropdownFiltersDate from './DropdownFiltersItems/DropdownFiltersDate/DropdownFiltersDate';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { usePopper } from "react-popper";
import clsx from "clsx";
import { ReactComponent as FilterIcon } from "../../../assets/images/icons/filter.svg";
import DashboardButton from "../Form/DashboardButton/DashboardButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DropdownFiltersButtonClear from "./DropdownFiltersButtons/DropdownFiltersButtonClear";
import DropdownFiltersButtonDone from "./DropdownFiltersButtons/DropdownFiltersButtonDone";
import { useSelector } from "react-redux";
import DropdownFiltersTextbox from "./DropdownFiltersItems/DropdownFiltersTextbox";
import DropdownFiltersMetadata from "./DropdownFiltersItems/DropdownFiltersMetadata";
import DropdownFiltersSelect from "./DropdownFiltersItems/DropdownFiltersSelect";
import ReactTooltip from "react-tooltip";
import { FIAT_CURRENCIES_SELECT } from "../../../utils/constants";
import DropdownFiltersAmount from "./DropdownFiltersItems/DropdownFiltersAmount/DropdownFiltersAmount";
import DropdownFiltersAmountDue from "./DropdownFiltersItems/DropdownFiltersAmountDue/DropdownFiltersAmountDue";
import DropdownFiltersCrypto from "./DropdownFiltersItems/DropdownFiltersCrypto/DropdownFiltersCrypto";
import DropdownFiltersCryptoAmount from "./DropdownFiltersItems/DropdownFiltersCryptoAmount/DropdownFiltersCryptoAmount";
import isEmpty from "lodash/isEmpty";
import { typesTable, typesTableArray } from 'utils/transactions';

function DropdownFilters({ filters = [], onChange, data = null, page = "" }) {
  const dropdownRef = useRef(null);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const [disableStatus, setDisableStatus] = useState(false);
  const [visible, setVisibility] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [enabledFilters, setEnabledFilters] = useState({
    date: false,
    name: false,
    email: false,
    currency_type: false,
    shippable: false,
    url: false,
    metadata: false,
    currency: false,
    discount_type: false,
    discount_terms: false,
    calculation: false,
    invoice_type: false,
    invoice_status: false,
    payment_status: false,
    topup_status: false,
    payout_status: false,
    due_date: false,
    shipping_description: false,
    customer: false,
    amount: false,
    amount_due: false,
    reporting_amount: false,
    crypto_amount: false,
    crypto_currency: false,
    source: false,
    product: false,
    price: false,
    contains_recurring_price: false,
    reason: false,
    crypto_from_address: false,
    crypto_to_address: false,
    tx_hash: false,
    topup_method: false,
    type: false,
    parent: false,
    delivry_status: false,
  });
  const { filters: appFilters } = useSelector(
    (state) => state.app
  );
  // const { current_account } = useSelector((state) => state.auth);
  useOutsideClick(dropdownRef, handleDocumentClick);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getFormSchema = useMemo(() => {
    const validationSchema = {};

    const date_fields = [];

    if (enabledFilters.date) {
      date_fields.push('created_at');
    }

    if (enabledFilters.due_date) {
      date_fields.push('due_date');
    }

    date_fields.forEach((date_field) => {
      if (
        appFilters[`${date_field}_option`] === 'in_the_last'
      ) {
        validationSchema[`${date_field}_type`] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
        validationSchema[`${date_field}_number`] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }

      if (
        [
          'is_equal_to',
          'is_after',
          'is_on_or_after',
          'is_before',
          'is_before_or_on',
        ].includes(appFilters[`${date_field}_option`])
      ) {
        validationSchema[`${date_field}_start_date`] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }

      if (
        appFilters[`${date_field}_option`] === 'is_between'
      ) {
        validationSchema[`${date_field}_start_date`] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
        validationSchema[`${date_field}_end_date`] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
    });

    if (enabledFilters.name) {
      validationSchema.name = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.email) {
      validationSchema.email = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.shippable) {
      validationSchema.shippable = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.url) {
      validationSchema.url = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.metadata) {
      validationSchema.metadata = yup.array().of(
        yup.object().shape({
          key: yup
            .string()
            .required('Required')
            .trim()
            .label('Key'),
          value: yup
            .string()
            .required('Required')
            .trim()
            .label('Value'),
        })
      );
    }

    if (enabledFilters.currency) {
      validationSchema.currency = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.discount_type) {
      validationSchema.type = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.discount_terms) {
      validationSchema.terms = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.calculation) {
      validationSchema.inclusive = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.invoice_type) {
      validationSchema.type = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }
    if (enabledFilters.topup_method) {
      validationSchema.topup_method = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }
    if (enabledFilters.invoice_status) {
      validationSchema.status = yup
        .string()
        .required('Required')
        .trim()
        .label('Input')
        .typeError('Required');
    }

    if (enabledFilters.payment_status) {
      validationSchema.status = yup
        .string()
        .required('Required')
        .trim()
        .label('Input')
        .typeError('Required');
    }
    if (enabledFilters.topup_status) {
      validationSchema.status = yup
        .string()
        .required('Required')
        .trim()
        .label('Input')
        .typeError('Required');
    }
    if (enabledFilters.payout_status) {
      validationSchema.status = yup
        .string()
        .required('Required')
        .trim()
        .label('Input')
        .typeError('Required');
    }

    if (enabledFilters.shipping_description) {
      validationSchema.query = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.customer) {
      validationSchema.customer = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.amount) {
      if (appFilters['amount_option'] === 'is_equal_to') {
        validationSchema['amount'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (appFilters['amount_option'] === 'is_between') {
        validationSchema['amount_gte'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
        validationSchema['amount_lte'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (
        appFilters['amount_option'] === 'is_greater_than'
      ) {
        validationSchema['amount_gt'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (appFilters['amount_option'] === 'is_less_than') {
        validationSchema['amount_lt'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
    }

    if (enabledFilters.reporting_amount) {
      if (appFilters['amount_option'] === 'is_equal_to') {
        validationSchema['amount'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (appFilters['amount_option'] === 'is_between') {
        validationSchema['amount_gte'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
        validationSchema['amount_lte'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }

      if (
        appFilters['amount_option'] === 'is_greater_than'
      ) {
        validationSchema['amount_gt'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (appFilters['amount_option'] === 'is_less_than') {
        validationSchema['amount_lt'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
    }

    if (enabledFilters.amount_due) {
      if (appFilters["amount_due_option"] === "is_equal_to") {
        validationSchema["amount_due"] = yup
          .string()
          .required("Required")
          .trim()
          .label("Input");
      }
      if (appFilters["amount_due_option"] === "is_between") {
        validationSchema["amount_due_gte"] = yup
          .string()
          .required("Required")
          .trim()
          .label("Input");
        validationSchema["amount_due_lte"] = yup
          .string()
          .required("Required")
          .trim()
          .label("Input");
      }
      if (appFilters["amount_due_option"] === "is_greater_than") {
        validationSchema["amount_due_gt"] = yup
          .string()
          .required("Required")
          .trim()
          .label("Input");
      }
      if (appFilters["amount_due_option"] === "is_less_than") {
        validationSchema["amount_due_lt"] = yup
          .string()
          .required("Required")
          .trim()
          .label("Input");
      }
    }

    if (enabledFilters.crypto_amount) {
      if (
        appFilters['crypto_amount_option'] === 'is_equal_to'
      ) {
        validationSchema['crypto_amount'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (
        appFilters['crypto_amount_option'] === 'is_between'
      ) {
        validationSchema['crypto_amount_gte'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
        validationSchema['crypto_amount_lte'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (
        appFilters['crypto_amount_option'] ===
        'is_greater_than'
      ) {
        validationSchema['crypto_amount_gt'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
      if (
        appFilters['crypto_amount_option'] ===
        'is_less_than'
      ) {
        validationSchema['crypto_amount_lt'] = yup
          .string()
          .required('Required')
          .trim()
          .label('Input');
      }
    }

    if (enabledFilters.contains_recurring_price) {
      validationSchema.type = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.product) {
      validationSchema.product = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }
    if (enabledFilters.price) {
      validationSchema.price = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.crypto_currency) {
      validationSchema.crypto_currency = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    if (enabledFilters.source) {
      validationSchema.source = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }
    if (enabledFilters.crypto_from_address) {
      validationSchema.crypto_from_address = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }
    if (enabledFilters.crypto_to_address) {
      validationSchema.crypto_to_address = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }
    if (enabledFilters.tx_hash) {
      validationSchema.tx_hash = yup
        .string()
        .required('Required')
        .trim()
        .label('Input');
    }

    return validationSchema;
  }, [enabledFilters, appFilters]);

  const form = useForm({
    resolver: yupResolver(
      yup.object(getFormSchema).required()
    ),
  });

  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 15,
          },
        },
      ],
    }
  );

  const toggleFilter = useCallback((filter, value) => {
    console.log(filter, value);
    setEnabledFilters((state) => ({
      ...state,
      [filter]: value,
    }));
  }, []);

  useEffect(() => {
    if (data) {
      form.setValue('status', null);
      if (!isEmpty(data)) {
        const states = Object.entries(data);
        states.forEach((state) => {
          if (state[1] && state[1] !== '') {
            if (state[0] === 'status' && state[0] !== '') {
              form.setValue(state[0], state[1]);
            }
          }
        });
      }

      if (page === 'payments')
        toggleFilter('payment_status', 'status' in data);
      console.log(page)
      if (page === 'invoices')
        toggleFilter('invoice_status', 'status' in data);
      if (page === 'topups') toggleFilter('topup_status', 'status' in data);
      if (page === 'payouts')
        toggleFilter('payout_status', 'status' in data);
      setDisableStatus(!isEmpty(data) || 'status' in data);
    }
  }, [data, page, toggleFilter, form]);

  useEffect(() => {
    console.log('enabledFilters', enabledFilters)
    setFiltersCount(
      Object.values(enabledFilters).filter(
        (state) => state === true
      ).length
    );
  }, [enabledFilters]);

  function handleDocumentClick() {
    setVisibility(false);
    update && update();
  }

  function handleDropdownClick() {
    setVisibility(!visible);
    update && update();
  }

  return (
    <div ref={dropdownRef}>
      <div
        ref={referenceRef}
        onClick={handleDropdownClick}
        className='cursor-pointer'>
        <DashboardButton
          outline
          color='light'
          icon={<FilterIcon />}>
          <div className='flex items-center gap-3'>
            <span>Filters</span>
            {filtersCount > 0 && (
              <>
                <span className='vertical-divider' />
                <span className='text-tertiary'>
                  {filtersCount}
                </span>
              </>
            )}
          </div>
        </DashboardButton>
      </div>
      <div
        ref={popperRef}
        style={{ ...styles.popper, zIndex: 10 }}
        {...attributes.popper}>
        <div
          style={styles.offset}
          className={clsx(
            'app-dropdown app-dropdown__filters',
            visible && 'app-dropdown--open'
          )}>
          <div className='app-dropdown__items'>
            <div className='app-dropdown__filters__header px-4'>
              <DropdownFiltersButtonClear
                setEnabledFilters={setEnabledFilters}
              />
              <span className='text-small'>Filters</span>
              <DropdownFiltersButtonDone
                form={form}
                filters={filters}
                onChange={onChange}
                setFiltersCount={setFiltersCount}
                enabledFilters={enabledFilters}
                handleDocumentClick={handleDocumentClick}
              />
            </div>
            {filters.includes('invoice_status') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  disabled={disableStatus}
                  state={enabledFilters.invoice_status}
                  form={form}
                  title='Status'
                  placeholder='Select status'
                  field='status'
                  options={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Issued', value: 'issued' },
                    { label: 'Paid', value: 'paid' },
                    {
                      label: 'Part paid',
                      value: 'part_paid',
                    },
                    {
                      label: 'Over due',
                      value: 'over_due',
                    },
                    {
                      label: 'Uncollectible',
                      value: 'uncollectible',
                    },
                    { label: 'Void', value: 'void' },
                  ]}
                  defaultVal='draft'
                  onToggle={(value) => {
                    toggleFilter('invoice_status', value)
                  }
                  }
                />
              </div>
            )}
            {filters.includes('payment_status') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  disabled={disableStatus}
                  state={enabledFilters.payment_status}
                  form={form}
                  title='Status'
                  placeholder='Select status'
                  field='status'
                  options={[
                    { label: 'Pending', value: 'pending' },
                    {
                      label: 'Cancelled',
                      value: 'cancelled',
                    },
                    { label: 'Failed', value: 'failed' },
                    {
                      label: 'Succeeded',
                      value: 'succeeded',
                    },
                    {
                      label: 'Under Paid',
                      value: 'under_paid',
                    },
                    {
                      label: 'Over Paid',
                      value: 'over_paid',
                    },
                  ]}
                  defaultVal='pending'
                  onToggle={(value) =>
                    toggleFilter('payment_status', value)
                  }
                />
              </div>
            )}
            {filters.includes('currency_type') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.currency_type}
                  form={form}
                  title='Type'
                  defaultVal='crypto'
                  placeholder='Select Type'
                  field='type'
                  options={[
                    {
                      value: 'crypto',
                      label: 'Crypto',
                    },
                    {
                      value: 'fiat',
                      label: 'Fiat',
                    }
                  ]}
                  onToggle={(value) =>
                    toggleFilter('currency_type', value)
                  }
                  tooltip="Will only return transactions made with the specified currency type"
                />
              </div>
            )}
            {filters.includes('topup_method') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.topup_method}
                  form={form}
                  title='Method'
                  defaultVal='crypto'
                  placeholder='Method'
                  field='topup_method'
                  options={[
                    { label: 'Blockchain', value: 'crypto' },
                    {
                      label: 'Binance',
                      value: 'binance_pay',
                    },
                    { label: 'Crypto.com', value: 'crypto_com_pay' },
                    {
                      label: 'PayPal',
                      value: 'paypal',
                    },
                    {
                      label: 'Bank Transfer',
                      value: 'bank_account',
                    },
                    {
                      label: 'WeChat',
                      value: 'wechat',
                    },
                    {
                      label: 'Card',
                      value: 'card',
                    },
                  ]}
                  onToggle={(value) =>
                    toggleFilter('topup_method', value)
                  }
                />
              </div>
            )}
            {filters.includes('topup_status') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  // disabled={disableStatus}
                  state={enabledFilters.topup_status}
                  form={form}
                  title='Status'
                  placeholder='Select status'
                  field='status'
                  options={[
                    { label: 'Pending', value: 'pending' },
                    {
                      label: 'Cancelled',
                      value: 'cancelled',
                    },
                    { label: 'Failed', value: 'failed' },
                    {
                      label: 'Succeeded',
                      value: 'succeeded',
                    },
                    {
                      label: 'Under Paid',
                      value: 'under_paid',
                    },
                    {
                      label: 'Over Paid',
                      value: 'over_paid',
                    },
                  ]}
                  defaultVal='pending'
                  onToggle={(value) =>
                    toggleFilter('topup_status', value)
                  }
                />
              </div>
            )}
            {filters.includes('payout_status') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.payout_status}
                  form={form}
                  title='Status'
                  placeholder='Select status'
                  field='status'
                  options={[
                    { label: 'Pending', value: 'pending' },
                    { label: 'Cancelled', value: 'cancelled' },
                    { label: 'Failed', value: 'failed' },
                    { label: 'Succeeded', value: 'succeeded' },
                  ]}
                  defaultVal='pending'
                  onToggle={(value) =>
                    toggleFilter('payout_status', value)
                  }
                />
              </div>
            )}
            {filters.includes('customer') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Customer'
                  inputPlaceholder='A customer ID'
                  state={enabledFilters.customer}
                  form={form}
                  field='customer'
                  onToggle={(value) =>
                    toggleFilter('customer', value)
                  }
                />
              </div>
            )}
            {filters.includes('amount') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersAmount
                  state={enabledFilters.amount}
                  form={form}
                  onToggle={(value) =>
                    toggleFilter('amount', value)
                  }
                />
              </div>
            )}
            {filters.includes('reporting_amount') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersAmount
                  state={enabledFilters.reporting_amount}
                  form={form}
                  placeholder={'Reporting Amount'}
                  onToggle={(value) =>
                    toggleFilter('reporting_amount', value)
                  }
                />
              </div>
            )}
            {filters.includes("amount_due") && (
              <div className="app-dropdown__filters__item">
                <DropdownFiltersAmountDue
                  state={enabledFilters.amount_due}
                  form={form}
                  onToggle={(value) => toggleFilter("amount_due", value)}
                />
              </div>
            )}
            {filters.includes("crypto_amount") && (
              <div className="app-dropdown__filters__item">

                <DropdownFiltersCryptoAmount
                  state={enabledFilters.crypto_amount}
                  form={form}
                  onToggle={(value) =>
                    toggleFilter('crypto_amount', value)
                  }
                />
              </div>
            )}
            {filters.includes('currency') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.currency}
                  form={form}
                  title='Currency'
                  placeholder='Currency'
                  field='currency'
                  options={FIAT_CURRENCIES_SELECT}
                  onToggle={(value) =>
                    toggleFilter('currency', value)
                  }
                />
              </div>
            )}
            {filters.includes('crypto_currency') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersCrypto
                  state={enabledFilters.crypto_currency}
                  form={form}
                  placeholder='Cryptocurrency'
                  field='crypto_currency'
                  onToggle={(value) =>
                    toggleFilter('crypto_currency', value)
                  }
                />
              </div>
            )}
            {filters.includes('reason') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.reason}
                  form={form}
                  title='Reason'
                  placeholder='Reason'
                  field='reason'
                  options={typesTableArray(typesTable)}
                  onToggle={(value) => {
                    console.log(value)
                    toggleFilter('reason', value)
                  }
                  }
                />
              </div>
            )}
            {filters.includes('source') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Source'
                  inputPlaceholder='A source ID'
                  state={enabledFilters.source}
                  form={form}
                  field='source'
                  onToggle={(value) =>
                    toggleFilter('source', value)
                  }
                  info='Search for payments related to a specific invoice, order or payment link'
                />
              </div>
            )}
            {filters.includes('crypto_from_address') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  title='Address'
                  placeholder='From Address'
                  inputPlaceholder='Enter an address'
                  state={enabledFilters.crypto_from_address}
                  form={form}
                  field='crypto_from_address'
                  onToggle={(value) =>
                    toggleFilter('crypto_from_address', value)
                  }
                  info='Search by a receiving blockchain address'
                />
              </div>
            )}
            {filters.includes('crypto_to_address') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  title='Address'
                  placeholder='To Address'
                  inputPlaceholder='Enter an address'
                  state={enabledFilters.crypto_to_address}
                  form={form}
                  field='crypto_to_address'
                  onToggle={(value) =>
                    toggleFilter('crypto_to_address', value)
                  }
                  info='Search by a recipient blockchain address'
                />
              </div>
            )}
            {filters.includes('tx_hash') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  title='Transaction Hash'
                  placeholder='Transaction hash'
                  inputPlaceholder='Enter a hash'
                  state={enabledFilters.tx_hash}
                  form={form}
                  field='tx_hash'
                  onToggle={(value) =>
                    toggleFilter('tx_hash', value)
                  }
                  info='Search by a blockchain transaction hash'
                />
              </div>
            )}
            {filters.includes('date') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersDate
                  state={enabledFilters.date}
                  form={form}
                  onToggle={(value) =>
                    toggleFilter('date', value)
                  }
                />
              </div>
            )}
            {filters.includes('type') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  inputPlaceholder='E.g: product. *'
                  tooltip='Search by a product ID'
                  title={'Type'}
                  state={enabledFilters.type}
                  form={form}
                  field='type'
                  onToggle={(value) =>
                    toggleFilter('type', value)
                  }
                />
              </div>
            )}
            {filters.includes('parent') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  title='Parent'
                  state={enabledFilters.parent}
                  inputPlaceholder='A parent object ID’'
                  form={form}
                  field='parent'
                  onToggle={(value) =>
                    toggleFilter('parent', value)
                  }
                />
              </div>
            )}            {filters.includes('delivry_status') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.delivry_status}
                  form={form}
                  title='Delivery Status'
                  defaultVal={true}
                  placeholder='Select status'
                  field='delivry_status'
                  options={[
                    {
                      value: true,
                      label: 'Successful',
                    },
                    {
                      value: false,
                      label: 'Pending/Failed',
                    }
                  ]}
                  onToggle={(value) =>
                    toggleFilter('delivry_status', value)
                  }
                />
              </div>
            )}
            {filters.includes('name') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Name'
                  state={enabledFilters.name}
                  form={form}
                  field='name'
                  onToggle={(value) =>
                    toggleFilter('name', value)
                  }
                />
              </div>
            )}
            {filters.includes('email') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Email'
                  state={enabledFilters.email}
                  form={form}
                  field='email'
                  onToggle={(value) =>
                    toggleFilter('email', value)
                  }
                />
              </div>
            )}
            {filters.includes('shippable') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.shippable}
                  form={form}
                  title='Shippable'
                  defaultVal={true}
                  placeholder='Shippable'
                  field='shippable'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  onToggle={(value) =>
                    toggleFilter('shippable', value)
                  }
                />
              </div>
            )}
            {filters.includes('url') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='URL'
                  state={enabledFilters.url}
                  form={form}
                  field='url'
                  onToggle={(value) =>
                    toggleFilter('url', value)
                  }
                />
              </div>
            )}
            {filters.includes('metadata') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersMetadata
                  form={form}
                  state={enabledFilters.metadata}
                  onToggle={(value) =>
                    toggleFilter('metadata', value)
                  }
                />
              </div>
            )}
            {filters.includes('discount_type') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.discount_type}
                  form={form}
                  title='Type'
                  defaultVal='standard'
                  placeholder='Type'
                  field='type'
                  options={[
                    {
                      label: 'Standard Discount',
                      value: 'standard',
                    },
                    {
                      label: 'Sale Amount',
                      value: 'amount_gte',
                    },
                    {
                      label: 'Product Specific',
                      value: 'product',
                    },
                    {
                      label: 'Product Quantity',
                      value: 'product_quantity',
                    },
                  ]}
                  onToggle={(value) =>
                    toggleFilter('discount_type', value)
                  }
                />
              </div>
            )}
            {filters.includes('discount_terms') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.discount_terms}
                  form={form}
                  title='Terms'
                  defaultVal='amount_off'
                  placeholder='Terms'
                  field='terms'
                  options={[
                    {
                      label: 'Amount Off',
                      value: 'amount_off',
                    },
                    {
                      label: 'Percent Off',
                      value: 'percent_off',
                    },
                  ]}
                  onToggle={(value) =>
                    toggleFilter('discount_terms', value)
                  }
                />
              </div>
            )}
            {filters.includes('calculation') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.calculation}
                  form={form}
                  title='Calculation'
                  defaultVal={true}
                  placeholder='Calculation'
                  field='inclusive'
                  options={[
                    { label: 'Inclusive', value: true },
                    { label: 'Exclusive', value: false },
                  ]}
                  onToggle={(value) =>
                    toggleFilter('calculation', value)
                  }
                />
              </div>
            )}
            {filters.includes('invoice_type') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersSelect
                  state={enabledFilters.invoice_type}
                  form={form}
                  title='Type'
                  defaultVal='one_off'
                  placeholder='Select type'
                  field='type'
                  options={[
                    { label: 'One Off', value: 'one_off' },
                    {
                      label: 'Subscription',
                      value: 'subscription',
                    },
                  ]}
                  onToggle={(value) =>
                    toggleFilter('invoice_type', value)
                  }
                />
              </div>
            )}
            {filters.includes('due_date') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersDate
                  state={enabledFilters.due_date}
                  form={form}
                  placeholder='Choose a date'
                  filter_field='due_date'
                  onToggle={(value) =>
                    toggleFilter('due_date', value)
                  }
                />
              </div>
            )}
            {filters.includes('shipping_description') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Description'
                  state={
                    enabledFilters.shipping_description
                  }
                  form={form}
                  field='query'
                  onToggle={(value) =>
                    toggleFilter(
                      'shipping_description',
                      value
                    )
                  }
                />
              </div>
            )}
            {filters.includes(
              'contains_recurring_price'
            ) && (
                <div className='app-dropdown__filters__item'>
                  <DropdownFiltersSelect
                    state={
                      enabledFilters.contains_recurring_price
                    }
                    form={form}
                    title='Type'
                    placeholder='Select type'
                    field='type'
                    options={[
                      { label: 'One Off', value: 'one_off' },
                      {
                        label: 'Subscription',
                        value: 'subscription',
                      },
                    ]}
                    defaultVal='one_off'
                    onToggle={(value) =>
                      toggleFilter(
                        'contains_recurring_price',
                        value
                      )
                    }
                  />
                </div>
              )}
            {filters.includes('product') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Product'
                  inputPlaceholder='Select a product'
                  state={enabledFilters.product}
                  form={form}
                  field='product'
                  onToggle={(value) =>
                    toggleFilter('product', value)
                  }
                />
              </div>
            )}
            {filters.includes('price') && (
              <div className='app-dropdown__filters__item'>
                <DropdownFiltersTextbox
                  placeholder='Price'
                  inputPlaceholder='Select a price'
                  state={enabledFilters.price}
                  form={form}
                  field='price'
                  onToggle={(value) =>
                    toggleFilter('price', value)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip
        place='bottom'
        effect='solid'
        type='light'
        padding='10px'
        textColor='#1A1E23'
        backgroundColor='#ffffff'
        className='api-key__tooltip'
      />
    </div>
  );
}

export default DropdownFilters;
