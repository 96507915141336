import { useEffect, useMemo, useState } from 'react';
// import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { useDispatch, useSelector } from 'react-redux';
import LabelTag from 'components/Common/LabelTag/LabelTag';
import { ReactComponent as DateIcon } from 'assets/images/icons/date.svg';
import InfoIcon from "assets/images/icons/info.svg";

// import {
//   useDeleteInvoice,
//   useFetchInvoices,
// } from '../../../../../../api/customer/invoice';
import clsx from 'clsx';
import {
    capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from 'utils/string';
// import { dateFormatter } from '../../../../../../utils/date';
// import {
//   setRefetchInvoices,
//   setSelectedInvoice,
// } from '../../../../../../store/invoice/invoiceSlice';
import { toast } from 'react-toastify';
// import InvoicesTableActions from './InvoicesTableActions/InvoicesTableActions';
// import useTablePagination from '../../../../hooks/useTablePagination';
import useTablePagination from 'hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import ModalDelete from 'components/Dashboard/Modals/ModalDelete/ModalDelete';
import DashboardTable from 'components/Dashboard/DashboardTable/DashboardTable';
import { dateFormatter } from 'utils/date';
import styled from 'styled-components';
import { setSelectedSwap } from 'store/swaps/swapsSlice';
import { useFetchSwaps } from 'api/wallet/swaps';
// import { ReactComponent as RefreshIcon } from '../../../../../../assets/images/icons/refresh.svg';

function SwapsTable({ filters = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);

  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows ,    filters);

//   const {
//     mutate: mutateDeleteInvoice,
//     isLoading: isDeleteLoading,
//   } = useDeleteInvoice(deleteInvoice?.id);

//   useEffect(() => {
//     setRefetch(true);
//   }, [current_account, refetch_invoices, filters]);

//   useEffect(() => {
//     if (requestQuery) setRefetch(true);
//   }, [requestQuery]);

  const onFetchSwapsSuccess = (data) => {
    setData(data);
    console.log(data.data)
    const tableData = data.data.map((row) => {
      return {
        id: row.id,
        crypto_currency: row.crypto_currency,
        currency : row.currency,
        type: row.type,
        crypto_amount: cryptocurrencyFormatter(row.crypto_amount),
        // crypto_fee : cryptocurrencyFormatter(row.crypto_fee),
        // crypto_net :cryptocurrencyFormatter( row.crypto_net),
        amount: (row.amount) || "null",
        target_crypto_amount :  cryptocurrencyFormatter(row.target_crypto_amount),
        target_crypto_currency : row.target_crypto_currency,
        currency : row.balance_transaction.currency,
        amount :row.balance_transaction.amount,
        rate : row.exchange_rate,
        created: dateFormatter(row.created_at, true),
        _data: row,
      };
    });
    setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchSwapsError = (e) => {
    // setRefetch(false);
    console.log(e)
  };

  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const { isFetching: isFetchingData } = useFetchSwaps({
    onSuccess: onFetchSwapsSuccess,
    onError: onFetchSwapsError,
    params: {
        expand: ['balance_transaction'],
        limit: limitQuery,
        ...requestQuery,
        ...filters,
      },
   enabled : true,
  });

  const handleRowClick = (row) => {
    dispatch(setSelectedSwap(row._data));
    navigate(DASHBOARD_PATH + `/swaps/${row.id}`);

  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div
              className='flex font-normal items-center justify-start'
              style={{ fontSize: '1.0rem' , color : "#B0B5BE"  }}>
                <DateIcon style={{margin: "0 5px"}}/>
            {row?.created}
            </div>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'Type',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
              <div  className={'font-semibold '+ (row.type === "instant" ?  'text-primary' : 'text-tertiary'  )}>
                {capitalize(row.type.toUpperCase())}
              </div>
          );
        },
      },
     
      {
        Header: 'Amount',
        accessor: 'amount',
        maxWidth: 60, 

        // maxWidth: 60,
        // Width: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return  <CryptoAmountStyle>

          <div>
            <img   src={getIconUrl(row?.crypto_currency) } />
            {row?.crypto_amount} 
            {" "}
            {row?.crypto_currency}
          </div>
          <span>
           ( {
              currencyPrettier(row?.currency ,row?.amount)
            })
          </span>
      </CryptoAmountStyle>

} ,
        },
      {
        Header: 'Fee From Net',
        accessor: 'fee',
        maxWidth: 60, 
        Cell: (cell) => 
        {
          const row = cell.row.original;
          return (
            
  
            <LabelTag
            color={clsx(
             row?.status === 'succeeded' && 'primary',
             row?.status === 'processing' && 'tertiary',
             row?.status === 'void' && 'warning',
             row?.status === 'canceled' && 'secondary',
             row?.status === 'failed' && 'danger',
             row?.status === 'uncollectible' && 'dark'
            )}>
            {/* {cell.value} */}
            {row?.status}
              
          </LabelTag>
  
          )
          }
      },
      {
        Header: 'To',
        accessor: 'to',
        maxWidth: 60,
        Cell: (cell) => 
        {
          const row = cell.row.original;
          return (
            
  
            <CryptoAmountStyle>

            <div>
              <img   src={getIconUrl(row?.target_crypto_currency) } />
              {row?.target_crypto_amount} 
              {" "}
              {row?.target_crypto_currency}
            </div>
            <span>
             ( {
                currencyPrettier(row?.currency ,row?.amount)
              })
            </span>
        </CryptoAmountStyle>
  
          )
          }
      },
      {
        Header: <div  className='flex align-center
        '      >
          Rate
          <ToolTip
          tooltip={"From currency to currency"}
          icon={InfoIcon}
          />
        </div>,
        accessor: "rate",
        maxWidth: 60,
        Cell: (cell) => 
        {
          const row = cell.row.original;
          return (
                    <div className="font-semibold">
                      
                        {row?.rate}
                    </div>
          )
          }
      },
    ],
    []
  );


  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount} 
          noRecordTag="Swaps"
        />

       </div>
      {/* {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={ false}//isDeleteLoading}
          title='Delete Invoice'
          message='Are you sure you want to delete this invoice?'
        />
      )} */}
    </>
  );
}



export  function ToolTip({
  icon = null,
  alt=null,
  tooltip = "",
  tooltipFor = null,
  tooltipHtml = false,
}) {
  return (
    <div className=' flex-center'>
      {icon && (
        <div className="app-input__label__icon">
          <img
            data-html={tooltipHtml}
            data-for={tooltipFor}
            data-tip={tooltip}
            src={icon}
            alt={alt ?? tooltip}
          />
        </div>
      )}
    </div>
  )
}
const ToolTipStyle = styled.div`
  display: flex;
  `;

const CryptoAmountStyle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div{
    display: flex;
    align-items: center;
    gap: 5px;
    >img{
        width: 20px;
        height: 20px;
    }
  }
  > span{

  }

  `;
export default SwapsTable;
