// import "./Webhooks.scss";
import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../../components/Common/Form/DashboardButton/DashboardButton";
// import WebhooksTable from "../../../../components/Pages/Dashboard/Webhooks/WebhooksTable/WebhooksTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFetchWebhook } from "../../../../api/webhook/webhook";
import { useNavigate, useParams } from "react-router-dom";
import EventsTable from "./EventsTable";
import DropdownFilters from "components/Common/DropdownFilters/DropdownFilters";
import useTableFilters from "hooks/useTableFilters";
import EventsDetails from "./EventsDetails";
import { useFetchEvent, useFetchEvents } from "api/event/event";
import {
	setRefetchEvent,
	setSelectedEvent
} from "store/events/eventsSlice";

export default function Events() {
	const { live_mode_access } = useSelector((state) => state.auth.current_account);
	const DASHBOARD_PATH = !live_mode_access ? '/dashboard/test' : '/dashboard';
	const { selected_event, refetch_event } = useSelector((state) => state.event);
	const [refreshEvent, setRefreshEvent] = useState(false);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [filters, setFilters] = useState({});
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useFetchEvent({
		onSuccess: (data) => {
			dispatch(setSelectedEvent(data));
			setRefetchEvent(false);
		},
		onError: (error) => {
			setRefetchEvent(false);
			navigate(DASHBOARD_PATH + "/events");
			toast.error(error.response?.data.error.message);
		},
		id,
		enabled: refreshEvent,
		params: {
			expand: [
				// 'blockchain_transaction',
				// 'balance_transaction',
				// 'network_fee_balance_transaction'
			],
		},
	});

	const handleFiltersChange = (filters) => {
		setFilters(filters);
	};
	const handleCloseDetailsModal = useCallback(() => {
		setShowDetailsModal(false);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowDetailsModal(false);
		dispatch(setSelectedEvent(null));
		navigate(DASHBOARD_PATH + "/events");
	}, [dispatch, navigate]);
	useEffect(() => {
		setShowDetailsModal(!!selected_event);
	}, [selected_event]);

	useEffect(() => {
		if (!id) {
			handleCloseModal();
			return;
		}

		setRefreshEvent(true);
	}, [refetch_event, id, handleCloseModal]);

	const getFilters = useMemo(() => {
		console.log("filters", filters);
		return {
			...filters,
		};
	}, [filters]);

	return (
		<div className="app-dashboard-container">
			<DashboardNavbar />
			<div className="app-dashboard__content">
				<div className="app-dashboard__card">
					<div className="app-dashboard__card__header">
						<div className="app-dashboard__card__title">Events</div>
						<DropdownFilters
							filters={[
								"date",
								"type",
								"parent",
								"delivry_status",
							]}
							data={{}}
							onChange={handleFiltersChange}
							page="events"
						/>
					</div>
					<EventsTable
						filters={getFilters}
					/>
				</div>
				{showDetailsModal &&
					<EventsDetails show={showDetailsModal} onClose={handleCloseDetailsModal} />
				}
			</div>
		</div>
	)
}
