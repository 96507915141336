import { useEffect, useMemo, useState } from 'react';
// import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { useDispatch, useSelector } from 'react-redux';
import LabelTag from 'components/Common/LabelTag/LabelTag';
import { ReactComponent as DateIcon } from 'assets/images/icons/date.svg';

// import {
//   useDeleteInvoice,
//   useFetchInvoices,
// } from '../../../../../../api/customer/invoice';
import clsx from 'clsx';
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from 'utils/string';
// import { dateFormatter } from '../../../../../../utils/date';
// import {
//   setRefetchInvoices,
//   setSelectedInvoice,
// } from '../../../../../../store/invoice/invoiceSlice';
import { toast } from 'react-toastify';
// import InvoicesTableActions from './InvoicesTableActions/InvoicesTableActions';
// import useTablePagination from '../../../../hooks/useTablePagination';
import useTablePagination from 'hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import ModalDelete from 'components/Dashboard/Modals/ModalDelete/ModalDelete';
import DashboardTable from 'components/Dashboard/DashboardTable/DashboardTable';
import { useFetchBalanceTransactions } from 'api/wallet/balance_transactions';
import { dateFormatter } from 'utils/date';
import styled from 'styled-components';
import { setSelectedTransaction } from 'store/transactions/transactionsSlice';
import { useFetchTopups } from 'api/wallet/topups';
import { useFetchPayouts } from 'api/wallet/payouts';
import { useFetchTransfers } from 'api/wallet/transfers';
// import { ReactComponent as RefreshIcon } from '../../../../../../assets/images/icons/refresh.svg';

function TransferTable({ filters = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [deleteInvoice, setDeleteInvoice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] =
    useState(false);
  const [data, setData] = useState([]);
//   const { current_account } = useSelector(
//     (state) => state.auth
//   );
//   const { refetch_invoices } = useSelector(
//     (state) => state.invoice
//   );
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

//   const {
//     mutate: mutateDeleteInvoice,
//     isLoading: isDeleteLoading,
//   } = useDeleteInvoice(deleteInvoice?.id);

//   useEffect(() => {
//     setRefetch(true);
//   }, [current_account, refetch_invoices, filters]);

//   useEffect(() => {
//     if (requestQuery) setRefetch(true);
//   }, [requestQuery]);
const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  const onFetchInvoicesSuccess = (data) => {
    setData(data);
    console.log(data.data)
    // const tableData = data.data.map((row) => {
    //   return {
    //     id: row.id,
    //     crypto_currency: row.crypto_currency,
    //     currency : row.currency,
    //     type: row.type,
    //     status: row.status,
    //     crypto_amount: cryptocurrencyFormatter(row.crypto_amount),
    //     // crypto_fee : cryptocurrencyFormatter(row.crypto_fee),
    //     // crypto_net :cryptocurrencyFormatter( row.crypto_net),
    //     amount: (row.amount),
    //     // fee: (row.fee),
    //     // net :( row.net),
    //     description : "null",
    //     created: dateFormatter(row.created_at, true),
    //     _data: row,
    //   };
    // });
    // setTableRows(tableData);
    setRefetch(false);
  };

  const onFetchInvoicesError = (e) => {
    // setRefetch(false);
    console.log(e)
  };


  const { isFetching: isFetchingData } = useFetchTransfers({
    onSuccess: onFetchInvoicesSuccess,
    onError: onFetchInvoicesError,
    params: {
        // expand: ['customer'],
        limit: limitQuery,
        ...requestQuery,
        ...filters,
      },
   enabled : true,
  });

  const handleRowClick = (row) => {
    dispatch(setSelectedTransaction(row._data));
    navigate(DASHBOARD_PATH + `/balance_transactions/${row.id}`);

  };

  const handleDeleteClick = (invoice) => {
    // setDeleteInvoice(invoice);
    // setShowDeleteModal(true);

  };

  const handleModalDelete = () => {
    // mutateDeleteInvoice(null, {
    //   onSuccess: () => {
    //     setDeleteInvoice(null);
    //     setShowDeleteModal(false);
    //     dispatch(setSelectedInvoice(null));
    //     dispatch(setRefetchInvoices(Date.now()));
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data.error.message);
    //   },
    // });
  };
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div
              className='app-customers__modal__earning'
              style={{ fontSize: '1.0rem' , color : "#B0B5BE"  }}>
                <DateIcon style={{margin: "0 5px"}}/>
            {row?.created}
            </div>
          );
        },
      },
      {
        Header: 'App',
        accessor: 'app',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <LabelTag
              color={clsx(
                cell.value === 'paid' && 'primary',
                cell.value === 'part_paid' && 'tertiary',
                cell.value === 'void' && 'warning',
                cell.value === 'issued' && 'secondary',
                cell.value === 'over_due' && 'danger',
                cell.value === 'uncollectible' && 'dark'
              )}>
              {/* {cell.value} */}
              {row?.type}
                
            </LabelTag>
          );
        },
      },
     
      {
        Header: 'Amount',
        accessor: 'amount',

        // maxWidth: 60,
        // Width: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return  <CryptoAmountStyle>

          <div>
            <img src={getIconUrl(row?.crypto_currency) } />
            {row?.crypto_amount} 
            {" "}
            {row?.crypto_currency}
          </div>
          <span>
           ( {
              currencyPrettier(row?.currency ,row?.amount)
            })
          </span>
      </CryptoAmountStyle>

} ,
        },
      {
        Header: 'Status',
        accessor: '',
        maxWidth: 60,
        Cell: (cell) => 
        {
          const row = cell.row.original;
          return (
            
  
            <LabelTag
            color={clsx(
             row?.status === 'succeeded' && 'primary',
             row?.status === 'processing' && 'tertiary',
             row?.status === 'void' && 'warning',
             row?.status === 'canceled' && 'secondary',
             row?.status === 'failed' && 'danger',
             row?.status === 'uncollectible' && 'dark'
            )}>
            {/* {cell.value} */}
            {row?.status}
              
          </LabelTag>
  
          )
          }
      },
    ],
    []
  );


  return (
    <>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={tableRows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount} 
          noRecordTag="transfers"
        />

       </div>
      {/* {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={ false}//isDeleteLoading}
          title='Delete Invoice'
          message='Are you sure you want to delete this invoice?'
        />
      )} */}
    </>
  );
}

const CryptoAmountStyle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div{
    display: flex;
    align-items: center;
    gap: 5px;
    >img{
        width: 20px;
        height: 20px;
    }
  }
  > span{

  }

  `;
export default TransferTable;
