import { useDispatch, useSelector } from "react-redux";
import ModalFullScreen from "../../../../../../Common/ModalFullScreen/ModalFullScreen";
import React, { useState } from "react";
import DashboardButton from "../../../../../../Common/Form/DashboardButton/DashboardButton";
import Dropdown from "../../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../../assets/images/icons/dashboard/more.svg";
import { ReactComponent as DateIcon } from "../../../../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../../../../utils/date";
import { ReactComponent as EditIcon } from "../../../../../../../assets/images/icons/dashboard/edit.svg";
import { snakeCaseToTitleCase } from "../../../../../../../utils/string";
import LabelTag from "../../../../../../Common/LabelTag/LabelTag";
import {
  useDeleteTaxRate,
  useUpdateTaxRate,
} from "../../../../../../../api/product/tax_rate";
import {
  setRefetchTaxRate,
  setSelectedTaxRate,
  setShowTaxRateForm,
} from "../../../../../../../store/tax_rate/taxRateSlice";
import { toast } from "react-toastify";
import ModalDelete from "../../../../../../Dashboard/Modals/ModalDelete/ModalDelete";
import TaxRatesModalDetailsLogsTable from "./TaxRatesModalDetailsLogsTable";
import TaxRatesModalDetailsEventsTable from "./TaxRatesModalDetailsEventsTable";
import CopyToClipboardBoxID from "../../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";

function TaxRatesModalDetails({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selected_tax_rate } = useSelector((state) => state.tax_rate);

  const { mutate: mutateUpdateTaxRate } = useUpdateTaxRate(
    selected_tax_rate?.id
  );
  const { mutate: mutateDeleteTaxRate, isLoading: isDeleteLoading } =
    useDeleteTaxRate(selected_tax_rate?.id);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleArchiveClick = () => {
    mutateUpdateTaxRate(
      {
        active: !selected_tax_rate.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setSelectedTaxRate(data.data));
          dispatch(setRefetchTaxRate(Date.now()));
          toast.success(
            `Tax rate ${selected_tax_rate.active ? "archived" : "unarchived"}`
          );
        },
      }
    );
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleModalDelete = () => {
    mutateDeleteTaxRate(null, {
      onSuccess: () => {
        setShowDeleteModal(false);
        handleClose();
        dispatch(setSelectedTaxRate(null));
        dispatch(setRefetchTaxRate(Date.now()));
        toast.success("Tax rate deleted");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleEditClick = () => {
    dispatch(setShowTaxRateForm(true));
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name="header">
          <div className="app-customers__modal__header">
            <div className="app-modal-fullscreen__header__title">Tax Rate</div>
            <CopyToClipboardBoxID text={selected_tax_rate?.id} />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name="body">
          <div className="space-y-7">
            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex items-center gap-3">
                    <div className="app-modal-fullscreen__big-title">
                      {selected_tax_rate.display_name}
                    </div>
                    <span className="text-light font-semibold">at</span>
                    <div className="app-modal-fullscreen__medium-title">
                      {selected_tax_rate.percent} %
                    </div>
                    <LabelTag
                      color={`${
                        selected_tax_rate.active ? "primary" : "light"
                      }`}
                      className="ml-3 mt-1"
                    >
                      {selected_tax_rate.active ? "Active" : "Archived"}
                    </LabelTag>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <DashboardButton size="sm" color="light" disabled>
                    Copy to livemode
                  </DashboardButton>
                  <Dropdown
                    items={[
                      {
                        label: "Delete",
                        className: "text-danger font-semibold",
                        onClick: handleDeleteClick,
                      },
                      {
                        label: selected_tax_rate.active
                          ? "Archive"
                          : "Unarchive",
                        onClick: handleArchiveClick,
                      },
                    ]}
                  >
                    <Dropdown.Slot name="toggle">
                      <MoreIcon className="cursor-pointer" />
                    </Dropdown.Slot>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="horizontal-divider" />
            <div className="flex flex-wrap gap-5 divide-x">
              <div className="pr-5 pr-20">
                <div className="app-modal-fullscreen__label">CALCULATION</div>
                <div className="app-modal-fullscreen__value">
                  {selected_tax_rate.inclusive ? "Inclusive" : "Exclusive"}
                </div>
              </div>
              <div className="pl-5 pr-20">
                <div className="app-modal-fullscreen__label">DESCRIPTION</div>
                <div className="app-modal-fullscreen__value">
                  {selected_tax_rate.description ?? "-"}
                </div>
              </div>
            </div>
            <div className="space-y-1.5">
              <div className="flex justify-between">
                <div className="app-modal-fullscreen__title">Details</div>
                <div className="flex gap-4">
                  <DashboardButton
                    outline
                    color="light"
                    size="sm"
                    icon={<EditIcon />}
                    onClick={handleEditClick}
                  >
                    Edit
                  </DashboardButton>
                </div>
              </div>
              <div className="horizontal-divider" />
              <div className="pt-4">
                <div className="grid grid-cols-5 gap-4">
                  <div className="col-span-2 space-y-2">
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">ID</div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_tax_rate.id}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Description
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_tax_rate.description ?? "-"}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">Type</div>
                      <div className="app-modal-fullscreen__kv__value">
                        {snakeCaseToTitleCase(selected_tax_rate.type)}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Created
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        <div className="flex items-center">
                          <DateIcon className="mr-1.5" />
                          <span className="app-modal-fullscreen__value">
                            {dateFormatter(selected_tax_rate.created_at, true)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 space-y-2">
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Country
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_tax_rate.country ?? "-"}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Region
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_tax_rate.state ?? "-"}
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">Rate</div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_tax_rate.percent} %
                      </div>
                    </div>
                    <div className="app-modal-fullscreen__kv">
                      <div className="app-modal-fullscreen__kv__key">
                        Calculation
                      </div>
                      <div className="app-modal-fullscreen__kv__value">
                        {selected_tax_rate.inclusive
                          ? "Inclusive"
                          : "Exclusive"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TaxRatesModalDetailsLogsTable enabled={!!selected_tax_rate} />
            <TaxRatesModalDetailsEventsTable enabled={!!selected_tax_rate} />
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title="Delete Tax Rate"
          message="Are you sure you want to delete this tax rate?"
        />
      )}
    </>
  );
}

export default TaxRatesModalDetails;
