import { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../../../../../assets/images/icons/dashboard/delete.svg";
import { useSelector } from "react-redux";
import { useUpdateInvoice } from "../../../../../../../../api/customer/invoice";
import SelectTaxRates from "../../../../../../../Dashboard/SelectTaxRates/SelectTaxRates";

function InvoiceTaxRates({ refetchInvoice }) {
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(false);
  const { draft_invoice } = useSelector((state) => state.invoice);

  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  useEffect(() => {
    if (!draft_invoice) return;
    setSelectedTaxRates(draft_invoice?.default_tax_rates);
  }, [draft_invoice]);

  const handleTaxRateSelect = (tax_rate) => {
    setShowTaxRatesSelect(false);

    if (!draft_invoice) return;

    mutateUpdateInvoice(
      {
        default_tax_rates: [
          ...selectedTaxRates.map((tax_rate) => tax_rate.id),
          tax_rate?.id,
        ],
      },
      {
        onSuccess: () => {
          refetchInvoice();
          setShowTaxRatesSelect(false);
        },
      }
    );
  };

  const handleAddTaxRateClick = () => {
    setShowTaxRatesSelect(true);
  };

  const handleDeleteTaxRateClick = (id) => {
    const tax_rates = selectedTaxRates
      .filter((tax_rate) => tax_rate.id !== id)
      .map((tax_rate) => tax_rate.id);

    mutateUpdateInvoice(
      {
        default_tax_rates: tax_rates.length ? tax_rates : "",
      },
      {
        onSuccess: () => {
          refetchInvoice();
          setShowTaxRatesSelect(false);
        },
      }
    );
  };

  return (
    <div>
      <div className="app-invoices__modal__earning">Tax rate</div>
      {selectedTaxRates.map((tax_rate, index) => (
        <div key={index}>
          <div className="app-invoices__modal__invoice-discount-tax mb-1">
            <div>
              <span className="mr-2">{`${tax_rate.display_name} (${tax_rate.percent}%${tax_rate.inclusive ? ' incl.' : ''})`}</span>
            </div>
            <DeleteIcon
              onClick={() => handleDeleteTaxRateClick(tax_rate.id)}
              className="cursor-pointer"
            />
          </div>
        </div>
      ))}
      {showTaxRatesSelect && (
        <div className="w-8/12">
          <SelectTaxRates
            onSelect={handleTaxRateSelect}
            data={selectedTaxRates.map((tax_rate) => tax_rate.id)}
          />
        </div>
      )}
      {!showTaxRatesSelect && (
        <span
          onClick={handleAddTaxRateClick}
          className="app-invoices__modal__link"
        >
          Add tax rate
        </span>
      )}
    </div>
  );
}

export default InvoiceTaxRates;
