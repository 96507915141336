function Stack({
  columns = 12,
  gap = 5,
  as = "div",
  className = "",
  children
}) {
  const Tag = as;
  return (
    <Tag className={`grid gap-${gap} grid-cols-${columns} ${className}`}>
      {children}
    </Tag>
  )
}

export default Stack;
