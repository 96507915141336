import SelectProductsSelected from "../../../../../../../Dashboard/SelectProductsSelected/SelectProductsSelected";
import {ReactComponent as ImagePlaceholder} from "../../../../../../../../assets/images/dashboard/product-placeholder.svg";
import {ReactComponent as CloseIcon} from "../../../../../../../../assets/images/icons/modal-close.svg";
import React from "react";
import {useSelector} from "react-redux";
import {useFieldArray} from "react-hook-form";

function DiscountsModalCreateProducts({ control, currency }) {
  const { selected_discount } = useSelector((state) => state.discount);

  const { fields: products, append, remove} = useFieldArray({
    control,
    name: "products",
  });

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="app-customers__modal__form-title">Products</div>
        <div className="text-light">{products.length}</div>
      </div>
      <div>
        <SelectProductsSelected
          value={null}
          placeholder="Find or add an item"
          currency={currency}
          onSelect={(product) => append(product)}
          disabled={selected_discount?.id}
        />
        {products.map((product, index) =>
          <div className="flex items-center justify-between gap-3 mt-3" key={index}>
            <div className="flex items-center gap-3">
              <div style={{width: "45px"}}>
                {product.image_url.length
                  ? <img src={product.image_url[0]} alt="Product"/>
                  : <ImagePlaceholder/>
                }
              </div>
              <span className="app-modal-fullscreen__small-title">
                {product.name}
              </span>
            </div>
            {!selected_discount &&
            <div
              onClick={() => remove(index)}
              className="cursor-pointer"
            >
              <CloseIcon/>
            </div>
            }
          </div>
        )}
      </div>
    </>
  )
}

export default DiscountsModalCreateProducts;
