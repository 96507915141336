import Textarea from "../../../../../../../Common/Form/Textarea/Textarea";
import {useDispatch, useSelector} from "react-redux";
import {useUpdateInvoice} from "../../../../../../../../api/customer/invoice";
import {setDraftInvoice} from "../../../../../../../../store/invoice/invoiceSlice";

function InvoiceFooter({ register }) {
  const dispatch = useDispatch();
  const { draft_invoice } = useSelector((state) => state.invoice);
  const { mutate: mutateUpdateInvoice } = useUpdateInvoice(draft_invoice?.id);

  const handleFooterBlur = (e) => {
    if (!draft_invoice) return;

    mutateUpdateInvoice({ footer: e.target.value }, {
      onSuccess: (data) => dispatch(setDraftInvoice(data.data))
    })
  }

  return (
    <div>
      <div className="app-modal-fullscreen__small-title mb-3">Footer</div>
      <Textarea
        {...register("footer", {
          onBlur: handleFooterBlur
        })}
        block
        placeholder="Thanks for your business."
      />
    </div>
  )
}

export default InvoiceFooter;