import { useFetchBalance } from 'api/wallet/balance';
import { useFetchCurrencies } from 'api/wallet/currencies';
import { useStoreTopup } from 'api/wallet/topups';
import Button from 'components/Common/Form/Button/Button';
import ButtonGradient from 'components/Common/Form/Button/ButtonGradient/ButtonGradient';
import Input from 'components/Common/Form/Input/Input';
import { InputPriceCrypto } from 'components/Common/Form/InputPrice/InputPrice';
import Label from 'components/Common/Form/Label/Label';
import Select from 'components/Common/Form/Select/Select';
import Modal from 'components/Common/Modal/Modal'
import SelectNetwork from 'components/Dashboard/SelectNetwork/SelectNetwork';
import moment from 'moment';
import React , {useState , useEffect , useMemo} from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components';
import crypto_currenies from 'utils/crypto_currencies';
import { currencySymbol } from 'utils/string';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import InfoIcon from "assets/images/icons/info.svg";
import TopupFinish from './TopupModals/TopupFinishModal';
import "../../styles.scss";
import TopupCryptoModal from './TopupModals/TopupCryptoModal';
import { useLocation, useNavigate } from 'react-router-dom';

export default function TopupsCreateModel({
    show = false,
    onClose = null,
    selectedCurrency = null,
}) {
  
  const types = [
    {
      label : "Crypto",
      value : "crypto",
    },
    {
      label : "Fiat",
      value : "fiat"
    }
  ]
  const methods = {
    fiat : [
      {
        label : "Bank Transfer",
        value : "bank",
      },  
      {
        label : "Paypal",
        value : "paypal",
      }
    ],
    crypto : [
      {
        label : "Blockchain",
        value : "crypto",
      }, 
      // {
      //   label : "Binance",
      //   value : "Binance_pay",
      // }, 
      // {
      //   label : "Crypto.com",
      //   value : "Crypto_com_pay",
      // }, 
    ]
  }
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState({label :"Crypto", value :"crypto"});
  const [errors, setErrors] = useState({
    amount: "",
    currency: "",
    method: "",
    network: "",
    
  });
  const [currency, setcurrency] = useState(selectedCurrency);
  const [data, setdata] = useState("");
  const [method, setMethod] = useState({label :"Blockchain", value :"crypto"});
  const [amount, setamount] = useState("");
  const [networks, setNetworks] = useState([]);
  const [network, setNetwork] = useState("");
  const [description, setDescription] = useState("");
  const [finish, setFinish] = useState(false);
  const [resData, setresData] = useState({});
  const {pathname} = useLocation();
  const navigate = useNavigate();
  useEffect(() => {

    return () => {
        navigate(pathname.replace("/create", "").replace("/edit", ""));

    };

  }, []);

  const {
    mutate: mutateStoreTopup,
    isSuccess: isStoreSuccess,
    isLoading: isStoreLoading,
  } = useStoreTopup();

  const handleClose = () => {
    onClose && onClose();
  };

  const handleNewEstimates = () => {
    // if (isLoading) return;
    // fetchPaymentEstimation();
  };

  const onSubmit = () => {
    console.log("submit")
    // check if  network is selected  and method is selected
    if (method === "") {
      setErrors((errors) => ({
        ...errors,
        method: "Please select a method",
      }));
      return;
    }
    else if (network === "") {
      setErrors((errors) => ({
        ...errors,
        network: "Please select a network",
      }));
      return;
    }
    const data ={}
    data.type = type.value;
    data.method = method.value; // TODO
    data.crypto_currency = currency;
    data.network = network.network;
    if (description) data.description = description; 
    console.log(data) 
    
    mutateStoreTopup(data, {
      onSuccess: (data) => {
        console.log(data)
        setresData(data.data)
        setFinish(true)
      },
      onError: (error) => {
        console.log(error)
        toast.error(error.response?.data.error.message);
       
      },
    });
  };

  const sortNetworks = (networks) => {
    const active = networks.filter((network) => network.is_active);
    const inactive = networks.filter((network) => !network.is_active);
    return [...active, ...inactive];
  };

  // const getCryptoName = (crypto) => {
  //   const cryptoCurrency = crypto_currenies.find((obj) => obj.label === crypto);

  //   if (cryptoCurrency) return `${cryptoCurrency.name}${cryptoCurrency.label ? [' (' + cryptoCurrency.label + ')'] : ''}`
  //   else return null;
  // }

  const onFetchBalanceSuccess = (data) => {
    setdata(data)
    const currencies = data.map((data) => {
      return {
        label: data.currency.toUpperCase(),
        value: data.currency.toUpperCase(),
        // name: type.value === "crypto"? getCryptoName(data.currency.toUpperCase()) : null,
        icon: type.value === "crypto"? data.currency.toLowerCase() : currencySymbol(data.currency),

      };
    });
    if (currency === null)
    {
      setcurrency(data[0].currency)
      setNetworks(sortNetworks(data[0].networks))

    }
    else
      setNetworks(sortNetworks(data.find((item) => item.currency === currency).networks))
    setCurrencies(currencies);
  };

  const handleCurrencyChange = (value) => {
    setcurrency(value.value);
    if (type.value === "crypto") {
      setNetworks(sortNetworks(data.find((item) => item.currency === value.value).networks));
    }

  };

  const handleSelectType = (value) => {
    console.log(value.value);
    setType(value);
    setMethod(value.value === "crypto" ? methods?.crypto[0] : methods?.fiat[0]);
  };

  const handleSelectMethod = (value) => {
    if (errors.method) {
      setErrors((errors) => ({
        ...errors,
        method: "",
      }));
    }
    console.log(method)
    setMethod(value)
    // setFiatItem(value);
  };

  const getCurrency = useMemo(() => {
    return currencies.length ? currencies[0].value : null;
  }, [currencies]);

  const { isLoading } = useFetchCurrencies({
    onSuccess: onFetchBalanceSuccess,
    onError: ()=>{},
    params:{
      type: type.value,
      networks : true
    }
    // enabled: refetch,
  } , [type]);

  const handleNetworkSelect = (value) => {
    if (errors.network) {
      setErrors((errors) => ({
        ...errors,
        network: "",
      }));
    }
    setNetwork(value);
  };
  useEffect(() => {


  }, []);
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  return (
  <>
  {
    finish ? <TopupFinish
    onClose={onClose}
    data={resData}
    show={show}
    currencies={currencies}
    networks={networks}

    /> : (
      <>
      <Modal show={show} onClose={handleClose} size='md' footerBorder={true} bodyScrollable={true}>
        <Modal.Slot name='header'>
          <div>
            <div className='app-invoice__modal__title'>

              Topup {type.value === 'crypto' ? 'Crypto' : 'Fiat'}
            </div>
          </div>
        </Modal.Slot>
        <Modal.Slot name='body'>
          <FormStyle className='gap-4'>
            {/* <div>
              <Label 
                title='Type' 
                error={""}
                icon={InfoIcon}
                tooltipHtml={true}
                tooltip={`
                 Choose if you want to topup your Cryptocurrency or Fiat balance
                  `}
                />
              <Select
                  disabled={true}
                  onSelect={handleSelectType}
                  path="assets/images/icons/cryptos"
                  style={{ minWidth: "150px" }}
                  options={types}
                  value={type?.value}
              />
            </div> */}
            <div>
              <Label 
                title='Method' 
                error={errors.method}
                icon={InfoIcon}
                tooltipHtml={true}
                tooltip={`
                 The method you want to use to topup your balance
                `}
              />

              <Select
                  error={errors.method}
                  onSelect={handleSelectMethod}
                  path="assets/images/icons/cryptos"
                  style={{ minWidth: "150px" }}
                  options={type?.value === "crypto" ? methods.crypto : methods.fiat}
                  value={method?.value}
                  placeholder="Select method"
              />
            </div>
            {type?.value === 'crypto' && (
              <TopupCryptoModal
                currencies={currencies}
                networks={networks}
                currency={currency}
                network={network}
                errors={errors}
                method={method}
                handleCurrencyChange={handleCurrencyChange}
                handleNetworkSelect={handleNetworkSelect}
                getIconUrl={getIconUrl}
                isLoading={isLoading}
              />
            )}

            <div>
              <Label title='Description' error={""} subtitle={'(optional)'} />
              <Input  className="w-full " onChange={(e)=>setDescription(e.target.value)} placeholder='Description..' value={description} />  
            </div>
          </FormStyle>
        </Modal.Slot>
        <Modal.Slot name='footer'>
          <div className='flex justify-end'>
          <Button  onClick={(e)=>{
                onSubmit()
            }  } type='  '>
              Confirm
            </Button>
          </div>
        </Modal.Slot>
      </Modal>
      </>
    )
    
  }
  </>
  )
}

const FormStyle = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 10px; */
    >div{
        width: 100%;

            >.networks{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .active{
                border: 2px solid #47CE7D;
              }
              >label{
                padding:  0 10px;
                flex-basis : 49%;
                margin-bottom:10px ;
                height: 99px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #DCDEE6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                input
                {
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: none;
                  border: 0;
                  box-shadow: inset 0 0 0 3px #DCDEE6;
                  box-shadow: inset 0 0 0 3.5px #DCDEE6;
                  appearance: none;
                  padding: 0;
                  margin: 0;
                  transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
                  pointer-events: none;
                  margin: 2px;
                  &:focus
                  {

                    outline: none;
                  }
                  
                  &:checked
                  {

                    box-shadow: inset 0 0 0 6px #47CE7D;
                  }

                }
                >.first{
                  height: 100%;
                  justify-content: space-around;
                  display: flex;
                  align-items: flex-start;
                  flex-direction :column;
                  >img{

                    max-width: 100px;
                  }
                }

              }
            }
        
    }
    `;

    