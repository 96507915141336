import Label from "../../../../../../../Common/Form/Label/Label";
import clsx from "clsx";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setFormSchemaDiscount} from "../../../../../../../../store/discount/discountSlice";

function DiscountsModalCreateBehavior({ behavior, setValue }) {
  const dispatch = useDispatch();
  const { form_schema_discount, selected_discount } = useSelector((state) => state.discount);

  const handleBehaviorChange = (behavior) => {
    if (selected_discount?.id) return;

    setValue("behavior", behavior);
    dispatch(setFormSchemaDiscount({
      ...form_schema_discount,
      behavior
    }));

    if (behavior === "amount_off") {
      setValue("percent_off", "");
    }

    if (behavior === "percent_off") {
      setValue("amount_off", "");
    }
  }

  return (
    <div>
      <Label
        title="Behavior"
      />
      <div className="flex gap-3">
        <div
          onClick={() => handleBehaviorChange("amount_off")}
          className={clsx(
            "duration-300 border rounded-lg w-full shadow text-center text-light px-2 py-1",
            behavior === "amount_off" && "border-primary text-primary_darken",
            selected_discount?.id ? "cursor-default" : "cursor-pointer"
          )}
        >
          <span>Amount Off</span>
        </div>
        <div
          onClick={() => handleBehaviorChange("percent_off")}
          className={clsx(
            "duration-300 border rounded-lg w-full shadow text-center text-light px-2 py-1",
            behavior === "percent_off" && "border-primary text-primary_darken",
            selected_discount?.id ? "cursor-default" : "cursor-pointer"
          )}
        >
          <span>Percent Off</span>
        </div>
      </div>
    </div>
  )
}

export default DiscountsModalCreateBehavior;