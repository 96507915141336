import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  capitalize,
  cryptocurrencyFormatter,
  currencyFormatter,
  currencySymbol,
} from '../../../../../../utils/string';
import ReactDOMServer from 'react-dom/server';
import DashboardButton from '../../../../../Common/Form/DashboardButton/DashboardButton';
import InfoIcon from '../../../../../../assets/images/icons/info.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/images/icons/dashboard/edit.svg';

const Details = () => {
  const [showModal, setShowModal] = useState(false);
  const { selected_payment } = useSelector(
    (state) => state.payment
  );

  const balance_transaction = typeof selected_payment?.balance_transaction === 'object' ? selected_payment?.balance_transaction : null;

  const handleEditClick = () => {
    setShowModal(true);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };
  return (
    <div className='space-y-1.5 pb-2'>
      <div className='flex justify-between'>
        <div className='app-modal-fullscreen__title'>
          Details
        </div>
        <div className='flex gap-4'>
          <DashboardButton
            outline
            color='light'
            size='sm'
            icon={<EditIcon />}
            onClick={handleEditClick}>
            Edit
          </DashboardButton>
        </div>
      </div>
      <div className='horizontal-divider' />
      <div className='pt-4'>
        <div className='grid grid-cols-5 gap-4'>
          <div className='col-span-2 space-y-2'>
            {selected_payment.on_behalf_of && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  On behalf of
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payment.on_behalf_of}
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Currency
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.currency?.toUpperCase()}
              </div>
            </div>
            {selected_payment.crypto_currency && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Cryptocurrency
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payment.crypto_currency?.toUpperCase()}
                </div>
              </div>
            )}
            {[
              'under_paid',
              'over_paid',
              'succeeded',
            ].includes(selected_payment.status) &&
              !selected_payment.captured && (
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Captureable Amount
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    <div className='flex items-start gap-2'>
                      <div>
                        <div className='app-modal-fullscreen__table__amount'>
                          <div className='text-light'>
                            {currencySymbol(
                              selected_payment.currency
                            )}
                            {currencyFormatter(
                              selected_payment.currency,
                              selected_payment.capturable_amount
                            )}{' '}
                            <span>
                              {selected_payment.currency?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            selected_payment.capturable_crypto_amount
                          )}{' '}
                          {selected_payment.crypto_currency}
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Amount
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                <div className='flex items-start gap-2'>
                  <div>
                    <div className='app-modal-fullscreen__table__amount'>
                      <div className='text-light'>
                        {currencySymbol(
                          selected_payment.currency
                        )}
                        {currencyFormatter(
                          selected_payment.currency,
                          selected_payment.amount
                        )}{' '}
                        <span>
                          {selected_payment.currency?.toUpperCase()}
                        </span>
                      </div>
                    </div>
                    {selected_payment.crypto_amount && (
                      <div className='app-modal-fullscreen__value'>
                        (
                        {cryptocurrencyFormatter(
                          selected_payment.crypto_amount
                        )}{' '}
                        {selected_payment.crypto_currency})
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selected_payment.amount !==
              selected_payment.amount_received && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Amount Received
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {currencySymbol(
                            selected_payment.currency
                          )}
                          {currencyFormatter(
                            selected_payment.currency,
                            selected_payment.amount_received
                          )}{' '}
                          <span>
                            {selected_payment.currency?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      <div className='app-modal-fullscreen__value'>
                        (
                        {cryptocurrencyFormatter(
                          selected_payment.crypto_amount_received
                        )}{' '}
                        {selected_payment.crypto_currency})
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selected_payment.amount_overpaid > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Over Paid Amount
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {currencySymbol(
                            selected_payment.currency
                          )}
                          {currencyFormatter(
                            selected_payment.currency,
                            selected_payment.amount_overpaid
                          )}{' '}
                          <span>
                            {selected_payment.currency?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      {selected_payment.crypto_amount_overpaid && (
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            selected_payment.crypto_amount_overpaid
                          )}{' '}
                          {selected_payment.crypto_currency}
                          )
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selected_payment.amount_underpaid > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Under Paid Amount
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {currencySymbol(
                            selected_payment.currency
                          )}
                          {currencyFormatter(
                            selected_payment.currency,
                            selected_payment.amount_underpaid
                          )}{' '}
                          <span>
                            {selected_payment.currency?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      {selected_payment.crypto_amount_underpaid && (
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            selected_payment.crypto_amount_underpaid
                          )}{' '}
                          {selected_payment.crypto_currency}
                          )
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selected_payment.crypto_padding_amount > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Crypto Padding Amount
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <img
                      className='w-4 mt-0.5'
                      src={getIconUrl(
                        selected_payment.crypto_currency.toLowerCase()
                      )}
                      alt={selected_payment.crypto_currency}
                    />
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {cryptocurrencyFormatter(
                            selected_payment.crypto_padding_amount
                          )}
                          <span>
                            {
                              selected_payment.crypto_currency
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selected_payment.balance_transaction && (
              <>
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Fee
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    <div className='flex items-start gap-2'>
                      <div>
                        <div className='app-modal-fullscreen__table__amount'>
                          <div className='text-light'>
                            {currencySymbol(
                              balance_transaction?.currency
                            )}
                            {currencyFormatter(
                              balance_transaction?.currency,
                              balance_transaction?.fee
                            )}
                            <span>
                              {balance_transaction?.currency?.toUpperCase()}
                            </span>
                          </div>
                          {console.log('Balance Transaction', balance_transaction)}
                          {balance_transaction?.fee_details?.length > 0 && (
                            <img
                              className='ml-2'
                              data-for='fees-tooltip'
                              data-tip={ReactDOMServer.renderToString(
                                <>
                                  {balance_transaction.fee_details.map(
                                    (fee, index) => (
                                      <div
                                        key={index}
                                        className='app-modal-fullscreen__table__amount w-full'>
                                        <div className='inline-flex flex-wrap gap-3'>
                                          <span>
                                            {
                                              fee.description
                                            }
                                            :
                                          </span>
                                          <div className='text-right'>
                                            <div className='font-semibold'>
                                              {currencySymbol(
                                                fee?.currency
                                              )}
                                              {currencyFormatter(
                                                fee?.currency,
                                                fee?.amount
                                              )}
                                              <span>
                                                {fee?.currency?.toUpperCase()}
                                              </span>
                                            </div>
                                            {fee.crypto_amount && (
                                              <div className='font-normal'>
                                                (
                                                {cryptocurrencyFormatter(
                                                  fee.crypto_amount
                                                )}
                                                <span>
                                                  {
                                                    fee.crypto_currency
                                                  }
                                                </span>
                                                )
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                              data-html={true}
                              src={InfoIcon}
                              alt='Info'
                            />
                          )}
                        </div>
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            balance_transaction.crypto_fee
                          )}{' '}
                          {
                            balance_transaction.crypto_currency
                          }
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Net
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    <div className='flex items-start gap-2'>
                      <div>
                        <div className='app-modal-fullscreen__table__amount'>
                          <div className='text-light'>
                            {currencySymbol(
                              balance_transaction.currency
                            )}
                            {currencyFormatter(
                              balance_transaction.currency,
                              balance_transaction.net
                            )}{' '}
                            <span>
                              {selected_payment.currency?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        {balance_transaction.crypto_net && (
                          <div className='app-modal-fullscreen__value'>
                            (
                            {cryptocurrencyFormatter(
                              balance_transaction.crypto_net
                            )}{' '}
                            {
                              balance_transaction.crypto_currency
                            }
                            )
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selected_payment.failure_message && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Failure Message
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payment.failure_message}
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Description
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.description ?? '-'}
              </div>
            </div>
            {selected_payment.exchange_rate > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Exchange Rate
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-center gap-2'>
                    <div className='font-semibold'>1</div>
                    <div className='flex gap-1'>
                      <img
                        className='w-4 mt-0.5'
                        src={getIconUrl(
                          selected_payment.crypto_currency.toLowerCase()
                        )}
                        alt={
                          selected_payment.crypto_currency
                        }
                      />
                      <div className='app-modal-fullscreen__value'>
                        {selected_payment.crypto_currency}
                      </div>
                    </div>
                    =
                    <div className='font-semibold'>
                      {currencySymbol(
                        selected_payment.currency
                      )}
                      {selected_payment.exchange_rate}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='col-span-2 space-y-2'>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Receipt Email
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.receipt_email ?? '-'}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Capture Method
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.capture_method
                  ? capitalize(
                      selected_payment.capture_method
                    )
                  : '-'}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Captured
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.captured ? 'Yes' : 'No'}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Auto Convert
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.auto_convert ? (
                  <div className='flex gap-2'>
                    <img
                      className='w-4 mt-0.5'
                      src={getIconUrl(
                        selected_payment.crypto_currency.toLowerCase()
                      )}
                      alt={selected_payment.crypto_currency}
                    />
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {cryptocurrencyFormatter(
                            selected_payment.auto_convert_currency
                          )}
                          <span>
                            {
                              selected_payment.crypto_currency
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  'No'
                )}
              </div>
            </div>
            {balance_transaction?.swap && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Swap
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {balance_transaction.swap}
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Payment Forwarding
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment.payment_forwarding
                  ? 'Yes'
                  : 'No'}
              </div>
            </div>
            {balance_transaction?.payout && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Payout
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {balance_transaction.payout}
                </div>
              </div>
            )}
            {selected_payment?.customer && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>Customer</div>
                <div className='app-modal-fullscreen__kv__value'>
                  <Link
                    to={`/dashboard/customers/${selected_payment.customer?.id}`}
                    className='text-tertiary'>
                    {selected_payment.customer?.id}
                  </Link>
                </div>
              </div>
            )}
            {selected_payment.type !== 'payment' && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>{capitalize(selected_payment.type)}</div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_payment.type === 'invoice' ? (
                    <Link
                      to={`/dashboard/invoices/${selected_payment.source}`}
                      className='text-tertiary'>
                      {selected_payment.source}
                    </Link>
                  ) : (
                    <>{selected_payment.source}</>
                  )}
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Balance Transaction
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {balance_transaction?.id ? (
                  <Link
                    to={`/dashboard/balance_transactions/${balance_transaction.id}`}
                    className='text-tertiary'>
                    {balance_transaction.id}
                  </Link>
                ) : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showModal && (
        <PaymentsModalUpdate
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )} */}
    </div>
  );
};

export default Details;
