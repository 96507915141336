import "./SectionSubscriptions.scss";
import {ReactComponent as SubscriptionsIcon} from "../../../../assets/images/icons/subscriptions.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/subscriptions/illustration.svg";
import {ReactComponent as CustomisableIcon} from "../../../../assets/images/sections/subscriptions/customisable.svg";
import Stack from "../../../Common/Stack/Stack";
import {Link} from "react-router-dom";

function SectionSubscriptions({ scrollToContact }) {
  return (
    <div id="subscription" className="section-subscriptions">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <SubscriptionsIcon className="section-products__section__subtitle__icon" />
              Automated Subscriptions
            </h3>
            <h1 className="h1 section-products__section__title">
              Subscribe your Customers to recurring payments.
            </h1>
            <p className="text section-products__section__text">
              Create and manage automated recurring subscriptions for your products or services.
              Improve your conversions with the best Cryptocurrency payment system.
            </p>
            <div className="section-subscriptions__buttons">
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
                  Start Now
                </Button>
              </Link>
              <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
            </div>
            <h5 className="h5 section-products__section__info section-subscriptions__customisable">
              Manageable and Customisable
            </h5>
            <div className="section-subscriptions__customisable__content">
              <div className="section-subscriptions__customisable__content__icon">
                <CustomisableIcon />
              </div>
              <p className="text-small section-subscriptions__customisable__content__text">
                Create and update customer subscriptions, making their invoicing smooth and hassle free.
                Send payment reminders and allow your customers to a free trial before they commit.
                Easily adjust or change your Customers subscriptions, and even create add-ons to fulfil their every needs.
              </p>
            </div>
          </div>
          <div className="section-subscriptions__illustration">
            <Illustration />
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default SectionSubscriptions;
