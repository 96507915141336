import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchCurrencies = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/currencies", { params })
    : api.sandbox.get("/currencies", { params });

export const useFetchCurrencies = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchCurrencies", params], () => fetchCurrencies(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

// const fetchTopup = (id) =>
//   appStore.getState().app.livemode
//     ? api.live.get(`/topups/${id}`, {
//         params: {
//           expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
//         },
//       })
//     : api.sandbox.get(`/topups/${id}`, {
//         params: {
//           expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
//         },
//       });

// export const useFetchTopup = ({ onSuccess, onError, enabled, id }) => {
//   return useQuery(["fetchTopup", id], () => fetchTopup(id), {
//     onSuccess,
//     onError,
//     select: (data) => data.data,
//     enabled,
//     retry: false,
//     refetchOnWindowFocus: false,
//   });
// };

// const storeTopup = (data) =>
//   appStore.getState().app.livemode
//     ? api.live.post("/topups", data)
//     : api.sandbox.post("/topups", data);

// export const useStoreTopup = () => {
//   return useMutation("storeTopup", storeTopup);
// };

// const updateTopup = (id, data) =>
//   appStore.getState().app.livemode
//     ? api.live.patch(`/topups/${id}`, data)
//     : api.sandbox.patch(`/topups/${id}`, data);

// export const useUpdateTopup = (id) => {
//   return useMutation(["updateTopup", id], (data) =>
//     updateTopup(id, data)
//   );
// };