import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import DashboardNavbar from "layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import TransactionTable from "./TransactionTable";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as ExportIcon } from "assets/images/icons/export.svg";
import useTableFilters from "hooks/useTableFilters";
import DropdownFilters from "components/Common/DropdownFilters/DropdownFilters";
import TransactionDetails from "./TransactionDetails";
import { setSelectedTransaction, setRefetchTransaction } from "store/transactions/transactionsSlice";
import { useFetchBalanceTransaction } from "api/wallet/balance_transactions";

const TABLE_FILTERS = [
  { label: "All", column: "crypto", value: "" },
  { label: "Crypto", column: "crypto", value: true },
  { label: "Fiat", column: "crypto", value: false},
];

function BalanceTransactions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected_transaction, refetch_transaction } = useSelector((state) => state.transaction);
  const [showDetailModal, setShowDetailsModal] = useState(false);
  const [refreshTransaction, setRefreshTransaction] = useState(false);
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  var currency =window.location.search?.split("=")[1] ;
  const [filters, setFilters] = useState({
    crypto_currency: currency,
  });
  const { id } = useParams();
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });

  useFetchBalanceTransaction({
    onSuccess: (data) => {
      dispatch(setSelectedTransaction(data));
      setRefreshTransaction(false);
    },
    onError: (error) => {
      setRefreshTransaction(false);
      navigate(DASHBOARD_PATH + "/balance_transactions");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: !!id,
  });
  // Details modal:
  const handleCloseDetailModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedTransaction(null));
    navigate(DASHBOARD_PATH + "/balance_transactions");
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_transaction);
  }, [selected_transaction])

  useEffect(() => {
    if (!id) {
      handleCloseDetailModal();
      return;
    }

  }, [refetch_transaction, id, handleCloseDetailModal]);
  // Closes modal
  useEffect(() => {
    return () => {
      dispatch(setSelectedTransaction(null));
    };
  }, [dispatch]);


  const handleFiltersChange = (filters) => {
    setFilters(filters);
    console.log(filters);
    setFilter({ column: "status", value: filters?.status ?? "" });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
    );
  }, [activeFilter]);

  const getFilters = useMemo(() => {
    return {
      ...filters,
      ...(activeFilter.status ? activeFilter : null),
    };
  }, [filters, activeFilter]);

//   const handleFilterClick = (filter) => {
//     if (filter && filter.label === "All") {
//       setFilters((state) => {
//         const { status, ...rest } = state;

//         return rest;
//       });
//     }

//     setFilter(filter);
//   };

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Balance Transactions</div>
              <span className="app-dashboard__card__header__filters">
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
            <DropdownFilters
                filters={[
                  "currency_type",
                  "currency",
                  "crypto_currency",
                  "amount", 
                  "crypto_amount",
                  'reason',
                  "date",
                  // "reporting_amount"
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                
                page="transactions"
              />
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
              </div>
          </div>
          <TransactionTable filters={getFilters} />
        </div>
        {showDetailModal && (
          <TransactionDetails
            show={showDetailModal}
            onClose={handleCloseDetailModal}
          />
        )}
        {/* {showCreateModal && (
          <InvoicesModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )} */}
        <ReactTooltip
          id="invoice-modal-create"
          place="bottom"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
        </ReactTooltip>
      </div>
    </div>
  );
}

export default BalanceTransactions;
