import Checkbox from "../../../Form/Checkbox/Checkbox";
import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import DropdownFiltersFormSelect from "../../DropdownFiltersForm/DropdownFiltersFormSelect";
import DropdownFiltersFormInput from "../../DropdownFiltersForm/DropdownFiltersFormInput";
import { useDispatch } from "react-redux";
import { setFilters } from "../../../../../store/app/appSlice";
import { FIAT_CURRENCIES_SELECT } from "../../../../../utils/constants";
import { currencySymbol } from "../../../../../utils/string";

const AMOUNT_OPTIONS = [
  { label: "is equal to", value: "is_equal_to" },
  { label: "is between", value: "is_between" },
  { label: "is greater than", value: "is_greater_than" },
  { label: "is less than", value: "is_less_than" },
];

function DropdownFiltersAmount({
  form: {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
  },
  onToggle,
  state = false,
  placeholder = "Amount",
  filter_field = "amount",
}) {
  const dispatch = useDispatch();

  const currencyValue = watch("currency");
  const amount_option = watch(`${filter_field}_option`);

  useEffect(() => {
    dispatch(setFilters({ [`${filter_field}_option`]: "is_equal_to" }));
    setValue(`${filter_field}_option`, "is_equal_to");
  }, [dispatch, filter_field, setValue]);

  const handleOpen = () => {
    onToggle && onToggle(!state);
  };

  const getCurrencySymbol = useMemo(() => {
    const symbol = currencySymbol(currencyValue);
    return !symbol || symbol === "" ? currencyValue?.toUpperCase() : symbol;
  }, [currencyValue]);

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
        />
        <span className="text-small">{placeholder}</span>
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <DropdownFiltersFormSelect
              options={AMOUNT_OPTIONS}
              onChange={(value) => {
                setValue(`${filter_field}_option`, value);
                trigger(`${filter_field}_option`);
                dispatch(setFilters({ [`${filter_field}_option`]: value }));
              }}
              value={amount_option}
            />
            <div className="flex items-start gap-2 mt-2">
              {currencyValue && (
                <span className="mt-1">{getCurrencySymbol}</span>
              )}
              {amount_option === "is_equal_to" && (
                <div className="w-full">
                  <DropdownFiltersFormInput
                    type="number"
                    min="0"
                    {...register(`${filter_field}`)}
                    error={errors?.[`${filter_field}`]?.message}
                  />
                </div>
              )}
              {amount_option === "is_between" && (
                <>
                  <div className="w-full">
                    <DropdownFiltersFormInput
                      type="number"
                      min="0"
                      placeholder="MIN"
                      {...register(`${filter_field}_gte`)}
                      error={errors?.[`${filter_field}_gte`]?.message}
                    />
                  </div>
                  <div className="w-full">
                    <DropdownFiltersFormInput
                      type="number"
                      min="0"
                      placeholder="MAX"
                      {...register(`${filter_field}_lte`)}
                      error={errors?.[`${filter_field}_lte`]?.message}
                    />
                  </div>
                </>
              )}
              {amount_option === "is_greater_than" && (
                <div className="w-full">
                  <DropdownFiltersFormInput
                    type="number"
                    min="0"
                    {...register(`${filter_field}_gt`)}
                    error={errors?.[`${filter_field}_gt`]?.message}
                  />
                </div>
              )}
              {amount_option === "is_less_than" && (
                <div className="w-full">
                  <DropdownFiltersFormInput
                    type="number"
                    min="0"
                    {...register(`${filter_field}_lt`)}
                    error={errors?.[`${filter_field}_lt`]?.message}
                  />
                </div>
              )}
            </div>
            {amount_option && (
              <>
                <div className="flex items-center gap-3 mt-2">
                  <span>Currency:</span>
                  <DropdownFiltersFormSelect
                    onChange={(value) => {
                      setValue("currency", value);
                      trigger("currency");
                    }}
                    options={FIAT_CURRENCIES_SELECT}
                    error={errors?.["currency"]?.message}
                    value={currencyValue}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersAmount;
