import React, { useEffect } from 'react';
import Label from 'components/Common/Form/Label/Label';
import Select from 'components/Common/Form/Select/Select';
import InfoIconImage from '../../../../../../../../assets/images/icons/info.svg';

const TaxBehaviour = ({
  onSelectOption,
  tax_behaviour,
	disabled
}) => {
  return (
    <>
      <div>
        <Label
          title='Tax Behaviour'
          icon={InfoIconImage}
          tooltipHtml={true}
          tooltip={`
        Define if the price is inclusive or exclusive of tax. 
        If <code style="background:#d3d8e0;font:normal;border-radius:3px">&nbsp;Unspecified&nbsp;</code> is defined, the price’s
        tax behaviour will depend on the tax rate applied to it.
          `}
        />
        <Select
          onSelect={(e) => onSelectOption(e)}
          options={[
            {
              label: 'Unspecified',
              value: 'unspecified',
            },
            {
              label: 'Includes tax',
              value: 'inclusive',
            },
            {
              label: 'Excludes tax',
              value: 'exclusive',
            },
          ]}
          value={tax_behaviour}
          placeholder={tax_behaviour ? tax_behaviour : 'Unspecified'}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default TaxBehaviour;
