import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    navbar: false,
    livemode: false,
    filters: {
      created_at_option: null,
      due_date_option: null,
    }
  },
  reducers: {
    toggleNavbar: (state) => {
      state.navbar = !state.navbar
    },
    setLiveMode: (state, payload) => {
      state.livemode = payload.payload
    },
    setFilters: (state, payload) => {
      state.filters = {
        ...state.filters,
        ...payload.payload
      }
    }
  },
})

export const { toggleNavbar, setLiveMode, setFilters } = appSlice.actions

export default appSlice.reducer
