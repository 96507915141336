import './DashboardTable.scss';
import {
  useTable,
  usePagination,
  useFilters,
  useFlexLayout,
  useRowSelect,
} from 'react-table';
import clsx from 'clsx';
import { forwardRef, useEffect, useRef } from 'react';
import Checkbox from '../../Common/Form/Checkbox/Checkbox';
import { DASHBOARD_TABLE_LIMIT } from '../../../utils/constants';
  
const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          color='primary'
          size='sm'
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const headerProps = (props, { column }) =>
  getStyles(props, column.align);

const cellProps = (props, { cell }) =>
  getStyles(props, cell.column.align);

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent:
        align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const defaultPropGetter = () => ({});

function DashboardTable({
  clickable = false,
  selectable = false,
  columns = [],
  data = [],
  filter_value = '',
  filter_column = '',
  state = {},
  pageCount: controlledPageCount,
  onPreviousPage = null,
  onNextPage = null,
  canPreviousPage = false,
  canNextPage = false,
  loading = false,
  count = 0,
  noRecordTag = null,
  paginated = true,
  ...rest
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    nextPage,
    previousPage,
    getColumnProps = defaultPropGetter,
    getCellProps = defaultPropGetter,

    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        ...state,
        pageSize: DASHBOARD_TABLE_LIMIT,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    usePagination,
    useFlexLayout,
    selectable && useRowSelect,
    (hooks) => {
      selectable &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            width: 10,
            Header: ({
              getToggleAllPageRowsSelectedProps,
            }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const withIcon = (cell) => cell.column.id === 'icon';

  const handleRowClick = (row, cell) => {
    if (['selection', 'icon'].includes(cell.column.id)) {
      return;
    }

    clickable && rest.onRowClick && rest.onRowClick(row);
  };

  useEffect(() => {


    if (
      !columns.find(
        (column) =>
          column?.accessor === filter_column ||
          column?.id === filter_column
      )
    )
      return;

    if (filter_column) {
      setFilter(filter_column, filter_value || undefined);
    }
  }, [setFilter, filter_column, filter_value, columns]);

  return (
    <>
      <div
        className={clsx(
          'app-table',
          loading && 'app-overlay'
        )}>
        <div className='app-table__wrapper'>
          <table
            {...getTableProps()}
            className='app-table__content'>
            <thead className='app-table__header'>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className='app-table__tr'>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        headerProps
                      )}
                      scope='col'
                      className='app-table__header__th'>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className='app-table__body'>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={clsx(
                      'app-table__tr',
                      clickable &&
                        'app-table__tr--clickable'
                    )}>
                    {row.cells.map((cell, index) => {
                      const propsCell = () =>
                        cell.getCellProps(cellProps);
                      return (
                        <td
                          onClick={() =>
                            handleRowClick(
                              row.original,
                              cell
                            )
                          }
                          {...propsCell([
                            {
                              className:
                                cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          className={clsx(
                            'app-table__body__td',
                            index !== 0 &&
                              'app-table__body__td__value',
                            withIcon(cell) &&
                              'app-table__body__td__icon'
                          )}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {!data.length && (
                <tr>
                  <td className='text-light text-sm text-center py-3'>
                    {`No ${noRecordTag ?? 'Records'} found`}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {paginated && <div className='app-table__pagination'>
          {/*{data.length}*/}
          <div className='app-table__pagination__result'>
            {count} Results
          </div>
          <div className='app-table__pagination__numbers'>
            {/*{pageOptions.map((page) =>
                <span
                  key={page}
                  onClick={() => gotoPage(page)}
                  className={clsx(
                    "app-table__pagination__page",
                    page === pageIndex && "app-table__pagination__page--current"
                  )}
                >
              {page + 1}
            </span>
            )}*/}
            {/*<span className="app-table__pagination__page app-table__pagination__page--more">...</span>*/}
          </div>
          <div className='app-table__pagination__buttons'>
            <button
              onClick={() => {
                onPreviousPage();
                previousPage();
              }}
              disabled={!canPreviousPage}
              className='app-table__pagination__button'>
              Previous
            </button>
            <button
              onClick={() => {
                onNextPage();
                nextPage();
              }}
              disabled={!canNextPage}
              className='app-table__pagination__button'>
              Next
            </button>
          </div>
        </div>}
      </div>
    </>
  );
}

export default DashboardTable;
