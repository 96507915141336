import { createSlice } from '@reduxjs/toolkit';

export const paymentLinkSlice = createSlice({
  name: 'paymentLink',
  initialState: {
    selected_payment_link: null,
    new_payment_link: null,
    refetch_payment_link: null,
    checkout_branding: null,
    delete_payment_link: null,
    selected_payment_link_details: null,
    edit_payment_link: null,
    payment_link_accepted_cryptos: [],
    form_schema_payment_link: {
      linkType: 'payment',
      type: 'standard',
    },
  },
  reducers: {
    setSelectedPaymentLink: (state, payload) => {
      console.log(
        ' setSelectedPaymentLink from reducer',
        payload
      );
      state.selected_payment_link = payload.payload;
    },
    setRefetchPaymentLink: (state, payload) => {
      state.refetch_payment_link = payload.payload;
    },

    setCheckoutBranding: (state, payload) => {
      console.log('checkout brand from reducer', payload);
      state.checkout_branding = payload.payload;
    },

    setDeletePaymentLink: (state, payload) => {
      state.delete_payment_link = payload.payload;
    },
    setSelectedPaymentLinkDetails: (state, payload) => {
      state.selected_payment_link_details = payload.payload;
    },
    setEditPaymentLink: (state, payload) => {
      state.edit_payment_link = payload.payload;
    },
    setNewPaymentLink: (state, payload) => {
      state.edit_payment_link = payload.payload;
    },
    setPaymentLinkAcceptedCryptos: (state, payload) => {
      state.payment_link_accepted_cryptos = payload.payload;
    },
    setFormSchemaPaymentLink: (state, payload) => {
      state.form_schema_payment_link = payload.payload;
    },
    resetFormSchemaPaymentLink: (state) => {
      state.form_schema_payment_link = {
        linkType: 'payment',
        type: 'standard',
      };
    },
  },
});

export const {
  setSelectedPaymentLink,
  setRefetchPaymentLink,
  setCheckoutBranding,
  setDeletePaymentLink,
  setSelectedPaymentLinkDetails,
  setEditPaymentLink,
  setPaymentLinkAcceptedCryptos,
  setNewPaymentLink,
  setFormSchemaPaymentLink,
  resetFormSchemaPaymentLink,
} = paymentLinkSlice.actions;

export default paymentLinkSlice.reducer;
