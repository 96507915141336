import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import MainLayout from 'layouts/Main/Main';
import MainHome from 'pages/Main/Home/Home';
import Products from 'pages/Main/Products/Products';
import MainWithoutFaqLayout from 'layouts/Main/MainWithoutFaq';
import Faq from 'pages/Main/Faq/Faq';
import DashboardLayout from 'layouts/Dashboard/Dashboard';
import DashboardHome from 'pages/Dashboard/Home/Home';
import DashboardDevelopersAPIKeys from 'pages/Dashboard/Developers/APIKeys/APIKeys';
import DashboardDevelopersWebhooks from 'pages/Dashboard/Developers/Webhooks/Webhooks';
import AuthLayout from 'layouts/Auth/Auth';
import Login from 'pages/Auth/Login/Login';
import Register from 'pages/Auth/Register/Register';
import ForgotPassword from 'pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword';
import Verify from 'pages/Auth/Verify/Verify';
import Resend from 'pages/Auth/Resend/Resend';
import { useSelector } from 'react-redux';
import DashboardCustomers from 'pages/Dashboard/Customers/Customers';
import DashboardCustomersInvoices from 'pages/Dashboard/Billing/Invoices/Invoices';
import DashboardPayments from 'pages/Dashboard/Payments/Payments';
import DashboardPaymentsLinks from 'pages/Dashboard/Payments/PaymentLinks/PaymentLinks';
// import PaymentLinksModalDetails from 'components/Pages/Dashboard/Payments/PaymentLinksModal/PaymentLinksModalDetails';
import DashboardPaymentsRefunds from 'pages/Dashboard/Payments/Refunds/Refunds';
// import RefundsModalDetails from 'components/Pages/Dashboard/Payments/RefundsModals/RefundsModalDetails';
import DashboardProducts from 'pages/Dashboard/Products/Products';
import DashboardProductsDiscounts from 'pages/Dashboard/Products/Discounts/Discounts';
import DashboardProductsTaxRates from 'pages/Dashboard/Products/TaxRates/TaxRates';
import DashboardShippingRates from 'pages/Dashboard/Products/ShippingRates/ShippingRates';
import Error from 'pages/Error/Error';
import Balance from "pages/Dashboard/Balance/Balance"
import DashboardPrices from 'pages/Dashboard/Prices/Prices';
import BalanceTransactions from 'pages/Dashboard/Balance/BalanceTransactions/BalanceTransactions';
import TopUps from 'pages/Dashboard/Balance/TopUps/Topups';
import Payouts from 'pages/Dashboard/Balance/Payouts/Payouts';
import Transfers from 'pages/Dashboard/Balance/Transfers/Transfer';
import Swaps from 'pages/Dashboard/Balance/Swaps/Swaps';
import Events from 'pages/Dashboard/Developers/Events/Events';
import UserProfile from 'components/Pages/profile';

function RequireAuth({ children }) {
  const { authenticated, accounts } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();

  if (!authenticated && !accounts.length) {
    return (
      <Navigate to='/login' state={{ from: location }} />
    );
  }

  return children;
}

function RequireGuest({ children }) {
  const { authenticated, accounts } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();

  if (authenticated && accounts.length) {
    return <Navigate to='/' state={{ from: location }} />;
  }

  return children;
}

function AppRoutes({ contactRef }) {
  return (
    <Routes>
      <Route
        path='/'
        element={<MainLayout contactRef={contactRef} />}>
        <Route index element={<MainHome />} />
        <Route
          path='products'
          element={<Products contactRef={contactRef} />}
        />
      </Route>

      <Route path='/' element={<MainWithoutFaqLayout />}>
        <Route path='faq' element={<Faq />} />
      </Route>
      <Route
        path='/'
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }>
        <Route path='profile'>
          <Route index element={<UserProfile />} />
        </Route>
      </Route>
      <Route
        path='/dashboard/test'
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }>
        <Route index element={<DashboardHome />} />

        <Route path='api_keys'>
          <Route
            index
            element={<DashboardDevelopersAPIKeys />}
          />
        </Route>
        <Route path='balance'>
          <Route
            index
            element={<Balance />}
          />
        </Route>
        <Route path='balance_transactions'>
          <Route
            index
            element={<BalanceTransactions />}
          />
          <Route
            path=':id'
            element={<BalanceTransactions />}
          />
          <Route
            path='?currency=:id'
            element={<BalanceTransactions />}
          />

        </Route>
        <Route path='topups'>
          <Route
            index
            element={<TopUps />}
          />
          <Route
            path=':id'
            element={<TopUps />}
          />

        </Route>
        <Route path='swaps'>
          <Route
            index
            element={<Swaps />}
          />
          <Route
            path=':id'
            element={<Swaps />}
          />

        </Route>
        <Route path='payouts'>
          <Route
            index
            element={<Payouts />}
          />
          <Route
            path=':id'
            element={<Payouts />}
          />

        </Route>
        <Route path='transfers'>
          <Route
            index
            element={<Transfers />}
          />
          <Route
            path=':id'
            element={<Transfers />}
          />

        </Route>
        <Route path='webhooks'>
          <Route
            index
            element={<DashboardDevelopersWebhooks />}
          />
          <Route
            path=':id'
            element={<DashboardDevelopersWebhooks />}
          />
        </Route>
        <Route path='events'>
          <Route
            index
            element={<Events />}
          />
          <Route
            path=':id'
            element={<Events />}
          />
        </Route>
        <Route path='tax_rates'>
          <Route
            index
            element={<DashboardProductsTaxRates />}
          />
          <Route
            path=':id'
            element={<DashboardProductsTaxRates />}
          />
        </Route>
        <Route path='customers'>
          <Route index element={<DashboardCustomers />} />
          <Route
            path=':id'
            element={<DashboardCustomers />}
          />
        </Route>
        <Route path='invoices'>
          <Route
            index
            element={<DashboardCustomersInvoices />}
          />
          <Route
            path=':id'
            element={<DashboardCustomersInvoices />}
          />
          <Route
            path=':id/edit'
            element={<DashboardCustomersInvoices />}
          />
        </Route>
        <Route path='payments'>
          <Route index element={<DashboardPayments />} />
          <Route
            path=':id'
            element={<DashboardPayments />}
          />
          <Route
            path=':id/refund'
            element={<DashboardPayments />}
          />
        </Route>
        <Route path='payment_link'>
          <Route
            index
            element={<DashboardPaymentsLinks />}
          />
          <Route
            path=':id'
            element={<DashboardPaymentsLinks />}
          />
        </Route>
        <Route path='refunds'>
          <Route
            index
            element={<DashboardPaymentsRefunds />}
          />
          <Route
            path=':id'
            element={<DashboardPaymentsRefunds />}
          />
        </Route>
        <Route path='products'>
          <Route index element={<DashboardProducts />} />
          <Route
            path=':id'
            element={<DashboardProducts />}
          />
          <Route
            path=':id/edit'
            element={<DashboardProducts />}
          />
          <Route
            path=':id/create_price'
            element={<DashboardProducts />}
          />
          <Route
            path=':id/edit_price'
            element={<DashboardProducts />}
          />
        </Route>
        <Route path='prices'>
          <Route
            path=':id/edit'
            element={<DashboardPrices />}
          />
          <Route path=':id' element={<DashboardPrices />} />
        </Route>
        <Route path='discounts'>
          <Route
            index
            element={<DashboardProductsDiscounts />}
          />
          <Route
            path=':id'
            element={<DashboardProductsDiscounts />}
          />
        </Route>
        <Route path='shipping_rates'>
          <Route
            index
            element={<DashboardShippingRates />}
          />
          <Route
            path=':id'
            element={<DashboardShippingRates />}
          />
        </Route>
        <Route path='*' element={<Error />} />
      </Route>
      <Route
        path='/dashboard'
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }>
        <Route index element={<DashboardHome />} />
        <Route path='api_keys'>
          <Route
            index
            element={<DashboardDevelopersAPIKeys />}
          />
        </Route>
        <Route path='balance'>
          <Route
            index
            element={<Balance />}
          />
        </Route>
        <Route path='balance_transactions'>
          <Route
            index
            element={<BalanceTransactions />}
          />
          <Route
            path=':id'
            element={<BalanceTransactions />}
          />
          <Route
            path='?currency=:id'
            element={<BalanceTransactions />}
          />

        </Route>
        <Route path='topups'>
          <Route
            index
            element={<TopUps />}
          />
          <Route
            path=':id'
            element={<TopUps />}
          />

        </Route>
        <Route path='swaps'>
          <Route
            index
            element={<Swaps />}
          />
          <Route
            path=':id'
            element={<Swaps />}
          />

        </Route>
        <Route path='payouts'>
          <Route
            index
            element={<Payouts />}
          />
          <Route
            path=':id'
            element={<Payouts />}
          />

        </Route>
        <Route path='transfers'>
          <Route
            index
            element={<Transfers />}
          />
          <Route
            path=':id'
            element={<Transfers />}
          />

        </Route>
        <Route path='webhooks'>
          <Route
            index
            element={<DashboardDevelopersWebhooks />}
          />
          <Route
            path=':id'
            element={<DashboardDevelopersWebhooks />}
          />
        </Route>
        <Route path='events'>
          <Route
            index
            element={<Events />}
          />
          <Route
            path=':id'
            element={<Events />}
          />
        </Route>
        <Route path='tax_rates'>
          <Route
            index
            element={<DashboardProductsTaxRates />}
          />
          <Route
            path=':id'
            element={<DashboardProductsTaxRates />}
          />
        </Route>
        <Route path='customers'>
          <Route index element={<DashboardCustomers />} />
          <Route
            path=':id'
            element={<DashboardCustomers />}
          />
        </Route>
        <Route path='invoices'>
          <Route
            index
            element={<DashboardCustomersInvoices />}
          />
          <Route
            path=':id'
            element={<DashboardCustomersInvoices />}
          />
        </Route>
        <Route path='payments'>
          <Route index element={<DashboardPayments />} />
          <Route
            path=':id'
            element={<DashboardPayments />}
          />
          <Route
            path=':id/refund'
            element={<DashboardPayments />}
          />
        </Route>
        <Route path='payment_link'>
          <Route
            index
            element={<DashboardPaymentsLinks />}
          />
          <Route
            path=':id'
            element={<DashboardPaymentsLinks />}
          />
        </Route>
        <Route path='refunds'>
          <Route
            index
            element={<DashboardPaymentsRefunds />}
          />
          <Route
            path=':id'
            element={<DashboardPaymentsRefunds />}
          />
        </Route>
        <Route path='products'>
          <Route index element={<DashboardProducts />} />
          <Route
            path=':id'
            element={<DashboardProducts />}
          />
          <Route
            path=':id/edit'
            element={<DashboardProducts />}
          />
          <Route
            path=':id/create_price'
            element={<DashboardProducts />}
          />
          <Route
            path=':id/edit_price'
            element={<DashboardProducts />}
          />
        </Route>
        <Route path='prices'>
          <Route path=':id' element={<DashboardPrices />} />
        </Route>
        <Route path='discounts'>
          <Route
            index
            element={<DashboardProductsDiscounts />}
          />
          <Route
            path=':id'
            element={<DashboardProductsDiscounts />}
          />
        </Route>
        <Route path='shipping_rates'>
          <Route
            index
            element={<DashboardShippingRates />}
          />
          <Route
            path=':id'
            element={<DashboardShippingRates />}
          />
        </Route>
        <Route path='*' element={<Error />} />
      </Route>

      <Route path='/' element={<AuthLayout />}>
        <Route
          path='login'
          element={
            <RequireGuest>
              <Login />
            </RequireGuest>
          }
        />
        <Route path='register' element={<Register />} />
        <Route
          path='forgot_password'
          element={<ForgotPassword />}
        />
        <Route
          path='reset_password'
          element={<ResetPassword />}
        />
        <Route path='verify' element={<Verify />} />
        <Route path='resend' element={<Resend />} />
      </Route>

    </Routes>
  );
}

export default AppRoutes;
