import api from "../../utils/axios";
import {useQuery} from "react-query";

const fetchTeams = (params) => api.live.get("/teams", { params });
export const useFetchTeams = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchTeams", params],
    () => fetchTeams(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
