import DashboardModalTable from "../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useTablePagination from "../../../../../../../hooks/useTablePagination";
import {
  currencyFormatter,
  currencySymbol,
  formatCryptoValueLength,
} from "../../../../../../../utils/string";
import { useFetchPayments } from "../../../../../../../api/customer/payment";
import { ReactComponent as DateIcon } from "../../../../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../../../../utils/date";
import { ReactComponent as EyeIcon } from "../../../../../../../assets/images/icons/dashboard/eye.svg";
import { useNavigate } from "react-router-dom";
import LabelTag from "../../../../../../Common/LabelTag/LabelTag";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";

function CustomerDetailsPaymentsTable() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_customer } = useSelector((state) => state.customer);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchPaymentsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  };

  const { isFetching: isFetchingData } = useFetchPayments({
    onSuccess: onFetchPaymentsSuccess,
    onError: () => null,
    enabled: true,
    params: {
      customer: selected_customer?.id,
      limit: limitQuery,
      ...requestQuery,
    },
  });

  const handlePaymentViewClick = useCallback(
    (id) => {
      navigate(`/dashboard/payments/${id}`);
    },
    [navigate]
  );

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "amount",
        width: 70,
        maxWidth: 80,
        Cell: (cell) => {
          return (
            <div className="flex items-start gap-2">
              <div>
                <div className="app-modal-fullscreen__value">
                  <div className="flex gap-1">
                    <div
                      className="text-dark font-semibold"
                      style={{ fontSize: "1.2rem" }}
                    >
                      {currencySymbol(cell.row.original.currency)}
                      {currencyFormatter(
                        cell.row.original.currency,
                        cell.row.original.amount
                      )}
                    </div>
                  </div>
                </div>
                {cell.row.original.crypto_amount && (
                  <div className="app-modal-fullscreen__table__amount">
                    <div className="flex items-center gap-1">
                      <img
                        className="w-4 mt-1"
                        src={getIconUrl(cell.row.original.crypto_currency)}
                        alt={cell.row.original.crypto_currency}
                        style={{ width: "1.2rem" }}
                      />
                      <div className="font-normal">
                        {formatCryptoValueLength(
                          cell.row.original.crypto_amount
                        )}
                        {/* <span
                          className="leading-6"
                          style={{ fontSize: ".825rem" }}
                        >
                          {cell.row.original.crypto_currency?.toUpperCase()}
                        </span> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <>
              {cell.value === "under_paid" && row.captured && (
                <LabelTag color="primary">
                  <div
                    data-for="refunds-tooltip"
                    data-tip={`This payment was underpaid by 
                  ${currencySymbol(row.currency)}${currencyFormatter(
                      row.currency,
                      row.amount_underpaid
                    )} 
                  amount (${formatCryptoValueLength(
                    row.crypto_amount_underpaid
                  )} ${row.crypto_currency})`}
                    className="flex items-center gap-1"
                  >
                    <span>Succeeded</span>
                    <FaArrowAltCircleDown className="text-danger" />
                  </div>
                </LabelTag>
              )}
              {cell.value === "over_paid" && row.captured && (
                <LabelTag color="primary">
                  <div
                    data-for="refunds-tooltip"
                    data-tip={`This payment was overpaid by 
                  ${currencySymbol(row.currency)}${currencyFormatter(
                      row.currency,
                      row.amount_underpaid
                    )} 
                  amount (${formatCryptoValueLength(
                    row.crypto_amount_underpaid
                  )} ${row.crypto_currency})`}
                    className="flex items-center gap-1"
                  >
                    <span>Succeeded</span>
                    <FaArrowAltCircleUp className="text-tertiary" />
                  </div>
                </LabelTag>
              )}
              {cell.value === "succeeded" && row.captured && (
                <LabelTag color="primary">Succeeded</LabelTag>
              )}
              {["under_paid", "over_paid", "succeeded"].includes(cell.value) &&
                !row.captured && <LabelTag>Uncaptured</LabelTag>}
              {row.refunded && <LabelTag color="secondary">Refunded</LabelTag>}
              {cell.value === "failed" && (
                <div data-for="refunds-tooltip" data-tip={row.failure_message}>
                  <LabelTag color="danger">Failed</LabelTag>
                </div>
              )}
              {cell.value === "pending" && (
                <LabelTag color="tertiary">Pending</LabelTag>
              )}
              {cell.value === "cancelled" && (
                <LabelTag color="dark">Cancelled</LabelTag>
              )}
              
            </>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        width: 180,
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value">{cell.value ?? "-"}</div>
        ),
      },
      {
        Header: "Date",
        accessor: "created_at",
        width: 70,
        Cell: (cell) => (
          <div className="flex items-center">
            <DateIcon className="mr-1.5" />
            <span className="app-modal-fullscreen__value">
              {dateFormatter(cell.value, true)}
            </span>
          </div>
        ),
      },
      {
        Header: "",
        id: "icon",
        width: 20,
        Cell: (cell) => (
          <EyeIcon
            onClick={() => handlePaymentViewClick(cell.row.original.id)}
            className="cursor-pointer"
          />
        ),
      },
    ],
    [handlePaymentViewClick]
  );

  return (
    <div className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Payments</div>
        <div className="app-modal-fullscreen__sm-link text-primary">Create</div>
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Payments"
      />
    </div>
  );
}

export default CustomerDetailsPaymentsTable;
