import "./SectionGame.scss";
import Stack from "../../../Common/Stack/Stack";
import {ReactComponent as GameIcon} from "../../../../assets/images/icons/game.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {Link} from "react-router-dom";

const gameData = [
  {
    icon: "wallet",
    title: "User Wallets",
    text: `Create quick Wallets for your users to facilitate deposits and 
      withdrawals of cryptocurrency into your development. 
      Allow them to make fast transfers to other user wallets, 
      and open up the endless possibilities to expand your application!`
  },
  {
    icon: "cryptocurrency",
    title: "Create your own Cryptocurrency",
    text: `You can create your own BEP20, ERC20 or TRC20 cryptocurrency to power your development. 
      Use it to reward users, create minable features and even a microeconomy within your Game or App.`
  },
  {
    icon: "nft",
    title: "NFT Creations",
    text: `Create quick and easy NFT’s for in game assets or user trophies, the possibilities 
      are endless! By creating NFT’s for in-game assets you can allow your users to withdraw and 
      trade game assets, creating your own game market place. Allow users to make in-game purchases 
      of assets or game NFTs wit add more value and features to your Development.`
  },
  {
    icon: "reward",
    title: "Reward Your Users",
    text: `Create and manage reward pool systems for your users. 
      For example, you could charge a certain percent for in-game crypto transfers 
      that gets credited to a reward pool. Then Distribute the reward amounts back to your users!`
  }
]

function SectionGame({ scrollToContact }) {
  return (
    <div id="gds" className="section-game">
      <div className="app-container">
        <h3 className="h3 section-products__section__subtitle">
          <GameIcon className="section-products__section__subtitle__icon" />
          Game Developer Suite – GDS
        </h3>
        <h1 className="h1 section-products__section__title">
          The Fastest and Most Powerful Cryptocurrency Integrations for game and app developers.
        </h1>
        <p className="text section-products__section__text">
          Integrate Cryptocurrency features into your game or application faster than ever before.
          Create Wallets for your users to deposit and withdraw Cryptocurrency from and much more!
        </p>
        <div className="section-retail__buttons">
          <Link to="/register">
            <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
              Start Now
            </Button>
          </Link>
          <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
        </div>
        <Stack columns="1" gap="12" className="md:grid-cols-2 section-retail__features">
          {gameData.map((item, index) => {
            const SVGIcon = require(`../../../../assets/images/sections/game/${item.icon}.svg?svgr`).default;
            return (
              <div key={index} className="section-retail__features__content">
                <div className="section-retail__features__content__icon">
                  <SVGIcon />
                </div>
                <h5 className="h5 section-products__section__info section-retail__features__content__title">
                  {item.title}
                </h5>
                <p className="text-small section-retail__features__content__text lg:pr-14 pr-0">
                  {item.text}
                </p>
              </div>
            )
          })}
        </Stack>
      </div>
    </div>
  )
}

export default SectionGame;
