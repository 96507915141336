import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchTransfers = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/transfers", { params })
    : api.sandbox.get("/transfers", { params });

export const useFetchTransfers = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchTransfers", params], () => fetchTransfers(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchTransfer = (id) =>
  appStore.getState().app.livemode
    ? api.live.get(`/transfers/${id}`, {
        params: {
          expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
        },
      })
    : api.sandbox.get(`/transfers/${id}`, {
        params: {
          expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
        },
      });

export const useFetchTransfer = ({ onSuccess, onError, enabled, id }) => {
  return useQuery(["fetchTransfer", id], () => fetchTransfer(id), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const storeTransfer = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/transfers", data)
    : api.sandbox.post("/transfers", data);

export const useStoreTransfer = () => {
  return useMutation("storeTransfer", storeTransfer);
};

const updateTransfer = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/transfers/${id}`, data)
    : api.sandbox.patch(`/transfers/${id}`, data);

export const useUpdateTransfer = (id) => {
  return useMutation(["updateTransfer", id], (data) =>
    updateTransfer(id, data)
  );
};
