import "./SelectProducts.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { useFetchProducts } from "../../../api/product/product";
import PlusIcon from "../../../assets/images/icons/dashboard/plus.svg";
import { ReactComponent as Loader } from "../../../assets/images/loader.svg";
import truncate from "lodash/truncate";
import { dropdownPriceDescription } from "../../../utils/price_utils";
import { useAsyncDebounce } from "react-table";
import { useDispatch } from "react-redux";
import { setSearchProductInput } from "../../../store/invoice/invoiceSlice";

function SelectProducts({
  value = null,
  placeholder = "",
  currency = "",
  type = null, // 'one_time' or 'recurring'
  onClose = null,
  ...rest
}) {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, handleClose);

  function handleClose() {
    console.log('Handle close');
    setOpen(false);
    console.log('Handle close 2');
    onClose && onClose();
    console.log('Handle close 3');
  }

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  // Filter only prices that meet the provided currency and type;
  const filterProductPrices = (data) => {
    return data.map((item) => {
      return {
        ...item,
        prices: {
          ...item.prices,
          data: item.prices.data.filter((price) => {
            if (currency && type) return price.currency === currency && price.type === type;
            else if (currency) return price.currency === currency;
            else if (type) return price.type === type;
            else return true;
          })
        }
      }
    });
  };

  const { isLoading, refetch } = useFetchProducts({
    onSuccess: (data) => {
      setOptions(filterProductPrices(data.data));
    },
    onError: () => null,
    enabled: false,
    params: {
      sort: 'updated_at',
      include: ["prices"],
      active: true,
      ...bindParams,
    },
  });

  useEffect(() => {
    refetch();
  }, [currency, refetch]);

  const handleOptionClick = (option) => {
    setSelected(option);
    setInputValue(option.name);
    setOpen(false);
    dispatch(setSearchProductInput(""));
    rest.onSelect && rest.onSelect(option);
  };

  const handleAddItemClick = () => {
    dispatch(setSearchProductInput(trimInputValue));
    rest.oneTime && rest.oneTime();
  };

  const handleNewProductClick = () => {
    dispatch(setSearchProductInput(trimInputValue));
    rest.createProduct && rest.createProduct();
  };

  const handleInputChange = useAsyncDebounce(() => {
    setOpen(true);
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const trimInputValue = useMemo(() => {
    return inputValue.trim();
  }, [inputValue]);

  return (
    <div ref={selectRef} className="select-product">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <input
          type="text"
          className="select-customer__input__value"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && 
        <div className="select-customer__options">
          <div onClick={handleAddItemClick} className="select-customer__add">
            <img className="select-customer__add__icon" src={PlusIcon} alt="" />
            <span>
              Add {trimInputValue ? `'${trimInputValue}' as ` : ""} one-time
              item
            </span>
          </div>
          <div onClick={handleNewProductClick} className="select-customer__add">
            <img className="select-customer__add__icon" src={PlusIcon} alt="" />
            <span>
              Create {trimInputValue ? `'${trimInputValue}' as ` : ""} new
              product
            </span>
          </div>
          {options.length > 0 &&
          <div className="select-customer__recent">Recent products</div>
          }
          {isLoading && 
            <div className="select-customer__loader">
              <Loader />
            </div>
          }
          {!isLoading && options.map((product, index) => 
            <div
              key={index}
              onClick={() => handleOptionClick(product)}
              className={clsx(
                "select-product__options__item flex justify-between",
                selected &&
                  product.id === selected.id &&
                  "select-product__options__item--active"
              )}
            >
              <div>
                {truncate(product.name, {'length': 25, 'separator': "..." })}
              </div>
              {
                product.prices.data.length > 0 ?
                (product.prices.data.length === 1 ? 
                  <span>
                    {dropdownPriceDescription(product.prices.data[0])}
                  </span>
                  : 
                  <span>{product.prices.data.length} prices</span>
                ) 
                : 
                <span>No price</span>
              }
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default SelectProducts;
