import "./SectionPaymentLinks.scss";
import {ReactComponent as PaymentLinksIcon} from "../../../../assets/images/icons/payment-links.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {ReactComponent as Illustration} from "../../../../assets/images/sections/payment-links/illustration.svg";
import {ReactComponent as Step1Icon} from "../../../../assets/images/sections/payment-links/step-1.svg";
import {ReactComponent as Step2Icon} from "../../../../assets/images/sections/payment-links/step-2.svg";
import {ReactComponent as Step3Icon} from "../../../../assets/images/sections/payment-links/step-3.svg";
import Stack from "../../../Common/Stack/Stack";
import {Link} from "react-router-dom";

function SectionPaymentLinks({ scrollToContact }) {
  return (
    <div id="payment-links" className="section-payment-links">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <PaymentLinksIcon className="section-products__section__subtitle__icon" />
              Payment Links
            </h3>
            <h1 className="h1 section-products__section__title">
              Start Selling Products Quickly and Easily.
            </h1>
            <p className="text section-products__section__text">
              Create shareable links to start selling your products or services online.
              This provides a No-code solution and can be used with or without you having a website!
            </p>
            <div className="section-payment-links__buttons">
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
                  Start Now
                </Button>
              </Link>
              <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
            </div>
            <div className="section-payment-links__steps">
              <Stack columns="3" className="section-payment-links__steps__container">
                <div className="section-payment-links__step">
                  <div className="section-payment-links__step__number section-payment-links__step__number--blue">
                    <span>01</span>
                  </div>
                  <div className="section-payment-links__step__line" />
                </div>
                <div>
                  <h5 className="h5 section-payment-links__step__title">
                    Create a Payment Link
                  </h5>
                  <p className="text-small section-payment-links__step__text">
                    Create a simple Payment request, sell your product or service,
                    collect a donation, or start a new subscription all without a single line of code.
                  </p>
                </div>
                <div className="section-payment-links__step__icon">
                  <Step1Icon />
                </div>
              </Stack>
              <Stack columns="3" className="section-payment-links__steps__container">
                <div className="section-payment-links__step">
                  <div className="section-payment-links__step__number section-payment-links__step__number--white">
                    <span>02</span>
                  </div>
                  <div className="section-payment-links__step__line" />
                </div>
                <div>
                  <h5 className="h5 section-payment-links__step__title">
                    Share the Link
                  </h5>
                  <p className="text-small section-payment-links__step__text">
                    Share the link via SMS, email, social media, or website posts.
                    <span className="block">
                      The same link can be shared as many times as you want and shared across multiple platforms.
                    </span>
                  </p>
                </div>
                <div className="section-payment-links__step__icon">
                  <Step2Icon />
                </div>
              </Stack>
              <Stack columns="3" className="section-payment-links__steps__container">
                <div className="section-payment-links__step">
                  <div className="section-payment-links__step__number section-payment-links__step__number--blue">
                    <span>03</span>
                  </div>
                </div>
                <div>
                  <h5 className="h5 section-payment-links__step__title">
                    Receive Payment
                  </h5>
                  <p className="text-small section-payment-links__step__text">
                    Your Customer can make payment through your payment
                    link hosted page and get notified on a successful payment.
                  </p>
                </div>
                <div className="section-payment-links__step__icon">
                  <Step3Icon />
                </div>
              </Stack>
            </div>
          </div>
          <div className="section-payment-links__illustration">
            <Illustration />
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default SectionPaymentLinks;
