import api from "../../utils/axios";
import {useQuery} from "react-query";
import appStore from "../../store";

const fetchRequestsLog = (params) => appStore.getState().app.livemode
  ? api.live.get("/requests", { params })
  : api.sandbox.get("/requests", { params });

export const useFetchRequestsLog = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchRequestsLog", params],
    () => fetchRequestsLog(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
