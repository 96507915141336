import "./Input.scss";
import clsx from "clsx";
import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      type = "text",
      placeholder = "",
      className = "",
      size = null,
      block = false,
      error = "",
      warning = "",
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <input
          
          ref={ref}
          className={clsx(
            className,
            "app-input placeholder-white",
            block && "app-input--block",
            size && `app-input--${size}`,
            error && "app-input--error",
            warning && "app-input--warning"
          )}
          type={type}
          placeholder={placeholder}
          disabled={rest?.disabled}
          {...rest}
        />
        {error && <p className="app-input__help">{error}</p>}
        {warning && <p className="text-xs text-orange-400">{warning}</p>}
      </>
    );
  }
);

export default Input;
