import Dropdown from "../../../../../Common/Dropdown/Dropdown";
import { ReactComponent as MoreIcon } from "../../../../../../assets/images/icons/dashboard/more.svg";
import React from "react";
import { setRefetchWebhook } from "../../../../../../store/webhook/webhookSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useUpdateWebhook } from "../../../../../../api/webhook/webhook";

function WebhooksTableActions({ webhook = null, onDelete = null }) {
  const dispatch = useDispatch();

  const { mutate: mutateUpdateWebhook } = useUpdateWebhook(webhook?.id);

  const handleStatusUpdate = () => {
    mutateUpdateWebhook(
      {
        active: !webhook.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setRefetchWebhook(Date.now()));
          toast.success(
            `Webhook has been ${data.data.active ? "enabled" : "disabled"}`
          );
        },
      }
    );
  };

  return (
    <Dropdown
      items={[
        {
          label: webhook.active ? "Disabled" : "Enable",
          onClick: handleStatusUpdate,
        },
        {
          label: "Delete",
          className: "text-danger font-semibold",
          onClick: () => {
            onDelete && onDelete(webhook);
          },
        },
      ]}
    >
      <Dropdown.Slot name="toggle">
        <MoreIcon />
      </Dropdown.Slot>
    </Dropdown>
  );
}

export default WebhooksTableActions;
