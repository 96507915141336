import Label from "../../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../../Common/Form/Input/Input";
import clsx from "clsx";
import ArrowIcon from "../../../../../../../../../assets/images/icons/dashboard/arrow_down.svg";
import {ReactComponent as CloseIcon} from "../../../../../../../../../assets/images/icons/modal-close.svg";
import SelectTaxRates from "../../../../../../../../Dashboard/SelectTaxRates/SelectTaxRates";
import {
  currencyConverter, 
  currencyFormatter, 
  currencySymbol,
  fiatCurrencies,
  sanitizeUnitAmount
} from "../../../../../../../../../utils/string";
import {
  dropdownPriceDescription
} from "../../../../../../../../../utils/price_utils";
import SelectDiscounts from "../../../../../../../../Dashboard/SelectDiscounts/SelectDiscounts";
import DashboardButton from "../../../../../../../../Common/Form/DashboardButton/DashboardButton";
import {useEffect, useState} from "react";
import {useStoreInvoiceItem, useUpdateInvoiceItem} from "../../../../../../../../../api/customer/invoice";
import {
  setEditInvoiceItem,
  setRefetchInvoiceItems,
  setSelectedInvoiceItem,
  setSelectOneTime, setShowProductsSelect
} from "../../../../../../../../../store/invoice/invoiceSlice";
import {useDispatch, useSelector} from "react-redux";
import InputPrice from "../../../../../../../../Dashboard/InputPrice/InputPrice";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {ITEM_NEGATIVE_PRICE_REGEX} from "../../../../../../../../../utils/constants";

const formSchema = yup.object({
  product_name: yup.string().required().trim().label('Item name'),
  price: yup.string()
    .required()
    .trim()
    .matches(ITEM_NEGATIVE_PRICE_REGEX, 'Price must not exceed 2 decimal places')
    .label('Price'),
  quantity: yup.number().required().min(1).label('Quantity').typeError("Quantity is a required field"),
}).required();

function InvoiceItemsOneTime() {
  const dispatch = useDispatch();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [showDiscountsSelect, setShowDiscountsSelect] = useState(true);
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(true);
  const {
    selected_invoice_customer,
    draft_invoice,
    invoice_currency,
    search_product_input,
    edit_invoice_item
  } = useSelector((state) => state.invoice);

  const {
    watch,
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: {errors}
  } = useForm({
    defaultValues: {
      product_name: edit_invoice_item?.product.name ?? search_product_input ?? "",
      price: edit_invoice_item?.price
        ? ""
        : "",
      quantity: edit_invoice_item?.quantity ?? 1
    },
    resolver: yupResolver(formSchema)
  });

  const price = watch("price");

  const { mutate: mutateStoreInvoiceItem, isLoading: isStoreLoading } = useStoreInvoiceItem();
  const { mutate: mutateUpdateInvoiceItem, isLoading: isUpdateLoading } = useUpdateInvoiceItem(edit_invoice_item?.invoice_item);

  useEffect(() => {
    if (edit_invoice_item) {
      if (edit_invoice_item.discounts.length) setSelectedDiscount(edit_invoice_item.discounts[0]);
      if (edit_invoice_item.tax_rates.length) setSelectedTaxRates(edit_invoice_item.tax_rates);
      setShowDiscountsSelect(!edit_invoice_item.discounts.length);
      setShowTaxRatesSelect(!edit_invoice_item.tax_rates.length);
    }
  }, [edit_invoice_item]);

  const handleQuantityChange = (e) => {
    setValue("quantity", e.target.value);
    trigger("quantity");
  }

  const handlePriceChange = (value) => {
    setValue("price", value);
    trigger("price");
  }

  const handleDiscountSelect = (discount) => {
    setSelectedDiscount(discount);
    setShowDiscountsSelect(false);
  }

  const handleDeleteDiscountClick = () => {
    setSelectedDiscount(null);
    setShowDiscountsSelect(true);
  }

  const handleTaxRateSelect = (tax) => {
    if (selectedTaxRates.find(item => item.id === tax.id)) {
      return;
    }

    setSelectedTaxRates([
      ...selectedTaxRates,
      tax
    ]);
    setShowTaxRatesSelect(false);
  }

  const handleDeleteTaxRateClick = (tax) => {
    setSelectedTaxRates(selectedTaxRates.filter(item => item.id !== tax.id));
  }

  const handleAddTaxRateClick = () => {
    setShowTaxRatesSelect(true);
  }

  const handleCancelClick = () => {
    dispatch(setSelectOneTime(false));
    dispatch(setShowProductsSelect(false));
    dispatch(setEditInvoiceItem(null));
  }

  const handleUpdateItem = (formData, add_another = false) => {
    mutateUpdateInvoiceItem({
      quantity: formData.quantity,
      discounts: selectedDiscount ? [{ discount: selectedDiscount.id }] : "",
      tax_rates: selectedTaxRates.length ? selectedTaxRates.map(tax => tax.id) : ""
    }, {
      onSuccess: () => {
        dispatch(setSelectOneTime(false));
        dispatch(setSelectedInvoiceItem(null));
        dispatch(setRefetchInvoiceItems(Date.now()));
        dispatch(setEditInvoiceItem(null));
        dispatch(setShowProductsSelect(false));

        if (add_another) dispatch(setShowProductsSelect(true));
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    })
  }

  const handleSaveClick = (formData, add_another = false) => {
    if (edit_invoice_item) {
      handleUpdateItem(formData, add_another);
      return;
    }

    const _data = {
      customer: selected_invoice_customer.id,
      quantity: formData.quantity,
      invoice: draft_invoice?.id,
    }

    let priceData = {
      description: formData.product_name,
      currency: invoice_currency
    };
    const unitAmount = currencyConverter(invoice_currency, price, true);
    if (unitAmount.includes('.')) {
      priceData.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
    } else priceData.unit_amount = Number(unitAmount);

    _data.price_data = priceData;

    _data.discounts = selectedDiscount
      ? [{ discount: selectedDiscount.id }]
      : []

    _data.tax_rates = selectedTaxRates.length
      ? selectedTaxRates.map(tax => tax.id)
      : [];

    mutateStoreInvoiceItem(_data, {
      onSuccess: () => {
        dispatch(setSelectOneTime(false));
        dispatch(setSelectedInvoiceItem(null));
        dispatch(setRefetchInvoiceItems(Date.now()));
        dispatch(setEditInvoiceItem(null));
        dispatch(setShowProductsSelect(false));

        if (add_another) dispatch(setShowProductsSelect(true));
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    })
  }

  const fieldError = (field) => {
    return errors[field]?.message/* || (error && error.response?.data.error.param === field)*/;
  }

  return (
    <>
      <div className="space-y-1.5 my-4 w-4/5">
        <div className="grid gap-5 grid-cols-6">
          <div className="col-span-3">
            <Label title="Item" />
            <Input
              block
              disabled={edit_invoice_item}
              type="text"
              {...register("product_name")}
              error={fieldError("product_name")}
            />
          </div>
          <div>
            <Label title="Qty" />
            <Input
              type="number"
              block
              {...register("quantity", {
                onChange: handleQuantityChange
              })}
              className="text-light"
              placeholder="1"
              min="1"
              error={fieldError("quantity")}
            />
          </div>
          <div className="col-span-2">
            <Label title="Price" />
            <InputPrice
              disabled={edit_invoice_item}
              value={price}
              onChange={handlePriceChange}
              className="text-light"
              currency={!edit_invoice_item ? invoice_currency : null}
              placeholder={edit_invoice_item?.price ? dropdownPriceDescription(edit_invoice_item.price) : (fiatCurrencies[invoice_currency].zero_decimal ? "0" : "0.00")}
              error={fieldError("price")}
            />
          </div>
        </div>
        <div className={clsx(
          "app-invoices__modal__collapse pt-2",
          showAdvanced && "app-invoices__modal__collapse--open"
        )}>
          <div
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="app-invoices__modal__collapse__menu app-modal-fullscreen__small-title mb-3"
          >
            <span className="text-secondary">Items taxes and discounts</span>
            <img className="app-invoices__modal__collapse__menu__icon" src={ArrowIcon} alt="" />
          </div>
          <div className="space-y-8 app-invoices__modal__collapse__item">
            <div className="grid grid-cols-3 gap-6">
              <div>
                <Label title="Item taxes"/>
                <div>
                  {selectedTaxRates.map((tax, index) =>
                    <div
                      key={index}
                      className="app-invoices__modal__discount-tax mb-1"
                    >
                      <div>
                        <span className="mr-2">{tax.display_name}</span>
                        {`(${tax.percent}%${tax.inclusive && ' incl.'})`}
                      </div>
                      <CloseIcon onClick={() => handleDeleteTaxRateClick(tax)} />
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  {showTaxRatesSelect
                    ?
                    <>
                      <SelectTaxRates
                        onSelect={handleTaxRateSelect}
                        data={selectedTaxRates.map(tax => tax.id)}
                      />
                    </>
                    :
                    <span
                      onClick={handleAddTaxRateClick}
                      className="app-invoices__modal__link"
                    >
                    Add another tax rate
                  </span>
                  }
                </div>
              </div>
              <div>
                <Label title="Item discounts"/>
                {selectedDiscount &&
                <div>
                  <div className="app-invoices__modal__discount-tax mb-1">
                    <div>
                      <span className="mr-2">{selectedDiscount.name}</span>
                      {selectedDiscount.amount_off && (
                        <>
                          {`- ${currencySymbol(selectedDiscount.currency)} ${currencyFormatter(
                            selectedDiscount.currency,
                            selectedDiscount.amount_off
                          )} off`}
                        </>
                      )}
                      {selectedDiscount.percent_off && (
                        <>{`- ${selectedDiscount.percent_off}% off`}</>
                      )}
                    </div>
                    <CloseIcon onClick={() => handleDeleteDiscountClick()} />
                  </div>
                </div>
                }
                <div className="mt-2">
                  {(!selectedDiscount && showDiscountsSelect) &&
                  <SelectDiscounts
                    onSelect={handleDiscountSelect}
                  />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="horizontal-divider" />
        <div className="flex justify-end items-center mt-4 gap-3">
          <div
            onClick={handleCancelClick}
            className="text-secondary font-semibold cursor-pointer"
          >
            Cancel
          </div>
          <DashboardButton
            onClick={() => handleSubmit((data) => handleSaveClick(data, true))()}
            disabled={isStoreLoading || isUpdateLoading}
            type="button"
            dense
            color="light"
          >
            Save and add another
          </DashboardButton>
          <DashboardButton
            onClick={() => handleSubmit((data) => handleSaveClick(data))()}
            disabled={isStoreLoading || isUpdateLoading}
            type="button"
            dense
            color="primary"
          >
            Save
          </DashboardButton>
        </div>
      </div>
    </>
  )
}

export default InvoiceItemsOneTime;
