import "./DashboardSidebar.scss";
import Logo from "../../../assets/images/logo.svg";
import {ReactComponent as SidebarBanner} from "../../../assets/images/dashboard/sidebar-banner.svg";
import {Link} from "react-router-dom";
import DashboardSidebarMenu from "./DashboardSidebarMenu/DashboardSidebarMenu";
import {useDispatch} from "react-redux";
import {useUserLogout} from "../../../api/auth/auth";
import {logoutUser} from "../../../store/app/authSlice";
import Switch from "../../../components/Common/Form/Switch/Switch";
import ReactTooltip from "react-tooltip";

function DashboardSidebar() {
  const dispatch = useDispatch();
  const { mutate: mutateUserLogout } = useUserLogout();

  const getIconUrl = (icon) => {
    return require(`../../../assets/images/icons/dashboard/${icon}.svg`);
  }

  const handleLogoutClick = () => {
    mutateUserLogout();
    dispatch(logoutUser());
  }

  return (
    <div className="sidebar">
      <div>
        <div className="sidebar__top">
          <Link to="/dashboard" className="sidebar__logo">
            <img src={Logo} alt="Walio" />
          </Link>
        </div>
        <div className="sidebar__divider" />
        <div className="app-testmode" data-for="sidebar-tooltip" data-tip="You can't go into live mode yet">
          <Switch checked disabled color="yellow" />
          <span>Test mode</span>
          <ReactTooltip
            id="sidebar-tooltip"
            place="bottom"
            effect="solid"
            type="dark"
            padding="10px"
          />
        </div>
        <div className="sidebar__menu">
          <div className="sidebar__menu__group">Merchant Account</div>
          <DashboardSidebarMenu />
        </div>
        <div className="sidebar__divider mt-5" />
        <div className="sidebar__menu">
          <div className="sidebar__menu__group">User Account</div>
          <div className="sidebar__menu__item">
           <Link to={"/profile"}>
             <img src={getIconUrl("account")} alt="Profile" />
             <span>Profile</span>
           </Link>
          </div>
          {/* <div className="sidebar__menu__item">
            <div>
              <img src={getIconUrl("billing")} alt="Billing" />
              <span>Billing</span>
            </div>
          </div> */}
          <div onClick={handleLogoutClick} className="sidebar__menu__item">
            <div>
              <img src={getIconUrl("logout")} alt="Logout" />
              <span>Logout</span>
            </div>
          </div>
        </div>
        {/* <SidebarBanner className="sidebar__banner" /> */}
      </div>
      {/* <div>
        <div className="sidebar__divider mt-5" />
        <div className="sidebar__menu">
          <div className="sidebar__menu__group">Account</div>
          <div className="sidebar__menu__item">
           <div>
             <img src={getIconUrl("account")} alt="Profile Settings" />
             <span>Profile Settings</span>
           </div>
          </div>
          <div className="sidebar__menu__item">
            <div>
              <img src={getIconUrl("billing")} alt="Billing" />
              <span>Billing</span>
            </div>
          </div>
          <div onClick={handleLogoutClick} className="sidebar__menu__item">
            <div>
              <img src={getIconUrl("logout")} alt="Logout" />
              <span>Logout</span>
            </div>
          </div>
        </div>
        {<SidebarBanner className="sidebar__banner" />
      </div> */}
    </div>
  )
}

export default DashboardSidebar;
