import {useEffect, useMemo, useRef, useState} from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import {currencySymbol} from "../../../utils/string";

function SelectPrice({
  currency = "",
  path = "",
  options = [],
  value = null,
  style,
  placeholder = "",
  className = "",
  error = "",
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, () => setOpen(false));

  const handleOptionClick = (option, index) => {
    setActiveIndex(index);
    setOpen(false);
    setShowInput(false);
    setInputValue("");
    rest.onSelect && rest.onSelect(option);
  }

  useEffect(() => {
    setActiveIndex(options.findIndex(option => option.value === value));
  }, [value, options]);

  const getActiveIndex = useMemo(() => {
    if (activeIndex === -1) {
      return null;
    }
    return options.length && options[activeIndex];
  }, [options, activeIndex]);

  const getIconUrl = (icon) => {
    try {
      return require(`../../../${path}/${icon}.svg`);
    } catch(err) {
      return null;
    }
  }

  const handleAddPriceClick = () => {
    setOpen(false);
    setShowInput(true);
    setInputValue("1");
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleInputBlur = () => {
    rest.onSelect && rest.onSelect({
      label: inputValue,
      value: inputValue
    });
  }

  return (
    <div
      ref={selectRef}
      className={clsx(
        className,
        "app-select"
      )}
      style={style}
    >
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "app-select__input",
          open && "app-select__input--active",
          error && "app-select__input--error"
        )}
      >
        <div className="app-select__input__value w-full">
          {showInput
          ?
            <div className="relative w-full">
              <input
                autoFocus
                type="number"
                className="app-select__input__value__input"
                placeholder={placeholder}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                min={rest.min ?? null}
              />
              <div className="absolute left-0 top-0">
                {currencySymbol(currency)}
              </div>
            </div>
          :
            getActiveIndex
              ? <>
                {(getActiveIndex.icon && getActiveIndex.icon !== "") &&
                <img
                  className="app-select__input__icon"
                  src={getIconUrl(getActiveIndex.icon)}
                  alt=""
                />
                }
                <span>{getActiveIndex.label}</span>
              </>
              : <span>{placeholder}</span>

          }
        </div>
        <img className="app-select__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open &&
      <div className="app-select__options">
        {options.map((option, index) =>
          <div
            key={index}
            onClick={() => handleOptionClick(option, index)}
            className={clsx(
              "app-select__options__item",
              index === activeIndex && "app-select__options__item--active"
            )}
          >
            {(option.icon && option.icon !== "") &&
            <img
              className="app-select__options__item__icon"
              src={getIconUrl(option.icon)}
              alt=""
            />
            }
            {option.label}
            {option?.price?.description &&
            <div className="app-select__options__item__description">{option?.price?.description}</div>
            }
          </div>
        )}
        <div
          onClick={handleAddPriceClick}
          className="app-select__options__item"
        >
          <span className="text-secondary font-semibold">Add new price</span>
        </div>
      </div>
      }
      {error && <p className="app-input__help">{error}</p>}
    </div>
  )
}

export default SelectPrice;