import Modal from "../../../../../Common/Modal/Modal";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Button from "../../../../../Common/Form/Button/Button";
import clsx from "clsx";
import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../utils/string";
import {
  useFetchUpcomingInvoice,
  useFetchUpcomingInvoiceLineItems,
  useStoreInvoice,
} from "../../../../../../api/customer/invoice";
import { useDispatch, useSelector } from "react-redux";
import Label from "../../../../../Common/Form/Label/Label";
import Select from "../../../../../Common/Form/Select/Select";
import uniqBy from "lodash/uniqBy";
import { toast } from "react-toastify";
import {
  setRefetchInvoices,
  setRefetchPendingItems,
} from "../../../../../../store/invoice/invoiceSlice";

function CustomersModalInvoiceNow({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const { current_account } = useSelector((state) => state.auth);
  const [selectedCurrency, setSelectedCurrency] = useState(current_account?.settings?.reporting_currency ?? 'usd');
  const { selected_customer } = useSelector((state) => state.customer);
  const { invoice_now_currencies } = useSelector((state) => state.invoice);

  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    refetch: refetchInvoice,
  } = useFetchUpcomingInvoice({
    params: {
      customer: selected_customer?.id,
      currency: selectedCurrency,
      upcoming_mode: "no_cycle",
      expand: ["customer", "discounts", "default_tax_rates", "shipping_rates"],
    },
  });
  const { data: invoiceItems, isLoading: isLoadingInvoiceItems } =
    useFetchUpcomingInvoiceLineItems({
      params: {
        customer: selected_customer?.id,
        currency: selectedCurrency,
        upcoming_mode: "no_cycle",
        expand: [
          "product",
          "price",
          "discounts",
          "tax_rates",
          "shipping_rates",
        ],
      },
    });

  const { mutate: mutateStoreInvoice, isLoading: isLoadingInvoiceNow } =
    useStoreInvoice();

  useEffect(() => {
    setSelectedCurrency(
      invoice_now_currencies.length ? invoice_now_currencies[0] : ""
    );
  }, [invoice_now_currencies]);

  const getInvoiceItems = useMemo(() => {
    return invoiceItems.filter((item) => item.subscription === null);
  }, [invoiceItems]);

  const getCurrencies = useMemo(() => {
    return uniqBy(
      invoice_now_currencies.map((currency) => {
        return { label: currency.toUpperCase(), value: currency };
      }),
      "value"
    );
  }, [invoice_now_currencies]);

  const getInvoiceDiscount = useMemo(() => {
    const discountGroup = invoice?.discount_amounts.find(
      (discount) => discount.source === "group"
    );
    return discountGroup
      ? invoice?.discounts.find(
          (discount) => discount.id === discountGroup.discount
        )
      : null;
  }, [invoice]);

  const isLoadingData = useMemo(() => {
    return (
      !invoice || !invoiceItems || isLoadingInvoice || isLoadingInvoiceItems
    );
  }, [invoice, invoiceItems, isLoadingInvoice, isLoadingInvoiceItems]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCurrencyChange = (currency) => {
    if (selectedCurrency === currency?.value) return;

    setSelectedCurrency(currency?.value);
    refetchInvoice();
  };

  const handleInvoiceNowClick = () => {
    mutateStoreInvoice(
      {
        customer: selected_customer?.id,
        currency: selectedCurrency,
        include_pending_items: true,
      },
      {
        onSuccess: () => {
          handleClose();
          dispatch(setRefetchInvoices(Date.now()));
          dispatch(setRefetchPendingItems(Date.now()));
          toast.success("Invoice created successfully");
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      }
    );
  };

  const getItemDiscountAmount = (discount_id, discounts) => {
    return discounts.find((item) => item?.discount === discount_id);
  };

  return (
    <Modal show={show} onClose={handleClose} footerBorder>
      <Modal.Slot name="header">
        <div className="flex items-center justify-between w-full">
          <div className="app-invoice__modal__title">Create Invoice</div>
          <div className="mr-8">
            <Label title="Currencies" />
            <Select
              onSelect={handleCurrencyChange}
              options={getCurrencies}
              value={selectedCurrency}
              style={{ minWidth: "100px" }}
            />
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        {(!invoice || !getInvoiceItems) && (
          <div className="app-modal-fullscreen__value text-center">
            No Invoices found
          </div>
        )}
        {!isLoadingData && (
          <table className="app-invoices__preview__table">
            <thead>
              <tr>
                <th width="40%" className="app-modal-fullscreen__label">
                  DESCRIPTION
                </th>
                <th className="app-modal-fullscreen__label">QTY</th>
                <th className="app-modal-fullscreen__label">UNIT PRICE</th>
                <th className="app-modal-fullscreen__label">TAX</th>
                <th width="10%" className="app-modal-fullscreen__label">
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              {getInvoiceItems.map((item, index) => (
                <Fragment key={index}>
                  <tr
                    className={clsx(
                      item.discounts?.length &&
                        "app-invoices__preview__table__row--borderless",
                      !(index % 2) && "app-invoices__preview__table__row--bg"
                    )}
                  >
                    <td>
                      <div className="app-invoices__preview__table__row__value">
                        {item.product.name}
                      </div>
                    </td>
                    <td>
                      <div className="app-invoices__preview__table__row__value">
                        {item.quantity}
                      </div>
                    </td>
                    <td>
                      <div className="app-invoices__preview__table__row__amount">
                        {currencySymbol(item.price.currency)}
                        {currencyFormatter(
                          item.price.currency,
                          item.price.unit_amount_decimal
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="app-invoices__preview__table__row__value">
                        {item.tax_rates.length > 0
                          ? item.tax_rates.map((tax_rate, index) => (
                              <div key={index}>
                                {tax_rate.percent}%
                                {tax_rate.inclusive ? " incl." : ""}
                              </div>
                            ))
                          : null}
                      </div>
                    </td>
                    <td>
                      <div className="app-invoices__preview__table__row__amount justify-end">
                        <span className="font-semibold">
                          {currencySymbol(item.price.currency)}
                          {currencyFormatter(item.price.currency, item.amount)}
                        </span>
                      </div>
                    </td>
                  </tr>
                  {item.discounts.map((discount, dIndex) => {
                    const _discount = getItemDiscountAmount(
                      discount.id,
                      item.discount_amounts
                    );

                    return (
                      _discount && (
                        <tr
                          key={dIndex}
                          className={clsx(
                            !(index % 2) &&
                              "app-invoices__preview__table__row--bg"
                          )}
                        >
                          <td>
                            <div className="app-invoices__preview__table__row__value pl-3">
                              <div className="text-light">
                                {discount.name}{" "}
                                {discount.amount_off &&
                                  `(${currencySymbol(
                                    discount.currency
                                  )}${currencyFormatter(
                                    discount.currency,
                                    discount.amount_off
                                  )} off)`}
                                {discount.percent_off &&
                                  `(${discount.percent_off}% off)`}
                              </div>
                            </div>
                          </td>
                          <td />
                          <td />
                          <td />
                          <td>
                            <div className="app-invoices__preview__table__row__amount justify-end">
                              -{currencySymbol(discount.currency)}
                              {currencyFormatter(
                                discount.currency,
                                _discount.amount
                              )}{" "}
                            </div>
                          </td>
                        </tr>
                      )
                    );
                  })}
                </Fragment>
              ))}
              <tr className="app-invoices__preview__table__row--borderless">
                <td />
                <td />
                <td />
                <td>
                  <div className="app-invoices__preview__table__row__value font-semibold">
                    Subtotal
                  </div>
                </td>
                <td>
                  <div className="app-invoices__preview__table__row__amount justify-end">
                    <span className="font-semibold">
                      {currencySymbol(invoice.currency)}
                      {currencyFormatter(
                        invoice.currency,
                        invoice.subtotal
                      )}{" "}
                    </span>
                  </div>
                </td>
              </tr>
              {getInvoiceDiscount && (
                <tr
                  className="
                  app-invoices__preview__table__row--bg
                  app-invoices__preview__table__row--bg--empty
                  app-invoices__preview__table__row--borderless
                "
                >
                  <td />
                  <td />
                  <td />
                  <td>
                    <div className="app-invoices__preview__table__row__value">
                      {getInvoiceDiscount.name}{" "}
                      {getInvoiceDiscount.amount_off &&
                        `(${currencySymbol(
                          invoice.currency
                        )}${currencyFormatter(
                          invoice.currency,
                          getInvoiceDiscount.amount_off
                        )} off)`}
                      {getInvoiceDiscount.percent_off &&
                        `(${getInvoiceDiscount.percent_off}% off)`}
                    </div>
                  </td>
                  <td>
                    <div className="app-invoices__preview__table__row__amount justify-end">
                      -{currencySymbol(invoice.currency)}
                      {currencyFormatter(
                        invoice.currency,
                        getInvoiceDiscount.amount
                      )}{" "}
                    </div>
                  </td>
                </tr>
              )}
              {invoice.tax_amounts.map((tax_rate, tIndex) => (
                <tr
                  key={tIndex}
                  className="
                    app-invoices__preview__table__row--bg
                    app-invoices__preview__table__row--bg--empty
                    app-invoices__preview__table__row--borderless
                  "
                >
                  <td />
                  <td />
                  <td />
                  <td>
                    <div className="app-invoices__preview__table__row__value">
                      {tax_rate.display_name} ({tax_rate.percent}%
                      {tax_rate.inclusive ? " incl." : ""})
                    </div>
                  </td>
                  <td>
                    <div className="app-invoices__preview__table__row__amount justify-end">
                      {currencySymbol(invoice.currency)}
                      {currencyFormatter(
                        invoice.currency,
                        tax_rate.amount
                      )}{" "}
                    </div>
                  </td>
                </tr>
              ))}
              <tr
                className="
                  app-invoices__preview__table__row--bg--empty
                  app-invoices__preview__table__row--bg--gray
                  app-invoices__preview__table__row--borderless
                "
              >
                <td />
                <td />
                <td />
                <td>
                  <div className="app-invoices__preview__table__row__value font-semibold">
                    Total
                  </div>
                </td>
                <td>
                  <div className="app-invoices__preview__table__row__amount justify-end">
                    <span className="font-semibold">
                      {currencySymbol(invoice.currency)}
                      {currencyFormatter(invoice.currency, invoice.total)}
                    </span>
                  </div>
                </td>
              </tr>
              <tr
                className="
                  app-invoices__preview__table__row--bg--empty
                  app-invoices__preview__table__row--bg--gray
                  app-invoices__preview__table__row--borderless
                "
              >
                <td />
                <td />
                <td />
                <td>
                  <div className="app-invoices__preview__table__row__value font-semibold">
                    Amount due
                  </div>
                </td>
                <td>
                  <div className="app-invoices__preview__table__row__amount justify-end">
                    <span className="font-semibold">
                      {currencySymbol(invoice.currency)}
                      {currencyFormatter(invoice.currency, invoice.amount_due)}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex items-center justify-between">
          <div className="text-light text-sm">
            <p>This invoice will be billed in 1 to 2 hours and</p>
            <p>won’t affect any subscriptions</p>
          </div>
          <Button
            onClick={handleInvoiceNowClick}
            type="button"
            loading={isLoadingInvoiceNow}
          >
            Invoice Now
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default CustomersModalInvoiceNow;
