import "./Loader.scss";
import LoaderIcon from "../../assets/images/page-loader.svg";

function Loader() {
  return (
    <div className="app-loader">
      <img src={LoaderIcon} alt="Loading ..." />
    </div>
  )
}

export default Loader;