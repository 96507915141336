import Input from "../../../components/Common/Form/Input/Input";
import Stack from "../../../components/Common/Stack/Stack";
import Label from "../../../components/Common/Form/Label/Label";
import Button from "../../../components/Common/Form/Button/Button";
import "./ResetPassword.scss";
import {Link, useLocation} from "react-router-dom";
import Logo from '../../../assets/images/logo.svg';
import FacebookIcon from '../../../assets/images/social/facebook.png';
import TwitterIcon from '../../../assets/images/social/twitter.png';
import TelegramIcon from '../../../assets/images/social/telegram.png';
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useResetPassword} from "../../../api/auth/auth";
import Alert from "../../../components/Common/Alert/Alert";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const resetPasswordSchema = yup.object({
  password: yup.string().required().label('Password'),
  password_confirmed: yup.string().required()
    .oneOf([yup.ref('password'), null], 'Passwords does not match')
    .label('Confirm Password'),
}).required();

function ResetPassword() {
  const location = useLocation();
  const [token, setToken] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState("");
  const { handleSubmit, register, reset, formState:{ errors } } = useForm({
    resolver: yupResolver(resetPasswordSchema)
  });
  const { mutate, data, isLoading, isSuccess, isError, error: reqError } = useResetPassword();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const subject = query.get('subject');

    if (token && subject) {
      setToken(token);
      setSubject(subject);
    } else {
      setError("Invalid Token");
    }
  }, [location]);

  const onSubmit = (formData) => {
    setError("");

    mutate({
      ...formData,
      subject,
      token
    }, {
      onSuccess: () => reset(),
      onError: (error) => setError(error.response.data.error.message)
    });
  }

  const fieldError = (field) => {
    return errors[field]?.message || (reqError && reqError.response?.data.error.param === field);
  }

  return (
    <div className="app-auth min-h-screen">
      <div className="app-container app-auth__container">
        <div className="app-auth__content">
          <div className="app-auth__logo">
            <Link to="/">
              <img src={Logo} alt="Walio" />
            </Link>
          </div>
          <div className="app-auth__card app-auth__card__login">
            <div className="app-auth__card__title app-password__title">Reset your password</div>
            {(isError || error) &&
              <Alert
                className="my-4"
                type="danger"
                message={error}
              />
            }
            {isSuccess &&
              <Alert
                className="my-4"
                type="success"
                message={data.data.message}
              />
            }
            {!isSuccess
            ?
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack columns="1">
                  {error !== "Invalid Token" &&
                    <>
                      <div>
                        <Label
                          title="Password"
                          error={fieldError("password")}
                        />
                        <Input
                          {...register("password")}
                          type="password"
                          block
                          error={fieldError("password")}
                        />
                      </div>
                      <div>
                        <Label
                          title="Confirm Password"
                          error={fieldError("password_confirmed")}
                        />
                        <Input
                          {...register("password_confirmed")}
                          type="password"
                          block
                          error={fieldError("password_confirmed")}
                        />
                      </div>
                      <Button loading={isLoading}>Continue</Button>
                    </>
                  }
                  <Link className="app-password__link" to="/login">Return to Sign in</Link>
                </Stack>
              </form>
            :
              <Link to="/login">
                <Button block>Return to Sign in</Button>
              </Link>
            }
          </div>
          <div className="app-auth__card__footer">
            <div>Don’t have an account? <Link to="/register" className="app-auth__card__footer__link">Sign up</Link></div>
          </div>
        </div>
        <div className="app-auth__footer">
          <div>© Walio All rights reserved.</div>
          <div className="app-auth__footer__social">
            <img src={FacebookIcon} alt="" href="https://www.facebook.com/mywalio.io/"/>
            <img src={TwitterIcon} alt="" href="https://twitter.com/walio_official"/>
            <img src={TelegramIcon} alt="" href="https://t.me/walio_official"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;
