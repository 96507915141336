import React from 'react';
import NumberEditableCell from './NumberEditableCell';

const LabelEditableCell = ({
  label,
  ...editableCellProps
}) => {
  return (
    <div className='act'>
      
      {/* <span className='text-start '>{label}</span> */}
      <NumberEditableCell
        label={label}
        {...editableCellProps}
        className='mr-8'
      />
    </div>
  );
};

export default LabelEditableCell;
