import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";

const fetchProfile = () => api.live.get("/profile");

export const useFetchProfile = (
	{ onSuccess, onError }
) => {
	return useQuery(
		"fetchProfile",
		fetchProfile,
		{
			onSuccess,
			onError,
			select: (data) => data.data,
			retry: false,
			refetchOnWindowFocus: false
		}
	);
}

const fetchQrCode = () => api.live.get("/two_factor");

export const useFetchQrCode = (
	{ onSuccess, onError }
) => {
	return useQuery(
		"fetchQrCode",
		fetchQrCode,
		{
			onSuccess,
			onError,
			select: (data) => data.data,
			retry: false,
			refetchOnWindowFocus: false
		}
	);
}

const updatePassword = (data) => api.live.post("/password", data);

export const useUpdatePassword = (
	{ onSuccess, onError }
) => {
	return useMutation(
		"updatePassword",
		updatePassword,
		{
			onSuccess,
			onError,
			retry: false
		}
	);
}

const enableTwoFactorAuthentication = (data) => api.live.post("/two_factor/enable", data);

export const useEnableTwoFactorAuthentication = (
	{ onSuccess, onError }
) => {
	return useMutation(
		"enableTwoFactorAuthentication",
		enableTwoFactorAuthentication,
		{
			onSuccess,
			onError,
			retry: false
		}
	);
}

const disableTwoFactorAuthentication = (data) => api.live.post("/two_factor/disable", data);

export const useDisableTwoFactorAuthentication = (
	{ onSuccess, onError }
) => {
	return useMutation(
		"disableTwoFactorAuthentication",
		disableTwoFactorAuthentication,
		{
			onSuccess,
			onError,
			retry: false
		}
	);
}
