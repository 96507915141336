import BN from "bignumber.js";
import { currencyPrettier, fiatCurrencies, currencySymbol } from "./string";
/**
 * Will return a simple price description for the provided price object.
 * @param {Object} price A price object.
 * @returns {String} A description of the price
 */
export function simplePriceDescription(price) {
	let description;

	if (price.billing_scheme === 'tiered') {
		const firstTier = price.tiers[0];
		// TODO: format unit_amount_decimal and flat_amount_decimal;
		description = `Starts at ${firstTier.unit_amount_decimal ? [currencyPrettier(price.currency, firstTier.unit_amount_decimal) + (firstTier.flat_amount_decimal ? [' + ' + currencyPrettier(price.currency, firstTier.flat_amount_decimal)] : '')] : currencyPrettier(price.currency, firstTier.flat_amount_decimal)}`;
	
	} else {
		
		if (price.custom_unit_amount) {
			// It is a Customer Chooses Amount price:
			description = `Customer chooses amount (${price.currency.toUpperCase()})`;

		} else {
			// TODO: format unit_amount_decimal;
			description = `${currencyPrettier(price.currency, price.unit_amount_decimal)}${price.transform_quantity ? [' per group of ' + price.transform_quantity.divide_by] : ''}`;
		}
	}

	if (price.type === 'recurring') {
		description = `${description} ${price.recurring.interval_count > 1 ? ['every ' + price.recurring.interval_count + ' ' + price.recurring.interval + 's'] : ['/ ' + price.recurring.interval]}`;
	}

	return description;
}

export function getTieredTableData(price) {
	var tiers = price.tiers;
	var Tabledata = [];
	for (var i = 0; i < tiers.length; i++) {
		var tier = tiers[i];
		var newTableData = {
			quantity: "",
			unitAmount: "",
			flatAmount: ""
		};
		newTableData.unitAmount = tier.unit_amount ? currencyPrettier(price.currency, tier.unit_amount) :"-";
		newTableData.flatAmount = tier.flat_amount ? currencyPrettier(price.currency, tier.flat_amount) : "-";
		if (i === 0) {
			if (tier.up_to === 1) newTableData.quantity = "1"; 
			else newTableData.quantity = `1 to ${tier.up_to}`;
		}
		else if (i === tiers.length - 1) newTableData.quantity = `${tiers[i - 1].up_to + 1} and above`;
		else
		{
			if (tier.up_to === tiers[i - 1].up_to + 1) newTableData.quantity =`${tier.up_to}`;
			else newTableData.quantity = `${tiers[i - 1].up_to + 1} to ${tier.up_to}`;
		}
		Tabledata.push(newTableData);

	}
	return Tabledata;
	
}

export function dropdownPriceDescription(price) {
	let description;

	if (price.billing_scheme === 'tiered') {
		const firstTier = price.tiers[0];

		description = `Starts at ${firstTier.unit_amount_decimal ? [currencyPrettier(price.currency, firstTier.unit_amount_decimal) + (firstTier.flat_amount_decimal ? [' + ' + currencyPrettier(price.currency, firstTier.flat_amount_decimal)] : '')] : currencyPrettier(price.currency, firstTier.flat_amount_decimal)}`;
	
	} else {
		
		if (price.custom_unit_amount) {
			// It is a Customer Chooses Amount price:
			if (typeof price.custom_unit_amount.preset === 'number') {
				description = `${currencyPrettier(price.currency, price.custom_unit_amount.preset)} (Preset)`;
			} else {
				description = `Customer chooses (${price.currency.toUpperCase()})`;
			}

		} else {
			description = `${currencyPrettier(price.currency, price.unit_amount_decimal)}${price.transform_quantity ? [' per ' + price.transform_quantity.divide_by] : ''}`;
		}
	}

	if (price.type === 'recurring') {
		description = `${description} ${price.recurring.interval_count > 1 ? ['every ' + price.recurring.interval_count + ' ' + price.recurring.interval + 's'] : ['/ ' + price.recurring.interval]}`;
	}

	return description;
}


/**
 * Will return price description for the provided EXISTING price object.
 * @param {Object} price A price object.
 * @returns {Object} Containing:
 * 	* `description` - String - The displayable description.
 * 	* `metered` - Boolean - If the price is a metered price (to display metered icon).
 *  * `no_preset` - Boolean - If the price is a Customer Chooses Amount type, if a preset value has been set.
 * If `no_preset=true`, then display 'Customer chooses amount (price.currency)' with the `description` below it.
 */
 export function priceDescription(price) {
	let description;
	let noPreset = false;
	let metered = false;

	if (price.billing_scheme === 'tiered') {
		const firstTier = price.tiers[0];
		// TODO: format unit_amount_decimal and flat_amount_decimal;
		description = `Starts at ${firstTier ? (firstTier.unit_amount_decimal ? [currencyPrettier(price.currency, firstTier.unit_amount_decimal) + (firstTier.flat_amount_decimal ? [' + ' + currencyPrettier(price.currency, firstTier.flat_amount_decimal)] : '')] : currencyPrettier(price.currency, firstTier.flat_amount_decimal)) : currencyPrettier(price.currency, "0")}`;
	
	} else {
		
		if (price.custom_unit_amount) {
			// It is a Customer Chooses Amount price:
			if (typeof price.custom_unit_amount.preset === 'number') {
				// TODO: format preset;
				description = `${currencyPrettier(price.currency, price.custom_unit_amount.preset)} (Preset)`;
			} else {
				description = `Customer chooses amount (${price.currency.toUpperCase()})`;
				noPreset = true;
			}
		} else {
			// TODO: format unit_amount_decimal;
			description = `${currencyPrettier(price.currency, price.unit_amount_decimal)}${price.transform_quantity ? [' per group of ' + price.transform_quantity.divide_by] : ''}`;
		}
	}

	if (price.type === 'recurring') {
		description = `${description} / ${price.recurring?.interval_count > 1 ? [price.recurring?.interval_count + '' + price.recurring?.interval + 's'] : price.recurring?.interval}`;
	}

	return {
		description,
		metered,
		no_preset: noPreset,
	}
}

/**
 * Will return price description for prices that are being created in the Create Product modal.
 * @param {Object} price A price object.
 * @returns {Object} Containing:
 * 	* `description` - String - The displayable description.
 * 	* `metered` - Boolean - If the price is a metered price (to display metered icon).
 *  * `no_preset` - Boolean - If the price is a Customer Chooses Amount type, if a preset value has been set.
 * If `no_preset=true`, then display 'Customer chooses amount (price.currency)' with the `description` below it.
 */
export function newPriceDescription(price) {
	let description;

	if (price.mode?.id === 'tiered_pricing') {
		const firstTier = price.tiers[0];
		// TODO: format unit_amount_decimal and flat_amount_decimal;
		description = `Starts at ${firstTier?.unit_amount ? [currencySymbol(price.currency) + firstTier?.unit_amount] : currencyPrettier(price.currency, '0')}${firstTier?.flat_amount ? [' + ' + currencySymbol(price.currency) + firstTier?.flat_amount] : ''}`;
	
	} else {
		
		if (price.mode?.id === 'customer_chooses') {
			// It is a Customer Chooses Amount price:
			if (typeof price.custom_unit_amount.preset === 'number') {
				// TODO: format preset;
				description = `${currencyPrettier(price.currency, price.custom_unit_amount.preset)} (Preset)`;
			} else {
				description = `Customer chooses amount (${price.currency.toUpperCase()})`;
			}
		} else {
			// TODO: format unit_amount_decimal;
			description = `${price.amount?.length > 0 ? [currencySymbol(price.currency) + price.amount] : currencyPrettier(price.currency, '0')}${price.mode?.id === 'package_pricing' ? [' per group of ' + price.transform_quantity.divide_by] : ''}`;
		}
	}

	if (price.type === 'recurring') {
		description = `${description} / ${price.recurring?.interval_count > 1 ? [price.recurring?.interval_count + '' + price.recurring?.interval + 's'] : price.recurring?.interval}`;
	}

	return description;
}

/**
 * Will return an array of billable values calculated from a price's tiers based on the supplied quantity.
 * This includes both flat fee amounts and unit amounts.
 * @param {Array} priceTiers An array of tiering values. At least 2 should be provided.
 * @param {String} tierMode The tier mode to calculate from. One of `volume` or `graduated`
 * @param {Number} quantity The quantity to calculate the amount for.
 * @returns {Object} Containing:
 * 	* `amount` - Integer String - The total billable amount.
 * 	* `tiers` - Array - A break down of each tier that contributed to the total amount.
 */
// export function PreviewTiers(priceTiers = [], tierMode = 'volume', quantity = 0) {
// 	try {
// 		if (priceTiers.length < 1) throw new Error('Provide atleast two valid pricing tiers');
// 		let itemTotal = '0';
// 		let tiers = [];

// 		// Handle volume type tier:
// 		// In this method, we loop each tier to find the one that is valid;
// 		// When found, we calculate amount for item based on the valid tier's data;
// 		if (tierMode === 'volume') {
// 			for (let i = 0; i < priceTiers.length; i++) {
// 				let tierValid = false;
// 				if (typeof priceTiers[i].up_to === 'number') {
// 					// Check if we meet this tier:
// 					if (quantity <= priceTiers[i].up_to) tierValid = true;
// 				} else {
// 					// This is the highest tier and open ended quantity limit;
// 					tierValid = true;
// 				}

// 				if (tierValid) {
// 					// Unit amount for tier check:
// 					let tierAmount = '0';
// 					let unitAmount;
// 					let flatAmount;
// 					if (priceTiers[i].unit_amount_decimal) {
// 						tierAmount = BN(tierAmount).plus(BN(priceTiers[i].unit_amount_decimal).times(quantity).toFixed(0)).toFixed(0).toString();
// 						unitAmount = priceTiers[i].unit_amount_decimal;
// 					}
// 					// Flat fee for tier check:
// 					if (priceTiers[i].flat_amount_decimal) {
// 						tierAmount = BN(tierAmount).plus(priceTiers[i].flat_amount_decimal).toFixed(0).toString();
// 						flatAmount = priceTiers[i].flat_amount_decimal;
// 					}

// 					itemTotal = tierAmount;

// 					tiers.push({
// 						flat_amount: flatAmount ? flatAmount : null,
// 						quantity,
// 						tier: i + 1,
// 						tier_total: tierAmount,
// 						unit_amount: unitAmount ? unitAmount : null,
// 					});
// 					// break the loop
// 					break;
// 				}
// 			}
// 		}

// 		// Handle graduated type tier:
// 		// In this method, every tier is valid.
// 		// we deduct the quantity used in each period from 'quantityRemaining' after each tier.
// 		// if quantityRemaining = 0, then we break the loop;
// 		if (tierMode === 'graduated') {
// 			let quantityRemaining = quantity;
// 			let previousTier;
// 			for (let i = 0; i < priceTiers.length; i++) {
// 				let tierQuantity = 0; // the quantity to use to calculate the tiers amount

// 				// 1. Work out what the quantity value to use for this tier; 
// 				if (typeof priceTiers[i].up_to === 'number') {
// 					// check if the quantity is greater than the tiers 'up_to' value
// 					if (quantity > priceTiers[i].up_to) {
// 						// The quantity we use for this tier is 'tier.up_to' value;
// 						tierQuantity = Math.min(quantityRemaining, (previousTier ? (priceTiers[i].up_to - previousTier.up_to) : priceTiers.tiers[i].up_to));
// 					} else {
// 						// The quantity is NOT greater than the tiers 'up_to' value so must be <= to it;
// 						// quantity is the smallest of 'quantityRemaining' or 'tier.up_to';
// 						tierQuantity = Math.min(quantityRemaining, priceTiers[i].up_to);
// 					}
// 				} else {
// 					// This is an open ended tier (the last tier available);
// 					// meaning that any quantity left, will be billed at this amount;
// 					tierQuantity = quantityRemaining;
// 				}

// 				// 2. Unit amount for tier check:
// 				let tierAmount = '0';
// 				let unitAmount;
// 				let flatAmount;
// 				if (priceTiers[i].unit_amount_decimal) {
// 					tierAmount = BN(tierAmount).plus(BN(priceTiers[i].unit_amount_decimal).times(tierQuantity).toFixed(0)).toFixed(0).toString();
// 					unitAmount = priceTiers[i].unit_amount_decimal;
// 				}
// 				// 3. Flat fee for tier check:
// 				if (priceTiers[i].flat_amount_decimal) {
// 					tierAmount = BN(tierAmount).plus(priceTiers[i].flat_amount_decimal).toFixed(0).toString();
// 					flatAmount = priceTiers[i].flat_amount_decimal;
// 				}

// 				itemTotal = tierAmount;

// 				tiers.push({
// 					amount: tierAmount,
// 					flat_amount: flatAmount ? flatAmount : null,
// 					quantity: tierQuantity,
// 					tier: i + 1,
// 					tier_label: previousTier ? `Next ${tierQuantity}` : `First ${tierQuantity}`,
// 					unit_amount: unitAmount ? unitAmount : null,
// 				});

// 				// 4. Reduce quantity remaining by what was consumed in this tier;
// 				quantityRemaining -= tierQuantity;
				
// 				// 5. If no quantity is remaining, then break the loop to end the calculations;
// 				// otherwise we continue on to the next tier (if there is one);
// 				if (quantityRemaining < 1) break;
// 				else previousTier = priceTiers[i]; // set previous tier for the next iteration:
// 			}
// 		}

// 		return {amount: itemTotal, tiers};
// 	} catch (e) {
// 		throw e;
// 	}
// }
export function PreviewTiers(priceTiers = [], tierMode = 'volume', quantity = 0, currency = 'usd') {
	try {
		if (priceTiers.length < 1) throw new Error('Provide atleast two valid pricing tiers');
		let itemTotal = '0';
		let tiers = [];

		const fixValue = fiatCurrencies[currency].zero_decimal ? 0 : 2;

		// Handle volume type tier:
		// In this method, we loop each tier to find the one that is valid;
		// When found, we calculate amount for item based on the valid tier's data;
		if (tierMode === 'volume') {
			for (let i = 0; i < priceTiers.length; i++) {
				let tierValid = false;
				if (priceTiers[i].last_unit !== 'inf') {
					// Check if we meet this tier:
					if (quantity <= priceTiers[i].last_unit) {
						tierValid = true;
					}

				} else {
					// This is the highest tier and open ended quantity limit;
					tierValid = true;
				}

				if (tierValid) {
					// Unit amount for tier check:
					let tierAmount = '0';
					let unitAmount;
					let flatAmount;
					if (priceTiers[i].unit_amount) {
						tierAmount = BN(tierAmount).plus(BN(priceTiers[i].unit_amount).times(quantity)).toFixed(fixValue).toString();
						unitAmount = priceTiers[i].unit_amount;
					}
					// Flat fee for tier check:
					if (priceTiers[i].flat_amount) {
						tierAmount = BN(tierAmount).plus(priceTiers[i].flat_amount).toFixed(fixValue).toString();
						flatAmount = priceTiers[i].flat_amount;
					}

					itemTotal = tierAmount;

					tiers.push({
						flat_amount: flatAmount ? flatAmount : null,
						quantity,
						tier: i + 1,
						tier_total: tierAmount,
						unit_amount: unitAmount ? unitAmount : null,
					});

					// break the loop
					break;
				}
			}
		}

		// Handle graduated type tier:
		// In this method, every tier is valid.
		// we deduct the quantity used in each period from 'quantityRemaining' after each tier.
		// if quantityRemaining = 0, then we break the loop;
		if (tierMode === 'graduated') {
			let quantityRemaining = quantity;
			let previousTier;
			for (let i = 0; i < priceTiers.length; i++) {
				let tierQuantity = 0; // the quantity to use to calculate the tiers amount

				// 1. Work out what the quantity value to use for this tier; 
				if (priceTiers[i].last_unit !== 'inf') {
					// check if the quantity is greater than the tiers 'last_unit' value
					if (quantity > priceTiers[i].last_unit) {
						// The quantity we use for this tier is 'tier.last_unit' value;
						tierQuantity = Math.min(quantityRemaining, (previousTier ? (priceTiers[i].last_unit - previousTier.last_unit) : priceTiers[i].last_unit));
					} else {
						// The quantity is NOT greater than the tiers 'last_unit' value so must be <= to it;
						// quantity is the smallest of 'quantityRemaining' or 'tier.last_unit';
						tierQuantity = Math.min(quantityRemaining, priceTiers[i].last_unit);
					}
				} else {
					// This is an open ended tier (the last tier available);
					// meaning that any quantity left, will be billed at this amount;
					tierQuantity = quantityRemaining;
				}

				// 2. Unit amount for tier check:
				let tierAmount = '0';
				let unitAmount;
				let flatAmount;
				if (priceTiers[i].unit_amount) {
					tierAmount = BN(tierAmount).plus(BN(priceTiers[i].unit_amount).times(tierQuantity)).toFixed(fixValue).toString();
					unitAmount = priceTiers[i].unit_amount;
				}
				// 3. Flat fee for tier check:
				if (priceTiers[i].flat_amount) {
					tierAmount = BN(tierAmount).plus(priceTiers[i].flat_amount).toFixed(fixValue).toString();
					flatAmount = priceTiers[i].flat_amount;
				}

				itemTotal = tierAmount;

				tiers.push({
					amount: tierAmount,
					flat_amount: flatAmount ? flatAmount : null,
					quantity: tierQuantity,
					tier: i + 1,
					tier_label: previousTier ? `Next ${tierQuantity}` : `First ${tierQuantity}`,
					unit_amount: unitAmount ? unitAmount : null,
				});

				// 4. Reduce quantity remaining by what was consumed in this tier;
				quantityRemaining -= tierQuantity;
				
				// 5. If no quantity is remaining, then break the loop to end the calculations;
				// otherwise we continue on to the next tier (if there is one);
				if (quantityRemaining < 1) break;
				else previousTier = priceTiers[i]; // set previous tier for the next iteration:
			}
		}

		return {amount: itemTotal, tiers};
	} catch (e) {
		throw e;
	}
}

/**
 * Will return an array of tier object values from an existing Price's tiers to display in the Tiers table.
 * @param {Array} priceTiers An array of tiering values from the existing Price object.
 * @param {String} tierMode The tier mode to calculate from, found on the `tier_mode` attribute of the Price object
 * @returns {Array}
 */
export function createTiersData(priceTiers = [], tierMode = 'volume') {
	let tiers = [];

	for (let i = 0; i < priceTiers.length; i++) {
		let tierObj = {
			flat_amount: priceTiers[i].flat_amount_decimal || null,
			quantity: {
				first_unit: priceTiers[i + 1] ? priceTiers[i + 1].up_to + 1 : (tierMode === 'graduated' ? 0 : 1),
				last_unit: priceTiers[i].up_to,
			},
			unit_amount: priceTiers[i].unit_amount_decimal || null,
		};
		
		tiers.push(tierObj);
	}

	return tiers;
}

// ------- Calculation Helpers: -------- //

/**
 * Will return a description for each tier for line items.
 * @param {Object} price A price object
 * @param {Object} product A product object
 * @param {Number} quantity The quantity of an item. Ensure any transformations have been handled
 * @param {Number} tierAmount The calculated amount for the item in the tier (before discounts and tax)
 * @param {Number} tierNumber The tiers number
 * @param {Boolean} flatFee If the description is for a flat fee tier amount
 * @example 
 * '[quantity] [productLabel?] x [product.name] (Tier [tierNumber] at £1.50 / month)'
 * eg: '5 users x Email Account (Tier 1 at £10.00 / month)'
 * 
 * Or for flat fees:
 * '[product.name] (Tier [tierNumber] at £1.50 / month)'
 * e.g: 'Email Account (Tier 1 at $5.00 / month)'
 * @returns {String} A description of the items billable value for a tiered price;
 */
const tierLineItemDescription = (price, product, quantity = 0, tierAmount, tierNumber, flatFee = false) => {
	const customLabel = product?.unit_label ? product.unit_label : null;
	const recurringPrice = price.type === 'recurring' ? true : false;

	const quantityAndName = flatFee ? (product?.name ?? '') : `${quantity + ' ' + (customLabel ? [customLabel + ' x ']: 'x ')}${product?.name ?? ''}`;
	const priceStr = currencyPrettier(price.currency, tierAmount);
	const label = recurringPrice ? ` / ${price.recurring.interval_count < 2 ? price.recurring.interval : [price.recurring.interval_count + ' ' + price.recurring.interval + 's']}` : null;
	return `${quantityAndName} (Tier ${tierNumber} at ${priceStr}${flatFee ? '' : ' per unit'}${label ? label : ''})`;
};

/**
 * Will return an array of billable values calculated from a price's tiers based on the supplied quantity.
 * This includes both flat fee amounts and unit amounts.
 * @param {Object} price A price object
 * @param {Object} product A product object
 * @param {Number} quantity The quantity of an item. Ensure any transformations have been handled
 * @returns {Array} An array of billable values from an items tiers;
 */
export function calculateTiers (price = null, product = null, quantity = 0) {
	try {
		if (!price) throw new Error('calculateTiers: Price is required');
		
		let tiers = [];

		// Handle volume type tier:
		// In this method, we loop each tier to find the one that is valid;
		// When found, we calculate amount for item based on the valid tier's data;
		if (price.tiers_mode === 'volume') {
			for (let i = 0; i < price.tiers.length; i++) {
				let tierValid = false;
				if (typeof price.tiers[i].up_to === 'number') {
					// Check if we meet this tier:
					if (quantity <= price.tiers[i].up_to) tierValid = true;
				} else {
					// This is the highest tier and open ended quantity limit;
					tierValid = true;
				}

				if (tierValid) {
					// Unit amount for tier check:
					if (price.tiers[i].unit_amount_decimal) {
						// push data to tiers array:
						tiers.push({
							amount: Number(BN(price.tiers[i].unit_amount_decimal).times(quantity).toFixed(0).toString()),
							description: tierLineItemDescription(price, product, quantity, price.tiers[i].unit_amount_decimal, i + 1),
							tier: i + 1,
							tier_label: 'Unit Amount',
							quantity,
							unit_amount: typeof price.tiers[i].unit_amount === 'number' ? price.tiers[i].unit_amount : null,
							unit_amount_decimal: price.tiers[i].unit_amount_decimal,
						});
					}
					// Flat fee for tier check:
					if (price.tiers[i].flat_amount_decimal) {
						const flatFee = price.tiers[i].flat_amount_decimal;

						// push data to tiers array:
						tiers.push({
							amount: Number(BN(flatFee).toFixed(0).toString()),
							description: tierLineItemDescription(price, product, 0, flatFee, i + 1, true),
							tier: i + 1,
							tier_label: 'Flat fee',
							quantity: 0,
						});
					}
					// break the loop
					break;
				}
			}
		}

		// Handle graduated type tier:
		// In this method, every tier is valid.
		// we deduct the quantity used in each period from 'quantityRemaining' after each tier.
		// if quantityRemaining = 0, then we break the loop;
		if (price.tiers_mode === 'graduated') {
			let quantityRemaining = quantity;
			let previousTier;
			for (let i = 0; i < price.tiers.length; i++) {
				let tierQuantity = 0; // the quantity to use to calculate the tiers amount

				// 1. Work out what the quantity value to use for this tier; 
				if (typeof price.tiers[i].up_to === 'number') {
					// check if the quantity is greater than the tiers 'up_to' value
					if (quantity > price.tiers[i].up_to) {
						// The quantity we use for this tier is 'tier.up_to' value;
						tierQuantity = Math.min(quantityRemaining, (previousTier ? (price.tiers[i].up_to - previousTier.up_to) : price.tiers[i].up_to));

					} else {
						// The quantity is NOT greater than the tiers 'up_to' value so must be <= to it;
						// quantity is the smallest of 'quantityRemaining' or 'tier.up_to';
						tierQuantity = Math.min(quantityRemaining, price.tiers[i].up_to);
					}
				} else {
					// This is an open ended tier (the last tier available);
					// meaning that any quantity left, will be billed at this amount;
					tierQuantity = quantityRemaining;
				}

				// 2. Unit amount for tier check:
				if (price.tiers[i].unit_amount_decimal) {					
					// push data to tiers array:
					tiers.push({
						amount: Number(BN(price.tiers[i].unit_amount_decimal).times(tierQuantity).toFixed(0).toString()),
						description: tierLineItemDescription(price, product, tierQuantity, price.tiers[i].unit_amount_decimal, i + 1),
						tier: i + 1,
						tier_label: previousTier ? (typeof price.tiers[i].up_to === 'number' ? `Next ${previousTier.up_to + 1} to ${price.tiers[i].up_to}` : `${previousTier.up_to + 1} and above`) : `First ${price.tiers[i].up_to}`,
						unit_amount: typeof price.tiers[i].unit_amount === 'number' ? price.tiers[i].unit_amount : null,
						unit_amount_decimal: price.tiers[i].unit_amount_decimal,
						quantity: tierQuantity,
					});
				}

				// 3. Flat fee for tier check:
				if (price.tiers[i].flat_amount_decimal) {
					const flatFee = price.tiers[i].flat_amount_decimal;

					// push data to tiers array:
					tiers.push({
						amount: Number(BN(flatFee).toFixed(0).toString()),
						description: tierLineItemDescription(price, product, 0, flatFee, i + 1, true),
						tier: i + 1,
						tier_label: previousTier ? (price.tiers[i].up_to ? `Flat fee for next ${previousTier.up_to + 1} to ${price.tiers[i].up_to}` : `Flat fee for ${previousTier.up_to + 1} and above`) : `Flat fee for first ${price.tiers[i].up_to}`,
						quantity: 0,
					});
				}

				// 4. Reduce quantity remaining by what was consumed in this tier;
				quantityRemaining -= tierQuantity;
				
				// 5. If no quantity is remaining, then break the loop to end the calculations;
				// otherwise we continue on to the next tier (if there is one);
				if (quantityRemaining < 1) break;
				else previousTier = price.tiers[i]; // set previous tier for the next iteration:
			}
		}

		return tiers;
	} catch (e) {
		throw e;
	}
}

/**
 * Will return an items computed amount based on the details provided. You can provide a price object or a custom unit amount.
 * @param {Object} price A price object. Can be null.
 * @param {Number} initialQuantity The items initial quantity.
 * @param {Number} customUnitAmount A custom unit amount if no `price` is provided.
 * @returns {Number} The calculated item's amount.
 */
export function calculateAmount(price = null, initialQuantity = 1, customUnitAmount = null) {
	try {
		if (!price && typeof customUnitAmount !== 'number') {
			throw new Error('price or customUnitAmount is required');
		}
		// Define the price's unit amount: (either customUnitAmount or price.amount);
		const unitAmount = typeof customUnitAmount === 'number' ? customUnitAmount.toString() : (price.unit_amount_decimal ? price.unit_amount_decimal : (typeof price.custom_unit_amount?.preset === 'number' ? price.custom_unit_amount.preset.toString() : (typeof price.custom_unit_amount.minimum === 'number' ? price.custom_unit_amount.minimum.toString() : '0')));
		let quantity = initialQuantity;
		let amount = '0';
		
		if (price) {
			// Handle any quantity transformations:
			if (price.transform_quantity) {
				if (price.transform_quantity.round === 'up') quantity = Math.ceil(quantity / price.transform_quantity.divide_by);
				else quantity = Math.floor(quantity / price.transform_quantity.divide_by); // round down;
			}

			// per item calculation:
			if (price.billing_scheme === 'per_unit') {
				amount = BN(unitAmount).times(quantity).toFixed(0).toString();
			}
			
			// tier based calculation:
			if (price.billing_scheme === 'tiered') {
				const tiers = calculateTiers(price, null, quantity);
				for (const tier of tiers) {
					amount = BN(amount).plus(BN(tier.amount)).toFixed(0).toString();
				}
			}
		} else {
			amount = BN(unitAmount).times(quantity).toFixed(0).toString();
		}
		

		return amount;
	} catch (e) {
		throw e;
	}
}

export function getBillingPeriod(price) {
	let billingPeriod = 'daily';
	if (price.recurring) {
		if (price.recurring.interval_count < 2) {
			if (price.recurring.interval === 'day') billingPeriod = 'daily';
			if (price.recurring.interval === 'week') billingPeriod = 'weekly';
			if (price.recurring.interval === 'month') billingPeriod = 'monthly';
			if (price.recurring.interval === 'year') billingPeriod = 'yearly';
		} else {
			if (price.recurring.interval === 'month' && price.recurring.interval_count === 3) billingPeriod = 'three_monthly';
			else if (price.recurring.interval === 'month' && price.recurring.interval_count === 6) billingPeriod = 'six_monthly';
			else billingPeriod = 'custom';
		}
	}
	return billingPeriod
}

export function validateTiers(tierData) {
  // Array containing all errors:
  let tierErrors = [];

  for (let i = 0; i < tierData.length; i++) {
    // 1. validate 'last_unit' is => 'first_unit'
    if (tierData[i].last_unit !== 'inf' &&
    tierData[i].last_unit < tierData[i].first_unit) {
        tierErrors.push({index: i, field: 'last_unit', message: `Must be greater than or equal to ${tierData[i].first_unit}`});
    }
    if (!tierData[i].unit_amount && !tierData[i].flat_amount) {
      tierErrors.push({index: i, field: 'unit_amount', message: `Requires at least one Per Unit or Flat Fee value`});
    }
  }

  return tierErrors;
};


// validate tier;