import Modal from "../../../../../Common/Modal/Modal";
import Button from "../../../../../Common/Form/Button/Button";
import Label from "../../../../../Common/Form/Label/Label";
import Stack from "../../../../../Common/Stack/Stack";
import CustomSelectDiscounts from "../../../../../Dashboard/CustomSelectDiscounts/CustomSelectDiscounts";
import CustomSelectTaxRates from "../../../../../Dashboard/CustomSelectTaxRates/CustomSelectTaxRates";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../Common/Form/Input/Input";
import InputPrice from "../../../../../Common/Form/InputPrice/InputPrice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FIAT_CURRENCIES_SELECT,
  ITEM_PRICE_REGEX,
} from "../../../../../../utils/constants";
import {
  currencyConverter,
  currencyFormatter,
  currencySymbol,
  sanitizeUnitAmount
} from "../../../../../../utils/string";
import { toast } from "react-toastify";
import {
  useFetchInvoiceItem,
  useStoreInvoiceItem,
  useUpdateInvoiceItem,
} from "../../../../../../api/customer/invoice";
import SelectProductsPrices from "../../../../../Dashboard/SelectProductsPrices/SelectProductsPrices";
import Select from "../../../../../Common/Form/Select/Select";
import { setRefetchPendingItems } from "../../../../../../store/invoice/invoiceSlice";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/icons/modal-close.svg";
import Alert from "../../../../../Common/Alert/Alert";
import { setSelectedPendingInvoiceItem } from "../../../../../../store/customer/customerSlice";

const formSchema = yup
  .object({
    product: yup.string().required().label("Product"),
    amount: yup
      .string()
      .required()
      .trim()
      .matches(
        ITEM_PRICE_REGEX,
        "Price must be greater than 0 and must not exceed 2 decimal places"
      )
      .label("Price"),
    quantity: yup
      .number()
      .required()
      .min(1)
      .label("Quantity")
      .typeError("Quantity is a required field"),
  })
  .required();

function CustomersModalCreateInvoiceItem({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { selected_customer, selected_pending_invoice_item } = useSelector(
    (state) => state.customer
  );
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(true);

  const { mutate: mutateStoreInvoiceItem, isLoading: isStoreLoading } =
    useStoreInvoiceItem();

  const { mutate: mutateUpdateInvoiceItem, isLoading: isUpdateLoading } =
    useUpdateInvoiceItem(selected_pending_invoice_item?.id);

  const onFetchPendingInvoiceItemsSuccess = (data) => {
    dispatch(setSelectedPendingInvoiceItem(data));
  };

  useFetchInvoiceItem({
    onSuccess: onFetchPendingInvoiceItemsSuccess,
    onError: () => null,
    enabled: !!selected_pending_invoice_item?.id,
    params: {
      id: selected_pending_invoice_item?.id,
      expand: ["product", "price", "discounts", "tax_rates"],
    },
  });

  const {
    reset,
    watch,
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      price: selected_pending_invoice_item?.price ?? null,
      product: selected_pending_invoice_item?.product?.id ?? "",
      quantity: selected_pending_invoice_item?.quantity ?? null,
      currency: selected_pending_invoice_item?.currency ?? null,
      amount: selected_pending_invoice_item?.amount
        ? currencyFormatter(
            selected_pending_invoice_item?.currency,
            selected_pending_invoice_item?.amount
          )
        : null,
      discount: selected_pending_invoice_item?.discounts.length
        ? selected_pending_invoice_item?.discounts[0]
        : null,
      tax_rates: selected_pending_invoice_item?.tax_rates.length
        ? selected_pending_invoice_item?.tax_rates 
        : [],
    },
    resolver: yupResolver(formSchema),
  });

  const product = watch("product");
  const price = watch("price");
  const discount = watch("discount");
  const tax_rates = watch("tax_rates");

  useEffect(() => {
    if (!selected_pending_invoice_item?.product?.id) return;

    setSelectedProduct(selected_pending_invoice_item?.product);
    setValue("product", selected_pending_invoice_item?.product?.id ?? "");
    setValue("price", selected_pending_invoice_item?.price);
    setValue("quantity", selected_pending_invoice_item?.quantity);
    setValue("currency", selected_pending_invoice_item?.currency);
    setValue(
      "amount",
      selected_pending_invoice_item?.amount
        ? currencyFormatter(
            selected_pending_invoice_item?.currency,
            selected_pending_invoice_item?.amount
          )
        : null
    );
    setValue(
      "discount",
      selected_pending_invoice_item?.discounts.length
        ? selected_pending_invoice_item?.discounts[0]
        : null
    );
    setValue("tax_rates", selected_pending_invoice_item?.tax_rates ?? []);
  }, [selected_pending_invoice_item, setValue]);

  const handleClose = () => {
    onClose && onClose();
  };

  const getBalanceCurrency = useMemo(() => {
    return Object.keys(selected_customer.balance)[0] ?? "";
  }, [selected_customer]);

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const onSubmit = (formData) => {
    const _data = {
      quantity: formData.quantity,
    };

    _data.discounts = formData.discount
      ? [{ discount: formData.discount?.id }]
      : [];

    _data.tax_rates = formData.tax_rates.length
      ? formData.tax_rates.map((rate) => rate?.id)
      : [];

    if (selected_pending_invoice_item?.id) {
      mutateUpdateInvoiceItem(_data, {
        onSuccess: () => {
          dispatch(setRefetchPendingItems(Date.now()));
          toast.success("Invoice item updated successfully");
          handleClose();
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      });
      return;
    }

    _data.customer = selected_customer.id;

    if (formData.price) {
      _data.price = formData.price.id;
    } else {
      let priceData = {
        product: formData.product,
        currency: formData.currency,
      }
      const unitAmount = currencyConverter(
        formData.currency,
        parseInt(formData.amount, 10), true
      );
      if (unitAmount.includes('.')) {
        priceData.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
      } else priceData.unit_amount = Number(unitAmount);
  
      _data.price_data = priceData;
    }
    

    mutateStoreInvoiceItem(_data, {
      onSuccess: () => {
        reset();
        dispatch(setRefetchPendingItems(Date.now()));
        toast.success("Invoice item created successfully");
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleCloseClick = () => {
    onClose();
  };

  const isDisabled = useMemo(() => {
    return selected_pending_invoice_item?.proration;
  }, [selected_pending_invoice_item]);

  const isNonDiscountable = useMemo(() => {
    return (
      selected_pending_invoice_item &&
      !selected_pending_invoice_item?.discountable
    );
  }, [selected_pending_invoice_item]);

  const handleRemoveTaxRate = (tax) => {
    setValue(
      "tax_rates",
      tax_rates.filter((tax_rate) => tax_rate.id !== tax.id)
    );
  };

  return (
    <Modal show={show} onClose={handleClose} size="sm" overflowVisible>
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {selected_pending_invoice_item ? "Edit" : "Create"} Invoice Item
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        {isDisabled && (
          <Alert
            className="mb-4"
            type="info"
            message="This item is for a prorated amount and cannot be deleted"
          />
        )}
        <form id="create-invoice-item" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1" gap="4">
            <div>
              <Label title="Item" error={fieldError("product")} />
              <SelectProductsPrices
                placeholder="Select Item"
                value={price}
                product={selectedProduct}
                currency={getBalanceCurrency}
                type="one_time"
                onSelect={(product, price) => {
                  setSelectedProduct(product);
                  setValue("product", product?.id);
                  setValue("price", price);
                  setValue(
                    "amount",
                    currencyFormatter(getBalanceCurrency, price?.unit_amount_decimal)
                  );
                  setValue("currency", price?.currency);
                  trigger("product");
                  trigger("price");
                  trigger("amount");
                }}
                error={fieldError("product")}
                disabled={selected_pending_invoice_item}
              />
            </div>
            {product && (
              <Stack columns="2">
                <div>
                  <Label title="Quantity" error={fieldError("quantity")} />
                  <Input
                    {...register("quantity")}
                    block
                    type="number"
                    min="1"
                    error={fieldError("quantity")}
                    disabled={isDisabled}
                  />
                </div>
                <div>
                  <Label title="Price" error={fieldError("amount")} />
                  <InputPrice
                    {...register("amount")}
                    block
                    currencies={FIAT_CURRENCIES_SELECT}
                    onCurrencyChange={(e) => {
                      setValue("currency", e?.value);
                    }}
                    currency={getBalanceCurrency}
                    placeholder="0.00"
                    error={fieldError("amount")}
                    readOnly
                    disabled={selected_pending_invoice_item}
                  />
                </div>
              </Stack>
            )}
            <div>
              <Label title="Discount" />
              {discount && (
                <div>
                  <div className="app-invoices__modal__discount-tax mb-1">
                    <div>
                      <span className="mr-2">{discount.name}</span>
                      {discount.amount_off && (
                        <>
                          {currencySymbol(discount.currency)}{" "}
                          {currencyFormatter(
                            discount.currency,
                            discount.amount_off
                          )}
                        </>
                      )}
                      {discount.percent_off && <>{discount.percent_off}%</>}
                    </div>
                    <CloseIcon onClick={() => setValue("discount", null)} />
                  </div>
                </div>
              )}
              {!discount && (
                <div
                  data-tip={
                    isNonDiscountable ? "This item is not discountable" : ""
                  }
                >
                  <CustomSelectDiscounts
                    currency={getBalanceCurrency}
                    onSelect={(e) => {
                      setValue("discount", e);
                    }}
                    disabled={isDisabled || isNonDiscountable}
                  />
                </div>
              )}
            </div>
            <div>
              <Label title="Tax Rate" />
              <div>
                {tax_rates.map((tax, index) => (
                  <div
                    key={index}
                    className="app-invoices__modal__discount-tax mb-1"
                  >
                    <div>
                      <span className="mr-2">{tax.display_name}</span>(
                      {tax.percent}%)
                    </div>
                    <CloseIcon onClick={() => handleRemoveTaxRate(tax)} />
                  </div>
                ))}
              </div>
              {showTaxRatesSelect ? (
                <CustomSelectTaxRates
                  onSelect={(e) => {
                    setValue("tax_rates", [...tax_rates, e]);
                    setShowTaxRatesSelect(false);
                  }}
                  disabled={isDisabled}
                />
              ) : (
                <span
                  onClick={() => setShowTaxRatesSelect(true)}
                  className="app-invoices__modal__link"
                >
                  Add another tax rate
                </span>
              )}
            </div>
            <div>
              <Label title="Subscription" />
              <Select
                options={[
                  { label: "Auto (Next billed invoice)", value: "auto" },
                ]}
                value="auto"
                disabled={isDisabled}
              />
            </div>
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex gap-3 justify-end">
          {!selected_pending_invoice_item ? (
            <Button
              form="create-invoice-item"
              type="submit"
              loading={isStoreLoading}
            >
              Create
            </Button>
          ) : (
            <>
              <Button type="button" color="light" onClick={handleCloseClick}>
                Cancel
              </Button>
              <Button
                form="create-invoice-item"
                type="submit"
                loading={isUpdateLoading}
                disabled={isDisabled}
              >
                Edit
              </Button>
            </>
          )}
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default CustomersModalCreateInvoiceItem;
