import DashboardNavbar from "../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";

function Error() {
  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="flex items-center justify-center h-4/5">
          <div className="text-center">
            <p className="h1 mb-4">Section Not Available</p>
            <p className="h5 text-light">This section is under development and will be available soon =)</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error;