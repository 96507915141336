import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import ArrowDownIcon from "../../../../assets/images/icons/dashboard/arrow_down.svg";
import useOutsideClick from "../../../../hooks/useOutsideClick";

const DropdownFiltersFormSelect = ({
  options = [],
  onChange = null,
  className = "",
  error = "",
  value = null,
  iconPath = "",
  disabled = false,
  ...rest
}) => {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  useOutsideClick(selectRef, () => setOpen(false));

  const handleOptionClick = (option, index) => {
    setActiveIndex(index);
    setOpen(false);
    onChange && onChange(option?.value);
  };

  useEffect(() => {
    setActiveIndex(options.findIndex((option) => option.value === value));
  }, [value, options]);

  const getActiveIndex = useMemo(() => {
    if (activeIndex === -1) {
      return null;
    }
    return options.length && options[activeIndex];
  }, [options, activeIndex]);

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/${iconPath}/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  const handleOpenClick = () => {
    if (disabled) return;
    setOpen(!open);
  };

  return (
    <>
      <div
        ref={selectRef}
        className={clsx(className, "app-dropdown__filters__select")}
        // style={style}
      >
        <div
          onClick={handleOpenClick}
          className={clsx(
            "app-dropdown__filters__select__input",
            open && "app-dropdown__filters__select__input--active",
            disabled && "app-dropdown__filters__select__input--disabled"
            // borderLess && "app-dropdown__filters__select__input--borderless"
          )}
        >
          <div className="app-dropdown__filters__select__input__value">
            {getActiveIndex ? (
              <span>{getActiveIndex.label}</span>
            ) : (
              <span>{rest?.placeholder}</span>
            )}
          </div>
          <img
            className="app-dropdown__filters__select__input__arrow"
            src={ArrowDownIcon}
            alt=""
          />
        </div>
        {open && (
          <div className="app-dropdown__filters__select__options">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option, index)}
                className={clsx(
                  "app-dropdown__filters__select__options__item",
                  index === activeIndex &&
                    "app-dropdown__filters__select__options__item--active"
                )}
              >
                {option.icon && (
                  <img
                    className="app-dropdown__filters__select__options__item__icon"
                    src={getIconUrl(option.icon)}
                    alt={option.label}
                  />
                )}
                {option.name ? option.name : option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {/*<select
        ref={ref}
        className={clsx(
          "app-dropdown__filters__select",
          className,
        )}
        {...rest}
      >
        {options.map((option, index) =>
          <option key={index} value={option.value}>{option.label}</option>
        )}
      </select>*/}
      {error && <p className="app-input__help">{error}</p>}
    </>
  );
};

export default DropdownFiltersFormSelect;
