import Checkbox from "../../../Form/Checkbox/Checkbox";
import { useEffect, useMemo } from "react";
import clsx from "clsx";
import DropdownFiltersFormSelect from "../../DropdownFiltersForm/DropdownFiltersFormSelect";
import { ReactComponent as ArrowIcon } from "../../../../../assets/images/icons/dashboard/arrow-direction.svg";
import DropdownFiltersFormInput from "../../DropdownFiltersForm/DropdownFiltersFormInput";
import Radio from "../../../Form/Radio/Radio";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import DropdownFiltersFormDatePicker from "../../DropdownFiltersForm/DropdownFiltersFormDatePicker/DropdownFiltersFormDatePicker";
import { setFilters } from "../../../../../store/app/appSlice";

const DATE_OPTIONS = [
  { label: "in the last", value: "in_the_last" },
  { label: "is equal to", value: "is_equal_to" },
  { label: "is between", value: "is_between" },
  { label: "is after", value: "is_after" },
  { label: "is on or after", value: "is_on_or_after" },
  { label: "is before", value: "is_before" },
  { label: "is before or on", value: "is_before_or_on" },
];

function DropdownFiltersDate({
  form: {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
  },
  onToggle,
  state = false,
  placeholder = "Created Date",
  filter_field = "created_at",
}) {
  const dispatch = useDispatch();
  const { current_account } = useSelector((state) => state.auth);

  const date_option = watch(`${filter_field}_option`);
  const date_type = watch(`${filter_field}_type`);

  const getClientTimeZone = useMemo(() => {
    return moment().tz(current_account.timezone).zoneAbbr();
  }, [current_account]);

  useEffect(() => {
    dispatch(setFilters({ [`${filter_field}_option`]: "in_the_last" }));
    setValue(`${filter_field}_option`, "in_the_last");
  }, [dispatch, filter_field, setValue]);

  useEffect(() => {
    setValue(`${filter_field}_timezone`, getClientTimeZone);
  }, [setValue, getClientTimeZone, filter_field]);

  const handleOpen = () => {
    onToggle && onToggle(!state);
  };

  return (
    <div className="app-dropdown__filters__item__wrapper">
      <div onClick={handleOpen} className="app-dropdown__filters__item__toggle">
        <Checkbox
          color="primary"
          size="sm"
          checked={state}
          onChange={handleOpen}
        />
        <span className="text-small">{placeholder}</span>
      </div>
      {state && (
        <div
          className={clsx(
            "app-dropdown__filters__item__collapse",
            state && "app-dropdown__filters__item__collapse--open"
          )}
        >
          <div className="app-dropdown__filters__item__content">
            <DropdownFiltersFormSelect
              options={DATE_OPTIONS}
              onChange={(value) => {
                setValue(`${filter_field}_option`, value);
                trigger(`${filter_field}_option`);
                dispatch(setFilters({ [`${filter_field}_option`]: value }));
              }}
              value={date_option}
            />
            <div className="flex gap-2 mt-2">
              {date_option === "in_the_last" && (
                <>
                  <div className="app-dropdown__filters__item__arrow-direction">
                    <ArrowIcon />
                  </div>
                  <div>
                    <DropdownFiltersFormInput
                      type="number"
                      min="0"
                      {...register(`${filter_field}_number`)}
                      error={errors?.[`${filter_field}_number`]?.message}
                    />
                  </div>
                  <DropdownFiltersFormSelect
                    options={[
                      { label: "days", value: "days" },
                      { label: "months", value: "months" },
                    ]}
                    onChange={(value) => {
                      setValue(`${filter_field}_type`, value);
                      trigger(`${filter_field}_type`);
                    }}
                    value={date_type}
                  />
                </>
              )}
              {date_option === "is_equal_to" && (
                <>
                  <div className="app-dropdown__filters__item__arrow-direction">
                    <ArrowIcon />
                  </div>
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_start_date`, date);
                      trigger(`${filter_field}_start_date`);
                    }}
                    error={errors?.[`${filter_field}_start_date`]?.message}
                  />
                </>
              )}
              {date_option === "is_between" && (
                <>
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_start_date`, date);
                      trigger(`${filter_field}_start_date`);
                    }}
                    error={errors?.[`${filter_field}_start_date`]?.message}
                  />
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_end_date`, date);
                      trigger(`${filter_field}_end_date`);
                    }}
                    error={errors?.[`${filter_field}_end_date`]?.message}
                  />
                </>
              )}
              {date_option === "is_after" && (
                <>
                  <div className="app-dropdown__filters__item__arrow-direction">
                    <ArrowIcon />
                  </div>
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_start_date`, date);
                      trigger(`${filter_field}_start_date`);
                    }}
                    error={errors?.[`${filter_field}_start_date`]?.message}
                  />
                </>
              )}
              {date_option === "is_on_or_after" && (
                <>
                  <div className="app-dropdown__filters__item__arrow-direction">
                    <ArrowIcon />
                  </div>
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_start_date`, date);
                      trigger(`${filter_field}_start_date`);
                    }}
                    error={errors?.[`${filter_field}_start_date`]?.message}
                  />
                </>
              )}
              {date_option === "is_before" && (
                <>
                  <div className="app-dropdown__filters__item__arrow-direction">
                    <ArrowIcon />
                  </div>
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_start_date`, date);
                      trigger(`${filter_field}_start_date`);
                    }}
                    error={errors?.[`${filter_field}_start_date`]?.message}
                  />
                </>
              )}
              {date_option === "is_before_or_on" && (
                <>
                  <div className="app-dropdown__filters__item__arrow-direction">
                    <ArrowIcon />
                  </div>
                  <DropdownFiltersFormDatePicker
                    onChange={(date) => {
                      setValue(`${filter_field}_start_date`, date);
                      trigger(`${filter_field}_start_date`);
                    }}
                    error={errors?.[`${filter_field}_start_date`]?.message}
                  />
                </>
              )}
            </div>
            <div className="flex items-end gap-3 mt-2">
              <span>Time zone:</span>
              <Radio
                color="primary"
                value={getClientTimeZone}
                label={getClientTimeZone}
                className="flex items-center cursor-pointer"
                {...register(`${filter_field}_timezone`)}
              />
              <Radio
                color="primary"
                value="UTC"
                label="UTC"
                className="flex items-center cursor-pointer"
                {...register(`${filter_field}_timezone`)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownFiltersDate;
