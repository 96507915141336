import ModalFullScreen from "components/Common/ModalFullScreen/ModalFullScreen";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LabelTag from "components/Common/LabelTag/LabelTag";
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import { ReactComponent as EditIcon } from 'assets/images/icons/dashboard/edit.svg';
import ArrowIcon from 'assets/images/icons/arrow-right-dark.png';
import { toast } from "react-toastify";
import {
    capitalize,
    cryptocurrencyFormatter,
    currencyFormatter,
    currencyPrettier,
    currencySymbol,
} from "utils/string";
import clsx from "clsx";
import moment from "moment";
import { dateFormatter } from "utils/date";
// import MetadataForm from "../../../../../../Dashboard/MetadataForm/MetadataForm";

// import ModalDelete from "../../../../../../Dashboard/Modals/ModalDelete/ModalDelete";

// import InvoiceDetailsButtons from "./InvoiceDetailsButtons/InvoiceDetailsButtons";
// import InvoiceDetailsEventTable from "./InvoiceDetails/InvoiceDetailsEventsTable";
// import InvoiceDetailsLogsTable from "./InvoiceDetails/InvoiceDetailsLogsTable";
// import InvoiceDetailsPaymentsTable from "./InvoiceDetails/InvoiceDetailsPaymentsTable";
// import InvoiceDetailsCreditNotesTable from "./InvoiceDetails/InvoiceDetailsCreditNotesTable";
// import InvoiceDetailsNotes from "./InvoiceDetails/InvoiceDetailsNotes";
// import InvoiceDetailsItems from "./InvoiceDetails/InvoiceDetailsItems";
import CopyToClipboardBoxID from "components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID";
import { Link } from "react-router-dom";
import NotesModule from 'components/Dashboard/Notes/Notes';
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import MetadataForm from "components/Dashboard/MetadataForm/MetadataForm";
import Input from "components/Common/Form/Input/Input";

function SwapDetails({ show = false, onClose = null }) {
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const { selected_swap } = useSelector((state) => state.swap);

    useEffect(() => {
        console.log("selected_swap", selected_swap);
    }, [])

    const handleClose = () => {
        onClose && onClose();
    };
    const handleEditClick = () => {
        setEdit(!edit);
    };

    const getIconUrl = (icon) => {
        try {
            return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
        } catch (err) {
            return null;
        }
    };
    const getIconBrandUrl = (icon) => {
        try {
            return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
        } catch (err) {
            return null;
        }
    };
    return (
        <>
            <ModalFullScreen show={show} onClose={handleClose}>
                <ModalFullScreen.Slot name="header">
                    <div className="app-customers__modal__header">
                        <div className="app-modal-fullscreen__header__title">swap</div>
                        <CopyToClipboardBoxID text={selected_swap?.id} />
                    </div>
                </ModalFullScreen.Slot>
                <ModalFullScreen.Slot name="body">
                    <div className="space-y-7">
                        <div className="space-y-1">
                            <div style={{ padding: "10px 0" }} className="flex items-start gap-3">

                                <img style={{ width: "40px", height: "40px" }} alt={selected_swap?.crypto_currency} src={getIconUrl(selected_swap?.crypto_currency)} />

                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} className="app-customers__modal__balance">
                                    <span style={{ lineHeight: "1em", fontSize: "40px" }} className="text-light font-semibold">
                                        {cryptocurrencyFormatter(selected_swap?.crypto_amount)}

                                    </span>
                                    <span style={{ fontSize: "15px" }} className="text-light">
                                        ( {currencyPrettier(selected_swap?.balance_transaction.currency, selected_swap?.balance_transaction.amount)})
                                    </span>
                                </div>
                                <span className="text-light ">{selected_swap.crypto_currency.toUpperCase()}</span>
                                <img src={ArrowIcon}
                                    alt="arrow"
                                    className="self-center"
                                    style={{ margin: "0px 40px" }}
                                />
                                <img style={{ width: "40px", height: "40px" }} alt={selected_swap?.target_crypto_currency} src={getIconUrl(selected_swap?.target_crypto_currency)} />

                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} className="app-customers__modal__balance">
                                    <span style={{ lineHeight: "1em", fontSize: "40px" }} className="text-light font-semibold">
                                        {cryptocurrencyFormatter(selected_swap?.target_crypto_amount)}

                                    </span>
                                    <span style={{ fontSize: "15px" }} className="text-light">
                                        ( {currencyPrettier(selected_swap?.balance_transaction.currency, selected_swap?.balance_transaction.amount)})
                                    </span>
                                </div>
                                <span className="text-light ">{selected_swap.target_crypto_currency.toUpperCase()}</span>
                                <div
                                    className="ml-3 text-primary text-sm font-semibold self-center"

                                >
                                    {selected_swap?.type.toUpperCase()}

                                </div>
                            </div>

                        </div>
                        <div className="horizontal-divider" />
                        <div className='flex flex-wrap gap-5 divide-x pb-7'>
                            <div className='pl-5 pr-20'>
                                <div className='app-modal-fullscreen__label'>
                                    DATE
                                </div>
                                <div className='flex items-center'>
                                    <DateIcon className='mr-1.5' />
                                    <span className='app-modal-fullscreen__value'>
                                        {dateFormatter(
                                            selected_swap.created_at,
                                            true
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className='pl-5 pr-20'>
                                <div className='app-modal-fullscreen__label'>
                                    RATE
                                </div>
                                <div className='app-modal-fullscreen__value'>
                                    <span className='text-dark font-semibold'  >
                                        {selected_swap?.exchange_rate}
                                    </span>
                                </div>
                            </div>
                            <div className='pl-5 flex'>
                                <div>
                                    <div className='app-modal-fullscreen__label'>
                                        FEE
                                    </div>
                                    <div className='app-modal-fullscreen__value'>
                                        <Amount
                                            crypto_amount={selected_swap.balance_transaction.crypto_fee}
                                            crypto_currency={selected_swap.balance_transaction.crypto_currency}
                                            amount={selected_swap.balance_transaction.amount}
                                            currency={selected_swap.balance_transaction.currency}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>




                        <div className='space-y-1.5'>
                            <div className='flex justify-between'>
                                <div className='app-modal-fullscreen__title'>
                                    Details
                                </div>
                            </div>
                            <div className='horizontal-divider' />
                            <div className='pt-4'>
                                <div className='grid grid-cols-5 gap-4'>
                                    <div className='col-span-2 space-y-2 gap-4'>

                                        <div className='app-modal-fullscreen__kv'>
                                            <div className='app-modal-fullscreen__kv__key'>
                                                Type
                                            </div>
                                            <div className='app-modal-fullscreen__kv__value'>
                                                {selected_swap?.type.toUpperCase()}
                                            </div>
                                        </div>
                                        <div className='app-modal-fullscreen__kv'>
                                            <div className='app-modal-fullscreen__kv__key'>
                                                Source
                                            </div>
                                            <div className='app-modal-fullscreen__kv__value'>
                                                Undefined // todo
                                            </div>
                                        </div>
                                        <div className='app-modal-fullscreen__kv'>
                                            <div className='app-modal-fullscreen__kv__key'>
                                                Amount (before fees)
                                            </div>
                                            <div className='app-modal-fullscreen__kv__value'>
                                                <Amount
                                                    crypto_amount={selected_swap?.crypto_amount}
                                                    crypto_currency={selected_swap?.crypto_currency}
                                                    amount={selected_swap?.amount}
                                                    currency={selected_swap?.balance_transaction.currency}
                                                />
                                            </div>
                                        </div>
                                        <div className='app-modal-fullscreen__kv'>
                                            <div className='app-modal-fullscreen__kv__key'>
                                                From
                                            </div>
                                            <div className='app-modal-fullscreen__kv__value flex'>
                                                <img
                                                    src={getIconUrl(selected_swap?.crypto_currency)}
                                                    alt={selected_swap?.crypto_currency}
                                                    className='mr-1.5 w-5 h-5'
                                                />
                                                <span className='text-light '>
                                                    {selected_swap?.crypto_currency.toUpperCase()}
                                                </span>

                                            </div>
                                        </div>
                                        <div className='app-modal-fullscreen__kv'>
                                            <div className='app-modal-fullscreen__kv__key'>
                                                TO
                                            </div>
                                            <div className='app-modal-fullscreen__kv__value flex align-center'>
                                                <img
                                                    src={getIconUrl(selected_swap?.target_crypto_currency)}
                                                    alt={selected_swap?.target_crypto_currency}
                                                    className='mr-1.5 w-5 h-5'
                                                />
                                                <span className='text-light '>
                                                    {selected_swap?.target_crypto_currency.toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='app-modal-fullscreen__title'>
                            Transactions
                        </div>

                        <div className='horizontal-divider' />
                        <div className='flex flex-wrap flex-col gap-5  pb-7'>
                            <div className='pl-5 pr-20'>
                                <div className='app-modal-fullscreen__label'>
                                    FROM TRANSACTION TODo
                                </div>
                                <div className='app-modal-fullscreen__value'>
                                    <CopyToClipboardBoxID text={selected_swap.balance_transaction?.tx_hash} />

                                </div>
                            </div>
                            <div className='pl-5 pr-20'>
                                <div className='app-modal-fullscreen__label'>
                                    TP TRANSACTIONTODo
                                </div>
                                <div className='app-modal-fullscreen__value'>
                                    <CopyToClipboardBoxID text={selected_swap.balance_transaction?.tx_hash} />

                                </div>
                            </div>
                        </div>
                        <div className='app-modal-fullscreen__title'>
                                Metadata
                        </div>

                        <div className='horizontal-divider' />
                        <MetadataForm metadata={selected_swap?.metadata} />
                    </div>

                </ModalFullScreen.Slot>
            </ModalFullScreen>
        </>
    );
}

export default SwapDetails;


export function Amount({ crypto_currency, crypto_amount, currency, amount }) {
    const getIconUrl = (icon) => {
        try {
            return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
        } catch (err) {
            return null;
        }
    };
    return (
        <div className="flex items-start gap-1">
            <img style={{ width: "15px", height: "15px" }} alt={crypto_currency} src={getIconUrl(crypto_currency)} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: "12px" }} className="app-customers__modal__balance">
                <span style={{ lineHeight: "1em", fontSize: "13px" }} className="text-light font-semibold">
                    {cryptocurrencyFormatter(crypto_amount)}
                    <span style={{ fontSize: "10px" }} className="text-light ">{crypto_currency.toUpperCase()}</span>
                </span>
                <span style={{ fontSize: "12px" }} className="text-light">
                    ( {currencyPrettier(currency, amount)})
                </span>
            </div>
        </div>
    )
}
