import api from '../../utils/axios';
import { useMutation, useQuery } from 'react-query';
import appStore from '../../store';

const fetchRefunds = (params) =>
  appStore.getState().app.livemode
    ? api.live.get('/refunds', { params })
    : api.sandbox.get('/refunds', { params });

export const useFetchRefunds = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchRefunds', params],
    () => {
      return fetchRefunds(params);
    },
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchRefund = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/refunds/${id}`, { params })
    : api.sandbox.get(`/refunds/${id}`, { params });

export const useFetchRefund = ({
  id,
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchRefund', id, params],
    () => fetchRefund(id, params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const updateRefund = (id, data) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/refunds/${id}`, data)
    : api.sandbox.patch(`/refunds/${id}`, data);

export const useUpdateRefund = (id) => {
  return useMutation(['updateRefund', id], (data) =>
    updateRefund(id, data)
  );
};

const fetchRefundEstimate = (params) =>
  appStore.getState().app.livemode
    ? api.live.get(`refunds/estimate`, { params })
    : api.sandbox.get(`refunds/estimate`, { params });

export const useFetchRefundEstimate = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(
    ['fetchRefundEstimate', params],
    () => fetchRefundEstimate(params),
    {
      onSuccess,
      onError,
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

const createRefund = (data) => {
  appStore.getState().app.livemode
    ? api.live.post(`/refunds`, data)
    : api.sandbox.post(`/refunds`, data);
}

export const useCreateRefund = () => {
  return useMutation(['createRefund'], (data) =>
    createRefund(data)
  );
};