import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchBalance = () => appStore.getState().app.livemode
  ? api.live.get('/balance', {
    params: {
      include: 'converted_amount'
    }
  })
  : api.sandbox.get('/balance', {
    params: {
      include: 'converted_amount'
    }
  });

export const useFetchBalance = ({onSuccess, onError, enabled}) => {
  return useQuery(
    "fetchBalance",
    fetchBalance,
    {
      onSuccess,
      onError,
      enabled,
      select: (data) => data.data,
      retry: false,
      refetchOnWindowFocus: false
    }
  )
}

const storeVault = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/balance/vault", data)
    : api.sandbox.post("/balance/vault", data);

export const useStoreVault = () => {
  return useMutation("storeVault", storeVault);
};

const storeFromVault = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/balance/from_vault", data)
    : api.sandbox.post("/balance/from_vault", data);

export const useStoreFromVault = () => {
  return useMutation("storeFromVault", storeFromVault);
};