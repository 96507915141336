import api from "../../utils/axios";
import {useMutation, useQuery} from "react-query";
import appStore from "../../store";

const fetchTaxRates = (params) => appStore.getState().app.livemode
  ? api.live.get("/tax_rates", { params })
  : api.sandbox.get("/tax_rates", { params });

export const useFetchTaxRates = ({onSuccess, onError, enabled, params = null}) => {
  return useQuery(
    ["fetchTaxRates", params],
    () => fetchTaxRates(params),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const fetchTaxRate = (id) => appStore.getState().app.livemode
  ? api.live.get(`/tax_rates/${id}`)
  : api.sandbox.get(`/tax_rates/${id}`);

export const useFetchTaxRate = ({id, onSuccess, onError, enabled}) => {
  return useQuery(
    ["fetchTaxRate", id],
    () => fetchTaxRate(id),
    {
      onSuccess,
      onError,
      select: (data) => data.data,
      enabled,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

const storeTaxRate = (data) => appStore.getState().app.livemode
  ? api.live.post("/tax_rates", data)
  : api.sandbox.post("/tax_rates", data);

export const useStoreTaxRate = () => {
  return useMutation(
    "storeTaxRate",
    storeTaxRate
  );
}

const updateTaxRate = (id, data) => appStore.getState().app.livemode
  ? api.live.patch(`/tax_rates/${id}`, data)
  : api.sandbox.patch(`/tax_rates/${id}`, data);

export const useUpdateTaxRate = (id) => {
  return useMutation(
    ["updateTaxRate", id],
    (data) => updateTaxRate(id, data)
  );
}

const deleteTaxRate = (id) => appStore.getState().app.livemode
  ? api.live.delete(`/tax_rates/${id}`)
  : api.sandbox.delete(`/tax_rates/${id}`);

export const useDeleteTaxRate = (id) => {
  return useMutation(
    ["deleteTaxRate", id],
    () => deleteTaxRate(id)
  );
}