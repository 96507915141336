import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencySymbol,
} from '../../../../../../../utils/string';
import LabelTag from '../../../../../../Common/LabelTag/LabelTag';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useTablePagination from '../../../../../../../hooks/useTablePagination';
import { useFetchRefunds } from '../../../../../../../api/payment/refunds';
import DashboardModalTable from '../../../../../../Dashboard/DashboardModalTable/DashboardModalTable';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DateIcon } from '../../../../../../../assets/images/icons/date.svg';
import { dateFormatter } from '../../../../../../../utils/date';

const REASONS_MESSAGE = {
  overcharge: 'Purchase over charged',
  customer_request: 'Customer requested',
  cancelled: 'Purchase cancelled',
  credit_note: 'Invoice credit note',
};

function PaymentDetailsRefundsTable({ enabled = false }) {
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const { selected_payment } = useSelector(
    (state) => state.payment
  );
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchPaymentsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
  };

  const { isLoading: isFetchingData } = useFetchRefunds({
    onSuccess: onFetchPaymentsSuccess,
    onError: () => null,
    enabled,
    params: {
      payment: selected_payment?.id,
      limit: limitQuery,
      ...requestQuery,
    },
  });

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount',
        maxWidth: 70,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className='flex items-start gap-2'>
              <div>
                <div className='app-modal-fullscreen__value'>
                  <div className='flex gap-1'>
                    <span className='w-4' />
                    <div>
                      {currencySymbol(row.currency)}
                      {currencyFormatter(
                        row.currency,
                        row.amount
                      )}
                    </div>
                  </div>
                </div>
                <div className='app-modal-fullscreen__value'>
                  <div className='flex gap-1'>
                    <img
                      src={getIconUrl(
                        row.crypto_currency.toLowerCase()
                      )}
                      alt={row.crypto_currency}
                      className='w-4'
                    />
                    <div>
                      {cryptocurrencyFormatter(
                        row.crypto_amount
                      )}{' '}
                      <span>
                        {row.crypto_currency?.toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 50,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div
              data-for='refunds-tooltip'
              data-tip={row?.failure_message}>
              <LabelTag
                color={clsx(
                  row.status === 'succeeded' && 'primary',
                  row.status === 'failed' && 'danger',
                  row.status === 'pending' && 'tertiary'
                )}>
                {row.status}
              </LabelTag>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (cell) => {
          return (
            <div className='app-modal-fullscreen__value'>
              {cell.value ?? '-'}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div className='text-right'>Reason</div>
        ),
        accessor: 'reason',
        Cell: (cell) => {
          return (
            <div className='app-modal-fullscreen__value text-right'>
              {REASONS_MESSAGE?.[cell.value] ?? '-'}
            </div>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        maxWidth: 70,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              <div>
                <DateIcon className='mr-1.5' />
              </div>
              <span className='app-modal-fullscreen__value'>
                {dateFormatter(cell.value, true)}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className='space-y-1.5 pb-2'>
      <div className='app-modal-fullscreen__title space-x-4'>
        <span>Refunds</span>
        <span>
          ({currencySymbol(selected_payment.currency)}
          {currencyFormatter(
            selected_payment.currency,
            selected_payment.amount_refunded
          )}
          )
        </span>
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Refunds"
      />
    </div>
  );
}

export default PaymentDetailsRefundsTable;
