import Modal from "../../../../../Common/Modal/Modal";
import Stack from "../../../../../Common/Stack/Stack";
import Label from "../../../../../Common/Form/Label/Label";
import Input from "../../../../../Common/Form/Input/Input";
import Button from "../../../../../Common/Form/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactFlagsSelect from "react-flags-select";
import Checkbox from "../../../../../Common/Form/Checkbox/Checkbox";
import {
  useStoreCustomer,
  useUpdateCustomer,
} from "../../../../../../api/customer/customer";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { setSelectedInvoiceCustomer } from "../../../../../../store/invoice/invoiceSlice";
import {
  setRefetchCustomer,
  setSelectedCustomer,
} from "../../../../../../store/customer/customerSlice";
import { forbiddenCountries } from "../../../../../../utils/countries";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";

const formSchema = yup
  .object({
    name: yup.string().required().trim().label("Name"),
    email: yup.string().email().label("Email"),
  })
  .required();

function CustomersModalCreate({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const { selected_customer } = useSelector((state) => state.customer);

  const {
    register,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: selected_customer?.name ?? "",
      email: selected_customer?.email ?? "",
      description: selected_customer?.description ?? "",
      shipping: {
        first_line: selected_customer?.shipping?.first_line ?? "",
        second_line: selected_customer?.shipping?.second_line ?? "",
        post_code: selected_customer?.shipping?.post_code ?? "",
        city: selected_customer?.shipping?.city ?? "",
        state: selected_customer?.shipping?.state ?? "",
        country: selected_customer?.shipping?.country ?? "",
      },
      ignore_duplicate: selected_customer?.ignore_duplicate ?? false,
    },
    resolver: yupResolver(formSchema),
  });

  const country = watch("shipping.country");

  const { mutate: mutateStoreCustomer, isLoading: isStoreLoading } =
    useStoreCustomer();
  const { mutate: mutateUpdateCustomer, isLoading: isUpdateLoading } =
    useUpdateCustomer(selected_customer?.id);

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      return () => {
        navigate(location.pathname.replace("/create", "").replace("/edit", ""));

      }
    }, [])
  useEffect(() => {
    register("shipping.country");
  }, [register]);

  const handleClose = () => {
    onClose && onClose();
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const cleanEmptyKeys = (obj) =>
    Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? cleanEmptyKeys(v) : v])
      .reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

  const onSubmit = (formData) => {
    let filteredData = cleanEmptyKeys(formData);
    // remove empty shipping object;
    if (filteredData.shipping && Object.keys(filteredData.shipping).length < 1) delete filteredData.shipping;
    // Object.entries(formData).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});

    if (selected_customer?.id) {
      mutateUpdateCustomer(filteredData, {
        onSuccess: (data) => {
          toast.success("Customer updated");
          dispatch(setSelectedInvoiceCustomer(data.data));
          dispatch(setSelectedCustomer(data.data));
          dispatch(setRefetchCustomer(Date.now()));
          handleClose();
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      });
      return;
    }

    mutateStoreCustomer(filteredData, {
      onSuccess: (data) => {
        toast.success("Customer created");
        dispatch(setSelectedInvoiceCustomer(data.data));
        dispatch(setRefetchCustomer(Date.now()));
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  return (
    <Modal show={show} onClose={handleClose} size="sm">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {selected_customer?.id ? "Edit" : "Add"} Customer
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form id="new-customer" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1" gap="4">
            <div className="app-customers__modal__form-title">Details</div>
            <div>
              <Label title="Name" required={true} error={fieldError("name")} />
              <Input
                {...register("name")}
                type="text"
                block
                error={fieldError("name")}
              />
            </div>
            <div>
              <Label title="Email" error={fieldError("email")} />
              <Input
                {...register("email")}
                type="email"
                block
                error={fieldError("email")}
              />
            </div>
            <div>
              <Label title="Description" subtitle='(optional)' error={fieldError("description")} />
              <Input
                {...register("description")}
                type="text"
                block
                error={fieldError("description")}
              />
            </div>
            <div className="app-customers__modal__form-title">
              Shipping Details
            </div>
            <div>
              <Label
                title="Address Line 1"
                error={fieldError("shipping.first_line")}
              />
              <Input
                {...register("shipping.first_line")}
                type="text"
                block
                error={fieldError("shipping.first_line")}
              />
            </div>
            <div>
              <Label
                title="Address Line 2"
                error={fieldError("shipping.second_line")}
              />
              <Input
                {...register("shipping.second_line")}
                type="text"
                block
                error={fieldError("shipping.second_line")}
              />
            </div>
            <div>
              <Label
                title="Postal Code"
                error={fieldError("shipping.post_code")}
              />
              <Input
                {...register("shipping.post_code")}
                type="text"
                block
                error={fieldError("shipping.post_code")}
              />
            </div>
            <div>
              <Label title="City" error={fieldError("shipping.city")} />
              <Input
                {...register("shipping.city")}
                type="text"
                block
                error={fieldError("shipping.city")}
              />
            </div>
            <div>
              <Label title="State" error={fieldError("shipping.state")} />
              <Input
                {...register("shipping.state")}
                type="text"
                block
                error={fieldError("shipping.state")}
              />
            </div>
            <div>
              <Label title="Country" />
              <ReactFlagsSelect
                countries={forbiddenCountries}
                blacklistCountries
                searchable
                placeholder="Choose a country..."
                className="app-react-flag-select"
                selectButtonClassName={clsx(
                  "app-select__input app-select__input__country",
                  fieldError("shipping.country") &&
                    "app-select__input__country--error"
                )}
                selected={country}
                onSelect={(code) => {
                  setValue("shipping.country", code);
                  clearErrors("shipping.country");
                }}
              />
              {fieldError("shipping.country") && (
                <p className="app-input__help -mt-1">
                  {fieldError("shipping.country")}
                </p>
              )}
            </div>
            {!selected_customer && (
              <div className="flex items-center gap-3">
                <Checkbox
                  {...register("ignore_duplicate")}
                  color="primary"
                  className="mt-1"
                />
                <div className="text-light text-sm">
                  Ignore if a duplicate customer found?
                </div>
              </div>
            )}
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end gap-3">
          <Button onClick={handleClose} color="light" type="button">
            Cancel
          </Button>
          <Button
            disabled={isStoreLoading || isUpdateLoading}
            form="new-customer"
            type="submit"
          >
            {selected_customer?.id ? "Save" : "Add"}
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default CustomersModalCreate;
