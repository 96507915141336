import React, { useEffect, useState} from 'react';
import { ReactComponent as EyeIcon } from 'assets/images/icons/dashboard/eye.svg';
import { currencySymbol } from 'utils/string';
// import './style.scss';
import { PreviewTiers } from 'utils/price_utils';
import styled from 'styled-components';
import useOutsideClick from 'hooks/useOutsideClick';

function TierPreview({previewRef, tiers, currency , tiers_mode}) {
  const [units, setunits] = useState(tiers[tiers.length - 1].first_unit);
  const [amount, setamount] = useState(PreviewTiers(tiers, tiers_mode, units ?? 0, currency))
  useOutsideClick(previewRef, (e)=>{
    // alert('OUTSIDE CLICK')
		previewRef?.current.classList.remove("show");
  });
  useEffect(() => {
    var tierValue = PreviewTiers(tiers, tiers_mode, tiers[tiers.length - 1].first_unit, currency);
		setunits(tiers[tiers.length - 1].first_unit);
    setamount(tierValue);
  }, [tiers]);
  
  return (
		<div  
			onClick={(e)=>{
				previewRef?.current.classList.toggle("show")
			}}
			className='app-tax_rates__button-link flex mx-2'
		>
			<EyeIcon
				// onClick={() =>
				//   handlePaymentViewClick(cell.row.original.id)
				// }
				className='cursor-pointer app-tax_rates__button-link mx-1 mt-1'
			/>
			<div className='pre-cont' >
				<span 
					name='toggle'
					style={{position: "relative"}}
					className='cursor-pointer'>
					Preview
				</span>
				<Previerstyle  
				onClick={(e)=>e.stopPropagation()}
				ref={previewRef}
					style={{zIndex : "200"}}
				>
					PRICING PREVIEW
				{ parseFloat(amount.amount) === 0 && amount.tiers.length === 0
				?
					<div className='msg'>
						To see an example, please enter a unit range and price for each tier.
					</div>
					:
					<>
						<div>
							<input 
							pattern="^[0-9]\d*$"
							maxLength={10}
							onKeyPress={(event) => {
								if (!/^[0-9]{0,10}$/.test(event.key) && event.key !== "." && event.key !== "") {
									event.preventDefault();
								}
							}}
							onChange={(e)=>{
								if (e.target.value === "") {
									setunits(null);
									setamount(PreviewTiers(tiers ,tiers_mode, 0, currency));
								} else {
									setunits(parseFloat(e.target.value));
									setamount(PreviewTiers(tiers ,tiers_mode, parseFloat(e.target.value), currency));
								}
							}}  
							value={units} 
							placeholder={"0"}/>
							{ "  units "} would cost{" "}{currencySymbol(currency)}{amount.amount} 
						</div>
						{
							amount.tiers.map((data, i) => {
								return (<div key={i} style={{fontWeight: 'normal'}}>
									{`${tiers_mode === "volume" ? data.quantity : data.tier_label} x ${currencySymbol(currency)}${data.unit_amount ?? '0.00'}${data.flat_amount ? [' + ' + currencySymbol(currency) + data.flat_amount] : ''} = ${[currencySymbol(currency) + (tiers_mode === "volume" ? amount.amount : data.amount)]}`} 
								</div>)
							})
						}
					</>
				}
				</Previerstyle>
			</div>
		</div>
  )
}

const Previerstyle = styled.div`
max-height: 300px;
overflow: scroll;
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
  position: absolute;
  top:70%;
  right: 0;
  display: none;
  align-items: flex-start;
  flex-direction:column;
  border-radius: 3px;
  padding: 0.5rem ;
  margin: 0.5rem 1rem;
  width: 11rem;
  background: white;
  color: black;
  font-size: 15px;
  border-radius:5px;
  border: 1px solid #dcdee6;
  width: 250px;
 input{
    min-width: 30px;
    max-width: 50px;

    padding: 0 10px;
    outline: none;
    border: 1px solid #dcdee6;
    border-radius: 5px;
  }
  .msg{
    opacity: 0.5;
    font-weight: 400;
    font-size: 12px;
  }


`;

export default TierPreview;