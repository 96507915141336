import "./SectionRewards.scss";
import Stack from "../../../Common/Stack/Stack";
import { ReactComponent as RewardsIcon } from "../../../../assets/images/icons/rewards.svg";
import LogoSmall from "../../../../assets/images/logo-small.svg";

const rewardsData = [
  {
    col: "1",
    icon: "merchant-rewards",
    title: "Merchant rewards",
    text: `Walio offers a range of rewards for merchants. 
      Earn 0.1% worth of WALIO from your sales over $1000 USD, 
      or the equivalent in your currency, each month. 
      This is an additional way to say thank you to our users.`,
  },
  {
    col: "1",
    icon: "customer-rewards",
    title: "Customer Rewards",
    text: `Customers can earn 0.1% worth of WALIO on purchases they make over $150. 
      The provides a great incentive for your customers to make 
      Cryptocurrency purchases and adds more benefits to your product!`,
  },
  {
    col: "2",
    icon: "merchant-discounts",
    title: "Merchant Discounts",
    text: `Walio offers discounts on payment fees for Merchants that hold certain 
      amounts of Walio in their account. The amounts are calculated as a monthly average and 
      will change based on your accounts average holding. Discounts are applied as follows:`,
  },
];

function SectionRewards() {
  return (
    <div id="rewards" className="section-rewards">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <RewardsIcon className="section-products__section__subtitle__icon" />
              Rewards
            </h3>
            <h1 className="h1 section-products__section__title">
              A payment solution that rewards you and your customers!
            </h1>
            <p className="text section-products__section__text">
              Earn WALIO for sales you make and allow your customers to earn
              Walio for their purchases! Improve your customer experience and
              add more value to their purchase.
            </p>
          </div>
          <div className="section-rewards__allocated">
            <div className="section-rewards__allocated__title">
              Walio has allocated
            </div>
            <div className="section-rewards__allocated__tokens">
              <img src={LogoSmall} alt="Walio" />
              <span className="section-rewards__allocated__tokens__figure">
                1,500,000
              </span>
              <span className="section-rewards__allocated__tokens__walio">
                WALIO
              </span>
            </div>
            <div className="section-rewards__allocated__text">
              tokens to distribute{" "}
              <span>to Merchants and their customers as rewards.</span>
            </div>
          </div>
        </Stack>
        <Stack
          columns="1"
          className="md:grid-cols-2 gap-x-24 gap-y-16 section-rewards__features"
        >
          {rewardsData.map((item, index) => {
            const SVGIcon =
              require(`../../../../assets/images/sections/rewards/${item.icon}.svg?svgr`).default;
            return (
              <div
                key={index}
                className={`section-rewards__features__content md:col-span-${item.col} col-auto`}
              >
                <div className="section-rewards__features__content__icon">
                  <SVGIcon />
                </div>
                <h5 className="h5 section-products__section__info section-rewards__features__content__title">
                  {item.title}
                </h5>
                <p className="text-small section-rewards__features__content__text">
                  {item.text}
                </p>
              </div>
            );
          })}
        </Stack>
        <Stack
          columns="1"
          className="lg:grid-cols-3 md:grid-cols-2 section-rewards__prices"
        >
          <div className="section-rewards__prices__card">
            <div className="section-rewards__allocated__tokens section-rewards__prices__card__price">
              <img src={LogoSmall} alt="Walio" />
              <span className="section-rewards__allocated__tokens__figure">
                5,000 – 10,000
              </span>
              <span className="section-rewards__allocated__tokens__walio">
                WALIO
              </span>
            </div>
            <div className="text-small section-rewards__prices__card__fees">
              Payment Processing Fee reduced to 1%
            </div>
            <div className="text-small section-rewards__prices__card__fees">
              Auto/Swap convert reduced to 2.6%
            </div>
          </div>
          <div className="section-rewards__prices__card">
            <div className="section-rewards__allocated__tokens section-rewards__prices__card__price">
              <img src={LogoSmall} alt="Walio" />
              <span className="section-rewards__allocated__tokens__figure">
                10,001 – 60,000
              </span>
              <span className="section-rewards__allocated__tokens__walio">
                WALIO
              </span>
            </div>
            <div className="text-small section-rewards__prices__card__fees">
              Payment Processing Fee reduced to 0.9%
            </div>
            <div className="text-small section-rewards__prices__card__fees">
              Auto/Swap convert reduced to 2.25%
            </div>
          </div>
          <div className="section-rewards__prices__card">
            <div className="section-rewards__allocated__tokens section-rewards__prices__card__price">
              <div className="section-rewards__prices__card__small">
                greater than
              </div>
              <img src={LogoSmall} alt="Walio" />
              <span className="section-rewards__allocated__tokens__figure">
                60,000
              </span>
              <span className="section-rewards__allocated__tokens__walio">
                WALIO
              </span>
            </div>
            <div className="text-small section-rewards__prices__card__fees">
              Payment Processing Fee reduced to 0.8%
            </div>
            <div className="text-small section-rewards__prices__card__fees">
              Auto/Swap convert reduced to 1.85%
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
}

export default SectionRewards;
