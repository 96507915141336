import './Payments.scss';
import DashboardNavbar from '../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar';
import DashboardButton from '../../../components/Common/Form/DashboardButton/DashboardButton';
import { ReactComponent as ExportIcon } from '../../../assets/images/icons/export.svg';
import PaymentsTable from '../../../components/Pages/Dashboard/Payments/PaymentsTable/PaymentsTable';
import PaymentsModalDetails from '../../../components/Pages/Dashboard/Payments/PaymentsModals/PaymentsModalDetails/PaymentsModalDetails';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPayment } from '../../../store/payment/paymentSlice';
import clsx from 'clsx';
import useTableFilters from '../../../hooks/useTableFilters';
import { toast } from 'react-toastify';
import { useFetchPayment } from '../../../api/payment/payment';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownFilters from '../../../components/Common/DropdownFilters/DropdownFilters';
import ReactTooltip from 'react-tooltip';

const TABLE_FILTERS = [
  { label: 'All', column: 'status', value: '' },
  {
    label: 'Succeeded',
    column: 'status',
    value: 'succeeded',
  },
  { label: 'Refunded', column: 'refunded', value: true },
  { label: 'Uncaptured', column: 'captured', value: false },
];

function Payments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selected_payment, refetch_payment } = useSelector(
    (state) => state.payment
  );
  const [filters, setFilters] = useState({});
  const [showDetailsModal, setShowDetailsModal] =
    useState(false);
  const [refreshPayment, setRefreshPayment] =
    useState(false);
  const { id } = useParams();
  const { activeFilter, setFilter, isFilterActive } =
    useTableFilters({
      filters: TABLE_FILTERS,
      defaultFilter: TABLE_FILTERS[0],
    });
    const { live_mode_access } = useSelector((state) => state.auth.current_account);

    const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchPayment({
    onSuccess: (data) => {
      dispatch(setSelectedPayment(data));
      setRefreshPayment(false);
      console.log('testing on success from payments', data);
    },
    onError: (error) => {
      setRefreshPayment(false);
      navigate(DASHBOARD_PATH + '/payments');
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshPayment,
    params: {
      expand: [
        'customer',
        'balance_transaction',
        'blockchain_transaction',
      ],
    },
  });

  const handleCloseModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedPayment(null));
    navigate(DASHBOARD_PATH + '/payments');
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_payment);
  }, [selected_payment]);

  useEffect(() => {
    if (!id) {
      handleCloseModal();
      return;
    }

    setRefreshPayment(true);
  }, [refetch_payment, id, handleCloseModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedPayment(null));
    };
  }, [dispatch]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({
      column: 'status',
      value: filters?.status ?? '',
    });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(
        ([_, v]) => v != null && v !== ''
      )
    );
  }, [activeFilter]);

  const getFilters = useMemo(() => {
    if (activeFilterFiltered?.status === 'succeeded') {
      activeFilterFiltered.captured = true;
    }
    return {
      ...filters,
      ...activeFilterFiltered,
    };
  }, [filters, activeFilterFiltered]);

  const handleFilterClick = (filter) => {
    if (filter && filter.label === 'All') {
      setFilters((state) => {
        const { status, ...rest } = state;

        return rest;
      });
    }

    setFilter(filter);
  };

  return (
    <div className='app-dashboard-container'>
      <DashboardNavbar />
      <div className='app-dashboard__content'>
        <div className='app-dashboard__card'>
          <div className='app-dashboard__card__header'>
            <div className='flex'>
              <div className='app-dashboard__card__title'>
                Payments
              </div>
              <span className='app-dashboard__card__header__filters'>
                {TABLE_FILTERS.map((filter, index) => (
                  <span
                    key={index}
                    onClick={() =>
                      handleFilterClick(filter)
                    }
                    className={clsx(
                      'app-dashboard__card__header__filters__item',
                      isFilterActive(filter) &&
                        'app-dashboard__card__header__filters__item--active'
                    )}>
                    {filter.label}
                  </span>
                ))}
              </span>
            </div>
            <div className='app-dashboard__card__buttons'>
              <DropdownFilters
                filters={[
                  'payment_status',
                  'customer',
                  'amount',
                  'crypto_amount',
                  'date',
                  'currency',
                  'crypto_currency',
                  'source',
                  'metadata',
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page='payments'
              />
              <DashboardButton
                color='tertiary'
                outline
                icon={<ExportIcon />}>
                <span className='text-dark'>Export</span>
              </DashboardButton>
            </div>
          </div>
          <PaymentsTable filters={getFilters} />
        </div>
        {showDetailsModal && (
          <PaymentsModalDetails
            show={showDetailsModal}
            onClose={handleCloseModal}
          />
        )}
        <ReactTooltip
          id='refunds-tooltip'
          place='top'
          effect='solid'
          type='light'
          padding='10px'
          textColor='#1A1E23'
          backgroundColor='#ffffff'
          className='api-key__tooltip'>
          {}
        </ReactTooltip>
        <ReactTooltip
          id='fees-tooltip'
          place='right'
          effect='solid'
          type='light'
          padding='10px'
          textColor='#1A1E23'
          backgroundColor='#ffffff'
          className='api-key__tooltip api-key__tooltip--shadow api-key__tooltip--opacity api-key__tooltip--padding'>
          {}
        </ReactTooltip>
      </div>
    </div>
  );
}

export default Payments;
