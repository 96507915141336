import Modal from "../../../../../../Common/Modal/Modal";
import Stack from "../../../../../../Common/Stack/Stack";
import Button from "../../../../../../Common/Form/Button/Button";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputPrice from "../../../../../../Common/Form/InputPrice/InputPrice";
import Label from "../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../Common/Form/Input/Input";
import { ReactComponent as PreviewPattern } from "../../../../../../../assets/images/dashboard/shipping_rates_preview.svg";
import { ReactComponent as InfoIcon } from "../../../../../../../assets/images/icons/info.svg";
import {
  currencyConverter,
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../utils/string";
import ReactTooltip from "react-tooltip";
import {
  useStoreShippingRate,
  useUpdateShippingRate,
} from "../../../../../../../api/product/shipping_rates";
import { toast } from "react-toastify";
import { FIAT_CURRENCIES_SELECT } from "../../../../../../../utils/constants";
import { setRefetchShippingRate } from "../../../../../../../store/shipping_rate/shippingRateSlice";

const formSchema = yup
  .object({
    from: yup
      .string()
      // .required("Required")
      .test("from", "Min can't be greater than Max", function (val) {
        return !this.parent.to || parseInt(val) <= parseInt(this.parent.to);
      })
      .label("Min"),
    to: yup
      .string()
      // .required("Required")
      .label("Max"),
  })
  .required();

function ShippingRatesModalCreate({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const { selected_shipping_rate } = useSelector(
    (state) => state.shipping_rate
  );

  const { current_account } = useSelector(
    (state) => state.auth
  );

  const { mutate: mutateStoreShippingRate } = useStoreShippingRate();
  const { mutate: mutateUpdateShippingRate } = useUpdateShippingRate(
    selected_shipping_rate?.id
  );

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: selected_shipping_rate?.amount
        ? currencyFormatter(
            selected_shipping_rate?.currency,
            selected_shipping_rate?.amount
          )
        : 0,
      description: selected_shipping_rate?.description ?? "",
      currency: selected_shipping_rate?.currency ?? current_account.settings.reporting_currency,
      from: selected_shipping_rate?.shipping_time?.from ?? "",
      to: selected_shipping_rate?.shipping_time?.to ?? "",
    },
    resolver: yupResolver(formSchema),
  });

  const description = watch("description");
  const currency = watch("currency");
  const amount = watch("amount");
  const from = watch("from");
  const to = watch("to");

  useEffect(() => {
    register("currency");
  }, [register]);

  const daysWord = (days) => (days > 1 ? "days" : "day");

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const onSubmit = (formData) => {
    if (selected_shipping_rate?.id) {
      mutateUpdateShippingRate(
        { description: formData.description },
        {
          onSuccess: () => {
            toast.success("Shipping rate updated");
            handleClose();
          },
          onError: (error) => {
            toast.error(error.response?.data.error.message);
          },
        }
      );
      return;
    }

    const data = {
      amount:
        formData.amount > 0
          ? currencyConverter(formData.currency, formData.amount)
          : 0,
      currency: formData.currency,
    };
    if (formData.description) data.description = formData.description;

    if (formData.from && formData.to) {
      data.shipping_time = {
        from: parseInt(formData.from, 10),
        to: parseInt(formData.to, 10),
      };
    }

    mutateStoreShippingRate(data, {
      onSuccess: () => {
        dispatch(setRefetchShippingRate(Date.now()));
        toast.success("Shipping rate created");
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  return (
    <Modal show={show} onClose={handleClose} size="lg">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {selected_shipping_rate?.id ? "Edit" : "Add"} Shipping Rate
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form
          className="mb-4"
          id="create-shipping_rate"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-2 gap-x-4">
            <div>
              <Stack columns="1" gap="4">
                <div>
                  <Label title="Amount" />
                  <InputPrice
                    {...register("amount")}
                    label={amount > 0 ? null : "FREE"}
                    currency={currency}
                    currencies={FIAT_CURRENCIES_SELECT}
                    onCurrencyChange={(e) => setValue("currency", e?.value)}
                    block
                    disabled={selected_shipping_rate?.id}
                  />
                </div>
                <div>
                  <Label title="Description" />
                  <Input
                    {...register("description")}
                    placeholder="e.g. Standard Delivery"
                    block
                  />
                  <small className="text-light">
                    Will appear on invoices or receipts and at checkout.
                  </small>
                </div>
                <div>
                  <Label title="Estimated Shipping Time" subtitle='(optional)'/>
                  <div className="flex gap-4">
                    <small className="text-light mt-2">Between</small>
                    <div className="w-14">
                      <Input
                        {...register("from")}
                        placeholder="min"
                        block
                        error={fieldError("from")}
                        disabled={selected_shipping_rate?.id}
                      />
                    </div>
                    <small className="text-light mt-2">and</small>
                    <div className="w-14">
                      <Input
                        {...register("to")}
                        placeholder="max"
                        block
                        disabled={selected_shipping_rate?.id}
                      />
                    </div>
                    <small className="text-light mt-2">business days</small>
                  </div>
                </div>
              </Stack>
            </div>
            <div>
              <div className="app-shipping_rates__preview">
                <Stack columns="1" gap="4">
                  <div className="app-shipping_rates__preview__text app-shipping_rates__preview__text--light flex items-center">
                    <div>Subtotal</div>
                    <div className="app-shipping_rates__preview__placeholder" />
                  </div>
                  <div className="app-shipping_rates__preview__divider" />
                  <div className="app-shipping_rates__preview__text font-semibold">
                    <div>
                      <div>Shipping</div>
                      <div className="app-shipping_rates__preview__description">
                        {description && description}
                        {from && (
                          <>
                            {!to &&
                              ` (up to ${from} business ${daysWord(from)})`}
                            {to &&
                              to !== from &&
                              ` (${from} to ${to} business ${daysWord(to)})`}
                            {to === from &&
                              ` (${from} business ${daysWord(from)})`}
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="app-customers__modal__earning">
                        {amount > 0 ? (
                          <>
                            {currencySymbol(currency)}
                            {currencyFormatter(currency, amount * 100)}
                            <span>{currency?.toUpperCase()}</span>
                          </>
                        ) : (
                          "Free"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="app-shipping_rates__preview__divider" />
                  <div className="app-shipping_rates__preview__text app-shipping_rates__preview__text--light flex items-center">
                    <div>Total</div>
                    <div className="app-shipping_rates__preview__placeholder" />
                  </div>
                </Stack>
                <PreviewPattern className="app-shipping_rates__preview__pattern" />
              </div>
              <div className="app-shipping_rates__preview__customer flex items-center justify-center gap-2">
                <span>Customer preview</span>
                <InfoIcon
                  data-tip={
                    "This is what the customer will see when paying at checkout"
                  }
                />
              </div>
            </div>
          </div>
          <ReactTooltip
            place="top"
            effect="solid"
            type="light"
            padding="10px"
            textColor="#1A1E23"
            backgroundColor="#ffffff"
            className='api-key__tooltip'
          />
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button form="create-shipping_rate" type="submit">
            {selected_shipping_rate ? "Update" : "Save"}
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default ShippingRatesModalCreate;
