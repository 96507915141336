import Modal from "../../../../../../Common/Modal/Modal";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../../../Common/Form/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Stack from "../../../../../../Common/Stack/Stack";
import Label from "../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../Common/Form/Input/Input";
import Select from "../../../../../../Common/Form/Select/Select";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import InputGroup from "../../../../../../Common/Form/InputGroup/InputGroup";
import ReactFlagsSelect from "react-flags-select";
import Textarea from "../../../../../../Common/Form/Textarea/Textarea";
import {
  useStoreTaxRate,
  useUpdateTaxRate,
} from "../../../../../../../api/product/tax_rate";
import { toast } from "react-toastify";
import { setRefetchTaxRate } from "../../../../../../../store/tax_rate/taxRateSlice";
import { forbiddenCountries } from "../../../../../../../utils/countries";
import InfoIcon from "../../../../../../../assets/images/icons/info.svg";
import ReactTooltip from "react-tooltip";

function TaxRatesModalCreate({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const [taxType, setTaxType] = useState("gst");
  const [regionLabel, setRegionLabel] = useState(false);
  const { selected_tax_rate } = useSelector((state) => state.tax_rate);

  const getFormSchema = useMemo(() => {
    const validationSchema = {};

    validationSchema.type = yup.string().required().label("Type");
    validationSchema.percent = yup
      .string()
      .required()
      .trim()
      .matches(
        /^([0-9]{1,2}(\.[0-9]{1,2})?|100(\.00+)?)$/,
        'Tax rate must be between 0-100 and can have a maximum of 3 decimals'
      )
      .label("Percent")
      .typeError("Percent is a required field");

    if (taxType === "sales_tax") {
      validationSchema.display_name = yup.string().required().label("Type");
    }

    return validationSchema;
  }, [taxType]);

  const {
    register,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: selected_tax_rate?.type ?? "gst",
      inclusive: selected_tax_rate?.inclusive ?? true,
      display_name: selected_tax_rate?.display_name ?? "",
      description: selected_tax_rate?.description ?? "",
      percent: selected_tax_rate?.percent ?? "",
      country: selected_tax_rate?.country ?? "",
      state: selected_tax_rate?.state ?? "",
    },
    resolver: yupResolver(yup.object(getFormSchema).required()),
  });

  const type = watch("type");
  const inclusive = watch("inclusive");
  const country = watch("country");

  useEffect(() => {
    if (!selected_tax_rate) return;
    setRegionLabel(!!selected_tax_rate?.state);
  }, [selected_tax_rate]);

  const { mutate: mutateStoreTaxRate } = useStoreTaxRate();
  const { mutate: mutateUpdateTaxRate } = useUpdateTaxRate(
    selected_tax_rate?.id
  );

  const handleClose = () => {
    onClose && onClose();
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const handleTypeChange = (e) => {
    setValue("type", e?.value);
    setTaxType(e?.value);
  };

  const onSubmit = (formData) => {
    if (selected_tax_rate?.id) {
      mutateUpdateTaxRate(
        {
          description: formData?.description,
        },
        {
          onSuccess: () => {
            dispatch(setRefetchTaxRate(Date.now()));
            toast.success("Tax Rate updated successfully");
            handleClose();
          },
          onError: (error) => {
            toast.error(error.response?.data.error.message);
          },
        }
      );
      return;
    }

    const _data = {
      type: formData.type,
      percent: Number(formData.percent),
      inclusive: formData.inclusive,
    };

    if (formData.display_name) _data.display_name = formData.display_name;
    if (formData.description) _data.description = formData.description;
    if (formData.country) _data.country = formData.country;
    if (formData.state) _data.state = formData.state;

    mutateStoreTaxRate(_data, {
      onSuccess: () => {
        dispatch(setRefetchTaxRate(Date.now()));
        toast.success("Tax Rate created successfully");
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  return (
    <Modal show={show} onClose={handleClose} size="sm" overflowVisible>
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {selected_tax_rate?.id ? "Edit" : "Add"} Tax Rate
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form id="create-tax_rate" onSubmit={handleSubmit(onSubmit)}>
          <Stack columns="1" gap="4">
            <div>
              <div
                className={clsx(
                  "grid grid-cols-1 gap-x-3",
                  type === "sales_tax" && "grid-cols-2"
                )}
              >
                <div>
                  <Label title="Type" error={fieldError("type")} />
                  <Select
                    onSelect={handleTypeChange}
                    options={[
                      { label: "GST", value: "gst" },
                      { label: "VAT", value: "vat" },
                      { label: "Custom", value: "sales_tax" },
                    ]}
                    value={type}
                    disabled={selected_tax_rate?.id}
                  />
                </div>
                {type === "sales_tax" && (
                  <div>
                    <div>
                      <Label
                        title="Display Name"
                        icon={InfoIcon}
                        tooltip="The name of the tax that will be displayed on invoices and purchases"
                        error={fieldError("display_name")}
                      />
                      <Input
                        {...register("display_name")}
                        block
                        type="text"
                        error={fieldError("display_name")}
                        disabled={selected_tax_rate?.id}
                      />
                      <ReactTooltip
                        multiline
                        place="bottom"
                        effect="solid"
                        type="light"
                        padding="10px"
                        textColor="#1A1E23"
                        backgroundColor="#ffffff"
                        className="api-key__tooltip"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <Label title="Rate" error={fieldError("percent")} />
              <div className="grid grid-cols-2 gap-x-3">
                <div>
                  <InputGroup
                    {...register("percent")}
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    append="%"
                    block
                    error={fieldError("percent")}
                    disabled={selected_tax_rate?.id}
                  />
                </div>
                <Select
                  onSelect={(e) => setValue("inclusive", e.value)}
                  options={[
                    { label: "Inclusive", value: true },
                    { label: "Exclusive", value: false },
                  ]}
                  value={inclusive}
                  disabled={selected_tax_rate?.id}
                />
              </div>
            </div>
            <div>
              <Label title="Country" subtitle='(optional)' error={fieldError("country")} />
              <ReactFlagsSelect
                countries={forbiddenCountries}
                blacklistCountries
                selected={country}
                onSelect={(code) => {
                  setValue("country", code);
                  clearErrors("country");
                }}
                searchable
                className="app-react-flag-select"
                selectButtonClassName={clsx(
                  "app-select__input app-select__input__country",
                  fieldError("country") && "app-select__input__country--error"
                )}
                disabled={selected_tax_rate?.id}
              />
              {!selected_tax_rate && (
                <div>
                  <span
                    onClick={() => setRegionLabel(!regionLabel)}
                    className="app-tax_rates__button-link"
                  >
                    {regionLabel ? "- Hide" : "+ Add"} region label
                  </span>
                </div>
              )}
              {regionLabel && (
                <div className="mt-2">
                  <Label title="State" subtitle='(optional)' error={fieldError("state")} />
                  <Input
                    {...register("state")}
                    block
                    type="text"
                    error={fieldError("state")}
                    disabled={selected_tax_rate?.id}
                  />
                </div>
              )}
            </div>
            <div>
              <Label
                title="Description"
                subtitle='(optional)'
                error={fieldError("Description")}
              />
              <Textarea
                {...register("description")}
                block
                rows="1"
                error={fieldError("Description")}
              />
            </div>
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button form="create-tax_rate" type="submit">
            Save
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default TaxRatesModalCreate;
