import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    authenticated: false,
    accounts: [],
    current_account: null
  },
  reducers: {
    setUser: (state, payload) => {
      state.user = payload.payload
    },
    setAuthenticated: (state, payload) => {
      state.authenticated = payload.payload
    },
    setMerchantAccounts: (state, payload) => {
      state.accounts = payload.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.authenticated = false;
      state.accounts = [];
      state.current_account = null;
      state.merchant_account = null;
    },
    setCurrentAccount: (state, payload) => {
      state.current_account = payload.payload;
    }
  },
})

export const {
  setUser,
  setAuthenticated,
  setMerchantAccounts,
  logoutUser,
  setCurrentAccount
} = authSlice.actions

export default authSlice.reducer
