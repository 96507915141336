import { currencyPrettier } from '../../../../../../../utils/string';
import { simplePriceDescription } from '../../../../../../../utils/price_utils';
import { ReactComponent as DateIcon } from '../../../../../../../assets/images/icons/date.svg';
import { dateFormatter } from '../../../../../../../utils/date';
import DashboardButton from '../../../../../../Common/Form/DashboardButton/DashboardButton';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTablePagination from '../../../../../../../hooks/useTablePagination';
import DashboardModalTable from '../../../../../../Dashboard/DashboardModalTable/DashboardModalTable';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/images/icons/dashboard/plus.svg';
import {
  fetchPriceSubscriptionsCount,
  useFetchProductPrices,
} from '../../../../../../../api/product/price';
import CopyToClipboardBoxID from '../../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import ProductDetailsPricingTableActions from './ProductDetailsPricingTableActions';
import LabelTag from '../../../../../../Common/LabelTag/LabelTag';
import { setSelectedProductPriceDetails } from '../../../../../../../store/product/productSlice';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

function ProductDetailsPricingTable({ setShowPriceModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const { selected_product, refetch_product_price } =
    useSelector((state) => state.product);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  useEffect(() => {
    setRefetch(true);
  }, [refetch_product_price]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onFetchProductPricesSuccess = async (data) => {
    setData(data);
    setTableRows(data.data);
    setRefetch(false);

    if (data.data) {
      for (const price in data.data) {
        if (data.data[price].type !== 'recurring') {
          continue;
        }

        await fetchPriceSubscriptionsCount(
          data.data[price].id
        ).then((result) => {
          setTableRows((state) =>
            state.map((row) => {
              if (row.id === data.data[price].id) {
                return {
                  ...row,
                  subscription_count:
                    result.data.subscription_count,
                };
              }
              return row;
            })
          );
        });
      }
    }
  };

  const onFetchProductPricesError = () => {
    setRefetch(false);
  };

  const { isLoading: isFetchingData } =
    useFetchProductPrices({
      onSuccess: onFetchProductPricesSuccess,
      onError: onFetchProductPricesError,
      enabled: refetch,
      params: {
        product: selected_product?.id,
        limit: limitQuery,
        ...requestQuery,
      },
    });
    const { live_mode_access } = useSelector(
      (state) => state.auth.current_account
    );
  
    const DASHBOARD_PATH = !live_mode_access ? "/dashboard/test" : "/dashboard";
    const [showPriceDetailsModal, setShowPriceDetailsModal] = useState(false);
  const handleAddPriceClick = () => {
    setShowPriceModal(true);
  };

  const handleRowClick = (price) => {
    dispatch(setSelectedProductPriceDetails(price));
    navigate(DASHBOARD_PATH + `/prices/${price.id}`);
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Price',
        accessor: 'amount',
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <>
              <div className='flex items-center justify-between gap-6'>
                <div className='app-modal-fullscreen__value'>
                  <div className='app-modal-fullscreen__table__amount'>
                    {simplePriceDescription(row)}
                  </div>
                  {row.type === 'recurring' &&
                    row.recurring.usage_type ===
                      'metered' && (
                      <DateIcon
                        data-tip='Customers subscribed to this pricing plan will be billed based on their reported metered usage every period.'
                        className='mt-1 mr-1.5 w-4 self-start'
                      />
                    )}
                </div>
                {!row.active && (
                  <LabelTag color='danger'>
                    Inactive
                  </LabelTag>
                )}
              </div>
              {row.custom_unit_amount?.preset && (
                <div className='app-modal-fullscreen__value mt-1'>
                  {`${currencyPrettier(
                    row.currency,
                    row.custom_unit_amount.preset
                  )} (Preset)`}
                </div>
              )}
              {row.description && (
                <div className='app-modal-fullscreen__value mt-1'>
                  {row.description}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
        minWidth: 200,
        Cell: (cell) => {
          return (
            <div
              className='app-modal-fullscreen__value break-all'
              onClick={(e) => e.stopPropagation()}>
              <CopyToClipboardBoxID
                text={cell.value}
                index={cell.row.index}
              />
            </div>
          );
        },
      },
      {
        Header: 'Subscriptions',
        accessor: 'subscription_count',
        Cell: (cell) => {
          return (
            <div className='app-modal-fullscreen__value'>
              {cell.value ?? '-'}
            </div>
          );
        },
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        width: 80,
        Cell: (cell) => {
          return (
            <div className='flex items-center'>
              <DateIcon className='mr-1.5' />
              <span className='app-modal-fullscreen__value'>
                {dateFormatter(cell.value)}
              </span>
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'icon',
        minWidth: 80,
        Cell: (cell) => (
          <ProductDetailsPricingTableActions
            price={cell.row.original}
          />
        ),
      },
    ],
    []
  );

  return (
    <div className='space-y-1.5 pb-7'>
      <div className='flex justify-between'>
        <div className='app-modal-fullscreen__title'>
          Pricing
        </div>
        <div className='flex gap-4'>
          <DashboardButton
            onClick={handleAddPriceClick}
            outline
            color='primary'
            size='sm'
            icon={<PlusIcon />}>
            <span className='text-dark'>Add Price</span>
          </DashboardButton>
        </div>
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        clickable
        onRowClick={handleRowClick}
        noRecordTag="Prices"
      />
    </div>
  );
}

export default ProductDetailsPricingTable;
