import DashboardButton from "../../Form/DashboardButton/DashboardButton";
import React from "react";

function DropdownFiltersButtonClear({ setEnabledFilters }) {
  const handleClearClick = () => {
    setEnabledFilters({
      date: false,
      name: false,
      email: false,
      currency_type: false,
      shippable: false,
      url: false,
      metadata: false,
      currency: false,
      discount_type: false,
      discount_terms: false,
      calculation: false,
      invoice_type: false,
      invoice_status: false,
      payment_status: false,
      topup_status: false,
      payout_status: false,
      due_date: false,
      shipping_description: false,
      customer: false,
      amount: false,
      amount_due: false,
      reporting_amount: false,
      crypto_amount: false,
      crypto_currency: false,
      source: false,
      product: false,
      price: false,
      contains_recurring_price: false,
      reason: false,
      crypto_from_address: false,
      crypto_to_address :false,
      tx_hash : false,
      topup_method : false,
    });
  };

  return (
    <DashboardButton onClick={handleClearClick} color="light" size="sm">
      Clear
    </DashboardButton>
  );
}

export default DropdownFiltersButtonClear;
