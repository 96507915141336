import { createSlice } from '@reduxjs/toolkit';

export const swapSlice = createSlice({
  name: 'swap',
  initialState: {
    selected_swap: null,

  },
  reducers: {
    setSelectedSwap: (state, payload) => {
      state.selected_swap = payload.payload;
    },

  },
});

export const {
  setSelectedSwap,

} = swapSlice.actions;

export default swapSlice.reducer;
