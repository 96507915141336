import { ReactComponent as PDFIcon } from '../../../../../../../assets/images/icons/pdf.svg';
import Dropdown from '../../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../../assets/images/icons/dashboard/more.svg';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStoreInvoice } from '../../../../../../../api/customer/invoice';
import { setRefetchInvoices } from '../../../../../../../store/invoice/invoiceSlice';
import { useDispatch } from 'react-redux';

function InvoicesTableActions({
  invoice,
  onDelete = null,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    mutate: mutateStoreInvoice,
    isLoading: isStoreLoading,
  } = useStoreInvoice();

  const handleDeleteClick = useCallback(() => {
    onDelete && onDelete(invoice);
  }, [invoice, onDelete]);

  const handleViewCustomerClick = useCallback(() => {
    if (!invoice._data.customer) return;
    navigate(
      `/dashboard/customers/${invoice._data.customer?.id}`
    );
  }, [invoice, navigate]);

  const handleDuplicateClick = useCallback(() => {
    if (isStoreLoading) return;

    const data = {
      customer: invoice._data.customer.id,
      currency: invoice._data.currency,
      crypto_payment_padding:
        invoice._data.crypto_payment_padding,
    };

    if (invoice._data?.crypto_payment_currencies.length) {
      data.crypto_payment_currencies =
        invoice._data.crypto_payment_currencies;
    }
    if (invoice._data?.custom_fields) {
      data.custom_fields = invoice._data.custom_fields;
    }
    if (invoice._data?.footer) {
      data.footer = invoice._data.footer;
    }
    if (invoice._data?.memo) {
      data.memo = invoice._data.memo;
    }

    mutateStoreInvoice(data, {
      onSuccess: (data) => {
        dispatch(setRefetchInvoices(Date.now()));
        navigate(`/dashboard/invoices/${data.data?.id}`);
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  }, [
    invoice,
    mutateStoreInvoice,
    navigate,
    isStoreLoading,
    dispatch,
  ]);

  const dropDownItems = useMemo(() => {
    const _items = [];

    if (invoice.status !== 'draft') {
      _items.push({ label: 'Download', icon: <PDFIcon /> });
    }

    _items.push({
      label: 'Duplicate',
      onClick: handleDuplicateClick,
    });

    if (invoice.status === 'draft') {
      _items.push({
        label: 'Delete Draft',
        className: 'text-danger font-semibold',
        onClick: handleDeleteClick,
      });
    }

    _items.push({
      label: 'View Customer',
      onClick: handleViewCustomerClick,
    });

    return _items;
  }, [
    invoice,
    handleDeleteClick,
    handleViewCustomerClick,
    handleDuplicateClick,
  ]);

  return (
    <Dropdown items={dropDownItems}>
      <Dropdown.Slot name='toggle'>
        <MoreIcon />
      </Dropdown.Slot>
    </Dropdown>
  );
}

export default InvoicesTableActions;
