import "./SectionFeatures.scss";
import Stack from "../../../Common/Stack/Stack";

const featuresData = [
  {
    icon: "2fa",
    title: "Two-factor Authentification",
    text: `Secure your account with Two-factor authentification. 
      This allows secure processing of funds into and out of your Walio accounts.`
  },
  {
    icon: "teams",
    title: "Teams",
    text: `Create teams in your application space, to allow your staff to 
      handle and process payments, refunds, data and product management. 
      Assign roles for each staff member to restrict what they can and cannot do.`
  },
  {
    icon: "auto-convert",
    title: "Auto-Convert",
    text: `We understand that the volatility of cryptocurrency has made a lot of 
      merchants turn away from accepting cryptocurrency as a payment option. 
      Secure your revenue by choosing to have your receiving payment automatically 
      converted into a cryptocurrency Stable Coin. <span class="block">** Fees Apply</span>`
  },
  {
    icon: "currency",
    title: "Currency Swaps",
    text: `Walio has an integrated exchange system, to allow you to swap between currencies with ease. 
      Convert your crypto to any of our supported cryptocurrencies and allow yourself to cash out faster.`
  },
  {
    icon: "token",
    title: "Token Creation",
    text: `Create your own cryptocurrency, to award your customers or users. 
      Walio allows you to create your own BEP20, ERC20 or TRC20 token all accessible through your client dashboard.`
  },
  {
    icon: "nft",
    title: "Non-Fungible Token (NFT) Creation",
    text: `Create your own NFT’s with ease in your client dashboard. 
      They can used within your application or website for 
      customer/user ranks, trophies, in game assets and much, much more!`
  },
  {
    icon: "reward",
    title: "Reward Pool Creations",
    text: `Designed specifically for game developers, you can create your own Reward Pools, 
      to reward your users within your game. Set predefined parameters for reward pool 
      eligibility and automated payment regimes.`
  },
  {
    icon: "wallet",
    title: "Connected Wallets",
    text: `Create connected wallets all linked to your primary Application. 
      This allow you to manage your users transactions more efficiently and effectively, 
      rapidly tokenizing your product.`
  },
  {
    icon: "discount",
    title: "Discounts",
    text: `Create and allow your customers to use discounts and promotional codes on purchases. 
      Make them product specific, or only active when payment amounts reach a certain value, the choice is yours.`
  },
  {
    icon: "webhook",
    title: "Webhooks",
    text: `Receive real-time notifications to your backend when certain events happen on your account.`
  }
]

function SectionFeatures() {
  return (
    <div className="section-features">
      <div className="app-container">
        <Stack columns="1" className="md:grid-cols-2">
          <div>
            <h3 className="h3 section-features__title">Features</h3>
            <p className="text section-features__text">
              Walio has a range of features to make your crypto payment secure,
              easier, and less volatile. They create benefits.
            </p>
          </div>
        </Stack>
        <Stack columns="1" gap="16" className="lg:grid-cols-3 md:grid-cols-2 section-retail__features gap-x-5">
          {featuresData.map((feature, index) => {
            const SVGIcon = require(`../../../../assets/images/sections/features/${feature.icon}.svg?svgr`).default;
            return (
              <div key={index} className="section-retail__features__content">
                <div className="section-retail__features__content__icon">
                  <SVGIcon />
                </div>
                <h5 className="h5 section-products__section__info section-retail__features__content__title">
                  {feature.title}
                </h5>
                <p
                  dangerouslySetInnerHTML={{__html: feature.text}}
                  className="text-small section-retail__features__content__text lg:pr-14 pr-0" />
              </div>
            )
          })}
        </Stack>
      </div>
    </div>
  )
}

export default SectionFeatures;
