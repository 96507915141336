import Label from "../../../../../../../Common/Form/Label/Label";
import Select from "../../../../../../../Common/Form/Select/Select";
import React from "react";
import {setFormSchemaDiscount} from "../../../../../../../../store/discount/discountSlice";
import {useDispatch, useSelector} from "react-redux";
import InfoIconImage from "../../../../../../../../assets/images/icons/info.svg";

function DiscountsModalCreateType({ setValue, trigger, type }) {
  const dispatch = useDispatch();
  const { form_schema_discount, selected_discount } = useSelector((state) => state.discount);

  const handleTypeChange = (e) => {
    setValue("type", e?.value);
    trigger("type");

    dispatch(setFormSchemaDiscount({
      ...form_schema_discount,
      type: e?.value
    }));
  }

  return (
    <div>
      <Label
        title="Type"
        icon={InfoIconImage}
        tooltipFor="discount-modal-create"
        tooltipHtml={true}
        tooltip={`
          <strong>Discount Types:</strong>
          <li class="font-semibold">Standard</li>
          A Standard discount
          <li class="font-semibold">Sale Amount</li>
          Will be active when the total sale value is ≥ this amount
          <li class="font-semibold">Product Specific</li>
          Will be active for specific products
          <li class="font-semibold">Product Quantity</li>
          Will be active for specific products when their quantity is ≥ this amount
        `}
      />
      <Select
        onSelect={handleTypeChange}
        options={[
          { label: "Standard Discount", value: "standard" },
          { label: "Sale Amount", value: "amount_gte" },
          { label: "Product Specific", value: "product" },
          { label: "Product Quantity", value: "product_quantity" },
        ]}
        value={type}
        disabled={selected_discount?.id}
      />
    </div>
  )
}

export default DiscountsModalCreateType;