import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import styled, { keyframes } from "styled-components";
import ReactTooltip from "react-tooltip";
import Modal from "../../../../../../Common/Modal/Modal";
import Button from "../../../../../../Common/Form/Button/Button";
import Stack from "../../../../../../Common/Stack/Stack";
import {
  useSignMerchantUpload,
  useStoreProduct,
  useUpdateProduct,
} from "../../../../../../../api/product/product";

import { setRefetchProduct } from "../../../../../../../store/product/productSlice";

import ProductsModalCreateName from "../ProductsModalCreateForm/ProductsModalCreateName";
import ProductsModalCreateDescription from "../ProductsModalCreateForm/ProductsModalCreateDescription";
import ProductsModalCreateUpload from "../ProductsModalCreateForm/ProductsModalCreateUpload";

import ProductsModalShippable from "../ProductsModalCreateForm/ProductsModalShippable";
import ProductsModalUrl from "../ProductsModalCreateForm/ProductsModalUrl";
import ProductsModalUnitLabel from "../ProductsModalCreateForm/ProductsModalUnitLabel";
import ProductsModalCreatePrice from "./ProductsModalCreatePrice";
import ProductsModalCreateBillingType from "./ProductsModalCreateBillingType";
import ProductsModalCreateMetered from "../ProductsModalCreateForm/ProductsModalCreateMetered";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/icons/dashboard/delete.svg";
import { ReactComponent as More } from "../../../../../../../assets/images/icons/dashboard/more.svg";
import { ReactComponent as ArrowDown } from "../../../../../../../assets/images/icons/dashboard/arrow_down.svg";

import { PRICE_REGEX } from "../../../../../../../utils/constants";
import {
  currencyConverter,
  currencyFormatter,
  currencySymbol,
  sanitizeUnitAmount,
} from "../../../../../../../utils/string";
import {
  getBillingPeriod,
  newPriceDescription,
  validateTiers,
} from "utils/price_utils";
import { useStorePrice } from "../../../../../../../api/product/price";
import StandardPricing, {
  StandardPricingCreate,
} from "../../ProductsModalDetails/ProductDetails/StandardPricing";
import { PRODUCT_MODEL_VALUES } from "../../ProductsModalDetails/ProductDetails/ProductAddPriceModal";

import ProductPricingDropDown from "../../ProductsModalDetails/ProductDetails/ProductPricingDropDown";
import CustomerChooses, {
  CustomerChoosesCreate,
} from "../../ProductsModalDetails/ProductDetails/CustomerChooses";
import PackagePricing, {
  PackagePricingCreate,
} from "../../ProductsModalDetails/ProductDetails/PackagePricing";
import TieredPricing, {
  TieredPricingCreate,
} from "../../ProductsModalDetails/ProductDetails/TieredPricing";
import Dropdown from "components/Common/Dropdown/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
const max_prices = 5;
export function AddPrice({
  id,
  price,
  registerData,
  fieldError,
  setexpanded,
  expanded,
  prices,
  updatedata,
  errorss,
  watch,
  control,
  setValue,
  duplicatePrice,
  deletePrice
}) {
  const navigate = useNavigate();
  const location = useLocation();

  console.log('prices: ', prices);
  console.log('Expanded: ', expanded);
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `prices.${id}.tiers`,
  });

  const errors = watch(`prices.${id}.errors`);

  const [selectedProductModel, setSelectedPriceModel] = useState(
    price?.mode ?? PRODUCT_MODEL_VALUES.STANDARD
  );

  const handleProductPricingModelChange = (option) => {
    const priceModel = Object.values(PRODUCT_MODEL_VALUES).find(
      (item) => item.id === option.value
    );

    setSelectedPriceModel(priceModel);

    updatedata(id, {
      ...prices[id],
      mode: priceModel,
      billing_scheme:
        priceModel.id === "tiered_pricing" ? "tiered" : "per_unit",
    });
  };

  // Don't know what this does?
  useEffect(() => {
    console.log("Errors  is", errors);
  }, [errors]);

  //   useEffect(() => {

  //       // navigate(location.pathname + "/add_price");

  //     return () => {
  //       navigate(location.pathname)
  //     };
  // }, [])
  return (
    <AddPriceStyled>
      <div className="w-full flex flex-col" onClick={() => {
        var test = expanded;
        for (var i = 0; i < test.length; i++) {
          if (i === id) test[i] = !test[i];
          else test[i] = false;
        }
        setexpanded([...test]);
      }}>
        <div

          className="header">
          <div
            className={
              "title  truncate "
              + (errors.length && !expanded[id] ? "text-danger" : "")
            }
          >
            {expanded[id] ? "Pricing details" : newPriceDescription(price)}
            {errors.length > 0 &&
              !expanded[id] &&
              <span className="text-xs ml-2 text-danger ">
                {"This price has an error!"}
              </span>
              // errors.map((error, index) => {
              //   console.log(error);
              //   return (
              //     <span className="text-xs ml-2 text-danger ">
              //       {error.message}
              //     </span>
              //   );
              // })
            }
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="buttons"
          >
            <Dropdown
              items={
                prices.length < max_prices
                  ? [
                    {
                      label: "Duplicate",
                      onClick: (e) => {
                        duplicatePrice(id);
                      },
                    },
                    {
                      label: "Delete",
                      className: "text-danger font-semibold",
                      onClick: (e) => {
                        deletePrice(id);
                      },
                    },
                  ]
                  : [
                    {
                      label: "Delete",
                      className: "text-danger font-semibold",
                      onClick: (e) => {
                        deletePrice(id);
                      },
                    },
                  ]
              }
            >
              <Dropdown.Slot name="toggle">
                <More />
              </Dropdown.Slot>
            </Dropdown>
            <ArrowDown
              onClick={() => {
                var test = expanded;
                for (var i = 0; i < test.length; i++) {
                  if (i === id) test[i] = !test[i];
                  else test[i] = false;
                }
                setexpanded([...test]);
              }}
              className={expanded[id] ? "up" : ""}
            />
          </div>
        </div>
      </div>
      {true && (
        <div className={expanded[id] ? "body expanded" : "body closed"}>
          <ProductPricingDropDown
            price={false}
            optionsTitle={"Pricing Model"}
            options={Object.values(PRODUCT_MODEL_VALUES)}
            selectedOption={price.mode}
            onSelectOption={handleProductPricingModelChange}
          />
          {selectedProductModel.id === PRODUCT_MODEL_VALUES.STANDARD.id && (
            <StandardPricingCreate
              index={id}
              register={registerData}
              fieldError={fieldError}
              type={price?.type}
              metered={price?.metered}
              billing_period={price?.billing_period}
              update={updatedata}
              prices={prices}
              errors={errorss}
            />
          )}
          {selectedProductModel.id === PRODUCT_MODEL_VALUES.CUSTOMER_CHOOSES.id && (
            <CustomerChoosesCreate
              index={id}
              fieldError={fieldError}
              register={registerData}
              update={updatedata}
              currency={price?.currency}
              prices={prices}
              preset={price?.custom_unit_amount?.preset}
              minimum={price?.custom_unit_amount?.minimum}
              maximum={price?.custom_unit_amount?.maximum}
            />
          )}
          {selectedProductModel.id === PRODUCT_MODEL_VALUES.PACKAGE.id && (
            <PackagePricingCreate
              register={registerData}
              fieldError={fieldError}
              type={price?.type}
              metered={price?.metered}
              update={updatedata}
              prices={prices}
              errors={errorss}
              index={id}
            />
          )}
          {selectedProductModel.id === PRODUCT_MODEL_VALUES.TIERED.id && (
            <>
              <TieredPricingCreate
                // formMethods={""}
                tierFields={fields}
                tierAppend={append}
                tierRemove={remove}
                tierUpdate={update}
                setValue={setValue}
                prices={prices}
                errors={errors}
                tiers_mode={price?.tiers_mode}
                tiers={price?.tiers}
                billing_scheme={price?.billing_scheme}
                currency={price?.currency}
                register={registerData}
                type={price?.type}
                metered={price?.metered}
                fieldError={fieldError}
                index={id}
                update={updatedata}
                otherErrors={errorss}
              />
            </>
          )}
        </div>
      )}
    </AddPriceStyled>
  );
}

const AddPriceStyled = styled.div`
  border-bottom: 1px solid #e5e7eb;
  height: auto;
  width: 60%;
  .up {
    transform: rotate(180deg);
  }

  > .body {
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 0px;

    /* Set our transitions up. */
    /* transition: all 500ms ease-in-out; */
  }

  > .expanded {
    max-height: 700px;
    max-width: 550px;
    padding: 10px 0px 10px 0px;
  }

  > .closed {
    max-height: 0;
    overflow: hidden;
  }

 .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0px;
    > .buttons {
      /* background-color: black; */
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      /* background-color: #e5e7eb; */
      > svg {
        margin: 6px;
        > path {
          fill: #e5e7eb;
          stroke: #e5e7eb;
        }
      }
    }
  }
`;
