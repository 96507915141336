import {useEffect, useMemo, useState} from "react";

const useTableFilters = ({filters = [], defaultFilter = null}) => {
  const [currentFilter, setCurrentFilter] = useState(defaultFilter ?? filters[0]);

  useEffect(() => {
    setCurrentFilter(defaultFilter);
  }, [defaultFilter]);

  const setFilter = (filter) => {
    setCurrentFilter(filter);
  }

  const isFilterActive = (filter) => {
    return currentFilter &&
      currentFilter.column === filter.column &&
      currentFilter.value === filter.value;
  }

  const activeFilter = useMemo(() => {
    if (!currentFilter) return null;

    return { [currentFilter.column]: currentFilter.value };
  }, [currentFilter]);

  return {
    activeFilter,
    setFilter,
    isFilterActive
  }
}

export default useTableFilters;
