import Alert from "../../../../Common/Alert/Alert";
import DashboardTable from "../../../../Dashboard/DashboardTable/DashboardTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetchApiKeys, useRevealSecretKey } from "../../../../../api/api_key/api_key";
import { capitalize } from "../../../../../utils/string";
import InfoIcon from "../../../../../assets/images/icons/info.svg";
import { ReactComponent as MoreIcon } from "../../../../../assets/images/icons/dashboard/more.svg";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import useTablePagination from "../../../../../hooks/useTablePagination";
import Dropdown from "components/Common/Dropdown/Dropdown";
import moment from "moment";
import { ToolTip } from "pages/Dashboard/Balance/Swaps/SwapsTable";
import { useNavigate } from "react-router-dom";

function APIKeysTable({
  showRoll = null,
  showDelete = null,
  showEdit = null,
  setKey = null,
  type = "standard",
  setShowCreate = null,
  fetchChanges,
}) {
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage
  } = useTablePagination(data, tableRows, null, 1);

  const navigate = useNavigate();

  const {
    mutate: mutateRevealSecretKey,
    isLoading: isLoadingReveal,
    isError: isErrorReveal,
    error: errorReveal
  } = useRevealSecretKey();

  useEffect(() => {
    setRefetch(true);
    ReactTooltip.rebuild();
  }, [current_account]);

  useEffect(() => {
    if (requestQuery) setRefetch(true);
    ReactTooltip.rebuild();
  }, [requestQuery]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const updateTableRow = (index, data) => {
    setTableRows((state) =>
      state.map((row, i) => {
        if (i === index) {
          return { ...row, ...data };
        }
        return row;
      })
    );
  }

  const onRevealSecretKeySuccess = useCallback((data, cell) => {
    const newData = {
      api_key: data.data.api_key,
      hidden_on_next_view: data.data.hidden_on_next_view,
      revealed: true
    };
    updateTableRow(cell.row.index, newData);
  }, []);

  const revealSecretKey = useCallback((id, cell) => {
    if (cell.row.original.revealed) {
      const newData = {
        api_key: cell.row.original.api_key_redacted,
        hidden_on_next_view: true,
        revealed: false
      };
      updateTableRow(cell.row.index, newData);
      return;
    }

    mutateRevealSecretKey(id, {
      onSuccess: (data) => onRevealSecretKeySuccess(data, cell)
    })
  }, [mutateRevealSecretKey, onRevealSecretKeySuccess]);

  const onFetchApiKeysSuccess = (data) => {
    console.log("API data>", data);
    setData(data);
    const tableData = data.data.map((row) => {
      return {
        name: row.name ?? `${capitalize(row.type)} Key`,
        api_key: keyNeedsReveal(row.type) ? row.api_key_redacted : row.api_key,
        api_key_redacted: keyNeedsReveal(row.type) ? row.api_key_redacted : "",
        hidden_on_next_view: row.hidden_on_next_view,
        type: row.type,
        note: row.note,
        revealed: false,
        id: row.id,
        created_at: moment.unix(row.created_at).format("DD MMM YYYY HH:mm"),
        last_used: row.last_used ? moment.unix(row.last_used).format("DD MMM YYYY HH:mm") : "-",
        delete_at: row.delete_at ? moment.unix(row.delete_at).format("DD MMM YYYY HH:mm") : "-",
        delete_at_unix: row.delete_at,
      }
    }).filter((row) => {
      if (type === "standard")
        return row.type === "publishable" || row.type === "secret";
      return row.type === type;
    });
    setTableRows(tableData);
    if (setShowCreate) setShowCreate(data.count < 50);
    setRefetch(false);
  }

  const onFetchApiKeysError = () => {
    setRefetch(false);
  }

  const { isFetching: isFetchingData } = useFetchApiKeys({
    onSuccess: onFetchApiKeysSuccess,
    onError: onFetchApiKeysError,
    enabled: refetch,
    params: {
      limit: 50,
      ...requestQuery
    }
  }, [fetchChanges]);

  const keyNeedsReveal = (type) => {
    return type === "secret" || type === "restricted";
  }

  const tableColumns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      maxWidth: 80,
    },
    {
      Header: "Key",
      accessor: "api_key",
      minWidth: 320,
      Cell: (cell) => {
        return (
          <div className="">
            <span className="mr-1 break-all">{cell.value}</span>
            {keyNeedsReveal(cell.row.original.type) &&
              <>
                {cell.row.original.hidden_on_next_view === false &&
                  <button
                    onClick={() => revealSecretKey(cell.row.original.id, cell)}
                    className="app-table__pagination__button"
                    disabled={isLoadingReveal}
                  >
                    {cell.row.original.revealed
                      ? "Hide"
                      : `Reveal ${isLoadingReveal ? "..." : ""}`
                    }
                  </button>
                }
                <div className="mt-1">
                  <ToolTip
                    tooltip={cell.row.original.note ?? "Add a note to your Key to remind you where it is stored"}
                    alt="Note"
                    icon={InfoIcon}
                    tooltipFor={'tooltip-api'}
                    tooltipHtml={true}
                  />
                  {/* <img
                    data-tip={cell.row.original.note ?? "Add a note to your Key to remind you where it is stored"}
                    src={InfoIcon}
                    alt="Note"
                  /> */}
                </div>
                {/* <ReactTooltip
                  place="right"
                  effect="solid"
                  type="light"
                  padding="10px"
                  textColor="#1A1E23"
                  backgroundColor='#ffffff'
                  className='api-key__tooltip'
                /> */}
              </>
            }
          </div>
        );
      }
    },
    {
      Header: "Last Used",
      accessor: "last_used",
      minWidth: 60,
      maxWidth: 90,
      Cell: (cell) => {
        return (
          <div>
            {cell.value}
          </div>
        );
      }
    },
    {
      Header: "Created",
      accessor: "created_at",
      minWidth: 60,
      maxWidth: 90,
      Cell: (cell) => {
        return (
          <div>
            {cell.value}
          </div>
        );
      }
    },
    {
      Header: "Deletes At",
      accessor: "delete_at",
      minWidth: 60,
      maxWidth: 90,
      Cell: (cell) => {
        return (
          <div>
            {cell.value}
          </div>
        );
      }
    },
    {
      Header: "",
      accessor: "icon",
      maxWidth: 30,
      Cell: (cell) => {
        const items = [
          {
            label: "Delete",
            className: "text-danger font-semibold",
            onClick: (e) => {
              setKey(cell.row.original);
              showDelete();
            },
          },
        ];

        if (cell.row.original.type === "secret" && typeof cell.row.original.delete_at_unix !== 'number') {
          items.unshift({
            label: "Roll api key",
            onClick: (e) => {
              setKey(cell.row.original);
              showRoll();
            },
          });
        }

        if (cell.row.original.type !== "publishable") {
          items.unshift({
            label: "Edit",
            onClick: (e) => {
              setKey(cell.row.original);
              showEdit();
              // navigate(`/dashboard/test/api_keys/edit/${cell.row.original.id}`);
            },
          });
        }

        return (
          <>
            {cell.row.original.type === "publishable" ? <></> : (<div>
              <Dropdown
                items={items}
              >
                <Dropdown.Slot name="toggle">
                  <MoreIcon />
                </Dropdown.Slot>
              </Dropdown>
            </div>)}
          </>)
      }
    }
  ], [revealSecretKey, isLoadingReveal]);

  return (
    <>
      {isErrorReveal &&
        <Alert
          className="mb-4"
          type="danger"
          message={errorReveal?.response.data.error.message}
        />
      }
      <DashboardTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="API Keys"
        paginated={false}
      />
    </>
  )
}

export default APIKeysTable;
