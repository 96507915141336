import Stack from "../../../Common/Stack/Stack";
import "./SectionFlexible.scss";
import Button from "../../../Common/Form/Button/Button";
import {Link} from "react-router-dom";

function SectionFlexible() {
  return (
    <div className="section-flexible">
      <div className="w-1/2 section-flexible__left-side md:block hidden" />
      <div className="w-1/2 section-flexible__right-side md:block hidden" />
      <div className="app-container section-flexible__left-side md:hidden block" style={{marginRight: '0', marginLeft: 'auto'}} />
      <div className="app-container section-flexible__container">
        <h3 className="h3 section-flexible__solution__title">A flexible payments solution</h3>
        <Stack columns="1" gap="4" className="lg:grid-cols-2 section-flexible__solution">
          <div className="section-flexible__solution__content">
            <p className="text section-flexible__solution__text">
              Walio integrates a host of features to allow websites,
              and apps <span>accept and tokenize cryptocurrency with easy</span>.
              Our products facilitate simple payment creations for quick transactions,
              invoicing, order building and subscription based business models.
            </p>
          </div>
          <div className="section-flexible__solution__content z-10 md:mt-0 mt-10">
            <p className="text section-flexible__solution__text mb-20">
              Whether you are an online or in-person retailer,
              marketplace, service provider, game developer,
              freelancer or anything in between,
              Walio has an <span>easy solution for your cryptocurrency payment needs</span>.
            </p>
            <Link to="/register">
              <Button outline className="section-flexible__solution__button">
                Start Accepting Crypto
              </Button>
            </Link>
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default SectionFlexible;
