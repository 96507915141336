import { useEffect, useMemo, useState } from 'react';
// import { ReactComponent as DateIcon } from '../../../../../../assets/images/icons/date.svg';
import { useDispatch, useSelector } from 'react-redux';
import LabelTag from 'components/Common/LabelTag/LabelTag';
// import {
//   useDeleteInvoice,
//   useFetchInvoices,
// } from '../../../../../../api/customer/invoice';
import clsx from 'clsx';
import {
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
  formatCryptoValueLength,
} from 'utils/string';
// import { dateFormatter } from '../../../../../../utils/date';
// import {
//   setRefetchInvoices,
//   setSelectedInvoice,
// } from '../../../../../../store/invoice/invoiceSlice';
import { toast } from 'react-toastify';
// import InvoicesTableActions from './InvoicesTableActions/InvoicesTableActions';
// import useTablePagination from '../../../../hooks/useTablePagination';
import useTablePagination from 'hooks/useTablePagination';
import { useNavigate } from 'react-router-dom';
import ModalDelete from 'components/Dashboard/Modals/ModalDelete/ModalDelete';
import DashboardTable from 'components/Dashboard/DashboardTable/DashboardTable';
import { useFetchBalanceTransactions } from 'api/wallet/balance_transactions';
import { useFetchBalance } from 'api/wallet/balance';
import crypto_currencies from 'utils/crypto_currencies';
import styled from 'styled-components';
import "./Balance.scss";
import TopupsCreateModel from './TopUps/TopupsCreateModel';
import PayoutsCreateModal from './Payouts/PayoutsCreateModal';
import TransferCreateModal from './Transfers/TransferCreateModal';
import SwapsCreateModal from './Swaps/SwapsCreateModal';
function BalanceTable({ filters = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [tableRows2, setTableRows2] = useState([]);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [crypto, setcrypto] = useState([]);

  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(crypto, tableRows2 ,filters, 5);
  useEffect(() => {

    setRows(tableRows)
  }, [tableRows])
const genrateTablerowsData = (fiat)=>{
  var data = [];
  
    for (const elem of fiat.available)
    {
        let newobj = {} ;
        newobj.currency = currencySymbol(elem.currency) +  elem.currency;
        
        newobj.available = currencyFormatter(elem.currency, elem.amount) ;
        var i  = fiat.pending.findIndex(x => x.currency === elem.currency);
        if (i === -1)
        { 
            newobj.pending =  currencyFormatter(elem.currency, 0);
        }
        else
        newobj.pending = currencyFormatter(elem.currency, fiat.pending[i].amount);
        data.push(newobj);
    }
    return data;
}

const genrateTableCryptorowsData = (crypto)=>{
  var data = [];
    for (const elem of crypto.available)
    {
        let newobj = {} ;
        
        let i  = crypto_currencies.findIndex(x => x.label === elem.crypto_currency);
        if (i !== -1) {
          newobj.crypto = {
            name: crypto_currencies[i].name,
            icon:  elem.crypto_currency ,
            code:  elem.crypto_currency,
          };

        } else {
          newobj.crypto = {
            name: elem.crypto_currency,
            icon:   elem.crypto_currency,
            code:  elem.crypto_currency,
          };
        }

        newobj.available = {
          amount: formatCryptoValueLength(elem.crypto_amount) ,
          converted_amount: elem.converted_amount,
          converted_currency: elem.converted_currency,
        };
        
        var j  = crypto.pending.findIndex(x => x.crypto_currency === elem.crypto_currency);
        newobj.pending =  {
          amount: formatCryptoValueLength(j >= 0 ? crypto.pending[j].crypto_amount : 0) ,
          converted_amount: j >= 0 ? crypto.pending[j].converted_amount : 0,
          converted_currency: elem.converted_currency
        };
        
        var j  = crypto.reserve.findIndex(x => x.crypto_currency === elem.crypto_currency);
        newobj.reserve =  {
          amount: formatCryptoValueLength(j >= 0 ? crypto.reserve[j].crypto_amount : 0) ,
          converted_amount: j >= 0 ? crypto.reserve[j].converted_amount : 0,
          converted_currency:elem.converted_currency
        };
        var j  = crypto.vault.findIndex(x => x.crypto_currency === elem.crypto_currency);
        newobj.vault =  {
          amount: formatCryptoValueLength(j >= 0 ? crypto.vault[j].crypto_amount : 0) ,
          converted_amount: j >= 0 ? crypto.vault[j].converted_amount : 0,
          converted_currency:elem.converted_currency
        };

        data.push(newobj);
    }
    return data;
}
  const onFetchBalanceSuccess = (data) => {
    console.log(data)
    setData(data);
    var tmpdata =  genrateTableCryptorowsData(data.crypto)
    const tableData = genrateTablerowsData(data.fiat).map((row) => {
      return {
        currency: row.currency,
        available: row.available,
        pending: row.pending,
        _data: row,
      };
    });
    // sort by available converted amount

    setcrypto(tmpdata)
    const tableDataCrypto = tmpdata.map((row) => {
      return {
        crypto: row.crypto,
        available: row.available,
        pending: row.pending,
        reserve : row.reserve,
        vault : row.vault,


      };
    });
    setTableRows(tableData);

    tableDataCrypto.sort((a, b) => {
      return b.available.converted_amount - a.available.converted_amount;
    });

    setTableRows2(tableDataCrypto);
    setRefetch(false);
  };

  const onFetchBalanceError = (e) => {
    setRefetch(false);
  };


  const { isFetching: isFetchingData } = useFetchBalance({
    onSuccess: onFetchBalanceSuccess,
    onError: onFetchBalanceError,
    enabled: refetch,
    params: {
      expand: ['customer'],
      limit: limitQuery,
      ...requestQuery,
      ...filters,
    },
  });

  const handleRowClick = (row) => {
    // dispatch(setSelectedInvoice(row._data));
    // navigate(`/dashboard/invoices/${row.id}`);

  };

  const handleDeleteClick = (invoice) => {
    // setDeleteInvoice(invoice);
    // setShowDeleteModal(true);

  };

  const handleModalDelete = () => {
    // mutateDeleteInvoice(null, {
    //   onSuccess: () => {
    //     setDeleteInvoice(null);
    //     setShowDeleteModal(false);
    //     dispatch(setSelectedInvoice(null));
    //     dispatch(setRefetchInvoices(Date.now()));
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data.error.message);
    //   },
    // });
  };
  const onChange = (e) => {
    const value = e.target.value;
    console.log(tableRows)
    const filteredData = tableRows.filter((row) => {
      return row?.currency.toLowerCase().includes(value.toLowerCase());
    });
    setRows(filteredData);
  };
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Currency',
        accessor: 'currency',
      // width: 60,
      minWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div
              className='text-sm font-semibold'
              style={{ fontSize: '1.2rem' }}>
              {row.currency?.toUpperCase()}
            </div>
          );
        },
      },
      {
        Header: 'Available',
        accessor: 'available',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div
            className='text-sm font-semibold'
            style={{ fontSize: '1.2rem' }}>
            {row.available}
          </div>
          );
        },
      },
      {
        Header: 'Pending',
        accessor: 'pending',
        maxWidth: 60,

        // width: 1,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <>
              <div
              className='text-sm font-semibold'>
              {row.pending}
            </div>
            </>
          );
        },
      },
     
      {
        Header: 'actions',
        accessor: 'actions',

        id: 'icon',
        // Width: 70,
        Cell: (cell) => (
            <Actions>
                <button>Payout</button>
                <button>Swap</button>
                <button
                onClick={() => {
                    navigate(`/dashboard/balance_transactions?currency=${cell.row.original.currency}`);
                }}
                >View Txns</button>
            </Actions>
        ),
      },
    ],
    []
  );

  return (
    <>
    <div className="header">
        Fiat Currencies
        <div className='searchBar'>
          <input
          onChange={onChange}
          
          type="text" placeholder="Search" />
        </div>
    </div>
      <div className='mt-6'>
        <DashboardTable
          columns={tableColumns}
          data={rows}
          clickable
          onRowClick={handleRowClick}
          pageCount={pageCount}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          loading={isFetchingData}
          count={dataCount}
          noRecordTag="balance"
          paginated={false}
        />

      </div>
      <CryptoTable  data={crypto} tableRows={tableRows2} isFetchingData={isFetchingData} filters={filters}/>
    </>
  );
}

export  function CryptoTable({data ,tableRows,  isFetchingData , filters}) {
  const navigate = useNavigate();
  const [modalStates, setModalStates] = useState({
    topup : false,
    payout : false,
    swap : false,
    transfer : false,
  })
  const [ currency , setCurrency] = useState('')
  const [ toCurrency , setToCurrency] = useState('')
  const [rows, setRows] = useState([])
  const [page, setpage] = useState(0)
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,

  } = useTablePagination(data, tableRows ,filters, 10)

  function chunkArray(arr , n) {
    const chunkedArr = [];
    let i = 0;
    while (i < arr.length) {
      chunkedArr.push(arr.slice(i, i + n));
      i += n;
    }
    return chunkedArr;
  }


    useEffect(() => {
      setRows(tableRows)
    }, [tableRows])

    const getIconUrl = (icon) => {
      try {
        return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
      } catch (err) {
        return null;
      }
    };
  const tableColumns2 = useMemo(
    () => [
      {
        Header: 'Currency',
        accessor: 'currency',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div className='flex items-center w-full'> 
             
                <div  className='min-h-[25px] min-w-[25px] w-[25px] h-[25px] overflow-hidden rounded-full 
                 '>
                  <img
                    className='w-full h-full object-cover'
                  alt='crypto'
                  src={getIconUrl(row?.crypto?.icon.toLowerCase())}
                  />
                 {row?.crypto?.icon}
                </div>
              <div className='flex text-sm  m-[5px] flex-col leading-4
              ' >
                    {row?.crypto?.code}
                  <span className='truncate
                  '>{row?.crypto?.name}</span> 
              </div>
            </div >
          );
        },
      },
      {
        Header: 'Available',
        accessor: 'available',
        maxWidth: 60,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div 
      
              style={{ fontSize: '1rem' }}>
              <div className='font-semibold mb-1'>
                {row?.available?.amount}

              </div>
              <span>{currencyPrettier(row?.available?.converted_currency, row?.available?.converted_amount)}</span> 

            </div >
          );
        },
      },
      {
        Header: 'Pending',
        accessor: 'pending',
        maxWidth: 60,

        // width: 1,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div 
      
              style={{ fontSize: '1rem' }}>
              <div className='font-semibold mb-1'>
              {row?.pending?.amount}

            </div>
            <span>{currencyPrettier(row?.pending?.converted_currency, row?.pending?.converted_amount)}</span> 

          </div>
          );
        },
      },
      {
        Header: 'Reserve',
        accessor: 'reserve',
        maxWidth: 60,

        // width: 1,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div 
      
              style={{ fontSize: '1rem' }}>
              <div className='font-semibold mb-1'>
              {row?.reserve?.amount}

            </div>
            <span>{currencyPrettier(row?.reserve?.converted_currency, row?.reserve?.converted_amount)}</span> 

          </div>
          );
        },
      },
      {
        Header: 'vault',
        accessor: 'vault',
        maxWidth: 60,

        // width: 1,
        Cell: (cell) => {
          const row = cell.row.original;

          return (
            <div
      
            style={{ fontSize: '1rem' }}>
            <div className='font-semibold mb-1'>
              {row?.vault?.amount}

            </div>
            <span>{currencyPrettier(row?.vault?.converted_currency, row?.vault?.converted_amount)}</span> 

          </div>
          );
        },
      },
     
      {
        Header: 'actions',
        accessor: 'pending',
        id: 'icon',

        Cell: (cell) => {
          const row = cell.row.original;
        
            return <Actions>
                <button onClick={()=>{
                  setCurrency(row?.crypto?.code)
                  setModalStates({...modalStates, topup : true})
                  }} >Topup</button>
                <button onClick={()=>{
                  setCurrency(row?.crypto?.code)
                  setModalStates({...modalStates, payout : true})
                  }} 
                >Payout</button>
                <button
                  onClick={()=>{
                      setCurrency(row?.crypto?.code)

                      // get next currency  after row?.crypto to swap to
                    console.log(tableRows[tableRows.indexOf(row) + 1])
                     setToCurrency(tableRows[tableRows.indexOf(row) + 1]?.crypto?.code || null)

                    setModalStates({...modalStates, swap : true})
                  }}
                >Swap</button>
                <button 
                  onClick={()=>{
                    setCurrency(row?.crypto?.code)
                    setModalStates({...modalStates, transfer : true})
                  }}

                >Transfer</button>
                <button onClick={()=>{
                  navigate(`/dashboard/balance_transactions?currency=${row?.crypto?.code}`)
                }} >View Txns</button>

            </Actions>

              },
      },
    ],
    []
  );
  const onChange = (e) => {
    const value = e.target.value;
    const filteredData = tableRows.filter((row) => {
      return row?.crypto?.code.toLowerCase().includes(value.toLowerCase());
    });
    setRows(filteredData);
  };

  return (
    <>
      <div className='header'>
        Crypto Currencies
        <div className='searchBar'>
          <input
          onChange={onChange}
          
          type="text" placeholder="Search" />
        </div>
      </div>
      <div className='mt-6'>
        <DashboardTable
              columns={tableColumns2}
              data={rows}
              clickable
              onRowClick={()=>{}}
              pageCount={pageCount}
              onPreviousPage={()=>{onPreviousPage() ;setpage(page - 1)}}
              onNextPage={()=>{onNextPage() ;setpage(page + 1) }}
              canNextPage={page === rows.length - 1 ? false : true}
              canPreviousPage={page === 0 ? false : true}
              loading={isFetchingData}
              count={dataCount}
              noRecordTag="balance"
              paginated={false}
            />
        </div>
        {
          modalStates?.topup && <TopupsCreateModel
          onClose={()=>{setModalStates({...modalStates, topup: false})}}
           show={modalStates.topup} 
           selectedCurrency={currency}
           />
           
        }
         {
          modalStates?.payout && <PayoutsCreateModal
          onClose={()=>{setModalStates({...modalStates, payout: false})}}
            show={modalStates.payout}
            selectedCurrency={currency}
            />
         } 
          {
            modalStates?.transfer && <TransferCreateModal
            onClose={()=>{setModalStates({...modalStates, transfer: false})}}
            show={modalStates.transfer}
            selectedCurrency={currency}
            />
          }
          {
            modalStates?.swap && <SwapsCreateModal
            onClose={()=>{setModalStates({...modalStates, swap: false})}}
            show={modalStates.swap}
            selectedFromCurrency={currency}
            selectedToCurrency={toCurrency}
            />
          }
        </>
  )
}


const CryptoCyrrencyStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 30px;
  > .img{
    width: 25px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      min-height: 25px;
      min-width: 25px;
      border-radius: 50%;
      overflow: hidden;
    >svg{
      width: 25px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      min-height: 25px;
      min-width: 25px;
  
      margin-right:  8px;
    }
  }
  > div{
    font-size: 14px;
    line-height: 20px;
    margin-left: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    >span{
      margin: 0;
    }


  }

  `;

const Actions = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;


  `;
export default BalanceTable;
