import Label from 'components/Common/Form/Label/Label'
import Select from 'components/Common/Form/Select/Select'
import SelectNetwork from 'components/Dashboard/SelectNetwork/SelectNetwork'
import React from 'react'

export default function TopupCryptoModal({
    handleCurrencyChange,
    handleNetworkSelect,
    currency,
    network,
    currencies,
    networks,
    errors,
    method = null,
    isLoading,

}) {
  console.log('currencies', currencies);
  return (
    <div className='flex gap-5'>
    
    <div>
      <Label 
      title='Currency' 
      error={""}
      required={true} 
      />
        <Select
          onSelect={handleCurrencyChange}
          path="assets/images/icons/cryptos"
          style={{ minWidth: "150px" }}
          options={currencies}
          //left={true}
          value={currency}
        />
    </div>
    {
      isLoading ? <div>Loading...</div> :

      <div className='flex-1'>
        <Label 
          title="Blockchain Network" 
          error={errors.network}
          required={method?.value === 'crypto' ? true : false} 
        />
        <SelectNetwork
          error={errors.network}
          value={network}
          disabled={false}
          options={networks ?? []}
          onSelect={handleNetworkSelect} 
          placeholder="Select network"
        />
      </div>
    }
  </div>
  )
}
