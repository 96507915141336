import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencySymbol,
} from '../../../../../utils/string';
import ReactDOMServer from 'react-dom/server';
import DashboardButton from '../../../../Common/Form/DashboardButton/DashboardButton';
import InfoIcon from '../../../../../assets/images/icons/info.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/images/icons/dashboard/edit.svg';
import { Link } from 'react-router-dom';

const DetailRefunds = () => {
  const [showModal, setShowModal] = useState(false);
  const { selected_refund } = useSelector(
    (state) => state.refund
  );

  const { balance_transaction = null } = selected_refund;

  const handleEditClick = () => {
    setShowModal(true);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };
  return (
    <div className='space-y-1.5 pb-2'>
      <div className='flex justify-between'>
        <div className='app-modal-fullscreen__title'>
          Details
        </div>
        <div className='flex gap-4'>
          <DashboardButton
            outline
            color='light'
            size='sm'
            icon={<EditIcon />}
            onClick={handleEditClick}>
            Edit
          </DashboardButton>
        </div>
      </div>
      <div className='horizontal-divider' />
      <div className='pt-4'>
        <div className='grid grid-cols-5 gap-4'>
          <div className='col-span-2 space-y-2'>
            {selected_refund.on_behalf_of && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  On behalf of
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_refund.on_behalf_of}
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Currency
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_refund.currency?.toUpperCase()}
              </div>
            </div>
            {selected_refund.crypto_currency && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Cryptocurrency
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_refund.crypto_currency?.toUpperCase()}
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Amount
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                <div className='flex items-start gap-2'>
                  <div>
                    <div className='app-modal-fullscreen__table__amount'>
                      <div className='text-light'>
                        {currencySymbol(
                          selected_refund.currency
                        )}
                        {currencyFormatter(
                          selected_refund.currency,
                          selected_refund.amount
                        )}{' '}
                        <span>
                          {selected_refund.currency?.toUpperCase()}
                        </span>
                      </div>
                    </div>
                    {selected_refund.crypto_amount && (
                      <div className='app-modal-fullscreen__value'>
                        (
                        {cryptocurrencyFormatter(
                          selected_refund.crypto_amount
                        )}{' '}
                        {selected_refund.crypto_currency})
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selected_refund.amount !==
              selected_refund.amount_received && (
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Amount (Net)
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    <div className='flex items-start gap-2'>
                      <div>
                        <div className='app-modal-fullscreen__table__amount'>
                          <div className='text-light'>
                            {currencySymbol(
                              selected_refund.currency
                            )}
                            {currencyFormatter(
                              selected_refund.currency,
                              selected_refund
                                .balance_transaction
                                .crypto_net
                            )}{' '}
                            <span>
                              {selected_refund.currency?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            selected_refund
                              .balance_transaction.crypto_net
                          )}{' '}
                          {selected_refund.crypto_currency})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Reason
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_refund.reason ?? '-'}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Description
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_refund.description ?? '-'}
              </div>
            </div>
            {selected_refund.amount_overpaid > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Over Paid Amount
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {currencySymbol(
                            selected_refund.currency
                          )}
                          {currencyFormatter(
                            selected_refund.currency,
                            selected_refund.amount_overpaid
                          )}{' '}
                          <span>
                            {selected_refund.currency?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      {selected_refund.crypto_amount_overpaid && (
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            selected_refund.crypto_amount_overpaid
                          )}{' '}
                          {selected_refund.crypto_currency})
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selected_refund.amount_underpaid > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Under Paid Amount
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {currencySymbol(
                            selected_refund.currency
                          )}
                          {currencyFormatter(
                            selected_refund.currency,
                            selected_refund.amount_underpaid
                          )}{' '}
                          <span>
                            {selected_refund.currency?.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      {selected_refund.crypto_amount_underpaid && (
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            selected_refund.crypto_amount_underpaid
                          )}{' '}
                          {selected_refund.crypto_currency})
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selected_refund.crypto_padding_amount > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Crypto Padding Amount
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-start gap-2'>
                    <img
                      className='w-4 mt-0.5'
                      src={getIconUrl(
                        selected_refund.crypto_currency.toLowerCase()
                      )}
                      alt={selected_refund.crypto_currency}
                    />
                    <div>
                      <div className='app-modal-fullscreen__table__amount'>
                        <div className='text-light'>
                          {cryptocurrencyFormatter(
                            selected_refund.crypto_padding_amount
                          )}
                          <span>
                            {
                              selected_refund.crypto_currency
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='col-span-2 space-y-2'>
            {selected_refund.balance_transaction && (
              <>
                <div className='app-modal-fullscreen__kv'>
                  <div className='app-modal-fullscreen__kv__key'>
                    Fee
                  </div>
                  <div className='app-modal-fullscreen__kv__value'>
                    <div className='flex items-start gap-2'>
                      <div>
                        <div className='app-modal-fullscreen__table__amount'>
                          <div className='text-light'>
                            {currencySymbol(
                              balance_transaction?.currency
                            )}
                            {currencyFormatter(
                              balance_transaction?.currency,
                              balance_transaction?.fee
                            )}
                            <span>
                              {balance_transaction?.currency?.toUpperCase()}
                            </span>
                          </div>
                          {balance_transaction?.fee_details
                            .length > 0 && (
                              <img
                                className='ml-2'
                                data-for='fees-tooltip'
                                data-tip={ReactDOMServer.renderToString(
                                  <>
                                    {balance_transaction.fee_details.map(
                                      (fee, index) => (
                                        <div
                                          key={index}
                                          className='app-modal-fullscreen__table__amount w-full'>
                                          <div className='inline-flex flex-wrap gap-3'>
                                            <span>
                                              {
                                                fee.description
                                              }
                                              :
                                            </span>
                                            <div className='text-right'>
                                              <div className='font-semibold'>
                                                {currencySymbol(
                                                  fee?.currency
                                                )}
                                                {currencyFormatter(
                                                  fee?.currency,
                                                  fee?.amount
                                                )}
                                                <span>
                                                  {fee?.currency?.toUpperCase()}
                                                </span>
                                              </div>
                                              {fee.crypto_amount && (
                                                <div className='font-normal'>
                                                  (
                                                  {cryptocurrencyFormatter(
                                                    fee.crypto_amount
                                                  )}
                                                  <span>
                                                    {
                                                      fee.crypto_currency
                                                    }
                                                  </span>
                                                  )
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                                data-html={true}
                                src={InfoIcon}
                                alt='Info'
                              />
                            )}
                        </div>
                        <div className='app-modal-fullscreen__value'>
                          (
                          {cryptocurrencyFormatter(
                            balance_transaction.crypto_fee
                          )}{' '}
                          {
                            balance_transaction.crypto_currency
                          }
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selected_refund.failure_message && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Failure Message
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {selected_refund.failure_message}
                </div>
              </div>
            )}

            {selected_refund.exchange_rate > 0 && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Exchange Rate
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  <div className='flex items-center gap-2'>
                    <div className='font-semibold'>1</div>
                    <div className='flex gap-1'>
                      <div className='app-modal-fullscreen__value'>
                        {selected_refund.crypto_currency}
                      </div>
                    </div>
                    =
                    <div className='font-semibold'>
                      {currencySymbol(
                        selected_refund.currency
                      )}
                      {selected_refund.exchange_rate}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Source
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                <Link to={`/dashboard/payments/${selected_refund.source}` ?? '#'}>
                  {selected_refund.source ?? '-'}
                </Link>
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Balance Transaction
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                <Link to={`/dashboard/balance_transactions/${balance_transaction?.id}` ?? '#'}>
                  {balance_transaction?.id ?? '-'}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRefunds;
