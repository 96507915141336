import "../DashboardTable/DashboardTable.scss";
import { useTable, usePagination, useFlexLayout } from "react-table";
import clsx from "clsx";
import { DASHBOARD_TABLE_LIMIT } from "../../../utils/constants";

const defaultPropGetter = () => ({});

function DashboardModalTable({
  clickable = false,
  columns = [],
  data = [],
  state = {},
  pageCount: controlledPageCount,
  onPreviousPage = null,
  onNextPage = null,
  canPreviousPage = false,
  canNextPage = false,
  loading = false,
  count = 0,
  hideColumns = false,
  noRecordTag = null,
  ...rest
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    getColumnProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        ...state,
        pageSize: DASHBOARD_TABLE_LIMIT,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    useFlexLayout
  );

  const handleRowClick = (row, cell) => {
    if (["selection", "icon"].includes(cell.column.id)) {
      return;
    }

    clickable && rest.onRowClick && rest.onRowClick(row);
  };

  return (
    <>
      <div className={clsx(loading && "app-overlay")}>
        <div>
          <table {...getTableProps()} className="app-modal-fullscreen__table">
            {!hideColumns && (
              <thead className="">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps([
                          {
                            style: {
                              ...column.style,
                              borderBottom: "none",
                            },
                          },
                        ])}
                        scope="col"
                        className="app-modal-fullscreen__label uppercase"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={clsx(
                      "items-center",
                      clickable && "cursor-pointer"
                    )}
                  >
                    {row.cells.map((cell) => (
                      <td
                        onClick={() => handleRowClick(row.original, cell)}
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {!data.length && (
                <tr>
                  <td className="app-modal-fullscreen__value mt-2 text-center">
                    {`No ${noRecordTag ?? 'Records'} found`}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {count > data.length && (
          <div className="app-table__pagination app-table__pagination--sm">
            <div className="app-table__pagination__result">{count} Results</div>
            <div className="app-table__pagination__buttons">
              <button
                onClick={() => {
                  onPreviousPage();
                  previousPage();
                }}
                disabled={!canPreviousPage}
                className="app-table__pagination__button app-table__pagination__button--sm"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  onNextPage();
                  nextPage();
                }}
                disabled={!canNextPage}
                className="app-table__pagination__button app-table__pagination__button--sm"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DashboardModalTable;
