import React, { forwardRef } from 'react';
import Label from '../../../../../../../Common/Form/Label/Label';
import InfoIconImage from '../../../../../../../../assets/images/icons/info.svg';
import clsx from 'clsx';

const BillingType = forwardRef(({
  setValue,
  type,
	disabled = false,
}, ref) => {
  
  const setBillingType = (type) => {
    if (disabled) return;

    setValue('type', type);
  };

  return (
    <>
      <div>
        <Label 
          title='Billing Type'
          icon={InfoIconImage}
          tooltipHtml={true}
          tooltip={`
        Choose <code style="background:#d3d8e0;font:normal;border-radius:3px">&nbsp;Recurring&nbsp;</code> for subscriptions or
        <code style="background:#d3d8e0;font:normal;border-radius:3px">&nbsp;One Time&nbsp;</code> for one-off purchases 
          `} 
        />
        <div className='flex gap-3' style={{width:"60%"}}>
          <div
            onClick={() => setBillingType && setBillingType('recurring')}
            className={clsx(
              'duration-300 border rounded-lg w-full shadow text-center text-light px-2 py-1',
              type === 'recurring' &&
                'border-tertiary text-tertiary_darken',
							disabled
                ? 'cursor-default'
                : 'cursor-pointer'
            )}>
            <span>Recurring</span>
          </div>
          <div
            onClick={() => setBillingType && setBillingType('one_time')}
            className={clsx(
              'duration-300 border rounded-lg w-full shadow text-center text-light px-2 py-1',
              type === 'one_time' &&
                'border-tertiary text-tertiary_darken',
								disabled
                ? 'cursor-default'
                : 'cursor-pointer'
            )}>
            <span>One time</span>
          </div>
        </div>
      </div>
    </>
  );
});

export default BillingType;
