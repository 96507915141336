import { useFetchBalance } from 'api/wallet/balance';
import { useFetchCurrencies } from 'api/wallet/currencies';
import Button from 'components/Common/Form/Button/Button';
import ButtonGradient from 'components/Common/Form/Button/ButtonGradient/ButtonGradient';
import Input from 'components/Common/Form/Input/Input';
import { InputPriceCrypto } from 'components/Common/Form/InputPrice/InputPrice';
import Label from 'components/Common/Form/Label/Label';
import Select from 'components/Common/Form/Select/Select';
import Modal from 'components/Common/Modal/Modal'
import React , {useState , useEffect , useMemo} from 'react'
import styled from 'styled-components';
import { currencySymbol } from 'utils/string';
import { ReactComponent as ArrowsIcon } from 'assets/images/icons/double-arrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SwapsCreateModal({
    show = false,
    onClose = null,
    selectedFromCurrency = null,
    selectedToCurrency = null

}) {
  const currencyTypes = [{
    label : "Crypto",
    value : "crypto",

  },
  {
    label : "Fiat",
    value : "fiat"
  }
]
  const methods = {
    fiat : [
      {
        label : "Bank Account (to topup/payout with a bank account",
        value : "bank",
      },  
      {
        label : "Paypal etc",
        value : "paypal",
      }
    ],
    crypto : [
      {
        label : "Blockchain (standard blockchain topups/payouts)",
        value : "Blockchain",
      }, 
      {
        label : "Binance (to topup/payout with a Binanace account)",
        value : "Binance",
      }, 
      {
        label : "Crypto.com",
        value : "Crypto_com",
      }, 
    ]
  }
  const [currencies, setCurrencies] = useState([]);
    const [CurrencyType, setCurrencyType] = useState({label :"Crypto", value :"crypto"});
    const [fromCurrency, setFromCurrency] = useState(selectedFromCurrency);
    const [toCurrency, setToCurrency] = useState(selectedToCurrency);
    const [amount, setAmount] = useState();
    const [data, setdata] = useState();
    const [method, setMethod] = useState(methods?.crypto[0]);
    const [networks, setNetworks] = useState([]);
    const [network, setNetwork] = useState("");
    const [key2fa, setKey2fa] = useState("");
    // const [currencies, setCurrencies] = useState([]);
  const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      return () => {
        navigate(location.pathname.replace("/create", "").replace("/edit", ""));

      }
    }, [])
  const handleClose = () => {
    onClose && onClose();
  };
  const onFetchCurrenciesSuccess = (data) => {
    setdata(data)
    const currencies = data.map((data) => {
      return {
        label: data.currency.toUpperCase(),
        value: data.currency.toUpperCase(),
        icon: CurrencyType.value === "crypto"? data.currency.toLowerCase() :currencySymbol(data.currency),

      };
    });
    if (fromCurrency === null)
      setFromCurrency(data[0].currency)
    if(toCurrency === null) 
      setToCurrency(data[1].currency)
    // setMethod(CurrencyType.value === "Crypto" ?methods.crypto[0]: methods.fiat[0])

    // const firstItem = {
    //   label: data.converted_total_currency.toUpperCase(),
    //   value: data.converted_total_currency.toUpperCase(),
    //   amount: data.converted_total_amount,
    //   icon: currencySymbol(data.converted_total_currency),
    //   total: true,
    // };
    // currencies.unshift(firstItem);

    // setFiatItem(firstItem);
    setCurrencies(currencies);
    // setRefetch(false);
  };
  const handleCurrencyChangeFrom = (value) => {
    setFromCurrency(value.value);
  
  };
  const handleCurrencyChangeTo = (value) => {
    setToCurrency(value.value);
  
  };
  const handleSelectCurrencyType = (value) => {
    console.log(value.value)
    setCurrencyType(value)
    setMethod(value.value === "crypto" ? methods?.crypto[0] : methods?.fiat[0])

    // setFiatItem(value);
  };
  const handleSelectNetwork = (value) => {
    console.log(value)
    setNetwork(value)
  };
  const handleSelectMethod = (value) => {
    console.log(method)
    setMethod(value)
    // setFiatItem(value);
  };
  const getCurrency = useMemo(() => {
    return currencies.length ? currencies[0].value : null;
  }, [currencies]);

  const { isLoading } = useFetchCurrencies({
    onSuccess: onFetchCurrenciesSuccess,
    onError: ()=>{},
    params:{
      type: CurrencyType.value,
      networks : true
    }
    // enabled: refetch,
  } , [CurrencyType]);

  useEffect(() => {


  }, []);
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  return (
    <Modal style={{overflow : "visible"}} show={show} onClose={handleClose} size='lg'>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
          Swap Currencies
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name='body'>
    <FormStyle>
        <div className='flex w[100%]'>
            <div style={{flex: '1'}}>
            <Label title='From (Amount)' error={""} />
                <InputPriceCrypto
                  block
                  min='0'
                  className="marg"
                  currency={fromCurrency}
                  currencies={currencies}
                  onChange={(e)=>setAmount(e.target.value)}

                  onCurrencyChange={handleCurrencyChangeFrom}
                  placeholder={ '0.00'}
                  // error={errors.amount}
                  value={amount}
            />
            </div>
            <div className='self-center mx-2
            '>
                <ArrowsIcon 
                onClick={()=>{
                  const temp = fromCurrency
                  setFromCurrency(toCurrency)
                  setToCurrency(temp)
                }} className='w-20 h-10 cursor-pointer hover:opacity-50
                 hover:scale-105 transition-all duration-300 select-none
                ' />
            </div>
            <div style={{flex: '1'}}>
            <Label title='To (Amount)' error={""} />
                <InputPriceCrypto
                  block
                  min='0'
                  className="marg"
                  currency={toCurrency}
                  currencies={currencies}
                  onChange={(e)=>setAmount(e.target.value)}

                  onCurrencyChange={handleCurrencyChangeTo}
                  placeholder={ '0.00'}
                  // error={errors.amount}
                  value={amount}
            />
            </div>
        </div>
          <div  >
            <span style={{margin : "10px auto" }} className='text-sm text-gray-500 flex justify-center
            '>1.00 {" " + fromCurrency + " = approx todo 973.33 " + toCurrency}</span>
          </div>
    
        <div>
            
          
        </div>
        

    </FormStyle>

      </Modal.Slot>
       <Modal.Slot name='test'>
        
      <div className='flex align-center center
      '
       style={{
        background: "#E3F7FF",
        padding: "10px",
        marginTop: "10px",
        height :"112px",
        alignItems: "center",
        padding: "25px",
        justifyContent: "space-between",
        
}}>
        <div className='flex flex-col mr-5
        '>
          <div className='app-modal-fullscreen__label'>FEE 0.3%:</div>
          <div className='app-modal-fullscreen__value'>0.00</div>
          </div>
          <div className='flex flex-col'>
          <div className='app-modal-fullscreen__label'>TOTAL APPROX. USD:</div>
          <div className='app-modal-fullscreen__value'>0.00</div>
          </div>
          <div className='flex flex-col'>
          <div className='app-modal-fullscreen__label'>TOTAL APPROX. BTC:</div>
          <div className='app-modal-fullscreen__value'>0.00</div>
          </div>
      </div>

      <div  className='flex flex-col
      '>
        <div style={{margin : "10px auto" }} >
        <Label title='2FA Code' error={""} />
        <Input
        className=" w-[250px] min-w-full 
        "
        value={key2fa}
        onChange={(e)=>{
            setKey2fa(e.target.value)
        }}
        placeholder='Enter 2FA Code'
        // className='app-modal-fullscreen__input'
            />                

        </div>
        <Button  style={{margin : "0px auto" }} onClick={(e)=>{
          
          }  } type='  '>
            Swap

          </Button>
        
      </div>
        </Modal.Slot>

      {/* <Modal.Slot name='footer'>
        <div className='flex justify-end'>
        <Button  onClick={(e)=>{
         
          }  } type='  '>
            Confirm

          </Button>
        </div>
      </Modal.Slot> */}
    </Modal>
  )
}

const FormStyle = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 10px; */
    >div{
        width: 100%;

            >.networks{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .active{
                border: 2px solid #47CE7D;
              }
              >label{
                padding:  0 10px;
                flex-basis : 49%;
                margin-bottom:10px ;
                height: 99px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #DCDEE6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                input
                {
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: none;
                  border: 0;
                  box-shadow: inset 0 0 0 3px #DCDEE6;
                  box-shadow: inset 0 0 0 3.5px #DCDEE6;
                  appearance: none;
                  padding: 0;
                  margin: 0;
                  transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
                  pointer-events: none;
                  margin: 2px;
                  &:focus
                  {

                    outline: none;
                  }
                  
                  &:checked
                  {

                    box-shadow: inset 0 0 0 6px #47CE7D;
                  }

                }
                >.first{
                  height: 100%;
                  justify-content: space-around;
                  display: flex;
                  align-items: flex-start;
                  flex-direction :column;
                  >img{

                    max-width: 100px;
                  }
                }

              }
            }
        
    }
    `;

    
const WarningStyle = styled.div`

    margin: 24px auto;
    min-width: 367px;
    max-width: 367px;
    padding: 12px 25px;
    background: #FFE3E8;
    border: 1px solid #EE677E;
border-radius: 8px;
text-align: center;
font: normal normal 600 14px/25px Blinker;
letter-spacing: 0.28px;
color: #FF5B7C;
opacity: 1;
`;