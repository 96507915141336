import "./APIKeys.scss";
import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../../components/Common/Form/DashboardButton/DashboardButton";
import APIKeysTable from "../../../../components/Pages/Dashboard/APIKeys/APIKeysTable/APIKeysTable";
import ApikeysCreateModal from "./ApikeysCreateModal";
import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import ApikeyRollModal from "./ApikeyRollModal";
import EditAPIKeyModal from "./EditAPIKeyModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function APIKeys() {
  const [showCreateSecretKey, setShowCreateSecretKey] = useState(false)
  const [showCreateRestrictedKey, setShowCreateRestrictedKey] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showRoll, setshowRoll] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showCreate, setShowCreate] = useState(false);
  const [fetchChanges, setFetchChanges] = useState(false);
  const [key, setKey] = useState(null)
  const navigate = useNavigate();
  const location = useLocation();
  const { livemode } = useSelector((state) => state.app);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [])

  useEffect(() => {
    console.log("new changes")
  }, [fetchChanges])

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [location])

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="app-dashboard__card__title">Standard Keys</div>
            {showCreate && (
              <DashboardButton onClick={() => setShowCreateSecretKey(true)} color="primary" outline icon="+">
                <span className="text-dark">Create {!livemode && "a Testmode"} Secret Key</span>
              </DashboardButton>
            )}
          </div>
          <p className="app-dashboard__card__help">Use these keys to authenticate API Requests.</p>
          <APIKeysTable
            showRoll={() => setshowRoll(true)}
            showDelete={() => setShowDelete(true)}
            showEdit={() => setShowEdit(true)}
            type="standard"
            setKey={setKey}
            setShowCreate={setShowCreate}
            fetchChanges={fetchChanges}
          />
          {showCreateSecretKey && (
            <ApikeysCreateModal
              show={showCreateSecretKey}
              onClose={() => setShowCreateSecretKey(false)}
              setFetchChanges={setFetchChanges}
            />)}
          {showRoll && (
            <ApikeyRollModal
              show={showRoll}
              onClose={() => setshowRoll(false)}
              selectedApiKey={key}
            />)}
          <ReactTooltip
            id="tooltip-api"
            place="right"
            effect="solid"
            type="light"
            padding="10px"
            textColor="#1A1E23"
            backgroundColor="#ffffff"
            className="api-key__tooltip"
          >
          </ReactTooltip>
          <div className="app-dashboard__card__header mt-6">
            <div className="app-dashboard__card__title">Restricted Keys</div>
            {showCreate && (
              <DashboardButton onClick={() => setShowCreateRestrictedKey(true)} color="primary" outline icon="+">
                <span className="text-dark">Create {!livemode && "a Testmode"} Restricted Key</span>
              </DashboardButton>
            )}
          </div>
          <p className="app-dashboard__card__help">Create a restricted key to use securely within your applications.</p>
          <APIKeysTable
            showRoll={() => setshowRoll(true)}
            showDelete={() => setShowDelete(true)}
            showEdit={() => setShowEdit(true)}
            setKey={setKey}
            type="restricted"
            fetchChanges={fetchChanges}
          />
          {showCreateRestrictedKey && (
            <ApikeysCreateModal
              restrcitedKey={true}
              show={showCreateRestrictedKey}
              onClose={() => setShowCreateRestrictedKey(false)}
            />)}
          {showEdit && (
            <EditAPIKeyModal
              show={showEdit}
              onClose={
                () => {
                  setShowEdit(false);
                  navigate("/dashboard/test/api_keys");
                }
              }
              selectedApiKey={key}
            />)}
          <ReactTooltip
            id="tooltip-api"
            place="right"
            effect="solid"
            type="light"
            padding="10px"
            textColor="#1A1E23"
            backgroundColor="#ffffff"
            className="api-key__tooltip"
          >
          </ReactTooltip>
          {showDelete && (
            <ApikeyRollModal
              show={showDelete}
              onClose={() => setShowDelete(false)}
              isDelete={true}
              selectedApiKey={key}
            />)}
        </div>
      </div>
    </div>
  )
}

export default APIKeys;
