
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import React, {
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import { dateFormatter } from '../../../../../../../../utils/date';
import {
  currencyFormatter,
  currencySymbol,
} from '../../../../../../../../utils/string';
import clsx from 'clsx';

function InvoicePreview({ hostedData }) {
  const {
    draft_invoice,
    invoice_custom_fields,
    selected_invoice_customer,
    invoice_line_items,
  } = useSelector((state) => state.invoice);
  // const { current_account } = useSelector((state) => state.auth);

  const getIssueDate = useMemo(() => {
    return (
      draft_invoice?.status_timeline.issued_at ??
      draft_invoice?.created_at
    );
  }, [draft_invoice]);

  /*const getAddress = useMemo(() => {
    if (current_account.business_profile.support_address.first_line) {
      return current_account.business_profile.support_address;
    }
    if (current_account.company.address.first_line) {
      return current_account.company.address;
    }
    return null;
  }, [current_account]);*/

  const getInvoiceDiscount = useMemo(() => {
    const discountGroup =
      draft_invoice?.discount_amounts.find(
        (discount) => discount.source === 'group'
      );

    if (discountGroup) {
      const discount = draft_invoice.discounts.find(
        (discount) => discount.id === discountGroup.discount
      );

      return { ...discount, amount: discountGroup.amount };
    }

    return null;
  }, [draft_invoice]);

  const getShippingRate = useCallback(
    (id) => {
      return draft_invoice.shipping_rates.find(
        (item) => item.id === id
      );
    },
    [draft_invoice]
  );

  const getInvoiceTaxRates = useMemo(() => {
    return draft_invoice?.tax_amounts.filter(
      (discount) => discount.source === 'group'
    );
  }, [draft_invoice]);

  const getItemTaxRates = useCallback((item) => {
    return item.tax_amounts.filter(
      (tax_rate) => tax_rate.source === 'item'
    );
  }, []);

  const daysWord = (days) => (days > 1 ? 'days' : 'day');

  const getItemDiscountAmount = (
    discount_id,
    discounts
  ) => {
    return discounts.find(
      (item) => item?.discount === discount_id
    );
  };

  return (
    <>
      <div className='space-y-7 mt-2 mb-4'>
        <div>
          <div className='app-modal-fullscreen__tab app-modal-fullscreen__tab--active'>
            Preview
          </div>
          <p className='app-modal-fullscreen__text mt-3'>
            <span className='text-light'>
              Change how this page looks in
            </span>
            <span className='text-tertiary'>
              {' '}
              branding settings
            </span>
            .
          </p>
        </div>
        <div className='space-x-6'>
          <div className='app-modal-fullscreen__tab'>
            Email
          </div>
          <div className='app-modal-fullscreen__tab app-modal-fullscreen__tab--active'>
            Invoice PDF
          </div>
          <div className='app-modal-fullscreen__tab'>
            Invoice Page
          </div>
        </div>
      </div>
      {!draft_invoice && (
        <div className='app-invoices__preview'>
          <div className='app-invoices__preview__header'>
            <div className='flex justify-between mb-3'>
              <div className='app-invoices__preview__header__name' />
              <div className='app-invoices__preview__header__invoice mt-2'>
                Invoice
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='app-invoices__preview__header__customer-info'></div>
              <div className='app-invoices__preview__header__invoice-info'>
                <div>
                  <div className='app-invoices__preview__header__invoice-info__key'>
                    Invoice number
                  </div>
                  <div className='app-invoices__preview__header__invoice-info__value'>
                    -
                  </div>
                </div>
                <div>
                  <div className='app-invoices__preview__header__invoice-info__key'>
                    Date of issue
                  </div>
                  <div className='app-invoices__preview__header__invoice-info__value'>
                    -
                  </div>
                </div>
                <div>
                  <div className='app-invoices__preview__header__invoice-info__key'>
                    Date due
                  </div>
                  <div className='app-invoices__preview__header__invoice-info__value'>
                    -
                  </div>
                </div>
              </div>
            </div>
            <div className='space-y-10'>
              <div>
                <div className='app-modal-fullscreen__label mt-4'>
                  BILL TO
                </div>
              </div>
              <table className='app-invoices__preview__table'>
                <thead>
                  <tr>
                    <th
                      width='40%'
                      className='app-modal-fullscreen__label'>
                      DESCRIPTION
                    </th>
                    <th className='app-modal-fullscreen__label'>
                      QTY
                    </th>
                    <th className='app-modal-fullscreen__label'>
                      UNIT PRICE
                    </th>
                    <th className='app-modal-fullscreen__label'>
                      TAX
                    </th>
                    <th
                      width='10%'
                      className='app-modal-fullscreen__label'>
                      AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='app-invoices__preview__table__row--borderless'>
                    <td />
                    <td />
                    <td />
                    <td>
                      <div className='app-invoices__preview__table__row__value font-semibold'>
                        Subtotal
                      </div>
                    </td>
                    <td>
                      <div className='app-invoices__preview__table__row__amount justify-end'>
                        <span className='font-semibold'>
                          £0.00
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className='
                  app-invoices__preview__table__row--bg--empty
                  app-invoices__preview__table__row--bg--gray
                  app-invoices__preview__table__row--borderless
                '>
                    <td />
                    <td />
                    <td />
                    <td>
                      <div className='app-invoices__preview__table__row__value font-semibold'>
                        Amount due
                      </div>
                    </td>
                    <td>
                      <div className='app-invoices__preview__table__row__amount justify-end'>
                        <span className='font-semibold'>
                          £0.00
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='horizontal-divider horizontal-divider--dense mt-20' />
            <div className='app-invoices__preview__footer__pay'>
              <div>Pay £00.00</div>
            </div>
            <div className='app-invoices__preview__footer__question'>
              <span className='font-semibold'>
                Questions?
              </span>
            </div>
          </div>
        </div>
      )}
      {draft_invoice && (
        <div className='app-invoices__preview'>
          <div className='app-invoices__preview__header'>
            <div className='flex justify-between mb-3'>
              <div className='app-invoices__preview__header__name'>
                Invoice
              </div>
              <div className='app-invoices__preview__header__invoice'>
                {hostedData?.merchant.business_name}
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='app-invoices__preview__header__invoice-info'>
                <div>
                  <div className='app-invoices__preview__header__invoice-info__key'>
                    Invoice number
                  </div>
                  <div className='app-invoices__preview__header__invoice-info__value'>
                    {draft_invoice.invoice_number}
                  </div>
                </div>
                <div>
                  <div className='app-invoices__preview__header__invoice-info__key'>
                    Date of issue
                  </div>
                  <div className='app-invoices__preview__header__invoice-info__value'>
                    {dateFormatter(getIssueDate)}
                  </div>
                </div>
                {draft_invoice.due_date ? (

                  <div>
                    <div className='app-invoices__preview__header__invoice-info__key'>
                      Date due
                    </div>
                    <div className='app-invoices__preview__header__invoice-info__value'>
                      {dateFormatter(
                        moment()
                          .add(
                            typeof draft_invoice.days_until_due ===
                              'number'
                              ? draft_invoice.days_until_due
                              : 0
                          )
                          .unix()
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="app-invoices__preview__header__invoice-info__key">
                      Date due
                    </div>
                    <div className="app-invoices__preview__header__invoice-info__value">
                      {dateFormatter(moment().add(typeof draft_invoice.days_until_due === 'number' ? draft_invoice.days_until_due : 0).unix())}
                    </div>
                  </div>
                )}
              </div>
              <div className='app-invoices__preview__header__customer-info'>
                {hostedData?.merchant.support_phone && (
                  <div>
                    {hostedData?.merchant.support_phone}
                  </div>
                )}
                {hostedData?.merchant.support_email && (
                  <div>
                    {hostedData?.merchant.support_email}
                  </div>
                )}
                {/*<div>{current_account.business_profile.support_website}</div>
              {getAddress &&
                <>
                  <div>{getAddress.first_line}{getAddress.second_line ? `, ${getAddress.second_line}` : ""}</div>
                  <div>{getAddress.post_code}, {getAddress.city}, {getAddress.state}, {getAddress.country}</div>
                </>
              }*/}
                <div className='app-invoices__preview__header__invoice-info mt-4'>
                  {invoice_custom_fields.map(
                    (field, index) => (
                      <div key={index}>
                        <div className='app-invoices__preview__header__invoice-info__key'>
                          {Object.keys(field)[0]}
                        </div>
                        <div className='app-invoices__preview__header__invoice-info__value'>
                          {field[Object.keys(field)[0]]}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className='space-y-10'>
              <div>
                <div className='app-modal-fullscreen__label mt-4'>
                  BILL TO
                </div>
                <div className='app-invoices__preview__header__customer-info'>
                  <div className='text-dark font-semibold'>
                    {selected_invoice_customer?.name}
                  </div>
                  <div className='text-dark'>
                    {selected_invoice_customer?.email}
                  </div>
                </div>
              </div>
              <div>
                <div className='app-invoices__preview__due'>
                  {currencySymbol(draft_invoice.currency)}
                  {currencyFormatter(
                    draft_invoice.currency,
                    draft_invoice.amount_due
                  )}
                  {draft_invoice.due_date ? (
                    <span> due {dateFormatter(draft_invoice.due_date)}</span>
                  ) : (<span> due {dateFormatter(moment().add(typeof draft_invoice.days_until_due === 'number' ? draft_invoice.days_until_due : 0).unix())}</span>)}

                </div>
                <div className='text-sm text-light mt-2'>
                  {draft_invoice.memo}
                </div>
              </div>
              <table className='app-invoices__preview__table'>
                <thead>
                  <tr>
                    <th
                      width='35%'
                      className='app-modal-fullscreen__label'>
                      DESCRIPTION
                    </th>
                    <th className='app-modal-fullscreen__label'>
                      QTY
                    </th>
                    <th
                      width='18%'
                      className='app-modal-fullscreen__label'>
                      UNIT PRICE
                    </th>
                    <th className='app-modal-fullscreen__label'>
                      TAX
                    </th>
                    <th
                      width='10%'
                      className='app-modal-fullscreen__label'>
                      AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoice_line_items.map((item, index) => (
                    <Fragment key={index}>
                      <tr
                        className={clsx(
                          item.discounts?.length &&
                            'app-invoices__preview__table__row--borderless',
                          !(index % 2) &&
                            'app-invoices__preview__table__row--bg'
                        )}>
                        <td>
                          <div className='app-invoices__preview__table__row__value'>
                            {item.product.name}
                          </div>
                        </td>
                        <td>
                          <div className='app-invoices__preview__table__row__value'>
                            {item.quantity}
                          </div>
                        </td>
                        <td>
                          <div className='app-invoices__preview__table__row__amount'>
                            {currencySymbol(
                              item.price.currency
                            )}
                            {currencyFormatter(
                              item.price.currency,
                              item.price.unit_amount_decimal
                            )}
                          </div>
                        </td>
                        <td>
                          <div className='app-invoices__preview__table__row__value'>
                            <div className='space-x-2'>
                              {getItemTaxRates(item)
                                .length > 0
                                ? getItemTaxRates(item).map(
                                    (tax_rate, index) => (
                                      <span key={index}>
                                        {tax_rate.percent}%
                                        {tax_rate.inclusive
                                          ? ' incl.'
                                          : ''}
                                        {getItemTaxRates(
                                          item
                                        ).length > 1 &&
                                          getItemTaxRates(
                                            item
                                          ).length !==
                                            index + 1 &&
                                          ', '}{' '}
                                      </span>
                                    )
                                  )
                                : getInvoiceTaxRates.length >
                                    0 &&
                                  getInvoiceTaxRates.map(
                                    (tax_rate, index) => (
                                      <span key={index}>
                                        {tax_rate.percent}%
                                        {tax_rate.inclusive
                                          ? ' incl.'
                                          : ''}
                                      </span>
                                    )
                                  )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='app-invoices__preview__table__row__amount justify-end'>
                            <span className='font-semibold'>
                              {currencySymbol(
                                item.price.currency
                              )}
                              {currencyFormatter(
                                item.price.currency,
                                item.amount
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                      {item.discounts.map(
                        (discount, dIndex) => {
                          const _discount =
                            getItemDiscountAmount(
                              discount.id,
                              item.discount_amounts
                            );
                          return (
                            _discount && (
                              <tr
                                key={dIndex}
                                className={clsx(
                                  !(index % 2) &&
                                    'app-invoices__preview__table__row--bg'
                                )}>
                                <td>
                                  <div className='app-invoices__preview__table__row__value pl-3'>
                                    <div className='text-light'>
                                      {discount.name}{' '}
                                      {discount.amount_off &&
                                        `(${currencySymbol(
                                          discount.currency
                                        )}${currencyFormatter(
                                          discount.currency,
                                          discount.amount_off
                                        )} off)`}
                                      {discount.percent_off &&
                                        `(${discount.percent_off}% off)`}
                                    </div>
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td>
                                  <div className='app-invoices__preview__table__row__amount justify-end'>
                                    -
                                    {currencySymbol(
                                      discount.currency
                                    )}
                                    {currencyFormatter(
                                      discount.currency,
                                      _discount.amount
                                    )}{' '}
                                  </div>
                                </td>
                              </tr>
                            )
                          );
                        }
                      )}
                    </Fragment>
                  ))}
                  <tr className='app-invoices__preview__table__row--borderless'>
                    <td />
                    <td />
                    <td colSpan='2'>
                      <div className='app-invoices__preview__table__row__value font-semibold'>
                        Subtotal
                      </div>
                    </td>
                    <td>
                      <div className='app-invoices__preview__table__row__amount justify-end'>
                        <span className='font-semibold'>
                          {currencySymbol(
                            draft_invoice.currency
                          )}
                          {currencyFormatter(
                            draft_invoice.currency,
                            draft_invoice.subtotal

                          )}{" "}

                        </span>
                      </div>
                    </td>
                  </tr>
                  {getInvoiceDiscount && (
                    <tr
                      className='
                  app-invoices__preview__table__row--bg
                  app-invoices__preview__table__row--bg--empty
                  app-invoices__preview__table__row--borderless
                '>
                      <td />
                      <td />
                      <td
                        colSpan='2'
                        className='app-invoices__preview__table__td__border'>
                        <div className='app-invoices__preview__table__row__value'>
                          {getInvoiceDiscount.name}{' '}
                          {getInvoiceDiscount.amount_off &&
                            `(${currencySymbol(
                              draft_invoice.currency
                            )}${currencyFormatter(
                              draft_invoice.currency,
                              getInvoiceDiscount.amount_off
                            )} off)`}
                          {getInvoiceDiscount.percent_off &&
                            `(${getInvoiceDiscount.percent_off}% off)`}
                        </div>
                      </td>
                      <td className='app-invoices__preview__table__td__border'>
                        <div className='app-invoices__preview__table__row__amount justify-end'>
                          -
                          {currencySymbol(
                            draft_invoice.currency
                          )}
                          {currencyFormatter(
                            draft_invoice.currency,
                            getInvoiceDiscount.amount
                          )}{' '}
                        </div>
                      </td>
                    </tr>
                  )}
                  {draft_invoice.shipping_amounts.map(
                    (shipping_rate, tIndex) => {
                      const _shipping_rate =
                        getShippingRate(
                          shipping_rate.shipping_rate
                        );
                      return (
                        <tr
                          key={tIndex}
                          className='
                            app-invoices__preview__table__row--bg
                            app-invoices__preview__table__row--bg--empty
                            app-invoices__preview__table__row--borderless
                          '>
                          <td />
                          <td />
                          <td
                            colSpan='2'
                            className={
                              draft_invoice.shipping_amounts
                                .length -
                                1 ===
                              tIndex
                                ? 'app-invoices__preview__table__td__border'
                                : ''
                            }>
                            <div className='app-invoices__preview__table__row__value'>
                              <div>Shipping</div>
                              {_shipping_rate?.description &&
                                `${_shipping_rate.description} `}
                              {_shipping_rate?.shipping_time && (
                                <>
                                  {_shipping_rate
                                    .shipping_time.to ===
                                    null &&
                                    `up to ${
                                      _shipping_rate
                                        .shipping_time.from
                                    } business ${daysWord(
                                      _shipping_rate
                                        .shipping_time.from
                                    )}`}
                                  {_shipping_rate
                                    .shipping_time.to !==
                                    null &&
                                    _shipping_rate
                                      .shipping_time.to !==
                                      _shipping_rate
                                        .shipping_time
                                        .from &&
                                    `${
                                      _shipping_rate
                                        .shipping_time.from
                                    } to ${
                                      _shipping_rate
                                        .shipping_time.to
                                    } business ${daysWord(
                                      _shipping_rate
                                        .shipping_time.to
                                    )}`}
                                  {_shipping_rate
                                    .shipping_time.to ===
                                    _shipping_rate
                                      .shipping_time.from &&
                                    `${
                                      _shipping_rate
                                        .shipping_time.from
                                    } business ${daysWord(
                                      _shipping_rate
                                        .shipping_time.from
                                    )}`}
                                </>
                              )}
                              {/*{tax_rate.display_name} ({tax_rate.percent}%*/}
                              {/*{tax_rate.inclusive ? " inc." : ""})*/}
                            </div>
                          </td>
                          <td
                            className={
                              draft_invoice.shipping_amounts
                                .length -
                                1 ===
                              tIndex
                                ? 'app-invoices__preview__table__td__border'
                                : ''
                            }>
                            <div className='app-invoices__preview__table__row__amount justify-end'>
                              {currencySymbol(
                                draft_invoice.currency
                              )}
                              {currencyFormatter(
                                draft_invoice.currency,
                                shipping_rate.amount
                              )}{' '}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                  {draft_invoice.tax > 0 && (

                    <tr className="app-invoices__preview__table__row--borderless">
                      <td />
                      <td />
                      <td colSpan="2">
                        <div className="app-invoices__preview__table__row__value font-semibold">

                          Total excluding tax
                        </div>
                      </td>
                      <td>

                        <div className="app-invoices__preview__table__row__amount justify-end">
                          <span className="font-semibold">
                            {currencySymbol(draft_invoice.currency)}
                            {currencyFormatter(
                              draft_invoice.currency,
                              draft_invoice.total_excluding_tax
                            )}{" "}

                          </span>
                        </div>
                      </td>
                    </tr>
                  )}

                  {draft_invoice.tax_amounts.map((tax_rate, tIndex) => (
                    <tr
                      key={tIndex}
                      className="
                    app-invoices__preview__table__row--bg
                    app-invoices__preview__table__row--bg--empty
                    app-invoices__preview__table__row--borderless
                  "
                    >
                      <td />
                      <td />
                      <td
                        colSpan="2"
                        className={
                          draft_invoice.tax_amounts.length - 1 === tIndex
                            ? "app-invoices__preview__table__td__border"
                            : ""
                        }
                      >
                        <div className="app-invoices__preview__table__row__value">
                          {tax_rate.display_name} ({tax_rate.percent}%
                          {tax_rate.inclusive ? " incl." : ""})
                        </div>
                      </td>
                      <td
                        className={
                          draft_invoice.tax_amounts.length - 1 === tIndex
                            ? "app-invoices__preview__table__td__border"
                            : ""
                        }
                      >
                        <div className="app-invoices__preview__table__row__amount justify-end">
                          {currencySymbol(draft_invoice.currency)}
                          {currencyFormatter(
                            draft_invoice.currency,
                            tax_rate.amount
                          )}{" "}
                        </div>
                      </td>
                    </tr>
                  ))}

                  <tr
                    className='
                      app-invoices__preview__table__row--bg--empty
                      app-invoices__preview__table__row--bg--gray
                      app-invoices__preview__table__row--borderless
                    '>
                    <td />
                    <td />
                    <td colSpan='2'>
                      <div className='app-invoices__preview__table__row__value font-semibold'>
                        Total
                      </div>
                    </td>
                    <td>
                      <div className='app-invoices__preview__table__row__amount justify-end'>
                        <span className='font-semibold'>
                          {currencySymbol(
                            draft_invoice.currency
                          )}
                          {currencyFormatter(
                            draft_invoice.currency,
                            draft_invoice.total
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                  {draft_invoice.applied_balance > 0 && (
                    <tr className="
                    app-invoices__preview__table__row--bg--empty
                    app-invoices__preview__table__row--bg--gray
                    app-invoices__preview__table__row--borderless
                  "
                    >
                      <td />
                      <td />
                      <td colSpan="2">
                        <div className="app-invoices__preview__table__row__value font-semibold">

                          Applied Balance
                        </div>
                      </td>
                      <td>
                        <div className="app-invoices__preview__table__row__amount justify-end">
                          <span className="font-semibold">
                            {currencySymbol(draft_invoice.currency)}
                            {currencyFormatter(
                              draft_invoice.currency,
                              draft_invoice.applied_balance
                            )}
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr
                    className='
                  app-invoices__preview__table__row--bg--empty
                  app-invoices__preview__table__row--bg--gray
                  app-invoices__preview__table__row--borderless
                '>
                    <td />
                    <td />
                    <td colSpan='2'>
                      <div className='app-invoices__preview__table__row__value font-semibold'>
                        Amount due
                      </div>
                    </td>
                    <td>
                      <div className='app-invoices__preview__table__row__amount justify-end'>
                        <span className='font-semibold'>
                          {currencySymbol(
                            draft_invoice.currency
                          )}
                          {currencyFormatter(
                            draft_invoice.currency,
                            draft_invoice.amount_due
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <div className='text-sm text-light mt-2'>
                  {draft_invoice.footer}
                </div>
              </div>
            </div>
            <div className='horizontal-divider horizontal-divider--dense mt-20' />
            <div className='app-invoices__preview__footer__pay'>
              <div>
                Pay {currencySymbol(draft_invoice.currency)}
                {currencyFormatter(
                  draft_invoice.currency,
                  draft_invoice.amount_due
                )}
                {draft_invoice.due_date ? (
                  <span> by {dateFormatter(draft_invoice.due_date)}</span>
                ) : (<span> by {dateFormatter(moment().add(typeof draft_invoice.days_until_due === 'number' ? draft_invoice.days_until_due : 0).unix())}</span>)}
              </div>
              <div>
                <span className='mr-1'>Visit </span>
                <a
                  href={`https://invoice.walio.io/${draft_invoice.merchant}/${draft_invoice.secret}`}
                  className='font-semibold break-all'
                  target='_blank'
                  rel='noreferrer'>
                  https://invoice.walio.io/
                  {draft_invoice.merchant}/
                  {draft_invoice.secret}
                </a>
              </div>
            </div>
            <div className='app-invoices__preview__footer__question'>
              <div>
                <span className='font-semibold mr-1'>
                  Questions?{' '}
                </span>
                {hostedData?.merchant.support_phone && (
                  <span>
                    Call us on $
                    {hostedData?.merchant.support_phone}
                  </span>
                )}
                {hostedData?.merchant.support_website && (
                  <span>
                    Visit our website at $
                    {hostedData?.merchant.support_website}
                  </span>
                )}
              </div>
              {hostedData?.merchant
                .business_returns_policy && (
                <div>
                  <span className='font-semibold mr-1'>
                    Returns policy{' '}
                  </span>
                  <span className='break-all'>
                    {
                      hostedData?.merchant
                        .business_returns_policy
                    }
                  </span>
                </div>
              )}
              {hostedData?.merchant
                .business_privacy_policy && (
                <div>
                  <span className='font-semibold mr-1'>
                    Privacy policy{' '}
                  </span>
                  <span className='break-all'>
                    {
                      hostedData?.merchant
                        .business_privacy_policy
                    }
                  </span>
                </div>
              )}
              {hostedData?.merchant
                .business_terms_of_service && (
                <div>
                  <span className='font-semibold mr-1'>
                    Terms of use{' '}
                  </span>
                  <span className='break-all'>
                    {
                      hostedData?.merchant
                        .business_terms_of_service
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InvoicePreview;
