import Button from 'components/Common/Form/Button/Button'
import Input from 'components/Common/Form/Input/Input'
import Label from 'components/Common/Form/Label/Label'
import Modal from 'components/Common/Modal/Modal'
import React, { useMemo, useEffect } from 'react'
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux'
import InfoIcon from "assets/images/icons/info.svg";
import { useUpdateApiKey } from 'api/api_key/api_key'
import moment from 'moment'

const EditAPIKeyModal = (
	{
		show = false,
		onClose = null,
		selectedApiKey = null
	}
) => {
	const [APIKey, setAPIKey] = React.useState({
		name: selectedApiKey?.name,
		note: selectedApiKey?.note,
	})
	const updateApiKey = useUpdateApiKey()

	const handleClose = () => {
		onClose && onClose()
	}

	const handleOnChange = (e) => {
		const { name, value } = e.target
		setAPIKey((prev) =>
		({
			...prev,
			[name]: value
		})
		)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		let data = {
			id: selectedApiKey.id,
			name: APIKey.name,
			note: APIKey.note
		}
		updateApiKey.mutate(data, {
			onSuccess: (data) => {
				console.log(data)
				onClose && onClose()
			},
			onError: (error) => {
				console.log(error)
			}
		})
	}

	const delete_at = useMemo(() => {
		if (!selectedApiKey || !selectedApiKey.delete_at_unix) return null
		console.log(moment(selectedApiKey.delete_at).diff(moment(), 'hours'))
		if (moment(selectedApiKey.delete_at).diff(moment(), 'days') > 1) {
			return `This key will be deleted in ${moment(selectedApiKey.delete_at).diff(moment(), 'days')} days`
		} else if (moment(selectedApiKey.delete_at).diff(moment(), 'days') <= 1) {
			return `This key will be deleted in ${moment(selectedApiKey.delete_at).diff(moment(), 'hours')} hours`
		} else if (moment(selectedApiKey.delete_at).diff(moment(), 'hours') <= 1) {
			return `This key will be deleted in ${moment(selectedApiKey.delete_at).diff(moment(), 'minutes')} minutes`
		}
	}, [selectedApiKey])

	useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

	return (
		<Modal style={{ overflow: "visible" }} show={show} onClose={handleClose} size='sm'>
			<Modal.Slot name='header'>
				<div className='app-invoice__modal__title'>
					Edit API Key
				</div>
			</Modal.Slot>
			<Modal.Slot name='body'>
				<div className="text-xs absolute top-0 left-0 w-full">
					{delete_at && (
						<p className='bg-red-100 text-red-400 w-full p-4 border-t border-b border-red-200'>
							{delete_at}
						</p>)}
				</div>
				<form className={`w-full flex flex-col gap-4 mb-4 ${delete_at && "mt-8"}`}>
					<div className='w-full'>
						<Label title='Name' />
						<Input
							className='w-full'
							type='text'
							name='name'
							placeholder='Enter a name for this key'
							value={APIKey.name}
							onChange={handleOnChange}
						/>
					</div>
					<div className='w-full'>
						<Label
							title='Note'
							tooltip={`Leave a hint at where the keys is stored`}
							icon={InfoIcon}
							tooltipHtml={true}
							tooltipFor="tooltip-api"
						/>
						<Input
							className='w-full'
							type='text'
							name='note'
							placeholder="Enter a note for this key"
							value={APIKey.note}
							onChange={handleOnChange}
						/>
					</div>
				</form>
			</Modal.Slot>
			<Modal.Slot name='footer'>
				<div className='flex justify-end'>
					<Button
						onClick={handleSubmit}
						className='disabled:bg-opacity-70 disabled:cursor-not-allowed'
						disabled={!APIKey.name}
					>
						Edit
					</Button>
				</div>
			</Modal.Slot>
		</Modal>
	)
}

export default EditAPIKeyModal