import ModalFullScreen from 'components/Common/ModalFullScreen/ModalFullScreen'
import CopyToClipboardBoxID from 'components/Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as DateIcon } from "assets/images/icons/date.svg";
import { dateFormatter, timeForamtter } from 'utils/date';
import JsonData from './EventDetails/JsonData';
import WebhooksAttemps from './EventDetails/WebhooksAttemps';
import { useNavigate } from "react-router-dom";

export default function EventsDetails({
	show = false,
	onClose = null,
}) {
	const { selected_event } = useSelector((state) => state.event);
	const { live_mode_access } = useSelector((state) => state.auth.current_account);
	const DASHBOARD_PATH = !live_mode_access ? '/dashboard/test' : '/dashboard';
	const navigate = useNavigate();

	const handleClose = () => {
		navigate(DASHBOARD_PATH + "/events");
		onClose && onClose();
	};

	return (
		<>
			<ModalFullScreen show={show} onClose={handleClose}>
				<ModalFullScreen.Slot name="header">
					<div className="app-customers__modal__header">
						<div className="app-modal-fullscreen__header__title">Event</div>
						<CopyToClipboardBoxID text={selected_event?.id} />
					</div>
				</ModalFullScreen.Slot>
				<ModalFullScreen.Slot name="body">
					<div className="space-y-7">
						<div className="space-y-1">
							<div className="text-[40px] font-semibold flex items-start gap-3 pb-[15px]">
								{selected_event?.type}
							</div>
						</div>
					</div>
					<div className="horizontal-divider" />
					<div className='flex flex-wrap gap-5 divide-x mt-5 pb-7'>
						<div className='pl-5 pr-20'>
							<div className='app-modal-fullscreen__label'>
								DATE
							</div>
							<div className='flex items-center'>
								<DateIcon className='mr-1.5' />
								<span className='app-modal-fullscreen__value'>
									{dateFormatter(selected_event.created_at)}, {" "}{timeForamtter(selected_event.created_at)}
								</span>
							</div>
						</div>
						<div className='pl-5 pr-20'>
							<div className='app-modal-fullscreen__label'>
								SOURCE
							</div>
							<div className='app-modal-fullscreen__value'>
								<span className='text-500  font-semibold'  >
									{!selected_event.request?.id ? "Automatic" : selected_event.request?.request?.from_dashboard ? "Dashboard" : `API Key - ${selected_event.request?.api_key}`}
								</span>
							</div>
						</div>
					</div>
					<JsonData
						data={selected_event.data.object}
					/>
					<WebhooksAttemps />
				</ModalFullScreen.Slot>
			</ModalFullScreen>
		</>
	)
}
