import "./SectionLinks.scss";
import Stack from "../../../Common/Stack/Stack";
import {ReactComponent as LinksIcon} from "../../../../assets/images/icons/links.svg";
import Button from "../../../Common/Form/Button/Button";
import ArrowRightIcon from "../../../../assets/images/icons/arrow-right.png";
import {Link} from "react-router-dom";

const linksData = [
  {
    icon: "house-crypto",
    title: `A flexible Marketplace solution <span class="block">for Cryptocurrency payments</span>`,
    text: `If you are a large platform or marketplace provider, 
      allow your Users to process and accept Cryptocurrency payments. 
      Create flexible Invoices or orders on their behalf, and collect your Platform fee on any payments made.`
  },
  {
    icon: "management",
    title: "Easy User Management",
    text: `Collect payments on behalf of your users, 
      then quickly and easily transfer their payments to their Link Account. 
      Linked Accounts can then create payouts and view the payments they have received from sales.`
  }
]

function SectionLinks({ scrollToContact }) {
  return (
    <div id="links" className="section-links">
      <div className="app-container">
        <Stack columns="1" className="lg:grid-cols-2">
          <div>
            <h3 className="h3 section-products__section__subtitle">
              <LinksIcon className="section-products__section__subtitle__icon" />
              Links
            </h3>
            <h1 className="h1 section-products__section__title">
              Powerful Payments for Platforms or Marketplaces.
            </h1>
            <p className="text section-products__section__text">
              Create Link accounts for your users to start processing payments on their behalf.
              Easily Collect fees on payments made to your users through your platform.
            </p>
            <div className="section-retail__buttons">
              <Link to="/register">
                <Button icon={ArrowRightIcon} color="primary" className="section-products__section__button">
                  Start Now
                </Button>
              </Link>
              <button onClick={scrollToContact} className="section-products__section__link">Contact us</button>
            </div>
          </div>
        </Stack>
        <Stack columns="1" gap="24" className="md:grid-cols-2 section-retail__features">
          {linksData.map((link, index) => {
            const SVGIcon = require(`../../../../assets/images/sections/links/${link.icon}.svg?svgr`).default;
            return (
              <div key={index} className="section-retail__features__content">
                <div className="section-retail__features__content__icon">
                  <SVGIcon />
                </div>
                <h5
                  dangerouslySetInnerHTML={{__html: link.title}}
                  className="h5 section-products__section__info section-retail__features__content__title"
                />
                <p className="text-small section-retail__features__content__text">
                  {link.text}
                </p>
              </div>
            )
          })}
        </Stack>
      </div>
    </div>
  )
}

export default SectionLinks;
