import React, { Children, useCallback } from "react";
import "./ModalFullScreen.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/modal-close.svg";
import clsx from "clsx";

const Slot = () => null;

function ModalFullScreen({
  show = false,
  fullscreen = false,
  children,
  ...rest
}) {
  const childrenArray = Children.toArray(children);
  const headerSlot = childrenArray.find(
    (child) => child?.props?.name === "header"
  );
  const bodySlot = childrenArray.find((child) => child?.props?.name === "body");
  const footerSlot = childrenArray.find(
    (child) => child?.props?.name === "footer"
  );

  const handleClose = useCallback(() => {
    rest.onClose && rest.onClose();
  }, [rest]);

  // useEffect(() => {
  //   if (show) document.body.style.overflow = "hidden";
  //   return () => (document.body.style.overflow = "unset");
  // }, [show]);

  // useEffect(() => {
  //   const close = (e) => {
  //     if(e.keyCode === 27){
  //       handleClose();
  //     }
  //   }
  //
  //   window.addEventListener('keydown', close)
  //   return () => window.removeEventListener('keydown', close)
  // }, [handleClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      className={clsx(
        "app-modal-fullscreen",
        fullscreen && "app-modal-fullscreen--full"
      )}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="app-modal-fullscreen__wrapper app-modal__wrapper--centered">
        {/*<div className="app-modal-fullscreen__overlay" aria-hidden="true" />*/}
        <div className="app-modal-fullscreen__container">
          <div className="app-modal-fullscreen__header">
            <div
              className="app-modal-fullscreen__header__container"
              id="modal-title"
            >
              <div
                onClick={handleClose}
                className="app-modal-fullscreen__header__close"
              >
                <CloseIcon />
              </div>
              <div className="vertical-divider" />
              {headerSlot?.props?.children}
            </div>
          </div>
          <div className="app-modal-fullscreen__body">
            {bodySlot?.props?.children}
          </div>
          <div className="app-modal-fullscreen__footer">
            {footerSlot?.props?.children}
          </div>
        </div>
      </div>
    </div>
  );
}

ModalFullScreen.Slot = Slot;

export default ModalFullScreen;
