import "./Button.scss";
import clsx from "clsx";
import Loader from "../../../../assets/images/loader.svg";

function Button({
  color = "primary",
  outline = null,
  className = "",
  loading = false,
  disabled = false,
  block = false,
  icon = null,
  children,
  ...rest
}) {
  return (
    <button
      disabled={disabled || loading}
      className={clsx(
        className,
        `app-button app-button--${color}`,
        outline && `app-button--${color}--outline`,
        block && 'app-button--block'
      )}
      {...rest}
    >
      <span className="app-button__content">
        {loading
          ? <img className="app-button__content--loader" src={Loader} alt="" />
          : children
        }
        {(icon && !loading) &&
          <div className="app-button__content__icon">
            <img src={icon} alt="" />
          </div>
        }
      </span>
    </button>
  )
}

export default Button;
