import Label from "../../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../../Common/Form/Input/Input";
import clsx from "clsx";
import ArrowIcon from "../../../../../../../../../assets/images/icons/dashboard/arrow_down.svg";
import {ReactComponent as CloseIcon} from "../../../../../../../../../assets/images/icons/modal-close.svg";
import SelectTaxRates from "../../../../../../../../Dashboard/SelectTaxRates/SelectTaxRates";
import { 
  currencyConverter, 
  currencyFormatter, 
  currencySymbol,
  sanitizeUnitAmount
} from "../../../../../../../../../utils/string";
import SelectDiscounts from "../../../../../../../../Dashboard/SelectDiscounts/SelectDiscounts";
import DashboardButton from "../../../../../../../../Common/Form/DashboardButton/DashboardButton";
import {useState} from "react";
import {useStoreInvoiceItem} from "../../../../../../../../../api/customer/invoice";
import {
  setRefetchInvoiceItems,
  setSelectedInvoiceItem,
  setSelectNewProduct,
  setShowProductsSelect
} from "../../../../../../../../../store/invoice/invoiceSlice";
import {useDispatch, useSelector} from "react-redux";
import InputPrice from "../../../../../../../../Dashboard/InputPrice/InputPrice";
import {useStorePrice} from "../../../../../../../../../api/product/price";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {PRICE_REGEX, INR_PRICE_REGEX} from "../../../../../../../../../utils/constants";

const formSchema = (currency) => yup.object({
  product_name: yup.string().required().trim().label('Item name'),
  price: yup.string()
    .required()
    .trim()
    .matches(currency?.toLowerCase() === 'inr' ? INR_PRICE_REGEX : PRICE_REGEX, `Price must be greater than 0 and must not exceed ${currency?.toLowerCase() === 'inr' ? '14' : '12'} decimal places`)
    .label('Price'),
  quantity: yup.number().required().min(1).label('Quantity').typeError("Quantity is a required field"),
}).required();

function InvoiceItemsCreateNewProduct() {
  const dispatch = useDispatch();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [showDiscountsSelect, setShowDiscountsSelect] = useState(true);
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(true);
  const {
    selected_invoice_customer,
    draft_invoice,
    invoice_currency,
    search_product_input
  } = useSelector((state) => state.invoice);

  const {
    watch,
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: {errors}
  } = useForm({
    defaultValues: {
      product_name: search_product_input ?? "",
      price: "",
      quantity: 1
    },
    resolver: yupResolver(formSchema(invoice_currency))
  });

  const price = watch("price");

  const { mutate: mutateStoreInvoiceItem, isLoading: isStoreInvoiceLoading } = useStoreInvoiceItem();
  const { mutate: mutateStorePrice, isLoading: isStorePriceLoading } = useStorePrice();

  const handleQuantityChange = (e) => {
    setValue("quantity", e.target.value);
    trigger("quantity");
  }

  const handlePriceChange = (value) => {
    setValue("price", value);
    trigger("price");
  }

  const handleDiscountSelect = (discount) => {
    setSelectedDiscount(discount);
    setShowDiscountsSelect(false);
  }

  const handleDeleteDiscountClick = () => {
    setSelectedDiscount(null);
    setShowDiscountsSelect(true);
  }

  const handleTaxRateSelect = (tax) => {
    if (selectedTaxRates.find(item => item.id === tax.id)) {
      return;
    }

    setSelectedTaxRates([
      ...selectedTaxRates,
      tax
    ]);
    setShowTaxRatesSelect(false);
  }

  const handleDeleteTaxRateClick = (tax) => {
    setSelectedTaxRates(selectedTaxRates.filter(item => item.id !== tax.id));
  }

  const handleAddTaxRateClick = () => {
    setShowTaxRatesSelect(true);
  }

  const handleCancelClick = () => {
    dispatch(setSelectNewProduct(false));
    dispatch(setShowProductsSelect(false));
  }

  const handleSaveClick = (formData, add_another = false) => {
    dispatch(setShowProductsSelect(false));

    const price_data = {
      currency: invoice_currency,
      type: "one_time",
      product_data: {
        name: formData.product_name,
      }
    }

    const unitAmount = currencyConverter(invoice_currency, price, true);
    if (unitAmount.includes('.')) {
      price_data.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
    } else price_data.unit_amount = Number(unitAmount);

    const _data = {
      customer: selected_invoice_customer.id,
      quantity: formData.quantity,
      invoice: draft_invoice?.id,
      tax_rates: selectedTaxRates.map(tax => tax.id),
    }

    if (selectedDiscount) {
      _data.discounts = [
        { discount: selectedDiscount.id }
      ]
    }

    mutateStorePrice(price_data, {
      onSuccess: (data) => {
        mutateStoreInvoiceItem({
          ..._data,
          price: data.data.id,
        }, {
          onSuccess: () => {
            dispatch(setSelectNewProduct(false));
            dispatch(setSelectedInvoiceItem(null));
            dispatch(setRefetchInvoiceItems(Date.now()));

            if (add_another) dispatch(setShowProductsSelect(true));
          },
          onError: (error) => {
            toast.error(error.response?.data.error.message);
          },
        })
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    })
  }

  const fieldError = (field) => {
    return errors[field]?.message/* || (error && error.response?.data.error.param === field)*/;
  }

  return (
    <>
      <div className="space-y-1.5 my-4 w-4/5">
        <div className="grid gap-5 grid-cols-6">
          <div className="col-span-3">
            <Label title="Item" />
            <Input
              block
              type="text"
              {...register("product_name")}
              error={fieldError("product_name")}
            />
          </div>
          <div>
            <Label title="Qty" />
            <Input
              type="number"
              block
              {...register("quantity", {
                onChange: handleQuantityChange
              })}
              className="text-light"
              placeholder="1"
              min="1"
              error={fieldError("quantity")}
            />
          </div>
          <div className="col-span-2">
            <Label title="Price" />
            <InputPrice
              value={price}
              onChange={handlePriceChange}
              className="text-light"
              currency={invoice_currency}
              placeholder="00.00"
              min="0"
              error={fieldError("price")}
            />
          </div>
        </div>
        <div className={clsx(
          "app-invoices__modal__collapse pt-2",
          showAdvanced && "app-invoices__modal__collapse--open"
        )}>
          <div
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="app-invoices__modal__collapse__menu app-modal-fullscreen__small-title mb-3"
          >
            <span className="text-secondary">Items taxes and discounts</span>
            <img className="app-invoices__modal__collapse__menu__icon" src={ArrowIcon} alt="" />
          </div>
          <div className="space-y-8 app-invoices__modal__collapse__item">
            <div className="grid grid-cols-3 gap-6">
              <div>
                <Label title="Item taxes"/>
                <div>
                  {selectedTaxRates.map((tax, index) =>
                    <div
                      key={index}
                      className="app-invoices__modal__discount-tax mb-1"
                    >
                      <div>
                        <span className="mr-2">{tax.display_name}</span>
                        {`(${tax.percent}%${tax.inclusive && ' incl.'})`}
                      </div>
                      <CloseIcon onClick={() => handleDeleteTaxRateClick(tax)} />
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  {showTaxRatesSelect
                    ?
                    <>
                      <SelectTaxRates
                        onSelect={handleTaxRateSelect}
                        data={selectedTaxRates.map(tax => tax.id)}
                      />
                    </>
                    :
                    <span
                      onClick={handleAddTaxRateClick}
                      className="app-invoices__modal__link"
                    >
                    Add another tax rate
                  </span>
                  }
                </div>
              </div>
              <div>
                <Label title="Item discounts"/>
                {selectedDiscount &&
                <div>
                  <div className="app-invoices__modal__discount-tax mb-1">
                    <div>
                      <span className="mr-2">{selectedDiscount.name}</span>
                      {selectedDiscount.amount_off && (
                        <>
                          {`- ${currencySymbol(selectedDiscount.currency)} ${currencyFormatter(
                            selectedDiscount.currency,
                            selectedDiscount.amount_off
                          )} off`}
                        </>
                      )}
                      {selectedDiscount.percent_off && (
                        <>{`- ${selectedDiscount.percent_off}% off`}</>
                      )}
                    </div>
                    <CloseIcon onClick={() => handleDeleteDiscountClick()}/>
                  </div>
                </div>
                }
                <div className="mt-2">
                  {(!selectedDiscount && showDiscountsSelect) &&
                  <SelectDiscounts
                    onSelect={handleDiscountSelect}
                  />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="horizontal-divider" />
        <div className="flex justify-end items-center mt-4 gap-3">
          <div
            onClick={handleCancelClick}
            className="text-secondary font-semibold cursor-pointer"
          >
            Cancel
          </div>
          <DashboardButton
            onClick={() => handleSubmit((data) => handleSaveClick(data, true))()}
            type="button"
            dense
            color="light"
          >
            Save and add another
          </DashboardButton>
          <DashboardButton
            disabled={isStoreInvoiceLoading || isStorePriceLoading}
            type="button"
            onClick={() => handleSubmit((data) => handleSaveClick(data))()}
            dense
            color="primary"
          >
            Save
          </DashboardButton>
        </div>
      </div>
    </>
  )
}

export default InvoiceItemsCreateNewProduct;
