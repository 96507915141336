import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useFetchPayout } from 'api/wallet/payouts';
import DashboardNavbar from "layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "components/Common/Form/DashboardButton/DashboardButton";
import { ReactComponent as ExportIcon } from "assets/images/icons/export.svg";
import useTableFilters from "hooks/useTableFilters";
import DropdownFilters from "components/Common/DropdownFilters/DropdownFilters";
import { setSelectedPayout } from "store/payouts/payoutsSlice";
import PayoutsTable from "./PayoutsTable";
import PayoutsCreateModal from "./PayoutsCreateModal";
import PayoutDetails from "./PayoutDetails";

const TABLE_FILTERS = [
  { label: "All", column: "crypto", value: "" },
  { label: "Crypto", column: "crypto", value: true },
  { label: "Fiat", column: "crypto", value: false},
];

function Payouts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selected_payout, refetch_payout } = useSelector((state) => state.payout);
  const [showDetailModal, setShowDetailsModal] = useState(false);
  const { id } = useParams();
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create"
  );
  const [refreshPayout, setRefreshPayout] = useState(false);
  const [filters, setFilters] = useState({});
  const { activeFilter, setFilter, isFilterActive } = useTableFilters({
    filters: TABLE_FILTERS,
    defaultFilter: TABLE_FILTERS[0],
  });
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchPayout({
    onSuccess: (data) => {
      dispatch(setSelectedPayout(data));
  
      setRefreshPayout(false);
    },
    onError: (error) => {
      setRefreshPayout(false);
      navigate(DASHBOARD_PATH+ "/payouts");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: !!id && (id !== "create" && id !== "edit"), 
    params: {
      expand: [
        'blockchain_transaction',
        'balance_transaction',
        'network_fee_balance_transaction'
      ],
    },
  });

  useEffect(() => {

    console.log("showPriceModal", location.pathname);
    if (showCreateModal) {
      if (!location.pathname.endsWith("/create" ) ) {
            navigate(location.pathname + "/create");
      } 
    }  
  
  
  }, [showCreateModal]);
  
  // Details modal:
  const handleCloseModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedPayout(null));
    navigate(DASHBOARD_PATH + "/payouts");
  }, [dispatch, navigate]);

  useEffect(() => {
    setShowDetailsModal(!!selected_payout);
  }, [selected_payout]);

  useEffect(() => {
    if (!id) {
      handleCloseModal();
      return;
    }

    setRefreshPayout(true);
  }, [refetch_payout, id, handleCloseModal]);
  
  // Closes modal
  useEffect(() => {
    return () => {
      dispatch(setSelectedPayout(null));
    };
  }, [dispatch]);

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    // dispatch(setDraftInvoice(null));
  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);

    setFilter({ column: "status", value: filters?.status ?? "" });
  };

  const activeFilterFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(activeFilter).filter(([_, v]) => v != null && v !== "")
    );
  }, [activeFilter]);

//   const getFilters = useMemo(() => {
//     return {
//       ...filters,
//       ...(activeFilter.status ? activeFilter : null),
//     };
//   }, [filters, activeFilter]);

//   const handleFilterClick = (filter) => {
//     if (filter && filter.label === "All") {
//       setFilters((state) => {
//         const { status, ...rest } = state;

//         return rest;
//       });
//     }

//     setFilter(filter);
//   };

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="flex">
              <div className="app-dashboard__card__title">Payouts</div>
              <span className="app-dashboard__card__header__filters">
              </span>
            </div>
            <div className="app-dashboard__card__buttons">
            <DropdownFilters
                filters={[
                  "currency_type",
                  "topup_method",
                  "payout_status",
                  "amount",
                  "crypto_amount",
                  "currency",
                  "crypto_currency",
                  "crypto_to_address",
                  "tx_hash",
                  "date",
                ]}
                data={activeFilterFiltered}
                onChange={handleFiltersChange}
                page="payouts"
              />
          <DashboardButton
                onClick={() => setShowCreateModal(true)}
                color='primary'
                outline
                icon='+'>
                <span className='text-dark'>
                  New Payout
                </span>
              </DashboardButton>
              <DashboardButton color="tertiary" outline icon={<ExportIcon />}>
                <span className="text-dark">Export</span>
              </DashboardButton>
              </div>
          </div>
          <PayoutsTable filters={filters} />
        </div>
        {showDetailModal && (
          <PayoutDetails
            show={showDetailModal}
            onClose={handleCloseModal}
          />
        )}
        {showCreateModal && (
          <PayoutsCreateModal
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
        <ReactTooltip
          id="payout-tooltip"
          place="bottom"
          effect="solid"
          type="light"
          padding="10px"
          textColor="#1A1E23"
          backgroundColor="#ffffff"
          className="api-key__tooltip"
        >
        </ReactTooltip>
      </div>
    </div>
  );
}

export default Payouts;
