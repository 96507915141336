import { createSlice } from '@reduxjs/toolkit'

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    selected_event: null,
    refetch_event: null,
  },
  reducers: {
    setSelectedEvent: (state, payload) => {
      state.selected_event = payload.payload
    },
    setRefetchEvent: (state, payload) => {
      state.refetch_event = payload.payload
    },

  },
})

export const {
  setSelectedEvent,
  setRefetchEvent,
} = eventSlice.actions

export default eventSlice.reducer
