import Label from "../../../../../../../Common/Form/Label/Label";
import Input from "../../../../../../../Common/Form/Input/Input";
import React from "react";
import {useSelector} from "react-redux";
import InfoIcon from "../../../../../../../../assets/images/icons/info.svg";

function DiscountsModalCreatePromoCode({ register }) {
  const { selected_discount } = useSelector((state) => state.discount);

  return (
    <div>
      <Label
        title="Promo code"
        subtitle="(optional)"
        icon={InfoIcon}
        tooltipFor="discount-modal-create"
        tooltipHtml={true}
        tooltip={`
          Create a promotional code for this Discount for customers to use during checkout
        `}
      />
      <Input
        {...register("promo_code")}
        block
        type="text"
        disabled={selected_discount?.id}
      />
    </div>
  )
}

export default DiscountsModalCreatePromoCode;