import clsx from "clsx";
import Label from '../../../../../../../Common/Form/Label/Label';
import Select from "../../../../../../../Common/Form/Select/Select";
import InfoIconImage from '../../../../../../../../assets/images/icons/info.svg';
import React, {forwardRef} from "react";

const BillingPeriod = forwardRef(({
  placeholder = "",
  className = "",
  block = false,
  error = "",
  onIntervalChange = null,
	onBillingPeriodChange = null,
	billing_period = "",
  interval = "",
  ...rest
}, ref) => {
  const handleIntervalSelect = (e) => {
    onIntervalChange && onIntervalChange(e);
  }
	const handleBillingPeriodSelect = (e) => {
		onBillingPeriodChange && onBillingPeriodChange(e)
	}

  return (
		<>
			<div>
				<Label
					title='Billing Period'
					error={error}
					icon={InfoIconImage}
          tooltipHtml={true}
          tooltip={`Define the frequency to bill a customer with this price`} 
				/>
				<Select
					onSelect={handleBillingPeriodSelect}
					options={[
						{label: "Daily", value: "daily"},
						{label: "Weekly", value: "weekly"},
						{label: "Monthly", value: "monthly"},
						{label: "Every 3 Months", value: "three_monthly"},
						{label: "Every 6 Months", value: "six_monthly"},
						{label: "Yearly", value: "yearly"},
						{label: "Custom", value: "custom"},
					]}
					value={billing_period}
					placeholder={billing_period ? billing_period : 'daily'}
					disabled={rest.disabled ?? false}
				/>
				{billing_period && billing_period === 'custom' && (
				<>
				<div style={{marginTop: '10px'}}></div>
				<div className="app-input-price">
					<div className="app-input-price__currency">Every</div>
					<input
						ref={ref}
						className={clsx(
							className,
							'app-input-price__input placeholder-white',
							block && 'app-input-price__input--block',
							error && 'app-input-price__input--error',
						)}
						type="number"
						placeholder={placeholder}
						{...rest}
					/>
					<div className="app-input-price__currencies">
						<div className="app-input-price__currencies__select">
							<div className="app-input-price__divider" />
							<Select
								onSelect={handleIntervalSelect}
								options={[
									{label: "days", value: "day"},
									{label: "weeks", value: "week"},
									{label: "months", value: "month"},
									// {label: "years", value: "year"},
								]}
								value={interval}
								borderLess
								disabled={rest.disabled ?? false}
							/>
						</div>
					</div>
					{error && <p className="app-input-price__input__help">{error}</p>}
				</div>
				</>)}
			</div>
		</>
  )
});

export default BillingPeriod;
