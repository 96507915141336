import "./SectionVolatile.scss";
import {ReactComponent as VolatileIcon} from "../../../../assets/images/icons/volatile.svg";
import Stack from "../../../Common/Stack/Stack";

function SectionVolatile() {
  return (
    <div id="volatile" className="section-volatile">
      <div className="app-container">
        <h3 className="h3 section-products__section__subtitle">
          <VolatileIcon className="section-products__section__subtitle__icon" />
          Volatile
        </h3>
        <h1 className="h1 section-products__section__title">
          Cryptocurrency is volatile, but we have you sorted.
        </h1>
        <p className="text section-products__section__text section-volatile__text">
          Avoid the price volatility of cryptocurrency, by enabling our
          Auto-convert features or using Payment Padding for your payments.
          Auto- convert secures the Value of your Sale, by automatically converting
          incoming payments into a Stable coin. Payment padding allows you to add an
          additional Percent fee on top of your payment amount, to factor in any price volatility.

          <span>How Auto convert works:</span>
        </p>
        <div className="section-volatile__steps">
          <Stack columns="1" gap="0" className="lg:grid-cols-3 section-volatile__steps__container">
            <div className="section-volatile__step__container">
              <div className="section-volatile__step">
                <div className="section-volatile__step__number section-volatile__step__number--blue">
                  <span>01</span>
                </div>
                <div className="section-volatile__step__line" />
              </div>
              <div>
                <h5 className="h5 section-volatile__step__title">
                  Enable Auto-convert
                </h5>
                <p className="text-small section-volatile__step__text">
                  Create a simple Payment request, sell your product or service, collect a donation,
                  or start a new subscription all without a single line of code.
                </p>
              </div>
            </div>
            <div className="section-volatile__step__container">
              <div className="section-volatile__step">
                <div className="section-volatile__step__number section-volatile__step__number--white">
                  <span>02</span>
                </div>
                <div className="section-volatile__step__line" />
              </div>
              <div>
                <h5 className="h5 section-volatile__step__title">
                  Receiving Payments are converted
                </h5>
                <p className="text-small section-volatile__step__text">
                  When a payment is received, it will automatically be converted to your
                  selected stable coin to protect you from price volatility.
                </p>
              </div>
            </div>
            <div className="section-volatile__step__container">
              <div className="section-volatile__step">
                <div className="section-volatile__step__number section-volatile__step__number--blue">
                  <span>03</span>
                </div>
              </div>
              <div>
                <h5 className="h5 section-volatile__step__title">
                  Secure your Sale Value
                </h5>
                <p className="text-small section-volatile__step__text">
                  Ensure you secure the value of your sale, without the risk of it decreasing.
                </p>
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default SectionVolatile;
