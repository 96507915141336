import "./Webhooks.scss";
import DashboardNavbar from "../../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar";
import DashboardButton from "../../../../components/Common/Form/DashboardButton/DashboardButton";
import WebhooksTable from "../../../../components/Pages/Dashboard/Webhooks/WebhooksTable/WebhooksTable";
import React, {useCallback, useEffect, useState} from "react";
import WebhooksModalCreate
  from "../../../../components/Pages/Dashboard/Webhooks/WebhooksModals/WebhooksModalCreate/WebhooksModalCreate";
import WebhooksModalDetails
  from "../../../../components/Pages/Dashboard/Webhooks/WebhooksModals/WebhooksModalDetails/WebhooksModalDetails";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useFetchWebhook} from "../../../../api/webhook/webhook";
import {setSelectedWebhook, setWebhookSecretKey} from "../../../../store/webhook/webhookSlice";
import {useNavigate, useParams} from "react-router-dom";

function Webhooks() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    refetch_webhook,
    selected_webhook,
    show_webhook_form,
    webhook_secret_key
  } = useSelector((state) => state.webhook);
  const [refreshWebhook, setRefreshWebhook] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const { id } = useParams();
  const { live_mode_access } = useSelector((state) => state.auth.current_account);

const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchWebhook({
    onSuccess: (data) => {
      setRefreshWebhook(false);
      if (!webhook_secret_key) {
        dispatch(setSelectedWebhook(data));
        return;
      }

      dispatch(setSelectedWebhook({
        ...data,
        secret: webhook_secret_key
      }));
      dispatch(setWebhookSecretKey(null));
    },
    onError: (error) => {
      setRefreshWebhook(false);
      navigate(DASHBOARD_PATH + "/webhooks");
      toast.error(error.response?.data.error.message);
    },
    id,
    enabled: refreshWebhook,
  })

  const handleCloseDetailsModal = useCallback(() => {
    setShowDetailsModal(false);
    dispatch(setSelectedWebhook(null));
    navigate(DASHBOARD_PATH + "/webhooks");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!id) {
      handleCloseDetailsModal();
      return;
    }

    setRefreshWebhook(true);
  }, [refetch_webhook, id, handleCloseDetailsModal]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedWebhook(null));
    }
  }, [dispatch]);

  useEffect(() => {
    setShowDetailsModal(!!selected_webhook);
  }, [selected_webhook]);

  useEffect(() => {
    setShowFormModal(show_webhook_form);
  }, [show_webhook_form]);

  return (
    <div className="app-dashboard-container">
      <DashboardNavbar />
      <div className="app-dashboard__content">
        <div className="app-dashboard__card">
          <div className="app-dashboard__card__header">
            <div className="app-dashboard__card__title">Webhooks</div>
            <DashboardButton onClick={() => setShowFormModal(true)} color="primary" outline icon="+">
              <span className="text-dark">New Webhook</span>
            </DashboardButton>
          </div>
          <WebhooksTable />
        </div>
        {showFormModal &&
        <WebhooksModalCreate show={showFormModal} onClose={() => setShowFormModal(false)} />
        }
        {showDetailsModal &&
        <WebhooksModalDetails show={showDetailsModal} onClose={handleCloseDetailsModal} />
        }
      </div>
    </div>
  )
}

export default Webhooks;
