import Label from "../../../../../../../Common/Form/Label/Label";
import InputGroup from "../../../../../../../Common/Form/InputGroup/InputGroup";
import React from "react";
import {useSelector} from "react-redux";

function DiscountsModalCreatePercentOff({ register, error = "" }) {
  const { selected_discount } = useSelector((state) => state.discount);

  return (
    <div>
      <Label
        title="Value"
        error={error}
      />
      <InputGroup
        {...register("percent_off")}
        type="number"
        min="0"
        step="0.01"
        append="%"
        block
        className="col-span-2"
        error={error}
        disabled={selected_discount?.id}
      />
    </div>
  )
}

export default DiscountsModalCreatePercentOff;