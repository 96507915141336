import React, { useState } from 'react';
import Label from '../../../../../../Common/Form/Label/Label';
import Select from '../../../../../../Common/Form/Select/Select';
import Textarea from '../../../../../../Common/Form/Textarea/Textarea';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import InputGroup from '../../../../../../Common/Form/InputGroup/InputGroup';
import InputPrice from '../../../../../../Common/Form/InputPrice/InputPrice';
import Checkbox from '../../../../../../Common/Form/Checkbox/Checkbox';
import InfoIconImage from '../../../../../../../assets/images/icons/info.svg';
import { ReactComponent as InfoIcon } from "../../../../../../../assets/images/icons/info.svg";
import { FIAT_CURRENCIES_SELECT } from '../../../../../../../utils/constants';
import { fiatCurrencies } from '../../../../../../../utils/string';
import { priceDescription } from 'utils/price_utils';

const CustomerChooses = ({
  register,
  setValue,
  fieldError,
  currency,
  preset,
  minimum,
  maximum,
}) => {
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  const [limits, setlimits] =
    useState();

  const handleCurrencyChange = (e) => {
    setValue('currency', e.value);
  };

  return (
    <>
      <div>
        <Label 
          title='Preset Amount' 
          subtitle='(optional)'
          icon={InfoIconImage}
          tooltipHtml={true}
          tooltip={`
          This amount will be suggested to the customer. They can choose to pay a different amount.
          `}
        />
        <InputPrice
          {...register('custom_unit_amount.preset')}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={
            fiatCurrencies[currency]
                  .zero_decimal
                ? '0'
                : '0.00'
          }
          error={fieldError('custom_unit_amount.preset')}
          value={preset}
          disabled={selected_product_price?.id}
        />
      </div>
      <div className='flex items-center gap-3'>
            <Checkbox
            onClick={()=>{setlimits(!limits)}}
              color='primary'
              className='mt-1'
            />
            <div className='text-light text-sm'>
              Set limits
            </div>
            <InfoIcon
              data-tip={
                "Set a minimum and maximum amount a customer can choose to pay"
              }
            />
          </div>
          {limits && (
            <>
              <div className='horizontal-divider' />
              <div>
                <Label title='Minimum amount' subtitle='(optional)' />
                <InputPrice
          {...register('custom_unit_amount.minimum')}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={
            fiatCurrencies[currency]
                  .zero_decimal
                ? '0'
                : '0.00'
          }
          error={fieldError('custom_unit_amount.minimum')}
          value={minimum}
          disableCurrencies={true}

          disabled={selected_product_price?.id}
        />
                <Label title='Maximum amount' subtitle='(optional)' />
                <InputPrice
          {...register('custom_unit_amount.maximum')}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={
            fiatCurrencies[currency]
                  .zero_decimal
                ? '0'
                : '0.00'
          }
          error={fieldError('custom_unit_amount.maximum')}
          value={maximum}
          disableCurrencies={true}
          disabled={selected_product_price?.id}
        />
              </div>
            </>
          )}
    
    </>
  );
};

export default CustomerChooses;
export const CustomerChoosesCreate = ({
  index,
  register,
  fieldError,
  currency,
  preset,
  minimum,
  maximum,
  update,
  prices,
}) => {
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  const [limits, setlimits] =
    useState();

  const handleCurrencyChange = (e) => {
    update(index, {
      ...prices[index],
      currency: e.value
    });
  };

  return (
    <>
      <div>
        <Label 
          title='Preset Amount' 
          subtitle='(optional)'
          icon={InfoIconImage}
          tooltipHtml={true}
          tooltip={`
          This amount will be suggested to the customer. They can choose to pay a different amount.
          `}
        />
        <InputPrice
          {...register(`prices.${index}.custom_unit_amount.preset`)}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={
            fiatCurrencies[currency]
                  .zero_decimal
                ? '0'
                : '0.00'
          }
          error={fieldError(`prices.${index}.custom_unit_amount.preset`)}
          value={preset}
          disabled={selected_product_price?.id}
        />
      </div>
      <div className='flex items-center gap-3'>
            <Checkbox
            onClick={()=>{setlimits(!limits)}}
              color='primary'
              className='mt-1'
            />
            <div className='text-light text-sm'>
              Set limits
            </div>
            <InfoIcon
              data-tip={
                "Set a minimum and maximum amount a customer can choose to pay"
              }
            />
          </div>
          {limits && (
            <>
              <div className='horizontal-divider' />
              <div>
                <Label title='Minimum amount' subtitle='(optional)' />
                <InputPrice
          {...register(`prices.${index}.custom_unit_amount.minimum`)}

          // onchange={(e)=>setValue('custom_unit_amount.minimum',e.target.value)}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          
          placeholder={
            fiatCurrencies[currency]
                  .zero_decimal
                ? '0'
                : '0.00'
          }
          error={fieldError(`prices.${index}.custom_unit_amount.minimum`)}
          value={minimum}
          disableCurrencies={true}

          disabled={selected_product_price?.id}
        />
                <Label title='Maximum amount' subtitle='(optional)' />
                <InputPrice
          {...register(`prices.${index}.custom_unit_amount.maximum`)}

          block
          min='0'
          // onchange={(e)=>setValue('custom_unit_amount.maximum',e.target.value)}
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={
            fiatCurrencies[currency]
                  .zero_decimal
                ? '0'
                : '0.00'
          }
          error={fieldError(`prices.${index}.custom_unit_amount.maximum`)}
          value={maximum}
          disableCurrencies={true}
          disabled={selected_product_price?.id}
        />
              </div>
            </>
          )}
    
    </>
  );
};


