import './Customers.scss';
import DashboardNavbar from '../../../layouts/Dashboard/DashboardNavbar/DashboardNavbar';
import DashboardButton from '../../../components/Common/Form/DashboardButton/DashboardButton';
import CustomersTable from '../../../components/Pages/Dashboard/Customers/CustomersTable/CustomersTable';
import { ReactComponent as ExportIcon } from '../../../assets/images/icons/export.svg';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedCustomer,
  setShowCustomerForm,
} from '../../../store/customer/customerSlice';
import CustomersModalDetails from '../../../components/Pages/Dashboard/Customers/CustomersModals/CustomersModalDetails/CustomersModalDetails';
import CustomersModalCreate from '../../../components/Pages/Dashboard/Customers/CustomersModals/CustomersModalCreate/CustomersModalCreate';
import DropdownFilters from '../../../components/Common/DropdownFilters/DropdownFilters';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetchCustomer } from '../../../api/customer/customer';
import { toast } from 'react-toastify';

function Customers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    selected_customer,
    show_customer_form,
    refetch_customer,
  } = useSelector((state) => state.customer);
  const [filters, setFilters] = useState({});
  const { id } = useParams();
  const [showCreateModal, setShowCreateModal] = useState(
    id === "create"
  );
  const [showModal, setShowModal] = useState(
   false
  );
  const [refreshCustomer, setRefreshCustomer] =
    useState(false);
  const { live_mode_access } = useSelector((state) => state.auth.current_account);
const DASHBOARD_PATH = !live_mode_access ?'/dashboard/test' :'/dashboard';
  useFetchCustomer({
    onSuccess: (data) => {
      dispatch(setSelectedCustomer(data));
      setRefreshCustomer(false);
    },
    onError: (error) => {
      setRefreshCustomer(false);
      navigate(DASHBOARD_PATH + '/customers');
      toast.error(error.response?.data.error.message);
    },
    id: id ,
    enabled: !!id && (id !== "create" && id !== "edit"),
  });

  useEffect(() => {

    console.log("showPriceModal", location.pathname);
    if (showCreateModal) {
      if (!location.pathname.endsWith("/create" ) ) {
            navigate(location.pathname + "/create");
      } 
    }  
  
  
  }, [showCreateModal]);
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    dispatch(setSelectedCustomer(null));
    navigate(DASHBOARD_PATH + '/customers');
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!id) {
      handleCloseModal();
      return;
    }

    setRefreshCustomer(true);
  }, [refetch_customer, id, handleCloseModal]);

  useEffect(() => {
    setShowModal(!!selected_customer);
  }, [selected_customer]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCustomer(null));
    };
  }, [dispatch]);

  const handleCloseCreateModal = () => {
   setShowCreateModal(false);
  };

  const handleNewCustomerClick = () => {
    setShowCreateModal(true);

  };

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  return (
    <div className='app-dashboard-container'>
      <DashboardNavbar />
      <div className='app-dashboard__content'>
        <div className='app-dashboard__card'>
          <div className='app-dashboard__card__header'>
            <div className='flex'>
              <div className='app-dashboard__card__title'>
                Customers
              </div>
            </div>
            <div className='app-dashboard__card__buttons'>
              <DropdownFilters
                filters={[
                  'date',
                  'name',
                  'email',
                  'metadata',
                ]}
                onChange={handleFiltersChange}
              />
              <DashboardButton
                onClick={handleNewCustomerClick}
                color='primary'
                outline
                icon='+'>
                <span className='text-dark'>
                  New Customer
                </span>
              </DashboardButton>
              <DashboardButton
                color='tertiary'
                outline
                icon={<ExportIcon />}>
                <span className='text-dark'>Export</span>
              </DashboardButton>
            </div>
          </div>
          <CustomersTable filters={filters} />
        </div>
        {showModal && (
          <CustomersModalDetails
            show={showModal}
            onClose={handleCloseModal}
          />
        )}
        {showCreateModal && (
          <CustomersModalCreate
            show={showCreateModal}
            onClose={handleCloseCreateModal}
          />
        )}
      </div>
    </div>
  );
}

export default Customers;
