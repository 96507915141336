import clsx from "clsx";
import Calendar from 'react-calendar';
import "./DropdownFiltersFormDatePicker.scss";
import {useRef, useState} from "react";
import {ReactComponent as CalendarIcon} from "../../../../../assets/images/icons/dashboard/calendar.svg";
import moment from "moment";
import useOutsideClick from "../../../../../hooks/useOutsideClick";
import {ReactComponent as CloseIcon} from "../../../../../assets/images/icons/modal-close.svg";

function DropdownFiltersFormDatePicker({
  placeholder = "",
  className = "",
  error = "",
  onChange,
  ...rest
}) {
  const calendarRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  useOutsideClick(calendarRef, () => setOpen(false));

  const handleDateChange = (date) => {
    setValue(date);
    onChange && onChange(date);
    setOpen(false);
  }

  const handleClearDate = () => {
    setValue("");
    onChange && onChange("");
    setOpen(false);
  }

  return (
    <div ref={calendarRef} className="app-dropdown__filters__datepicker">
      <div className="app-dropdown__filters__datepicker__input">
        <input
          onClick={() => setOpen(!open)}
          type="text"
          className={clsx(
            "app-dropdown__filters__input",
            error && 'app-dropdown__filters__input--error',
            className,
          )}
          defaultValue={value ? moment(value).format("D/M/YYYY") : ""}
          readOnly
          {...rest}
        />
        <div className="app-dropdown__filters__datepicker__input__icon">
          <CalendarIcon />
        </div>
        {value &&
        <div className="app-dropdown__filters__datepicker__input__clear">
          <CloseIcon onClick={handleClearDate}/>
        </div>
        }
      </div>
      {open &&
      <Calendar
        onChange={handleDateChange}
        value={value ? value : null}
        maxDate={new Date()}
        formatShortWeekday={(locale, date) => moment(date).format('dd')}
      />}
      {error && <p className="app-input__help">{error}</p>}
    </div>
  )
}

export default DropdownFiltersFormDatePicker;