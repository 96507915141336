import { useSelector } from 'react-redux';
import {
  capitalize,
} from 'utils/string';
import { blockchainNetwork } from 'utils/crypto_utils';

const PaymentMethod = () => {
  const { selected_payment } = useSelector(
    (state) => state.payment
  );

  return (
    <div className='space-y-1.5 pb-2'>
      <div className='app-modal-fullscreen__title'>
        Payment Method
      </div>
      <div className='horizontal-divider' />
      <div className='pt-4'>
        <div className='grid grid-cols-5 gap-4'>
          <div className='col-span-2 space-y-2'>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Transaction ID
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment?.blockchain_transaction?.id ?? '-'}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Method
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {capitalize(selected_payment.method)}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Network
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment?.blockchain_transaction?.network ? blockchainNetwork(
                  selected_payment.blockchain_transaction.network
                ) : '-'}
              </div>
            </div>
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Address
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {
                  selected_payment?.blockchain_transaction
                    ?.from_addr ? selected_payment.blockchain_transaction
                    .from_addr : '-'
                }
              </div>
            </div>
            {selected_payment?.blockchain_transaction
              ?.memo && (
              <div className='app-modal-fullscreen__kv'>
                <div className='app-modal-fullscreen__kv__key'>
                  Transaction Memo
                </div>
                <div className='app-modal-fullscreen__kv__value'>
                  {
                    selected_payment.blockchain_transaction
                      .memo
                  }
                </div>
              </div>
            )}
            <div className='app-modal-fullscreen__kv'>
              <div className='app-modal-fullscreen__kv__key'>
                Transaction Hash
              </div>
              <div className='app-modal-fullscreen__kv__value'>
                {selected_payment?.blockchain_transaction?.tx_hash ? (selected_payment.blockchain_transaction
                  .explorer ? (
                  <a
                    href={
                      selected_payment
                        .blockchain_transaction.explorer
                    }
                    target='_blank'
                    rel='noreferrer'
                    className='text-tertiary'>
                    {
                      selected_payment
                        .blockchain_transaction.tx_hash
                    }
                  </a>
                ) : (
                  <>
                    {
                      selected_payment
                        .blockchain_transaction.tx_hash
                    }
                  </>
                )) : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
