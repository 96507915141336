import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const fetchPayouts = (params) =>
  appStore.getState().app.livemode
    ? api.live.get("/payouts", { params })
    : api.sandbox.get("/payouts", { params });

export const useFetchPayouts = ({
  onSuccess,
  onError,
  enabled,
  params = null,
}) => {
  return useQuery(["fetchPayouts", params], () => fetchPayouts(params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const fetchPayout = (id, params) =>
  appStore.getState().app.livemode
    ? api.live.get(`/payouts/${id}`, { params })
    : api.sandbox.get(`/payouts/${id}`, { params });

export const useFetchPayout = ({ onSuccess, onError, enabled, id, params = null }) => {
  return useQuery(["fetchPayout", id], () => fetchPayout(id, params), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const storePayout = (data) =>
  appStore.getState().app.livemode
    ? api.live.post("/payouts", data)
    : api.sandbox.post("/payouts", data);

export const useStorePayout = () => {
  return useMutation("storePayout", storePayout);
};

const updatePayout = (id, data, params) =>
  appStore.getState().app.livemode
    ? api.live.patch(`/payouts/${id}`, data, { params })
    : api.sandbox.patch(`/payouts/${id}`, data, { params });

export const useUpdatePayout = (id, params = null) => {
  return useMutation(["updatePayout", id], (data) =>
    updatePayout(id, data, params)
  );
};