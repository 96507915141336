import React from 'react';
import Label from '../../../../../../../Common/Form/Label/Label';
import Select from '../../../../../../../Common/Form/Select/Select';

const MeteredAggregation = ({
	onAggregationChange,
	error,
	disabled = false, // 
  aggregate_usage,
}) => {
  
  return (
    <>
			<div>
				<Label title='Metered aggregation method' />
				<Select
					onSelect={(e) => onAggregationChange(e)}
					options={[
						{
							label: 'Sum of usage during period',
							value: 'sum',
						},
						{
							label:
								'Most recent usage during period',
							value: 'last_during_period',
						},
						{
							label:
								'Most recent usage - including out of period',
							value: 'last_ever',
						},
						{
							label: 'Maximum usage during period',
							value: 'max',
						},
					]}
					value={aggregate_usage}
					disabled={disabled ?? false}
					error={error}
				/>
			</div>
		</>
	)
};

export default MeteredAggregation;
