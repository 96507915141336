import ModalFullScreen from '../../../../Common/ModalFullScreen/ModalFullScreen';
import Button from '../../../../Common/Form/Button/Button';
import React, { useEffect, useMemo, useState } from 'react';
import Stack from '../../../../Common/Stack/Stack';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFormSchemaPaymentLink,
  setFormSchemaPaymentLink,
  setRefetchPaymentLink,
} from 'store/paymentLink/paymentLinkSlice';
import { useForm } from 'react-hook-form';
import { useUpdatePaymentLink } from 'api/payment/paymentLink';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  currencyConverter,
  currencyFormatter,
  currencySymbol,
} from '../../../../../utils/string';
import PaymentLinkPreview from './PaymentLinkPreview';
import Select from 'components/Common/Form/Select/Select';
import Label from 'components/Common/Form/Label/Label';
import SelectProductsPrices from 'components/Dashboard/SelectProductsPrices/SelectProductsPrices';
import { ReactComponent as ImagePlaceholder } from '../../../../../assets/images/dashboard/product-placeholder.svg';
import { dropdownPriceDescription } from 'utils/price_utils';
import ProductsModalCreate from '../../Products/ProductsModals/ProductsModalCreate/ProductsModalCreate';
import { setEditProduct } from 'store/product/productSlice';
import SelectTaxRates from 'components/Dashboard/SelectTaxRates/SelectTaxRates';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/modal-close.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/images/icons/dashboard/arrow_down.svg';
import { computePaymentLinkPreview } from 'utils/payment_links';
import ProductsModalCreateUpload from '../../Products/ProductsModals/ProductsModalCreate/ProductsModalCreateForm/ProductsModalCreateUpload';
import Input from 'components/Common/Form/Input/Input';
import InputPrice from 'components/Common/Form/InputPrice/InputPrice';
import { FIAT_CURRENCIES_SELECT } from 'utils/constants';
import { CustomInputsInput, CustomOptions } from 'components/Common/Form/CustomInputsInput';

const CutomerChooseswhatToPayForm = ({
  expanded = true,
  setExpanded = null,
  newProducts = [],
  setProducts = null,
  setCurrency = null,
  product = null,
  currency = null,
  index = null,
  onDelete = null,
  quantity_options = null,
  tax_options = null,
}) => {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({});
  const imagesLength = useMemo(() => {
    return files?.length + images?.length;
  }, [files, images]);
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(true);

  useEffect(() => {
    const newFormData = {
      ...formData,
      image: files[0] ?? images[0],
    }
    setFormData(newFormData);
  }, [files, images]);

  useEffect(() => {
    console.log("formData>", formData)
  }, [formData]);

  useEffect(() => {
    const _product = {
      ...product,
      currency: currency ?? product?.currency,
    }
    console.log("_product", _product)
    setFormData(_product);
  }, [newProducts, product, currency]);

  const handleSave = () => {
    console.log("formData >>>>>>> save", formData, "save")
    const newProduct = {
      product: formData,
      expanded: false,
    }
    if (newProducts.length) {
      const _newProducts = [...newProducts];
      _newProducts[index] = newProduct;
      setProducts(_newProducts);
    } else {
      setProducts([newProduct]);
      if (setCurrency && !currency) {
        setCurrency(formData.currency);
      }
    }
  };

  const handleCancel = () => {
    setFormData(product);
    setExpanded(index);
  }

  const handleDeleteTaxRateClick = (index) => {
    setFormData({
      ...formData,
      selectedTaxRates: formData.selectedTaxRates.filter((item, i) => i !== index),
    });
  };

  const handleTaxRateSelect = (tax) => {
    if (!formData.selectedTaxRates) {
      setFormData({
        ...formData,
        selectedTaxRates: [tax],
      });
      setShowTaxRatesSelect(false);
      return;
    }
    if (formData.selectedTaxRates.find((item) => item.id === tax.id)) {
      return;
    }
    setFormData({
      ...formData,
      selectedTaxRates: [...formData.selectedTaxRates, tax],
    });
    setShowTaxRatesSelect(false);
  }


  return (
    <div className={`border border-gray-200 rounded-md shadow-sm bg-slate-50 bg-opacity-40 relative ${expanded ? 'p-4' : 'p-2 cursor-pointer'}`}
      onClick={() => setExpanded && !expanded && setExpanded(index)}
    >
      {onDelete && <CloseIcon className='absolute top-2 right-2 cursor-pointer' onClick={() => expanded && onDelete(index)} />}
      {expanded ?
        <div className="grid grid-cols-2 gap-x-4">
          <div className=' flex flex-col gap-2'>
            <div className=''>
              <Label title="Title" />
              <Input type='text' className='w-full border border-gray-300 rounded-md p-1'
                value={formData.title}
                onChange={(e) => setFormData({
                  ...formData,
                  title: e.target.value,
                })}
              />
            </div>
            <div className=''>
              <Label title="Description" optional />
              <Input type='text' className='w-full border border-gray-300 rounded-md p-1'
                value={formData.description}
                onChange={(e) => setFormData({
                  ...formData,
                  description: e.target.value,
                })}
              />
            </div>
            <div className=''>
              <Label title="Currency" />
              <Select
                className='w-auto'
                options={FIAT_CURRENCIES_SELECT}
                onSelect={(e) => {
                  setFormData({
                    ...formData,
                    currency: e.value,
                  })
                }}
                value={formData.currency}
                disabled={currency}
              />
            </div>
            <div className='flex gap-3'>
              <div className=''>
                <Label title="Preset amount" optional />
                <InputPrice
                  min='0'
                  className='w-full'
                  currency={currency ?? formData.currency}
                  placeholder={'0.00'}
                  disableCurrencies
                  hideCurrency
                  value={formData.preset_amount}
                  onChange={(e) => setFormData({
                    ...formData,
                    preset_amount: e.target.value,
                  })}
                />
              </div>
              <div className=''>
                <Label title="Limit" optional />
                <InputPrice
                  min='0'
                  className='w-full'
                  currency={currency ?? formData.currency}
                  hideCurrency
                  placeholder={'0.00'}
                  disableCurrencies
                  value={formData.limit}
                  onChange={(e) => setFormData({
                    ...formData,
                    limit: e.target.value,
                  })}
                />
              </div>
            </div>
            {quantity_options && (
              <div className=''>
                <div className='flex items-center gap-2 mt-3'>
                  <input type='checkbox' className='w-4 h-4 border cursor-pointer' checked={formData.adjustable_quantity} onChange={(e) => {
                    const value = e.target.checked;
                    if (!value) {
                      setFormData({
                        ...formData,
                        adjustable_quantity: value,
                        min_quantity: null,
                        max_quantity: null,
                      })
                    } else {
                      setFormData({
                        ...formData,
                        adjustable_quantity: value,
                        min_quantity: 1,
                        max_quantity: 99,
                      })
                    }
                  }} />
                  <label className=''>
                    Customer can adjust quantity
                  </label>
                </div>
                {formData.adjustable_quantity && (
                  <div className='flex gap-3 items-center mt-2'>
                    <div className='flex gap-3'>
                      <div className=''>
                        <Label title="Min Quantity" />
                        <Input
                          className='w-full'
                          placeholder={'1'}
                          value={formData.min_quantity}
                          onChange={(e) => setFormData({
                            ...formData,
                            min_quantity: e.target.value,
                          })}
                        />
                      </div>
                      <div className=''>
                        <Label title="Max Quantity" />
                        <Input
                          className='w-full'
                          placeholder={'99'}
                          value={formData.max_quantity}
                          onChange={(e) => setFormData({
                            ...formData,
                            max_quantity: e.target.value,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {tax_options && (
              <div className='mt-3'>
                <label className='text-sm'>
                  Add tax rates <span className='text-gray-500'>(optional)</span>
                </label>
                <div className='flex flex-col gap-1 mt-2'>
                  {formData?.selectedTaxRates?.map((tax, index) => (
                    <div
                      key={index}
                      className="items-center justify-between flex w-full border border-gray-200 rounded-md shadow-sm bg-slate-50 bg-opacity-40 p-2 relative"
                    >
                      <div>
                        <span className="mr-2">{tax.display_name}</span>
                        {`(${tax.percent}%${tax.inclusive ? ' incl.' : ''})`}
                      </div>
                      <CloseIcon
                        className='cursor-pointer'
                        onClick={() => handleDeleteTaxRateClick(index)}
                      />
                    </div>
                  ))}
                </div>
                <div className="mt-2">
                  {showTaxRatesSelect ? (
                    <>
                      <SelectTaxRates
                        onSelect={handleTaxRateSelect}
                        data={formData?.selectedTaxRates?.map((tax) => tax.id)}
                      />
                    </>
                  ) : (
                    <span
                      onClick={setShowTaxRatesSelect(true)}
                      className="text-blue-600 cursor-pointer hover:underline"
                    >
                      Add another tax rate
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='flex flex-col justify-between'>
            {!formData.image ?
              <ProductsModalCreateUpload
                setFiles={setFiles}
                files={files}
                setImages={setImages}
                images={images}
                imagesLength={imagesLength}
                className={"h-40 rounded-md border border-gray-400 border-dashed bg-white"}
              />
              : <div className='mt-8 rounded-md overflow-hidden relative h-48'>
                <CloseIcon className='absolute top-2 right-2 cursor-pointer' onClick={
                  () => {
                    setImages([]);
                    setFiles([]);
                    setFormData({
                      ...formData,
                      image: null,
                    })
                  }} />
                <img src={formData.image.preview} />
              </div>
            }
            <div className='flex gap-3 items-center mt-3 self-end'>
              {product && (
                <Button
                  color='light'
                  className='w-fit mt-2'
                  onClick={handleCancel}
                >Cancel</Button>
              )}

              <Button
                className='w-fit mt-2'
                onClick={handleSave}
                disabled={!formData.title || (!formData.currency && !currency)}
              >Save</Button>
            </div>
          </div>
        </div> :
        <div className='flex gap-3 items-center '>
          <div className='absolute -top-2 -right-2'>
          </div>
          <div className='flex gap-3'>
            <div className='h-14 w-14 flex items-center justify-center border border-gray-300 rounded-md overflow-hidden'>
              {formData?.image?.preview ? <img className='w-full h-full object-cover' src={formData?.image?.preview} alt={product?.title} /> : <ImagePlaceholder className='w-8 h-8' />}
            </div>
            <div className='flex-1'>
              <div className='text-lg font-semibold'>{formData?.title}</div>
              <p className='text-gray-500 text-base'>
                {formData?.description}
              </p>
              {formData?.selectedTaxRates?.length && tax_options > 0 && (
                <div className='flex gap-2 mt-2'>
                  {formData?.selectedTaxRates?.map((tax, index) => (
                    <div
                      key={index}
                      className="items-center justify-between flex text-sm text-white bg-slate-500 bg-opacity-80 rounded-sm px-1 py-0.5 w-fit"
                    >
                      <div>
                        <span className="mr-2">{tax.display_name}</span>
                        {`(${tax.percent}%${tax.inclusive ? ' incl.' : ''})`}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className='flex flex-col gap-1 mt-1'>
                <span className='text-xs text-white bg-slate-500 bg-opacity-80 rounded-sm px-1 py-0.5 w-fit'>
                  {formData.preset_amount ? `Startig From ${currencySymbol(formData?.currency)}${formData.preset_amount}` : 'No preset amount'}
                </span>
                <span className='text-xs text-white bg-slate-500 bg-opacity-80 rounded-sm px-1 py-0.5 w-fit'>
                  {formData.limit ? `Limited at: ${currencySymbol(formData?.currency)}${formData.limit}` : 'No limit'}
                </span>
              </div>
              {formData.adjustable_quantity && quantity_options && (
                <div className='flex gap-3 items-center mt-2 '>
                  <label className='text-sm text-gray-500'>
                    Customer can adjust quantity
                  </label>
                  <div className='flex gap-3'>
                    <div className=''>
                      <span className='text-sm text-white bg-slate-500 bg-opacity-80 rounded-sm px-1 py-0.5 w-fit'>
                        {formData.min_quantity ? `Min: ${formData.min_quantity}` : 'No min'}
                      </span>
                    </div>
                    <div className=''>
                      <span className='text-sm text-white bg-slate-500 bg-opacity-80 rounded-sm px-1 py-0.5 w-fit'>
                        {formData.max_quantity ? `Max: ${formData.max_quantity}` : 'No max'}
                      </span>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      }
    </div>)
}

const PaymentLinksModalCreate = ({
  show = false,
  onClose = null,
  basePrice = null,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('products or subscriptions');
  const [price, setPrice] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [favCurrency, setFavCurrency] = useState('');
  const [showItemsDropdown, setShowItemsDropdown] = useState(true);
  const [optionsExpanded, setOptionsExpanded] = useState(true);
  const [showTaxRatesSelect, setShowTaxRatesSelect] = useState(true);
  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [trialPeriod, setTrialPeriod] = useState({
    enabled: false,
    interval_count: 1,
  });


  const [paymentLinkData, setPaymentLinkData] = useState({});
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [newProducts, setNewProducts] = useState([]);

  const { selected_payment_link } = useSelector(
    (state) => state.paymentLink
  );

  const dropDownItems = useMemo(() => {
    const hasDecimalAmount = selectedProducts.some((item) => {
      return item?.price?.unit_amount_decimal;
    });
    const hasOneTimeItem = selectedProducts.some((item) => {
      return item?.product?.type == 'one_time';
    });
    return [
      {
        label: 'Products or subscriptions',
        value: 'products or subscriptions',
        sub_text: 'Ideal for ecommerce or software-as-a-service',
        disabled: false,
      },
      {
        label: 'Subscriptions',
        value: 'subscriptions',
        sub_text: 'Ideal for software-as-a-service',
        disabled: hasOneTimeItem,
      },
      {
        label: 'Customer chooses what to pay',
        value: 'customer chooses what to pay',
        sub_text: 'Ideal for tipping, donations or pay-what-you-want',
        disabled: hasDecimalAmount,
      },
    ];
  }, [selectedProducts, selectedTaxRates, newProducts]);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      linkType: selected_payment_link?.id
        ? selected_payment_link?.product
          ? 'product'
          : 'payment'
        : 'payment',
      product: selected_payment_link?.product ?? '',
      payment: selected_payment_link?.payment
        ? currencyFormatter(
          selected_payment_link?.currency,
          selected_payment_link?.payment
        )
        : '',
      currency: selected_payment_link?.currency,
      amount_gte: selected_payment_link?.payment
        ? currencyFormatter(
          selected_payment_link?.currency,
          selected_payment_link?.amount_gte
        )
        : '',
      quantity_gte:
        selected_payment_link?.quantity_gte ?? '',
      max_redemptions:
        selected_payment_link?.max_redemptions ?? '',
      end_date: selected_payment_link?.end_date
        ? moment
          .unix(selected_payment_link?.end_date)
          .toDate()
        : null,
      products: selected_payment_link?.products ?? [],
    },
  });

  useEffect(() => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> newProducts", newProducts)
  }, [newProducts]);

  const linkType = watch('linkType');
  const currency = watch('currency');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const payment = watch('payment');
  const amount_gte = watch('amount_gte');
  const quantity_gte = watch('quantity_gte');
  const max_redemptions = watch('max_redemptions');
  const end_date = watch('end_date');
  const products = watch('products');

  const { mutate: mutateUpdatePaymentLink } =
    useUpdatePaymentLink(selected_payment_link?.id);

  useEffect(() => {
    if (!selected_payment_link) return;

    dispatch(
      setFormSchemaPaymentLink({
        linkType: selected_payment_link?.product
          ? 'product'
          : 'payment',
        type: selected_payment_link?.type,
      })
    );
  }, [selected_payment_link, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetFormSchemaPaymentLink());
    };
  }, [dispatch]);

  const handleClose = () => {
    onClose && onClose();
  };

  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  const onSubmit = (formData) => {
    if (selected_payment_link?.id) {
      const _data = { payment: formData.payment };

      console.log(_data)

      mutateUpdatePaymentLink(_data, {
        onSuccess: () => {
          dispatch(resetFormSchemaPaymentLink());
          dispatch(setRefetchPaymentLink(Date.now()));

          toast.success('Payment updated successfully');
          handleClose();
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      });
      return;
    }

    const _data = {
      start_date: 'now',
    };

    if (formData.linkType === 'payment') {
      _data.payment = currencyConverter(
        formData.currency,
        formData.payment
      );
      _data.currency = formData.currency;
    }

    if (formData.linkType === 'product') {
      _data.product = formData.product;
    }

    if (formData.type === 'amount_gte') {
      _data.amount_gte = currencyConverter(
        formData.currency,
        formData.amount_gte
      );
    }

    if (
      ['product', 'product_quantity'].includes(
        formData.type
      )
    ) {
      if (formData.products.length) {
        _data.products = formData.products.map(
          (product) => product.id
        );
      }
    }
  };

  const handleDeleteTaxRateClick = (tax) => {
    setSelectedTaxRates(selectedTaxRates.filter((item) => item.id !== tax.id));
  };

  const handleAddTaxRateClick = () => {
    setShowTaxRatesSelect(true);
  };

  const handleTaxRateSelect = (tax) => {
    if (selectedTaxRates.find((item) => item.id === tax.id)) {
      return;
    }

    setSelectedTaxRates([...selectedTaxRates, tax]);
    setShowTaxRatesSelect(false);
  };

  const conatinsRecurringItem = () => {
    return selectedProducts.some((item) => {
      return item?.price?.recurring;
    });
  }

  const removeProduct = (index) => {
    if (selectedProducts.length === 1) {
      setShowItemsDropdown(true);
    }
    setSelectedProducts(selectedProducts.filter((item, i) => i !== index));
  };


  useEffect(() => {
    const line_items = {
      object: 'list',
      count: selectedProducts.length,
      has_more: false,
      data: selectedProducts.map((item) => {
        let quantity_adjustable = item.adjustable_quantity ? {
          enabled: true,
          maximum: item.max_quantity,
          minimum: item.min_quantity,
        } : { enabled: false };
        return {
          amount_discount: 0,
          amount_tax: 0,
          bill_at_period_end: false,
          price: item.price,
          product: item.product,
          quantity: item.quantity,
          quantity_adjustable: quantity_adjustable,
        }
      })
    }
    console.log("line_items??????", line_items)
    const computedPaymentLinkPreview = computePaymentLinkPreview(
      line_items?.data,
      0,
    );
    setPaymentLinkData({
      line_items: line_items,
      metadata: {},
      on_behalf_of: null,
      phone_number_collection: null,
      shipping_address_collection: { enabled: true, allowed_countries: [] },
      shipping_rates: [],
      submit_type: "pay",
      subscription_data: null,
      transfer_data: null,
      computed_amounts: computedPaymentLinkPreview.computed_amounts,
    })
  }, [selectedProducts, selectedTaxRates]);

  const handleExpantion = (index) => {
    const _newProducts = [...newProducts];
    const isExpanded = _newProducts[index].expanded;
    _newProducts.forEach((item, i) => {
      if (i === index) {
        item.expanded = !isExpanded;
      } else {
        item.expanded = false;
      }
    });
    setNewProducts(_newProducts);
  }

  const handleDelete = (index) => {
    setNewProducts(newProducts.filter((item, i) => i !== index));
  }

  return (
    <>
      <ModalFullScreen
        show={show}
        onClose={handleClose}
        fullscreen>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='flex items-center'>
              <div className='app-modal-fullscreen__header__title'>
                {selected_payment_link
                  ? 'Edit'
                  : 'Create a'}{' '}
                Payment Link
              </div>
            </div>
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          <div
            className='app-container flex'
            style={{
              height: 'calc(100vh - 172px)',
              overflow: 'hidden',
            }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='w-2/5 px-6 overflow-y-auto relative z-50'
            >
              <small className='text-light block mb-5 text-base'>
                Create a new payment link to sell your
                product or receive a payment.
              </small>
              <div className=''>
                <Stack columns='1' gap='2'>
                  <label className='font-semibold text-lg mb-1'>
                    Select Type
                  </label>
                  <Select
                    className='w-auto'
                    options={dropDownItems}
                    onSelect={(e) => {
                      setType(e.value)
                    }}
                    value={type}
                  />
                  <label className='font-semibold text-lg mt-4'>
                    Product
                  </label>
                  {type === 'products or subscriptions' || type === 'subscriptions' ? (
                    <div className=''>
                      {showItemsDropdown && (
                        <div className='mt-1'>
                          <SelectProductsPrices
                            placeholder="Select Item"
                            value={price}
                            product={selectedProduct}
                            currency={favCurrency}
                            noEmptyPrices={true}
                            type={type === 'subscriptions' ? 'recurring' : null}
                            onRefetch={(productsList) => {
                              productsList.forEach((product) => {
                              })
                            }}
                            onSelect={(product, price) => {
                              setPrice(price);
                              setSelectedProducts([...selectedProducts, {
                                product: product,
                                price: price,
                                quantity: 1,
                                adjustable_quantity: false,
                                min_quantity: 1,
                                max_quantity: 99,
                              }]);
                              setFavCurrency(price?.currency);
                              setShowItemsDropdown(false);
                              console.log(product)
                              console.log(price)
                            }}
                            error={fieldError("product")}
                            disabled={basePrice}
                          />
                        </div>
                      )}
                      <div className='mt-6 relative'>
                        <div className='flex flex-col gap-3'>
                          {selectedProducts.map((data, index) => {
                            const product = data?.product;
                            const price = data?.price;
                            return (
                              <div key={index} className='flex flex-col gap-1 border border-gray-200 rounded-md shadow-sm bg-slate-50 bg-opacity-40 p-2 relative'>
                                <div className='absolute -top-2 -right-2'>
                                  <div className='hover:shadow-sm native-drop-down rounded-full w-6 h-6'>
                                    •••
                                    <div className='absolute bg-white p-2 rounded-md w-24 hidden flex-col items-start shadow-md border border-gray-300 -translate-x-full left-1/2 top-3'>
                                      <button type='button' className='hover:underline hover:text-blue-600 cursor-pointer'
                                        onClick={() => {
                                          dispatch(setEditProduct(product));
                                          setShowEditProductModal(true);
                                        }}
                                      >Edit</button>
                                      <button type='button' className='hover:underline text-red-500 cursor-pointer'
                                        onClick={() => removeProduct(index)}
                                      >Delete</button>
                                    </div>
                                  </div>
                                </div>
                                <div className='flex gap-3'>
                                  <div className='h-14 w-14 flex items-center justify-center border border-gray-300 rounded-md'>
                                    {product?.image_url[0] ? <img className='w-full h-full object-cover' src={product?.image_url[0]} alt={product?.name} /> : <ImagePlaceholder className='w-8 h-8' />}
                                  </div>
                                  <div className='w-full'>
                                    <div className=''>{product?.name}</div>
                                    <span className='text-base text-gray-500'>
                                      {dropdownPriceDescription(price)}
                                    </span>
                                    <p className='text-gray-500 text-sm'>
                                      {product?.description}
                                    </p>
                                    <div className="">
                                      <div className='w-full mt-2'>
                                        <label>
                                          Add tax rates
                                        </label>
                                        <div className='w-1/2'>
                                          {selectedTaxRates.map((tax, index) => (
                                            <div
                                              key={index}
                                              className="items-center justify-between flex w-full"
                                            >
                                              <div>
                                                <span className="mr-2">{tax.display_name}</span>
                                                {`(${tax.percent}%${tax.inclusive ? ' incl.' : ''})`}
                                              </div>
                                              <CloseIcon
                                                className='cursor-pointer'
                                                onClick={() => handleDeleteTaxRateClick(tax)}
                                              />
                                            </div>
                                          ))}
                                        </div>
                                        <div className="mt-2">
                                          {showTaxRatesSelect ? (
                                            <>
                                              <SelectTaxRates
                                                onSelect={handleTaxRateSelect}
                                                data={selectedTaxRates.map((tax) => tax.id)}
                                              />
                                            </>
                                          ) : (
                                            <span
                                              onClick={handleAddTaxRateClick}
                                              className="app-invoices__modal__link"
                                            >
                                              Add another tax rate
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                                <div className=''>
                                  <div className='flex gap-3 items-center'>
                                    <input type='number' className='w-14 border border-gray-300 rounded-md p-1' value={data?.quantity} onChange={(e) => {
                                      const value = e.target.value;
                                      setSelectedProducts(selectedProducts.map((item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            quantity: value,
                                          }
                                        }
                                        return item;
                                      }))
                                    }} />
                                    <span className='text-base'>
                                      Quantity
                                    </span>
                                  </div>
                                  <div className='flex gap-3 items-center mt-1'>
                                    <div className='w-14 flex items-center justify-end'>
                                      <input type='checkbox' className='w-4 h-4 border' checked={data?.adjustable_quantity} onChange={(e) => {
                                        const value = e.target.checked;
                                        setSelectedProducts(selectedProducts.map((item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              adjustable_quantity: value,
                                            }
                                          }
                                          return item;
                                        }))
                                      }} />
                                    </div>
                                    <span className='text-base'>
                                      Let customers adjust quantity
                                    </span>
                                  </div>
                                  <div className='ml-16'>
                                    {data?.adjustable_quantity && (
                                      <div className='flex gap-3 items-center'>
                                        <input type='number' className='w-14 border border-gray-300 rounded-md p-1' value={data?.min_quantity} onChange={(e) => {
                                          const value = e.target.value;
                                          setSelectedProducts(selectedProducts.map((item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                min_quantity: value,
                                              }
                                            }
                                            return item;
                                          }))
                                        }} />
                                        <span className='text-base'>
                                          Min
                                        </span>
                                        <input type='number' className='w-14 border border-gray-300 rounded-md p-1' value={data?.max_quantity} onChange={(e) => {
                                          const value = e.target.value;
                                          setSelectedProducts(selectedProducts.map((item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                max_quantity: value,
                                              }
                                            }
                                            return item;
                                          }
                                          ))
                                        }
                                        } />
                                        <span className='text-base'>
                                          Max
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>)
                          })}
                        </div>
                        {selectedProducts.length > 0 && (
                          <button type='button' className='mt-3 text-sm text-blue-500 hover:underline' onClick={() => setShowItemsDropdown(true)}>Add more items</button>
                        )}
                      </div>
                    </div>
                  ) : type === 'customer chooses what to pay' ?
                    <div className=''>
                      {!newProducts.length ? <CutomerChooseswhatToPayForm
                        expanded={true}
                        setExpanded={null}
                        newProducts={newProducts}
                        setProducts={setNewProducts}
                        product={null}
                        currency={null}
                        setCurrency={setFavCurrency}
                        onDelete={null}
                        quantity_options={false}
                        tax_options={false}
                      /> :
                        <div className='overflow-auto'>
                          <div className='flex flex-col gap-3'>
                            {newProducts.map((item, index) => {
                              return (
                                <CutomerChooseswhatToPayForm
                                  key={index}
                                  index={index}
                                  currency={favCurrency}
                                  setExpanded={handleExpantion}
                                  product={item.product}
                                  newProducts={newProducts}
                                  setProducts={setNewProducts}
                                  expanded={item.expanded}
                                  onDelete={handleDelete}
                                  quantity_options={newProducts.length !== 1}
                                  tax_options={newProducts.length !== 1}
                                />
                              )
                            })}
                          </div>
                          <button
                            className='cursor-pointer text-blue-500 hover:underline text-sm mt-3'
                            onClick={() => {
                              setNewProducts([...newProducts, {
                                product: null,
                                expanded: true,
                              }])
                            }}
                          >
                            Add another item
                          </button>
                        </div>}
                    </div> : null}
                </Stack>
                <div className='font-semibold text-lg w-full flex justify-between items-center mt-4'>
                  <label className='cursor-pointer flex items-center gap-2'
                    onClick={() => setOptionsExpanded(!optionsExpanded)}
                  >
                    Options <ArrowUpIcon className={`transform ${!optionsExpanded ? 'rotate-180' : ''}`} />
                  </label>
                </div>
                {optionsExpanded && (
                  <div className=''>
                    {type === 'customer chooses what to pay' && newProducts.length === 1 && (<>
                      <div className=''>
                        <div className='flex items-center gap-2 mt-3'>
                          <input type='checkbox' className='w-4 h-4 border cursor-pointer' checked={newProducts[0].adjustable_quantity} onChange={(e) => {
                            const value = e.target.checked;
                            console.log(value)
                            if (!value) {
                              const _newProduct = { ...newProducts[0] };
                              console.table(_newProduct)
                              const _tmp = [{
                                expanded: _newProduct.expanded,
                                product: {
                                  ..._newProduct.product,
                                  adjustable_quantity: value,
                                  min_quantity: null,
                                  max_quantity: null,
                                },
                              }]
                              setNewProducts(_tmp)
                            } else {
                              const _newProduct = { ...newProducts[0] };
                              const _tmp = [{
                                expanded: _newProduct.expanded,
                                product: {
                                  ..._newProduct.product,
                                  adjustable_quantity: value,
                                  min_quantity: 1,
                                  max_quantity: 99,
                                },
                              }]
                              console.table(_tmp)
                              setNewProducts(_tmp)
                            }
                          }} />
                          <label className=''>
                            Customer can adjust quantity
                          </label>
                        </div>
                        {newProducts[0].product.adjustable_quantity
                          && (
                            <div className='flex gap-3 items-center mt-2'>
                              <div className='flex gap-3'>
                                <div className=''>
                                  <Label title="Min Quantity" />
                                  <Input
                                    className='w-full'
                                    placeholder={'1'}
                                    value={newProducts[0].product.min_quantity}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const _newProduct = { ...newProducts[0] };
                                      const _tmp = [{
                                        expanded: _newProduct.expanded,
                                        product: {
                                          ..._newProduct.product,
                                          min_quantity: value,
                                        },
                                      }]
                                      console.table(_tmp)
                                      setNewProducts(_tmp)
                                    }}
                                  />
                                </div>
                                <div className=''>
                                  <Label title="Max Quantity" />
                                  <Input
                                    className='w-full'
                                    placeholder={'99'}
                                    value={newProducts[0].product.max_quantity}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const _newProduct = { ...newProducts[0] };
                                      const _tmp = [{
                                        expanded: _newProduct.expanded,
                                        product: {
                                          ..._newProduct.product,
                                          max_quantity: value,
                                        },
                                      }]
                                      console.table(_tmp)
                                      setNewProducts(_tmp)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="">
                          <div className='w-full mt-2'>
                            <label className=''>
                              Add tax rates
                            </label>
                            <div className='w-1/2 mt-4 flex flex-col gap-1'>
                              {newProducts[0]?.product?.selectedTaxRates?.map((tax, index) => (
                                <div
                                  key={index}
                                  className="items-center justify-between flex w-full border border-gray-200 rounded-md shadow-sm bg-slate-50 bg-opacity-40 p-2 relative"
                                >
                                  <div>
                                    <span className="mr-2">{tax.display_name}</span>
                                    {`(${tax.percent}%${tax.inclusive ? ' incl.' : ''})`}
                                  </div>
                                  <CloseIcon
                                    className='cursor-pointer'
                                    onClick={() => {
                                      const _newProduct = { ...newProducts[0] };
                                      const _selectedTaxRates = [..._newProduct?.product?.selectedTaxRates];
                                      const _tmp = [{
                                        expanded: _newProduct.expanded,
                                        product: {
                                          ..._newProduct.product,
                                          selectedTaxRates: _selectedTaxRates.splice(_selectedTaxRates.findIndex((item) => item.id === tax.id), 1),
                                        },
                                      }]
                                      setNewProducts(_tmp)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="mt-1 w-1/2">
                              {showTaxRatesSelect ? (
                                <>
                                  <SelectTaxRates
                                    onSelect={(tax) => {
                                      const _newProduct = { ...newProducts[0] };
                                      if (!_newProduct?.product?.selectedTaxRates) {
                                        setNewProducts([{
                                          expanded: _newProduct.expanded,
                                          product: {
                                            ..._newProduct.product,
                                            selectedTaxRates: [tax],
                                          },
                                        }])
                                        setShowTaxRatesSelect(false);
                                      }
                                      const _selectedTaxRates = [..._newProduct?.product?.selectedTaxRates];
                                      console.table(_selectedTaxRates)
                                      if (_selectedTaxRates?.find((item) => item.id === tax.id)) {
                                        return;
                                      }
                                      _selectedTaxRates?.push(tax);
                                      const _tmp = [{
                                        expanded: _newProduct.expanded,
                                        product: {
                                          ..._newProduct.product,
                                          selectedTaxRates: _selectedTaxRates,
                                        },
                                      }]
                                      console.table(_tmp)
                                      setNewProducts(_tmp)
                                      setShowTaxRatesSelect(false);
                                    }}
                                    data={newProducts[0]?.product?.selectedTaxRates?.map((tax) => tax.id)}
                                  />
                                </>
                              ) : (
                                <span
                                  onClick={handleAddTaxRateClick}
                                  className="text-blue-500 hover:underline text-sm mt-3 cursor-pointer"
                                >
                                  Add another tax rate
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)}
                    {conatinsRecurringItem() && (
                      <div className=''>
                        <div className=''>
                          <div className='flex items-center gap-2 mt-3'>
                            <input type='checkbox' className='w-4 h-4 border cursor-pointer' checked={trialPeriod.enabled} onChange={(e) => {
                              const value = e.target.checked;
                              setTrialPeriod({
                                ...trialPeriod,
                                enabled: value,
                              })
                            }} />
                            <label className=''>
                              Include a trial period
                            </label>
                          </div>
                          {trialPeriod.enabled && (
                            <div className='flex gap-3 items-center mt-2'>
                              <Input type='number' className='w-14 border border-gray-300 rounded-md p-1' value={trialPeriod.interval_count} onChange={(e) => {
                                const value = e.target.value;
                                setTrialPeriod({
                                  ...trialPeriod,
                                  interval_count: value,
                                })
                              }} />
                              <span className='text-base'>
                                Days
                              </span>
                            </div>
                          )}
                        </div>
                      </div>)}
                    <div className='mt-4'>
                      <CustomInputsInput />
                      <CustomOptions />
                    </div>
                  </div>
                )}
              </div>
            </form>
            <div className=''>
              <PaymentLinkPreview selected_payment_link={paymentLinkData} />
            </div>
          </div>
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {
        showEditProductModal && (
          <ProductsModalCreate
            show={showEditProductModal}
            onClose={() => setShowEditProductModal(false)}
          />
        )
      }
    </>
  );
};


export default PaymentLinksModalCreate;