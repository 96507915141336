import { useFetchBalance } from 'api/wallet/balance';
import { useFetchCurrencies } from 'api/wallet/currencies';
import Button from 'components/Common/Form/Button/Button';
import ButtonGradient from 'components/Common/Form/Button/ButtonGradient/ButtonGradient';
import { InputPriceCrypto } from 'components/Common/Form/InputPrice/InputPrice';
import Label from 'components/Common/Form/Label/Label';
import Select from 'components/Common/Form/Select/Select';
import Modal from 'components/Common/Modal/Modal'
import InfoIcon from "assets/images/icons/info.svg";
import React , {useState , useEffect , useMemo} from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { currencySymbol } from 'utils/string';
import NetworkSelect from './NetworkSelect.js';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PayoutsCreateModal({
    show = false,
    onClose = null,
    selectedCurrency = null,
}) {
  const types = [
    {
      label : "Crypto",
      value : "crypto",
    },
    {
      label : "Fiat",
      value : "fiat"
    }
  ];
  const methods = {
    fiat : [
      {
        label : "Bank Account",
        value : "bank",
      },  
      {
        label : "Paypal etc",
        value : "paypal",
      },
      {
        label : "Internal",
        value : "internal",
      }, 
    ],
    crypto : [
      {
        label : "Blockchain",
        value : "Blockchain",
      }, 
      {
        label : "Binance",
        value : "Binance",
      }, 
      {
        label : "Crypto.com",
        value : "Crypto_com",
      },
      {
        label : "Internal",
        value : "internal",
      }, 
    ]
  }
  const [currencies, setCurrencies] = useState([]);
  const [type, setCurrencyType] = useState({label :"Crypto", value :"crypto"});
  const [currency, setcurrency] = useState(selectedCurrency);
  const [amount, setAmount] = useState();
  const [data, setdata] = useState();
  const [method, setMethod] = useState(methods?.crypto[0]);
  const [networks, setNetworks] = useState([]);
  const [network, setNetwork] = useState("");
  // const [currencies, setCurrencies] = useState([]);
  const { current_account } = useSelector((state) => state.auth);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  useEffect(() => {

    return () => {
        navigate(pathname.replace("/create", "").replace("/edit", ""));

    };

  }, []);

  const handleClose = () => {
    onClose && onClose();
  };
  const onFetchCurrenciesSuccess = (data) => {
    setdata(data)
    const currencies = data.map((data) => {
      return {
        label: data.currency.toUpperCase(),
        value: data.currency.toUpperCase(),
        icon: type.value === "crypto" ? data.currency.toLowerCase() :currencySymbol(data.currency),

      };
    });
    if (currency === null) {
    setcurrency(data[0].currency)
    setNetworks(data[0].networks)
    }
    else
    {
      setNetworks(data.find((item) => item.currency === currency).networks);
    }
    // setMethod(type.value === "Crypto" ?methods.crypto[0]: methods.fiat[0])

    // const firstItem = {
    //   label: data.converted_total_currency.toUpperCase(),
    //   value: data.converted_total_currency.toUpperCase(),
    //   amount: data.converted_total_amount,
    //   icon: currencySymbol(data.converted_total_currency),
    //   total: true,
    // };
    // currencies.unshift(firstItem);

    // setFiatItem(firstItem);
    setCurrencies(currencies);
    // setRefetch(false);
  };
  const handleCurrencyChange = (value) => {
    setcurrency(value.value);
    if (type.value === "crypto") {
      setNetworks(data.find((item) => item.currency === value.value).networks);
    }
    // setConvertedAmount(
    //   value.fiat
    //     ? {
    //         fiat: value.fiat,
    //         fiat_amount: currencyFormatter(value.fiat, value.fiat_amount),
    //       }
    //     : null
    // );
    // setFiatItem(value);
  };
  const handleSelectCurrencyType = (value) => {
    console.log(value.value)
    setCurrencyType(value)
    setMethod(value.value === "crypto" ? methods?.crypto[0] : methods?.fiat[0])

    // setFiatItem(value);
  };
  const handleSelectNetwork = (value) => {
    console.log(value)
    setNetwork(value)
  };
  const handleSelectMethod = (value) => {
    console.log(method)
    setMethod(value)
    // setFiatItem(value);
  };
  const getCurrency = useMemo(() => {
    return currencies.length ? currencies[0].value : null;
  }, [currencies]);

  const { isLoading } = useFetchCurrencies({
    onSuccess: onFetchCurrenciesSuccess,
    onError: ()=>{},
    params:{
      type: type.value,
      networks : true
    }
    // enabled: refetch,
  } , [type]);

  useEffect(() => {
console.log(current_account)

  }, []);
  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/brands/${icon.toLowerCase()}.png`);
    } catch (err) {
      return null;
    }
  };
  return (
    <Modal style={{overflow : "visible"}} show={show} onClose={handleClose} size='md'>
      <Modal.Slot name='header'>
        <div>
          <div className='app-invoice__modal__title'>
            Payout {type.value === 'crypto' ? 'Crypto' : 'Fiat'}
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name='body'>
        <FormStyle className='gap-4'>
          <div>
              <Label 
                title='Type' 
                error={""}
                icon={InfoIcon}
                tooltipHtml={true}
                tooltip={`
                  Choose if you want to payout from your Cryptocurrency or Fiat balance
                `}
              />
              <Select
                  onSelect={handleSelectCurrencyType}
                  path="assets/images/icons/cryptos"
                  options={types}
                  value={type?.value}
              />
          </div>
          <div>
              <Label 
                title='Method' 
                error={""}
                icon={InfoIcon}
                tooltipHtml={true}
                tooltip={`
                  The method to use for your payout
                `} 
              />
              <Select
                  onSelect={handleSelectMethod}
                  path="assets/images/icons/cryptos"
                  style={{ minWidth: "150px" }}
                  options={type?.value === "crypto" ? methods.crypto : methods.fiat}
                  value={method?.value}
              />
          </div>
          <div>
              <Label title='Amount' error={""} />
                  <InputPriceCrypto
                    block
                    min='0'
                    className="marg"
                    currency={currency}
                    currencies={currencies}
                    onChange={(e)=>setAmount(e.target.value)}

                    onCurrencyChange={handleCurrencyChange}
                    placeholder={ '0.00'}
                    // error={errors.amount}
                    value={amount}
              />
            
          </div>
          {
            isLoading ? <div>Loading...</div> :
            type?.value === "crypto" &&
            <div>
                <Label title='Blockchain Network' error={""} />
                <NetworkSelect
                  onSelect={handleSelectNetwork}
                  path="assets/images/icons/cryptos"
                  options={networks}
                  value={network}
                  setNetwork={setNetwork}
                  placeholder="Select network"
                  />
            </div>
          }
          <div className='flex '>
            <div className='flex flex-col mr-5'>
              <div className='app-modal-fullscreen__label'>AVAILABLE:</div>
              <div className='app-modal-fullscreen__value'>0.00</div>
            </div>
            <div className='flex flex-col mr-5'>
              <div className='app-modal-fullscreen__label'>MIN. PAYOUT:</div>
              <div className='app-modal-fullscreen__value'>0.00</div>
            </div>
            <div className='flex flex-col mr-5'>
              <div className='app-modal-fullscreen__label'>MAX. PAYOUT:</div>
              <div className='app-modal-fullscreen__value'>0.00</div>
            </div>
          </div>
        </FormStyle>
      </Modal.Slot>
      <Modal.Slot name='test'>
        <div 
          className='flex align-center center'
          style={{
            background: "#E3F7FF",
            padding: "10px 25px 10px 25px",
            marginTop: "10px",
            height :"112px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className='flex flex-col mr-5'>
            <div className='app-modal-fullscreen__label'>{type?.value === "crypto" && method.value === 'crypto' ? 'NETWORK FEE' : 'FEE'}</div>
            <div className='app-modal-fullscreen__value'>0.00</div>
          </div>
          <div className='flex flex-col mr-5'>
            <div className='app-modal-fullscreen__label'>TOTAL WITHDRAW USD:</div>
            <div className='app-modal-fullscreen__value'>0.00</div>
          </div>
          <div className='flex flex-col mr-5'>
            <div className='app-modal-fullscreen__label'>TOTAL WITHDRAW BTC:</div>
            <div className='app-modal-fullscreen__value'>0.00</div>
          </div>
        </div>
        {/* todo */}
        <WarningStyle>
          Please, enable 2FA Authentification to withdraw
        </WarningStyle>
        <div  className='flex flex-col'>
          <Button  
            style={{margin : "0px auto" }} 
            onClick={(e)=>{
            
            }} 
            type='  '
          >
            Confirm Withdrawal
          </Button>
            <span style={{margin : "5px auto" }} className="text-gray-500 text-xs
            ">
            * Your payout will be sent immediately upon request. But it may take up to 10 minutes to process.
            </span>
        </div>
      </Modal.Slot>
      {/* <Modal.Slot name='footer'>
        <div className='flex justify-end'>
        <Button  onClick={(e)=>{
         
          }  } type='  '>
            Confirm

          </Button>
        </div>
      </Modal.Slot> */}
    </Modal>
  )
}

const FormStyle = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 10px; */
    >div{
        width: 100%;

            >.networks{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .active{
                border: 2px solid #47CE7D;
              }
              >label{
                padding:  0 10px;
                flex-basis : 49%;
                margin-bottom:10px ;
                height: 99px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #DCDEE6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                input
                {
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: none;
                  border: 0;
                  box-shadow: inset 0 0 0 3px #DCDEE6;
                  box-shadow: inset 0 0 0 3.5px #DCDEE6;
                  appearance: none;
                  padding: 0;
                  margin: 0;
                  transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
                  pointer-events: none;
                  margin: 2px;
                  &:focus
                  {

                    outline: none;
                  }
                  
                  &:checked
                  {

                    box-shadow: inset 0 0 0 6px #47CE7D;
                  }

                }
                >.first{
                  height: 100%;
                  justify-content: space-around;
                  display: flex;
                  align-items: flex-start;
                  flex-direction :column;
                  >img{

                    max-width: 100px;
                  }
                }

              }
            }
        
    }
    `;

    
const WarningStyle = styled.div`

    margin: 24px auto;
    min-width: 367px;
    max-width: 367px;
    padding: 12px 25px;
    background: #FFE3E8;
    border: 1px solid #EE677E;
border-radius: 8px;
text-align: center;
font: normal normal 600 14px/25px Blinker;
letter-spacing: 0.28px;
color: #FF5B7C;
opacity: 1;
`;