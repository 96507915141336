import clsx from "clsx";
import ArrowDownIcon from "../../../../../../assets/images/icons/dashboard/arrow_down.svg";
import React, { useRef, useState } from "react";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";
import Checkbox from "../../../../../Common/Form/Checkbox/Checkbox";

function InvoiceCryptoSelect({
  options = [],
  value = [],
  style,
  className = "",
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  useOutsideClick(selectRef, () => setOpen(false));

  const handleOptionClick = (option) => {
    rest.onSelect && rest.onSelect(option);
  };

  const getIconUrl = (icon) => {
    try {
      return require(`assets/images/icons/cryptos/${icon.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  };

  const handleClearClick = () => {
    rest.onClear && rest.onClear();
  };

  return (
    <div
      ref={selectRef}
      className={clsx(className, "app-select")}
      style={style}
    >
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "app-select__input app-select__input--h-auto",
          open && "app-select__input--active"
        )}
      >
        <div className="app-select__input__value">
          <div className="flex items-center gap-3 leading-none">
            {value.join(", ")}
          </div>
        </div>
        <img className="app-select__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open && (
        <div className="app-select__options">
          <div className="app-select__options__item app-select__options__item--hoverless mx-1">
            <div className="app-invoices__modal__select__crypto flex-1">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="app-invoices__modal__select__crypto__item"
                >
                  <Checkbox
                    color="primary"
                    size="sm"
                    onChange={() => handleOptionClick(option)}
                    checked={value.includes(option.label)}
                  />
                  <img
                    className="app-invoices__modal__select__crypto__item__icon"
                    src={getIconUrl(option.label)}
                    alt={option.label}
                  />
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div
            onClick={handleClearClick}
            className="app-invoices__modal__select__crypto__clear mx-1"
          >
            Clear all
          </div>
        </div>
      )}
    </div>
  );
}

export default InvoiceCryptoSelect;
