import { useEffect, useState } from "react";
import { DASHBOARD_TABLE_LIMIT } from "../utils/constants";

const useTablePagination = (
  data = [],
  rows = [],
  filters = null,
  pageSize = null
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [firstId, setFirstId] = useState(null);
  const [bindParams, setBindParams] = useState(null);

  useEffect(() => {
    if (!rows.length) return;
    if (rows[0].id === firstId) return;

    setFirstId(rows[0].id);
  }, [rows, firstId]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFirstId(null);
      setBindParams({});
    }
  }, [filters]);

  const onPreviousPage = () => {
    if (!rows.length) return;

    if (currentPage - 1 === 1) {
      setCurrentPage(currentPage - 1);
      setFirstId(null);
      setBindParams({});
      return;
    }

    setCurrentPage(currentPage - 1);
    setBindParams({
      ending_before: rows[0].id,
    });
  };

  const onNextPage = () => {
    if (!rows.length) return;

    setCurrentPage(currentPage + 1);
    setBindParams({
      starting_after: rows[rows.length - 1].id,
    });
  };

  return {
    currentPage,
    canNextPage: data.has_more,
    canPreviousPage: currentPage > 1, //firstId && bindParams && "starting_after" in bindParams,
    dataCount: data.count,
    pageCount: Math.ceil(rows.length / (pageSize ?? DASHBOARD_TABLE_LIMIT)),
    requestQuery: bindParams,
    limitQuery: pageSize ?? DASHBOARD_TABLE_LIMIT,
    setBindParams,
    onPreviousPage,
    onNextPage,
  };
};

export default useTablePagination;
