import Select from "../../../../Common/Form/Select/Select";
import Stack from "../../../../Common/Stack/Stack";
import DashboardButton from "../../../../Common/Form/DashboardButton/DashboardButton";
import { useFetchBalance } from "../../../../../api/wallet/balance";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencySymbol,
  isValidCurrency,
} from "../../../../../utils/string";

function SectionBalance() {
  const { current_account } = useSelector((state) => state.auth);
  const [refetch, setRefetch] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [fiat, setFiat] = useState(null);

  useEffect(() => {
    setRefetch(true);
  }, [current_account]);

  const onFetchBalanceSuccess = (data) => {
    const currencies = data.balance.map((currency) => {
      return {
        label: currency.crypto_currency.toUpperCase(),
        value: currency.crypto_currency.toUpperCase(),
        amount: currency.crypto_amount,
        icon: currency.crypto_currency.toLowerCase(),
        fiat: currency.converted_currency?.toUpperCase(),
        fiat_amount: currency.converted_amount,
      };
    });
    const firstItem = {
      label: data.converted_total_currency.toUpperCase(),
      value: data.converted_total_currency.toUpperCase(),
      amount: data.converted_total_amount,
      icon: currencySymbol(data.converted_total_currency),
      total: true,
    };
    currencies.unshift(firstItem);

    setFiatItem(firstItem);
    setCurrencies(currencies);
    setRefetch(false);
  };

  const onFetchBalanceError = () => {
    setRefetch(false);
  };

  const { isLoading } = useFetchBalance({
    onSuccess: onFetchBalanceSuccess,
    onError: onFetchBalanceError,
    enabled: refetch,
  });

  const handleSelect = (value) => {
    setConvertedAmount(
      value.fiat
        ? {
            fiat: value.fiat,
            fiat_amount: currencyFormatter(value.fiat, value.fiat_amount),
          }
        : null
    );

    setFiatItem(value);
  };

  const setFiatItem = (fiat) => {
    setFiat({
      fiat: fiat.value,
      fiat_amount: fiat.total
        ? currencyFormatter(fiat.value, fiat.amount)
        : cryptocurrencyFormatter(fiat.amount),
    });
  };

  const getCurrency = useMemo(() => {
    return currencies.length ? currencies[0].value : null;
  }, [currencies]);

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../../assets/images/icons/cryptos/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  return (
    <>
      {!isLoading && (
        <div className="app-dashboard__card col-span-4 xl:col-span-1 lg:col-span-2">
          <div className="app-dashboard__card__header">
            <div className="app-dashboard__card__title">My Balance</div>
            <Select
              onSelect={handleSelect}
              path="assets/images/icons/cryptos"
              style={{ minWidth: "150px" }}
              options={currencies}
              value={getCurrency}
            />
          </div>
          <div className="app-dashboard__card__balance">
            {fiat && (
              <div className="app-dashboard__card__balance__amount">
                {isValidCurrency(fiat.fiat) ? (
                  <div className="mr-2">{currencySymbol(fiat.fiat)}</div>
                ) : (
                  <img
                    className="mr-2 align-middle"
                    width="24"
                    src={getIconUrl(fiat.fiat?.toLowerCase())}
                    alt=""
                  />
                )}
                {fiat.fiat_amount}
                <span>{fiat.fiat}</span>
              </div>
            )}
            {convertedAmount && (
              <div className="app-dashboard__card__balance__converted">
                <div>
                  <span>{currencySymbol(convertedAmount.fiat)} </span>
                  {convertedAmount.fiat_amount}
                  <span>{convertedAmount.fiat}</span>
                </div>
              </div>
            )}
          </div>
          <Stack columns="2" gap="2.5">
            <DashboardButton color="danger">Payout</DashboardButton>
            <DashboardButton color="primary">Topup</DashboardButton>
          </Stack>
        </div>
      )}
    </>
  );
}

export default SectionBalance;
