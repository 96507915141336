import "./Dropdown.scss";
import { useState, useRef, Children } from "react";
import { usePopper } from "react-popper";
import useOutsideClick from "../../../hooks/useOutsideClick";
import clsx from "clsx";

const Slot = () => null;

function Dropdown({ children, items }) {
  const childrenArray = Children.toArray(children);
  const toggleSlot = childrenArray.find(
    (child) => child?.props?.name === "toggle"
  );

  const dropdownRef = useRef(null);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const [visible, setVisibility] = useState(false);
  useOutsideClick(dropdownRef, handleDocumentClick);

  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 15,
          },
        },
      ],
    }
  );

  function handleDocumentClick() {
    setVisibility(false);
    update && update();
  }

  function handleDropdownClick() {
    setVisibility(!visible);
    update && update();
  }

  const handleItemClick = (item) => {
    item.onClick && item.onClick();
    handleDocumentClick();
  };

  return (
    <div ref={dropdownRef}>
      <div
        ref={referenceRef}
        onClick={handleDropdownClick}
        className="py-2 cursor-pointer"
      >
        {toggleSlot?.props?.children}
      </div>
      <div
        ref={popperRef}
        style={{ ...styles.popper, zIndex: 10 }}
        {...attributes.popper}
      >
        <div
          style={styles.offset}
          className={clsx("app-dropdown", visible && "app-dropdown--open")}
        >
          <div className="app-dropdown__items">
            {items.map((item, index) => (
              <div
                key={index}
                className="app-dropdown__item"
                onClick={() => handleItemClick(item)}
              >
                <div className={item?.className}>{item.label}</div>
                {item.icon && (
                  <div className="app-dropdown__item__icon">{item.icon}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

Dropdown.Slot = Slot;

export default Dropdown;
