import {
  currencyFormatter,
  currencySymbol,
} from "../../../../../../../utils/string";
import { ReactComponent as DateIcon } from "../../../../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../../../../utils/date";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTablePagination from "../../../../../../../hooks/useTablePagination";
import { useFetchInvoiceItems } from "../../../../../../../api/customer/invoice";
import DashboardModalTable from "../../../../../../Dashboard/DashboardModalTable/DashboardModalTable";
import { setInvoiceNowCurrencies } from "../../../../../../../store/invoice/invoiceSlice";
import { setSelectedPendingInvoiceItem } from "../../../../../../../store/customer/customerSlice";
import CustomerDetailsPendingItemsTableActions from "./CustomerDetailsPendingItemsTableActions";

function CustomerDetailsPendingItemsTable({
  setShowCreateInvoiceItemModal,
  setInvoiceNowModal,
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const { selected_customer } = useSelector((state) => state.customer);
  const { refetch_pending_items } = useSelector((state) => state.invoice);
  const {
    canNextPage,
    canPreviousPage,
    dataCount,
    pageCount,
    requestQuery,
    limitQuery,
    onPreviousPage,
    onNextPage,
  } = useTablePagination(data, tableRows);

  const onFetchPendingInvoiceItemsSuccess = (data) => {
    setData(data);
    setTableRows(data.data);
    dispatch(setInvoiceNowCurrencies(data.data.map((item) => item.currency)));
    setRefetch(false);
  };

  const { isFetching: isFetchingData } = useFetchInvoiceItems({
    onSuccess: onFetchPendingInvoiceItemsSuccess,
    onError: () => null,
    enabled: refetch,
    params: {
      customer: selected_customer?.id,
      invoice: "null",
    },
    limit: limitQuery,
    ...requestQuery,
  });

  useEffect(() => {
    setRefetch(true);
  }, [refetch_pending_items]);

  const tableColumns = useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "amount",
        maxWidth: 80,
        Cell: (cell) => {
          const row = cell.row.original;
          return (
            <div 
              className="app-customers__modal__earning"
              style={{ fontSize: "1.2rem" }}
            >
              {currencySymbol(row.currency)}
              {currencyFormatter(row.currency, row.amount)}{" "}
              <span>{row.currency?.toUpperCase()}</span>
            </div>
          );
        },
      },
      {
        Header: "Invoice Item ID",
        accessor: "id",
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value break-all">
            {cell.value}
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        width: 150,
        Cell: (cell) => (
          <div className="app-modal-fullscreen__value">{cell.value ?? "-"}</div>
        ),
      },
      {
        Header: "Created",
        accessor: "created_at",
        width: 70,
        Cell: (cell) => (
          <div className="flex items-center">
            <DateIcon className="mr-1.5" />
            <span className="app-modal-fullscreen__value">
              {dateFormatter(cell.value)}
            </span>
          </div>
        ),
      },
      {
        Header: "",
        id: "icon",
        width: 10,
        Cell: (cell) => (
          <CustomerDetailsPendingItemsTableActions
            row={cell.row.original}
            setShowCreateInvoiceItemModal={setShowCreateInvoiceItemModal}
          />
        ),
      },
    ],
    [setShowCreateInvoiceItemModal]
  );

  const handleCreateInvoiceItemClick = () => {
    dispatch(setSelectedPendingInvoiceItem(null));
    setShowCreateInvoiceItemModal(true);
  };

  const handleInvoiceNowClick = () => {
    setInvoiceNowModal(true);
  };

  return (
    <div className="space-y-1.5">
      <div className="flex justify-between">
        <div className="app-modal-fullscreen__title">Pending Invoice Items</div>
        <div className="flex gap-4">
          <div
            onClick={handleCreateInvoiceItemClick}
            className="app-modal-fullscreen__sm-link text-primary"
          >
            Create
          </div>
          <div
            onClick={handleInvoiceNowClick}
            className="app-modal-fullscreen__sm-link text-tertiary"
          >
            Invoice Now
          </div>
        </div>
      </div>
      <DashboardModalTable
        columns={tableColumns}
        data={tableRows}
        pageCount={pageCount}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        loading={isFetchingData}
        count={dataCount}
        noRecordTag="Invoice Items"
      />
    </div>
  );
}

export default CustomerDetailsPendingItemsTable;
