import "./SelectAccounts.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "hooks/useOutsideClick";
import clsx from "clsx";
import ArrowDownIcon from "assets/images/icons/dashboard/arrow_down.svg";
import { useFetchProducts } from "api/product/product";
import PlusIcon from "assets/images/icons/dashboard/plus.svg";
import { ReactComponent as Loader } from "assets/images/loader.svg";
import truncate from "lodash/truncate";
import { dropdownPriceDescription } from "utils/price_utils";
import { useAsyncDebounce } from "react-table";
import { useDispatch } from "react-redux";
import { setSearchProductInput } from "store/invoice/invoiceSlice";
import { useFetchAccounts } from "api/merchant/merchant";
import { useFetchWallets } from "api/wallet/wallet";

function SelectAccounts({
  value = null,
  placeholder = "",
  currency = "",
  type = null, // 'one_time' or 'recurring'
  onClose = null,
  ...rest
}) {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, handleClose);

  function handleClose() {
    setOpen(false);
    onClose && onClose();
  }

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  // Filter only prices that meet the provided currency and type;
  const filterOptions = (data) => {
    // filter options using input value
    return data.filter((option) => {
      return option.name.toLowerCase().includes(inputValue.toLowerCase());
    }
    );
  };

  const { Loading, refetch } = useFetchAccounts({
    onSuccess: (data) => {
      setOptions(filterOptions(data));
    },
    onError: () => null,
    enabled: false,
    params: {
    },
  });

  useEffect(() => {
    refetch();
  }, [currency, refetch]);


  const handleOptionClick = (option) => {
    setSelected(option);
    setInputValue(option.name);
    setOpen(false);
    dispatch(setSearchProductInput(""));
    rest.onSelect && rest.onSelect(option);
  };

  const handleAddItemClick = () => {
    dispatch(setSearchProductInput(trimInputValue));
    rest.oneTime && rest.oneTime();
  };

  const handleNewProductClick = () => {
    dispatch(setSearchProductInput(trimInputValue));
    rest.createProduct && rest.createProduct();
  };

  const handleInputChange = useAsyncDebounce(() => {
    var optionfiltred = options.filter((option) => {
      return option.name.toLowerCase().includes(inputValue.toLowerCase());
    });
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const trimInputValue = useMemo(() => {
    return inputValue.trim();
  }, [inputValue]);

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <input
          type="text"
          className="select-customer__input__value"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && (
        <div className="select-customer__options">
          {Loading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {(options.length === 0 && !Loading )? (
            <div className="select-customer__no-results">
              <span>No active account found</span>
            </div>
          ):
            <>
            {options.map((product, index) => {
              return <div
                key={index}
                onClick={() => handleOptionClick(product)}
                className={clsx(
                  "select-product__options__item flex justify-between",
                  selected &&
                    product.id === selected.id &&
                    "select-product__options__item--active"
                )}
              >
                <div>
                    {/* add condition for the current account */}
                  {truncate(product.name, { length: 25, separator: "..." })}
                </div>
                <span>
                    {product.id}
                </span>

              </div>
})}
            </>

}
        </div>
      )
      
      }
    </div>
  );
}

export default SelectAccounts;


export function SelectWallets({
  value = null,
  placeholder = "",
  currency = "",
  type = null, // 'one_time' or 'recurring'
  onClose = null,
  ...rest
}) {
  const dispatch = useDispatch();
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputValue, setInputValue] = useState("");
  useOutsideClick(selectRef, handleClose);

  function handleClose() {
    setOpen(false);
    onClose && onClose();
  }

  const bindParams = useMemo(() => {
    return inputValue ? { query: inputValue } : null;
  }, [inputValue]);

  // Filter only prices that meet the provided currency and type;
  const filterOptions = (data) => {
    // filter options using input value
    return data.filter((option) => {
      return option.name.toLowerCase().includes(inputValue.toLowerCase());
    }
    );
  };

  const { Loading, refetch } = useFetchWallets({
    onSuccess: (data) => {
      setOptions(filterOptions(data.data));
    },
    onError: () => null,
    enabled: false,
    params: {
      limit: 30
    },
  });

  useEffect(() => {
    refetch();
  }, [currency, refetch]);


  const handleOptionClick = (option) => {
    setSelected(option);
    setInputValue(option.name);
    setOpen(false);
    dispatch(setSearchProductInput(""));
    rest.onSelect && rest.onSelect(option);
  };

  const handleAddItemClick = () => {
    dispatch(setSearchProductInput(trimInputValue));
    rest.oneTime && rest.oneTime();
  };

  const handleNewProductClick = () => {
    dispatch(setSearchProductInput(trimInputValue));
    rest.createProduct && rest.createProduct();
  };

  const handleInputChange = useAsyncDebounce(() => {
    var optionfiltred = options.filter((option) => {
      return option.name.toLowerCase().includes(inputValue.toLowerCase());
    });
    refetch();
  }, 300);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  const trimInputValue = useMemo(() => {
    return inputValue.trim();
  }, [inputValue]);

  return (
    <div ref={selectRef} className="select-customer">
      <div
        onClick={() => setOpen(!open)}
        className={clsx(
          "select-customer__input",
          open && "select-customer__input--active"
        )}
      >
        <input
          type="text"
          className="select-customer__input__value"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
        <img
          className="select-customer__input__arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </div>
      {open && (
        <div className="select-customer__options">
          {Loading && (
            <div className="select-customer__loader">
              <Loader />
            </div>
          )}
          {(options.length === 0 && !Loading )? (
            <div className="select-customer__no-results">
              <span>No Wallet found</span>
            </div>
          ):
            <>
            {options.map((product, index) => {
              return <div
                key={index}
                onClick={() => handleOptionClick(product)}
                className={clsx(
                  "select-product__options__item flex justify-between",
                  selected &&
                    product.id === selected.id &&
                    "select-product__options__item--active"
                )}
              >
                <div>
                    {/* add condition for the current account */}
                  {truncate(product.name, { length: 25, separator: "..." })}
                </div>
                <span>
                    {product.id}
                </span>

              </div>
})}
            </>

}
        </div>
      )
      
      }
    </div>
  );
}

