import React, { useState } from 'react';
import Label from '../../../../../../Common/Form/Label/Label';
import Select from '../../../../../../Common/Form/Select/Select';
import { useSelector } from 'react-redux';
import TaxBehaviour from './PriceFields/TaxBehaviour';
import BillingType from './PriceFields/BillingType';
import BillingPeriod from './PriceFields/BillingPeriod';
import MeteredAggregation from './PriceFields/MeteredAggregation';
import InputGroup from '../../../../../../Common/Form/InputGroup/InputGroup';
import InputPrice from '../../../../../../Common/Form/InputPrice/InputPrice';
import Checkbox from '../../../../../../Common/Form/Checkbox/Checkbox';
import InfoIcon from '../../../../../../../assets/images/icons/info.svg';
import { FIAT_CURRENCIES_SELECT } from '../../../../../../../utils/constants';
import { fiatCurrencies } from '../../../../../../../utils/string';
import{ReactComponent as ArrowDownIcon} from "../../../../../../../assets/images/icons/dashboard/arrow_down.svg";
import MetadataForm from 'components/Dashboard/MetadataForm/MetadataForm';
// import {ReactComponent as ArrowUpIcon} from "../../../../../../../assets/images/icons/dashboard/arrow_up.svg";
import ProductsModalCreatePrice from '../../ProductsModalCreate/AddPrice/ProductsModalCreatePrice';
import ProductsModalCreateBillingType from '../../ProductsModalCreate/AddPrice/ProductsModalCreateBillingType';
import ProductsModalCreateBillingPeriod from '../../ProductsModalCreate/AddPrice/ProductsModalCreateBillingPeriod';

const PackagePricing = ({
  register,
  setValue,
  fieldError,
  currency,
  amount,
  type,
  tax_behaviour,
  metered,
  aggregate_usage,
  interval,
  billing_period,
  round,
}) => {
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  
  const handleBillingType = (type) => {
    if (selected_product_price?.id) return;
    
    setValue('type', type);
  };
  
  const handleCurrencyChange = (e) => {
    setValue('currency', e.value);
  };
  return (
    <>
      <div>
        <Label title='Price' />
        <InputPrice
          {...register('amount')}
          block
          min='0'
          currency={currency}
          currencies={FIAT_CURRENCIES_SELECT}
          onCurrencyChange={handleCurrencyChange}
          placeholder={currency ? fiatCurrencies[currency].zero_decimal ? '0' : '0.00' : '0'}
          error={fieldError('amount')}
          value={amount}
          disabled={selected_product_price?.id}
        />
      </div>
      <div className='flex justify-between'>
        <InputGroup
          {...register('transform_quantity.divide_by', {
            valueAsNumber: true,
          })}
          type='number'
          min='0'
          prepend='Per'
          append='units'
          block
          className='col-span-1'
          onChange={(e) => setValue(e.target.value)}
          error={fieldError('transform_quantity')}
          disabled={selected_product_price?.id}
        />
        <div className='flex gap-3 px-8'>
          <Label
            title='Round'
            icon={InfoIcon}
            tooltipHtml={true}
            tooltip={`
          When rounding Up, quantity will be rounded up to the nearest package group. 
          <br /> When Down, quantity will be rounded down.
          `}
            className='mt-8'
          />
          <Select
            onSelect={(e) => {
              return setValue(
                'transform_quantity.round',
                e.value
              );
            }}
            options={[
              {
                label: 'Up',
                value: 'up',
              },
              {
                label: 'Down',
                value: 'down',
              },
            ]}
            value={round}
            placeholder='Up'
            disabled={selected_product_price?.id}
          />
        </div>
      </div>

      <div>
        <TaxBehaviour
          onSelectOption={(option) => {
            setValue('tax_behaviour', option.value);
          }}
          tax_behaviour={tax_behaviour}
          disabled={selected_product_price?.id && selected_product_price.tax_behaviour !== 'unspecified'}
        />
      </div>
      <div>
        <BillingType
          setValue={setValue}
          disabled={selected_product_price?.id}
          type={type}
        />
      </div>
      {type === 'recurring' && (
        <>
          <div>
            <BillingPeriod
              {...register('recurring.interval_count')}
              interval={interval}
              block
              onIntervalChange={(e) =>
                setValue('recurring.interval', e.value)
              }
              onBillingPeriodChange={(e) => {
                setValue('billing_period', e.value);
              }}
              billing_period={billing_period}
              placeholder='0'
              style={{ paddingLeft: '60px' }}
              error={fieldError('recurring.interval_count')}
              disabled={selected_product_price?.id}
            />
          </div>
          <div className='flex items-center gap-3'>
            <Checkbox
              {...register('metered')}
              color='primary'
              className='mt-1'
              disabled={selected_product_price?.id}
            />
            <div className='text-light text-sm'>
              Metered Usage
            </div>
          </div>
          {metered && (
            <div>
              <MeteredAggregation
                onAggregationChange={(e) =>
                  setValue(
                    'recurring.aggregate_usage',
                    e.value
                  )
                }
                error={fieldError('aggregate_usage')}
                disabled={selected_product_price?.id}
                aggregate_usage={aggregate_usage}
              />
            </div>
          )}
          {/* <div className='horizontal-divider' /> */}
        </>
      )}

    </>
  );
};

export default PackagePricing;

export  const PackagePricingCreate = ({
  register,
  fieldError,
  type,
  metered,
  index,
  prices,
  errors,
  update=()=>{},
}) => {
  const { selected_product_price } = useSelector(
    (state) => state.product
  );

  return (
    <>
      <ProductsModalCreatePrice
      price={prices[index]}
      index={index}
      register={register}
      update={update}
      errors={errors}
    />
      <div className='flex justify-between'>
        <InputGroup
          {...register(`prices.${index}.transform_quantity.divide_by`, {
            valueAsNumber: true,
          })}
          type='number'
          min='2'
          prepend='Per'
          append='units'
          block
          className='col-span-1'
          onChange={(e) => update(index, {
              ...prices[index],
              transform_quantity: {
                ...prices[index].transform_quantity,
                divide_by: e.target.value
              }
            })
          }
          error={fieldError(`prices.${index}.transform_quantity.divide_by`)}
          disabled={selected_product_price?.id}
          //value={prices?.[index].transform_quantity?.divide_by}
        />
        <div className='flex gap-3 px-8'>
          <Label
            title='Round'
            icon={InfoIcon}
            tooltipHtml={true}
            tooltip={`
          When rounding Up, quantity will be rounded up to the nearest package group. 
          <br /> When Down, quantity will be rounded down.
          `}
            className='mt-8'
          />
          <Select
            {...register(`prices.${index}.transform_quantity.round`)}
            onSelect={(e) => {
              return update(index, {
                ...prices[index],
                transform_quantity: {
                  ...prices[index].transform_quantity,
                  round: e.value
                }
              });
            }}
            options={[
              {
                label: 'Up',
                value: 'up',
              },
              {
                label: 'Down',
                value: 'down',
              },
            ]}
            value={prices?.[index].transform_quantity?.round}
            placeholder='Up'
            disabled={selected_product_price?.id}
          />
        </div>
      </div>

      <div>
        <TaxBehaviour
          onSelectOption={(option) => {
            update(index, {
              ...prices[index],
              tax_behaviour: option.value,
            });
          }}
          tax_behaviour={prices[index]?.tax_behaviour ?? 'unspecified'}
          // disabled={selected_product_price?.id && selected_product_price.tax_behaviour !== 'unspecified'}
        />
      </div>
      <ProductsModalCreateBillingType 
        index={index} 
        price={prices[index]} 
        update={update}
        type={type} 
      />
      {type === 'recurring' && (
        <>
          <ProductsModalCreateBillingPeriod
            {...register(`prices.${index}.recurring.interval_count`)}
            interval={prices[index].recurring.interval}
            onIntervalChange={(e) =>
              update(index, {
                ...prices[index],
                recurring: {
                  ...[prices[index].recurring],
                  interval: e.value
                }
              })
            }
            onBillingPeriodChange={(e) => update(index, {
                ...prices[index],
                billing_period: e.value,
              })
            }
            billing_period={prices[index].billing_period}
            placeholder='0'
            style={{ paddingLeft: '60px' }}
            error={errors.prices?.[index]?.recurring?.interval_count?.message}
            prices={prices}
            index={index}
            //register={register}
          />
          <div className='flex items-center gap-3'>
            <Checkbox
              onChange={(e) => update(index, {
                ...prices[index],
                metered: e.target.checked,
              })}
              color='primary'
              className='mt-1'
              disabled={selected_product_price?.id}
            />
            <div className='text-light text-sm'>
              Metered Usage
            </div>
          </div>
          {metered && (
            <div>
              <MeteredAggregation
                onAggregationChange={(e) =>
                  update(index, {
                    ...prices[index],
                    recurring: {
                      ...[prices[index].recurring],
                      aggregate_usage: e.value
                    }
                  })
                }
                // error={fieldError('aggregate_usage')}
                disabled={selected_product_price?.id}
                aggregate_usage={prices[index].recurring.aggregate_usage}
              />
            </div>
          )}
          {/* <div className='horizontal-divider' /> */}
        </>
      )}
    </>
  );
};



