import Label from '../../../../../../Common/Form/Label/Label';
import Input from '../../../../../../Common/Form/Input/Input';
import React from 'react';
import { useSelector } from 'react-redux';

function ProductsModalCreateName({
  register,
  error,
  name,
}) {
  const { edit_product } = useSelector(
    (state) => state.product
  );

  console.log('testing name fields', name);
  return (
    <div>
      <Label title='Name' required={edit_product?.id ? false : true} error={error} />
      <Input
        {...register('name')}
        type='text'
        block
        error={error}
        disabled={edit_product?.id}
      />
    </div>
  );
}

export default ProductsModalCreateName;
