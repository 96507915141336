import { useDispatch, useSelector } from 'react-redux';
import ModalFullScreen from '../../../../../../Common/ModalFullScreen/ModalFullScreen';
import React, { useMemo, useState } from 'react';
import DashboardButton from '../../../../../../Common/Form/DashboardButton/DashboardButton';
import Dropdown from '../../../../../../Common/Dropdown/Dropdown';
import { ReactComponent as MoreIcon } from '../../../../../../../assets/images/icons/dashboard/more.svg';
import { ReactComponent as DateIcon } from '../../../../../../../assets/images/icons/date.svg';
import { dateFormatter } from '../../../../../../../utils/date';
import { ReactComponent as EditIcon } from '../../../../../../../assets/images/icons/dashboard/edit.svg';
import {
  currencyFormatter,
  currencySymbol,
} from '../../../../../../../utils/string';
import LabelTag from '../../../../../../Common/LabelTag/LabelTag';
import {
  useDeleteDiscount,
  useUpdateDiscount,
} from '../../../../../../../api/product/discount';
import {
  setSelectedDiscount,
  setRefetchDiscount,
  setShowDiscountForm,
} from '../../../../../../../store/discount/discountSlice';
import { toast } from 'react-toastify';
import ModalDelete from '../../../../../../Dashboard/Modals/ModalDelete/ModalDelete';
import DiscountsModalDetailsLogsTable from './DiscountsModalDetailsLogsTable';
import DiscountsModalDetailsEventsTable from './DiscountsModalDetailsEventsTable';
import { ReactComponent as ImagePlaceholder } from '../../../../../../../assets/images/dashboard/product-placeholder.svg';
import { Link } from 'react-router-dom';
import { DISCOUNT_TYPES } from '../../../../../../../utils/constants';
import CopyToClipboardBoxID from '../../../../../../Dashboard/CopyToClipboardBoxID/CopyToClipboardBoxID';

const MAX_DISPLAYED_PRODUCTS = 2;

function DiscountsModalDetails({
  show = false,
  onClose = null,
}) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] =
    useState(false);
  const [showMoreProducts, setShowMoreProducts] =
    useState(false);
  const { selected_discount } = useSelector(
    (state) => state.discount
  );

  const { mutate: mutateUpdateDiscount } =
    useUpdateDiscount(selected_discount?.id);
  const {
    mutate: mutateDeleteDiscount,
    isLoading: isDeleteLoading,
  } = useDeleteDiscount(selected_discount?.id);

  const getDiscountProducts = useMemo(() => {
    return showMoreProducts
      ? selected_discount.products
      : selected_discount.products.slice(
          0,
          MAX_DISPLAYED_PRODUCTS
        );
  }, [selected_discount, showMoreProducts]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleArchiveClick = () => {
    mutateUpdateDiscount(
      {
        active: !selected_discount.active,
      },
      {
        onSuccess: (data) => {
          dispatch(setSelectedDiscount(data.data));
          dispatch(setRefetchDiscount(Date.now()));
          toast.success(
            `Discount ${
              selected_discount.active
                ? 'archived'
                : 'unarchived'
            }`
          );
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      }
    );
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleModalDelete = () => {
    mutateDeleteDiscount(null, {
      onSuccess: () => {
        setShowDeleteModal(false);
        handleClose();
        dispatch(setSelectedDiscount(null));
        dispatch(setRefetchDiscount(Date.now()));
        toast.success('Discount deleted successfully');
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const handleEditClick = () => {
    dispatch(setShowDiscountForm(true));
  };

  return (
    <>
      <ModalFullScreen show={show} onClose={handleClose}>
        <ModalFullScreen.Slot name='header'>
          <div className='app-customers__modal__header'>
            <div className='app-modal-fullscreen__header__title'>
              Discount
            </div>
            <CopyToClipboardBoxID
              text={selected_discount?.id}
            />
          </div>
        </ModalFullScreen.Slot>
        <ModalFullScreen.Slot name='body'>
          {selected_discount?.id && (
            <div className='space-y-7'>
              <div className='space-y-1'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <div className='flex items-center gap-8'>
                      <div className='app-modal-fullscreen__big-title'>
                        {selected_discount.name}
                        {' - '}
                        {selected_discount.amount_off && (
                          <span className='inline-flex'>
                            <div className='app-customers__modal__earning'>
                              {currencySymbol(
                                selected_discount.currency
                              )}
                              {currencyFormatter(
                                selected_discount.currency,
                                selected_discount.amount_off
                              )}
                            </div>
                          </span>
                        )}
                        {selected_discount.percent_off && (
                          <>
                            {selected_discount.percent_off}%
                          </>
                        )}
                      </div>
                      {!selected_discount.active && (
                        <LabelTag className='mt-1'>
                          Archived
                        </LabelTag>
                      )}
                    </div>
                  </div>
                  <div className='flex items-center gap-3'>
                    <DashboardButton
                      size='sm'
                      color='light'
                      disabled>
                      Copy to livemode
                    </DashboardButton>
                    <Dropdown
                      items={[
                        {
                          label: 'Delete',
                          className:
                            'text-danger font-semibold',
                          onClick: handleDeleteClick,
                        },
                        {
                          label: selected_discount.active
                            ? 'Archive'
                            : 'Unarchive',
                          onClick: handleArchiveClick,
                        },
                      ]}>
                      <Dropdown.Slot name='toggle'>
                        <MoreIcon className='cursor-pointer' />
                      </Dropdown.Slot>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className='horizontal-divider' />
              <div className='flex flex-wrap gap-5 divide-x'>
                <div className='pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    TYPE
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    {
                      DISCOUNT_TYPES?.[
                        selected_discount.type
                      ]
                    }
                    <LabelTag className='ml-2'>
                      {selected_discount.amount_off &&
                        'Amount off'}
                      {selected_discount.percent_off &&
                        'Percent off'}
                    </LabelTag>
                  </div>
                </div>
                <div className='pl-5 pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    REDEMPTIONS
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    {selected_discount.times_redeemed}
                  </div>
                </div>
                <div className='pl-5 pr-20'>
                  <div className='app-modal-fullscreen__label'>
                    PROMO CODE
                  </div>
                  <div className='app-modal-fullscreen__value'>
                    <span className='font-semibold'>
                      {selected_discount.promo_code ?? '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='space-y-1.5'>
                <div className='flex justify-between'>
                  <div className='app-modal-fullscreen__title'>
                    Details
                  </div>
                  <div className='flex gap-4'>
                    <DashboardButton
                      onClick={handleEditClick}
                      outline
                      color='light'
                      size='sm'
                      icon={<EditIcon />}>
                      Edit
                    </DashboardButton>
                  </div>
                </div>
                <div className='horizontal-divider' />
                <div className='pt-4'>
                  <div className='grid grid-cols-5 gap-4'>
                    <div className='col-span-2 space-y-2'>
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          ID
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          {selected_discount.id}
                        </div>
                      </div>
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          Name
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          {selected_discount.name}
                        </div>
                      </div>
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          Created
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          <div className='flex items-center'>
                            <DateIcon className='mr-1.5' />
                            <span className='app-modal-fullscreen__value'>
                              {dateFormatter(
                                selected_discount.created_at
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          Expires
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          {selected_discount.end_date ? (
                            <div className='flex items-center'>
                              <DateIcon className='mr-1.5' />
                              <span className='app-modal-fullscreen__value'>
                                {dateFormatter(
                                  selected_discount.end_date
                                )}
                              </span>
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-span-2 space-y-2'>
                      <div className='app-modal-fullscreen__kv'>
                        <div className='app-modal-fullscreen__kv__key'>
                          Type
                        </div>
                        <div className='app-modal-fullscreen__kv__value'>
                          <span className='mr-2'>
                            {
                              DISCOUNT_TYPES?.[
                                selected_discount.type
                              ]
                            }
                          </span>
                          <LabelTag>
                            {selected_discount.amount_off &&
                              'Amount off'}
                            {selected_discount.percent_off &&
                              'Percent off'}
                          </LabelTag>
                        </div>
                      </div>
                      {selected_discount.amount_off && (
                        <div className='app-modal-fullscreen__kv'>
                          <div className='app-modal-fullscreen__kv__key'>
                            Amount off
                          </div>
                          <div className='app-modal-fullscreen__kv__value'>
                            <div className='app-customers__modal__earning'>
                              <div className='text-light'>
                                {currencySymbol(
                                  selected_discount.currency
                                )}
                                {currencyFormatter(
                                  selected_discount.currency,
                                  selected_discount.amount_off
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selected_discount.percent_off && (
                        <div className='app-modal-fullscreen__kv'>
                          <div className='app-modal-fullscreen__kv__key'>
                            Percent off
                          </div>
                          <div className='app-modal-fullscreen__kv__value'>
                            {selected_discount.percent_off}%
                          </div>
                        </div>
                      )}
                      {selected_discount.type ===
                        'product_quantity' && (
                        <div className='app-modal-fullscreen__kv'>
                          <div className='app-modal-fullscreen__kv__key'>
                            Quantity
                          </div>
                          <div className='app-modal-fullscreen__kv__value'>
                            {selected_discount.quantity_gte}
                          </div>
                        </div>
                      )}
                      {selected_discount.type ===
                        'amount_gte' && (
                        <div className='app-modal-fullscreen__kv'>
                          <div className='app-modal-fullscreen__kv__key'>
                            Sale Amount
                          </div>
                          <div className='app-modal-fullscreen__kv__value'>
                            <div className='app-customers__modal__earning'>
                              <div className='text-light'>
                                {currencySymbol(
                                  selected_discount.currency
                                )}
                                {currencyFormatter(
                                  selected_discount.currency,
                                  selected_discount.amount_gte
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(selected_discount.type ===
                        'product' ||
                        selected_discount.type ===
                          'product_quantity') && (
                        <div className='app-modal-fullscreen__kv'>
                          <div className='app-modal-fullscreen__kv__key'>
                            Products
                          </div>
                          <div
                            className='app-modal-fullscreen__kv__value'
                            style={{ minWidth: '300px' }}>
                            {getDiscountProducts.map(
                              (product, index) => (
                                <div
                                  key={index}
                                  className='flex mb-2'>
                                  <div className='app-products__modal__header__image app-products__modal__header__image--sm'>
                                    {product.image_url
                                      .length ? (
                                      <img
                                        src={
                                          product
                                            .image_url[0]
                                        }
                                        alt='Product'
                                      />
                                    ) : (
                                      <ImagePlaceholder />
                                    )}
                                  </div>
                                  <Link
                                    to={`/dashboard/products/${product.id}`}
                                    className='text-tertiary'>
                                    {product.name}
                                  </Link>
                                </div>
                              )
                            )}
                            {selected_discount.products
                              .length >=
                              MAX_DISPLAYED_PRODUCTS +
                                1 && (
                              <div
                                onClick={() =>
                                  setShowMoreProducts(
                                    !showMoreProducts
                                  )
                                }
                                className='app-modal-fullscreen__sm-link text-secondary'>
                                Show{' '}
                                {showMoreProducts
                                  ? 'less'
                                  : 'more'}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <DiscountsModalDetailsLogsTable
                enabled={!!selected_discount}
              />
              <DiscountsModalDetailsEventsTable
                enabled={!!selected_discount}
              />
            </div>
          )}
        </ModalFullScreen.Slot>
      </ModalFullScreen>
      {showDeleteModal && (
        <ModalDelete
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleModalDelete}
          loading={isDeleteLoading}
          title='Delete Discount'
          message='Are you sure you want to delete this discount?'
        />
      )}
    </>
  );
}

export default DiscountsModalDetails;
