import DashboardButton from "../../Form/DashboardButton/DashboardButton";
import React from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import {
  cryptocurrencyFormatter,
  currencyConverter,
} from "../../../../utils/string";

function DropdownFiltersButtonDone({
  form,
  filters,
  onChange,
  setFiltersCount,
  enabledFilters,
  handleDocumentClick,
}) {
  const { current_account } = useSelector((state) => state.auth);

  const handleDoneClick = (formData) => {
    const filtersData = {};
    const date_fields = [];

    if (enabledFilters.date && filters.includes("date")) {
      date_fields.push("created_at");
    }

    if (enabledFilters.due_date && filters.includes("due_date")) {
      date_fields.push("due_date");
    }

    date_fields.forEach((date_field) => {
      if (formData[`${date_field}_option`] === "in_the_last") {
        const momentDate = moment()
          .startOf("day")
          .subtract(
            formData[`${date_field}_number`],
            formData[`${date_field}_type`]
          );

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          gte: momentDate.unix(),
        };
      }

      if (formData[`${date_field}_option`] === "is_equal_to") {
        const momentStartDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .startOf("day");

        const momentEndDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .endOf("day");

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentStartDate.add(utcOffset, "minutes");
          momentEndDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          gte: momentStartDate.unix(),
          lte: momentEndDate.unix(),
        };
      }

      if (formData[`${date_field}_option`] === "is_between") {
        const momentStartDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .startOf("day");

        const momentEndDate = moment(
          new Date(formData[`${date_field}_end_date`])
        )
          .tz(current_account.timezone)
          .endOf("day");

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentStartDate.add(utcOffset, "minutes");
          momentEndDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          gte: momentStartDate.unix(),
          lte: momentEndDate.unix(),
        };
      }

      if (formData[`${date_field}_option`] === "is_after") {
        const momentStartDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .endOf("day");

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentStartDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          gt: momentStartDate.unix(),
        };
      }

      if (formData[`${date_field}_option`] === "is_on_or_after") {
        const momentStartDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .startOf("day");

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentStartDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          gte: momentStartDate.unix(),
        };
      }

      if (formData[`${date_field}_option`] === "is_before") {
        const momentStartDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .startOf("day");

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentStartDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          lt: momentStartDate.unix(),
        };
      }

      if (formData[`${date_field}_option`] === "is_before_or_on") {
        const momentStartDate = moment(
          new Date(formData[`${date_field}_start_date`])
        )
          .tz(current_account.timezone)
          .endOf("day");

        if (formData[`${date_field}_timezone`] === "UTC") {
          const utcOffset = moment().tz(current_account.timezone).utcOffset();
          momentStartDate.add(utcOffset, "minutes");
        }

        filtersData[date_field] = {
          lte: momentStartDate.unix(),
        };
      }
    });

    if (enabledFilters.name && filters.includes("name")) {
      filtersData.name = formData.name;
    }

    if (enabledFilters.email && filters.includes("email")) {
      filtersData.email = formData.email;
    }

    if (enabledFilters.shippable && filters.includes("shippable")) {
      filtersData.shippable = formData.shippable;
    }

    if (enabledFilters.url && filters.includes("url")) {
      filtersData.url = formData.url;
    }

    if (
      enabledFilters.metadata &&
      filters.includes("metadata") &&
      formData.metadata.length
    ) {
      let _metadata = {};

      formData.metadata.forEach((meta) => {
        const _values = Object.values(meta);

        _metadata[formData.metadata_query] = {
          ..._metadata[formData.metadata_query],
          [_values[0]]: _values[1],
        };
      });

      filtersData.metadata = _metadata;
    }

    if (
      (enabledFilters.currency && filters.includes("currency")) ||
      (enabledFilters.amount &&
        filters.includes("amount") &&
        !formData.crypto_currency)
    ) {
      filtersData.currency = formData.currency;
    }

    if (
      (enabledFilters.crypto_currency && filters.includes("crypto_currency")) ||
      (enabledFilters.crypto_amount &&
        filters.includes("crypto_amount") &&
        !formData.currency)
    ) {
      filtersData.crypto_currency = formData.crypto_currency.toUpperCase();
    }
    if (
      (enabledFilters.reason && filters.includes("reason")))
       {
      filtersData.type = formData.reason;
      }
    if (
      (enabledFilters.currency_type && filters.includes("currency_type")))
       {
      filtersData.type = formData.type;
      }

    if (enabledFilters.discount_type && filters.includes("discount_type")) {
      filtersData.type = formData.type;
    }

    if (enabledFilters.discount_terms && filters.includes("discount_terms")) {
      filtersData.terms = formData.terms;
    }

    if (enabledFilters.calculation && filters.includes("calculation")) {
      filtersData.inclusive = formData.inclusive;
    }

    if (enabledFilters.invoice_type && filters.includes("invoice_type")) {
      filtersData.type = formData.type;
    }

    if (enabledFilters.invoice_status && filters.includes("invoice_status")) {
      filtersData.status = formData.status;
    }

    if (enabledFilters.payment_status && filters.includes("payment_status")) {
      filtersData.status = formData.status;
    }
    if (enabledFilters.topup_status && filters.includes("topup_status")) {
      filtersData.status = formData.status;
    }
    if (enabledFilters.payout_status && filters.includes("payout_status")) {
      filtersData.status = formData.status;
    }
    if (enabledFilters.topup_method && filters.includes("topup_method")) {
      filtersData.method = formData.topup_method;
    }

    if (
      enabledFilters.shipping_description &&
      filters.includes("shipping_description")
    ) {
      filtersData.query = formData.query;
    }

    if (enabledFilters.customer && filters.includes("customer")) {
      filtersData.customer = formData.customer;
    }

    if (enabledFilters.amount && filters.includes("amount")) {
      if (formData["amount_option"] === "is_equal_to") {
        filtersData["amount"] = currencyConverter(
          formData.currency,
          formData.amount
        );
      }
      if (formData["amount_option"] === "is_between") {
        filtersData["amount"] = {
          gte: currencyConverter(formData.currency, formData.amount_gte),
          lte: currencyConverter(formData.currency, formData.amount_lte),
        };
      }
      if (formData["amount_option"] === "is_greater_than") {
        filtersData["amount"] = {
          gt: currencyConverter(formData.currency, formData.amount_gt),
        };
      }
      if (formData["amount_option"] === "is_less_than") {
        filtersData["amount"] = {
          lt: currencyConverter(formData.currency, formData.amount_lt),
        };
      }
    }

    if (enabledFilters.amount_due && filters.includes("amount_due")) {
      if (formData["amount_due_option"] === "is_equal_to") {
        filtersData["amount"] = currencyConverter(
          formData.currency,
          formData.amount_due
        );
      }
      if (formData["amount_due_option"] === "is_between") {
        filtersData["amount_due"] = {
          gte: currencyConverter(formData.currency, formData.amount_due_gte),
          lte: currencyConverter(formData.currency, formData.amount_due_lte),
        };
      }
      if (formData["amount_due_option"] === "is_greater_than") {
        filtersData["amount_due"] = {
          gt: currencyConverter(formData.currency, formData.amount_due_gt),
        };
      }
      if (formData["amount_due_option"] === "is_less_than") {
        filtersData["amount_due"] = {
          lt: currencyConverter(formData.currency, formData.amount_due_lt),
        };
      }
    }

    if (enabledFilters.crypto_amount && filters.includes("crypto_amount")) {
      const formatted_amount = (amount) => Math.trunc(Number(amount) * Math.pow(10, 8));

      if (formData["crypto_amount_option"] === "is_equal_to") {
        filtersData["crypto_amount"] = formatted_amount(formData.crypto_amount);
      }
      if (formData["crypto_amount_option"] === "is_between") {
        filtersData["crypto_amount"] = {
          gte: formatted_amount(formData.crypto_amount_gte),
          lte: formatted_amount(formData.crypto_amount_lte),
        };
      }
      if (formData["crypto_amount_option"] === "is_greater_than") {
        filtersData["crypto_amount"] = {
          gt: formatted_amount(formData.crypto_amount_gt),
        };
      }
      if (formData["crypto_amount_option"] === "is_less_than") {
        filtersData["crypto_amount"] = {
          lt: formatted_amount(formData.crypto_amount_lt),
        };
      }
      // filtersData["crypto"] = true;
    }

    if (enabledFilters.source && filters.includes("source")) {
      filtersData.source = formData.source;
    }
    if (enabledFilters.crypto_to_address && filters.includes("crypto_to_address")) {
      filtersData.to_addr = formData.crypto_to_address;
    }
    if (enabledFilters.tx_hash && filters.includes("tx_hash")) {
      filtersData.tx_hash = formData.tx_hash;
    }
    if (enabledFilters.crypto_from_address && filters.includes("crypto_from_address")) {
      filtersData.from_addr = formData.crypto_from_address;
    }

    onChange && onChange(filtersData);
    handleDocumentClick();
    setFiltersCount(
      Object.values(enabledFilters).filter((state) => state === true).length
    );
  };

  return (
    <DashboardButton
      onClick={(e) => {
        e.stopPropagation();
         form.handleSubmit(handleDoneClick)()}}
      color="primary"
      size="sm"
    >
      Done
    </DashboardButton>
  );
}

export default DropdownFiltersButtonDone;
