import Modal from "../../../../../../Common/Modal/Modal";
import Button from "../../../../../../Common/Form/Button/Button";
import Stack from "../../../../../../Common/Stack/Stack";
import { useFieldArray, useForm } from "react-hook-form";
import CustomerChooses from "./CustomerChooses";
import PackagePricing from "./PackagePricing";
import AdditionalOptions from "./PriceFields/AdditionalOptions";
import TieredPricing from "./TieredPricing";
import StandardPricing from "./StandardPricing";
import React, { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PRICE_REGEX } from "../../../../../../../utils/constants";
import {
  useStorePrice,
  useUpdatePrice,
} from "../../../../../../../api/product/price";
import {
  currencyConverter,
  currencyFormatter,
  currencyPrettier,
  currencyPrettierNoSymbol,
  currencySymbol,
  fiatCurrencies,
  sanitizeUnitAmount,
} from "../../../../../../../utils/string";
import {
  getBillingPeriod,
  validateTiers,
} from "../../../../../../../utils/price_utils";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setRefetchProductPrice } from "../../../../../../../store/product/productSlice";
import { useLocation, useNavigate } from "react-router-dom";
import get from "lodash/get";
import ProductPricingDropDown from "./ProductPricingDropDown";

export const PRODUCT_MODEL_VALUES = {
  STANDARD: {
    id: "standard_pricing",
    value: "standard_pricing",
    toolTipDisplayData:
      "Standard pricing is when you charge the same price per unit",
    optionsDisplayName: "Standard price",
  }, // default
  PACKAGE: {
    id: "package_pricing",
    value: "package_pricing",
    toolTipDisplayData:
      "Package pricing is when you charge by a package or group of units, E.g. If you charge $20.00 per 5 units. The purchase amount rounded up by default, so a customer buying 8 units would pay $40.00",
    optionsDisplayName: "Package price",
  }, // transform_quantity &&  transform_quantity.divide_by && transform_quantity.round
  TIERED: {
    id: "tiered_pricing",
    value: "tiered_pricing",
    toolTipDisplayData:
      "Tiered pricing is when you use pricing tiers that can change the per unit price based on the quantity in an order",
    optionsDisplayName: "Tiered price",
  }, // Billing_scheme=tiered && tiers_mode && tiers
  CUSTOMER_CHOOSES: {
    id: "customer_chooses",
    value: "customer_chooses",
    toolTipDisplayData:
      "Customer chooses pricing allows your customers choose how much they want to pay for your product or service. This pricing model only works for Checkout Sessions or Payment Links",
    optionsDisplayName: "Customer chooses amount",
  }, // custom_unit_amount
};

const determineProductModelFromSelectedProductPrice = (
  selected_product_price
) => {
  if (!selected_product_price) {
    return PRODUCT_MODEL_VALUES.STANDARD;
  }
  const {
    transform_quantity,
    billing_scheme,
    tiers_mode,
    tiers,
    custom_unit_amount,
  } = selected_product_price;
  let productModel = PRODUCT_MODEL_VALUES.STANDARD;
  if (
    transform_quantity &&
    transform_quantity.divide_by &&
    transform_quantity.round
  ) {
    productModel = PRODUCT_MODEL_VALUES.PACKAGE;
  } else if (
    // billing_scheme &&
    billing_scheme === "tiered" &&
    tiers_mode &&
    tiers
  ) {
    productModel = PRODUCT_MODEL_VALUES.TIERED;
  } else if (custom_unit_amount) {
    productModel = PRODUCT_MODEL_VALUES.CUSTOMER_CHOOSES;
  }
  return productModel;
};

function ProductAddPriceModal({ show = false, onClose = null }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [formResolver, setFormResolver] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const { selected_product, selected_product_price } = useSelector(
    (state) => state.product
  );
  const { current_account } = useSelector((state) => state.auth);

  const [selectedProductModel, setSelectedPriceModel] = useState(
    determineProductModelFromSelectedProductPrice(selected_product_price)
  );

  useEffect(() => {
    console.log("selected_product_price", selected_product_price);
    setSelectedPriceModel(
      determineProductModelFromSelectedProductPrice(selected_product_price)
    );
    return () => {
        navigate(pathname.replace("/create", "").replace("/edit", ""));

    };

  }, [selected_product_price]);

  const initialTiers = [
    {
      first_unit: 1,
      last_unit: 1,
      unit_amount: "",
      flat_amount: "",
      //currency: defaultCurrency,
    },
    {
      first_unit: 2,
      last_unit: "inf",
      unit_amount: "",
      flat_amount: "",
      //currency: defaultCurrency,
    },
  ];

  const formMethods = useForm({
    defaultValues: {
      currency: selected_product_price
        ? selected_product_price.currency
        : current_account.settings.reporting_currency,
      amount: selected_product_price?.unit_amount_decimal
        ? currencyFormatter(
            selected_product_price?.currency,
            selected_product_price?.unit_amount_decimal
          )
        : "",
      tax_behaviour: selected_product_price?.tax_behaviour ?? "unspecified",
      billing_scheme: selected_product_price?.billing_scheme ?? "per_unit",
      transform_quantity: {
        divide_by: selected_product_price?.transform_quantity?.divide_by ?? "2",
        round: selected_product_price?.transform_quantity?.round ?? "up",
      },
      custom_unit_amount: {
        maximum: selected_product_price?.custom_unit_amount?.maximum ?? "",
        minimum: selected_product_price?.custom_unit_amount?.minimum ?? "",
        preset: selected_product_price?.custom_unit_amount?.preset ?? "",
      },
      tiers:
        selected_product_price?.tiers?.map((item, id) => {
          return {
            first_unit:
              id != 0
                ? selected_product_price?.tiers[id - 1].up_to + 1
                : selected_product_price?.tiers_mode === "volume"
                ? 1
                : 0,
            last_unit: item?.up_to ? item?.up_to : "inf",
            unit_amount: item?.unit_amount_decimal
              ? currencyFormatter(
                  selected_product_price.currency,
                  item.unit_amount_decimal
                )
              : "",
            flat_amount: item?.flat_amount_decimal
              ? currencyFormatter(
                  selected_product_price?.currency,
                  item?.flat_amount_decimal
                )
              : "",
            currency: selected_product_price?.currency,
          };
        }) ?? initialTiers,
      tiers_mode: selected_product_price?.tiers_mode ?? "volume",
      type: selected_product_price?.type ?? "recurring",
      billing_period: selected_product_price?.id
        ? getBillingPeriod(selected_product_price)
        : "daily",
      recurring: {
        aggregate_usage:
          selected_product_price?.recurring?.aggregate_usage ?? "sum",
        interval: selected_product_price?.recurring?.interval ?? "day",
        interval_count:
          selected_product_price?.recurring?.interval_count ?? "1",
      },
      metered:
        selected_product_price?.recurring?.usage_type === "metered" ?? false,
      description: selected_product_price?.description ?? "",
      metadata: selected_product_price?.metadata ?? null,
    },
    resolver: formResolver ? yupResolver(formResolver) : undefined,
  });

  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "tiers",
  });

  const type = watch("type");
  const currency = watch("currency");
  const amount = watch("amount");
  const metered = watch("metered");
  const tax_behaviour = watch("tax_behaviour");
  const transform_quantity = watch("transform_quantity");
  const divide_by = watch("transform_quantity.divide_by");
  const round = watch("transform_quantity.round");
  const aggregate_usage = watch("recurring.aggregate_usage");
  const custom_unit_amount = watch("custom_unit_amount");
  const preset = watch("custom_unit_amount.preset");
  const minimum = watch("custom_unit_amount.minimum");
  const maximum = watch("custom_unit_amount.maximum");
  const billing_scheme = watch("billing_scheme");
  const tiers = watch("tiers");
  const tiers_mode = watch("tiers_mode");
  const interval = watch("recurring.interval");
  const billing_period = watch("billing_period");
  const metadata = watch("metadata");
  const settierError = (errors) => {
    var unit_amount = document.getElementsByClassName("editable-cell-input");
    var last_unit = document.getElementsByClassName("editable-cell-input-last");
    var errorsElm = document.getElementsByClassName(
      "editable-cell-input-error"
    );
    var errorsElmLast = document.getElementsByClassName(
      "editable-cell-input-last-error"
    );
    var f = document.getElementsByClassName("editable-cell-input-flat");

    for (let i = 0; i < errors.length; i++) {
      const element = errors[i];
      console.log("Element is : ", element);
      if (element.field === "unit_amount") {
        unit_amount[element.index].classList.add("warning");
        errorsElm[element.index].classList.add("show");
        errorsElm[element.index].innerHTML = element.message;
      } else if (element.field === "last_unit") {
        last_unit[element.index].classList.add("warning");
        errorsElmLast[element.index].classList.add("show");
        errorsElmLast[element.index].innerHTML = element.message;
      }
    }
  };
  const removeErrors = (errors) => {
    var d = document.getElementsByClassName("editable-cell-input");
    var f = document.getElementsByClassName("editable-cell-input-flat");
    console.log("Errors are : ", d);
    for (let i = 0; i < errors.length; i++) {
      const element = errors[i];
      console.log("Element is : ", element);
      if (element.field === "unit_amount") {
        d[element.index].classList.remove("warning");
        // f[element.index].classList.remove("warning");
      } else if (element.field === "last_unit") {
      }
    }
  };
  useEffect(() => {
    // register('recurring.aggregate_usage');
    // register('recurring.interval');
    // register('metadata');
  }, [register]);

  // useEffect(() => {
  //   setSelectedCurrency(selected_product_price ? selected_product_price.currency : current_account.settings.reporting_currency);
  // }, [selected_product_price, current_account]);

  useEffect(() => {
    if (
      selectedProductModel === PRODUCT_MODEL_VALUES.STANDARD ||
      selectedProductModel === PRODUCT_MODEL_VALUES.PACKAGE
    ) {
      const formSchema = {
        amount: yup
          .string()
          .required()
          .trim()
          .matches(
            PRICE_REGEX,
            "Amount must be positive and not exceed 14 decimal places"
          )
          .label("Price"),
      };

      if (type === "recurring") {
        let _maxInterval = 365;
        if (interval === "week") _maxInterval = 52;
        if (interval === "month") _maxInterval = 12;
        if (interval === "year") _maxInterval = 1;

        formSchema.recurring = yup.object().shape({
          interval_count: yup
            .number()
            .required()
            .integer()
            .min(1)
            .max(_maxInterval)
            .label("Billing period")
            .typeError("Billing period must be an integer"),
        });
      }

      setFormResolver(yup.object(formSchema).required());
    } else {
      setFormResolver(undefined);
    }
  }, [selectedProductModel, type, interval]);

  const { mutate: mutateStorePrice, error: storeErrors } = useStorePrice();
  const { mutate: mutateUpdatePrice, error: updateErrors } = useUpdatePrice(
    selected_product_price?.id
  );

  const canEditMetadata = useMemo(() => {
    return !pathname.startsWith("/dashboard/prices");
  }, [pathname]);

  const handleClose = () => {
    onClose && onClose();
  };

  //HERE
  const handleProductPricingModelChange = (option) => {
    const priceModel = Object.values(PRODUCT_MODEL_VALUES).find(
      (item) => item.id === option.value
    );
    setSelectedPriceModel(priceModel);
  };

  const fieldError = (field) => {
    return (
      get(errors, field)?.message ||
      (storeErrors && storeErrors.response?.data.error.param === field) ||
      (updateErrors && updateErrors.response?.data.error.param === field)
    );
  };

  const onSubmit = (formData) => {
    console.log("Form data is : ", formData);
    if (selected_product_price?.id) {
      const data = {
        description: formData.description,
      };

      if (formData.metadata && canEditMetadata) {
        data.metadata = formData.metadata;
      }

      if (
        selected_product_price.tax_behaviour === "unspecified" &&
        formData.tax_behaviour !== "unspecified"
      ) {
        data.tax_behaviour = formData.tax_behaviour;
      }
      mutateUpdatePrice(data, {
        onSuccess: () => {
          toast.success("Price updated successfully");
          dispatch(setRefetchProductPrice(Date.now()));
          handleClose();
        },
        onError: (error) => {
          toast.error(error.response?.data.error.message);
        },
      });
      return;
    }

    const data = {
      currency: formData.currency,
      type: formData.type,
      product: selected_product.id,
    };

    if (selectedProductModel === PRODUCT_MODEL_VALUES.CUSTOMER_CHOOSES) {
      let customUnitAmount = {
        enabled: true, // custom_unit_amount.enabled: true
        // minimum: formData.custom_unit_amount.minimum ? Math.trunc(
        //   Number(currencyConverter(
        //     formData.currency,
        //     formData.custom_unit_amount.minimum,
        //     false
        //   )) 
        // ) : 1,
      };

      if (formData.custom_unit_amount.minimum) {
        customUnitAmount.minimum = Math.trunc(
          Number(currencyConverter(
            formData.currency,
            formData.custom_unit_amount.minimum,
            false
          )) 
        );
      }

      if (formData.custom_unit_amount.maximum)
        customUnitAmount.maximum = Math.trunc(
          Number(
            currencyConverter(
              formData.currency,
              formData.custom_unit_amount.maximum,
              false
            )
          )
        );
      if (formData.custom_unit_amount.preset)
        customUnitAmount.preset = Math.trunc(
          Number(
            currencyConverter(
              formData.currency,
              formData.custom_unit_amount.preset,
              false
            )
          )
        );

      data.custom_unit_amount = customUnitAmount;
    } else {
      if (selectedProductModel === PRODUCT_MODEL_VALUES.TIERED) {
        let tiersData = [];
        console.log("data : ", validateTiers(formData.tiers));
        var errors = validateTiers(formData.tiers);
        if (errors.length > 0) {
          settierError(errors);
          return;
        }

        for (let i = 0; i < formData.tiers.length; i++) {
          let tierObj = {
            up_to:
              formData.tiers[i].last_unit !== "inf"
                ? Number(formData.tiers[i].last_unit)
                : formData.tiers[i].last_unit,
          };
          if (formData.tiers[i].unit_amount) {
            const unitAmount = currencyConverter(
              formData.currency,
              formData.tiers[i].unit_amount,
              true
            );

            if (unitAmount.includes(".")) {
              tierObj.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
            } else tierObj.unit_amount = Number(unitAmount);
          }
          if (formData.tiers[i].flat_amount) {
            const flatAmount = currencyConverter(
              formData.currency,
              formData.tiers[i].flat_amount,
              true
            );

            if (flatAmount.includes(".")) {
              tierObj.flat_amount_decimal = sanitizeUnitAmount(flatAmount);
            } else tierObj.flat_amount = Number(flatAmount);
          }
          tiersData.push(tierObj);
        }

        data.tiers = tiersData;
        data.tiers_mode = formData.tiers_mode;
        data.billing_scheme = "tiered";
      } else {
        const unitAmount = currencyConverter(
          formData.currency,
          formData.amount,
          true
        );

        if (unitAmount.includes(".")) {
          data.unit_amount_decimal = sanitizeUnitAmount(unitAmount);
        } else data.unit_amount = Number(unitAmount);

        if (selectedProductModel === PRODUCT_MODEL_VALUES.PACKAGE) {
          data.transform_quantity = {
            divide_by: formData.transform_quantity.divide_by,
            round: formData.transform_quantity.round,
          };
        }
      }

      if (formData.type === "recurring") {
        if (formData.billing_period === "daily") {
          data.recurring = {
            interval: "day",
            interval_count: 1,
            usage_type: "licenced",
          };
        }
        if (formData.billing_period === "weekly") {
          data.recurring = {
            interval: "week",
            interval_count: 1,
            usage_type: "licenced",
          };
        }
        if (formData.billing_period === "monthly") {
          data.recurring = {
            interval: "month",
            interval_count: 1,
            usage_type: "licenced",
          };
        }
        if (formData.billing_period === "three_monthly") {
          data.recurring = {
            interval: "month",
            interval_count: 3,
            usage_type: "licenced",
          };
        }
        if (formData.billing_period === "six_monthly") {
          data.recurring = {
            interval: "month",
            interval_count: 6,
            usage_type: "licenced",
          };
        }
        if (formData.billing_period === "yearly") {
          data.recurring = {
            interval: "year",
            interval_count: 1,
            usage_type: "licenced",
          };
        }
        if (formData.billing_period === "custom") {
          data.recurring = {
            interval: formData.recurring.interval,
            interval_count: Number(formData.recurring.interval_count),
            usage_type: "licenced",
          };
        }

        if (formData.metered) {
          data.recurring.aggregate_usage = formData.recurring.aggregate_usage;
          data.recurring.usage_type = "metered";
        }
      }
    }

    if (formData.metadata) data.metadata = formData.metadata;
    if (formData.description) data.description = formData.description;

    mutateStorePrice(data, {
      onSuccess: () => {
        toast.success("Price created successfully");
        dispatch(setRefetchProductPrice(Date.now()));
        handleClose();
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  const getPricingTypesToolTipData = (options) => {
    const listItems = options
      .map(
        (item) => `
        <li class="font-semibold">${item.optionsDisplayName}</li>
        ${item.toolTipDisplayData}
        `
      )
      .join("");
    return `
            <strong>Pricing Types:</strong>
            ${listItems}
          `;
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      size="md"
      footerBorder={true}
      bodyScrollable={true}
    >
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">
            {selected_product_price?.id ? "Edit" : "Create a"} price
          </div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <form
          style={
            {
              // maxHeight: '600px',
              // overflowY: 'scroll',
            }
          }
          id="create-price"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack columns="1" gap="4">
            <ProductPricingDropDown
              price={false}
              optionsTitle={"Pricing Model"}
              toolTipData={getPricingTypesToolTipData(
                Object.values(PRODUCT_MODEL_VALUES)
              )}
              options={Object.values(PRODUCT_MODEL_VALUES)}
              selectedOption={selectedProductModel}
              onSelectOption={handleProductPricingModelChange}
            />
            {selectedProductModel === PRODUCT_MODEL_VALUES.STANDARD && (
              <StandardPricing
                register={register}
                setValue={setValue}
                fieldError={fieldError}
                currency={currency}
                amount={amount}
                type={type}
                tax_behaviour={tax_behaviour}
                metered={metered}
                aggregate_usage={aggregate_usage}
                interval={interval}
                billing_period={billing_period}
              />
            )}
            {selectedProductModel === PRODUCT_MODEL_VALUES.PACKAGE && (
              <PackagePricing
                register={register}
                setValue={setValue}
                fieldError={fieldError}
                currency={currency}
                amount={amount}
                type={type}
                getValues={getValues}
                tax_behaviour={tax_behaviour}
                metered={metered}
                aggregate_usage={aggregate_usage}
                interval={interval}
                billing_period={billing_period}
                transform_quantity={transform_quantity}
                divide_by={divide_by}
                round={round}
              />
            )}

            {selectedProductModel === PRODUCT_MODEL_VALUES.CUSTOMER_CHOOSES && (
              <CustomerChooses
                setValue={setValue}
                fieldError={fieldError}
                register={register}
                currency={currency}
                custom_unit_amount={custom_unit_amount}
                preset={preset}
                minimum={minimum}
                maximum={maximum}
              />
            )}

            {selectedProductModel === PRODUCT_MODEL_VALUES.TIERED && (
              <>
                <TieredPricing
                  formMethods={formMethods}
                  tierFields={fields}
                  tierAppend={append}
                  tierRemove={remove}
                  tierUpdate={update}
                  setValue={setValue}
                  errors={errors}
                  tiers_mode={tiers_mode}
                  tiers={tiers}
                  billing_scheme={billing_scheme}
                  currency={currency}
                  register={register}
                  tax_behaviour={tax_behaviour}
                  type={type}
                  metered={metered}
                  aggregate_usage={aggregate_usage}
                  fieldError={fieldError}
                  interval={interval}
                  billing_period={billing_period}
                />
              </>
            )}
            {/* <Desription register={register}  metadata={metadata}  setValue={setValue}/> */}
            <AdditionalOptions
              register={register}
              metadata={metadata}
              setValue={setValue}
            />
          </Stack>
        </form>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex justify-end">
          <Button
            onClick={(e) => {
              if (selectedProductModel === PRODUCT_MODEL_VALUES.TIERED) {
                e.preventDefault();
                onSubmit(formMethods.getValues());
              }
            }}
            form="create-price"
            type="  "
          >
            {selected_product_price?.id ? "Save" : "Create"}
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default ProductAddPriceModal;
